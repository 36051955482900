import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { AdminTooltip } from "@components/tooltip";
import { FloatingSwitch } from "@pages/addChallengesPage/tabs/team/challengeTeamCardStyle";
import { getText } from "@utils/getText";
import { Alert, Card, Checkbox, Form, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { profileFields } from "environment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface TeamChallengeCardProps {
    form: FormInstance;
    hidden?: boolean;
    disabled?: boolean;
    defaultValues: TeamChallengeFormValues;
    editMode: boolean;
    onBlur(): void;
}

export interface TeamChallengeFormValues {
    team: {
        enabled: boolean;
        fixedTeams: boolean;
        profileProperty: string;
        maxMembers: number;
        inviteCodeEnabled: boolean;
    };
}

export const TeamChallengeCard: React.FC<TeamChallengeCardProps> = (props) => {
    const { editMode, form: formInstance, hidden, onBlur, disabled } = props;
    const [form] = useForm(formInstance);

    const getDefaultCheckedValueForPropertyFilterCheckbox = () => {
        if (!editMode) {
            return false;
        }
        if (form.getFieldValue(["team", "fixedTeams"])) {
            return false;
        }
        return Boolean(form.getFieldValue(["team", "profileProperty"]));
    };

    React.useEffect(() => {
        const defaultCheckedValue = getDefaultCheckedValueForPropertyFilterCheckbox();
        form.setFieldsValue({
            team: {
                filterTeamsByProperty: defaultCheckedValue
            }
        });
    }, [editMode]);

    const handleCheckboxChange = (event: CheckboxChangeEvent, value: "fixedTeams"|"filter") => {
        if (event.target.checked) {
            if (value === "fixedTeams") {
                form.setFieldsValue({
                    team: {
                        filterTeamsByProperty: false
                    }
                });
            } else {
                form.setFieldsValue({
                    team: {
                        fixedTeams: false
                    }
                });
            }
        }
        sharedProps.onBlur();
    };

    const visualisations = form.getFieldValue(["visualisations"]);
    const hasTeamVisualisations = visualisations.filter(vis => ["team", "userOwnTeam"].includes(vis.dataType));
    const hasUserVisualisations = visualisations.filter(vis => ["user"].includes(vis.dataType));

    const sharedProps = {
        disabled,
        onBlur
    };

    const zeroValidation = (rule, value, callback) => {
        if (value < 0) {
            callback("Must be 0 or higher");
        } else {
            callback();
        }
    };

    const renderFixedTeams = () => {
        const shouldDisable = !form.getFieldValue(["team", "enabled"]) || editMode;
        return (
            <Flex>
                <Form.Item
                    name={["team", "fixedTeams"]}
                    valuePropName="checked"
                >
                    <Checkbox
                        {...sharedProps}
                        disabled={shouldDisable}
                        onChange={(e) => handleCheckboxChange(e, "fixedTeams")}
                    >
                        <FormattedMessage id="fixedTeams" />
                    </Checkbox>
                </Form.Item>
                <AdminTooltip
                    customStyles={{ padding: "1px 5px" }}
                    description={<FormattedMessage id="warning.disabledChangeInEditMode" />}
                    hide={!editMode}
                    type="warning"
                />
            </Flex>
        );
    };

    const renderFilterTeamsByProperty = () => {
        const shouldDisable = !form.getFieldValue(["team", "enabled"]) || editMode;
        return (
            <Flex>
                <Form.Item
                    name={["team", "filterTeamsByProperty"]}
                    valuePropName="checked"
                >
                    <Checkbox
                        {...sharedProps}
                        disabled={shouldDisable}
                        onChange={(e) => handleCheckboxChange(e, "filter")}
                    >
                        <FormattedMessage id="filterTeamsByProperty" />
                    </Checkbox>
                </Form.Item>
                <AdminTooltip
                    customStyles={{ padding: "1px 5px" }}
                    description={<FormattedMessage id="warning.disabledChangeInEditMode" />}
                    hide={!editMode}
                    type="warning"
                />
            </Flex>
        );
    };

    const renderTeamsByProperty = () => {
        const shouldDisable = !(form.getFieldValue(["team", "fixedTeams"]) || form.getFieldValue(["team", "filterTeamsByProperty"]))
        || !form.getFieldValue(["team", "enabled"]) || editMode;
        return (
            <SelectField
                id="defineTeamsByProperty"
                label={<FormattedMessage id={form.getFieldValue(["team", "filterTeamsByProperty"]) ? "filterTeamsByProperty" : "defineTeamsByProperty"} />}
                name={["team", "profileProperty"]}
                {...sharedProps}
                allowClear
                disabled={shouldDisable}
                rules={[{
                    required: form.getFieldValue(["team", "fixedTeams"]) || form.getFieldValue(["team", "filterTeamsByProperty"]),
                    message: <FormattedMessage id="form.isrequired" />
                }]}
            >
                <Select.Option
                    key="frontendrole"
                    value="frontendrole"
                >
                    <FormattedMessage id="frontendRole" />
                </Select.Option>
                {
                    profileFields && profileFields.length > 0 && profileFields.map(profileField => (
                        <Select.Option
                            key={profileField.key}
                            value={profileField.key}
                        >
                            {getText(profileField.label)}
                        </Select.Option>
                    ))
                }
            </SelectField>
        );
    };

    const renderWarning = () => {
        const teamIsEnabled = form.getFieldValue(["team", "enabled"]);
        if (!editMode) {
            if (teamIsEnabled && hasTeamVisualisations.length) {
                return (
                    <Alert
                        showIcon
                        description={<FormattedMessage id="warning.teamSetting.team.enabled.visualisations" />}
                        message={<FormattedMessage id="warning.title" />}
                        type="warning"
                    />
                );
            }

            if (!teamIsEnabled && hasUserVisualisations.length) {
                return (
                    <Alert
                        showIcon
                        description={<FormattedMessage id="warning.teamSetting.user.disabled.visualisations" />}
                        message={<FormattedMessage id="warning.title" />}
                        type="warning"
                    />
                );
            }
        } else {
            return (
                <Alert
                    showIcon
                    description={<FormattedMessage id="warning.teamSetting.disabled.visualisations" />}
                    message={<FormattedMessage id="warning.title" />}
                    type="warning"
                />
            );
        }
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="team" />}
            />
            {renderWarning()}
            <Form.Item
                name={["team", "enabled"]}
                valuePropName="checked"
            >
                <FloatingSwitch
                    disabled={editMode}
                    onChange={sharedProps.onBlur}
                />
            </Form.Item>
            <ContentHeader title={<FormattedMessage id="teamChallenge" />} />
            <Flex gap="20" >
                <Form.Item
                    noStyle
                    dependencies={[["team", "enabled"]]}
                >
                    {() => renderFixedTeams()}
                </Form.Item>
                <Form.Item
                    noStyle
                    dependencies={[["team", "enabled"]]}
                >
                    {() => renderFilterTeamsByProperty()}
                </Form.Item>
            </Flex>
            <Form.Item
                noStyle
                dependencies={[["team", "fixedTeams"], ["team", "enabled"], ["team", "filterTeamsByProperty"]]}
            >
                {() => renderTeamsByProperty()}
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={[["team", "enabled"]]}
            >
                {() => {
                    const shouldDisable = !form.getFieldValue(["team", "enabled"]);
                    return (
                        <Form.Item
                            name={["team", "inviteCodeEnabled"]}
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...sharedProps}
                                disabled={shouldDisable}
                                onChange={sharedProps.onBlur}
                            >
                                <FormattedMessage id="inviteCodeEnabled" />
                            </Checkbox>
                        </Form.Item>
                    );
                }}
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={[["team", "enabled"]]}
            >
                {() => {
                    const autoParticipationEnabled = Boolean(form.getFieldValue("autoParticipationEnabled"));
                    const shouldDisable = !form.getFieldValue(["team", "enabled"]) || autoParticipationEnabled;
                    return (
                        <Flex>
                            <Field
                                id="maxTeamMembers"
                                label={<FormattedMessage id="maxTeamMembers" />}
                                name={["team", "maxMembers"]}
                                rules={[{ validator: zeroValidation }]}
                                {...sharedProps}
                                numberType
                                disabled={shouldDisable}
                                min={0}
                            />
                            <AdminTooltip
                                customStyles={{ padding: "1px 5px" }}
                                description={<FormattedMessage id="warning.challenge.maxNumbersOnAutoJoin" />}
                                hide={!autoParticipationEnabled}
                                type="warning"
                            />
                        </Flex>
                    );
                }}
            </Form.Item>
        </Card>
    );
};
