import { AddCardStyles, CardContent } from "@components/addCard/addCardStyle";
import { PlusOutlined } from "@ant-design/icons";
import * as React from "react";

export interface AddCardProps {
    label: string | JSX.Element;
    onClick?(): void;
}
export const AddCard: React.FC<AddCardProps> = (props) => {
    const { label, onClick } = props;

    return (
        <AddCardStyles
            onClick={onClick}
        >
            <CardContent>
                <PlusOutlined />
                {label}
            </CardContent>
        </AddCardStyles>
    );
};
