import { DashboardConfigWidgetVideo, DashboardConfigWidgetVideoInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetVideoFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetVideo, {
    video: LanguageObject;
    title: LanguageObject;
}>>;

export const mapVideoStateWidgetToVideoWidget = (widget: DashboardConfigWidgetVideoFormValues): DashboardConfigWidgetVideoInput => ({
    ...widget,
    source: "video",
    video: toTextByLanguage(widget.video),
    title: toTextByLanguage(widget.title)
});

export const mapVideoWidgetToVideoStateWidget = (videoWidget: DashboardConfigWidgetVideo): DashboardConfigWidgetVideoFormValues => {
    const urls: string[] = [];

    Object.keys(videoWidget.video).forEach((value) => {
        const video = videoWidget.video[value];

        if (urls.indexOf(video.text) < 0) {
            urls.push(video.text);
        }
    });

    return {
        ...videoWidget,
        title: toLanguageObject(videoWidget.title),
        type: videoWidget.type as ViewType,
        video: toLanguageObject(videoWidget.video)
    };
};
