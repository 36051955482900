import { CheckCircleFilled, DeleteOutlined, EditOutlined, LockOutlined, MinusCircleFilled } from "@ant-design/icons";
import { ExportEventContainer } from "@components/export/exportEventContainer";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface EventTableData extends TableData {
    chrono: boolean;
    registrationsOpen: number;
    title: string;
    time: number[];
    status: boolean;
    statusType: string;
    participants: number | undefined;
}

export interface EventTableProps extends TableComponentProps {
    dataSource: EventTableData[];
    handlers: TableHandlers & {
        removeEvent(id: string): void;
    };
}

const renderTitle = (text: string, row: EventTableData) => {
    return (
        <Link
            className="plainText"
            to={`/event/events/edit/${row.id}/general`}
        >
            {text}
        </Link>
    );
};

const renderChrono = (chrono) => {
    return chrono
        ? <CheckCircleFilled className="green" />
        : <MinusCircleFilled />;
};

const renderRegistrationsOpen = (registrationsOpen) => {
    if (!registrationsOpen) {
        return <MinusCircleFilled className="red" />;
    }

    if (registrationsOpen === 1) {
        return <CheckCircleFilled className="green" />;
    }

    return (
        <FormattedMessage
            id="registrationOpensDate"
            values={{ date: moment(registrationsOpen).format("DD/MM/YYYY") }}
        />
    );
};

const renderHandlers = (row: EventTableData, handlers: EventTableProps["handlers"]) => {
    return {
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <ExportEventContainer id={row.id} />
                <Link to={`/event/events/edit/${row.id}/general`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                {row.participants !== undefined && row.participants === 0
                    ? (
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            title={<FormattedMessage id="deleteConfirm" />}
                            onConfirm={() => handlers.removeEvent(row.id)}
                        >
                            <TableIcon as={DeleteOutlined} />
                        </Popconfirm>
                    )
                    : (
                        <Tooltip title={<FormattedMessage id="event.cantDelete" />}>
                            <TableIcon as={LockOutlined} />
                        </Tooltip>
                    )}
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    };
};

export const EventTable: React.FC<EventTableProps> = (props) => {
    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...props}
        >
            <Column
                key="chrono"
                dataIndex="chrono"
                render={renderChrono}
                sorter={(a: EventTableData, b: EventTableData) => Number(b.chrono) - Number(a.chrono)}
                title={<FormattedMessage id="chrono" />}
                width={90}
            />
            <Column
                key="registrationsOpen"
                sorter
                dataIndex="registrationsOpen"
                render={renderRegistrationsOpen}
                title={<FormattedMessage id="registrationsOpen" />}
                width={105}
            />
            <Column
                key="title"
                dataIndex="title"
                render={renderTitle}
                sorter={(a: EventTableData, b: EventTableData) => a.title.localeCompare(b.title)}
                title={<FormattedMessage id="title" />}
            />
            <Column
                key="start"
                sorter
                dataIndex="start"
                render={(_, row: EventTableData) => moment(row.time[0]).format("DD/MM/YYYY")}
                title={<FormattedMessage id="start" />}
            />
            <Column
                key="end"
                sorter
                dataIndex="end"
                render={(_, row: EventTableData) => moment(row.time[1]).format("DD/MM/YYYY")}
                title={<FormattedMessage id="end" />}
            />
            <Column
                key="roles"
                dataIndex="roles"
                title={<FormattedMessage id="roles" />}
                width={120}
            />
            <Column
                key="participants"
                dataIndex="participants"
                title={<FormattedMessage id="participants" />}
                width={120}
            />
            <Column
                key="handlers"
                dataIndex=""
                render={(_, row: EventTableData) => renderHandlers(row, props.handlers)}
                title=""
                width={120}
            />
        </Table>
    );
};
