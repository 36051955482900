import styled from "styled-components";

export const FieldInfo = styled.span`
    opacity: 0.6;
    float: right;
`;

export const BlockLabel = styled.label`
    display: block;
`;

export const FieldError = styled.span`
    color: #CF4948;
    margin-top: -8px;
`;
