import { PlusCircleOutlined as AddIcon, DeleteOutlined as DeleteIcon } from "@ant-design/icons";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useEnabledLang } from "@context/enabledLangContext/context";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { maxCharacters } from "@utils/maxCharacters";
import { DatePicker, Space, message } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SharedProps } from "../../general/sections/challengeBasicSettingsCard";
import { AddRangeIconWrapper, CardSubtitle, DeleteRangeIconWrapper, Section } from "../challengeAdvancedTabStyle";

interface SuperDaysSettingsProps extends SharedProps {
    form: FormInstance;
    languageErrors?: LanguageErrors;
}

interface TranslatedElementProps {
    language: string;
    hidden: boolean;
    sharedProps: SharedProps;
}

interface RangeType {
    from: Moment | null;
    to: Moment | null;
}

export const SuperDaysSettings = ({ form, languageErrors = {}, ...sharedProps }: SuperDaysSettingsProps) => {
    const { enabledLanguages } = useEnabledLang();
    const intl = useIntl();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [rangePickers, setRangePickers] = React.useState<RangeType[]>(() => {
        const initialDates = form.getFieldValue(["superDays", "dates"]);

        if (initialDates.length > 0) {
            return initialDates;
        }

        return [{ from: null, to: null }];
    });

    React.useEffect(() => {
        form.setFields([{
            name: ["superDays", "dates"],
            value: rangePickers
        }]);
    }, [rangePickers, form]);

    const handleAddRangePicker = () => {
        setRangePickers([...rangePickers, { from: null, to: null }]);
    };

    const handleRemoveRangePicker = (index) => {
        const newDatePickerValues = [...rangePickers];
        newDatePickerValues.splice(index, 1);
        setRangePickers(newDatePickerValues);
    };

    const isRangeValid = (newRangeStart: RangeType["from"], newRangeEnd: RangeType["to"], newRangeIndex: number): boolean => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [index, range] of rangePickers.entries()) {
            const previousRangeStart = range.from;
            const previousRangeEnd = range.to;

            if (index === newRangeIndex) {
                continue;
            }

            if (previousRangeStart === null || previousRangeEnd === null) {
                continue;
            }

            const isRangeOverlapping = previousRangeStart!.isSameOrBefore(newRangeEnd, "D") && previousRangeEnd!.isSameOrAfter(newRangeStart, "D");

            if (isRangeOverlapping) {
                return false;
            }
        }
        return true;
    };

    const handleRangeChange = (startDate: RangeType["from"], endDate: RangeType["to"], index: number) => {
        const isClearCommand = startDate === null && endDate === null;

        if (!isClearCommand && !isRangeValid(startDate, endDate, index)) {
            message.error(intl.formatMessage({ id: "superDays.rangeOverlap.error" }), 3);
            return;
        }

        const newDatePickerValues = [...rangePickers];
        newDatePickerValues[index] = {
            from: startDate,
            to: endDate
        };

        setRangePickers(newDatePickerValues);
        sharedProps.onChange();
    };

    const translationTabsProps = React.useMemo(() => enabledLanguages.map((language) => ({
        sharedProps,
        language: language.code,
        hidden: language.code !== selectedLanguage
    })), [enabledLanguages, selectedLanguage]);

    return (
        <React.Fragment>
            <CardSubtitle><FormattedMessage id="superDays.title" /></CardSubtitle>
            <Section>
                <TranslationTabs
                    activeLanguage={selectedLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />
                {translationTabsProps.map(props => <TitleField {...props} />)}
                {translationTabsProps.map(props => <SubtitleField {...props} />)}
            </Section>
            <Section>
                <Space direction="vertical">
                    <FormattedMessage id="ranges" />
                    <Form.Item name={["superDays", "dates"]}>
                        {rangePickers.map((value, index) => (
                            <div>
                                <DatePicker.RangePicker
                                    value={[value.from, value.to]}
                                    onChange={(dates) => {
                                        if (!dates) {
                                            handleRangeChange(null, null, index);
                                        } else {
                                            handleRangeChange(dates[0], dates[1], index);
                                        }
                                    }}
                                />
                                {index > 0 && (
                                    <DeleteRangeIconWrapper>
                                        <DeleteIcon onClick={() => handleRemoveRangePicker(index)} />
                                    </DeleteRangeIconWrapper>
                                )}
                            </div>
                        ))}
                    </Form.Item>
                    <AddRangeIconWrapper>
                        <AddIcon
                            height={24}
                            onClick={handleAddRangePicker}
                        />
                    </AddRangeIconWrapper>
                </Space>
            </Section>
            <Field
                numberType
                id="superdays.multiplier"
                initialValue={1}
                label={<FormattedMessage id="multiplier" />}
                min={1}
                name={["superDays", "multiplier"]}
                step={0.1}
                {...sharedProps}
            />
        </React.Fragment>
    );
};

const SubtitleField = (props: TranslatedElementProps) => (
    <Field
        key={`superdays.description.${props.language}`}
        hidden={props.hidden}
        id={`superdays.description.${props.language}`}
        label={<FormattedMessage id="description" />}
        name={["superDays", "description", props.language]}
        {...props.sharedProps}
    />
);

const TitleField = (props: TranslatedElementProps) => (
    <Field
        key={`superdays.title.${props.language}`}
        hidden={props.hidden}
        id={`superdays.title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["superDays", "title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            }
        ]}
        {...props.sharedProps}
    />
);
