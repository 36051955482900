import styled from "styled-components";

export const LoginStyle = styled.div`
    background: url("https://res.cloudinary.com/energylab/image/upload/v1593509750/development/login_bg.jpg");
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    background-size: cover;
    justify-content: center;
`;

export const LoginBoxStyle = styled.div`
    width: 600px;
    height: 300px;
    display: flex;
    padding: 2em;
    background: white;
    box-shadow: 5px 5px 12px rgba(0 ,0 ,0, 0.5);
    align-items: center;
    justify-content: center;

    img {
        width: 176px;
        height: 216px;
        flex-shrink: 0;
        margin-right: 3em;
    }

    .login {
        &__cover {
            flex-basis: 100%;
        }

        &__form {
            padding: 20px 0;
        }
    }
`;
