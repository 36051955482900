/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-lines-per-function */
import Icon from "@ant-design/icons";
import { Field } from "@components/field/field";
import { Flex } from "@components/flex/flex";
import { ChallengeVisualisationFont } from "@components/fontForm/fontForm";
import { TimelineAddElement } from "@components/timelinePreview/timelineAddElement/timelineAddElement";
import { TimelinePreview } from "@components/timelinePreview/timelinePreview";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { visualisationBannerCounterFactory } from "@pages/addChallengesPage/factories";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { VisualisationdCardstyle, VisualisationdGridStyle } from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisationStyle";
import { ChallengeTimelineVisualisationGoal } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineGoal/editChallengeTimelineGoal";
import { ChallengeVisualisationBaseFormValues, VisualisationTypeForm } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { setArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { Card, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../context/enabledLangContext/context";
import { ChallengeTimelineVisualisationCounter } from "./editChallengeTimelineCounter/editChallengeTimelineCounter";
import { ChallengeTimelineVisualisationRepeater } from "./editChallengeTimelineRepeater/editChallengeTimelineRepeater";
import { TimelineHeaderSettingsCard } from "./editChallengeTimelineVisualisationStyle";

const MAX_COUNTERS = 6;

// Fix for Grid typings not allowing children
export const { Grid } = Card;

export interface ChallengeTimelineVisualisationFlag {
    image: string;
    font: ChallengeVisualisationFont;
}

export interface ChallengeTimelineVisualisationBanner {
    image?: string;
    backgroundColor?: string;
    goal?: ChallengeTimelineVisualisationGoal;
    repeater?: ChallengeTimelineVisualisationRepeater;
    counters?: ChallengeTimelineVisualisationCounter[];
}

export interface ChallengeTimelineVisualisationFormValues extends ChallengeVisualisationBaseFormValues {
    conditionId?: string;
    banner?: ChallengeTimelineVisualisationBanner;
    foregroundImage: string;
    backgroundImage: string;
    splitterImage: string;
    flag?: ChallengeTimelineVisualisationFlag;
    successImage: string;
}

export interface EditChallengeTimelineVisualisationProps {
    form: FormInstance;
    visualisation: ChallengeTimelineVisualisationFormValues;
    conditions?: ChallengeConditionFormValuesUnion[];
    index: number;
    hidden: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(language): void;
    editCounter(index: number): void;
    deleteRepeater(): void;
    editRepeater(): void;
    editGoal(): void;
    deleteGoal(): void;

    editForegroundImage(): void;
    editBackgroundImage(): void;
    editSplitterImage(): void;
    editFlagImage(): void;
    editFinishedImage(): void;

    back(): void;
    onBlur(): void;
}

export interface EditChallengeTimelineVisualisationState {
    previewValue: number;
    counterIndex?: number;
}

export const EditChallengeTimelineVisualisationCard: React.FC<EditChallengeTimelineVisualisationProps> = (props) => {
    const { hidden, form, visualisation, onBlur, conditions, back, index, editCounter, editRepeater, deleteRepeater, editGoal, deleteGoal, activeLanguage, changeSelectedLanguage, languageErrors, editForegroundImage, editBackgroundImage, editSplitterImage, editFlagImage, editFinishedImage } = props;

    const { enabledLanguages } = useEnabledLang();

    // eslint-disable-next-line no-shadow
    const renderCounterCards = (counter: ChallengeTimelineVisualisationCounter, counterIndex: number, remove: (index: number | number[]) => void, visualisationIndex: number) => {
        return (
            <TimelineHeaderSettingsCard
                key={`${counterIndex}-${visualisationIndex}`}
                actions={[
                    <a onClick={() => editCounter(counterIndex)}><FormattedMessage id="edit" /></a>,
                    <a onClick={() => remove(counterIndex)}><FormattedMessage id="remove" /></a>
                ]}
                align="center"
            >
                {counter.image
                    ? (
                        <img
                            alt="Counter"
                            role="presentation"
                            src={counter.image}
                        />
                    )
                    : <Icon type="picture" />}
                <br />
                {counter.activityTypes && counter.activityTypes.length
                    ? counter.activityTypes.map((type) => (`${type} `))
                    : "Type"}
            </TimelineHeaderSettingsCard>
        );
    };

    const renderCounterCard = () => {
        return (
            <div>
                <Flex direction="column">
                    <Form.List name={["visualisations", index, "banner", "counters"]}>
                        {(fields, { add, remove }) => {
                            const formCounters = form.getFieldValue(["visualisations", index, "banner", "counters"]);
                            const displayCounters = (formCounters || []).map((c, i) => renderCounterCards(c, i, remove, index));
                            return (
                                <React.Fragment>
                                    {displayCounters}
                                    {displayCounters.length < MAX_COUNTERS && (
                                        <TimelineAddElement
                                            handleClick={() => {
                                                add(visualisationBannerCounterFactory());
                                            }}
                                            height="100%"
                                            subTitle={(
                                                <FormattedMessage
                                                    id="challenge.visualisations.timeline.max.per.timeline"
                                                    values={{ maxAmount: MAX_COUNTERS.toString() }}
                                                />
                                            )}
                                            title={<FormattedMessage id="challenge.visualisations.timeline.progressindicators" />}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Form.List>
                </Flex>
            </div>
        );
    };

    const addEmptyGoal = () => {
        setArrayFieldValue(
            form,
            [
                ["visualisations"],
                ["banner", "goal"]
            ],
            index,
            {}
        );

        editGoal();
    };

    const renderGoalCard = () => {
        let goal: ChallengeTimelineVisualisationGoal | undefined;
        if (visualisation.banner && visualisation.banner.goal) {
            goal = visualisation.banner && visualisation.banner.goal;
        }

        if (!goal) {
            return (
                <TimelineAddElement
                    handleClick={addEmptyGoal}
                    height="100%"
                    subTitle={(
                        <FormattedMessage
                            id="challenge.visualisations.timeline.max.per.timeline"
                            values={{ maxAmount: "1" }}
                        />
                    )}
                    title={<FormattedMessage id="challenge.visualisations.timeline.goal" />}
                />
            );
        }

        return (
            <TimelineHeaderSettingsCard
                actions={[
                    <a onClick={editGoal}><FormattedMessage id="edit" /></a>,
                    <a onClick={deleteGoal}><FormattedMessage id="remove" /></a>
                ]}
                align="left"
            >
                <Flex
                    alignItems="center"
                    direction="row"
                >
                    {goal.image
                        ? (
                            <img
                                alt="Goal"
                                role="presentation"
                                src={goal.image}
                            />
                        )
                        // TODO: replace icon
                        : <Icon type="picture" />}
                    {goal.currentValue && <FormattedMessage id="challenge.visualisations.current" />}
                    {goal.currentValue && goal.endValue && (
                        <React.Fragment>&nbsp;/&nbsp;</React.Fragment>
                    )}
                    {goal.endValue && <FormattedMessage id="challenge.visualisations.end" />}
                </Flex>
            </TimelineHeaderSettingsCard>
        );
    };

    const addEmptyRepeater = () => {
        setArrayFieldValue(
            form,
            [
                ["visualisations"],
                ["banner", "repeater"]
            ],
            index,
            {}
        );

        editRepeater();
    };

    const renderRepeaterCard = () => {
        let repeater: ChallengeTimelineVisualisationRepeater | undefined;
        if (visualisation.banner && visualisation.banner.repeater) {
            repeater = visualisation.banner && visualisation.banner.repeater;
        }

        if (!repeater) {
            return (
                <TimelineAddElement
                    handleClick={addEmptyRepeater}
                    height="100%"
                    subTitle={(
                        <FormattedMessage
                            id="challenge.visualisations.timeline.max.per.timeline"
                            values={{ maxAmount: "1" }}
                        />
                    )}
                    title={<FormattedMessage id="challenge.visualisations.timeline.repeater" />}
                />
            );
        }

        return (
            <TimelineHeaderSettingsCard
                actions={[
                    <a onClick={editRepeater}><FormattedMessage id="edit" /></a>,
                    <a onClick={deleteRepeater}><FormattedMessage id="remove" /></a>
                ]}
                align="left"
            >
                <Flex
                    alignItems="center"
                    direction="row"
                >
                    {repeater.image
                        ? (
                            <img
                                alt="Repeater"
                                role="presentation"
                                src={repeater.image}
                            />
                        )
                        : <Icon type="picture" />}
                    <FormattedMessage
                        id="challenge.visualisations.timeline.repeaterX"
                        values={{ repeat: repeater.repeat }}
                    />
                </Flex>
            </TimelineHeaderSettingsCard>
        );
    };

    const renderHeaderOptions = () => {
        return (
            <TimelinePreview
                backgroundImage={visualisation.backgroundImage}
                finishedImage={visualisation.successImage}
                flagImage={visualisation.flag?.image}
                foregroundImage={visualisation.foregroundImage}
                splitterImage={visualisation.splitterImage}
                onBackgroundImageClicked={editBackgroundImage}
                onFinishedImageClicked={editFinishedImage}
                onFlagImageClicked={editFlagImage}
                onForegroundImageClicked={editForegroundImage}
                onSplitterImageClicked={editSplitterImage}
            />
        );
    };

    const renderBodyOptions = () => {
        return (
            <Flex
                justifyContent="space-between"
                style={{ marginTop: "10px" }}
            >
                {renderCounterCard()}
                {renderRepeaterCard()}
                {renderGoalCard()}
            </Flex>
        );
    };

    const titleField = (titleProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.title.${titleProps.language}`}
            hidden={titleProps.hidden}
            id={`visualisations.title.${titleProps.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="title" />}
            name={["visualisations", index, "title", titleProps.language]}
            rules={[
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                },
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }
            ]}
            {...titleProps.sharedProps}
        />
    );

    const descriptionField = (descriptionProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.description.${descriptionProps.language}`}
            hidden={descriptionProps.hidden}
            id={`visualisations.description.${descriptionProps.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="description" />}
            name={["visualisations", index, "description", descriptionProps.language]}
            rules={[
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                },
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }
            ]}
            {...descriptionProps.sharedProps}
        />
    );

    const isTeamChallenge = form.getFieldValue(["team", "enabled"]);

    const sharedProps = {
        onBlur
    };

    const langFieldProps: ChallengeLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                form,
                sharedProps,
                defaultValues: {},
                language: language.code,
                hidden: language.code !== activeLanguage
            }
        )), [enabledLanguages, activeLanguage]
    );

    return (
        <VisualisationdCardstyle hidden={hidden}>
            <VisualisationdGridStyle>
                <VisualisationTypeForm
                    back={back}
                    conditions={conditions}
                    form={form}
                    index={index}
                    isTeamChallenge={isTeamChallenge}
                    prefix={["visualisations", index]}
                    visualisation={visualisation}
                    onChange={onBlur}
                />
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <FormattedMessage
                    id="titleAndDescription"
                    tagName="h1"
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(descriptionField)}
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <FormattedMessage
                    id="challenge.visualisation.timeline.how.title"
                    tagName="h1"
                />
                <FormattedMessage
                    id="challenge.visualisation.timeline.how.text"
                    tagName="p"
                />

                <FormattedMessage
                    id="challenge.visualisation.timeline.title"
                    tagName="h2"
                />
                <FormattedMessage
                    id="challenge.visualisation.timeline.how.to.use.text"
                    tagName="p"
                />
                <FormattedMessage
                    id="challenge.visualisation.timeline.how.image.size"
                    tagName="strong"
                />
                {renderHeaderOptions()}
                {renderBodyOptions()}
            </VisualisationdGridStyle>
        </VisualisationdCardstyle>
    );
};
