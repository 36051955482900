import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { withAddChallengeTag, withUpdateChallengeTag, withGetChallengeTags } from "@graphql2/types";
import { withRemoveChallengeTagMutation } from "@pages/tagsPage/challengeTagsPageContainer";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getChallengeTags } from "@graphql2/graphql_op";

export const withChallengeTags = withGetChallengeTags({
    skip: (props: AddTagsRouteProps) => {
        return !props.match.params.id;
    }
});

const withAddChallengeTagMutation = withAddChallengeTag({
    name: "addTag",
    options: {
        update: cleanCache([getChallengeTags])
    }
});
const withChallengeTagMutation = withUpdateChallengeTag({
    name: "updateTag"
});

export const AddChallengeTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddChallengeTagMutation,
    withUpdateTag: withChallengeTagMutation,
    withTags: withChallengeTags,
    withRemoveTag: withRemoveChallengeTagMutation,
    callbackUrl: "/challenges/tags",
    path: ["data", "challengeTags"]
});
