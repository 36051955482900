import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { getDashboardsOverview } from "@graphql2/graphql_op";
import { AddDashboardPage } from "@pages/addDashboardPage/addDashboardPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import { SortAndPaging } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { AddDashboardConfigurationProps, ArticleFilter, GetApplicationsProps, GetDashboardsOverviewProps, GetDashboardsProps, GetDashboardsQuery, RemoveDashboardConfigurationProps, UpdateDashboardConfigurationProps, withAddDashboardConfiguration, withGetApplications, withGetDashboards, withGetDashboardsOverview, withRemoveDashboardConfiguration, withUpdateDashboardConfiguration } from "../../graphql2/types";
import { LocalStateProps } from "../../utils/withLocalState";

export interface AddDashboardPageContainerProps {
    editMode?: boolean;
}

export interface LocalState extends SortAndPaging {
    filter?: ArticleFilter;
}

type AddDashboardsRouteProps = RouteComponentProps<
    { device: ViewType; id: string; },
    {},
    { import: GetDashboardsQuery["dashboardConfigurations"][0]; }
>;

//  Should fetch only one
const withDashboards = withGetDashboards({
    name: "dashboards",
    options: (props: AddDashboardsRouteProps) => ({
        variables: {
            filter: {
                ids: [props.match.params.id]
            }
        }
    }),
    skip: (props: AddDashboardsRouteProps) => {
        // It should skip if no params and show blank add page
        return !props.match.params.id;
    }
});

const withAddDashboardConfigMutation = withAddDashboardConfiguration({
    name: "addDashboardConfiguration",
    options: {
        update: cleanCache([getDashboardsOverview])
    }
});

const withApplications = withGetApplications({
    name: "applications"
});

const withUpdateDashboardConfigMutation = withUpdateDashboardConfiguration({
    name: "updateDashboardConfiguration",
    options: {
        update: cleanCache([getDashboardsOverview])
    }
});

const withRemoveDashboardConfigMutation = withRemoveDashboardConfiguration({
    name: "removeDashboardConfiguration",
    options: {
        update: cleanCache([getDashboardsOverview])
    }
});

// Shouldn't need a filter
const withPlacesOverview = withGetDashboardsOverview({
    name: "dashboardConfigurations"
});

export const AddDashboardPageContainer = compose<React.ComponentClass<AddDashboardPageContainerProps>>(
    withApplications,
    withDashboards,
    withAddDashboardConfigMutation,
    withPlacesOverview,
    withUpdateDashboardConfigMutation,
    withRemoveDashboardConfigMutation,
    withDashboards
)(AddDashboardPage);

export type AddDashboardPageProps = AddDashboardPageContainerProps
    & AddDashboardsRouteProps
    & AddDashboardConfigurationProps<{}, "addDashboardConfiguration">
    & GetApplicationsProps<{}, "applications">
    & UpdateDashboardConfigurationProps<{}, "updateDashboardConfiguration">
    & MaybeElements<GetDashboardsProps<{}, "dashboards">>
    & GetDashboardsOverviewProps<{}, "dashboardConfigurations">
    & RemoveDashboardConfigurationProps<{}, "removeDashboardConfiguration">
    & LocalStateProps<LocalState>
