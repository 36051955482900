import { ApplicationFragment } from "@graphql2/types";
import { getOutFalseValues } from "./getOutFalseValues";

export const getFrontendRoleIdFromString = (role: string) => {
    return role.split("_")[2];
};

export function getFrontendApplication(applications: ApplicationFragment[]): ApplicationFragment | undefined {
    return applications.find(a => a.id === "frontend");
}

export function getFrontendRoles(applications: ApplicationFragment[] | undefined): ApplicationFragment["roles"] {
    if (!applications) {
        return [];
    }
    const application = getFrontendApplication(applications);

    if (!application) {
        return [];
    }

    return application.roles;
}

export function getFrontendRoleNamesFromIds(applications: ApplicationFragment[], ids: string[]): string[] {
    const frontendApplication = getFrontendApplication(applications);
    if (frontendApplication) {
        return ids.map(id => frontendApplication?.roles.find(r => r.id === id)?.name).filter(getOutFalseValues);
    }
    return [];
}
export function getFrontendRoleIdsFromNames(applications: ApplicationFragment[], names: string[]): string[] {
    const frontendApplication = getFrontendApplication(applications);
    if (frontendApplication) {
        return names.map(name => frontendApplication.roles.find(r => r.name === name)?.id).filter(getOutFalseValues);
    }
    return [];
}

export function getFrontendRoleNames(applications: ApplicationFragment[] | ApplicationFragment[]): string[] {
    const frontendApplication = getFrontendApplication(applications);
    if (frontendApplication) {
        return frontendApplication.roles.map(r => r.name);
    }
    return [];
}
