import { DashboardTableData } from "@components/dashboard/dashboardOverviewTable";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { AddDashboardConfigurationMutationVariables, DashboardConfig, DashboardConfigWidget, DashboardConfigWidgetActivity, DashboardConfigWidgetCaroussel, DashboardConfigWidgetChallenge, DashboardConfigWidgetChallengeVisualisation, DashboardConfigWidgetCommunity, DashboardConfigWidgetContent, DashboardConfigWidgetEvent, DashboardConfigWidgetGeneric, DashboardConfigWidgetPopup, DashboardConfigWidgetRendering, DashboardConfigWidgetSocial, DashboardConfigWidgetStatistics, DashboardConfigWidgetVideo, GetDashboardsQuery, Scalars } from "@graphql2/types";
import { Column, DashboardFormValues, DeviceSetting, DeviceSettings } from "@pages/addDashboardPage/addDashboardPage";
import { WidgetObject, WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";
import { formatDate } from "@utils/dateUtils";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { DashboardConfigWidgetChallengeVisualisationFormValues, mapChallengeVisualisationStateWidgetToChallengeVisualisationWidget, mapChallengeVisualisationWidgetToChallengeVisualisationStateWidget } from "@utils/mappers/widgets/challengeVisualisationWidgetMapper";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import * as moment from "moment";
import { DashboardConfigWidgetActivityFormValues, mapActivityStateWidgetToActivityWidget, mapActivityWidgetToActivityStateWidget } from "./widgets/activityWidgetMapper";
import { DashboardConfigWidgetCarousselFormValues, mapCarousselStateWidgetToCarousselWidget, mapCarousselWidgetToCarousselStateWidget } from "./widgets/carousselWidgetMapper";
import { DashboardConfigWidgetChallengeFormValues, mapChallengeStateWidgetToChallengeWidget, mapChallengeWidgetToChallengeStateWidget } from "./widgets/challengeWidgetMapper";
import { DashboardConfigWidgetCommunityFormValues, mapCommunityStateWidgetToCommunityWidget, mapCommunityWidgetToCommunityStateWidget } from "./widgets/communityWidgetMapper";
import { DashboardConfigWidgetContentFormValues, mapContentStateWidgetToContentWidget, mapContentWidgetToContentStateWidget } from "./widgets/contentWidgetMapper";
import { DashboardConfigWidgetEventFormValues, mapEventStateWidgetToEventWidget, mapEventWidgetToEventStateWidget } from "./widgets/eventWidgetMapper";
import { DashboardConfigWidgetGenericFormValues, mapGenericStateWidgetToGenericWidget, mapGenericWidgetToGenericStateWidget } from "./widgets/genericWidgetMapper";
import { DashboardConfigWidgetPopupFormValues, mapPopupStateWidgetToPopupWidget, mapPopupWidgetToPopupStateWidget } from "./widgets/popupWidgetMapper";
import { DashboardConfigWidgetSocialFormValues, mapSocialStateWidgetToSocialWidget, mapSocialWidgetToSocialStateWidget } from "./widgets/socialWidgetMapper";
import { DashboardConfigWidgetStatisticsFormValues, mapStatisticsStateWidgetToStatisticsWidget, mapStatisticsWidgetToStatisticsStateWidget } from "./widgets/statisticsWidgetMapper";
import { DashboardConfigWidgetVideoFormValues, mapVideoStateWidgetToVideoWidget, mapVideoWidgetToVideoStateWidget } from "./widgets/videoWidgetMapper";

export const filterEmptyWidgets = (widget: DashboardConfigWidget) => {
    if (!widget) {
        return false;
    }
    const { desktop, tablet, mobile } = widget;
    return !(!mobile && !desktop && !tablet);
};

export const mapDashboardsOverview = (originals: DashboardConfig[]): DashboardTableData[] => {
    return originals.map(dashboard => {
        const { id, place, publishOn, expiresOn, desktopWidths, mobileWidths, tabletWidths, sequence } = dashboard;

        const desktop = desktopWidths && desktopWidths.length;
        const tablet = tabletWidths && tabletWidths.length;
        const mobile = mobileWidths && mobileWidths.length;

        let statusType = "active";

        if (expiresOn && expiresOn < moment().valueOf()) {
            statusType = "expired";
        } else if (publishOn && publishOn > moment().valueOf()) {
            statusType = "notPublishedYet";
        }

        return {
            id,
            place,
            numberOfColumns: {
                desktop: desktop || 0,
                tablet: tablet || 0,
                mobile: mobile || 0
            },
            sequence,
            dashboardsAmount: 1,
            status: !((expiresOn && expiresOn < moment().valueOf()) || (publishOn && publishOn > moment().valueOf())) || false,
            statusType
        };
    });
};

export const deviceSettingToWidgetRendering = (widget: WidgetsFormValuesUnion, deviceSetting: DeviceSetting): DashboardConfigWidgetRendering | null => {
    let columnIndex = 0;
    let sequence = 0;
    let visible: boolean = true;

    if (!deviceSetting || !deviceSetting.columns) {
        return null;
    }

    const widthSettingColumn = deviceSetting.columns.find((column, index) => {
        if (!column.widgets) {
            return false;
        }

        return column.widgets.find((widthSettingWidget, widgetIndex) => {
            if (!widthSettingWidget || widthSettingWidget.id !== widget.id) {
                return false;
            }

            columnIndex = index;
            sequence = widgetIndex;
            visible = Boolean(widthSettingWidget.visible);

            return true;
        });
    });

    if (!widthSettingColumn) {
        return null;
    }

    return {
        column: columnIndex,
        sequence,
        visible
    };
};

export const mapWidgetFormValuesToWidget = (widget: WidgetsFormValuesUnion, devices: DeviceSettings): Scalars["DashboardConfigWidgetInput"] => {
    const mappedDevices = {
        desktop: deviceSettingToWidgetRendering(widget, devices.desktop),
        tablet: deviceSettingToWidgetRendering(widget, devices.tablet),
        mobile: deviceSettingToWidgetRendering(widget, devices.mobile)
    };

    if (objectTypeCheck<DashboardConfigWidgetChallengeFormValues>(widget, "challenge")) {
        return {
            ...mapChallengeStateWidgetToChallengeWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetEventFormValues>(widget, "event")) {
        return {
            ...mapEventStateWidgetToEventWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetContentFormValues>(widget, "content")) {
        return {
            ...mapContentStateWidgetToContentWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetGenericFormValues>(widget, "generic")) {
        return {
            ...mapGenericStateWidgetToGenericWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetSocialFormValues>(widget, "social")) {
        return {
            ...mapSocialStateWidgetToSocialWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetCarousselFormValues>(widget, "caroussel")) {
        return {
            ...mapCarousselStateWidgetToCarousselWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetVideoFormValues>(widget, "video")) {
        return {
            ...mapVideoStateWidgetToVideoWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetActivityFormValues>(widget, "activity")) {
        return {
            ...mapActivityStateWidgetToActivityWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetChallengeVisualisationFormValues>(widget, "challengeVisualisation")) {
        return {
            ...mapChallengeVisualisationStateWidgetToChallengeVisualisationWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetStatisticsFormValues>(widget, "statistics")) {
        return {
            ...mapStatisticsStateWidgetToStatisticsWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetCommunityFormValues>(widget, "community")) {
        return {
            ...mapCommunityStateWidgetToCommunityWidget(widget),
            ...mappedDevices
        };
    }

    if (objectTypeCheck<DashboardConfigWidgetPopupFormValues>(widget, "popup")) {
        return {
            ...mapPopupStateWidgetToPopupWidget(widget),
            ...mappedDevices
        };
    }

    throw Error("Unknown type, should not save!");
};

export const dashboardWidgetToWidgetsFormValuesUnion = (widget: DashboardConfigWidget): WidgetsFormValuesUnion => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { desktop, mobile, tablet, ...fields } = widget; // added because type doesnt include typename;

    const widgetFields = {
        ...fields,
        roles: fields.roles || [] // prevent empty single initialvalue
    };

    if (objectTypeCheck<DashboardConfigWidgetChallenge>(widget, "challenge")) {
        return mapChallengeWidgetToChallengeStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetEvent>(widget, "event")) {
        return mapEventWidgetToEventStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetContent>(widget, "content")) {
        return mapContentWidgetToContentStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetSocial>(widget, "social")) {
        return mapSocialWidgetToSocialStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetCaroussel>(widget, "caroussel")) {
        return mapCarousselWidgetToCarousselStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetVideo>(widget, "video")) {
        return mapVideoWidgetToVideoStateWidget(widgetFields as DashboardConfigWidgetVideo);
    }

    if (objectTypeCheck<DashboardConfigWidgetActivity>(widget, "activity")) {
        return mapActivityWidgetToActivityStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetChallengeVisualisation>(widget, "challengeVisualisation")) {
        return mapChallengeVisualisationWidgetToChallengeVisualisationStateWidget(widgetFields as DashboardConfigWidgetChallengeVisualisation);
    }

    if (objectTypeCheck<DashboardConfigWidgetGeneric>(widget, "generic")) {
        return mapGenericWidgetToGenericStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetStatistics>(widget, "statistics")) {
        return mapStatisticsWidgetToStatisticsStateWidget(widgetFields as DashboardConfigWidgetStatistics);
    }

    if (objectTypeCheck<DashboardConfigWidgetCommunity>(widget, "community")) {
        return mapCommunityWidgetToCommunityStateWidget(widgetFields);
    }

    if (objectTypeCheck<DashboardConfigWidgetPopup>(widget, "popup")) {
        return mapPopupWidgetToPopupStateWidget(widgetFields as DashboardConfigWidgetPopup);
    }

    throw Error("Type not found");
};

export const formValuesToDashboard = (dashboard: DashboardFormValues): AddDashboardConfigurationMutationVariables & { id?: string; } => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { widgets, publishOn, expiresOn, devices, id, ...rest } = dashboard;

    if (!devices) {
        throw Error("No width settings found");
    }

    const widgetIds = Object.keys(widgets);

    return {
        ...rest,
        desktopWidths: devices.desktop.widths,
        tabletWidths: devices.tablet.widths,
        mobileWidths: devices.mobile.widths,
        publishOn: formatDate(publishOn),
        expiresOn: formatDate(expiresOn, true),
        widgets: widgetIds.map(widgetId => {
            const widget = widgets[widgetId];
            return mapWidgetFormValuesToWidget({
                ...widget,
                id: widgetId
            }, devices);
        }).filter(filterEmptyWidgets)
    };
};

const sortWidgets = (first, second, type): number => {
    if (!first || !second) {
        return 0;
    }

    const firstHasOrder = first.id && first[type];
    const secondHasOrder = second.id && second[type];

    if (
        !firstHasOrder || isNaN(firstHasOrder.sequence)
        || !secondHasOrder || isNaN(secondHasOrder.sequence)
    ) {
        return 0;
    }

    return firstHasOrder.sequence >= secondHasOrder.sequence ? 1 : -1;
};

export const DashboardToWidthSettingColumn = (width: number, type: ViewType, columnIndex: number, widgets: DashboardConfigWidget[] | null | undefined): Column => {
    if (!widgets) {
        return {
            widgets: []
        };
    }

    const widgetsWithType = widgets.filter(widget => {
        const widgetType = widget[type];

        if (!widgetType) {
            return false;
        }

        return widgetType.column === columnIndex;
    });

    const convertedWidgets = widgetsWithType
        .sort((a, b) => sortWidgets(a, b, type))
        .map(widget => ({
            id: widget.id,
            visible: widget[type]?.visible || false
        }));

    return { widgets: convertedWidgets };
};

export const DashboardToDeviceSetting = (dashboard: DashboardConfig, type: ViewType): DeviceSetting => {
    const widths = (dashboard[`${type}Widths`] || []).filter(val => val !== null) as number[];
    const { widgets } = dashboard;

    let newWidths = widths;
    if (type === "tablet" && (widths.length === 3 || widths.includes[2])) {
        newWidths = [1, 1];
    }
    if (type === "mobile" && widths.length > 1) {
        newWidths = [1];
    }

    return {
        widths: newWidths,
        columns: (widths).map((width, index) => DashboardToWidthSettingColumn(width, type, index, widgets))
    };
};

export const dashboardToFormValues = (dashboard: GetDashboardsQuery["dashboardConfigurations"][0]): DashboardFormValues => {
    const {
        place,
        widgets,
        publishOn,
        sequence,
        expiresOn
    } = dashboard;

    return {
        place,
        devices: {
            desktop: DashboardToDeviceSetting(dashboard as DashboardConfig, "desktop"),
            tablet: DashboardToDeviceSetting(dashboard as DashboardConfig, "tablet"),
            mobile: DashboardToDeviceSetting(dashboard as DashboardConfig, "mobile")
        },
        publishOn: moment(publishOn || undefined),
        expiresOn: moment(expiresOn || undefined),
        sequence: sequence || 0,
        widgets: ((widgets as DashboardConfigWidget[])
            ?.filter(getOutFalseValues) || [])
            .filter(filterEmptyWidgets)
            .reduce(
                (widgetObject: WidgetObject, widget: DashboardConfigWidget) => ({
                    ...widgetObject,
                    [widget.id]: dashboardWidgetToWidgetsFormValuesUnion(widget)
                }),
                {}
            )
    };
};
