/* eslint-disable react/prop-types */
import { Badge, Breadcrumb, Button, Form, Popconfirm, Spin, message } from "antd";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SpinStyle } from "@components/style/globalStyles";
import { ADD_GALLERY_TABS, AddGalleriesPageProps } from "@pages/addGalleriesPage/addGalleriesPageContainer";
import { AddGalleriesPageStyle } from "@pages/addGalleriesPage/addGalleriesPageStyle";

import { getFrontendRoles } from "@utils/applicationUtils";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { GalleryImageFormValues, formValuesToGallery, formValuesToGalleryImage, galleryToFormValues } from "@utils/mappers/galleryMapper";

import { CommentCardContainer } from "@components/comments/commentCard/commentCardContainer";
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyGallery } from "@utils/emptyItems/emptyGallery";
import { getText } from "@utils/getText";
import { translateTags } from "@utils/mappers/tagMapper";
import { useForm } from "antd/lib/form/Form";
import { Link } from "react-router-dom";
import { ImageComments } from "./comments/imageComments";
import { GalleryContentCard, GalleryContentFormValues } from "./general/galleryContentCard";
import { GalleryImageOverviewCardContainer } from "./general/galleryImageOverviewCardContainer";
import { GallerySettingsCard, GallerySettingsFormValues } from "./general/gallerySettingsCard";

export type ErrorObj = object;

export type GalleryErrors = {
    title: ErrorObj;
    preview: ErrorObj;
    description: ErrorObj;
    type: ErrorObj;
    expiresOn: ErrorObj;
    publishOn: ErrorObj;
    imageUrl: ErrorObj;
    tags: ErrorObj;
    isDraft: ErrorObj;
};

export interface AddGalleriesPageState {
    selectedLanguage: string;
    type?: string;
    editContentIndex?: number;
    unsaved: boolean;
    errors?: GalleryErrors;
}

export type GalleryFormValues = { id?: string; }
    & GalleryContentFormValues
    & GallerySettingsFormValues
    & { images: GalleryImageFormValues[]; };

const galleryErrorMap = (tab: string, fieldErrors: Record<string, string>) => {
    switch (tab) {
        case ADD_GALLERY_TABS.general:
            return Boolean(
                fieldErrors.title
                    || fieldErrors.preview
                    || fieldErrors.description
                    || fieldErrors.type
                    || fieldErrors.expiresOn
                    || fieldErrors.publishOn
                    || fieldErrors.tags
                    || fieldErrors.isDraft
            );
        case ADD_GALLERY_TABS.images:
            return Boolean(fieldErrors.images);
        default:
            return Boolean(fieldErrors[tab]);
    }
};

export const AddGalleriesPage: React.FC<AddGalleriesPageProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();

    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setError] = useErrors();
    const [enabledSave, setEnabledSave] = React.useState<boolean>(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const {
        location, galleriesData, applicationsData, history, tagsData, match,
        updateGallery, addGallery, removeGallery, removeGalleryImage, addGalleryImage, updateGalleryImage
    } = props;
    const { galleries, loading } = galleriesData || {};
    const { applications, loading: applicationsLoading } = applicationsData || {};
    const { galleryTags, loading: tagsLoading } = tagsData || {};
    const { id, tab } = match.params;

    const tabs: string[] = [
        ADD_GALLERY_TABS.general,
        ADD_GALLERY_TABS.images,
        ADD_GALLERY_TABS.comments
    ];

    const currentTab = tabs.indexOf(tab || ADD_GALLERY_TABS.general);

    const galleryTabs = tabs.map(t => <Badge dot={galleryErrorMap(t, fieldErrors)}><FormattedMessage id={t} /></Badge>);

    React.useEffect(() => {
        if (location.state && location.state.import) {
            setEnabledSave(true);
        }
    });

    const translatedTags = React.useMemo(
        () => {
            if (tagsLoading || !galleryTags || !galleryTags.length) {
                return [];
            }
            return translateTags(galleryTags);
        },
        [galleryTags, tagsLoading]
    );

    const defaultValues = React.useMemo(
        () => createDefaultValues(galleryToFormValues, emptyGallery(), location.state?.import, loading, galleries?.[0]),
        [galleries, loading]
    );

    if (loading || applicationsLoading || tagsLoading) {
        return (
            <SpinStyle>
                <Spin />
            </SpinStyle>
        );
    }

    if ((!galleriesData?.galleries?.length && match.params.id) || !defaultValues) {
        props.history.push("/404");
    }

    const galleriesForm = form.getFieldsValue();

    const onBlur = () => {
        if (!enabledSave) {
            setEnabledSave(true);
        }
    };

    const defaultProps = {
        defaultValues,
        disabled: false,
        changeSelectedLanguage,
        onBlur
    };

    const handleSuccess = (label: string) => {
        message.success(intl.formatMessage({ id: label }));
        setEnabledSave(false);
    };

    const onSubmit = async (values: GalleryFormValues) => {
        setIsSaving(true);

        try {
            if (id) {
                const newImages = values.images.filter(image => !image.id);
                const existingImages = values.images.filter(image => image.id);
                const removedValues = defaultValues.images.filter(image => !existingImages.map(i => i.id).includes(image.id));

                existingImages.forEach(async i => {
                    try {
                        await updateGalleryImage({ variables: { ...formValuesToGalleryImage(i, id || ""), id: i.id } });
                    } catch (e) {
                        console.error(e);
                    }
                });

                newImages.forEach(async i => {
                    try {
                        await addGalleryImage({ variables: { ...formValuesToGalleryImage(i, id || "") } });
                    } catch (e) {
                        console.error(e);
                    }
                });

                removedValues.forEach(async i => {
                    try {
                        await removeGalleryImage({ variables: { id: i.id } });
                    } catch (e) {
                        console.error(e);
                    }
                });

                const response = await updateGallery({ variables: { ...formValuesToGallery(values), id } });
                if (!response || !response.data) {
                    throw Error("No response");
                }

                handleSuccess("gallery.galleryUpdated");
            } else {
                const response = await addGallery({ variables: { ...formValuesToGallery(values) } });
                if (!response || !response.data) {
                    throw Error("No response");
                }

                if (response?.data?.addGallery?.id && values.images) {
                    values.images.forEach(async i => {
                        try {
                            await addGalleryImage({ variables: { ...formValuesToGalleryImage(i, response?.data?.addGallery?.id || "") } });
                        } catch (e) {
                            console.error(e);
                        }
                    });
                }

                handleSuccess("gallery.galleryAdded");
                history.push(`/gallery/galleries/edit/${response.data.addGallery.id}/general`);
            }
        } catch (errors) {
            setError(errors);
            const errMsg = id ? "gallery.galleryUpdateFailed" : "gallery.galleryAddFailed";
            message.error(intl.formatMessage({ id: errMsg }));
        }

        setIsSaving(false);
    };

    const changeSelectedTab = (index: number | string) => {
        history.push(
            `/gallery/galleries/${match.params.id
                ? `edit/${match.params.id}`
                : "add"
            }/${tabs[index]}`
        );
    };
    const roles = getFrontendRoles(applications);

    const onDelete = async () => {
        await removeGallery({ variables: { id } });
        history.push("/gallery/galleries");
        message.success("gallery.deleted");
    };

    const generalTabActive = match.params.tab === ADD_GALLERY_TABS.general;
    const imagesTabActive = match.params.tab === ADD_GALLERY_TABS.images;
    const commentsTabActive = match.params.tab === ADD_GALLERY_TABS.comments;

    return (
        <AddGalleriesPageStyle>
            <Form
                form={form}
                initialValues={defaultValues}
                onFinish={onSubmit}
                onFinishFailed={setError}
            >
                <HeaderContent>
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/gallery/galleries">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {getText(galleriesForm?.title || defaultValues?.title, selectedLanguage)}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                            {enabledSave && <UnsavedChanges key="warning" />}
                            <Button
                                key="button1"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            {id && (
                                <Popconfirm
                                    cancelText="No"
                                    okText="Yes"
                                    placement="bottomRight"
                                    title={<FormattedMessage id="deleteConfirm" />}
                                    onConfirm={onDelete}
                                >
                                    <Button
                                        className="headerButton"
                                        type="ghost"
                                    >
                                        <FormattedMessage id="content.delete" />
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                    </HeaderActions>
                    <Flex justifyContent="space-between">
                        <ContentTabs
                            currentTab={currentTab}
                            handleTabSelectedLanguage={changeSelectedTab}
                            tabs={galleryTabs}
                        />
                    </Flex>
                </HeaderContent>
                <GalleryContentCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    form={form}
                    hidden={!generalTabActive}
                    languageErrors={errorsToLanguageErrors({
                        title: fieldErrors.title,
                        preview: fieldErrors.preview,
                        description: fieldErrors.description
                    })}
                />
                <GallerySettingsCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    form={form}
                    galleryTags={translatedTags}
                    hidden={!generalTabActive}
                    languageErrors={errorsToLanguageErrors({
                        type: fieldErrors.type,
                        expiresOn: fieldErrors.expiresOn,
                        publishOn: fieldErrors.publishOn,
                        tags: fieldErrors.tags,
                        isDraft: fieldErrors.isDraft
                    })}
                    roles={roles}
                />
                <GalleryImageOverviewCardContainer
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    errors={fieldErrors.images}
                    form={form}
                    hidden={!imagesTabActive}
                />
                {commentsTabActive && id && (
                    <CommentCardContainer
                        type="gallery"
                        typeId={id}
                    />
                )}
                {commentsTabActive && id && <ImageComments form={form} />}
            </Form>
        </AddGalleriesPageStyle>
    );
};
