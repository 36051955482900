import { compose } from "redux";
import * as React from "react";
import { withGetTranslations, GetTranslationsProps, Translation } from "../../../../graphql2/types";
import { ImportTranslationsModal } from "./importTranslationsModal";

export interface ImportTranslationsModalContainerProps {
    keys: string[];
    importedTranslations: any[];
    uploading: boolean;
    onAbort(): void;
    onSubmit(updateExisting: boolean, existingTranslations: Translation[], duplicatesToKeep?: { [key: string]: number }): void;
}

export const withGenericTranslations = withGetTranslations({
    options(ownProps: ImportTranslationsModalProps) {
        return {
            variables: {
                filter: {
                    includeDefault: true,
                    projectId: "",
                    default: true,
                    keys: ownProps.keys
                }
            }
        };
    },
    skip(ownProps: ImportTranslationsModalProps) {
        return Boolean(!ownProps.keys || !ownProps.keys.length);
    }
});

export const ImportTranslationsModalContainer = compose<React.ComponentClass<ImportTranslationsModalContainerProps>>(
    withGenericTranslations
)(ImportTranslationsModal);

export type ImportTranslationsModalProps =
    ImportTranslationsModalContainerProps
    & MaybeElements<GetTranslationsProps>;
