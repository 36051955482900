import { TableContainer } from "@components/table/tableContainer";
import { TagsTable } from "@components/tags/tagsTable";
import { HeaderContent } from "@components/headerContent/headerContent";
import { BaseTag, BaseTagsPageProps } from "@pages/tagsPage/baseTagsPageContainer";
import { readFile } from "@utils/readFile";
import { Alert, Button, Card, Upload } from "antd";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Flex } from "@components/flex/flex";
import { getDataFromProperties } from "@utils/getDataFromProperties";
import { translateTags } from "@utils/mappers/tagMapper";

export function TagsPage(props: BaseTagsPageProps) {
    const { path, callbackUrl, urlTarget, exportContainer } = props;
    const [uploadError, setUploadError] = useState("");

    const data = getDataFromProperties(props, path);

    const translatedTags = React.useMemo(
        () => translateTags(data || []),
        [data]
    );

    const isLoading = getDataFromProperties(props, path.slice(0, -1)).loading;

    async function handleUpload(info: { file: File }) {
        try {
            const importData = await readFile<BaseTag>(info.file);
            props.history.push(callbackUrl, { import: importData });
        } catch (error) {
            setUploadError("upload.tryAgain");
        }
    }

    async function removeTag(id: string) {
        await props.removeTag({ variables: { id } });
    }

    const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 });

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage id="contentTags.overview" tagName="h1" />
                    <div>
                        <Upload accept="application/json" customRequest={handleUpload}>
                            <Button className="headerButton" type="primary">
                                <FormattedMessage id="contentTags.import" />
                            </Button>
                        </Upload>
                        <Button
                            onClick={() => props.history.push(callbackUrl)}
                            className="headerButton"
                            type="primary"
                        >
                            <FormattedMessage id="contentTags.addContentTag" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                {uploadError && <Alert type="error" message={<FormattedMessage id={uploadError} />} />}
                <TableContainer
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: translatedTags.length
                    }}
                    onChange={(newPagination) => setPagination({
                        current: newPagination.current !== pagination.current ? newPagination.current : 1,
                        pageSize: newPagination.pageSize
                    })}
                    loading={isLoading}
                    TableComponent={TagsTable}
                    dataSource={translatedTags}
                    handlers={{
                        removeTag,
                        buildUrl: (id: string) => `/${urlTarget}/tags/edit/${id}/general`
                    }}
                    extraComponents={{ exportContainer }}
                />
            </Card>
        </div>
    );
}
