import styled from "styled-components";

export const DisableOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    cursor: not-allowed;
`;

export const ImageUploadStyle = styled.div`
    text-align: center;
    position: relative;

    .ant-btn {
        margin-top: 20px;
    }
`;

export const ImageCropContainerStyle = styled.div`
    background-color: white;
    position: relative;
    width: 100%;
    height: 250px;
`;

export const ImageCropControls = styled.div`
    width: 100%;
    display: flex;
`;

export const ImageCropControl = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    .ant-slider {
        width: 100%;
        margin-left: 10px;
    }
`;

export const UploadedImg = styled.img`
    max-width: 100%;
`;
