import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { withRemoveContentTagMutation } from "@pages/tagsPage/contentTagsPageContainer";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getContentTags } from "@graphql2/graphql_op";
import { withGetContentTags, withAddContentTag, withUpdateContentTag } from "../../graphql2/types";

export const withContentTags = withGetContentTags({
    skip: (props: AddTagsRouteProps) => {
        return !props.match.params.id;
    }
});

export const AddContentTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddContentTag({
        name: "addTag",
        options: {
            update: cleanCache([getContentTags])
        }
    }),
    withUpdateTag: withUpdateContentTag({
        name: "updateTag"
    }),
    withTags: withContentTags,
    withRemoveTag: withRemoveContentTagMutation,
    callbackUrl: "/content/tags",
    path: ["data", "contentTags"]
});
