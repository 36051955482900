import { ExportOutlined } from "@ant-design/icons";
import { ProviderActivitiesLink, Statistic, StatisticTitle, StatisticValue, Statistics, UserActivitiesCardStyle } from "@components/users/userActivitiesCard/userActivitiesCardStyle";
import { Avatar, Card, DatePicker, List, Spin } from "antd";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { UserActivitiesCardProps } from "./userActivitiesCardContainer";

export const UserActivitiesCard: React.FC<UserActivitiesCardProps> = (props: UserActivitiesCardProps) => {
    const { userActivities: { userActivities, loading: loadingUserActivities }, date } = props;
    const { url } = useRouteMatch();

    if (loadingUserActivities) {
        return (
            <Card title={<FormattedMessage id="activities" />}>
                <UserActivitiesCardStyle>
                    <Spin />
                </UserActivitiesCardStyle>
            </Card>
        );
    }

    if (!userActivities || !userActivities.length) {
        return (
            <Card title={<FormattedMessage id="activities" />}>
                <UserActivitiesCardStyle>
                    <FormattedMessage id="activities.noActivities" />
                </UserActivitiesCardStyle>
            </Card>
        );
    }

    const activity = userActivities[0];
    const dailyActvityDurationHours = Math.round(activity.distance / 100) / 10;

    if (!activity) {
        return null;
    }

    return (
        <Card
            extra={(
                <DatePicker
                    clearIcon={false}
                    value={date}
                    onChange={d => props.setLocalState({ date: d || undefined })}
                />
            )}
            title={<FormattedMessage id="activities" />}
        >
            <UserActivitiesCardStyle>
                <Statistics>
                    <Statistic>
                        <StatisticTitle>
                            <FormattedMessage id="activities.distance" />
                        </StatisticTitle>
                        <StatisticValue>
                            {dailyActvityDurationHours}
km
                        </StatisticValue>
                    </Statistic>
                    <Statistic>
                        <StatisticTitle>
                            <FormattedMessage id="activities.duration" />
                        </StatisticTitle>
                        <StatisticValue>{new Date(activity.duration * 1000).toISOString().substr(11, 8)}</StatisticValue>
                    </Statistic>
                    <Statistic>
                        <StatisticTitle>
                            <FormattedMessage id="activities.workouts" />
                        </StatisticTitle>
                        <StatisticValue>{activity.workouts.length}</StatisticValue>
                    </Statistic>
                    <Statistic>
                        <StatisticTitle>
                            <FormattedMessage id="activities.steps" />
                        </StatisticTitle>
                        <StatisticValue>{activity.steps}</StatisticValue>
                    </Statistic>
                </Statistics>
                <List
                    dataSource={userActivities[0].workouts}
                    itemLayout="horizontal"
                    renderItem={item => {
                        const activityTime = moment(item.start).format("DD/MM/YYYY HH:mm:ss");
                        const activityDistanceFloatKm = Math.round(item.distance / 100) / 10;
                        const actvityDurationHours = Math.round(item.duration) / 60 / 60;

                        return (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={(
                                        <Avatar
                                            shape="square"
                                            src={item.providers[0] ? `https://res.cloudinary.com/energylab/image/upload/v1567517523/generic-v2/icons/${item.providers[0]}.png` : undefined}
                                        />
                                    )}
                                    description={`${activityTime} - ${activityDistanceFloatKm}km - ${actvityDurationHours}h`}
                                    title={`${item.activity}${item.detailedActivity && ` - ${item.detailedActivity}`}`}
                                />
                            </List.Item>
                        );
                    }}
                />
                <ProviderActivitiesLink >
                    <Link to={`${url}/providerActivities`}>
                        <FormattedMessage id="providerActivities.goto" />
                        <ExportOutlined />
                    </Link>
                </ProviderActivitiesLink>
            </UserActivitiesCardStyle>
        </Card>
    );
};
