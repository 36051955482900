import { ChallengeVisualisationWidgetProps } from "@components/dashboard/widgets/challengeVisualisationWidget/challengeVisualisationWidgetContainer";
import { requiredrule } from "@components/form/requiredRule";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { Spin, Select, Alert } from "antd";
import { GetChallengeTitlesQuery } from "@graphql2/types";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { FixedChallengesFieldContainer } from "../challengeWidget/fixedChallengesField/fixedChallengesFieldContainer";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { GenericWidgetStyle } from "../genericWidget/genericWidgetStyle";
import { WidgetConfigStyle } from "../widgetStyle";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { SelectField } from "../../../field/selectField";

export const ChallengeVisualisationWidget: React.FC<ChallengeVisualisationWidgetProps> = (props) => {
    const { form, widgetId, challenges, applications, challengeId } = props;

    let visualisations: GetChallengeTitlesQuery["challenges"][0]["visualisations"] = [];

    const firstChallenge = challenges && challenges.challenges && challenges.challenges.length ? challenges.challenges[0] : null;
    if (firstChallenge && firstChallenge.visualisations) {
        visualisations = firstChallenge.visualisations.filter(getOutFalseValues);
    }

    const setChallenge = (id: string) => form.setFields([
        {
            name: ["widgets", widgetId, "visualisationId"],
            value: undefined
        },
        {
            name: ["widgets", widgetId, "challengeId"],
            value: id
        }
    ]);

    return (
        <GenericWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <FixedChallengesFieldContainer
                    challengeOptions={{
                        id: widgetId,
                        form
                    }}
                    singleMode
                    forceSingle
                    onlyChallengesWithVisualisations
                    onChange={setChallenge}
                />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
                {!(challenges && challenges.loading) && (
                    <SelectField
                        rules={[requiredrule]}
                        name={["widgets", widgetId, "visualisationId"]}
                        label={<FormattedMessage id="challengeVisualisationWidget.visualisation" />}
                        showSearch
                        disabled={!challengeId}
                        optionFilterProp="children"
                    >
                        {visualisations && visualisations
                            .filter(visualisation => visualisation && visualisation.type === "timeline")
                            .map((v) => (v && v.id && v.type && (
                                <Select.Option value={v.id} key={v.id}>
                                    {`${v.type}_${v.id}`}
                                </Select.Option>
                            )))}
                    </SelectField>
                )}
                {(challenges && challenges.loading) && <Spin size="small" />}
                {!visualisations && (
                    <Alert
                        type="error"
                        message={<FormattedMessage id="challengeVisualisationWidget.challengeNotFoundOrNoVisualisations" />}
                    />
                )}
            </WidgetConfigStyle>
        </GenericWidgetStyle>
    );
};
