import { ContentHeader } from "@components/contentHeader/contentHeader";
import { ApplicationFragment } from "@graphql2/types";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Col, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChallengeSettingsFormValues, SharedProps } from "../general/sections/challengeBasicSettingsCard";
import { AccessibilitySettings } from "./sections/AccessibilitySettings";
import { ExtraInfoVisibilitySettings } from "./sections/ExtraInfoVisibilitySettings";
import { PlacementInPlatformSettings } from "./sections/PlacementInPlatformSettings";
import { SuperDaysSettings } from "./sections/SuperDaysSettings";

export interface ChallengeAdvancedSettingsCardProps {
    form: FormInstance;
    defaultValues: Partial<ChallengeSettingsFormValues>;
    disabled?: boolean;
    hidden?: boolean;
    languageErrors?: LanguageErrors;
    roles?: ApplicationFragment["roles"];
    onBlur(): void;
}

export const ChallengeAdvancedTab: React.FC<ChallengeAdvancedSettingsCardProps> = (props) => {
    const {
        form,
        hidden,
        disabled,
        onBlur,
        roles,
        languageErrors
    } = props;

    const sharedProps: SharedProps = {
        disabled,
        onChange: onBlur
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="edit.advancedChallengeSettings" />}
            />
            <Row
                align="middle"
                gutter={48}
            >
                <Col span={12}>
                    <PlacementInPlatformSettings {...sharedProps} />
                    <ExtraInfoVisibilitySettings {...sharedProps} />
                </Col>
                <Col span={12}>
                    <AccessibilitySettings
                        roles={roles}
                        {...sharedProps}
                    />
                </Col>
            </Row>
            <Row style={{ flexDirection: "column", maxWidth: "685px" }}>
                <SuperDaysSettings
                    form={form}
                    languageErrors={languageErrors}
                    {...sharedProps}
                />
            </Row>
        </Card>
    );
};
