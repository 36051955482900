import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { MdeField } from "@components/field/mdeField";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { PrizeConfigFields } from "@pages/addChallengesPage/tabs/prizes/editChallengePrize/editChallengePrizeStyle";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { PrizeLanguageFieldProps } from "@utils/languageFieldProps";
import { storeImage } from "@utils/storeImage";
import { LanguageObject } from "@utils/toLanguageObject";
import { Button, Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../context/enabledLangContext/context";

export interface EditChallengePrizeProps {
    prize: ChallengePrize;
    index: number;
    disabled?: boolean;
    form: FormInstance;
    hidden?: boolean;
    hasError?: ErrorObj;
    activeLanguage: string;
    languageErrors: LanguageErrors;
    back(): void;
    onBlur(): void;
    changeSelectedLanguage(languageCode: string): void;
}

export interface ChallengePrize {
    title: LanguageObject;
    description: LanguageObject;
    imageUrl: string;
}

export interface ChallengeRewards {
    credits: number;
    prizes: ChallengePrize[];
}

export interface ChallengePrizesFormValues {
    rewards?: ChallengeRewards;
}

const prizeTitleField = (props: PrizeLanguageFieldProps) => (
    <Field
        key={`${props.index}${props.language}`}
        hidden={props.hidden}
        id={`rewards.prizes.${props.index}.title.${props.language}`}
        initialValue={props.defaultValues.title ? props.defaultValues.title[props.language] : ""}
        label={<FormattedMessage id="title" />}
        name={["rewards", "prizes", (props.index || 0), "title", props.language]}
        rules={[{
            required: true,
            message: <FormattedMessage id="form.isrequired" />
        }]}
        {...props.sharedProps}
    />
);

const prizeDescriptionField = (props: PrizeLanguageFieldProps, form: FormInstance) => (
    <MdeField
        key={`${props.index}${props.language}`}
        compatibilityMode
        form={form}
        formSetData={[
            {
                fields: [["rewards", "prizes"], ["description", props.language]],
                index: props.index || 0
            }
        ]}
        hidden={props.hidden}
        id={`rewards.prizes.${props.index}.description.${props.language}`}
        initialValue={props.defaultValues.description ? props.defaultValues.description[props.language] : ""}
        label={<FormattedMessage id="detail" />}
        name={["rewards", "prizes", (props.index || 0), "description", props.language]}
        rules={[{
            required: true,
            message: <FormattedMessage id="form.isrequired" />
        }]}
        {...props.sharedProps}
    />
);

export const EditChallengePrize: React.FC<EditChallengePrizeProps> = (props) => {
    const { prize, index, disabled, form, hidden, activeLanguage, languageErrors, onBlur, changeSelectedLanguage, back } = props;

    const { enabledLanguages } = useEnabledLang();

    const sharedProps = {
        disabled,
        onBlur
    };

    const languageFieldProps: PrizeLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                form,
                index,
                defaultValues: prize,
                sharedProps,
                language: language.code,
                hidden: language.code !== activeLanguage
            }
        )), [enabledLanguages, activeLanguage]
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                rightFields={[
                    <Button
                        key="back"
                        onClick={back}
                    >
                        <FormattedMessage id="back" />
                    </Button>
                ]}
                title={<FormattedMessage id="configPrize" />}
            />
            <FileDragAndDropContainer
                defaultImage={prize.imageUrl}
                onChange={async (image): Promise<void> => storeImage(sharedProps.onBlur, form, ["rewards", "prizes", index, "imageUrl"], image, undefined, { fields: [["rewards", "prizes"], ["imageUrl"]], index })}
            />
            <Field
                hidden
                id="imageUrl"
                initialValue={prize.imageUrl}
                name={["rewards", "prizes", index, "imageUrl"]}
                {...sharedProps}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />
            <PrizeConfigFields>
                {languageFieldProps.map(prizeTitleField)}
                {languageFieldProps.map(item => prizeDescriptionField(item, form))}
            </PrizeConfigFields>
        </Card>
    );
};
