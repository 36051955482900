import { Card, Form } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { CkeField } from "@components/field/ckeField";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";

import { languages } from "@pages/translationsPage/constants/languages";

import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { EventLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { FormInstance } from "antd/lib/form";

export interface EventContentFormValues {
    title: LanguageObject;
    description: LanguageObject;
}

export interface EventContentCardProps {
    form: FormInstance;
    hidden?: boolean;
    defaultValues: Partial<EventContentFormValues>;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    isChronoRaceOnNormalAdmin: boolean;
    onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
    projectId: string;
}

const titleField = (props: EventLanguageFieldProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden || props.isChronoRaceOnNormalAdmin}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

const descriptionField = (form: FormInstance) => (props: EventLanguageFieldProps) => (
    <Form.Item
        noStyle
        shouldUpdate={(pre, cur) => pre?.description?.[props.language] !== cur?.description?.[props.language]}
    >
        {() => {
            let descriptionFieldPath: (string|number)[] = ["description", props.language];
            let initialDescriptionObject = props.defaultValues.description;
            const indexOfProject = props.defaultValues?.projects?.findIndex(p => p.projectId === props.projectId);
            if (props.isChronoRaceOnNormalAdmin && indexOfProject !== undefined && indexOfProject !== -1) {
                descriptionFieldPath = ["projects", indexOfProject, "description", props.language];
                initialDescriptionObject = props.defaultValues?.projects?.[indexOfProject]?.description;
            }
            return (
                <CkeField
                    key={`description.${props.language}`}
                    disabled={props.sharedProps.disabled}
                    form={props.form}
                    hasError={form.getFieldsError(descriptionFieldPath).find(val => Boolean(val.errors.length))}
                    hidden={props.hidden}
                    id={`description.${props.language}`}
                    initialValue={initialDescriptionObject ? initialDescriptionObject[props.language] : ""}
                    label={<FormattedMessage id="description" />}
                    name={descriptionFieldPath}
                    rules={
                        [{
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }]
                    }
                    {...props.sharedProps}
                />
            );
        }}
    </Form.Item>
);

export class EventContentCard extends React.Component<EventContentCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const {
            changeSelectedLanguage,
            hidden,
            form,
            defaultValues,
            languageErrors,
            disabled,
            onBlur,
            activeLanguage,
            isChronoRaceOnNormalAdmin,
            projectId
        } = this.props;

        const sharedProps = {
            disabled,
            onBlur
        };

        const langFieldProps = languages.map(language => (
            {
                isChronoRaceOnNormalAdmin,
                form,
                sharedProps,
                defaultValues,
                language: language.code,
                hidden: language.code !== activeLanguage,
                projectId
            }
        ));

        return (
            <Card
                hidden={hidden}
                title={<FormattedMessage id="event" />}
            >
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(descriptionField(form))}
            </Card>
        );
    }
}
