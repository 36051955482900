export const unitTypeOptions = [
    "calories",
    "steps",
    "distance",
    "duration",
    "elevation",
    "workoutCalories",
    "workoutSteps",
    "workoutDistance",
    "workoutDuration",
    "workoutElevation",
    "workoutCount",
    "creditsEarned"
];

export const historyAggregationOptions = [
    "day",
    "week",
    "month",
    "overall"
];

export const historyChartTypeOptions = [
    "bar"
];

export const summaryAlgorithmOptions = [
    "avg",
    "total"
];

export const summaryAlgorithmDisplayTypeOptions = [
    "percentage",
    "number"
];
