import { DatePickerField } from "@components/field/DatePickerfield";
import { JSONData } from "@energylab/schematics";
import { formatDate } from "@utils/dateUtils";
import Form, { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import { FieldName } from "../fieldsMapper";

type DatePickerFieldWrapperProps = {
    disable: boolean;
    displayName: string;
    form: FormInstance;
    jsonData: JSONData;
    fieldNamePath?: FieldName;
    required: boolean;
};

export const DatePickerFieldWrapper: React.FC<DatePickerFieldWrapperProps> = (props) => {
    const { disable, displayName, form, jsonData, fieldNamePath, required } = props;
    return (
        <Form.Item name={fieldNamePath}>
            <Form.Item dependencies={[fieldNamePath || []]}>
                {() => {
                    const field = form.getFieldValue(fieldNamePath || "");
                    const date = field && moment(field);
                    return (
                        <DatePickerField
                            description={jsonData.description}
                            disabled={disable}
                            label={displayName}
                            rules={[
                                {
                                    required,
                                    message: "Required"
                                }
                            ]}
                            value={date}
                            onChange={(value) => {
                                form.setFields([{ name: fieldNamePath || "", value: value && formatDate(value) }]);
                            }}
                        />
                    );
                }}
            </Form.Item>
        </Form.Item>
    );
};
