import * as React from "react";
import { GenericWidgetStyle } from "@components/dashboard/widgets/genericWidget/genericWidgetStyle";
import { WidgetConfiguratorHeader, WidgetConfiguratorHeaderProps } from "@components/dashboard/widgetConfiguratorHeader/widgetConfiguratorHeader";
import { WidgetConfigStyle } from "@components/dashboard/widgets/widgetStyle";
import { FormattedMessage } from "react-intl";
import { getText } from "@utils/getText";

export interface DashboardWidgetPreviewCardProps extends WidgetConfiguratorHeaderProps {

}

export const DashboardWidgetPreviewCard: React.FC<DashboardWidgetPreviewCardProps> = (props) => {
    const { ...headerProps } = props;
    const { widget } = headerProps;
    const title = getText((widget as any).title);

    return (
        <GenericWidgetStyle visible>
            <WidgetConfiguratorHeader {...headerProps} />
            <WidgetConfigStyle visible={open}>
                <p><FormattedMessage id="title" tagName="b" />: {title}</p>
            </WidgetConfigStyle>
        </GenericWidgetStyle>
    );
};
