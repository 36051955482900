import { JourneyFormValues } from "@pages/addJourneysPage/addJourneysPage";
import * as moment from "moment";
import { discountTypes } from "@utils/discountTypes";
import { defaultDiscountRole } from "environment";

export const emptyJourney: JourneyFormValues = {
    title: {},
    date: moment(),
    discounts: {
        roles: [defaultDiscountRole],
        amount: 0,
        disabled: false,
        type: discountTypes[1]
    },
    distance: 0,
    eventCost: 0,
    location: {},
    detailedLocation: {},
    eventDescription: {},
    eventTitle: {},
    subTitle: {},
    type: "personal",
    sequence: 0,
    imageUrl: "",
    tags: [],
    content: [],
    coachType: "",
    comingSoon: false,
    hideInList: false,
    publishOn: moment(),
    expiresOn: moment(),
    cost: 105,
    currency: "EUR",
    eventCurrency: "EUR",
    ultimateTimeToRegisterEnabled: false,
    ultimateTimeToRegister: moment(),
    maximumParticipantsEnabled: false,
    maximumParticipants: 0,
    voucherRequired: false,
    newRoles: [],
    enabledLocales: []
};
