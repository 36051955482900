import * as React from "react";

import { ColorPreviewField } from "@components/field/colorPreviewField";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { Select } from "antd";

import { FormattedMessage } from "react-intl";
import { FormInstance } from "antd/lib/form";

export interface ChallengeVisualisationFont {
    size?: string;
    family?: string;
    color?: string;
}

export interface FontFormProps {
    prefix: (string | number)[];
    font?: ChallengeVisualisationFont;
    form: FormInstance;
}

const fonts = [
    "times",
    "arial"
];

export const FontForm: React.FC<FontFormProps> = (props) => {
    const { prefix, form } = props;

    const font: ChallengeVisualisationFont = props.font || {};

    return (
        <Flex justifyContent="space-between">
            <SelectField
                name={[...prefix, "family"]}
                initialValue={font.family}
                label={<FormattedMessage id="font" />}
            >
                {fonts.map(f => (
                    <Select.Option value={f} key="1">
                        {f}
                    </Select.Option>
                ))}
            </SelectField>
            <Field
                name={[...prefix, "size"]}
                initialValue={font.size}
                label={<FormattedMessage id="font.size" />}
                numberType
                min={0}
                suffix="em"
            />
            <ColorPreviewField
                name={[...prefix, "color"]}
                initialValue={font.color}
                label={<FormattedMessage id="font.color" />}
                form={form}
            />
        </Flex>
    );
};
