
import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { AddListItem } from "./addListItem";
import { ListFieldProps } from "./listField";
import { ListItem } from "./listItem";
import { SortableItemStyle } from "./styles";

export const SortableListField: React.FC<ListFieldProps> = (props) => {
    const { fields, operation, fieldOptions } = props;
    const { add, remove, move } = operation;
    const { disable } = fieldOptions;

    const onSortEnd = (oldIndex: number, newIndex: number) => move(oldIndex, newIndex);

    return (
        <SortableList
            style={{
                display: "flex",
                flexWrap: "wrap"
            }}
            onSortEnd={onSortEnd}
        >
            {fields.map(field => {
                return (
                    <SortableItem key={field.name}>
                        <SortableItemStyle>
                            <ListItem
                                field={field}
                                fieldOptions={fieldOptions}
                                remove={remove}
                            />
                        </SortableItemStyle>
                    </SortableItem>
                );
            })}
            <AddListItem
                add={add}
                disable={disable}
            />
        </SortableList>
    );
};
