import { Menu } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { debug } from "environment";

export interface NavItemProps {
    role: boolean;
    translation: string;
    to: string;
    experimental?: boolean;
}

export const NavItem: React.FC<NavItemProps> = (props) => {
    // itemProps are required by Ant Design
    const { role, translation, to, experimental, ...itemProps } = props;

    if (experimental && !debug) {
        return null;
    }

    if (!role) {
        return null;
    }

    return (
        <Menu.Item {...itemProps}>
            <Link to={to}>
                <FormattedMessage id={translation} />
            </Link>
        </Menu.Item>
    );
};
