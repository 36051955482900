/* eslint-disable react/prop-types */
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { FlexButtonWrapper } from "@pages/rolesPage/rolesPageStyle";
import { Button, Col, Collapse, Form, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { round } from "lodash";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Statistic, StatisticTitle, StatisticValue, Statistics } from "../userActivitiesCard/userActivitiesCardStyle";
import { transactionTypes } from "./constants/transactionTypes";
import { UserWalletCardProps } from "./userWalletCardContainer";
import { WalletLabel, WalletWrapper } from "./userWalletCardStyle";

export interface UserWalletCardState {
    saving: boolean;
}

export interface TransactionFormValues {
    type: string;
    amount: number;
}

interface Balance {
    spent: string | number;
    earned: string | number;
    balance: string | number;
}

export const UserWalletCard: React.FC<UserWalletCardProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();
    const [saving, setSaving] = React.useState(false);
    const { createCreditTransaction, creditStatistics, userId } = props;

    const submitForm = async (values: TransactionFormValues) => {
        setSaving(true);

        try {
            await createCreditTransaction({
                variables: {
                    userId,
                    ...values
                }
            });
            message.success(intl.formatMessage({ id: "transaction.success" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "transaction.error" }));
        }

        setSaving(false);
    };

    let values: Balance = {
        balance: "-",
        earned: "-",
        spent: "-"
    };

    if (creditStatistics.users && creditStatistics.users[0] && creditStatistics.users[0].creditStatistics) {
        values = {
            balance: round(creditStatistics.users[0].creditStatistics.balance, 1),
            earned: round(creditStatistics.users[0].creditStatistics.earned, 1),
            spent: round(creditStatistics.users[0].creditStatistics.spended, 1)
        };
    }

    return (
        <WalletWrapper>
            <Collapse>
                <Collapse.Panel
                    key="wallet"
                    header={(
                        <WalletLabel>
                            <FormattedMessage id="wallet.message" />
                        </WalletLabel>
                    )}
                >
                    <Statistics>
                        <Statistic>
                            <StatisticTitle>
                                <FormattedMessage id="credit.balance" />
                            </StatisticTitle>
                            <StatisticValue>{values.balance}</StatisticValue>
                        </Statistic>
                        <Statistic>
                            <StatisticTitle>
                                <FormattedMessage id="credit.earned" />
                            </StatisticTitle>
                            <StatisticValue>{values.earned}</StatisticValue>
                        </Statistic>
                        <Statistic>
                            <StatisticTitle>
                                <FormattedMessage id="credit.spent" />
                            </StatisticTitle>
                            <StatisticValue>{values.spent}</StatisticValue>
                        </Statistic>
                    </Statistics>
                    <Form
                        form={form}
                        initialValues={{
                            type: transactionTypes[0]
                        }}
                        onFinish={submitForm}
                    >
                        <FormattedMessage
                            id="addTransaction"
                            tagName="h2"
                        />
                        <Row gutter={10}>
                            <Col flex={1}>
                                <SelectField
                                    id="transactionType"
                                    label={<FormattedMessage id="transactionType" />}
                                    name="type"
                                    rules={[requiredrule]}
                                >
                                    {transactionTypes.map(t => (
                                        <Select.Option
                                            key={t.toLowerCase()}
                                            value={t.toLowerCase()}
                                        >
                                            {t}
                                        </Select.Option>
                                    ))}
                                </SelectField>
                            </Col>
                            <Col>
                                <Field
                                    numberType
                                    id="amount"
                                    label={<FormattedMessage id="amount" />}
                                    name="amount"
                                    rules={[requiredrule]}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Row>
                        <FlexButtonWrapper
                            fullWidth
                            justifyContent="center"
                            margin="20px 0"
                        >
                            <Button
                                key="button1"
                                htmlType="submit"
                                loading={saving}
                                type="primary"
                            >
                                <FormattedMessage id="profile.save" />
                            </Button>
                        </FlexButtonWrapper>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        </WalletWrapper>
    );
};
