import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { LoaderComponent } from "@components/loaderComponent/loaderComponent";
import { useGetChallengeTitlesLazyQuery, useGetContentTitlesLazyQuery, useGetEventTitlesLazyQuery } from "@graphql2/types";
import { getText } from "@utils/getText";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { StepType } from "./singleStepCard";

type TypeIdsFieldProps = {
    stepType: StepType | undefined;
    fieldName: number;
    form: FormInstance;
    stepTypeModifiedManually: boolean;
}

export const TypeIdsField = ({ stepType, fieldName, form, stepTypeModifiedManually }: TypeIdsFieldProps) => {
    const [typeIdsData, setTypeIdsData] = React.useState<{ value: string; title: string; }[] | undefined>(undefined);

    React.useEffect(() => {
        switch (stepType) {
            case "content":
                getContents();
                break;
            case "event":
                getEvents();
                break;
            case "challenge":
                getChallenges();
                break;
            default:
                break;
        }
    }, [stepType]);

    React.useEffect(() => {
        if (!stepTypeModifiedManually) {
            return;
        }

        form.setFields([{
            name: ["steps", fieldName, "typeIds"],
            value: stepType === "typeform" ? "" : []
        }]);
    }, [stepTypeModifiedManually, stepType]);

    const [getEvents, { loading: loadingEvents }] = useGetEventTitlesLazyQuery({
        variables: {
            filter: {
                endsAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.events.map(event => ({ value: event.id, title: getText(event.title) })));
        }
    });

    const [getChallenges, { loading: loadingChallenges }] = useGetChallengeTitlesLazyQuery({
        variables: {
            filter: {
                endsAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.challenges.map(challenge => ({ value: challenge.id, title: getText(challenge.title) })));
        }
    });

    const [getContents, { loading: loadingContents }] = useGetContentTitlesLazyQuery({
        variables: {
            filter: {
                expireAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.contents.map(content => ({ value: content.id, title: getText(content.title) })));
        }
    });

    if (!stepType) {
        return null;
    }

    if (loadingEvents || loadingChallenges || loadingContents) {
        return <LoaderComponent loading />;
    }

    if (stepType === "typeform") {
        return (
            <Field
                label={<FormattedMessage id="flows.typeIds.typeformId" />}
                name={[fieldName, "typeIds"]}
                rules={[requiredrule]}
            />
        );
    }

    return (
        <SelectField
            label={<FormattedMessage id={`flows.typeIds.${stepType}`} />}
            name={[fieldName, "typeIds"]}
            rules={[requiredrule]}
            {...(stepType !== "content" ? { mode: "multiple" } : {})}
        >
            {typeIdsData && typeIdsData.map(typeId => (
                <Select.Option
                    key={typeId.value}
                    value={typeId.value}
                >
                    {typeId.title}
                </Select.Option>
            ))}
        </SelectField>
    );
};
