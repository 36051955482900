import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm } from "antd";

import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { RoleType } from "@pages/rolesPage/rolesPage";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { PlainButton } from "@components/style/globalStyles";

export interface RolesTableData extends TableData {
    roleId: string;
    title: string;
    applicationId: string;
    application: string;
    description: string;
}

export interface RolesTableProps extends TableComponentProps {
    dataSource: RolesTableData[];
    handlers: TableHandlers & {
        selectRole(role: RoleType): void;
        removeRole(roleId: string, applicationId: string): void;
    };
}

export class RolesTable extends React.Component<RolesTableProps> {
    public render() {
        return (
            <Table
                pagination={{ showSizeChanger: true, showQuickJumper: true }}
                rowKey="id"
                {...this.props}
            >
                <Table.Column
                    key="id"
                    title={<FormattedMessage id="id" />}
                    dataIndex="roleId"
                />
                <Table.Column
                    key="title"
                    title={<FormattedMessage id="title" />}
                    dataIndex="title"
                    render={(text, row: RolesTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <PlainButton onClick={() => handlers.selectRole({
                                    id: row.roleId,
                                    name: row.title,
                                    description: row.description,
                                    applicationId: row.applicationId
                                })}>
                                    {text}
                                </PlainButton>
                            )
                        };
                    }}
                />
                <Table.Column
                    key="application"
                    title={<FormattedMessage id="application" />}
                    dataIndex="application"
                />
                <Table.Column
                    key="description"
                    title={<FormattedMessage id="description" />}
                    dataIndex="description"
                />
                <Table.Column
                    key="handlers"
                    width={120}
                    render={(text, row: RolesTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                row.applicationId === "frontend" && (
                                    <Flex justifyContent="flex-end" alignItems="center">
                                        <TableIcon
                                            as={EditOutlined}
                                            onClick={() => handlers.selectRole({
                                                id: row.roleId,
                                                name: row.title,
                                                description: row.description,
                                                applicationId: row.applicationId
                                            })}
                                        />
                                        <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeRole(row.roleId, row.applicationId)} okText="Yes" cancelText="No">
                                            <TableIcon as={DeleteOutlined} />
                                        </Popconfirm>
                                    </Flex>
                                )
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                />
            </Table>
        );
    }
}
