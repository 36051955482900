import { Col } from "antd";
import styled from "styled-components";

export const ColItem = styled(Col)`
    display: flex;
    border: 1px solid #F2F2F2;
    padding: 10px;
    justify-content: center;
    align-items: center;
`;

export const ColHeader = styled(ColItem)`
  background-color: #FAFAFA;
`;
