import * as React from "react";
import { compose } from "redux";
import { AddTranslationProps, GetTranslationsProps, withAddTranslation, withGetTranslations } from "../../../graphql2/types";
import { AddTranslations } from "./addTranslations";

export interface AddTranslationsContainerProps {
    keys?: string[];
    filterEnabled?: boolean;
    onChangeKeys(keys: string[]): void;
    onSubmit(): void;
}

export const withGenericTranslations = withGetTranslations({
    options(ownProps: AddTranslationsProps) {
        return {
            variables: {
                filter: {
                    includeDefault: true,
                    projectId: "",
                    default: true,
                    keys: ownProps.keys
                }
            }
        };
    },
    skip(ownProps: AddTranslationsProps) {
        return Boolean(!ownProps.keys || !ownProps.keys.length);
    }
});

export const withAddTranslationMutation = withAddTranslation({
    name: "addTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const AddTranslationsContainer = compose<React.ComponentClass<AddTranslationsContainerProps>>(
    withGenericTranslations,
    withAddTranslationMutation
)(AddTranslations);

export type AddTranslationsProps =
    AddTranslationsContainerProps
    & MaybeElements<GetTranslationsProps>
    & AddTranslationProps<{}, "addTranslation">
