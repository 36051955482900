import styled from "styled-components";

export const ImportTranslationActionsStyle = styled.div`
    display: flex;
    justify-content: space-between;

    margin-top: 12px;

    .ant-btn {
        margin-bottom: 6px;
    }

    .warning {
        background-color: orange;
        border-color: orange;
    }
`

export const ProceedStyle = styled.div`
    font-weight: bold;
    margin-bottom: 6px;

    .anticon {
        margin-right: 4px;
    }
`;

export const ExistingTranslationsStyle = styled.div`
    p {
        margin-bottom: 6px;
    }
`

export const UpdateOptionsStyle = styled.div`
    .ant-radio-wrapper {
        display: block;
    }
 `