import { DownloadOutlined } from "@ant-design/icons";
import { getDataFromProperties } from "@utils/getDataFromProperties";
import { getText } from "@utils/getText";
import { createBlob, saveFile } from "@utils/saveFile";
import { Button } from "antd";
import { autobind } from "core-decorators";
import camelCase from "lodash/camelCase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "../table/tableStyle";
import { ExportContainerProps } from "./exportContainer";

export interface ExportProps {
    id: string;
    buttonDownload?: boolean;
    onFinish?(): void;
}

export interface ExportFactoryProps {
    targetProp: string[];
    targetSelector?;
}

export function exportFactory({
    targetProp,
    targetSelector = (data) => data[0]
}: ExportFactoryProps) {
    @autobind
    class Export extends React.Component<ExportContainerProps> {
        public componentDidUpdate() {
            const data = getDataFromProperties(this.props, targetProp);
            if (
                // Target should be marked for export
                this.props.shouldExport
                // Target-data should be available
                && data
                && data.length
            ) {
                const { setLocalState, id } = this.props;

                this.export(targetSelector(data, id));

                setLocalState({ shouldExport: false });
            }
        }

        public render() {
            const { setLocalState, buttonDownload, onFinish = () => { } } = this.props;

            if (buttonDownload) {
                return (
                    <Button
                        icon={<DownloadOutlined />}
                        type="primary"
                        onClick={() => {
                            setLocalState({ shouldExport: true }); onFinish();
                        }}
                    >
                        <FormattedMessage id="export" />
                    </Button>
                );
            }
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
                <a onClick={() => setLocalState({ shouldExport: true })}>
                    <TableIcon as={DownloadOutlined} />
                </a>
            );
        }

        private export(target) {
            const { id } = this.props;

            let name = `${id}.json`;

            if (target && target.title && target.__typename) {
                name = `${target.__typename.toUpperCase()}_${camelCase(getText(target.title))}.json`;
            }

            const file = createBlob(target);
            saveFile(name, file);
        }
    }

    return Export;
}
