import uuidv4 from "uuid/v4";

export interface MilestoneFactoryProps {

}

export const milestoneFactory = () => ({
    id: uuidv4(),
    conditionId: "",
    percentage: 0,
    title: {},
    description: {},
    imageUrl: "",
    mailEnabled: false,
    notificationsEnabled: false
});
