import * as React from "react";
import { compose } from "redux";

import { ExportProps, exportFactory } from "./export";
import { getByIdQuery, withLocalState, ExportContainerProps } from "./exportContainer";
import { withGetContents, GetContentsProps } from "../../graphql2/types";

export const withContent = withGetContents(getByIdQuery);

export const ExportContentContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withContent
)(exportFactory({ targetProp: ["data", "contents"] }));

export type ExportContentContainerProps = ExportContainerProps & MaybeElements<GetContentsProps>;
