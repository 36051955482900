import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { MdeField } from "@components/field/mdeField";
import { SelectField } from "@components/field/selectField";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { JourneyContentLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { storeImage } from "@utils/storeImage";
import { LanguageObject } from "@utils/toLanguageObject";
import { Button, Card, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../context/enabledLangContext/context";

export interface Content {
    id: string;
    type: string;
    title: LanguageObject;
    description: LanguageObject;
    imageUrl: string;
    imageCaption: string;
    url: string;
}

export interface EditJourneyContentFormValues {
    content?: Content[];
}

export interface EditJourneyContentCardProps {
    form: FormInstance;
    hidden?: boolean;
    disabled?: boolean;
    activeLanguage: string;
    index: number;
    languageErrors?: LanguageErrors;
    defaultImage?: string;
    content: Content;
    back(): void;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const contentTitleField = (props: JourneyContentLanguageFieldProps) => (
    <Field
        key={`${props.index}${props.language}`}
        hidden={props.hidden}
        id={`content.${props.index}.title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={[props.index || 0, "title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            }
        ]}
        {...props.sharedProps}
    />
);

const contentDetailField = (props: JourneyContentLanguageFieldProps) => (
    <MdeField
        key={`${props.index} ${props.language}`}
        form={props.form}
        formSetData={[
            {
                fields: [["content"], ["description", props.language]],
                index: props.index || 0
            }
        ]}
        hidden={props.hidden}
        id={`content.${props.index}.description.${props.language}`}
        // Custom field so still needs initialValue
        initialValue={props.defaultValues.description[props.language]}
        label={<FormattedMessage id="description" />}
        name={[props.index || 0, "description", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

export const EditJourneyContentCard: React.FC<EditJourneyContentCardProps> = (props) => {
    const { enabledLanguages } = useEnabledLang();
    const { changeSelectedLanguage, back, defaultImage, content, index, hidden, form, languageErrors, disabled, onBlur, activeLanguage } = props;
    const sharedProps = {
        disabled,
        onBlur
    };
    const langFieldProps: JourneyContentLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(
            language => ({
                form, index, sharedProps, defaultValues: content, language: language.code, hidden: language.code !== activeLanguage
            })
        ),
        [enabledLanguages, activeLanguage]
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                rightFields={[
                    <Button
                        key="back"
                        onClick={back}
                    >
                        <FormattedMessage id="back" />
                    </Button>
                ]}
                title={<FormattedMessage id="editContent" />}
            />

            <Input
                name="id"
                type="hidden"
            />

            <SelectField
                id={`content.${index}.type`}
                label={<FormattedMessage id="contentType" />}
                name={[index, "type"]}
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage id="form.isrequired" />
                    }
                ]}
                {...sharedProps}
            >
                <Select.Option
                    key="tab"
                    value="tab"
                >
Tab
                </Select.Option>
                <Select.Option
                    key="contentList"
                    value="contentList"
                >
Content list
                </Select.Option>
                <Select.Option
                    key="disclaimerList"
                    value="disclaimerList"
                >
Disclaimer list
                </Select.Option>
                <Select.Option
                    key="USPList"
                    value="USPList"
                >
USP list
                </Select.Option>
                <Select.Option
                    key="cornerImage"
                    value="cornerImage"
                >
Corner image
                </Select.Option>
            </SelectField>
            <FileDragAndDropContainer
                defaultImage={defaultImage}
                onChange={async (image): Promise<void> => storeImage(() => onBlur(), form, ["content", index, "imageUrl"], image, undefined, { fields: [["content"], ["imageUrl"]], index })}
            />
            <Field
                hidden
                id={`content.${index}.imageUrl`}
                name={[index, "imageUrl"]}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />

            {langFieldProps.map(contentTitleField)}
            {langFieldProps.map(contentDetailField)}
        </Card>
    );
};
