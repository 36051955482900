import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { HeaderWrapper, TriggerIcon } from "@components/header/headerStyle";
import { LogoutContainer } from "@components/header/logout/logoutContainer";
import * as React from "react";

export interface HeaderProps {
    navCollapsed: boolean;
    toggle: () => void;
}

export interface HeaderState {

}

export const Header = (props: HeaderProps) => {
    return (
        <HeaderWrapper>
            <TriggerIcon
                as={props.navCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
                onClick={props.toggle}
            />
            <LogoutContainer />
        </HeaderWrapper>
    );
};
