import { DashboardConfigWidgetActivity, DashboardConfigWidgetActivityInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetActivityFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetActivity, {
    title: LanguageObject;
}>>;

export const mapActivityStateWidgetToActivityWidget = (widget: DashboardConfigWidgetActivityFormValues): DashboardConfigWidgetActivityInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title)
    };
};

export const mapActivityWidgetToActivityStateWidget = (activityWidget: DashboardConfigWidgetActivity): DashboardConfigWidgetActivityFormValues => {
    return {
        ...activityWidget,
        type: activityWidget.type as ViewType,
        title: toLanguageObject(activityWidget.title)
    };
};
