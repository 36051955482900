import styled from "styled-components";

export const CardSubtitle = styled.h2`

    span {
        font-family: "pingFang", sans-serif;
        font-size: .75rem;
        font-weight: 600;
        color: #A0C63D;
    }
`;

export const SettingsRowWrapper = styled.div`

    .ant-row .ant-checkbox-wrapper {
        margin: 7.5px 0;
    }

    span {
        line-height: 20px;
        font-size: .75rem;
        color: #282828;
        font-weight: 400;
    }
`;

export const DateAndTimesWrapper = styled.div`
    display: flex;

    .ant-row {
        margin-right: 10px;
    }
`;
