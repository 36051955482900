import React, { PureComponent } from "react";

import { PageNotFoundStyle } from "./pageNotFoundStyle";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "antd";

export class PageNotFoundPage extends PureComponent {
    render() {
        return (
        <PageNotFoundStyle>
            <h2>404</h2> 
            <FormattedMessage tagName="p" id="notfound.text" />
            <p><Link to="/"><Button type="primary"><FormattedMessage id="notfound.button" /></Button></Link></p>
        </PageNotFoundStyle>
        );
    }
}