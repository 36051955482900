/* tslint:disable */

export function debounce(func: Function, wait: number, immediate?: boolean) {
    let timeout;

    return function (...args) {
        const context = this;
        const later = function () {
            timeout = null;
            if (!immediate) { func.apply(context, args); }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) { func.apply(context, args); }
    };
}

/* tslint:enable */
