import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm, Avatar, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import { DeleteOutlined, DisconnectOutlined, EditOutlined } from "@ant-design/icons";
import * as moment from "moment";

import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import ProvidersIcons from "./ProvidersIcons";

export interface UsersTableData extends TableData {
    avatar?: string;
    name?: (string | null);
    email?: (string | null);
    lastLogin?: (number | null);
    roles?: (string | null)[];
    blocked?: (boolean | null);
    providers?: (string | null)[];
}

export interface UsersTableProps extends TableComponentProps {
    dataSource: UsersTableData[];
    handlers: TableHandlers & {
        removeUser(id: string, anonymize: boolean): void;
        handlePagination(page: number): void;
    };
}

const renderAvatar = (avatar: string, row) => {
    return (
        <Avatar shape="square" src={avatar}>
            {!avatar && row.name && row.name.split(" ").map(r => r.substr(0, 1))}
        </Avatar>
    );
};

const renderTitle = (text: string, row: UsersTableData) => (
    <Link className="plainText" to={`/users/detail/${row.id}`}>
        {text}
    </Link>
);

export const UsersTable: React.FC<UsersTableProps> = ({ handlers, ...tableProps }) => {
    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="avatar"
                dataIndex="avatar"
                width={30}
                render={renderAvatar}
            />
            <Column
                key="givenName"
                title={<FormattedMessage id="name" />}
                dataIndex="name"
                defaultSortOrder="ascend"
                sorter
                render={renderTitle}
            />
            <Column
                key="email"
                title={<FormattedMessage id="email" />}
                dataIndex="email"
                width={400}
                sorter
            />
            <Column
                key="lastLogin"
                title={<FormattedMessage id="lastLogin" />}
                dataIndex="lastLogin"
                width={200}
                render={text => moment(text).format("DD/MM/YYYY")}
            />
            <Column
                key="providers"
                title={<FormattedMessage id="providers" />}
                dataIndex="providers"
                width={200}
                render={ProvidersIcons}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(text, row: UsersTableData) => ({
                    children: (
                        <Flex justifyContent="flex-end" alignItems="center">
                            <Link to={`/users/detail/${row.id}`}>
                                <TableIcon type="edit" theme="outlined" as={EditOutlined} />
                            </Link>
                            <Popconfirm
                                title={<FormattedMessage id="anonimyseConfirm" />}
                                onConfirm={() => handlers.removeUser(row.id, true)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Tooltip placement="bottom" title={<FormattedMessage id="user.anonimyse" />}>
                                    <TableIcon type="delete" theme="outlined" as={DisconnectOutlined} />
                                </Tooltip>
                            </Popconfirm>
                            <Popconfirm
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={() => handlers.removeUser(row.id, false)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Tooltip placement="bottom" title={<FormattedMessage id="user.remove" />}>
                                    <TableIcon type="delete" theme="outlined" as={DeleteOutlined} />
                                </Tooltip>
                            </Popconfirm>
                        </Flex>
                    ),
                    props: {
                        colSpan: 2
                    }
                })}
            />
        </Table>
    );
};
