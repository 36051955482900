import { getJourneys } from "@graphql2/graphql_op";
import { AddJourneysPage } from "@pages/addJourneysPage/addJourneysPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import { FormProps } from "antd/lib/form";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { AddJourneyProps, GetApplicationsProps, GetJourneyTagsProps, GetJourneysProps, JourneyFragment, RemoveJourneyProps, UpdateJourneyProps, withAddJourney, withGetApplications, withGetJourneyTags, withGetJourneys, withRemoveJourney, withUpdateJourney } from "../../graphql2/types";

export interface AddJourneysPageContainerProps {

}

type AddJourneyRouteProps = RouteComponentProps<{
    id: string;
    tab: string;
},
    {},
    { import: JourneyFragment; }>;

export const ADD_JOURNEY_TABS = {
    general: "general",
    content: "content"
};

export const withRemoveJourneyMutation = withRemoveJourney({
    name: "removeJourney",
    options: {
        refetchQueries: ["getJourneys", "getJourneyCount"],
        awaitRefetchQueries: true
    }
});

export const withJourneys = withGetJourneys({
    name: "journeys",
    options(props: AddJourneyRouteProps) {
        return {
            variables: {
                filter: {
                    ids: props.match.params.id ? [props.match.params.id] : []
                },
                paging: {}
            }
        };
    },
    skip(props: AddJourneyRouteProps) {
        return !props.match.params.id;
    }
});

export const withUpdateJourneyMutation = withUpdateJourney({
    name: "updateJourney",
    options: {
        refetchQueries: ["getJourneys", "getJourneyCount"],
        awaitRefetchQueries: true
    }
});

export const withAddJourneyMutation = withAddJourney({
    name: "addJourney",
    options: {
        update: cleanCache([getJourneys])
    }
});

export const withApplications = withGetApplications({
    name: "applications"
});

export const withJourneyTags = withGetJourneyTags({
    name: "journeyTags"
});

export const AddJourneysPageContainer = compose<React.ComponentClass<AddJourneysPageContainerProps>>(
    withJourneyTags,
    withJourneys,
    withApplications,
    withUpdateJourneyMutation,
    withRemoveJourneyMutation,
    withAddJourneyMutation
)(AddJourneysPage);

export type AddJourneysPageProps = AddJourneysPageContainerProps
    & FormProps
    & RouteComponentProps
    & MaybeElements<GetJourneysProps<{}, "journeys">>
    & UpdateJourneyProps<{}, "updateJourney">
    & GetJourneyTagsProps<{}, "journeyTags">
    & AddJourneyRouteProps
    & AddJourneyProps<{}, "addJourney">
    & GetApplicationsProps<{}, "applications">
    & RemoveJourneyProps<{}, "removeJourney">;
