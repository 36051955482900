import { JourneysPage } from "@pages/journeysPage/journeysPage";
import { journeyToFormValues } from "@utils/mappers/journeyMapper";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { defaultSortAndPagingSettings, FilterSortPagingQuery } from "@utils/withPagingAndSort";
import { filterQueryState, FilterQueryStateProps } from "@utils/withFilterQueryState";
import { createWithUpdateFormValues } from "@utils/createWithUpdateFormValues";
import { withGetJourneys, GetJourneysProps, withGetJourneyCount, GetJourneyCountProps, withRemoveJourney, RemoveJourneyProps } from "../../graphql2/types";

export interface JourneysPageContainerProps {
    id: string;
}

const withFilterQueryState = filterQueryState<FilterSortPagingQuery>(
    { pageSize: defaultSortAndPagingSettings.paging.limit }
);

export const withJourneys = withGetJourneys({
    options({ filterQuery }: FilterQueryStateProps<FilterSortPagingQuery>) {
        return {
            variables: {
                paging: { limit: filterQuery.pageSize, skip: filterQuery.skip },
                sort: {
                    field: filterQuery.sortBy || "duration.start",
                    order: filterQuery.sort || "DESC"
                }
            }
        };
    }
});

export const withJourneyCount = withGetJourneyCount({
    name: "count"
});

export const withRemoveJourneyMutation = withRemoveJourney({
    name: "removeJourney",
    options: {
        refetchQueries: ["getJourneys", "getJourneyCount"],
        awaitRefetchQueries: true
    }
});

export const JourneysPageContainer = compose<React.ComponentClass<JourneysPageContainerProps>>(
    withFilterQueryState,
    withJourneys,
    withJourneyCount,
    withRemoveJourneyMutation,
    createWithUpdateFormValues(journeyToFormValues, ["data", "journeys"])
)(JourneysPage);

export type JourneysPageProps =
    & FilterQueryStateProps<FilterSortPagingQuery>
    & JourneysPageContainerProps
    & RouteComponentProps
    & GetJourneysProps
    & GetJourneyCountProps<{}, "count">
    & RemoveJourneyProps<{}, "removeJourney">;
