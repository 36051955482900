import { ApolloClient, ApolloLink, InMemoryCache, from } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { mergeDeepArray } from "@apollo/client/utilities";
import { getLanguageHeader } from "@utils/getLanguageHeader";
import DebounceLink from "apollo-link-debounce";
import * as environment from "environment";
import introspectionResult from "./introspection-result";

const uri = (window.location.origin.includes("localhost") || environment.debug) ? `${environment.graphQlEndpoint}/api/graphql` : "/api/graphql";

/*
* Debounce link
* Typings are wrong, so we have to cast it as any to ApolloLink
*/

// tslint:disable-next-line:no-any
const debounceLink: ApolloLink = new DebounceLink(500) as any;

/*
*  Auth Link
*/

const authLink = setContext((_noop, { headers }) => {
    let token = localStorage.getItem("token");

    if (!token) {
        token = environment.anonymousToken;
    }

    const language = getLanguageHeader();

    const newHeaders = { ...headers };

    if (token) {
        newHeaders.Authorization = `Bearer ${token}`;
    }
    newHeaders.Language = language;

    return { headers: newHeaders };
});

// This link should automatically get rid of all __typename fields so we don't have to manually remove them in our mappers
const cleanTypename = new ApolloLink((operation, forward) => {
    const proposedOp = operation;
    if (proposedOp.variables) {
        const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
        proposedOp.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(proposedOp).map((data) => {
        return data;
    });
});

const link = from([
    debounceLink,
    cleanTypename,
    // createPersistedQueryLink(),
    authLink,
    new BatchHttpLink({ uri })
]);

// Send mutations containing an Upload scalar to the upload link. Send the rest to the batchlink.
export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        possibleTypes: introspectionResult.possibleTypes,
        typePolicies: {
            Application: {
                fields: {
                    roles: {
                        merge: (existing = [], incoming = []) => {
                            return mergeDeepArray([existing, incoming]);
                        }
                    }
                },
                keyFields: ["name", "roles"]
            },
            Challenge: {
                fields: {
                    team: {
                        merge: true
                    }
                }
            },
            ChallengeTeam: {
                fields: {
                    team: {
                        merge: true
                    }
                }
            },
            Role: {
                keyFields: ["id", "name"]
            }
        }
    })
});
