import { DeleteOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { Flex } from "@components/flex/flex";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { FALLBACK_IMAGE, MultiImageUpload } from "@components/imageUpload/multi/multiImageUpload";
import { CardInfo } from "@components/style/globalStyles";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { CardSubtitle } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityConditionStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { handleError } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { GalleryImageFormValues } from "@utils/mappers/galleryMapper";
import { maxCharacters } from "@utils/maxCharacters";
import { saveFile } from "@utils/saveFile";
import { Button, Card, Col, Divider, Form, Image, List, Modal, Row, Switch, Table, notification } from "antd";
import JSZip from "jszip";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as strip from "remove-markdown-and-html";
import { DislikeIcon, SwitchWrapper } from "../addGalleriesPageStyle";
import { GalleryImageOverviewCardProps } from "./galleryImageOverviewCardContainer";

export interface GalleryImageOverviewImage {
    id: string;
    title: string;
    description: string;
    url?: string;
    embedUrl?: string;
    userId?: string;
}

export const GalleryImageOverviewCard: React.FC<GalleryImageOverviewCardProps> = (props) => {
    const { hidden, form, activeLanguage, changeSelectedLanguage, errors, users: { loading: usersLoading, users } } = props;

    const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
    const [showOnlyReportedImages, setShowOnlyReportedImages] = React.useState(false);
    const [api, contextHolder] = notification.useNotification();

    const addMultipleImages = (urls: string[], add: (value) => void) => {
        setUploadModalVisible(false);
        urls.forEach(url => {
            add({
                title: {},
                description: {},
                url,
                embedUrl: ""
            });
        });
    };

    const handleFailedUpload = (fileNames: string[]) => {
        setUploadModalVisible(false);
        if (fileNames.length) {
            api.open({
                message: "Images upload failed",
                description: (
                    <React.Fragment>
                        Following images were not uploaded because they were too large or the upload failed:
                        { fileNames.map((fileName) => (
                            <div>
&quot;
                                {fileName}
&quot;
                            </div>
                        )) }
                    </React.Fragment>
                ),
                type: "warning",
                duration: 0
            });
        }
    };

    const saveAllImages = async () => {
        try {
            const images = [...props.defaultValues.images];
            const jszip = new JSZip();

            const imagesFolder = jszip.folder("images");
            const imagesFetchAll = images.map((image) => {
                if (!image.url) {
                    return;
                }
                const user = users?.find(u => u.id === image.userId);
                const imageName = [user?.familyName, user?.givenName, image.id].filter(Boolean).join("_");
                return fetch(
                    image.url
                ).then((response) => response.blob()).then((res) => imagesFolder?.file(`${imageName}.png`, res, { base64: true }));
            });

            await Promise.all(imagesFetchAll);
            jszip.generateAsync({ type: "blob" }).then((content) => {
                saveFile("images.zip", content);
            });
        } catch (e) {
            handleError("gallery.download.failed");
        }
    };

    const reportedImage = (value: boolean) => {
        if (value) {
            return <DislikeIcon />;
        }
    };

    const renderImage = (value: string) => {
        return (
            <Image
                fallback={FALLBACK_IMAGE}
                height={100}
                preview={false}
                src={value}
                width={100}
            />
        );
    };

    const titleField = (index, p) => (
        <Field
            key={`title.${p.language}`}
            disabled
            hidden={p.hidden}
            id={`title.${p.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="title" />}
            name={[index, "title", p.language]}
            rules={[
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                }
            ]}
            {...p.sharedProps}
        />
    );
    const descriptionField = (index, p) => (
        <Field
            key={`description.${p.language}`}
            hidden={p.hidden}
            id={`description.${p.language}`}
            label={<FormattedMessage id="description" />}
            name={[index, "description", p.language]}
            rows={2}
            {...p.sharedProps}
        />
    );

    const renderInfo = (row: GalleryImageFormValues & { index: number; }) => {
        const values = form.getFieldValue(["images", row.index]);

        return (
            <List.Item.Meta
                description={strip(getText(values.description)) || <FormattedMessage id="gallery.image.noDescription" />}
                title={strip(getText(values.title)) || <FormattedMessage id="gallery.image.noTitle" />}
            />
        );
    };

    const renderUserId = (row: GalleryImageFormValues & { index: number; }) => {
        const values = form.getFieldValue(["images", row.index]);
        return (
            <List.Item.Meta
                title={strip(getText(values.userId)) || ""}
            />
        );
    };

    const deleteImage = (index: number, remove: (i) => void) => {
        return (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    icon={<DeleteOutlined />}
                    type="link"
                    onClick={() => remove(index)}
                />
            </Flex>
        );
    };

    const renderExpandedRow = (row: GalleryImageFormValues & { index: number; hasError: boolean; }, isHidden?: boolean) => {
        if (row.hasError) {
            form.validateFields();
        }

        const sharedProps = {
            form
        };

        const langFieldProps = languages.map(language => ({ sharedProps, defaultValues: {}, language: language.code, hidden: language.code !== activeLanguage }));
        const image = form.getFieldValue(["images", row.index, "url"]);

        return (
            <Row
                gutter={24}
                hidden={isHidden}
            >
                <Col span={12}>
                    <TranslationTabs
                        activeLanguage={activeLanguage}
                        errors={{}}
                        handleChangeSelectedLanguage={changeSelectedLanguage}
                        languages={languages}
                    />
                    {langFieldProps.map(p => titleField(row.index, p))}
                    {langFieldProps.map(p => descriptionField(row.index, p))}
                    <Divider />
                    <Field
                        key="embedUrl"
                        id="embedUrl"
                        label={<FormattedMessage id="embedUrl" />}
                        name={[row.index, "embedUrl"]}
                    />
                </Col>
                <Col span={12}>
                    <CardSubtitle><FormattedMessage id="gallery.image.settings" /></CardSubtitle>
                    <ImageUpload
                        noRemove // only replacing is allowed since image is a required field
                        required
                        form={form}
                        image={image}
                        name={["images", row.index, "url"]}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <Card hidden={hidden}>
            {contextHolder}
            <Form.List name={["images"]}>
                {(fields, { add, remove }) => {
                    const dataSource = (form.getFieldValue("images") || []).map((img, i) => ({
                        ...img,
                        key: i,
                        index: i,
                        hasError: Boolean(errors?.[i])
                    }));

                    const onlyReportedDataSource = dataSource.filter(img => img.reported);

                    return (
                        <React.Fragment>
                            <ContentHeader
                                rightFields={[
                                    <Button
                                        icon={<DownloadOutlined />}
                                        loading={usersLoading}
                                        type="primary"
                                        onClick={saveAllImages}
                                    >
                                        <FormattedMessage id="gallery.download" />
                                    </Button>,
                                    <Button
                                        icon={<UploadOutlined />}
                                        type="primary"
                                        onClick={() => setUploadModalVisible(true)}
                                    >
                                        <FormattedMessage id="gallery.multiUpload" />
                                    </Button>,
                                    <SwitchWrapper
                                        alignItems="center"
                                        gap={10}
                                        justifyContent="flex-end"
                                    >
                                        <FormattedMessage id="gallery.onlyReported.images" />
                                        <Switch
                                            size="small"
                                            onChange={() => setShowOnlyReportedImages(!showOnlyReportedImages)}
                                        />
                                    </SwitchWrapper>
                                ]}
                                subTitle={(
                                    <CardInfo>
                                        <FormattedMessage id="gallery.image.info" />
                                    </CardInfo>
                                )}
                                title={<FormattedMessage id="gallery.images" />}
                            />
                            <Table
                                dataSource={showOnlyReportedImages ? onlyReportedDataSource : dataSource}
                                expandable={{
                                    expandedRowRender: (row) => renderExpandedRow(row)
                                }}
                            >
                                <Table.Column
                                    key="image"
                                    dataIndex="url"
                                    render={renderImage}
                                    title={<FormattedMessage id="gallery.image" />}
                                    width={100}
                                />
                                <Table.Column
                                    key="info"
                                    dataIndex="title"
                                    render={(_, row: GalleryImageFormValues & { index: number; }) => renderInfo(row)}
                                    title={<FormattedMessage id="gallery.info" />}
                                />
                                <Table.Column
                                    key="userId"
                                    dataIndex="userId"
                                    render={(_, row: GalleryImageFormValues & { index: number; }) => renderUserId(row)}
                                    title={<FormattedMessage id="gallery.userId" />}
                                />
                                {renderErrorColumn()}
                                <Table.Column
                                    key="reported"
                                    dataIndex="reported"
                                    render={reportedImage}
                                />
                                <Table.Column
                                    key="handlers"
                                    dataIndex="index"
                                    render={(index) => deleteImage(index, remove)}
                                    width={50}
                                />
                            </Table>
                            {/* Required so fields don't unmount and validation triggers correctly */}
                            {dataSource.map(data => renderExpandedRow(data, true))}
                            <Modal
                                closable
                                footer={null}
                                title={<FormattedMessage id="gallery.image.uploadModal" />}
                                visible={uploadModalVisible}
                                onCancel={() => {
                                    setUploadModalVisible(false);
                                }}
                            >
                                <MultiImageUpload
                                    onFailedUpload={handleFailedUpload}
                                    onSuccessfulUpload={(urls) => addMultipleImages(urls, add)}
                                />
                            </Modal>
                        </React.Fragment>
                    );
                }}
            </Form.List>
        </Card>
    );
};
