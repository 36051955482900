import styled from "styled-components";
import { theme } from "@style/theme";

export const CardInfo = styled.span`
    opacity: 0.6;
`;

export const SpinStyle = styled.div`
    width: 100%;

    .ant-spin {
        left: 50% !important;
        position: relative !important;
        top: 30px !important;
        transform: translateX(-50%) !important;
    }
`;

export const PlainButton = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    &:hover {
        color: ${props => theme.primaryColor};
    }
`;
