import { CheckupLocationAvailabilityFormValues } from "@pages/addCheckupPage/addCheckupPage";
import * as moment from "moment";

export const emptyCheckupLocationAvailability: CheckupLocationAvailabilityFormValues = {
    coach: "",
    coachType: "",
    date: moment(),
    duration: {
        hours: 1,
        minutes: 15
    },
    location: "",
    time: moment()
};
