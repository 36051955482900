import { EventTable } from "@components/events/eventTable";
import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { useAuth } from "@context/authContext/context";
import { EventFragment } from "@graphql2/types";
import { EventsPageProps } from "@pages/eventsPage/eventsPageContainer";
import { getFrontendRoles } from "@utils/applicationUtils";
import { translateEvents } from "@utils/mappers/eventMapper";
import { modifyEventsIds } from "@utils/modifyEventsIds";
import { readFile } from "@utils/readFile";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Button, Card, Upload } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ONE_WEEK_AGO_DATE } from "./eventsPageContainer";

export const EventsPage: React.FC<EventsPageProps> = (props) => {
    const { history, events, removeEvent, count, paging, applications } = props;
    const intl = useIntl();
    const { projectId } = useAuth();

    const updateUrlQuery = useUpdateQuery({ replace: true });
    const roles = React.useMemo(() => getFrontendRoles(applications.applications || []), [applications.applications]);

    const translatedEvents = React.useMemo(
        () => translateEvents(events?.events || [], projectId, roles),
        [events, roles, projectId]
    );

    const handleUpload = async (info: { file: File; }) => {
        const data = await readFile<EventFragment>(info.file);
        history.push("/event/events/add/general", { import: modifyEventsIds(data, projectId) });
    };

    const removeEventFn = async (id: string) => {
        await removeEvent({ variables: { id } });
    };

    const updateTableSettings = async (pagination: PaginationConfig, filters: Record<string, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="events.overview"
                        tagName="h1"
                    />
                    <div>
                        <Upload
                            accept="application/json"
                            customRequest={handleUpload}
                        >
                            <Button
                                className="headerButton"
                                type="primary"
                            >
                                <FormattedMessage id="events.import" />
                            </Button>
                        </Upload>
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => history.push("/event/events/add/general")}
                        >
                            <FormattedMessage id="events.addEvent" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <DynamicFilterBar fields={[
                    {
                        name: "textSearch",
                        type: "input",
                        search: true,
                        placeholder: intl.formatMessage({ id: "filter.textSearch" })
                    },
                    {
                        name: "endsAfter",
                        type: "date",
                        label: intl.formatMessage({ id: "filter.endsAfter" }),
                        defaultValue: ONE_WEEK_AGO_DATE
                    },
                    {
                        name: "isChronorace",
                        type: "checkbox",
                        label: intl.formatMessage({ id: "filter.isChronorace" })
                    }
                ]}
                />
                <TableContainer
                    dataSource={translatedEvents || []}
                    handlers={{
                        removeEvent: removeEventFn
                    }}
                    loading={events?.loading}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count?.eventCount
                    }}
                    TableComponent={EventTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
