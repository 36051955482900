import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { DashboardConfigWidgetPopup, DashboardConfigWidgetPopupInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";

// export type DashboardConfigWidgetPopup = DashboardConfigWidget & {
//     title?: Maybe<Array<Maybe<TextByLanguage>>>;
//     description?: Maybe<Array<Maybe<TextByLanguage>>>;
//     callToActionText?: Maybe<Array<Maybe<TextByLanguage>>>;
//     url?: Maybe<Scalars["String"]>;
//     image?: Maybe<Scalars["String"]>;
//   };

// export type DashboardConfigWidgetPopupInput = {
//     description?: InputMaybe<Array<InputMaybe<TextByLanguageInput>>>;
//     callToActionText?: InputMaybe<Array<InputMaybe<TextByLanguageInput>>>;
//     desktop?: InputMaybe<DashboardConfigWidgetRenderingInput>;
//     fullWidth?: InputMaybe<Scalars["Boolean"]>;
//     id: Scalars["String"];
//     mobile?: InputMaybe<DashboardConfigWidgetRenderingInput>;
//     paging?: InputMaybe<DashboardConfigWidgetPagingInput>;
//     roles?: InputMaybe<Array<Scalars["String"]>>;
//     url?: Maybe<Scalars["String"]>;
//     image?: Maybe<Scalars["String"]>;
//     tablet?: InputMaybe<DashboardConfigWidgetRenderingInput>;
//     title?: InputMaybe<Array<InputMaybe<TextByLanguageInput>>>;
//     type: Scalars["String"];
//   };

export type DashboardConfigWidgetPopupFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetPopup, {
    description: LanguageObject;
    title: LanguageObject;
    callToActionText: LanguageObject;
}>>;

export const mapPopupStateWidgetToPopupWidget = (widget: DashboardConfigWidgetPopupFormValues): DashboardConfigWidgetPopupInput => ({
    ...widget,
    callToActionText: toTextByLanguage(widget.callToActionText),
    description: toTextByLanguage(widget.description),
    title: toTextByLanguage(widget.title)
});

export const mapPopupWidgetToPopupStateWidget = (popupWidget: DashboardConfigWidgetPopupInput): DashboardConfigWidgetPopupFormValues => {
    return {
        ...popupWidget,
        title: toLanguageObject(popupWidget.title),
        description: toLanguageObject(popupWidget.description),
        callToActionText: toLanguageObject(popupWidget.callToActionText),
        type: popupWidget.type as ViewType
    };
};
