import { ContentTabs } from "@components/contentTabs/contentTabs";
import { Field } from "@components/field/field";
import { HeaderContent } from "@components/headerContent/headerContent";
import { FormModal } from "@components/modal/modal";
import { SOCIAL_TABS, SocialPageProps } from "@pages/socialPage/socialPageContainer";
import { SocialPageStyle } from "@pages/socialPage/socialPageStyle";
import { Badge, Form, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GeneralCard } from "./general/generalCard";
import { InstagramCardContainer } from "./instagram/instagramCardContainer";
import { TwitterCardContainer } from "./twitter/twitterCardContainer";

/* eslint max-lines-per-function: ["error", 1000] */
export const SocialPage: React.FC<SocialPageProps> = (props) => {
    const tabs: string[] = [
        SOCIAL_TABS.general,
        SOCIAL_TABS.instagram,
        SOCIAL_TABS.twitter
    ];

    const intl = useIntl();
    const [form] = useForm();
    const [loading, setLoading] = React.useState(false);
    const [showTagModal, setShowTagModal] = React.useState(false);
    const [showWordModal, setShowWordModal] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<string>();

    const {
        match,
        socialFilterWords,
        socialTags
    } = props;

    const currentTab = tabs.indexOf(match.params.tab);

    const renderSocialTab = (id, hasErrors) => {
        return <Badge dot={hasErrors}><FormattedMessage id={id} /></Badge>;
    };

    const socialTabs = [
        renderSocialTab(SOCIAL_TABS.general, false),
        renderSocialTab(SOCIAL_TABS.instagram, false),
        renderSocialTab(SOCIAL_TABS.twitter, false)
    ];

    const changeSelectedTab = (index: number | string) => {
        props.history.push(`/social/${tabs[index]}`);
    };

    function addItem(type: string) {
        setShowTagModal(Boolean(type === "tag"));
        setShowWordModal(Boolean(type === "word"));
        setSelectedValue(undefined);
        form.setFieldsValue({
            value: ""
        });
    }

    function clearAll() {
        setShowTagModal(false);
        setShowWordModal(false);
        setSelectedValue(undefined);
        setLoading(false);
        form.setFieldsValue({
            value: ""
        });
    }

    function updateTag(value: string) {
        setShowTagModal(true);
        setSelectedValue(value);
        form.setFieldsValue({
            value
        });
    }

    function updateWord(value: string) {
        setShowWordModal(true);
        setSelectedValue(value);
        form.setFieldsValue({
            value
        });
    }

    async function removeTag(tag: string) {
        const { removeSocialTag } = props;

        try {
            await removeSocialTag({ variables: { tag } });
            message.success(intl.formatMessage({ id: "social.tagRemoved" }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "social.tagRemoveFailed" }));
        }
    }

    async function removeWord(word: string) {
        const { removeSocialFilterWord } = props;

        try {
            await removeSocialFilterWord({ variables: { word } });
            message.success(intl.formatMessage({ id: "social.wordRemoved" }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "social.wordRemoveFailed" }));
        }
    }

    async function submitForm() {
        const { addSocialFilterWord, updateSocialFilterWord, addSocialTag, updateSocialTag } = props;

        if (!form) {
            return;
        }

        try {
            const { value } = await form.validateFields();
            setLoading(true);
            if (selectedValue) {
                // UPDATE
                if (showTagModal) {
                    await updateSocialTag({ variables: { oldTag: selectedValue, newTag: value } });
                } else if (showWordModal) {
                    await updateSocialFilterWord({ variables: { oldWord: selectedValue, newWord: value } });
                }
            } else {
                // ADD
                // eslint-disable-next-line no-lonely-if
                if (showTagModal) {
                    await addSocialTag({ variables: { tag: value } });
                } else if (showWordModal) {
                    await addSocialFilterWord({ variables: { word: value } });
                }
            }
            clearAll();
            form.resetFields();
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "social.somethingWentWrong" }));
        }
    }

    function renderModal() {
        return (
            <FormModal
                cancelButtonProps={{
                    onClick: clearAll
                }}
                className="noModalFooter"
                confirmLoading={loading}
                hidden={!showTagModal && !showWordModal}
                okButtonProps={{
                    onClick: submitForm,
                    label: <FormattedMessage id="save" />
                }}
            >
                <Form form={form}>
                    <Field
                        id="value"
                        label={<FormattedMessage id={showTagModal ? "tag" : "word"} />}
                        name="value"
                    />
                </Form>
            </FormModal>
        );
    }

    if (!socialTags || !socialFilterWords) {
        return <Spin />;
    }

    return (
        <SocialPageStyle>
            <HeaderContent>
                <FormattedMessage id="social.social" />
                <ContentTabs
                    currentTab={currentTab}
                    handleTabSelectedLanguage={changeSelectedTab}
                    tabs={socialTabs}
                />
            </HeaderContent>
            <GeneralCard
                addItem={addItem}
                hidden={match.params.tab !== SOCIAL_TABS.general}
                removeTag={removeTag}
                removeWord={removeWord}
                socialFilterWords={socialFilterWords}
                socialTags={socialTags}
                updateTag={updateTag}
                updateWord={updateWord}
            />
            <InstagramCardContainer hidden={match.params.tab !== SOCIAL_TABS.instagram} />
            <TwitterCardContainer hidden={match.params.tab !== SOCIAL_TABS.twitter} />
            {renderModal()}
        </SocialPageStyle>
    );
};
