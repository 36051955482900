import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { FieldsMapper } from "@components/genericFields/fieldsMapper";
import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useAuth } from "@context/authContext/context";
import { CreditConfigTypeFormValues, creditConfigTypeToFormValues, findConfigType, formValuesToCreditConfigType, getCappingValueForConfigType } from "@utils/mappers/creditsMapper";
import { Button, Card, Form, Select, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AddCreditTypePageProps } from "./addCreditTypePageContainer";
import { HeaderActions } from "./addCreditTypePageStyle";

export type CreditVersion = "generic" | "activityV1" | "activityV2" | "activityV3";

const creditVersionOptions: CreditVersion[] = ["generic", "activityV1", "activityV2", "activityV3"];

export const AddCreditTypePage: React.FC<AddCreditTypePageProps> = (props) => {
    const { match, history,
        creditConfigCount: { creditConfigCount, loading: countLoading, error: countError },
        creditConfigs: { creditConfigs, loading, error },
        addCreditConfig, updateCreditConfig } = props;
    const creditConfig = creditConfigs?.[0];
    const configTypeId = match.params.id;
    const editMode = Boolean(match.params.id);
    const configType = findConfigType(configTypeId, creditConfig?.types);
    const [configVersion, setConfigVersion] = useState<CreditVersion>(configType?.version as CreditVersion || "generic");

    const intl = useIntl();
    const [form] = useForm();
    const { projectId } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [enabledSave, setEnabledSave] = useState<boolean>();

    const configTypeData = useMemo(() => {
        if (form) {
            return creditConfigTypeToFormValues(configVersion, configType, form);
        }
    }, [configVersion, configType, form]);

    const submitForm = async (values: CreditConfigTypeFormValues) => {
        setIsSaving(true);
        const mutationVariables = { projectId, creditConfig: formValuesToCreditConfigType(values, creditConfig, configType?.type) };
        const transformedValues = () => (creditConfigCount
            ? updateCreditConfig({ variables: mutationVariables })
            : addCreditConfig({ variables: mutationVariables }));

        try {
            await form.validateFields();
            await transformedValues();
            message.success(intl.formatMessage({ id: "webshop.credits.configUpdated" }));
            history.push("/credits");
        } catch (err) {
            message.error(intl.formatMessage({ id: "webshop.credits.configUpdateFailed" }));
        } finally {
            setIsSaving(false);
            setEnabledSave(false);
        }
    };

    if (loading || countLoading) {
        return (
            <SpinStyle>
                <Spin />
            </SpinStyle>
        );
    }

    if ((configTypeId && !configType) || error || countError) {
        history.push("/404");
    }

    return (
        <Form
            form={form}
            onFinish={submitForm}
        >
            <HeaderContent>
                <HeaderActions>
                    <div>
                        {enabledSave && <UnsavedChanges key="warning" />}
                        <Button
                            className="headerButton"
                            htmlType="submit"
                            loading={isSaving}
                            type="primary"
                        >
                            <FormattedMessage id="saveChanges" />
                        </Button>
                    </div>
                </HeaderActions>
            </HeaderContent>
            <Card>
                <ContentHeader
                    title={editMode ? configType?.type || "" : "New config type"}
                />
                <SelectField
                    required
                    id="version"
                    initialValue={configVersion}
                    label="Version"
                    name="version"
                    onChange={(value) => {
                        setConfigVersion(value as CreditVersion);
                    }}
                >
                    {creditVersionOptions.map(version => (
                        <Select.Option
                            key={version}
                            value={version}
                        >
                            {version}
                        </Select.Option>
                    ))}
                </SelectField>
                <FieldsMapper
                    disableTitle
                    disable={false}
                    form={form}
                    intl={intl}
                    jsonData={configTypeData}
                    name={["typeSpecificProps"]}
                />
                <Form.Item>
                    <Field
                        numberType
                        initialValue={getCappingValueForConfigType(configType?.type, creditConfig?.capping)}
                        label="Capping"
                        min={0}
                        name="capping"
                    />
                </Form.Item>
            </Card>
        </Form>
    );
};
