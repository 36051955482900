import { languageCodeToObject } from "@pages/translationsPage/constants/languages";
import { languages } from "environment";
import React, { createContext, useReducer } from "react";
import { Action, enabledLanguagesReducer } from "./reducers";

export type EnabledLanguageType = {
    code: string;
    label: string;
}[]

export type EnabledLangState = {
    enabledLanguages: EnabledLanguageType;
}

export const initialLangState = {
    enabledLanguages: languageCodeToObject(languages)
};

const EnabledLangContext = createContext<{
    enabledLangState: EnabledLangState;
    globalDispatch: React.Dispatch<Action>;
}>({
    enabledLangState: initialLangState,
    globalDispatch: () => null
});

const EnabledLangProvider: React.FC = ({ children }) => {
    const [enabledLangState, globalDispatch] = useReducer(enabledLanguagesReducer, initialLangState);

    return (
        <EnabledLangContext.Provider value={{ enabledLangState, globalDispatch }}>
            {children}
        </EnabledLangContext.Provider>
    );
};

const useEnabledLang = () => {
    const { enabledLangState } = React.useContext(EnabledLangContext);

    return enabledLangState;
};

export { EnabledLangContext, EnabledLangProvider, useEnabledLang };

