import styled from "styled-components";

export const ActivityWidgetStyle = styled.div`
    .ant-row {
        width: 100%;
    }
`;

export const RowFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
