/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { CommentsTableData } from "@components/comments/commentsTable";
import { CommentFragment } from "@graphql2/types";

export const tableComments = (originals?: CommentFragment[]): CommentsTableData[] => {
    if (!originals || !originals.length) {
        return [];
    }

    const comments: CommentsTableData[] = [];

    originals.forEach((commentItem: CommentFragment) => {
        const { id, comment, reported, moderated, user, parentId, subComments } = commentItem;

        if (!commentItem) {
            return null;
        }

        comments.push({
            id,
            userId: user && user.id,
            displayName: (user && user.displayName) || "",
            comment,
            parentId,
            reported,
            date: new Date(parseInt(id.toString().substring(0, 8), 16) * 1000).getTime(),
            status: (!moderated && !reported) || false,
            statusType: moderated && reported
                ? "moderatedAndReported"
                : moderated
                    ? "moderated"
                    : reported
                        ? "reported"
                        : "active"
        });

        subComments.forEach(subComment => comments.push({
            id: subComment.id,
            userId: subComment.user && subComment.user.id,
            displayName: subComment.user ? subComment.user.displayName : "",
            comment: subComment.comment,
            parentId: subComment.parentId,
            reported: subComment.reported,
            date: new Date(parseInt(subComment.id.toString().substring(0, 8), 16) * 1000).getTime(),
            status: (!subComment.moderated && !subComment.reported) || false,
            statusType: subComment.moderated && subComment.reported
                ? "moderatedAndReported"
                : subComment.moderated
                    ? "moderated"
                    : subComment.reported
                        ? "reported"
                        : "active"
        }));
    });

    return comments;
};
