import styled from "styled-components";

export const AddCreditTypePageStyle = styled.div`
    h3 {
        color: #A0C63D;
        margin: 15px 0 0 0;
        font-weight: 600;
    }
`;

export const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
