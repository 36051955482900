import { ContentHeader } from "@components/contentHeader/contentHeader";
import { CkeField } from "@components/field/ckeField";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useEnabledLang } from "@context/enabledLangContext/context";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ContentsLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ContentFormValues {
    title: LanguageObject;
    preview: LanguageObject;
    content: LanguageObject;
    enabledLocales?: string[];
}

export interface ContentCardProps {
    form: FormInstance;
    hidden?: boolean;
    defaultValues: Partial<ContentFormValues>;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    customErrors?: any;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const titleField = (props: ContentsLanguageFieldProps) => (
    <Field
        {...props.sharedProps}
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
    />
);

const previewField = (props: ContentsLanguageFieldProps) => (
    <Field
        {...props.sharedProps}
        key={`preview.${props.language}`}
        hidden={props.hidden}
        id={`preview.${props.language}`}
        label={<FormattedMessage id="preview" />}
        name={["preview", props.language]}
        rules={[{
            required: true,
            message: <FormattedMessage id="form.isrequired" />
        }]}
    />
);

const contentField = (props: ContentsLanguageFieldProps) => props.defaultValues.content && (
    <CkeField
        {...props.sharedProps}
        key={`content.${props.language}`}
        disabled={props.sharedProps.disabled}
        hasError={props.extra?.content?.[props.language]}
        hidden={props.hidden}
        id={`content.${props.language}`}
        initialValue={props.defaultValues.content[props.language]}
        label={<FormattedMessage id="content" />}
        name={["content", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        onChangeUnsaved={props.sharedProps.onBlur}
    />
);

export const ContentCard: React.FC<ContentCardProps> = (props) => {
    const { changeSelectedLanguage, hidden, form, defaultValues, languageErrors, customErrors, disabled, onBlur, activeLanguage } = props;
    const { enabledLanguages } = useEnabledLang();

    const sharedProps = {
        disabled,
        form,
        onBlur
    };

    const langFieldProps = React.useMemo(
        () => enabledLanguages.map(
            language => ({ form, sharedProps, defaultValues, language: language.code, hidden: language.code !== activeLanguage, extra: customErrors })
        ),
        [enabledLanguages, activeLanguage, customErrors]
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="content" />}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />
            {langFieldProps.map(titleField)}
            {langFieldProps.map(previewField)}
            {langFieldProps.map(contentField)}
        </Card>
    );
};
