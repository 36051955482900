import { EventWidgetStyle } from "@components/dashboard/widgets/eventWidget/eventWidgetStyle";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { Alert, Form, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { ModeSelection } from "@components/dashboard/components/modeSelection/modeSelection";
import { DynamicPreviewWrapper } from "@components/dashboard/dynamicPreview/dynamicPreviewWrapper";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { FixedEventsFieldContainer } from "./fixedEventsField/fixedEventsFieldContainer";

const DISPLAY_TYPES = ["card", "list"];
const ORDER = ["id", "start", "end", "publishedOn", "registration.end"];

interface EventWidgetProps extends WidgetProps {

}

export const EventWidget: React.FC<EventWidgetProps> = (props) => {
    const { applications, form, widgetId } = props;

    return (
        <EventWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
                <SelectField
                    name={["widgets", widgetId, "displayType"]}
                    label={<FormattedMessage id="eventWidget.displayType" />}
                    id="displayType"
                >
                    {DISPLAY_TYPES.map(type => <Select.Option value={type} key={type}>{type}</Select.Option>)}
                </SelectField>
                <ModeSelection
                    widgetId={widgetId}
                    form={form}
                    moduleName="event"
                />
                <Form.Item
                    dependencies={[["widgets", widgetId, "mode"]]}
                >
                    {() => {
                        const mode = form.getFieldValue(["widgets", widgetId, "mode"]);
                        return (
                            mode === "dynamic"
                                ? (
                                    <DynamicPreviewWrapper
                                        titleId="eventWidget.dynamic"
                                        type={PreviewTypes.event}
                                        paginationOptions={{
                                            form,
                                            defaults: {
                                                fields: ORDER
                                            },
                                            id: widgetId
                                        }}
                                        widgetId={widgetId}
                                        form={form}
                                    />
                                )
                                : (
                                    <FixedEventsFieldContainer
                                        eventOptions={{
                                            form,
                                            id: widgetId
                                        }}
                                    />
                                )
                        );
                    }}
                </Form.Item>
                <Flex className="imageInherit" fullWidth margin="15px 0 0">
                    <Alert message={<FormattedMessage id="eventWidget.imageInherit" />} type="info" />
                </Flex>
            </WidgetConfigStyle>
        </EventWidgetStyle>
    );
};
