import { CommentCard } from "@components/comments/commentCard/commentCard";
import { getComments } from "@graphql2/graphql_op";
import { CommentFilter, GetCommentsProps, Paging, RemoveCommentProps, UpdateCommentProps, withGetComments, withRemoveComment, withUpdateComment } from "@graphql2/types";
import { cleanCache } from "@utils/cleanCacheHoc";
import { InjectedIntlProps } from "@utils/override.types";
import { Comment_admin } from "@utils/roles";
import { WithAuthContextProps, withAuthContext } from "@utils/withAuthContext";
import { LocalStateProps, localState } from "@utils/withLocalState";
import * as React from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";

export interface CommentCardContainerProps {
    typeId: string;
    type: string;
    noCard?: boolean;
}

export interface LocalState {
    filter?: CommentFilter;
    paging?: Paging;
    search?: string;
}

const refetchGetCommentsQueryConfig = {
    refetchQueries: () => ["getComments"],
    awaitRefetchQueries: true,
    update: cleanCache([getComments])
};

const withLocalState = localState<LocalState>({});

const withComments = withGetComments({
    name: "comments",
    options(ownProps: LocalState & CommentCardContainerProps & WithAuthContextProps) {
        return {
            variables: {
                filter: {
                    ...ownProps.filter,
                    type: ownProps.type,
                    typeId: ownProps.typeId
                },
                paging: ownProps.paging
            }
        };
    },
    skip(ownProps: WithAuthContextProps) {
        return !ownProps.hasRole(Comment_admin);
    }
});

const withUpdateCommentMutation = withUpdateComment({
    name: "updateComment",
    options: refetchGetCommentsQueryConfig
});

const withRemoveCommentMutation = withRemoveComment({
    name: "removeComment",
    options: refetchGetCommentsQueryConfig
});

export const CommentCardContainer = compose<React.ComponentClass<CommentCardContainerProps>>(
    withLocalState,
    withAuthContext,
    withComments,
    withUpdateCommentMutation,
    withRemoveCommentMutation,
    injectIntl
)(CommentCard);

export type CommentCardProps =
    WithAuthContextProps
    & CommentCardContainerProps
    & LocalStateProps<LocalState>
    & MaybeElements<GetCommentsProps<{}, "comments">>
    & UpdateCommentProps<{}, "updateComment">
    & RemoveCommentProps<{}, "removeComment">
    & InjectedIntlProps;
