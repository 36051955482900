import { Flex } from "@components/flex/flex";
import styled from "styled-components";

export const AddCheckupPageStyle = styled.div`
    ${Flex} ${Flex} > div + div {
        margin-left: 1em;
    }
    ${Flex} + ${Flex} {
        margin-left: 2em;
    }
`;
