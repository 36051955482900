import { OrdersTableData } from "@components/orders/ordersTable";
import { GetOrdersQuery } from "@graphql2/types";
import { getText } from "@utils/getText";

export function translateOrders(originals: GetOrdersQuery["articleOrders"]): OrdersTableData[] {
    const translatedOrders: OrdersTableData[] = [];

    originals.forEach(order => {
        const { id, user, orders: orderedArticles } = order;
        const { id: userId, email: userEmail } = user;

        const articlesMapped = orderedArticles.map((orderedArticle) => {
            return {
                articleId: orderedArticle.article.id,
                articleTitle: getText(orderedArticle.article.title),
                articleAmount: orderedArticle.amount
            };
        });

        translatedOrders.push({
            id,
            userId,
            userEmail,
            orderedArticles: articlesMapped
        });
    });

    return translatedOrders;
}
