import { Slider } from "antd";
import * as React from "react";
import Cropper from "react-easy-crop";
import { Point } from "react-easy-crop/types";
import { FormattedMessage } from "react-intl";
import { ImageCropContainerStyle, ImageCropControls, ImageCropControl } from "../imageUploadStyle";
import getCroppedImg from "./cropImage";
import { FormModal } from "../../modal/modal";

export enum ImageRatios {
    AVATAR = 1 / 1,
    DEFAULT = 1440 / 500
}

export interface ImageCropProps {
    src?: string;
    aspect: number;
    zoom: boolean;
    rotate: boolean;
    onImageCrop?(img?: string): void;
}

export interface ImageCropState {
    crop: {
        x: number;
        y: number;
    },
    src?: string;
    zoomVal: number;
    rotateVal: number;
    croppedAreaPixels?: {
        width: number;
        height: number;
        x: number;
        y: number;
    },
    loading?: boolean;
}

const MODAL_WIDTH = 600;
const MIN_ZOOM = 1;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.1;
const MIN_ROTATE = 0;
const MAX_ROTATE = 360;
const ROTATE_STEP = 1;

export const ImageCrop: React.FC<ImageCropProps> = ({ aspect, src, onImageCrop, rotate, zoom }) => {
    const [loading, setLoading] = React.useState(false);
    const [showModal, setModalVisible] = React.useState(true);
    const [rotateVal, setRotateVal] = React.useState(0);
    const [croppedAreaPixels, setCropArea] = React.useState({
        height: 0,
        width: 0,
        x: 0,
        y: 0
    });
    const [crop, setCropValue] = React.useState<Point>({ x: 0, y: 0 });
    const [zoomVal, setZoomVal] = React.useState(1);

    const onOk = async () => {
        setLoading(true);
        if (onImageCrop) {
            const img = await getCroppedImg(
                src,
                croppedAreaPixels,
                rotateVal
            );
            onImageCrop(img);
        }

        setLoading(false);
        setModalVisible(false);
    };

    const onCropComplete = async (_, cropArea) => {
        setCropArea(cropArea);
    };

    return (
        <FormModal
            hidden={!showModal}
            title={<FormattedMessage id="imageCrop.title" />}
            maxWidth={MODAL_WIDTH}
            maskClosable={false}
            destroyOnClose
            confirmLoading={loading}
            closeIcon
            okButtonProps={{
                type: "primary",
                onClick: onOk
            }}
            cancelButtonProps={{
                onClick: () => setModalVisible(false)
            }}
        >
            <ImageCropContainerStyle>
                <Cropper
                    image={src}
                    onCropChange={setCropValue}
                    aspect={aspect}
                    zoomWithScroll={zoom}
                    crop={crop}
                    zoom={zoomVal}
                    rotation={rotateVal}
                    onCropComplete={onCropComplete}
                    classes={{ containerClassName: "image-crop-container", mediaClassName: "image-crop-media" }}
                />
            </ImageCropContainerStyle>
            <ImageCropControls>
                {zoom
                    && (
                        <ImageCropControl>
                            <FormattedMessage id="imageCrop.zoom" />
                            <Slider
                                min={MIN_ZOOM}
                                max={MAX_ZOOM}
                                step={ZOOM_STEP}
                                value={zoomVal}
                                onChange={setZoomVal}
                            />
                        </ImageCropControl>
                    )}
                {rotate
                    && (
                        <ImageCropControl>
                            <FormattedMessage id="imageCrop.rotate" />
                            <Slider
                                min={MIN_ROTATE}
                                max={MAX_ROTATE}
                                step={ROTATE_STEP}
                                value={rotateVal}
                                onChange={setRotateVal}
                            />
                        </ImageCropControl>
                    )}
            </ImageCropControls>
        </FormModal>
    );
};
