import * as React from "react";
import { Card } from "antd";
import { TableContainer } from "@components/table/tableContainer";
import { instagramFeedTableMapper } from "@utils/mappers/socialMapper";
import { autobind } from "core-decorators";
import { TableFilter } from "@components/table/tableFilters";
import { InstagramFeedTable } from "@components/social/instagramFeedTable";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { onTableChange } from "@utils/withPagingAndSort";
import { InstagramCardProps } from "./instagramCardContainer";

@autobind
export class InstagramCard extends React.Component<InstagramCardProps> {
    public render() {
        const {
            hidden,
            instagramFeed,
            changeVisibleInstagramFeed,
            count,
            filterQuery
        } = this.props;

        return (
            <Card hidden={hidden}>
                <TableContainer
                    tableFilter={{
                        filterOn: { title: true },
                        updateFilter: this.applyFilter
                    }}
                    pagination={{
                        current: filterQuery.skip ? filterQuery.skip / filterQuery.pageSize + 1 : 0,
                        pageSize: filterQuery.pageSize,
                        total: (count && count.instagramFeedCount) || 0
                    }}
                    totalCount={(count && count.instagramFeedCount) || 0}
                    loading={(instagramFeed && instagramFeed.loading) || (count && count.loading)}
                    TableComponent={InstagramFeedTable}
                    dataSource={instagramFeedTableMapper((instagramFeed && instagramFeed.instagramFeed) || [])}
                    onChange={this.updateTableSettings}
                    handlers={{
                        changeVisibleInstagramFeed
                    }}
                />
            </Card>
        );
    }

    private applyFilter(titleFilter: TableFilter) {
        const { filterQuery } = this.props;
        filterQuery.updateUrlQuery({
            ...filterQuery,
            skip: 0,
            textSearch: titleFilter.title,
            tags: titleFilter.tags,
            locales: titleFilter.locales
        });
    }

    private updateTableSettings(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) {
        const { filterQuery } = this.props;
        const { paging: { limit, skip }, sort } = onTableChange(pagination, filters, sorter);

        filterQuery.updateUrlQuery({
            pageSize: limit,
            skip,
            sort: (sort && sort.order) || filterQuery.sort,
            sortBy: (sort && sort.field) || filterQuery.sortBy
        });
    }
}
