interface MSNavigator extends Navigator {
    msSaveBlob: (blob: Blob, defaultName?: string) => boolean;
}

export function createBlob(object) {
    return new Blob([JSON.stringify(object, undefined, 4)], { type: "json" });
}

export function saveFile(name: string, file: Blob) {
    // IE fallback
    if ((navigator as MSNavigator).msSaveBlob) {
        (navigator as MSNavigator).msSaveBlob(file, name);

        return;
    }

    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
}
