import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { getEventGroupCount, getEventGroups } from "@graphql2/graphql_op";
import { GetEventGroupCountProps, GetEventGroupsProps, GetEventTitlesProps, RemoveEventGroupProps, withGetEventGroupCount, withGetEventGroups, withGetEventTitles, withRemoveEventGroup } from "@graphql2/types";
import { cleanCache } from "@utils/cleanCacheHoc";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { EventGroupPage } from "./eventGroupPage";

const withEventGroups = withGetEventGroups({
    name: "eventGroups",
    options: ({ paging }: GraphQLPagingAndSort) => ({
        variables: {
            paging
        }
    })
});

const withEventGroupCount = withGetEventGroupCount({
    name: "eventGroupCount"
});

const withEventTitles = withGetEventTitles({
    name: "eventTitles",
    options: {
        variables: {
            filter: {
                published: true,
                endsAfter: moment().startOf("day").valueOf()
            }
        }
    }
});

const withRemoveEventGroupMutation = withRemoveEventGroup({
    name: "removeEventGroup",
    options: {
        refetchQueries: () => ["getEventGroups"],
        awaitRefetchQueries: true,
        update: cleanCache([getEventGroups, getEventGroupCount])
    }
});

export const EventGroupPageContainer = compose<React.ComponentClass<EventGroupPageProps>>(
    withRouter,
    withParseDynamicFilterBar(),
    withEventGroups,
    withEventGroupCount,
    withEventTitles,
    withRemoveEventGroupMutation
)(EventGroupPage);

export type EventGroupPageProps =
    RouteComponentProps &
    GraphQLPagingAndSort &
    GetEventGroupsProps<{}, "eventGroups"> &
    GetEventGroupCountProps<{}, "eventGroupCount"> &
    GetEventTitlesProps<{}, "eventTitles"> &
    RemoveEventGroupProps<{}, "removeEventGroup">;
