/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import { AddTranslationMutationVariables, TextByLanguageInput, Translation, UpdateTranslationMutationVariables } from "@graphql2/types";
import { csvToJson, readFile } from "@utils/readFile";
import { Button, Upload, message } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ImportTranslationsProps } from "./importTranslationsContainer";
import { ImportTranslationsModalContainer } from "./modal/importTranslationsModalContainer";

export const ImportTranslations: React.FC<ImportTranslationsProps> = (props) => {
    const { onSubmit, updateTranslation, addTranslation } = props;
    const intl = useIntl();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [uploadErrors, setUploadErrors] = React.useState<string[]>([]);
    const [importedTranslations, setImportedTranslations] = React.useState<any[]>();
    const [uploading, setUploading] = React.useState<boolean>(false);

    const handleImport = async (info: { file: File; }) => {
        setIsProcessing(true);

        try {
            const data = await readFile(info.file);
            const translations = csvToJson<Record<string, string>>(data);
            setImportedTranslations(translations.filter(t => t.key)); // will filter out any potential empty lines
        } catch (error) {
            setUploadErrors([...uploadErrors, "upload.tryAgain"]);
            setIsProcessing(false);
        }

        setIsProcessing(false);
    };

    const updateKeys = (translations: UpdateTranslationMutationVariables[]) => {
        translations.forEach(async t => {
            try {
                const result = await updateTranslation({
                    variables: t
                });

                if (!result && t.key) {
                    setUploadErrors([...uploadErrors, t.key]);
                }
            } catch (e) {
                console.error(e);
                t.key && setUploadErrors([...uploadErrors, t.key]);
            }
        });
    };

    const addKeys = (translations: AddTranslationMutationVariables[]) => {
        translations.forEach(async t => {
            try {
                const result = await addTranslation({
                    variables: t
                });

                if (!result) {
                    setUploadErrors([...uploadErrors, t.key]);
                }
            } catch (e) {
                console.error(e);
                setUploadErrors([...uploadErrors, t.key]);
            }
        });
    };

    const onProcessTranslations = async (updateExisting: boolean, existingTranslations: Translation[], duplicatesToKeep?: { [key: string]: number; }) => {
        setUploading(true);

        if (!importedTranslations) {
            return;
        }

        try {
            const existingKeys = existingTranslations.map(et => et.key);
            let importedWithoutDuplicates = importedTranslations;

            if (duplicatesToKeep) {
                importedWithoutDuplicates = importedTranslations.filter((item, i) => {
                    if (Object.keys(duplicatesToKeep).includes(item.key)) {
                        return duplicatesToKeep[item.key] === i;
                    }

                    return true;
                });
            }

            const translationsToCreate = importedWithoutDuplicates.filter(it => !existingKeys.includes(it.key));
            const translationsToUpdate = importedWithoutDuplicates.filter(it => existingKeys.includes(it.key));

            if (updateExisting) {
                const mappedForUpload: UpdateTranslationMutationVariables[] = existingTranslations.map(t => {
                    const imported = translationsToUpdate.find(nt => nt.key === t.key);
                    const { key, ...languages } = imported;

                    const translationsByLanguageInput: TextByLanguageInput[] = Object.keys(languages).map(value => ({
                        language: value,
                        text: languages[value] || `${key}(${value})`
                    }));

                    const existingTranslations = t.translations
                        .filter(t => t && t.language && !Object.keys(languages).includes(t.language)) // filter out existing languages
                        .map(t => _.omit(t, "__typename")); // get rid of __typename prop

                    return {
                        id: t.id,
                        key: t.key,
                        translations: [
                            ...existingTranslations,
                            ...translationsByLanguageInput
                        ]
                    } as UpdateTranslationMutationVariables;
                });

                updateKeys(mappedForUpload);
            }

            const mappedForCreate: AddTranslationMutationVariables[] = translationsToCreate.map(translation => {
                const { key, ...languages } = translation;

                const translationsByLanguageInput: TextByLanguageInput[] = Object.keys(languages).map(value => ({
                    language: value,
                    text: languages[value] || `${key}(${value})`
                }));

                return {
                    key,
                    translations: translationsByLanguageInput
                } as AddTranslationMutationVariables;
            });

            await addKeys(mappedForCreate);
            await onSubmit(importedWithoutDuplicates.map(t => t.key));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "somethingWentWrong" }));
        }
        setUploading(false);
        setImportedTranslations([]);
    };

    return (
        <React.Fragment>
            {(importedTranslations && importedTranslations.length && (
                <ImportTranslationsModalContainer
                    importedTranslations={importedTranslations}
                    keys={importedTranslations.map(t => t.key)}
                    uploading={uploading}
                    onAbort={() => setImportedTranslations([])}
                    onSubmit={onProcessTranslations}
                />
            )) || null}
            <Upload
                accept="application/csv"
                customRequest={handleImport}
            >
                <Button
                    className="headerButton"
                    loading={isProcessing}
                    type="primary"
                >
                    <FormattedMessage id="translations.import" />
                </Button>
            </Upload>
        </React.Fragment>
    );
};
