import { Row } from "antd";
import styled from "styled-components";

// tslint:disable-next-line:no-any
export const ChallengeVisualisationRow = styled(Row as any)`

    display: flex;
    align-items: flex-start;

    button {
        margin-bottom: 0.7em;
        width: 100%
    }
`;

export const ConditionsLeaderboardInfoStyle = styled.div`
    p {
        span {
            font-weight: bold;
        }
    }


    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
`;
