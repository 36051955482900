import { TableProps } from "@components/table/tableContainer";
import { TableStyle } from "@components/table/tableStyle";
import * as React from "react";
import { defaultTablePaginationSettings } from "@utils/withPagingAndSort";
import { TableFilters } from "./tableFilters";
import { TablePagination } from "./tablePagination";

export interface TableState {

}

export const Table: React.FC<TableProps> = (props) => {
    const { TableComponent, tableFilter, dataSource, loading, handlers, history, pagination, applications, onChange, ...other } = props;
    const mergedPagination = React.useMemo(() => {
        let proposedPagination = pagination;
        const notFirstPage = pagination?.current && pagination.current > 1;
        const noData = !loading && !dataSource.length;

        if (noData && notFirstPage) {
            proposedPagination = {
                ...proposedPagination,
                current: 1
            };
        }

        return {
            ...defaultTablePaginationSettings,
            ...proposedPagination
        };
    }, [pagination, dataSource]);

    React.useEffect(() => {
        const pageMismatch = mergedPagination.current !== pagination?.current;
        if (pageMismatch && onChange) {
            onChange(mergedPagination, {}, {});
        }
    }, [pagination, dataSource]);

    const sortedApplications = React.useMemo(
        () => {
            if (!applications) {
                return;
            }
            return applications.map((application) => {
                return {
                    ...application,
                    roles: [...application.roles].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                };
            });
        },
        [applications]
    );

    return (
        <TableStyle>
            {
                tableFilter && (
                    <TableFilters
                        updateFilter={tableFilter.updateFilter}
                        filterOn={tableFilter.filterOn || {}}
                        applications={sortedApplications}
                    />
                )
            }
            <TableComponent
                dataSource={dataSource}
                loading={loading}
                handlers={handlers}
                history={history}
                applications={sortedApplications}
                pagination={mergedPagination}
                onChange={onChange}
                {...other}
            />
            <TablePagination />
        </TableStyle>
    );
};
