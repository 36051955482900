import { FormInstance } from "antd/lib/form/Form";

export interface FormOptionFactoryProps {
    index: number;
}

export const formOptionFactory = (index: number) => {
    return {
        value: `option${index}`,
        title: {}
    };
};
