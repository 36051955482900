import { ModalStyle, ModalStyleProps, Sentinal, CloseIcon, StyledButton } from "@components/modal/modalStyle";
import { Flex } from "@components/flex/flex";
import { Button } from "antd";
import { ModalProps } from "antd/lib/modal";
import { ButtonProps } from "antd/lib/button";
import * as React from "react";
import { FormattedMessage } from "react-intl";


export type CustomButtonProps = ButtonProps & { label?: string | JSX.Element }

export type FormModalProps = ModalProps & ModalStyleProps & {
    children: React.ReactNode;
    maxWidth?: number;
    okButtonProps?: CustomButtonProps;
    cancelButtonProps?: CustomButtonProps;
};

/*
 * Ant Modals are rendered outside of the container.
 * Because of this, it can't be part of a form.
 * This component looks like the modal from ant, but is rendered where it is used
 * It's a copy of the HTML rendered by <Modal/>, because of this there are a number of classnames
 */

export const FormModal: React.FC<FormModalProps> = (props) => {
    const {
        hidden,
        maxWidth,
        title,
        closeIcon,
        okButtonProps: { label: okText, ...otherOkButtonProps } = {},
        cancelButtonProps: { label: cancelText, ...otherCancelButtonProps } = {}
    } = props;

    return (
        <ModalStyle hidden={hidden}>
            <div className="ant-modal-mask" />
            <div tabIndex={-1} className="ant-modal-wrap " role="dialog">
                <div
                    role="document"
                    className="ant-modal"
                    style={{
                        maxWidth: maxWidth ? `${maxWidth}px` : "520px",
                        width: "100%"
                    }}
                >
                    <Sentinal tabIndex={0}>sentinelStart</Sentinal>
                    <div className="ant-modal-content">
                        {(title || closeIcon)
                            && (
                                <div className="ant-modal-header">
                                    <Flex className="ant-modal-title" alignItems="baseline">
                                        {title}
                                        {closeIcon && <StyledButton icon={<CloseIcon />} type="text" onClick={otherCancelButtonProps.onClick} />}
                                    </Flex>
                                </div>
                            )}
                        <div className="ant-modal-body">
                            {props.children}
                        </div>

                        <div className="ant-modal-footer">
                            <div>
                                {otherCancelButtonProps.onClick && (
                                    <Button {...otherCancelButtonProps}>
                                        {cancelText || <FormattedMessage id="cancel" />}
                                    </Button>
                                )}
                                <Button {...otherOkButtonProps}>
                                    {okText || <FormattedMessage id="ok" />}
                                </Button>
                            </div>
                        </div>

                    </div>
                    <Sentinal tabIndex={0}>sentinelEnd</Sentinal>
                </div>
            </div>
        </ModalStyle>
    );
};
