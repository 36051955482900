import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

export class FormFieldLink extends FormFieldBase {
    public renderBody() {
        const { index, deleteFormElement } = this.props;

        return (
            <React.Fragment>
                <FormItemFlex>
                    <Field
                        {...this.sharedProps}
                        name={[index, "property"]}
                        initialValue={this.props.property}
                        label={<FormattedMessage id="translationKey" />}
                    />
                    <DeleteFormField
                        as={DeleteOutlined}
                        onClick={deleteFormElement}
                    />
                </FormItemFlex>
                <FormItemFlex>
                    <Field
                        name={[index, "defaultValue"]}
                        {...this.sharedProps}
                        label={<FormattedMessage id="URL" />}
                    />
                </FormItemFlex>
            </React.Fragment>
        );
    }
}
