import { Layout } from "antd";
import styled from "styled-components";

export const Logo = styled.h1`
    margin: 10px 0;
    padding-left: 24px;
`;

export const VersionNumber = styled.p`
    margin: 10px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
`;

export const FixedSideBar = styled(Layout.Sider)`
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
`;
