/* eslint-disable max-lines-per-function */
import { LoadingOutlined } from "@ant-design/icons";
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { createTabs } from "@components/tabs/createTabs";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { JourneyDiscount } from "@graphql2/types";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { ADD_JOURNEY_TABS, AddJourneysPageProps } from "@pages/addJourneysPage/addJourneysPageContainer";
import { AddJourneysPageStyle } from "@pages/addJourneysPage/addJourneysPageStyle";
import { Content, EditJourneyContentCard } from "@pages/addJourneysPage/content/editJourneyContentCard";
import { JourneyContentCard } from "@pages/addJourneysPage/content/journeyContentCard";
import { contentFactory } from "@pages/addJourneysPage/factories/contentFactory";
import { EventJourneySettingsFormValues, JourneySettingsCard, PersonalJourneySettingsFormValues } from "@pages/addJourneysPage/general/journeySettingsCard";
import { JourneyTitleAndDescriptionFormValues, TitleAndDescriptionCard } from "@pages/addJourneysPage/general/titleAndDescriptionCard";
import { JourneyVisibilityFormValues, VisibilityCard } from "@pages/addJourneysPage/general/visibilityCard";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyJourney } from "@utils/emptyItems/emptyJourney";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { isObjectWithKeys } from "@utils/isObjectWithKeys";
import { hasError } from "@utils/mapValidateErrorEntity";
import { formValuesToJourney, journeyToFormValues } from "@utils/mappers/journeyMapper";
import { Breadcrumb, Button, Form, Popconfirm, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { omit } from "lodash";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useGlobalActions } from "../../context/enabledLangContext/actions";

export type ErrorObj = object;

export type JourneyErrors = {
    title: ErrorObj;
    subTitle: ErrorObj;
    imageUrl: ErrorObj;
    eventTitle: ErrorObj;
    content: ErrorObj;
};

export interface JourneyDiscountToggle extends JourneyDiscount {
    disabled: boolean;
}

export type JourneyFormValues = JourneyTitleAndDescriptionFormValues
    & JourneyVisibilityFormValues
    & (EventJourneySettingsFormValues | PersonalJourneySettingsFormValues)
    & { discounts: JourneyDiscountToggle; }
    & { content: Content[]; }
    & {
        id?: string;
        imageUrl: string;
    };

export const AddJourneysPage: React.FC<AddJourneysPageProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();

    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [unsaved, setUnsaved] = React.useState(Boolean(props.location.state && props.location.state.import));
    const [editContentIndex, setEditContentIndex] = React.useState<number>();
    const [isSaving, setIsSaving] = React.useState(false);
    const [fieldErrors, setErrors] = useErrors();
    const general = omit(fieldErrors || {}, "content");
    const generalHasErrors = isObjectWithKeys(general);
    const journeyTabs = createTabs([
        { name: ADD_JOURNEY_TABS.general, hasErrors: generalHasErrors },
        { name: ADD_JOURNEY_TABS.content, hasErrors: Boolean(fieldErrors[ADD_JOURNEY_TABS.content]) }
    ]);

    const [type, setType] = React.useState("personal");

    const tabs: string[] = [
        ADD_JOURNEY_TABS.general,
        ADD_JOURNEY_TABS.content
    ];

    const { location, match, journeys: journeyData, applications, journeyTags, removeJourney, history } = props;
    const { journeys, loading } = journeyData || {};

    const [updateEnabledLanguages, resetEnabledLanguages] = useGlobalActions();

    React.useEffect(() => {
        return () => {
            resetEnabledLanguages();
        };
    }, []);

    const defaultValues = React.useMemo(
        () => createDefaultValues(journeyToFormValues, emptyJourney, location.state?.import, loading, journeys?.[0]),
        [journeys, loading]
    );

    React.useEffect(() => {
        if (defaultValues) {
            const { enabledLocales } = defaultValues;
            if (enabledLocales && enabledLocales.length) {
                updateEnabledLanguages(enabledLocales);
            }

            setType(defaultValues.type || "personal");
        }
    }, [defaultValues]);

    const currentTab = tabs.indexOf(match.params.tab);

    // Return loading should be after all hooks
    if (!defaultValues || (applications && applications.loading)) {
        return <LoadingOutlined />;
    }

    const _removeJourney = async (id?: string) => {
        if (!id) {
            message.success(intl.formatMessage({ id: "journey.deleted" }));
            history.push("/journeys");
            return;
        }

        const res = await removeJourney({ variables: { id } });
        if (res && !res.errors) {
            message.success(intl.formatMessage({ id: "journey.deleted" }));
            history.push("/journeys");
        } else {
            message.error(intl.formatMessage({ id: "journey.deleteFailed" }));
        }
    };

    // If the type field has been filled in, update the defaultValues so the UI can update.
    // The first render would make it undefined without this check.
    const typeInForm = (form.getFieldValue("type")) || defaultValues.type || type;
    if (!defaultValues.type && typeInForm) {
        defaultValues.type = typeInForm;
    }

    const roles = getFrontendRoles(applications.applications || []);

    const journeyForm = form.getFieldsValue();

    const onSubmit = async (values: JourneyFormValues) => {
        const { updateJourney, addJourney } = props;

        setIsSaving(true);

        try {
            if (match.params.id) {
                await updateJourney({
                    variables: {
                        id: match.params.id,
                        journey: formValuesToJourney(values)
                    }
                });
                message.success(intl.formatMessage({ id: "journeys.updated" }));
                history.push("/journeys");
            } else {
                const response = await addJourney({ variables: { journey: formValuesToJourney(values) } });
                message.success(intl.formatMessage({ id: "journeys.added" }));
                history.push(`/journeys/edit/${response.data?.addJourney.id}/general`);
            }
            setUnsaved(false);
            setErrors({});
        } catch (errors) {
            console.warn("errors", errors);

            setErrors(errors);
            message.error(
                "There are some errors in the form. Please check for highlighted or empty fields."
            );
        }

        setIsSaving(false);
    };

    const onBlur = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const defaultProps = {
        form,
        changeSelectedLanguage,
        disabled: isSaving,
        onBlur
    };
    /**
     * If you switch tabs to a table screen, or go back from an edit content to content overview,
     * tables won't be updated. To update these tables, always update the redux state when switching views.
     */
    const changeSelectedTab = (index: number | string) => {
        props.history.push(
            `/journeys/${match.params.id
                ? `edit/${props.match.params.id}`
                : "add"
            }/${([ADD_JOURNEY_TABS.general, ADD_JOURNEY_TABS.content])[index]}`
        );
    };

    const backFromEditContent = () => {
        setEditContentIndex(undefined);
    };

    const generalTabActive = match.params.tab === ADD_JOURNEY_TABS.general;
    const contentTabActive = match.params.tab === ADD_JOURNEY_TABS.content;

    return (
        <AddJourneysPageStyle>
            <Form
                hideRequiredMark
                form={form}
                initialValues={defaultValues}
                layout="horizontal"
                onFinish={onSubmit}
            >
                <React.Fragment>
                    <HeaderContent>
                        <HeaderActions>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/journeys">
                                        <FormattedMessage id="overview" />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {getText(journeyForm?.title || defaultValues?.title, selectedLanguage)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    key="button"
                                    htmlType="submit"
                                    loading={isSaving}
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                                <Popconfirm
                                    cancelText={<FormattedMessage id="popup.cancelText" />}
                                    okText={<FormattedMessage id="popup.okText" />}
                                    placement="bottomRight"
                                    title={<FormattedMessage id="deleteConfirm" />}
                                    onConfirm={() => _removeJourney(defaultValues.id)}
                                >
                                    <Button
                                        className="headerButton"
                                        type="default"
                                    >
                                        <FormattedMessage id="journeys.delete" />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </HeaderActions>
                        <Flex justifyContent="space-between">
                            <ContentTabs
                                currentTab={currentTab}
                                handleTabSelectedLanguage={changeSelectedTab}
                                tabs={journeyTabs}
                            />
                        </Flex>
                    </HeaderContent>

                    <TitleAndDescriptionCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        hidden={!generalTabActive}
                        languageErrors={errorsToLanguageErrors({
                            title: fieldErrors && fieldErrors.title,
                            description: fieldErrors && fieldErrors.subTitle
                        })}
                    />
                    <VisibilityCard
                        {...defaultProps}
                        hidden={!generalTabActive}
                        intl={intl}
                    />
                    <CoverImageCard
                        {...defaultProps}
                        showSaveButton
                        defaultImage={defaultValues.imageUrl}
                        formField="imageUrl"
                        hasError={fieldErrors && fieldErrors.imageUrl}
                        hidden={!generalTabActive}
                        titleId="coverImage"
                    />
                    <JourneySettingsCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        hidden={!generalTabActive}
                        languageErrors={errorsToLanguageErrors({
                            eventTitle: fieldErrors && fieldErrors.eventTitle
                        })}
                        roles={roles}
                        tags={journeyTags.journeyTags || []}
                        type={typeInForm}
                        typeChanged={setType}
                    />
                    <Form.List name="content">
                        {(fields, { add, move, remove }) => (
                            <React.Fragment>
                                <JourneyContentCard
                                    add={() => {
                                        setUnsaved(true);
                                        return add(contentFactory());
                                    }}
                                    content={form.getFieldValue("content")}
                                    editContent={setEditContentIndex}
                                    errors={fieldErrors.content}
                                    hidden={!contentTabActive || editContentIndex !== undefined}
                                    move={move}
                                    remove={remove}
                                />
                                {fields.map((c, index) => (
                                    <EditJourneyContentCard
                                        {...defaultProps}
                                        key={JSON.stringify(c)}
                                        activeLanguage={selectedLanguage}
                                        back={backFromEditContent}
                                        content={form.getFieldValue(["content", c.name]) || defaultValues.content[c.name]}
                                        defaultImage={defaultValues.content[index] && defaultValues.content[index].imageUrl}
                                        hidden={!contentTabActive || index !== editContentIndex}
                                        index={index}
                                        languageErrors={(hasError(fieldErrors.content, [index]) && errorsToLanguageErrors(fieldErrors.content[index])) || {}}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </Form.List>
                </React.Fragment>
            </Form>
        </AddJourneysPageStyle>
    );
};
