export const emptyAmbassador = {
    title: {},
    info: {},
    description: {},
    imageUrl: "",
    userId: "",
    activityTypes: [],
    place: "",
    tags: []
};
