import { Field } from "@components/field/field";
import { CardSubtitle } from "@pages/addChallengesPage/tabs/general/challengeGeneralTabStyle";
import { Checkbox, Col, Form, Row } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SharedProps } from "../../general/sections/challengeBasicSettingsCard";
import { SettingsRowWrapper } from "../challengeAdvancedTabStyle";

export const PlacementInPlatformSettings = (props: SharedProps) => {
    return (
        <React.Fragment>
            <CardSubtitle><FormattedMessage id="challenge.placementOnPlatform" /></CardSubtitle>
            <SettingsRowWrapper>
                <Row gutter={48}>
                    <Col span={12}>
                        <Form.Item
                            name="showFullDescription"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...props}
                            >
                                <FormattedMessage id="challenge.showFullDescription" />
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="hideProgressInList"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...props}
                            >
                                <FormattedMessage id="challenge.hideProgressInList" />
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="hideProgressInDetails"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...props}
                            >
                                <FormattedMessage id="challenge.hideProgressInDetails" />
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="hideProgressInInsights"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...props}
                            >
                                <FormattedMessage id="challenge.hideProgressInInsights" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item
                            name="hideInList"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...props}
                            >
                                <FormattedMessage id="challenge.hideInChallengeList" />
                            </Checkbox>
                        </Form.Item>
                        <Field
                            numberType
                            id="priority"
                            label={<FormattedMessage id="priorityInList" />}
                            min={0}
                            name="priority"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...props}
                        />
                    </Col>
                </Row>
            </SettingsRowWrapper>
        </React.Fragment>
    );
};
