import { ArticleVoucherTable, ArticleVoucherTableData } from "@components/articles/articleVoucherTable";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { TableContainer } from "@components/table/tableContainer";
import { readFile } from "@utils/readFile";
import { Button, Card, Dropdown, Form, Menu, Typography, Upload, message, notification } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { UploadOutlined } from "@ant-design/icons";
import { ArticleVoucherItem } from "@graphql2/types";

export interface VoucherFormValues {
    vouchers?: ArticleVoucherItem[];
}

export interface VoucherCardProps {
    form: FormInstance;
    onChange(): void;
}

const { Text } = Typography;

export const VoucherCard: React.FC<VoucherCardProps> = (props) => {
    const { form, onChange } = props;

    const [selectedRows, setSelectedRows] = React.useState<ArticleVoucherTableData[]>([]);
    const [error, setError] = React.useState<string>();
    const [api, contextHolder] = notification.useNotification();

    const vouchersCount = React.useMemo(() => {
        const vouchers = form.getFieldValue("vouchers") as ArticleVoucherItem[] | undefined;

        if (!vouchers?.length) {
            return {
                allVouchersCount: 0,
                claimedVouchersCount: 0,
                vouchersLeftCount: 0
            };
        }

        const allVouchersCount = vouchers.length;
        const claimedVouchersCount = vouchers.filter((voucher) => voucher.used).length;
        const vouchersLeftCount = allVouchersCount - claimedVouchersCount;

        return { allVouchersCount, claimedVouchersCount, vouchersLeftCount };
    }, [form]);

    const updateStockValue = (operation: "add"|"extract", value = 1) => {
        const stockValue = form.getFieldValue("currentStock");
        const updatedStockValue = operation === "add" ? stockValue + value : stockValue - value;
        form.setFieldsValue({ currentStock: updatedStockValue });
    };

    const handleVouchersToDelete = (rows) => {
        setSelectedRows(rows);
    };

    const handleDeleteVouchers = (remove: (i) => void) => {
        remove(selectedRows.map(r => r.index));
        updateStockValue("extract", selectedRows.filter(article => !article.used).length);
        onChange();
    };

    const findDuplicates = (newVoucherCodes: string[]) => {
        const currentVoucherCodes = (form.getFieldValue("vouchers") as ArticleVoucherItem[]).map((voucher) => voucher.code);
        const duplicates: string[] = [];
        const notDuplicates: string[] = [];

        newVoucherCodes.forEach((newVoucherCode) => {
            if (currentVoucherCodes.includes(newVoucherCode) || notDuplicates.includes(newVoucherCode)) {
                duplicates.push(newVoucherCode);
            } else {
                notDuplicates.push(newVoucherCode);
            }
        });

        return { duplicates, notDuplicates };
    };

    const handleUpload = async (info: { file: File }) => {
        try {
            const data = await readFile<string>(info.file);
            return data.split(/\r?\n/).filter(o => o.length);
        } catch (err) {
            setError("upload.tryAgain");
        }
    };

    const handleAddByUpload = async (add: (data) => void, info: { file: File }) => {
        const data = await handleUpload(info);

        if (data) {
            const { duplicates, notDuplicates } = findDuplicates(data);

            if (duplicates.length) {
                api.open({
                    message: "Duplicate code vouchers found",
                    description: (
                        <React.Fragment>
                            They will be exluded from the import. Duplicate codes:
                            { duplicates.map((duplicateCode) => <div>&quot;{duplicateCode}&quot;</div>) }
                        </React.Fragment>
                    ),
                    type: "warning",
                    duration: 0
                });
            }

            notDuplicates.forEach(code => {
                add({ code, used: false });
                updateStockValue("add");
            });
            message.info(`New ${notDuplicates.length} vouchers added`);

            if (notDuplicates.length > 0) {
                onChange();
            }
        } else if (error) {
            message.error(<FormattedMessage id={error} />);
        }
    };

    const handleRemoveByUpload = async (remove: (i) => void, info: { file: File }) => {
        const data = await handleUpload(info);
        const vouchers = form.getFieldValue("vouchers") as ArticleVoucherItem[];

        if (data && vouchers) {
            const vouchersToRemove = vouchers.map((voucher, index) => {
                if (data.includes(voucher.code)) {
                    return { used: voucher.used, index };
                }
                return undefined;
            }).filter(voucher => voucher !== undefined) as { used: boolean, index: number }[];

            if (vouchersToRemove.length > 0) {
                remove(vouchersToRemove.map(voucher => voucher.index));
                updateStockValue("extract", vouchersToRemove.filter(voucher => !voucher.used).length);
                onChange();
            }
        }
    };

    return (
        <Card>
            {contextHolder}
            <Card title={<Text strong><FormattedMessage id="webshop.voucher.statistics" /></Text>} size="small" style={{ width: "300px", margin: "0 0 20px 0" }}>
                <div><FormattedMessage id="webshop.voucher.amount.all" />: <Text strong>{vouchersCount.allVouchersCount}</Text></div>
                <div><FormattedMessage id="webshop.voucher.amount.claimed" />: <Text strong>{vouchersCount.claimedVouchersCount}</Text></div>
                <div><FormattedMessage id="webshop.voucher.amount.left" />: <Text strong>{vouchersCount.vouchersLeftCount}</Text></div>
            </Card>
            <Form.List name="vouchers">
                {(fields, { add, remove }) => {
                    const vouchers = form.getFieldValue("vouchers");
                    return (
                        <React.Fragment>
                            <ContentHeader
                                title={<FormattedMessage id="webshop.importVouchersCard" />}
                                rightFields={
                                    [
                                        <Upload
                                            name="file"
                                            accept=".csv"
                                            multiple={false}
                                            customRequest={(file) => handleAddByUpload(add, file)}
                                        >
                                            <Button icon={<UploadOutlined />} key="button1" type="primary">
                                                <FormattedMessage id="webshop.addVouchers" />
                                            </Button>
                                        </Upload>,
                                        <Dropdown
                                            overlay={
                                                (
                                                    <Menu>
                                                        <Menu.Item icon={<UploadOutlined />}>
                                                            <Upload
                                                                name="file"
                                                                accept=".csv"
                                                                multiple={false}
                                                                customRequest={(file) => handleRemoveByUpload(remove, file)}
                                                            >
                                                                <FormattedMessage id="webshop.vouchers.removeByCSV" />
                                                            </Upload>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            disabled={!selectedRows.length}
                                                            onClick={() => handleDeleteVouchers(remove)}
                                                        >
                                                            <FormattedMessage id="webshop.vouchers.removeSelected" />
                                                        </Menu.Item>
                                                    </Menu>
                                                )
                                            }
                                            placement="bottomLeft"
                                        >
                                            <Button danger key="button2"><FormattedMessage id="webshop.openDeleteVouchers" /></Button>
                                        </Dropdown>
                                    ]
                                }
                            />
                            <TableContainer
                                loading={false}
                                TableComponent={ArticleVoucherTable}
                                dataSource={(vouchers || []).map((v, i) => ({ index: i, id: `${i}-${v.code}`, ...v }))}
                                variables={{
                                    selectedRowKeys: selectedRows.map(r => r.id)
                                }}
                                handlers={{
                                    handleSelectedVouchersOnChange: handleVouchersToDelete
                                }}
                            />
                        </React.Fragment>
                    );
                }}
            </Form.List>
        </Card>
    );
};
