import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { CardSubtitle } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityConditionStyle";
import { Card, Checkbox, Col, Form, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface SettingsCardProps {
    form: FormInstance;
}

export const SettingsCard: React.FC<SettingsCardProps> = (props) => {
    const { form } = props;

    const validateOnAfterStart = (rule, value: Moment, callback: (error?: string | undefined) => void) => {
        const start: Moment | null = form.getFieldValue(["voucherStart"]);
        if (start && value && start.isAfter(value)) {
            return callback("invalid");
        }

        return callback();
    };

    const renderMaxUsage = () => {
        const enabledMaxUsage = form.getFieldValue("enabledMaxUsage");

        return (
            <Col span={8}>
                <Field
                    numberType
                    disabled={!(enabledMaxUsage !== undefined && enabledMaxUsage)}
                    label={<FormattedMessage id="voucher.maxUsage" />}
                    name="maxUsage"
                />
            </Col>
        );
    };

    const renderMaxUsagePerUser = () => {
        const enabledMaxUsagePerUser = form.getFieldValue("enabledMaxUsagePerUser");

        return (
            <Col span={8}>
                <Field
                    numberType
                    disabled={!(enabledMaxUsagePerUser !== undefined && enabledMaxUsagePerUser)}
                    label={<FormattedMessage id="voucher.maxUsagePerUser" />}
                    name="maxUsagePerUser"
                />
            </Col>
        );
    };

    const renderDateFields = () => {
        const disabled = !form.getFieldValue("enableVoucherPeriod");
        const required = disabled ? [] : [requiredrule];

        return (
            <Row gutter={16}>
                <Col span={6}>
                    <DatePickerField
                        disabled={disabled}
                        label={<FormattedMessage id="voucher.start" />}
                        name="voucherStart"
                        rules={[...required]}
                        validateTrigger={["onChange", "onSubmit"]}
                    />
                </Col>
                <Col span={6}>
                    <DatePickerField
                        disabled={disabled}
                        label={<FormattedMessage id="voucher.end" />}
                        name="voucherEnd"
                        rules={[
                            ...required,
                            {
                                validator: validateOnAfterStart,
                                message: <FormattedMessage id="challenge.end.afterStart" />
                            }
                        ]}
                        validateTrigger={["onChange", "onSubmit"]}
                    />
                </Col>
            </Row>
        );
    };

    const renderDiscountField = () => {
        const disabled = !form.getFieldValue("enabledDiscount");

        return (
            <React.Fragment>
                <Col span={10}>
                    <SelectField
                        allowClear
                        disabled={disabled}
                        id="discountType"
                        label={<FormattedMessage id="voucher.discountType" />}
                        name={["discount", "type"]}
                        rules={(!disabled && [requiredrule]) || []}
                    >
                        {
                            ["amount", "percent"].map(type => (
                                <Select.Option
                                    key={type}
                                    value={type}
                                >
                                    {type}
                                </Select.Option>
                            ))
                        }
                    </SelectField>
                </Col>
                <Col span={10}>
                    <Field
                        numberType
                        disabled={disabled}
                        label={<FormattedMessage id="voucher.discountAmount" />}
                        name={["discount", "amount"]}
                        required={!disabled}
                    />
                </Col>
            </React.Fragment>
        );
    };

    return (
        <Card className="smallMargin">
            <ContentHeader title={<FormattedMessage id="voucher.settings" />} />
            <Row gutter={16}>
                <Col span={12}>
                    <CardSubtitle><FormattedMessage id="voucher.usage" /></CardSubtitle>
                    <Col span={24}>
                        <Form.Item
                            name="enableVoucherPeriod"
                            valuePropName="checked"
                        >
                            <Checkbox><FormattedMessage id="voucher.enableVoucherPeriod" /></Checkbox>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            dependencies={[["enableVoucherPeriod"]]}
                        >
                            {() => renderDateFields()}
                        </Form.Item>
                    </Col>
                    <Row gutter={16}>
                        <Col span={9}>
                            <Form.Item
                                name="enabledMaxUsage"
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    <FormattedMessage
                                        id="voucher.enabledMaxUsage"
                                    />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Form.Item
                            noStyle
                            dependencies={[["enabledMaxUsage"]]}
                        >
                            {() => renderMaxUsage()}
                        </Form.Item>
                    </Row>
                    <Row gutter={16}>
                        <Col span={9}>
                            <Form.Item
                                name="enabledMaxUsagePerUser"
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    <FormattedMessage
                                        id="voucher.enabledMaxUsagePerUser"
                                    />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Form.Item
                            noStyle
                            dependencies={[["enabledMaxUsagePerUser"]]}
                        >
                            {() => renderMaxUsagePerUser()}
                        </Form.Item>
                    </Row>
                </Col>
                <Col span={12}>
                    <CardSubtitle><FormattedMessage id="voucher.discount" /></CardSubtitle>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="enabledDiscount"
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    <FormattedMessage id="voucher.enabledDiscount" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Form.Item
                            noStyle
                            dependencies={[["enabledDiscount"]]}
                        >
                            {() => renderDiscountField()}
                        </Form.Item>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
