import styled from "styled-components";
import Form from "antd/lib/form";

export const CardSubtitle = styled.span`
    font-family: "pingFang", sans-serif;
    font-size: .75rem;
    font-weight: 600;
    color: #A0C63D;
`;

export const EmptyField = styled(Form.Item)`
    margin: 0px;
    .ant-form-item-control-input{
        min-height: 0px;
    }
`;
