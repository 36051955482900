
import { AddFlowMutationVariables, FlowFragment, UpdateFlowMutationVariables } from "@graphql2/types";
import { formatDate } from "@utils/dateUtils";
import { FlowFormValues } from "@utils/emptyItems/emptyFlow";
import { getText } from "@utils/getText";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import moment from "moment";

export const mapFlowToFormValues = (flow: FlowFragment): FlowFormValues => ({
    id: flow.id,
    title: toLanguageObject(flow.title),
    start: moment(flow.start),
    end: moment(flow.end),
    reminderDaysDelay: flow.reminderDaysDelay ?? undefined,
    steps: flow.steps?.map(step => ({
        id: Number(step.id),
        title: toLanguageObject(step.title),
        description: toLanguageObject(step.description),
        icon: step.icon ?? "",
        type: step.type,
        typeIds: step.typeIds
    })) ?? []
});

export function mapFormValuesToAddFlows(values: FlowFormValues, projectId: string): AddFlowMutationVariables {
    return {
        flowInput: {
            projectId,
            title: toTextByLanguage(values.title),
            start: formatDate(values.start),
            end: formatDate(values.end, true),
            steps: values.steps.map(step => ({
                id: Number(step.id),
                title: toTextByLanguage(step.title),
                description: toTextByLanguage(step.description),
                icon: step.icon,
                type: step.type,
                typeIds: step.typeIds
            })),
            reminderDaysDelay: values.reminderDaysDelay
        }
    };
}

export function mapFormValuesToUpdateFlows(values: FlowFormValues, projectId: string, id: string): UpdateFlowMutationVariables {
    return {
        flowId: id,
        ...mapFormValuesToAddFlows(values, projectId)
    };
}

export const mapToFlowsTableData = (flow: FlowFragment) => ({
    ...flow,
    title: flow.title ? getText(flow.title) : ""
});
