import * as React from "react";
import { Card } from "antd";
import { TableContainer } from "@components/table/tableContainer";
import { twitterFeedTableMapper } from "@utils/mappers/socialMapper";
import { autobind } from "core-decorators";
import { TableFilter } from "@components/table/tableFilters";
import { TwitterFeedTable } from "@components/social/twitterFeedTable";
import { onTableChange } from "@utils/withPagingAndSort";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { TwitterCardProps } from "./twitterCardContainer";

@autobind
export class TwitterCard extends React.Component<TwitterCardProps> {
    public render() {
        const {
            hidden,
            count,
            twitterFeed,
            changeVisibleTwitterFeed,
            filterQuery
        } = this.props;

        return (
            <Card hidden={hidden}>
                <TableContainer
                    tableFilter={{
                        filterOn: { title: true },
                        updateFilter: this.applyFilter
                    }}
                    pagination={{
                        current: filterQuery.skip ? filterQuery.skip / filterQuery.pageSize + 1 : 0,
                        pageSize: filterQuery.pageSize,
                        total: (count && count.twitterFeedCount) || 0
                    }}
                    totalCount={(count && count.twitterFeedCount) || 0}
                    loading={(count && count.loading) || (twitterFeed && twitterFeed.loading)}
                    TableComponent={TwitterFeedTable}
                    dataSource={twitterFeedTableMapper((twitterFeed && twitterFeed.twitterFeed) || [])}
                    onChange={this.updateTableSettings}
                    handlers={{
                        changeVisibleTwitterFeed
                    }}
                />
            </Card>
        );
    }

    private applyFilter(titleFilter: TableFilter) {
        const { filterQuery } = this.props;
        filterQuery.updateUrlQuery({
            ...filterQuery,
            skip: 0,
            textSearch: titleFilter.title,
            tags: titleFilter.tags,
            locales: titleFilter.locales
        });
    }

    private updateTableSettings(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) {
        const { filterQuery } = this.props;
        const { paging: { limit, skip }, sort } = onTableChange(pagination, filters, sorter);

        filterQuery.updateUrlQuery({
            pageSize: limit,
            skip,
            sort: (sort && sort.order) || filterQuery.sort,
            sortBy: (sort && sort.field) || filterQuery.sortBy
        });
    }
}
