import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { DashboardConfigWidgetStatisticItem } from "@utils/mappers/widgets/statisticsWidgetMapper";
import { Form, Input, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FormListFieldData } from "antd/lib/form/FormList";
import { SubPropertyTypes } from "@components/dashboard/widgets/statisticsWidget/statisticsWidget";
import { FormInstance } from "antd/lib/form";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { StatisticsWidgetPaneStyle } from "./statisticsWidgetStyle";

export interface StatisticsWidgetPaneProps {
    pane: DashboardConfigWidgetStatisticItem;
    field: FormListFieldData;
    form: FormInstance;
    widgetId: string;
}

export const StatisticsWidgetPane: React.FC<StatisticsWidgetPaneProps> = (props) => {
    const { form, widgetId, pane, field } = props;
    const { statisticType, icon } = pane;
    const subPropertyEnabled = statisticType !== "challenges" && statisticType !== "users";

    const image = icon;
    return (
        <StatisticsWidgetPaneStyle>
            <FormattedMessage id={`statisticsWidget.${pane.statisticType}`} tagName="h3" />
            <TitleSubtitleDescriptionCard
                itemKey={field.name}
                disableDescription
                disableSubtitle
                widgetId={widgetId}
                prefix={[field.name]}
            />
            <FormattedMessage id="widgetTitle.settings" tagName="h2" />
            <Form.Item
                name={[field.name, "statisticType"]}
                rules={[requiredrule]}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item>

                <SelectField
                    name={[field.name, "aggregateBy"]}
                    rules={[requiredrule]}
                    label={<FormattedMessage id="statisticsWidget.showData" />}
                    showArrow
                >
                    <Select.Option value="project" key="project"><FormattedMessage id="statisticsWidget.users" /></Select.Option>
                    {pane.statisticType !== "users" && (
                        <Select.Option value="user" key="user">
                            <FormattedMessage id="statisticsWidget.user" />
                        </Select.Option>
                    )}
                </SelectField>
            </Form.Item>
            {subPropertyEnabled && (
                <Form.Item label={<FormattedMessage id="statisticsWidget.subProperty" />}>
                    <SelectField
                        name={[field.name, "subProperty"]}
                        rules={[requiredrule]}
                        showArrow
                    >
                        {["DISTANCE", "DURATION", "CALORIES", "STEPS", "ELEVATION", "WORKOUTS"].map(
                            type => (
                                <Select.Option
                                    value={SubPropertyTypes[type]}
                                    key={SubPropertyTypes[type]}
                                >
                                    <FormattedMessage id={`statisticsWidget.${type.toLowerCase()}`} />
                                </Select.Option>
                            )
                        )}
                    </SelectField>
                </Form.Item>
            )}
            <Form.Item label={<FormattedMessage id="statisticsWidgetPane.image" />}>
                <ImageUpload
                    image={image}
                    name={["widgets", widgetId, "statistics", field.name, "icon"]}
                    form={form}
                    cropSettings={{
                        aspect: ImageRatios.AVATAR,
                        rotate: true,
                        zoom: true
                    }}
                />
            </Form.Item>
        </StatisticsWidgetPaneStyle>
    );
};
