
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ChallengeCondition": [
      "ChallengeActivityCondition",
      "ChallengeFormCondition"
    ],
    "ChallengeConditionInfoblockConfigVisualisation": [
      "ChallengeConditionInfoblockCurrentDayConditionVisualisation",
      "ChallengeConditionInfoblockCurrentDayGoalVisualisation",
      "ChallengeConditionInfoblockNextDayGoalVisualisation",
      "ChallengeConditionInfoblockParticipantCountAllConditionsCompletedVisualisation"
    ],
    "ChallengeConditionVisualisation": [
      "ChallengeConditionBarVisualisation",
      "ChallengeConditionGridVisualisation",
      "ChallengeConditionInfoblockVisualisation",
      "ChallengeConditionLeaderboardVisualisation",
      "ChallengeConditionTimelineVisualisation"
    ],
    "Comment": [
      "AmbassadorComment",
      "ChallengeComment",
      "ContentComment",
      "EventComment",
      "GalleryComment",
      "GalleryImageComment"
    ],
    "ContentAttachment": [
      "ContentImageAttachment"
    ],
    "CreditConfigType": [
      "CreditConfigTypeActivityV1",
      "CreditConfigTypeActivityV2",
      "CreditConfigTypeActivityV3",
      "CreditConfigTypeGeneric"
    ],
    "DashboardConfigSocialFeed": [
      "InstagramFeed",
      "TwitterFeed"
    ],
    "DashboardConfigWidget": [
      "DashboardConfigWidgetActivity",
      "DashboardConfigWidgetCaroussel",
      "DashboardConfigWidgetChallenge",
      "DashboardConfigWidgetChallengeProgress",
      "DashboardConfigWidgetChallengeVisualisation",
      "DashboardConfigWidgetCommunity",
      "DashboardConfigWidgetContent",
      "DashboardConfigWidgetEvent",
      "DashboardConfigWidgetGallery",
      "DashboardConfigWidgetGeneric",
      "DashboardConfigWidgetPopup",
      "DashboardConfigWidgetSocial",
      "DashboardConfigWidgetStatistics",
      "DashboardConfigWidgetVideo",
      "DashboardConfigWidgetWrapper"
    ],
    "Gallery": [
      "ChallengeGallery",
      "ContentGallery",
      "EventGallery",
      "GenericGallery"
    ],
    "Journey": [
      "EventJourney",
      "PersonalJourney"
    ],
    "Like": [
      "ChallengeLike",
      "ContentLike",
      "EventLike",
      "GalleryImageLike",
      "GalleryLike"
    ],
    "MailEngine": [
      "MailEngineCampaignMonitor",
      "MailEngineSendgrid"
    ],
    "MailTemplateAttachment": [
      "MailTemplateAttachmentIcs"
    ],
    "PaymentConfig": [
      "PaymentConfigMollie"
    ],
    "UserNotification": [
      "UserEmailNotification"
    ],
    "VoucherUsage": [
      "JourneyVoucherUsage"
    ]
  }
};
      export default result;
    