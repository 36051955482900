import { Col } from "antd";
import React from "react";
import { NameAndDescription } from "../additionalComponents/nameAndDescription";

export type CardWrapperProps = {
    name: string|number;
    description?: string;
    disableTitle?: boolean;
}

export const CardWrapper: React.FC<CardWrapperProps> = (props) => {
    const { children, name, description, disableTitle } = props;

    const title = name === "number" || disableTitle
        ? undefined
        : (
            <NameAndDescription
                description={description}
                label={name}
            />
        );

    return (
        <Col>
            {title}
            <Col style={{ marginLeft: !disableTitle ? 15 : undefined }}>
                {children}
            </Col>
        </Col>
    );
};
