import { VoucherTableData } from "@components/voucher/voucherTable";
import { AddVoucherMutationVariables, InputMaybe, TextByLanguageInput, VoucherAssignmentInput, VoucherEnabledOnInput } from "@graphql2/types";
import { mapToVoucherTableData } from "@utils/mappers/voucherMapper";
import { csvToJson } from "@utils/readFile";
import * as moment from "moment";
import uuidv4 from "uuid/v4";

interface CsvVoucher {
    assignments: string;
    code: string;
    discount: string;
    enableVoucherPeriod: boolean;
    enabled: boolean;
    enabledMaxUsage: boolean;
    enabledMaxUsagePerUser: boolean;
    enabledOnChallenge: string;
    maxUsage: string;
    maxUsagePerUser: number;
    usageCount: number;
    voucherEnd: Date;
    voucherStart: Date;
}

export function csvToVouchers(data) {
    const vouchersRows = csvToJson<CsvVoucher>(data);
    const vouchers: AddVoucherMutationVariables[] = [];
    const vouchersTableData: VoucherTableData[] = [];
    const enabledOnNameMap = { challenge: "challengeParticipation", challengeParticipation: "challengeParticipation", journey: "journey" };

    // eslint-disable-next-line no-restricted-syntax
    for (const row of vouchersRows) {
        const {
            enabled,
            code,
            voucherStart,
            voucherEnd,
            maxUsage,
            enabledMaxUsage,
            maxUsagePerUser,
            enableVoucherPeriod,
            enabledMaxUsagePerUser,
            discount,
            assignments,
            ...other
        } = row;

        if (!code) {
            continue;
        }

        const titlesArray: AddVoucherMutationVariables["title"] = Object.keys(other)
            .filter(k => k.startsWith("title"))
            .map(tk => (
                {
                    text: other[tk],
                    language: tk.replace("title", "").toLowerCase().trim()
                }
            ));
        const enabledOn: VoucherEnabledOnInput[] = Object.keys(other)
            .filter(k => k.startsWith("enabledOn"))
            .map(ek => (
                {
                    type: enabledOnNameMap[ek.replace("enabledOn", "").toLowerCase().trim()],
                    ids: row[ek] ? row[ek].split(",") : []
                }
            ));

        const [discountType, discountAmount] = discount ? discount.split("=") : [null, null];
        const assignmentsArray: VoucherAssignmentInput[] = [];
        const rawAssignments = assignments ? assignments.split("Type") : [];
        // eslint-disable-next-line no-restricted-syntax
        for (const rawLine of rawAssignments) {
            const line = rawLine && rawLine.trim();
            if (!line) {
                continue;
            }
            const arrayLine = line.match(/=(\w+),.*=(\d+),.*=\[(.*)\]/);
            if (!arrayLine) {
                continue;
            }
            const [, type, credits, roles] = arrayLine;
            assignmentsArray.push(({ type, credits: Number(credits), roles: roles.split(",") }));
        }

        const voucher: Omit<AddVoucherMutationVariables, "assignments" | "enabledOn" | "title"
        > & {
            assignments: VoucherAssignmentInput[];
            enabledOn: VoucherEnabledOnInput[];
            title: InputMaybe<TextByLanguageInput>[];
        } = {
            enabled,
            code,
            voucherStart: moment(voucherStart, "DD/MM/yyyy").startOf("d").valueOf() || 0,
            voucherEnd: moment(voucherEnd, "DD/MM/yyyy").endOf("d").valueOf() || 0,
            maxUsage: Number(maxUsage) || 0,
            maxUsagePerUser: Number(maxUsagePerUser) || 0,
            enabledMaxUsage,
            enabledMaxUsagePerUser,
            discount: {
                type: discountType || "",
                amount: Number(discountAmount) || 0
            },
            assignments: assignmentsArray,
            enabledOn,
            enableVoucherPeriod,
            title: titlesArray
        };

        vouchers.push(voucher);
        vouchersTableData.push(mapToVoucherTableData({ ...voucher, id: uuidv4(), usageCount: 0 }));
    }

    return {
        vouchersTableData,
        vouchers
    };
}
