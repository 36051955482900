import { DashboardConfigWidgetChallengeVisualisation, DashboardConfigWidgetChallengeVisualisationInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { toLanguageObject, LanguageObject } from "../../toLanguageObject";
import { toTextByLanguage } from "../../toTextByLanguage";

export type DashboardConfigWidgetChallengeVisualisationFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetChallengeVisualisation, {
    title: LanguageObject;
}>>;

export const mapChallengeVisualisationStateWidgetToChallengeVisualisationWidget = (widget: DashboardConfigWidgetChallengeVisualisationFormValues): DashboardConfigWidgetChallengeVisualisationInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title)
    };
};

export const mapChallengeVisualisationWidgetToChallengeVisualisationStateWidget = (visualisationWidget: DashboardConfigWidgetChallengeVisualisation): DashboardConfigWidgetChallengeVisualisationFormValues => {
    return {
        ...visualisationWidget,
        type: visualisationWidget.type as ViewType,
        title: toLanguageObject(visualisationWidget.title)
    };
};
