import styled from "styled-components";
import { theme } from "@style/theme";

export const ArticlesPageStyle = styled.div`

    .red {
        color: ${props => theme.colors.error};
    }

    .green {
        color: ${props => theme.primaryColor};
    }

    .expired {
        color: ${props => theme.colors.error};
    }

    .draft {
        color: #D8D8D8;
    }

    .notPublishedYet {
        color: #FDBF2D;
    }

    .active {
        color: ${props => theme.primaryColor};
    }

`;

export const LowStock = styled.span`
    color: ${props => theme.colors.error};
`;

export const NormalStock = styled.span`

`;

export const BulkDeleteButtonStyle = styled.a`
    color: gray;
`;

export const AlertWrapperStyle = styled.div`
    display: block;
    margin: 15px 0;
    width: 100%;
`;

export const SpinStyle = styled.div`
    width: 100%;

    .ant-spin {
        left: 50% !important;
        position: relative !important;
        top: 30px !important;
        transform: translateX(-50%) !important;
    }
`;
