import { CheckboxField } from "@components/field/checkboxField";
import { ColorPreviewField } from "@components/field/colorPreviewField";
import { Field } from "@components/field/field";
import type { JSONData } from "@energylab/schematics/lib";
import { InjectedIntl } from "@utils/override.types";
import Form, { FormInstance } from "antd/lib/form";
import * as React from "react";
import { DatePickerFieldWrapper } from "./fieldWrappers/datePickerFieldWrapper";
import { ExtendedGenericFieldWrapper } from "./fieldWrappers/extendedGenericFieldWrapper";
import { GenericFieldWrapper } from "./fieldWrappers/genericFieldWrapper";
import { ListFieldWrapper } from "./fieldWrappers/list/listFieldWrapper";
import { SelectFieldWrapper } from "./fieldWrappers/selectFieldWrapper";
import { formatName } from "./utils";

export type FieldName = (string | number)[]

export interface FieldsMapperProps {
    disable: boolean;
    jsonData?: JSONData;
    name: FieldName;
    required?: boolean;
    uniqueItems?: boolean;
    namePath?: (string | number)[];
    form: FormInstance;
    intl: InjectedIntl;
    disableTitle?: boolean;
}

export const FieldsMapper: React.FC<FieldsMapperProps> = (props) => {
    const { name, form, jsonData, namePath = [], required, uniqueItems, intl, disableTitle } = props;

    const fieldNamePath = React.useMemo(() => (props.disable ? undefined : name), [props.disable, name]);
    const { fieldName, displayName } = React.useMemo(() => {
        const elementName = name.slice(name.length - 1)[0];
        return {
            fieldName: elementName,
            displayName: jsonData?.title ?? formatName(elementName)
        };
    }, [name]);

    const renderElement = (disable: boolean) => {
        if (jsonData?.type === "object") {
            if (jsonData.additionalProperties) {
                return (
                    <ExtendedGenericFieldWrapper
                        disable={disable}
                        disableTitle={disableTitle ?? false}
                        displayName={displayName}
                        fieldName={fieldName}
                        fieldNamePath={fieldNamePath}
                        form={form}
                        intl={intl}
                        jsonData={jsonData}
                        name={name}
                        namePath={namePath}
                    />
                );
            }
            return (
                <GenericFieldWrapper
                    disable={disable}
                    disableTitle={disableTitle ?? false}
                    displayName={displayName}
                    fieldName={fieldName}
                    form={form}
                    intl={intl}
                    jsonData={jsonData}
                    name={name}
                    namePath={namePath}
                    required={required ?? false}
                />
            );
        }

        if (jsonData?.type === "array") {
            if (jsonData.items?.enum) {
                return (
                    <SelectFieldWrapper
                        isArray
                        disable={disable}
                        displayName={displayName}
                        fieldNamePath={fieldNamePath}
                        intl={intl}
                        jsonData={jsonData}
                        required={required ?? false}
                    />
                );
            }
            return (
                <ListFieldWrapper
                    disable={disable}
                    disableTitle={disableTitle ?? false}
                    displayName={displayName}
                    fieldName={fieldName}
                    fieldNamePath={fieldNamePath}
                    form={form}
                    intl={intl}
                    jsonData={jsonData}
                    name={name}
                    namePath={namePath}
                />
            );
        }

        if (jsonData?.type === "boolean") {
            return (
                <CheckboxField
                    description={jsonData.description}
                    disabled={disable}
                    initialValue={jsonData.default ?? false}
                    label={displayName}
                    name={fieldNamePath}
                    rules={[
                        {
                            required,
                            message: "Required"
                        }
                    ]}
                />
            );
        }

        if (jsonData?.type === "string") {
            if (jsonData?.isColor) {
                return (
                    <ColorPreviewField
                        colorPickerPlacement="bottomLeft"
                        description={jsonData.description}
                        disabled={disable}
                        form={form}
                        initialValue={jsonData.default}
                        label={displayName}
                        name={fieldNamePath}
                        rules={[
                            {
                                required,
                                message: "Required"
                            }
                        ]}
                    />
                );
            }

            if (jsonData?.enum) {
                return (
                    <SelectFieldWrapper
                        disable={disable}
                        displayName={displayName}
                        fieldNamePath={fieldNamePath}
                        intl={intl}
                        jsonData={jsonData}
                        required={required ?? false}
                    />
                );
            }
            return (
                <Field
                    description={jsonData.description}
                    disabled={disable}
                    initialValue={jsonData.default}
                    label={displayName}
                    name={fieldNamePath}
                    rules={[
                        {
                            required,
                            message: "Required"
                        },
                        {
                            validator: (rule, value, callback) => {
                                const array = form.getFieldValue(namePath);
                                if (!uniqueItems || !value || array.filter(v => v === value).length < 2) {
                                    return callback();
                                }
                                return callback(intl.formatMessage({ id: "field.unique" }));
                            }
                        }
                    ]}
                />
            );
        }

        if (jsonData?.type === "number") {
            if (jsonData?.isDatePicker) {
                return (
                    <DatePickerFieldWrapper
                        disable={disable}
                        displayName={displayName}
                        fieldNamePath={fieldNamePath}
                        form={form}
                        jsonData={jsonData}
                        required={required ?? false}
                    />
                );
            }
            return (
                <Field
                    numberType
                    description={jsonData.description}
                    disabled={disable}
                    initialValue={jsonData.default}
                    label={displayName}
                    max={jsonData.maximum}
                    min={jsonData.minimum}
                    name={fieldNamePath}
                    rules={[
                        {
                            required,
                            message: "Required"
                        }
                    ]}
                    step={jsonData.step}
                />
            );
        }

        return null;
    };

    if (jsonData?.disableOn) {
        return (
            <Form.Item dependencies={jsonData?.disableOn}>
                {() => {
                    const disabledByField = jsonData?.disableOn?.some(formName => Boolean(form.getFieldValue(formName)));
                    return renderElement(disabledByField || props.disable);
                }}
            </Form.Item>
        );
    }
    if (jsonData?.enabledOn) {
        return (
            <Form.Item dependencies={jsonData?.enabledOn}>
                {() => {
                    const disabledByField = !jsonData?.enabledOn?.some(formName => Boolean(form.getFieldValue(formName)));
                    return renderElement(disabledByField || props.disable);
                }}
            </Form.Item>
        );
    }
    return renderElement(props.disable);
};

