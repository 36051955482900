import { DashboardConfigWidgetGeneric, DashboardConfigWidgetGenericInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetGenericFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetGeneric, {
    title: LanguageObject;
    description: LanguageObject;
}>>;

export const mapGenericStateWidgetToGenericWidget = (widget: DashboardConfigWidgetGenericFormValues): DashboardConfigWidgetGenericInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title),
        description: toTextByLanguage(widget.description)
    };
};

export const mapGenericWidgetToGenericStateWidget = (genericWidget: DashboardConfigWidgetGeneric): DashboardConfigWidgetGenericFormValues => {
    return {
        ...genericWidget,
        type: genericWidget.type as ViewType,
        title: toLanguageObject(genericWidget.title),
        description: toLanguageObject(genericWidget.description)
    };
};
