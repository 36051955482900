import { LanguageObject } from "@utils/toLanguageObject";
import { Moment } from "moment";
import * as moment from "moment";

export interface VoucherFormDiscount {
    type: string;
    amount: number;
}

export interface VoucherFormAssignments {
    type?: string | null;
    credits?: number | null;
    roles?: string[] | null;
}

export interface VoucherFormEnabledOn {
    type: string;
    ids?: string[] | null;
}

export interface VoucherFormValues {
    title: LanguageObject;
    voucherStart: Moment;
    voucherEnd: Moment;
    id: string;
    code: string;
    enabled: boolean;
    enabledMaxUsage: boolean;
    maxUsage: number;
    usageCount?: number;
    discount?: VoucherFormDiscount | null;
    enabledMaxUsagePerUser: boolean;
    maxUsagePerUser: number;
    assignments: VoucherFormAssignments[];
    enabledOn: VoucherFormEnabledOn[];
    enableVoucherPeriod: boolean;
    enabledDiscount: boolean;
}

export const emptyVoucher: VoucherFormValues = {
    id: "",
    title: {},
    code: "",
    enabled: true,
    voucherStart: moment(),
    voucherEnd: moment().add(1, "M"),
    enabledMaxUsage: false,
    maxUsage: 0,
    enabledMaxUsagePerUser: true,
    maxUsagePerUser: 1,
    discount: null,
    assignments: [],
    enabledOn: [],
    enableVoucherPeriod: true,
    enabledDiscount: false
};
