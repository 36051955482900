import { ContentsFormValues } from "@pages/addContentsPage/addContentsPage";
import * as environment from "environment";
import * as moment from "moment";

export const emptyContent: ContentsFormValues = {
    title: {},
    preview: {},
    content: {},
    imageUrl: "",
    isDraft: false,
    publishOn: moment(),
    expiresOn: moment(),
    url: "",
    isPublic: false,
    roles: undefined,
    tags: undefined,
    type: "content",
    featured: false,
    enabledLocales: environment.languages,
    disableComments: false
};
