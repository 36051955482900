/* eslint-disable no-nested-ternary */
import * as moment from "moment";
import { GalleryTableData } from "@components/gallery/galleryTable";
import { GalleryFormValues } from "@pages/addGalleriesPage/addGalleriesPage";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { emptyGallery } from "@utils/emptyItems/emptyGallery";
import { toLanguageObject, LanguageObject } from "@utils/toLanguageObject";
import { GalleryImageOverviewImage } from "@pages/addGalleriesPage/general/galleryImageOverviewCard";
import { emptyGalleryImage } from "@utils/emptyItems/emptyGalleryImage";
import { GalleryFragment, GalleryImageFragment, AddGalleryMutationVariables, AddGalleryImageMutationVariables } from "@graphql2/types";
import { formatDate } from "@utils/dateUtils";
import { getText } from "../getText";

export interface GalleryImageImageFormValues {
    url?: string | null;
    embedUrl?: string | null;
    userId?: string | null;
    reported?: boolean | null;
}

export interface GalleryImageContentFormValues {
    title: LanguageObject;
    description: LanguageObject;
}

export type GalleryImageFormValues = { id: string; galleryId?: string } & GalleryImageContentFormValues & GalleryImageImageFormValues;

export const galleryImageToOverview = (galleryImage: GalleryImageFragment): GalleryImageOverviewImage => {
    const { id, title, description, url, embedUrl } = galleryImage;

    return {
        id,
        title: title ? getText(title) : "",
        description: description ? getText(description) : "",
        url: url || undefined,
        embedUrl: embedUrl || undefined
    };
};

export const translateGalleries = (originals: GalleryFragment[]): GalleryTableData[] => {
    return originals.map(gallery => {
        const { id, title, type, isDraft, expiresOn, publishOn } = gallery;

        return {
            id,
            title: title ? getText(title) : "",
            type,
            status: !(isDraft || (expiresOn && expiresOn < moment().valueOf()) || (publishOn && publishOn > moment().valueOf())) || false,
            statusType:
                (expiresOn && expiresOn < moment().valueOf())
                    ? "expired"
                    : isDraft
                        ? "draft"
                        : (publishOn && publishOn > moment().valueOf())
                            ? "notPublishedYet"
                            : "active"
        };
    });
};

export const galleryImageToFormValues = (galleryImage?: GalleryImageFragment | undefined, galleryId?: string): GalleryImageFormValues => {
    if (!galleryImage) {
        return emptyGalleryImage();
    }

    const {
        title,
        description,
        url,
        embedUrl,
        id,
        userId,
        reported
    } = galleryImage;

    return {
        title: toLanguageObject(title),
        description: toLanguageObject(description),
        url,
        embedUrl,
        galleryId,
        id,
        userId,
        reported
    };
};

export const galleryToFormValues = (gallery: GalleryFragment): GalleryFormValues => {
    if (!gallery) {
        return emptyGallery();
    }

    const {
        id,
        title,
        preview,
        description,
        publishOn,
        expiresOn,
        roles,
        tags,
        type,
        typeId,
        isDraft,
        featured,
        images
    } = gallery;

    return {
        id,
        title: toLanguageObject(title),
        preview: toLanguageObject(preview),
        description: toLanguageObject(description),
        publishOn: moment(publishOn || undefined),
        expiresOn: moment(expiresOn || undefined),
        roles: (roles || []).filter(r => r) as string[],
        tags: (tags || []).map(tag => tag.id),
        type: type || "",
        typeId,
        isDraft: isDraft || false,
        featured: featured || false,
        images: (images || []).map(image => galleryImageToFormValues(image, gallery.id))
    };
};

export const formValuesToGallery = (formValues: GalleryFormValues): AddGalleryMutationVariables => {
    const {
        title,
        preview,
        description,
        publishOn,
        expiresOn,
        roles,
        type,
        typeId,
        tags,
        isDraft,
        featured
    } = formValues;

    return {
        title: toTextByLanguage(title),
        preview: toTextByLanguage(preview),
        description: toTextByLanguage(description),
        isDraft: isDraft || false,
        publishOn: formatDate(publishOn),
        expiresOn: formatDate(expiresOn),
        type: type || "",
        typeId,
        roles: roles || [],
        tags: tags || [],
        featured: featured || false
    };
};

export const formValuesToGalleryImage = (formValues: GalleryImageFormValues, galleryId: string): AddGalleryImageMutationVariables => {
    const {
        title,
        description,
        url,
        embedUrl
    } = formValues;

    return {
        title: toTextByLanguage(title),
        description: toTextByLanguage(description),
        url,
        embedUrl,
        galleryId
    };
};
