/* eslint-disable react/no-unused-prop-types */
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { ChallengeLanguageFieldProps, LanguageFieldSharedProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { Checkbox, Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { InternalNamePath } from "antd/lib/form/interface";
import { profileFields, useExtraCompanySettings } from "environment";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ProfileFieldsTranslationWrapper } from "./editChallengeLeaderboardVisualisationStyle";

interface ProfileFieldsNameMapperProps {
    fieldPrefix?: (string | number)[];
    prefix: (string | number)[];
    form: FormInstance;
    sharedProps?: LanguageFieldSharedProps;
}

export const ProfileFieldsNameMapper: React.FC<ProfileFieldsNameMapperProps> = (props) => {
    const { prefix, fieldPrefix = prefix, form, sharedProps } = props;

    if (!profileFields?.length) {
        return null;
    }

    return (
        <React.Fragment>
            <SelectField
                label={<FormattedMessage id="challenge.visualisations.additionalColumns" />}
                mode="multiple"
                name={[...fieldPrefix, "columnNames"]}
                onChange={(values: string[]) => {
                    const columns = form.getFieldValue([...prefix, "columns"]);
                    form.setFields([
                        {
                            name: [...prefix, "columns"],
                            value: values.map(value => (
                                {
                                    ...columns?.find((column) => column?.profileProperty === value),
                                    profileProperty: value
                                }
                            ))
                        }
                    ]);
                }}
                {...sharedProps}
            >
                {profileFields?.map(field => (
                    <Select.Option
                        key={field.key}
                        value={field.key}
                    >
                        {field.key}
                    </Select.Option>
                ))}
            </SelectField>
            <Form.Item
                initialValue
                name={[...fieldPrefix, "enableFilters"]}
                valuePropName="checked"
            >
                <Checkbox>
                    <FormattedMessage id="challenge.visualisations.enableFilters" />
                </Checkbox>
            </Form.Item>
        </React.Fragment>
    );
};

interface SelectedProfileFieldsTranslationsMapperProps extends ProfileFieldsNameMapperProps {
    columnsPathName: (string | number)[];
    langFieldProps: ChallengeLanguageFieldProps[];
}

export const titleField = (name: InternalNamePath, label: string | JSX.Element) => (titleProps: ChallengeLanguageFieldProps) => (
    <Field
        key={titleProps.language}
        hidden={titleProps.hidden}
        info={maxCharacters(useExtraCompanySettings ? 70 : 140)}
        label={label}
        name={[...name, titleProps.language]}
        rules={[
            {
                max: useExtraCompanySettings ? 70 : 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...titleProps.sharedProps}
    />
);

export const SelectedProfileFieldsTranslationsMapper: React.FC<SelectedProfileFieldsTranslationsMapperProps> = (props) => {
    const { columnsPathName, form, prefix, langFieldProps } = props;
    return (
        <ProfileFieldsTranslationWrapper>
            <Form.List
                name={columnsPathName}
            >
                {(fields) => {
                    return (
                        fields?.map((field) => {
                            const profileProperty = form.getFieldValue([...prefix, "columns", field.key, "profileProperty"]) || "";
                            return (
                                <React.Fragment>
                                    <Form.Item
                                        noStyle
                                        name={[field.key, "profileProperty"]}
                                    />
                                    {langFieldProps.map(
                                        titleField([field.key, "header"], profileProperty)
                                    )}
                                </React.Fragment>
                            );
                        })
                    );
                }}
            </Form.List>
        </ProfileFieldsTranslationWrapper>
    );
};
