/* eslint-disable max-lines */
import Icon, { ArrowLeftOutlined } from "@ant-design/icons";
import { MilestoneEmailTestProps } from "@components/challengeMilestonesTable/challengeMilestonesTable";
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useGlobalActions } from "@context/enabledLangContext/actions";
import { ADD_CHALLENGE_TABS, AddChallengesPageProps } from "@pages/addChallengesPage/addChallengesPageContainer";
import {
    AddChallengesPageStyle,
    HeaderActions,
    HeaderTitle,
    WrapperIconLink
} from "@pages/addChallengesPage/addChallengesPageStyle";
import { ChallengeConditionsFormValues } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeSettingsFormValues } from "@pages/addChallengesPage/tabs/general/sections/challengeBasicSettingsCard";
import { ChallengeTitleAndDescriptionFormValues } from "@pages/addChallengesPage/tabs/general/sections/titleAndDescriptionCard";
import { ChallengeMilestonesFormValues, MilestoneFormValues } from "@pages/addChallengesPage/tabs/milestones/challengeMilestonesCard/challengeMilstonesCard";
import { ChallengePrizesFormValues } from "@pages/addChallengesPage/tabs/prizes/editChallengePrize/editChallengePrize";
import { TeamChallengeFormValues } from "@pages/addChallengesPage/tabs/team/challengeTeamCard";
import { ChallengeVisualisationsForm } from "@pages/addChallengesPage/tabs/visualisations/challengeVisualisationsCard/challengeVisualisationsCard";
import { AddVisualisationForm } from "@pages/addChallengesPage/tabs/visualisations/default/visualisationsDefaultCard";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyChallenge } from "@utils/emptyItems/emptyChallenge";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { challengeToFormValues, formValuesToChallenge, mapFormValuestoTeamPropertyValues, mapMilestones } from "@utils/mappers/challengeMapper";
import { InjectedIntl } from "@utils/override.types";
import { Badge, Breadcrumb, Button, Form, Modal, Popconfirm, Spin, message, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as environment from "environment";
import isEqual from "lodash/isEqual";
import * as moment from "moment";
import * as React from "react";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { useErrors } from "../../hooks/useErrors";
import { ChallengeAdvancedTab } from "./tabs/advanced/challengeAdvancedTab";
import { ChallengConditionsTab } from "./tabs/conditions/challengeConditionsTab";
import { ChallengeExtensionsTab } from "./tabs/extensions/challengeExtensionsTab";
import { ChallengGeneralTab } from "./tabs/general/challengeGeneralTab";
import { ChallengeMilestonesTab } from "./tabs/milestones/challengeMilestonesTab";
import { ChallengeTieBreakerFormValues } from "./tabs/prizes/challengePrizesCard/sections/challengeTieBreakerSettings";
import { ChallengPrizesTab } from "./tabs/prizes/challengePrizesTab";
import { ChallengTeamTab } from "./tabs/team/challengeTeamTab";
import { ChallengeVisualisationsTab } from "./tabs/visualisations/challengeVisualisationsTab";

export type ErrorObj = object;

export type ChallengeFormValues = { id?: string; }
    & { data?: { [fieldname: string]: any; }; }
    & ChallengeTitleAndDescriptionFormValues
    & { imageUrl: string | undefined; }
    & ChallengeSettingsFormValues
    & ChallengeConditionsFormValues
    & TeamChallengeFormValues
    & ChallengeMilestonesFormValues
    & ChallengeTieBreakerFormValues
    & ChallengePrizesFormValues
    & ChallengeVisualisationsForm
    & AddVisualisationForm

export interface ChallengePageDefaultProps {
    disabled: boolean;
    defaultValues: ChallengeFormValues;
    intl: InjectedIntl;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const challengeErrorMap = (tab: string, fieldErrors: any) => {
    switch (tab) {
        case ADD_CHALLENGE_TABS.general:
            return Boolean(
                fieldErrors.activityTypes
                || fieldErrors.description
                || fieldErrors.subtitle
                || fieldErrors.title
                || fieldErrors.imageUrl
                || fieldErrors.duration
                || fieldErrors.publishedOn
                || fieldErrors.expiresOn
                || fieldErrors.ultimateSyncTime
            );
        case ADD_CHALLENGE_TABS.prizes:
            return Boolean(
                fieldErrors.rewards
                || fieldErrors.tieBreaker
                || fieldErrors.challengeVoucherConfiguration
            );
        default:
            return Boolean(fieldErrors[tab]);
    }
};

// eslint-disable-next-line max-lines-per-function
export const AddChallengesPage: React.FC<AddChallengesPageProps> = (props: AddChallengesPageProps) => {
    const [form] = useForm();
    const intl = useIntl();

    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setError] = useErrors();
    const [disableRecalculate, setDisableRecalculate] = React.useState<boolean>(false);

    const [conditionIndex, setConditionIndex] = React.useState<number>();
    const [milestoneIndex, setMilestoneIndex] = React.useState<number>();
    const [prizeIndex, setPrizeIndex] = React.useState<number>();
    const [visualisationIndex, setVisualisationIndex] = React.useState<number>();
    const [isSaving, setIsSaving] = React.useState(false);
    const [initialMilestones, setInitialMilestones] = React.useState<MilestoneFormValues[]>([]);

    const [showSaveModal, setShowSaveModal] = React.useState(false);
    const [isFieldChanged, setIsFieldChanged] = React.useState(false);

    const [unsaved, setUnsaved] = React.useState<boolean>(false);

    const [updateEnabledLanguages, resetEnabledLanguages] = useGlobalActions();

    const modalValidationFields = [
        "duration",
        "ultimateSyncTime",
        "registration",
        "conditions",
        "endless",
        "difficultyLevel",
        "exceedConditionLimit"];

    const onBlur = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const {
        match,
        challenges,
        tags,
        applications,
        history,
        challengeParticipantCount: { challengeParticipantCount },
        location
    } = props;

    const editMode = Boolean(match.params.id);

    React.useEffect(
        () => {
            const milestones = challenges?.challenges?.[0]?.milestones;
            if (milestones) {
                setInitialMilestones(mapMilestones(milestones));
            }
        },
        [challenges]
    );

    const getMilestoneIndexFromUrl = (): number | undefined => {
        const { tab, itemIndex } = props.match.params;
        const milestoneIdFromUrl = tab === "milestones" ? itemIndex : "";
        const initialMilestoneIndex = initialMilestones.findIndex(milestone => milestone.id === milestoneIdFromUrl);
        if (initialMilestoneIndex >= 0) {
            return initialMilestoneIndex;
        }
        return undefined;
    };

    const setItemIndex = (idx: number, itemId: string | undefined, setHookIndex: (index: number) => void) => {
        const { tab, id } = props.match.params;
        if (tab && itemId) {
            const baseUrl = `/challenges/${id ? `edit/${id}` : "add"}/${tab}`;
            props.history.push(`${baseUrl}/${itemId}`);
        }

        setHookIndex(idx);
    };

    React.useEffect(
        () => {
            if (initialMilestones.length) {
                setMilestoneIndex(getMilestoneIndexFromUrl());
            }
        },
        [initialMilestones]
    );

    const tabs = useMemo<string[]>(() => {
        const generalTabs = [
            ADD_CHALLENGE_TABS.general,
            ADD_CHALLENGE_TABS.advanced,
            ADD_CHALLENGE_TABS.conditions,
            ADD_CHALLENGE_TABS.team,
            ADD_CHALLENGE_TABS.milestones,
            ADD_CHALLENGE_TABS.prizes,
            ADD_CHALLENGE_TABS.visualisations
        ];
        if (environment.challengeExtensions) {
            generalTabs.push(ADD_CHALLENGE_TABS.extensions);
        }
        return generalTabs;
    }, []);

    const currentTab = tabs.indexOf(match.params.tab);

    React.useEffect(() => {
        const enabledLocales = challenges?.challenges?.[0]?.enabledLocales;
        if (enabledLocales && enabledLocales.length) {
            updateEnabledLanguages(enabledLocales);
        }

        return () => {
            // This is the cleanup function, and will execute when the component unmounts (eg. navigate back to /challenges)
            resetEnabledLanguages();
        };
    }, [challenges?.challenges]);

    const defaultValues = React.useMemo(
        () => createDefaultValues(challengeToFormValues, emptyChallenge, location.state?.import, challenges?.loading, challenges?.challenges?.[0]),
        [challenges]
    );

    if (tags?.loading || applications?.loading || challenges?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if ((!challenges?.challenges?.length && match.params.id) || !defaultValues) {
        props.history.push("/404");
    }

    const challenge = form.getFieldsValue();

    const triggerMilestoneEmail = async (milestoneId: string) => {
        const { sendMilestoneEmail } = props;
        try {
            const response = await sendMilestoneEmail(
                {
                    variables: {
                        challengeId: match.params.id ?? "",
                        milestoneId: milestoneId ?? ""
                    }
                }
            );
            if (response.data?.sendChallengeMilestoneEmail?.success) {
                message.success(intl.formatMessage({ id: "challengeTabs.milestones.email.success" }));
            } else {
                message.error(intl.formatMessage({ id: "challengeTabs.milestones.email.error" }));
            }
        } catch (errors) {
            message.error(intl.formatMessage({ id: "challengeTabs.milestones.email.error" }));
        }
    };

    const validateMilestoneEmail = (milestoneId: string) => {
        const findMilestone = (milestones) => milestones.find(m => m.id === milestoneId);
        const initialMilestone = findMilestone(initialMilestones);
        const formMilestone = findMilestone(form.getFieldValue("milestones"));

        const unSaved = {
            isTestable: false,
            tooltipText: "challengeTabs.milestones.trigger.tooltip.unsaved"
        };

        if (!initialMilestone) {
            return unSaved;
        }

        if (!formMilestone.mailEnabled) {
            return {
                isTestable: false,
                tooltipText: "challengeTabs.milestones.trigger.tooltip.disabled"
            };
        }

        if (isEqual(initialMilestone, formMilestone)) {
            return {
                isTestable: true,
                tooltipText: "challengeTabs.milestones.trigger.tooltip.action"
            };
        }
        return unSaved;
    };

    const testMilestoneEmailProps: MilestoneEmailTestProps = {
        validateMilestoneEmail,
        triggerMilestoneEmail
    };

    const onSubmit = async (values) => {
        setIsSaving(true);
        const errMsg = match.params.id ? "challenge.updateFailed" : "challenge.addFailed";
        const updateMsg = match.params.id ? "challenge.updated" : "challenge.added";
        const url = "/challenges";
        try {
            const transformValues = () => (match.params.id
                ? props.updateChallenge({ variables: { ...formValuesToChallenge(values), id: match.params.id } })
                : props.addChallenge({ variables: {
                    ...formValuesToChallenge(values),
                    teamPropertyValues: mapFormValuestoTeamPropertyValues(values.team, props.applications.applications)
                } })
            );

            const transformedValues = await transformValues();
            if (transformedValues) {
                handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                    history.push(url);
                });
            }
        } catch (errors) {
            setError(errors);
            handleError(<FormattedMessage id={errMsg} />, () => { });
            message.error(<FormattedMessage id="error.checkHighlightedFields" />);
        }
        setIsSaving(false);
    };

    const onFinishFailed = async (errors) => {
        const errMsg = match.params.id ? "challenge.updateFailed" : "challenge.addFailed";
        setError(errors);
        handleError(<FormattedMessage id={errMsg} />, () => { });
        message.error(<FormattedMessage id="error.checkHighlightedFields" />);
    };

    const defaultProps: ChallengePageDefaultProps = {
        defaultValues,
        disabled: false,
        changeSelectedLanguage,
        onBlur,
        intl
    };

    // TODO: use hasError to set <Badge dot={} />
    const challengeTabs = tabs.map(t => <Badge dot={challengeErrorMap(t, fieldErrors)}><FormattedMessage id={t} /></Badge>);

    const deleteChallenge = async () => {
        const { removeChallenge, match: { params: { id } } } = props;

        if (!id) {
            return history.push("/challenges");
        }
        try {
            await removeChallenge({ variables: { id } });
            history.push("/challenges");
        } catch {
            console.warn("Challenge not deleted");
        }
    };

    const recalculate = async () => {
        const { resyncChallenge, match: { params: { id } } } = props;

        if (!id || disableRecalculate) {
            return;
        }

        try {
            await resyncChallenge({ variables: { id } });
            notification.open({
                message: "Success",
                description: "Recalculate in progress. This can take a few hours."
            });
            setDisableRecalculate(true);
        } catch {
            console.warn("Challenge not recalculated");
        }
    };

    const conditionsDownload = async (id: string) => {
        const { exportChallengeConditionsFormData } = props;

        try {
            await exportChallengeConditionsFormData({
                variables: {
                    filter: {
                        ids: [{
                            conditionId: id,
                            challengeId: match.params.id
                        }]
                    },
                    format: "team:title;user:givenName;user:familyName;user:locale;user:email;formdata"
                }
            });
            message.success(intl.formatMessage({ id: "challenge.conditions.exportSuccess" }));
        } catch (e) {
            message.error(intl.formatMessage({ id: "challenge.conditions.exportFailed" }));
        }
    };

    const editSection = (idx: number, section: string, setHookIndex: (index: number) => void) => {
        setHookIndex(idx);
    };

    const changeSelectedTab = (index: number | string) => {
        history.push(
            `/challenges/${match.params.id ? `edit/${match.params.id}` : "add"}/${tabs[index]}`
        );
    };

    const backFromEdit = (tabIndex: number, setIndexHook: (value) => void) => {
        setIndexHook(undefined);
    };

    const renderGeneral = () => {
        const { tags: { challengeTags } } = props;
        const generalTabActive = match.params.tab === ADD_CHALLENGE_TABS.general;

        return (
            <ChallengGeneralTab
                challengeParticipantCount={challengeParticipantCount}
                customErrors={{
                    description: fieldErrors.description
                }}
                defaultProps={defaultProps}
                editMode={editMode}
                errors={errorsToLanguageErrors({
                    title: fieldErrors.title,
                    subtitle: fieldErrors.subtitle,
                    description: fieldErrors.description
                })}
                form={form}
                hidden={!generalTabActive}
                tags={challengeTags}
            />
        );
    };

    const renderAdvanced = () => {
        const { applications: { applications: apps } } = props;
        const roles = getFrontendRoles(apps);
        const advancedTabActive = match.params.tab === ADD_CHALLENGE_TABS.advanced;

        return (
            <div hidden={!advancedTabActive}>
                <ChallengeAdvancedTab
                    {...defaultProps}
                    form={form}
                    roles={roles}
                    onBlur={onBlur}
                />
            </div>
        );
    };

    const renderConditions = () => {
        const conditionsTabActive = match.params.tab === ADD_CHALLENGE_TABS.conditions;

        return (
            <ChallengConditionsTab
                backFromEdit={() => backFromEdit(1, setConditionIndex)}
                conditionIndex={conditionIndex}
                defaultProps={defaultProps}
                editCondition={(index: number) => editSection(index, "conditions", setConditionIndex)}
                errors={fieldErrors.conditions}
                form={form}
                hidden={!conditionsTabActive}
                selectedLanguage={selectedLanguage}
                onDownload={conditionsDownload}
            />
        );
    };

    const renderTeam = () => {
        const teamTabActive = match.params.tab === ADD_CHALLENGE_TABS.team;

        return (
            <ChallengTeamTab
                defaultProps={defaultProps}
                editMode={editMode}
                form={form}
                hidden={!teamTabActive}
            />
        );
    };

    const renderMilestones = () => {
        const milestonesTabActive = match.params.tab === ADD_CHALLENGE_TABS.milestones;

        return (
            <ChallengeMilestonesTab
                backFromEdit={() => backFromEdit(3, setMilestoneIndex)}
                defaultProps={defaultProps}
                editMilestone={index => setItemIndex(index, initialMilestones[index]?.id, setMilestoneIndex)}
                errors={fieldErrors.milestones}
                form={form}
                hidden={!milestonesTabActive}
                milestoneIndex={milestoneIndex}
                selectedLanguage={selectedLanguage}
                testMilestoneEmailProps={testMilestoneEmailProps}
            />
        );
    };

    const renderPrizes = () => {
        const prizesTabActive = match.params.tab === ADD_CHALLENGE_TABS.prizes;

        return (
            <ChallengPrizesTab
                backFromEdit={() => backFromEdit(4, setPrizeIndex)}
                defaultProps={defaultProps}
                editPrize={(index: number) => editSection(index, "prizes", setPrizeIndex)}
                errors={fieldErrors}
                form={form}
                hidden={!prizesTabActive}
                prizeIndex={prizeIndex}
                selectedLanguage={selectedLanguage}
            />
        );
    };

    const renderVisualisations = () => {
        const visualisationsTabActive = match.params.tab === ADD_CHALLENGE_TABS.visualisations;

        return (
            <ChallengeVisualisationsTab
                backFromEdit={() => backFromEdit(5, setVisualisationIndex)}
                changeSelectedLanguage={changeSelectedLanguage}
                defaultProps={defaultProps}
                editVisualisation={(index: number) => editSection(index, "visualisations", setVisualisationIndex)}
                errors={fieldErrors.visualisations}
                form={form}
                hidden={!visualisationsTabActive}
                selectedLanguage={selectedLanguage}
                visualisationIndex={visualisationIndex}
            />
        );
    };

    const renderExtensionsFields = () => {
        if (!environment.challengeExtensions) {
            return null;
        }
        const extensionsTabActive = match.params.tab === ADD_CHALLENGE_TABS.extensions;
        return (
            <div hidden={!extensionsTabActive}>
                <ChallengeExtensionsTab form={form} />
            </div>
        );
    };

    const handleSubmit = (values) => {
        if (match.params.id && isFieldChanged) {
            return setShowSaveModal(!showSaveModal);
        }
        onSubmit(values);
    };

    const handleChangedFields = (field) => {
        const shouldUpdate = !isFieldChanged && modalValidationFields.includes(field?.[0]?.name?.[0]);
        if (!shouldUpdate) {
            return;
        }
        setIsFieldChanged(shouldUpdate);
    };

    const resyncModal = () => {
        const disabledRecalculateBtn = challenge?.duration?.end < moment(Date.now());
        return (
            <Modal
                footer={[
                    <Button
                        key="save"
                        type="primary"
                        onClick={() => {
                            setShowSaveModal(false); onSubmit(form.getFieldsValue());
                        }}
                    >
                        <FormattedMessage id="saveWithoutRecalculate" />
                    </Button>,
                    <Button
                        key="recalculate"
                        disabled={disabledRecalculateBtn}
                        type="primary"
                        onClick={() => {
                            setShowSaveModal(false); recalculate(); onSubmit(form.getFieldsValue());
                        }}
                    >
                        <FormattedMessage id="challenge.saveAndRecalculate" />
                    </Button>,
                    <Button
                        key="cancel"
                        onClick={() => setShowSaveModal(!showSaveModal)}
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                ]}
                visible={showSaveModal}
                onCancel={() => setShowSaveModal(!showSaveModal)}
            >
                <p style={{ margin: "0 32px" }}><FormattedMessage id="challenge.saveOptionsConfirm" /></p>
            </Modal>
        );
    };

    return (
        <AddChallengesPageStyle>
            <Form
                form={form}
                initialValues={defaultValues}
                onFieldsChange={handleChangedFields}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
            >
                <React.Fragment>
                    <HeaderContent>
                        <HeaderActions>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/challenges">
                                        <FormattedMessage id="overview" />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {getText(challenge?.title || defaultValues?.title, selectedLanguage)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div>
                                <Popconfirm
                                    cancelText="No"
                                    okText="Yes"
                                    title={<FormattedMessage id="deleteConfirm" />}
                                    onConfirm={deleteChallenge}
                                >
                                    <Button
                                        className="headerButton"
                                        type="ghost"
                                    >
                                        <FormattedMessage id="challenge.delete" />
                                    </Button>
                                </Popconfirm>
                                <Popconfirm
                                    // TODO: replace icon
                                    cancelText="No"
                                    icon={(
                                        <Icon
                                            style={{ color: "red" }}
                                            type="question-circle-o"
                                        />
                                    )}
                                    okText="Yes"
                                    placement="bottomRight"
                                    title={<FormattedMessage id="resyncConfirm" />}
                                    onConfirm={recalculate}
                                >
                                    <Button
                                        className="headerButton"
                                        disabled={disableRecalculate}
                                        type="ghost"
                                    >
                                        <FormattedMessage id="challenge.recalculate" />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </HeaderActions>
                        <HeaderTitle>
                            <WrapperIconLink to="/challenges">
                                <ArrowLeftOutlined />
                            </WrapperIconLink>
                            <h1>
                                {getText(challenge?.title || defaultValues?.title, selectedLanguage)}
                            </h1>
                        </HeaderTitle>
                        <Flex
                            fullWidth
                            justifyContent="space-between"
                        >
                            <ContentTabs
                                currentTab={currentTab}
                                handleTabSelectedLanguage={changeSelectedTab}
                                tabs={challengeTabs}
                            />
                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    htmlType="submit"
                                    loading={isSaving}
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                            </div>
                            {resyncModal()}
                        </Flex>
                    </HeaderContent>
                    {renderGeneral()}
                    {renderAdvanced()}
                    {renderConditions()}
                    {renderTeam()}
                    {renderMilestones()}
                    {renderPrizes()}
                    {renderVisualisations()}
                    {renderExtensionsFields()}
                </React.Fragment>
            </Form>
        </AddChallengesPageStyle>
    );
};
