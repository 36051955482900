import { Breadcrumb, Button, Form, Popconfirm, Spin, message } from "antd";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { AddAmbassadorsPageProps } from "@pages/addAmbassadorsPage/addAmbassadorsPageContainer";
import { AddAmbassadorsPageStyle } from "@pages/addAmbassadorsPage/addAmbassadorsPageStyle";

import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";

import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { SpinStyle } from "@components/style/globalStyles";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { emptyAmbassador } from "@utils/emptyItems/emptyAmbassador";
import { emptyAmbassadorConfig } from "@utils/emptyItems/emptyAmbassadorConfig";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { ambassadorConfigToFormValues, ambassadorToFormValues, formValuesToAmbassador } from "@utils/mappers/ambassadorMapper";
import { translateTags } from "@utils/mappers/tagMapper";
import { useForm } from "antd/lib/form/Form";
import { Link } from "react-router-dom";
import { ContentCard, ContentFormValues } from "./general/contentCard";
import { SettingsCard, SettingsFormValues } from "./general/settingsCard";

export type ErrorObj = object;

export type ContentErrors = {
    title: ErrorObj;
    info: ErrorObj;
    description: ErrorObj;
    imageUrl: ErrorObj;
};

export interface AddAmbassadorsPageState {
    selectedLanguage: string;
    errors?: ContentErrors;
    unsaved: boolean;
}

export type AmbassadorsFormValues = {
    id?: string;
    imageUrl: string;
} & ContentFormValues & SettingsFormValues;

export const AddAmbassadorsPage: React.FC<AddAmbassadorsPageProps> = (props) => {
    const {
        ambassadors,
        ambassadorTags,
        singleUser,
        users,
        ambassadorConfigs,
        match,
        removeAmbassador,
        updateAmbassador,
        addAmbassador,
        history
    } = props;

    const { params: { cId, aId } } = match;

    const intl = useIntl();

    const editAmbassadorGroupUrl = `/ambassador/ambassadorConfigs/edit/${cId}`;

    const [form] = useForm();
    const [unsaved, setUnsaved] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setErrors] = useErrors();
    const [isSaving, setIsSaving] = React.useState(false);

    const translatedTags = React.useMemo(
        () => translateTags(ambassadorTags?.ambassadorTags || []),
        [ambassadorTags]
    );

    const ambassadorConfig = React.useMemo(
        () => (ambassadorConfigs?.ambassadorConfigs?.[0]
            ? ambassadorConfigToFormValues(ambassadorConfigs.ambassadorConfigs[0])
            : emptyAmbassadorConfig),
        [ambassadorConfigs]
    );

    const defaultValues = React.useMemo(
        () => (ambassadors?.ambassadors?.[0]
            ? ambassadorToFormValues(ambassadors.ambassadors[0])
            : emptyAmbassador),
        [ambassadors]
    );

    const ambassadorForm = form.getFieldsValue();

    const searchUsers = (userSearch: string) => {
        props.setLocalState({
            filter: {
                userSearch
            }
        });
    };

    const handleSuccess = (id: string) => {
        message.success(intl.formatMessage({ id }));
    };

    const handleError = (id: string) => {
        message.error(intl.formatMessage({ id }));
    };

    const place = ambassadorConfig.place || "";

    const submitForm = async (values) => {
        setIsSaving(true);

        try {
            const fields = values;
            fields.description = form.getFieldValue(["description"]);

            if (aId) {
                await updateAmbassador({ variables: { ...formValuesToAmbassador(fields), id: aId, place } });
                handleSuccess("ambassador.ambassadorUpdated");
                history.push(editAmbassadorGroupUrl);
            } else {
                await addAmbassador({ variables: { ...formValuesToAmbassador(fields), place } });
                handleSuccess("ambassador.ambassadorAdded");
                history.push(editAmbassadorGroupUrl);
            }
        } catch (errors) {
            const errId = aId ? "ambassador.ambassadorUpdateFailed" : "ambassador.ambassadorAddFailed";
            handleError(errId);
            setErrors(errors);
            message.error("There are some errors in the form. Please check for highlighted or empty fields.");
        }

        setIsSaving(false);
    };

    const onFailed = (err) => {
        setErrors(err);
    };

    const removeAmbassadorFn = async () => {
        if (aId) {
            await removeAmbassador({ variables: { id: aId } });
            history.push(editAmbassadorGroupUrl);
        }
    };

    const defaultProps = {
        changeSelectedLanguage: setSelectedLanguage,
        onSubmit: submitForm,
        onBlur: () => setUnsaved(true),
        defaultValues
    };

    const valuesIsReady = match.params.aId ? Boolean(ambassadorConfig.id) : true;

    if (ambassadors?.loading || ambassadorTags?.loading || !valuesIsReady) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if ((!ambassadors?.ambassadors?.length && match.params.aId) || !ambassadorConfigs) {
        props.history.push("/404");
    }

    return (
        <AddAmbassadorsPageStyle>
            <Form
                hideRequiredMark
                form={form}
                initialValues={defaultValues}
                onFinish={submitForm}
                onFinishFailed={onFailed}
            >
                <React.Fragment>
                    <HeaderContent>
                        <HeaderActions>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/ambassador/ambassadorConfigs">
                                        <FormattedMessage id="overview" />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={`/ambassador/ambassadorConfigs/edit/${cId}`}>
                                        {place}
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {getText(ambassadorForm?.title || defaultValues?.title, selectedLanguage)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    key="button1"
                                    htmlType="submit"
                                    loading={isSaving}
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                                <Popconfirm
                                    cancelText="No"
                                    okText="Yes"
                                    placement="bottomRight"
                                    title={<FormattedMessage id="deleteConfirm" />}
                                    onConfirm={removeAmbassadorFn}
                                >
                                    <Button
                                        danger
                                        className="headerButton"
                                    >
                                        <FormattedMessage
                                            id="content.delete"
                                        />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </HeaderActions>
                    </HeaderContent>
                    <React.Fragment>
                        <ContentCard
                            {...defaultProps}
                            activeLanguage={selectedLanguage}
                            form={form}
                            hidden={false}
                            languageErrors={errorsToLanguageErrors({
                                title: fieldErrors && fieldErrors.title,
                                info: fieldErrors && fieldErrors.info,
                                description: fieldErrors && fieldErrors.description
                            })}
                        />
                        {form && (
                            <React.Fragment>
                                {/* TODO: fix image after merge */}
                                <CoverImageCard
                                    {...defaultProps}
                                    required
                                    cropSettings={{
                                        aspect: ImageRatios.AVATAR,
                                        zoom: true,
                                        rotate: true
                                    }}
                                    defaultImage={defaultValues.imageUrl}
                                    form={form}
                                    formField="imageUrl"
                                    hasError={fieldErrors && fieldErrors.imageUrl}
                                    hidden={false}
                                    titleId="image"
                                />
                                <SettingsCard
                                    {...defaultProps}
                                    activeLanguage={selectedLanguage}
                                    form={form}
                                    hidden={false}
                                    searchUsers={searchUsers}
                                    singleUser={singleUser?.users}
                                    tags={translatedTags || []}
                                    users={users?.users}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </React.Fragment>
            </Form>
        </AddAmbassadorsPageStyle>
    );
};
