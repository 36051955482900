import { withLocalState } from "@components/export/exportContainer";
import { ChallengeParticipantFilter, GetChallengeParticipantCountProps, GetChallengeParticipantsProps, GetChallengeTitlesProps, RemoveChallengeParticipationProps, RemoveFromChallengeTeamProps, withGetChallengeParticipantCount, withGetChallengeParticipants, withGetChallengeTitles, withRemoveChallengeParticipation, withRemoveFromChallengeTeam } from "@graphql2/types";
import { LocalStateProps } from "@utils/withLocalState";
import { SortAndPaging } from "@utils/withPagingAndSort";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { ChallengeParticipantsPage } from "./challengeParticipantsPage";

export interface ChallengeParticipantsPageContainerProps {
    id: string;
}

export interface LocalState extends SortAndPaging {
    filter: ChallengeParticipantFilter;
}

type ChallengeParticipantsRouteProps = RouteComponentProps<{
    id: string;
}>;

export const withChallengeParticipants = withGetChallengeParticipants({
    name: "challengeParticipants",
    options(ownProps: LocalState & ChallengeParticipantsRouteProps) {
        return {
            variables: {
                filter: {
                    ...ownProps.filter,
                    challengeIds: ownProps.match.params.id ? [ownProps.match.params.id] : []
                },
                paging: ownProps.paging,
                sort: ownProps.sort || {
                    field: "id",
                    order: "ASC"
                }
            },
            fetchPolicy: "network-only"
        };
    }
});

export const withChallengeParticipantCount = withGetChallengeParticipantCount({
    name: "participantsCount",
    options(ownProps: LocalState & ChallengeParticipantsRouteProps) {
        return {
            variables: {
                filter: {
                    ...ownProps.filter,
                    challengeIds: ownProps.match.params.id ? [ownProps.match.params.id] : []
                }
            }
        };
    }
});

export const withChallengeTitles = withGetChallengeTitles({
    name: "challengeTitles",
    options(ownProps: LocalState & ChallengeParticipantsRouteProps) {
        return {
            variables: {
                filter: {
                    ids: ownProps.match.params.id ? [ownProps.match.params.id] : []
                }
            }
        };
    }
});

const withRemoveFromChallengeTeamMutation = withRemoveFromChallengeTeam({
    name: "removeFromChallengeTeam",
    options: {
        refetchQueries: () => ["getChallengeParticipants", "getChallengeParticipantCount"],
        onError: (error) => {
            throw new Error(error.message);
        }
    }
});

const withRemoveChallengeParticipationMutation = withRemoveChallengeParticipation({
    name: "removeChallengeParticipation",
    options: {
        refetchQueries: () => ["getChallengeParticipants", "getChallengeParticipantCount"],
        onError: (error) => {
            throw new Error(error.message);
        }
    }
});

export const ChallengeParticipantsPageContainer = compose<React.ComponentClass<ChallengeParticipantsPageContainerProps>>(
    withLocalState,
    withChallengeParticipants,
    withChallengeParticipantCount,
    withChallengeTitles,
    withRemoveFromChallengeTeamMutation,
    withRemoveChallengeParticipationMutation
)(ChallengeParticipantsPage);

export type ChallengeParticipantsPageProps = ChallengeParticipantsPageContainerProps
    & LocalStateProps<LocalState>
    & ChallengeParticipantsRouteProps
    & GetChallengeParticipantsProps<{}, "challengeParticipants">
    & GetChallengeParticipantCountProps<{}, "participantsCount">
    & GetChallengeTitlesProps<{}, "challengeTitles">
    & RemoveFromChallengeTeamProps<{}, "removeFromChallengeTeam">
    & RemoveChallengeParticipationProps<{}, "removeChallengeParticipation">;

