import * as React from "react";
import { compose } from "redux";

import { withGetAmbassadorTags, GetAmbassadorTagsProps } from "@graphql2/types";
import { ExportProps, exportFactory } from "./export";
import { withLocalState, ExportContainerProps } from "./exportContainer";

export const withAmbassadorTag = withGetAmbassadorTags({
    skip(ownProps: ExportContainerProps) {
        return !ownProps.shouldExport;
    }
});

export const ExportAmbassadorTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withAmbassadorTag
)(exportFactory({ targetProp: ["data", "ambassadorTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportAmbassadorTagContainerProps = ExportContainerProps & MaybeElements<GetAmbassadorTagsProps>;
