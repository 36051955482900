import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { MilestoneFormValues } from "@pages/addChallengesPage/tabs/milestones/challengeMilestonesCard/challengeMilstonesCard";
import { LanguageFieldSharedProps } from "@utils/languageFieldProps";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

/*
    Look at conditionUtil for more info
*/

export const tipFormatter = (value) => `${value}%`;

export interface HiddenMilestoneInputsProps {
    milestone: MilestoneFormValues;
    index: number;
}

export const HiddenMilestoneInputs: React.FC<HiddenMilestoneInputsProps> = (props) => {
    const { index, milestone } = props;

    return (
        <Field
            hidden
            initialValue={milestone.id}
            name={["milestones", index, "id"]}
            rules={[{ required: true }]}
        />
    );
};

export interface ConditionSelectionProps {
    form: FormInstance;
    index: number;
    milestone: MilestoneFormValues;
    conditions?: ChallengeConditionFormValuesUnion[];
    sharedProps: LanguageFieldSharedProps;
}

export const ConditionSelection: React.FC<ConditionSelectionProps> = (props) => {
    const { index, milestone, conditions, sharedProps } = props;

    return (
        <SelectField
            initialValue={milestone.conditionId}
            label={<FormattedMessage id="condition" />}
            name={["milestones", index, "conditionId"]}
            {...sharedProps}
        >
            <Select.Option
                key=""
                value=""
            >
                <FormattedMessage id="global" />
            </Select.Option>
            {conditions && conditions.map(condition => (
                <Select.Option
                    key={condition.id}
                    value={condition.id}
                >
                    <ConditionTitle condition={condition} />
                </Select.Option>
            ))}
        </SelectField>
    );
};
