import { Flex } from "@components/flex/flex";
import { FlowsTable } from "@components/flows/flowsTable";
import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { TableContainer } from "@components/table/tableContainer";
import { useAuth } from "@context/authContext/context";
import { mapToFlowsTableData } from "@utils/mappers/flowsMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import {
  getCurrentTablePage,
  getPageSize,
  getUpdateUrlQueryParams,
  onTableChange,
} from "@utils/withPagingAndSort";
import { Button, Card, Spin } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FlowsPageProps } from "./flowsPageContainer";

export const FlowsPage: React.FC<FlowsPageProps> = (props) => {
  const { history, flows, count, paging } = props;
  const { projectId } = useAuth();

  const updateUrlQuery = useUpdateQuery({ replace: true });

  function updateTableSettings(
    pagination: PaginationConfig,
    filters: Record<any, string[]>,
    sorter: SorterResult<any>
  ) {
    updateUrlQuery(
      getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter))
    );
  }

  const flowsTableData = React.useMemo(
    () => (flows?.flows || []).map(mapToFlowsTableData),
    [flows]
  );

  if (flows.loading) {
    return (
      <SpinStyle>
        <Spin />
      </SpinStyle>
    );
  }

  return (
    <div>
      <HeaderContent>
        <Flex justifyContent="space-between">
          <FormattedMessage id="flows.overview" tagName="h1" />
          <Button
            className="headerButton"
            type="primary"
            onClick={() => history.push("/flows/add")}
          >
            <FormattedMessage id="flows.addFlow" />
          </Button>
        </Flex>
      </HeaderContent>
      <Card>
        <TableContainer
          dataSource={flowsTableData || []}
          handlers={{
            removeFlow: (flowId) =>
              props.removeFlow({ variables: { flowId, projectId } }),
          }}
          loading={false}
          pagination={{
            current: getCurrentTablePage(paging),
            pageSize: getPageSize(paging),
            total: count && count.flowsCount,
          }}
          TableComponent={FlowsTable}
          onChange={updateTableSettings}
        />
      </Card>
    </div>
  );
};
