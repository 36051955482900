export const createSetFieldsValue = (fields, value) => {
    const reducer = (acc, item, index, arr) => ({ [item]: index + 1 < arr.length ? acc : value });

    if (!Array.isArray(fields)) {
        if (!fields.includes(".")) {
            return {
                [fields]: value
            };
        }

        return fields.split(".").reduceRight(reducer, {});
    }

    return fields.reduceRight(reducer, {});
};
