import { EventFormValues } from "@pages/addEventsPage/addEventsPage";
import * as moment from "moment";
import uuidv4 from "uuid/v4";
import { FormInstance } from "antd/lib/form";

export interface SubEventFactoryProps { }

export const subEventFactory = (form: FormInstance) => {
    const values = form.getFieldsValue() as EventFormValues;

    return {
        id: uuidv4(),
        sequence: (values.subEvents || []).length,
        title: {},
        date: values.start || moment(),
        endDate: null,
        info: ""
    };
};
