import { getArticlesOverview } from "@graphql2/graphql_op";
import { AddArticleProps, GetApplicationsProps, GetArticlesProps, UpdateArticleProps, withAddArticle, withGetApplications, withGetArticles, withUpdateArticle } from "@graphql2/types";
import { AddArticlesPage } from "@pages/addArticlesPage/addArticlesPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

export interface AddArticlesPageContainerProps {
    editMode?: boolean;
}

type AddArticleRouteProps = RouteComponentProps<{ id: string; tab: string; }, {}, { import: boolean; }>;

export const ADD_ARTICLE_TABS = {
    general: "general",
    voucher: "voucher"
};

export const withArticles = withGetArticles({
    name: "articles",
    options: (props: AddArticleRouteProps) => ({
        variables: {
            filter: {
                ids: props.match.params.id ? [props.match.params.id] : []
            },
            paging: {}
        }
    }),
    skip: (props: AddArticleRouteProps) => {
        return !props.match.params.id;
    }
});

export const withAddArticleMutation = withAddArticle({
    name: "addArticle",
    options: {
        update: cleanCache([getArticlesOverview])
    }
});

export const withUpdateArticleMutation = withUpdateArticle({
    name: "updateArticle"
});

export const withApplications = withGetApplications({
    name: "applications"
});

export const AddArticlesPageContainer = compose<React.ComponentClass<AddArticlesPageContainerProps>>(
    withApplications,
    withArticles,
    withAddArticleMutation,
    withUpdateArticleMutation
)(AddArticlesPage);

export type AddArticlesPageProps =
    AddArticlesPageContainerProps &
    AddArticleRouteProps &
    AddArticleProps<{}, "addArticle"> &
    MaybeElements<GetArticlesProps<{}, "articles">> &
    UpdateArticleProps<{}, "updateArticle"> &
    RouteComponentProps
    & GetApplicationsProps<{}, "applications">
