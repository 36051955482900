import { TagsPage } from "@pages/tagsPage/tagsPage";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { TextByLanguageFragment, RemoveContentTagProps, RemoveEventTagProps, RemoveGalleryTagProps, RemoveJourneyTagProps, RemoveAmbassadorTagProps, RemoveChallengeTagProps } from "@graphql2/types";

export interface BaseTag {
    id: string;
    title: TextByLanguageFragment[];
    description?: TextByLanguageFragment[] | null;
    color: string;
    image?: string | null;
}

export const withExtraProps = (extraProps: { [key: string]: string | React.ComponentType | any }) => Component => (props) => <Component {...props} {...extraProps} />;

export function createTagsPageContainer(withTags, withRemoveTag, callbackUrl: string, urlTarget: string, exportContainer: React.ComponentType, path: string[]) {
    return compose<React.ComponentClass>(
        withTags,
        withRemoveTag,
        withExtraProps({ callbackUrl, urlTarget, exportContainer, path })
    )(TagsPage);
}

export type BaseTagsPageProps =
    {
        callbackUrl: string;
        urlTarget: string;
        exportContainer: React.ComponentType;
        isLoading: boolean;
        path: string[]
    }
    & RouteComponentProps
    & (RemoveContentTagProps<{}, "removeTag"> | RemoveEventTagProps<{}, "removeTag"> | RemoveGalleryTagProps<{}, "removeTag"> | RemoveJourneyTagProps<{}, "removeTag"> | RemoveAmbassadorTagProps<{}, "removeTag"> | RemoveChallengeTagProps<{}, "removeTag">);
