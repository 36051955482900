import { OrderFieldButtonsStyle } from "@components/formContentFields/formElementStyle";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface OrderFieldButtonsProps {
    index: number;
    conditionIndex: number;
    type: string;
    moveFormElement(from: number, to: number): void;
}

export const OrderFieldButtons: React.FC<OrderFieldButtonsProps> = (props) => {
    const { moveFormElement, index, type } = props;

    return (
        <React.Fragment>
            <OrderFieldButtonsStyle>
                <UpOutlined onClick={() => moveFormElement(index, index - 1)} />
                <DownOutlined type="down" onClick={() => moveFormElement(index, index + 1)} />
            </OrderFieldButtonsStyle>
            <FormattedMessage id={type} />
        </React.Fragment>
    );
};
