import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { useGlobalActions } from "@context/enabledLangContext/actions";
import { ApplicationFragment } from "@graphql2/types";
import { JourneyDiscountToggle } from "@pages/addJourneysPage/addJourneysPage";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { EventJourneyForm } from "@pages/addJourneysPage/general/forms/eventJourneyForm";
import { PersonalJourneyForm } from "@pages/addJourneysPage/general/forms/personalJourneyForm";
import { BaseTag } from "@pages/tagsPage/baseTagsPageContainer";
import { coachTypes } from "@utils/coachTypes";
import { discountTypes } from "@utils/discountTypes";
import { emptyJourney } from "@utils/emptyItems/emptyJourney";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card, Checkbox, Col, DatePicker, Form, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import environment from "environment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface JourneySettingsFormValues {
    cost: number;
    currency: string;
    tags: string[];
    sequence: number;
    ultimateTimeToRegisterEnabled: boolean;
    ultimateTimeToRegister: Moment;
    maximumParticipantsEnabled: boolean;
    maximumParticipants: number;
    voucherRequired: boolean;
    newRoles: string[];
    type: string;
    coachType: string;
    enabledLocales?: string[] | null;
}

export interface PersonalJourneySettingsFormValues extends JourneySettingsFormValues {
    duration?: number;
}

export interface EventJourneySettingsFormValues extends JourneySettingsFormValues {
    date: Moment;
    distance: number;
    eventCost: number;
    eventCurrency: string;
    eventTitle: LanguageObject;
    eventDescription: LanguageObject;
    location: LanguageObject;
    detailedLocation: LanguageObject;
}

export interface JourneySettingsCardProps {
    form: FormInstance;
    roles: ApplicationFragment["roles"];
    disabled?: boolean;
    hidden?: boolean;
    tags: BaseTag[];
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    type: string;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
    typeChanged(type: string): void;
}

export const JourneySettingsCard: React.FC<JourneySettingsCardProps> = (props: JourneySettingsCardProps) => {
    const [updateEnabledLanguages, resetEnabledLanguages] = useGlobalActions();

    const {
        changeSelectedLanguage,
        form,
        hidden,
        disabled,
        languageErrors,
        onBlur,
        typeChanged,
        activeLanguage,
        roles,
        tags,
        type
    } = props;

    const sharedProps = {
        disabled,
        onBlur
    };

    const renderDiscount = () => {
        const isDiscountDisabled = !form.getFieldValue(["discounts", "disabled"]);

        return (
            <React.Fragment>
                <FormattedMessage
                    id="discount"
                    tagName="h2"
                />
                <Row gutter={16}>
                    <Col sm={6}>
                        <Form.Item
                            name={["discounts", "disabled"]}
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={({ target }) => {
                                    let fields: Partial<JourneyDiscountToggle> = {
                                        disabled: target.checked
                                    };
                                    if (target.checked) {
                                        fields = {
                                            ...fields,
                                            amount: emptyJourney.discounts.amount,
                                            type: emptyJourney.discounts.type
                                        };
                                    }
                                    form.setFieldsValue({ discounts: fields });
                                }}
                            >
                                <FormattedMessage id="journey.settings.disableDiscount" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row gutter={16}>
                            <Col>
                                <Field
                                    {...sharedProps}
                                    numberType
                                    disabled={isDiscountDisabled}
                                    label={<FormattedMessage id="amount" />}
                                    min={0}
                                    name={["discounts", "amount"]}
                                    onBlur={({ target }) => {
                                        form.setFieldsValue({
                                            discounts: {
                                                disabled: !target.value
                                            }
                                        });
                                        if (sharedProps.onBlur) {
                                            sharedProps.onBlur();
                                        }
                                    }}
                                />
                            </Col>
                            <Col>
                                <SelectField
                                    {...sharedProps}
                                    allowClear
                                    defaultActiveFirstOption
                                    disabled={isDiscountDisabled}
                                    label={<FormattedMessage id="type" />}
                                    name={["discounts", "type"]}
                                >
                                    {
                                        discountTypes.map(discountType => (
                                            <Select.Option
                                                key={discountType}
                                                value={discountType}
                                            >
                                                {discountType}
                                            </Select.Option>
                                        ))
                                    }
                                </SelectField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    // tslint:disable-next-line:max-func-body-length
    const renderGeneral = () => (
        <Flex>
            <MarginRightDiv>
                <Field
                    numberType
                    id="cost"
                    label={<FormattedMessage id="cost" />}
                    min={0}
                    name="cost"
                    {...sharedProps}
                />
            </MarginRightDiv>
            <MarginRightDiv>
                <SelectField
                    id="currency"
                    label={<FormattedMessage id="currency" />}
                    name="currency"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                >
                    <Select.Option
                        key="EUR"
                        value="EUR"
                    >
EUR
                    </Select.Option>
                    <Select.Option
                        key="USD"
                        value="USD"
                    >
USD
                    </Select.Option>
                    <Select.Option
                        key="GBP"
                        value="GBP"
                    >
GBP
                    </Select.Option>
                </SelectField>
            </MarginRightDiv>
            <MarginRightDiv>
                <SelectField
                    id="tags"
                    label={<FormattedMessage id="tags" />}
                    mode="multiple"
                    name="tags"
                    {...sharedProps}
                >
                    {tags && tags.map(tag => (
                        <Select.Option
                            key={tag.id}
                            value={tag.id}
                        >
                            {getText(tag.title)}
                        </Select.Option>
                    ))}
                </SelectField>
            </MarginRightDiv>
            <MarginRightDiv>
                <Field
                    numberType
                    id="sequence"
                    label={<FormattedMessage id="sequence" />}
                    min={0}
                    name="sequence"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                />
            </MarginRightDiv>
            <MarginRightDiv>
                <SelectField
                    id="coachType"
                    label={<FormattedMessage id="coachType" />}
                    name="coachType"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                >
                    {coachTypes.map(({ value, label }) => (
                        <Select.Option
                            key={value}
                            value={value}
                        >
                            {label}
                        </Select.Option>
                    ))}
                </SelectField>
            </MarginRightDiv>
            <div>
                <SelectField
                    id="enabledLocales"
                    label={<FormattedMessage id="enabledLocales" />}
                    mode="multiple"
                    name="enabledLocales"
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    {...sharedProps}
                    onChange={(value) => {
                        if (value.length) {
                            updateEnabledLanguages(value as string[]);
                        } else {
                            resetEnabledLanguages();
                        }
                    }}
                >
                    {environment.languages.map(lang => (
                        <Select.Option
                            key={lang}
                            value={lang}
                        >
                            {lang}
                        </Select.Option>
                    ))}
                </SelectField>
            </div>
        </Flex>
    );

    const renderRegistration = () => (
        <Flex>
            <MarginRightDiv>
                <Form.Item
                    name="ultimateTimeToRegisterEnabled"
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}><FormattedMessage id="ultimateTimeToRegisterEnabled" /></Checkbox>
                </Form.Item>
                <Form.Item
                    name="ultimateTimeToRegister"
                    style={{ marginLeft: 0 }}
                >
                    <DatePicker
                        style={{ padding: 0, width: "100%" }}
                        {...sharedProps}
                    />
                </Form.Item>
            </MarginRightDiv>
            <div>
                <Form.Item
                    name="maximumParticipantsEnabled"
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}><FormattedMessage id="maximumParticipants" /></Checkbox>
                </Form.Item>
                <Field
                    numberType
                    min={0}
                    name="maximumParticipants"
                    {...sharedProps}
                />
            </div>
        </Flex>
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="journeysettings" />}
            />

            <FormattedMessage
                id="general"
                tagName="h2"
            />
            {renderGeneral()}
            {renderDiscount()}

            <FormattedMessage
                id="registration"
                tagName="h2"
            />
            {renderRegistration()}
            <Form.Item
                name="voucherRequired"
                valuePropName="checked"
            >
                <Checkbox {...sharedProps}>
                    <FormattedMessage id="voucherRequired" />
                </Checkbox>
            </Form.Item>
            <SelectField
                id="newRoles"
                label={<FormattedMessage id="assignRoles" />}
                mode="multiple"
                name="newRoles"
                {...sharedProps}
            >
                {roles.map(role => (
                    <Select.Option
                        key={role.id}
                        value={role.id}
                    >
                        {role.name}
                    </Select.Option>
                ))}
            </SelectField>

            <FormattedMessage
                id="personalOrEventJourney"
                tagName="h2"
            />
            <SelectField
                id="type"
                label={<FormattedMessage id="type" />}
                name="type"
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage id="form.isrequired" />
                    }
                ]}
                {...sharedProps}
                onChange={value => typeChanged(value as string)}
            >
                <Select.Option
                    key="event"
                    value="event"
                >
Event
                </Select.Option>
                <Select.Option
                    key="personal"
                    value="personal"
                >
Personal
                </Select.Option>
            </SelectField>

            {type === "event" && (
                <EventJourneyForm
                    {...sharedProps}
                    activeLanguage={activeLanguage}
                    changeSelectedLanguage={changeSelectedLanguage}
                    form={form}
                    languageErrors={languageErrors}
                />
            )}

            {type === "personal" && (
                <PersonalJourneyForm {...sharedProps} />
            )}
        </Card>
    );
};
