import styled from "styled-components";

export const AddTranslationsStyle = styled.div`

    .flex {
        display: flex;

        .anticon {
            align-self: center;
            margin-left: 10px;
            color: red;
        }

        .ant-btn {
        margin-left: 10px;
        }

        .ant-select {
            flex: 1;
        }

        > span {
            margin-left: 5px;
            opacity: 0.5;
            margin-top: 2px;
        }
    }
    
    margin-bottom: 20px;
    h1 {
        margin-bottom: 10px;
    }
` 