import styled from "styled-components";

export const SearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-checkbox-wrapper {
        min-width: 250px;
    }

    .ant-input-search {
        margin-right: 10px;
    }
`;
