import { DashboardConfigWidgetStatistics, DashboardConfigWidgetStatisticsInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetStatisticItem = {
    title: LanguageObject;
    statisticType: string;
    aggregateBy: string;
    icon?: string;
    subProperty?: string;
};

export type DashboardConfigWidgetStatisticsFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetStatistics, {
    title: LanguageObject;
    statistics: DashboardConfigWidgetStatisticItem[];
}>>;

export const mapStatisticsStateWidgetToStatisticsWidget = (widget: DashboardConfigWidgetStatisticsFormValues): DashboardConfigWidgetStatisticsInput => {
    const mapped = {
        ...widget,
        title: toTextByLanguage(widget.title),
        statistics: (widget.statistics || []).map(widgetItem => ({
            ...widgetItem,
            title: widgetItem.title ? toTextByLanguage(widgetItem.title) : null,
            subProperty: widgetItem.statisticType === "users" || widgetItem.statisticType === "challenges" ? "" : widgetItem.subProperty
        }))
    };

    return mapped;
};

export const mapStatisticsWidgetToStatisticsStateWidget = (statisticsWidget: DashboardConfigWidgetStatistics): DashboardConfigWidgetStatisticsFormValues => {
    return {
        ...statisticsWidget,
        title: toLanguageObject(statisticsWidget.title),
        type: statisticsWidget.type as ViewType,
        statistics: (statisticsWidget.statistics || []).map((widgetItem) => ({
            ...widgetItem,
            title: toLanguageObject(widgetItem.title),
            icon: widgetItem.icon || undefined,
            statisticType: widgetItem.statisticType,
            aggregateBy: widgetItem.aggregateBy,
            subProperty: widgetItem.subProperty || undefined
        }))
    };
};
