import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { TagsTableData } from "@components/tags/tagsTable";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Checkbox, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { Moment } from "moment";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationFragment, useGetChallengeTitlesLazyQuery } from "@graphql2/types";
import { debounce } from "@utils/debounce";
import { translateChallengeTitles, TranslatedChallengeTitles } from "@utils/mappers/challengeMapper";
import { GalleryType, galleryTypes } from "../constants/galleryTypes";

export interface GallerySettingsFormValues {
    publishOn: Moment;
    expiresOn: Moment;
    isDraft: boolean;
    roles?: string[] | null;
    type: string;
    typeId?: string | null;
    url?: string;
    tags?: string[];
    featured: boolean;
}

export interface GallerySettingsCardProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    roles: ApplicationFragment["roles"];
    galleryTags: TagsTableData[];

    changeSelectedLanguage(languageCode: string): void;

    onBlur(): void;
}

export const GallerySettingsCard: React.FC<GallerySettingsCardProps> = props => {
    const { hidden, disabled, onBlur, form } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    const [challengeSearch, setChallengeSearch] = useState<string | null | undefined>();
    const [challengeSearchId, setChallengeSearchId] = useState<string | null | undefined>();
    const [searchChallengesTitles, setSearchChallengesTitles] = useState<TranslatedChallengeTitles[]>([]);
    const [initialChallengesTitles, setInitialChallengesTitles] = useState<TranslatedChallengeTitles[]>([]);

    const challengesTitles = useMemo(() => {
        return [
            ...initialChallengesTitles,
            ...searchChallengesTitles.filter((challengeTitle) => !initialChallengesTitles.some(({ id }) => id === challengeTitle.id))
        ];
    }, [searchChallengesTitles, initialChallengesTitles]);

    const [getSearchChallengeTitles] = useGetChallengeTitlesLazyQuery({
        onCompleted(data) {
            if (data.challenges) {
                setSearchChallengesTitles(translateChallengeTitles(data.challenges));
            }
        }
    });

    const [getInitialChallengeTitles] = useGetChallengeTitlesLazyQuery({
        onCompleted(data) {
            if (data.challenges) {
                setInitialChallengesTitles(translateChallengeTitles(data.challenges));
            }
        }
    });

    useEffect(() => {
        const typeId = form.getFieldValue("typeId");
        if (typeId) {
            getInitialChallengeTitles({
                variables: {
                    filter: {
                        ids: [typeId]
                    },
                    paging: {
                        limit: 1
                    }
                }
            });
        }
    }, [form]);

    useEffect(() => {
        if (!challengeSearch && !challengeSearchId) {
            setSearchChallengesTitles([]);
            return;
        }
        getSearchChallengeTitles({
            variables: {
                filter: {
                    ids: challengeSearchId ? [challengeSearchId] : [],
                    textSearch: challengeSearch
                },
                paging: {
                    limit: 10
                }
            }
        });
    }, [challengeSearch, challengeSearchId]);

    const renderTags = (sharedProps) => {
        const { galleryTags } = props;

        return (
            <Flex>
                <SelectField
                    name="tags"
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    mode="multiple"
                    label={<FormattedMessage id="tags" />}
                    id="tags"
                    {...sharedProps}
                    disabled={!galleryTags || !galleryTags.length}
                >
                    {
                        galleryTags
                            ? galleryTags.map(tag => (
                                <Select.Option value={tag.id} key={tag.id}>{tag.title}</Select.Option>
                            ))
                            : null
                    }
                </SelectField>
            </Flex>
        );
    };

    const renderVisibility = (sharedProps) => {
        return (
            <Flex>
                <MarginRightDiv>
                    <DatePickerField
                        name="publishOn"
                        validateTrigger={["onChange", "onSubmit"]}
                        onChange={sharedProps.onblu}
                        rules={[
                            validatePublishOn,
                            requiredrule
                        ]}
                        {...sharedProps}
                        label={<FormattedMessage id="publishOn" />}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <DatePickerField
                        name="expiresOn"
                        validateTrigger={["onChange", "onSubmit"]}
                        rules={[
                            validateExpiresOn,
                            requiredrule
                        ]}
                        {...sharedProps}
                        label={<FormattedMessage id="expiresOn" />}
                    />
                </MarginRightDiv>
            </Flex>
        );
    };

    const renderStatus = (sharedProps) => {
        return (
            <Flex>
                <Form.Item
                    name="isDraft"
                    valuePropName="checked"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="isDraft" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="featured"
                    valuePropName="checked"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="featured" />
                    </Checkbox>
                </Form.Item>
            </Flex>
        );
    };

    const renderRoles = (sharedProps) => {
        const { roles } = props;

        return (
            <Flex>
                <SelectField
                    name="roles"
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    label={<FormattedMessage id="webshop.roles" />}
                    id="roles"
                    mode="multiple"
                    filterOption={(input, option) => option && Object.values(option).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                    {...sharedProps}
                >
                    {roles.map(role => <Select.Option value={role.id} key={role.id}>{role.name}</Select.Option>)}
                </SelectField>
            </Flex>
        );
    };

    const renderGalleryTypeSettings = (sharedProps) => {
        const enableOnSearch = (value: string) => {
            setChallengeSearchId("");
            setChallengeSearch(value);
        };

        const enableOnSearchId = (value: string) => {
            setChallengeSearchId(value);
        };

        const enableOnSearchDebounced = debounce(enableOnSearch, 400);

        const enableOnSearchDebouncedId = debounce(enableOnSearchId, 400);

        return (
            <Flex>
                <Form.Item>
                    <SelectField
                        name="type"
                        label={<FormattedMessage id="type" />}
                        id="type"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        {...sharedProps}
                    >
                        {galleryTypes.map(t => (
                            <Select.Option
                                value={t.toLowerCase()}
                                key={t.toLowerCase()}
                            >{t}
                            </Select.Option>
                        ))}
                    </SelectField>
                </Form.Item>
                <Form.Item dependencies={["type"]}>
                    {() => {
                        const type = props.form.getFieldValue(["type"]) as GalleryType;

                        return type === "challenge"
                            && (
                                <React.Fragment>
                                    <SelectField
                                        label={<FormattedMessage id="challengeWidget.pickChallenge" />}
                                        name="typeId"
                                        id="typeId"
                                        rules={[requiredrule]}
                                        onSearch={enableOnSearchDebounced}
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        notFoundContent={null}
                                        defaultActiveFirstOption={false}
                                        {...sharedProps}
                                    >
                                        {
                                    challengesTitles?.map(challenge => (
                                        <Select.Option
                                            key={challenge.id}
                                            value={challenge.id}
                                        >
                                            {challenge.title}
                                        </Select.Option>
                                    ))
                                        }
                                    </SelectField>
                                    <SelectField
                                        label={<FormattedMessage id="challengeWidget.pickChallengeId" />}
                                        name="typeId"
                                        id="typeId"
                                        rules={[requiredrule]}
                                        onSearch={enableOnSearchDebouncedId}
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        notFoundContent={null}
                                        defaultActiveFirstOption={false}
                                        {...sharedProps}
                                    >
                                        {
                                    challengesTitles?.map(challenge => (
                                        <Select.Option
                                            key={challenge.id}
                                            value={challenge.id}
                                        >
                                            {challenge.id}
                                        </Select.Option>
                                    ))
                                        }
                                    </SelectField>
                                </React.Fragment>
                            );
                    }}

                </Form.Item>
            </Flex>
        );
    };

    const validatePublishDates = (publishOn: Moment, expiresOn: Moment) => {
        if (!publishOn || !expiresOn) {
            // Let required rule handle this
            return Promise.resolve();
        }

        if (publishOn.valueOf() > expiresOn.valueOf()) {
            return Promise.reject(new Error());
        }

        return Promise.resolve();
    };

    const validatePublishOn = ({ getFieldValue }) => {
        return {
            validator: (rule, publishOn: Moment) => {
                const expiresOn = getFieldValue("expiresOn");
                return validatePublishDates(publishOn, expiresOn);
            },
            message: <FormattedMessage id="form.error.expireDateMustBeLaterThanPublishDate" />
        };
    };

    const validateExpiresOn = ({ getFieldValue }) => {
        return {
            validator: (rule, expiresOn: Moment) => {
                const publishOn = getFieldValue("publishOn");
                return validatePublishDates(publishOn, expiresOn);
            },
            message: <FormattedMessage id="form.error.expireDateMustBeLaterThanPublishDate" />
        };
    };
    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="settings" />}
            />
            <Flex direction="column">
                <h3><FormattedMessage id="galleryTypeSettings" /></h3>
                {renderGalleryTypeSettings(sharedProps)}
                <h3><FormattedMessage id="status" /></h3>
                {renderStatus(sharedProps)}
                <h3><FormattedMessage id="roles" /></h3>
                {renderRoles(sharedProps)}
                <h3><FormattedMessage id="visibility" /></h3>
                {renderVisibility(sharedProps)}
                <h3><FormattedMessage id="tags" /></h3>
                {renderTags(sharedProps)}
            </Flex>
        </Card>
    );
};
