import { TagsTableData } from "@components/tags/tagsTable";
import { BaseTagFormValues } from "@pages/addTagsPage/addTagsPage";
import { BaseTag } from "@pages/tagsPage/baseTagsPageContainer";
import { emptyTag } from "@utils/emptyItems/emptyTag";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { AddContentTagMutationVariables, GetEventTagsQuery } from "@graphql2/types";
import { getText } from "../getText";

export function tagToFormValues(contentTag?: BaseTag | null): BaseTagFormValues {
    if (!contentTag) {
        return emptyTag;
    }

    const {
        id,
        title,
        description,
        image,
        color
    } = contentTag;

    return {
        id,
        title: toLanguageObject(title),
        description: toLanguageObject(description),
        image: image ?? "",
        color
    };
}

export function translateTags(originals: (BaseTag | GetEventTagsQuery["eventTags"][0])[]): TagsTableData[] {
    return originals.map(contentTag => {
        const { id, title, color, description, image } = contentTag;

        return {
            id,
            title: getText(title),
            description: getText(description),
            color,
            image
        };
    });
}

export function formValuesToTag(formValues: BaseTagFormValues): AddContentTagMutationVariables {
    const {
        title,
        description,
        image,
        color
    } = formValues;

    return {
        title: toTextByLanguage(title),
        description: toTextByLanguage(description),
        image: image || "",
        color: color || ""
    };
}
