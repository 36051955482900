import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { IGImage } from "@pages/socialPage/socialPageStyle";
import { autobind } from "core-decorators";
import * as moment from "moment";
import { InstagramOutlined, EyeOutlined, RollbackOutlined } from "@ant-design/icons";

export interface InstagramFeedTableData extends TableData {
    image: string;
    caption: string;
    user: string;
    visible: boolean;
}

export interface InstagramFeedTableProps extends TableComponentProps {
    dataSource: InstagramFeedTableData[];
    totalCount?: number;
    handlers: TableHandlers & {
        changeVisibleInstagramFeed(variables: { variables: { id: string; visible: boolean } }): void;
    };
}

@autobind
export class InstagramFeedTable extends React.Component<InstagramFeedTableProps> {
    public render() {
        const { totalCount } = this.props;

        return (
            <Table
                pagination={{
                    total: (totalCount || 0),
                    defaultPageSize: 50,
                    showSizeChanger: true,
                    showQuickJumper: true
                }}
                rowKey="id"
                {...this.props}
            >
                <Table.Column
                    key="date"
                    title={<FormattedMessage id="date" />}
                    dataIndex="date"
                    render={(date) => moment(date).format("DD/MM/YYYY HH:mm")}
                />
                <Table.Column
                    title={<FormattedMessage id="image" />}
                    dataIndex="image"
                    render={this.renderImage}
                    width={80}
                />
                <Table.Column
                    key="caption"
                    title={<FormattedMessage id="caption" />}
                    dataIndex="caption"
                    render={this.renderCaption}
                />
                <Table.Column
                    key="user"
                    title={<FormattedMessage id="user" />}
                    dataIndex="user"
                    width={100}
                />
                <Table.Column
                    key="visit"
                    dataIndex="link"
                    render={(link) => (
                        <a href={link} target="_black"><InstagramOutlined /> <FormattedMessage id="open" /></a>
                    )}
                    width={100}
                />
                <Table.Column
                    key="handlers"
                    width={120}
                    render={(text, row: InstagramFeedTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <Flex justifyContent="flex-end" alignItems="center">
                                    <a onClick={() => handlers.changeVisibleInstagramFeed({ variables: { id: row.id, visible: !row.visible } })}>
                                        {row.visible ? <EyeOutlined /> : <RollbackOutlined />}
                                    </a>
                                </Flex>
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                />
            </Table>
        );
    }

    private renderImage(image: string) {
        return <IGImage image={image} />;
    }

    private renderCaption(caption: string) {
        return <p>{caption.length > 100 ? `${caption.slice(0, 100)}...` : caption}</p>;
    }
}
