import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ExportJourneyContainer } from "@components/export/exportJourneyContainer";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";
import { Flex } from "../flex/flex";

export interface JourneyTableData extends TableData {
    title: string;
    type: string;
    coachType: string;
    participants: number;
}

export interface JourneyTableProps extends TableComponentProps {
    dataSource: JourneyTableData[];
    handlers: TableHandlers & {
        removeJourney(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<JourneyTableData>;
}

export const JourneyTable: React.FC<JourneyTableProps> = ({ handlers, ...tableProps }) => {
    const renderHandlers = (row: JourneyTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <ExportJourneyContainer id={row.id} />
                <Link to={`/journeys/edit/${row.id}/general`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeJourney(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table rowKey="id" {...tableProps}>
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                sorter
            />
            <Column
                key="types"
                title={<FormattedMessage id="type" />}
                dataIndex="type"
                filters={[
                    {
                        text: "Event",
                        value: "event"
                    },
                    {
                        text: "Personal",
                        value: "personal"
                    }
                ]}
            />
            <Column
                key="coachTypes"
                title={<FormattedMessage id="coachType" />}
                dataIndex="coachType"
                filters={[
                    {
                        text: "Sport",
                        value: "sport"
                    },
                    {
                        text: "Food",
                        value: "food"
                    },
                    {
                        text: "None",
                        value: "none"
                    },
                    {
                        text: "Popup",
                        value: "popup"
                    }
                ]}
            />
            <Column
                key="participants"
                title={<FormattedMessage id="participants" />}
                dataIndex="participants"
                sorter
            />
            <Column
                title=" "
                dataIndex=""
                render={(_, row: JourneyTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
