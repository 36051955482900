import * as moment from "moment";

export const getEmptyEventProject = (id: string = "") => ({
    projectId: id,
    description: {},
    subscriptionEnabled: true,
    formSubscriptionEnabled: false,
    formTemplates: [],
    roles: [],
    tags: [],
    participants: 0,
    participantsOnWaitingList: 0,
    viewParticipants: false,
    viewParticipantCount: false,
    waitingListEnabled: false,
    reminders: [],
    subEvents: [],
    featured: false
});

export const getEmptyEvent = () => ({
    title: {},
    description: {},
    image: "",
    start: moment(),
    end: moment(),
    registration: {
        start: moment(),
        end: moment()
    },
    publishedOn: moment(),
    expiresOn: moment(),
    activityTypes: [],
    hideInList: false,
    disableComments: false,
    subscriptionEnabled: false,
    viewParticipantCount: false,
    viewParticipants: false,
    enableWaitingList: false,
    organizer: "",
    url: "",
    organizator: "",
    chronoraceEventId: 0,
    subEvents: [],
    projects: []
});
