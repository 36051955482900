import { FileDragAndDropProps } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { FileDragAndDropStyle } from "@components/fileDragAndDrop/fileDragAndDropStyle";
import FileDragAndDropTarget from "@components/fileDragAndDrop/fileDragAndDropTarget";
import { Alert, Button } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { DropTargetMonitor } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { FormattedMessage } from "react-intl";
import { toBase64 } from "@utils/convertToBase64";

// tslint:disable-next-line:no-any -- Library has this types as an any.
export type Item = any;

export interface FileDragAndDropState {
    isEmpty: boolean;
    currentImage?: string;
    uploadError?: string;
}

@autobind
export class FileDragAndDrop extends React.Component<FileDragAndDropProps, FileDragAndDropState> {

    public state: FileDragAndDropState = {
        isEmpty: !this.props.defaultImage,
        currentImage: this.props.defaultImage || undefined
    };

    public render() {
        console.warn("DEPRECATED COMPONENT \"FileDragAndDrop\", use ImageUpload instead");
        const { currentImage, isEmpty, uploadError } = this.state;
        const { FILE } = NativeTypes;
        const fileInputRef = React.createRef<HTMLInputElement>();

        return (
            <React.Fragment>
                {uploadError && <Alert type="error" message={<FormattedMessage id={uploadError} />} />}
                <FileDragAndDropStyle>
                    <div>
                        <FileDragAndDropTarget accepts={[FILE]} onDrop={(_, monitor): void => this.onDrop(monitor)} currentImage={!isEmpty ? currentImage : undefined} />
                        <input hidden type="file" onChange={(e) => this.onChange(e, fileInputRef)} ref={fileInputRef} />
                        <Button onClick={() => this.simulateClickOn(fileInputRef)}>
                            <FormattedMessage id={!isEmpty ? "draganddrop.remove" : "draganddrop.browse"} />
                        </Button>
                    </div>
                </FileDragAndDropStyle>
            </React.Fragment>
        );
    }

    public componentDidUpdate(prevProps: FileDragAndDropProps) {
        //properly setState when data fetching happens after initial render.
        if (prevProps.defaultImage !== this.props.defaultImage) {
            this.setState({
                isEmpty: !this.props.defaultImage,
                currentImage: this.props.defaultImage || undefined
            });
        }
    }

    private simulateClickOn(ref: React.RefObject<HTMLInputElement>) {
        const { isEmpty } = this.state;

        if (isEmpty && ref.current) {
            ref.current.click();
        } else if (!isEmpty) {
            this.updateImage();
        }
    }

    private async updateImage(files?: FileList): Promise<void> {
        const { onChange } = this.props;
        let file: string | undefined;

        if (files) {
            if (files[0].size > 2000000) {
                this.setState({
                    uploadError: "upload.maxFileExceeded"
                });

                return;
            };

            this.setState({
                uploadError: undefined
            });
            try {
                file = await toBase64(files[0]);
            } catch (error) {
                this.setState({
                    uploadError: "upload.tryAgain"
                });
            }
        }

        this.setState({
            isEmpty: !file,
            currentImage: file
        });

        if (onChange) {
            onChange(file);
        }
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) {
        const files = ref.current && ref.current.files;
        this.updateImage(files || undefined);
    }

    private onDrop(monitor: DropTargetMonitor) {
        if (monitor) {
            const files = monitor.getItem().files;

            if (!files.length) {
                return;
            }

            this.updateImage(files);
        }
    }
}
