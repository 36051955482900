/* eslint-disable react/prop-types */
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { UserActivitiesCardContainer } from "@components/users/userActivitiesCard/userActivitiesCardContainer";
import { UserConnectedProvidersCard } from "@components/users/userConnectedProvidersCard/userConnectedProvidersCard";
import { UserInfoCard } from "@components/users/userInfoCard/userInfoCard";
import { UserRolesApplicationsCardContainer } from "@components/users/userRolesApplicationsCard/userRolesApplicationsCardContainer";
import { UserWalletCardContainer } from "@components/users/userWalletCard/userWalletCardContainer";
import { useAuth } from "@context/authContext/context";
import { Application, UpdateUserMutationVariables, UserApplicationInput } from "@graphql2/types";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { PageNotFoundPage } from "@pages/pageNotFoundPage/pageNotFoundPage";
import { FlexButtonWrapper } from "@pages/rolesPage/rolesPageStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { UserDetailPageProps } from "@pages/userDetailPage/userDetailPageContainer";
import { CenteredCol, UserDetailPageStyle } from "@pages/userDetailPage/userDetailPageStyle";
import { removeUserFn } from "@utils/removeUserFn";
import { Breadcrumb, Button, Col, Form, Modal, Popconfirm, Row, Select, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

export interface UserDetailPageState {
    saving: boolean;
    showImpersonateModal: boolean;
    showUpdateUserModal: boolean;
    storingImage: boolean;
    imageUrl?: string;
}

export const UserDetailPage: React.FC<UserDetailPageProps> = (props) => {
    const {
        users: { users, loading: usersLoading },
        history,
        removeUser
    } = props;
    const [showUpdateUserModal, setShowUpdateUserModal] = React.useState(false);
    const [showImpersonateUserModal, setShowImpersonateUserModal] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const user = users && users[0];
    const [form] = useForm();
    const intl = useIntl();
    const { projectId } = useAuth();

    if (usersLoading) {
        return <Spin />;
    }

    if (!user) {
        props.history.push("/404");
        return <PageNotFoundPage />;
    }

    const avatar = user && user.avatar;
    const { givenName, familyName, id, locked } = user;
    const name = givenName && familyName ? `${givenName} ${familyName}` : "";

    const unlinkProvider = async (userId: string, provider: string) => {
        try {
            await props.unlinkProvider({ variables: { userId, provider } });
            message.success(intl.formatMessage({ id: "provider.unlinked" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "provider.unlinkFailed" }));
        }
    };

    const changeBlockedUserState = async () => {
        try {
            await props.updateUser({ variables: { id: user.id, projectId, applications: null, locked: !locked } });
            message.success(intl.formatMessage({ id: locked ? "user.unBlocked" : "user.blocked" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: locked ? "user.couldNotUnblock" : "user.couldNotBlock" }));
        }
    };

    const validateEmail = async () => {
        try {
            const { data } = await props.validateEmail({ variables: { userId: id } });
            if (data?.validateEmail.success) {
                message.success(intl.formatMessage({ id: "email.validatedSuccessfully" }));
            } else {
                throw Error();
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: "email.validationFailed" }));
        }
    };

    const requestValidateEmail = async () => {
        try {
            const { data } = await props.requestValidateEmail({ variables: { email: user.email } });
            if (data?.requestValidateEmail.success) {
                message.success(intl.formatMessage({ id: "email.validationEmailSendSuccessfully" }));
            } else {
                throw Error();
            }
        } catch {
            message.error(intl.formatMessage({ id: "email.validationEmailSendFailed" }));
        }
    };

    const submitForm = async (values: UpdateUserMutationVariables) => {
        // TODO: create a mapper

        if (!user) {
            return;
        }

        setSaving(true);

        try {
            await props.updateUser({ variables: { ...values, id: user.id, projectId } });
            message.success(intl.formatMessage({ id: "user.updated" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "user.updateFailed" }));
        }

        setSaving(false);
    };

    const updateApplications = async (input: UserApplicationInput[], userId: string) => {
        setSaving(true);
        try {
            await props.updateUser({ variables: { id: userId, applications: input, projectId } });
            message.success(intl.formatMessage({ id: "user.updated" }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "user.updateFailed" }));
        }
        setSaving(false);
    };

    const impersonateUser = async (applicationId: string, userId: string) => {
        try {
            const response = await props.impersonateUser({ variables: { applicationId, userId, projectId } });

            if (response && response.data && response.data.impersonateUser.applicationUrl) {
                message.success(intl.formatMessage({ id: "user.impersonatedRedirect" }));
                window.open(response.data.impersonateUser.applicationUrl, "_blank");
            } else {
                message.success(intl.formatMessage({ id: "user.impersonateFailed" }));
            }
        } catch (err) {
            message.success(intl.formatMessage({ id: "user.impersonateFailed" }));
            console.error(err);
        }
    };

    const resyncProvider = async (provider: string, from: number, to: number) => {
        try {
            const response = await props.resyncProvider({ variables: { userId: user.id, provider, from, to } });
            if (response.data?.resyncProvider?.success) {
                message.success(intl.formatMessage({ id: "user.provider.resync.success" }));
            }
        } catch (err) {
            message.error(intl.formatMessage({ id: "user.provider.resync.error" }));
            console.error(err);
        }
    };

    const userHasApplications = user?.applications?.length !== (undefined || 0);
    const userApplications = (users?.[0]?.applications || []) as Application[];

    return (
        <UserDetailPageStyle>
            <HeaderContent>
                <HeaderActions>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/users/overview">
                                <FormattedMessage id="overview" />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        <Button
                            className="headerButton"
                            disabled={!userHasApplications}
                            type="primary"
                            onClick={() => setShowImpersonateUserModal(true)}
                        >
                            <FormattedMessage id="user.impersonate" />
                        </Button>
                        <Modal
                            closable={false}
                            footer={[
                                <Button onClick={() => setShowImpersonateUserModal(false)}>
                                    <FormattedMessage id="Cancel" />
                                </Button>
                            ]}
                            title={<FormattedMessage id="user.impersonate.title" />}
                            visible={showImpersonateUserModal}
                        >
                            <Row
                                gutter={[16, 16]}
                                justify="center"
                            >
                                {user.applications && user.applications.map(app => app && (
                                    <CenteredCol
                                        key={app.id}
                                        span={24}
                                    >
                                        <Button
                                            type="primary"
                                            onClick={async () => impersonateUser(app.id, user.id)}
                                        >
                                            {app.name}
                                        </Button>
                                    </CenteredCol>
                                ))}
                            </Row>
                        </Modal>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            placement="bottomRight"
                            title={<FormattedMessage id={!locked ? "blockConfirm" : "unblockConfirm"} />}
                            onConfirm={changeBlockedUserState}
                        >
                            <Button className="headerButton">
                                <FormattedMessage id={locked ? "user.unblock" : "user.block"} />
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            placement="bottomRight"
                            title={<FormattedMessage id="anonimyseConfirm" />}
                            onConfirm={async () => removeUserFn(id, removeUser, history, true)}
                        >
                            <Button
                                danger
                                className="headerButton"
                                type="dashed"
                            >
                                <FormattedMessage id="user.anonimyse" />
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            placement="bottomRight"
                            title={<FormattedMessage id="deleteConfirm" />}
                            onConfirm={async () => removeUserFn(id, removeUser, history)}
                        >
                            <Button
                                danger
                                className="headerButton"
                            >
                                <FormattedMessage id="user.remove" />
                            </Button>
                        </Popconfirm>
                    </div>
                </HeaderActions>
            </HeaderContent>
            <Row>
                <Col
                    push={8}
                    span={16}
                >
                    <UserActivitiesCardContainer userId={id} />
                    <UserRolesApplicationsCardContainer
                        existingRoles={userApplications}
                        forceLoading={saving}
                        onUpdate={updatedAppVars => {
                            updateApplications(updatedAppVars, user.id);
                        }}
                    />
                </Col>
                <Col
                    pull={16}
                    span={8}
                >
                    <UserInfoCard
                        requestValidateEmail={requestValidateEmail}
                        toggleEditUserModal={setShowUpdateUserModal}
                        users={users}
                        usersLoading={usersLoading}
                        validateEmail={validateEmail}
                    />
                    <UserConnectedProvidersCard
                        resyncProvider={resyncProvider}
                        unlinkProvider={unlinkProvider}
                        users={users}
                        usersLoading={usersLoading}
                    />
                    <UserWalletCardContainer userId={id} />
                </Col>
            </Row>
            <Modal
                className="noModalFooter"
                title={(
                    <FormattedMessage
                        id="user.editProfileOf"
                        values={{ name: `${user.givenName} ${user.familyName}` }}
                    />
                )}
                visible={showUpdateUserModal}
                onCancel={() => setShowUpdateUserModal(false)}
                onOk={() => setShowUpdateUserModal(false)}
            >
                <Form
                    form={form}
                    initialValues={user}
                    onFinish={submitForm}
                >
                    <FormattedMessage
                        id="general"
                        tagName="h3"
                    />
                    <Flex>
                        <Form.Item>
                            <Field
                                id="givenName"
                                label={<FormattedMessage id="firstName" />}
                                name="givenName"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Field
                                id="familyName"
                                label={<FormattedMessage id="familyName" />}
                                name="familyName"
                                type="text"
                            />
                        </Form.Item>
                    </Flex>
                    <Flex>
                        <Form.Item>
                            <Field
                                id="email"
                                label={<FormattedMessage id="email" />}
                                name="email"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item>
                            <SelectField
                                id="locale"
                                label={<FormattedMessage id="locale" />}
                                name="locale"
                            >
                                {languages.map(l => (
                                    <Select.Option
                                        key={l.code}
                                        value={l.code}
                                    >
                                        {l.label}
                                    </Select.Option>
                                ))}
                            </SelectField>
                        </Form.Item>
                    </Flex>
                    <FormattedMessage
                        id="avatar"
                        tagName="h3"
                    />
                    <Form.Item name="avatar">
                        <ImageUpload
                            form={form}
                            image={avatar}
                            name="avatar"
                        />
                    </Form.Item>
                    <FlexButtonWrapper
                        fullWidth
                        justifyContent="center"
                        margin="20px 0"
                    >
                        <Button
                            key="button1"
                            htmlType="submit"
                            loading={saving}
                            type="primary"
                        >
                            <FormattedMessage id="profile.save" />
                        </Button>
                    </FlexButtonWrapper>
                </Form>
            </Modal>
        </UserDetailPageStyle>
    );
};
