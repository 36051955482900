import * as React from "react";

import { compose } from "redux";

import { ChangeVisibleInstagramFeedProps, GetInstagramFeedCountProps, GetInstagramFeedProps, withChangeVisibleInstagramFeed, withGetInstagramFeed, withGetInstagramFeedCount } from "@graphql2/types";
import { FilterQueryStateProps, filterQueryState } from "@utils/withFilterQueryState";
import { FilterSortPagingQuery, defaultSortAndPagingSettings } from "@utils/withPagingAndSort";
import { InstagramCard } from "./instagramCard";

export interface InstagramCardContainerProps {
    hidden: boolean;
}

const withFilterQueryState = filterQueryState<FilterSortPagingQuery>({
    pageSize: Number(defaultSortAndPagingSettings.pageSize),
    skip: 0,
    sortBy: "date",
    sort: "DESC",
    textSearch: ""
});

const withInstagramFeed = withGetInstagramFeed({
    name: "instagramFeed",
    options(ownProps: InstagramCardProps) {
        return {
            variables: {
                filter: ownProps.filterQuery.textSearch ? { search: ownProps.filterQuery.textSearch } : null,
                paging: {
                    skip: ownProps.filterQuery.skip || 0,
                    limit: ownProps.filterQuery.pageSize || 10
                },
                sort: {
                    field: ownProps.filterQuery.sortBy,
                    order: ownProps.filterQuery.sort || "DESC"
                }
            }
        };
    }
});

const withHideInstagramFeed = withChangeVisibleInstagramFeed({
    name: "changeVisibleInstagramFeed"
});
const withInstagramFeedCount = withGetInstagramFeedCount({
    name: "count",
    options(ownProps: InstagramCardProps) {
        return {
            variables: {
                filter: ownProps.filterQuery.textSearch ? { search: ownProps.filterQuery.textSearch } : null
            }
        };
    }
});

export const InstagramCardContainer = compose<React.ComponentClass<InstagramCardContainerProps>>(
    withFilterQueryState,
    withInstagramFeedCount,
    withInstagramFeed,
    withHideInstagramFeed
)(InstagramCard);

export type InstagramCardProps =
    InstagramCardContainerProps
    & FilterQueryStateProps<FilterSortPagingQuery>
    & GetInstagramFeedProps<{}, "instagramFeed">
    & GetInstagramFeedCountProps<{}, "count">
    & ChangeVisibleInstagramFeedProps<{}, "changeVisibleInstagramFeed">;
