import styled from "styled-components";
import { ContentHeaderStyle } from "@components/contentHeader/contentHeaderStyle";

export const AddVouchersPageStyle = styled.div`
    .smallMargin {
      ${ContentHeaderStyle} {
          margin: 10px 0;
      }
    }
`;

export const VoucherRowButtonWrap = styled.div`
  margin-top: 22px;
`;
