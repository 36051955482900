/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { GlobalOutlined } from "@ant-design/icons";
import { Field } from "@components/field/field";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { toLanguageObject } from "@utils/toLanguageObject";
import { Button, Checkbox, Form, Popover, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IsCustomIconStyle, TranslationFormStyle, TranslationUpdateFields } from "./translationTableStyle";
import { MergedTranslation } from "./translationsList/translationsListContainer";

enum TranslationAction {
    UNTOUCHED = "untouched",
    ADD = "add",
    DELETE = "delete",
    UPDATE = "update"
}

export type TranslationTableData = TableData & MergedTranslation;

export interface TranslationTableProps extends TableComponentProps {
    dataSource: TranslationTableData[];
    handlers: TableHandlers & {};
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<TranslationTableData>;
}

export const TranslationTable: React.FC<TranslationTableProps> = ({ handlers, ...tableProps }) => {
    const [unsaved, setUnsaved] = useState({});

    const translationsMap = useMemo(() => {
        const _translationMap = {};
        let rowNumber = 0;
        for (const row of tableProps.dataSource) {
            _translationMap[row.id] = toLanguageObject(tableProps.dataSource[rowNumber++].translations);
        }
        return _translationMap;
    }, [tableProps.dataSource]);

    const checkOnChanged = (row: TranslationTableData, fieldname: string[]): boolean => {
        const { variables } = tableProps;
        if (variables) {
            const updatedValue = variables.form.getFieldValue(fieldname);
            if (!translationsMap[row.id]) {
                return false;
            }
            const correctTranslation = translationsMap[row.id][variables.language];
            return (!correctTranslation && updatedValue) || (updatedValue && correctTranslation !== updatedValue);
        }

        return false;
    };

    const getRowAction = (row: TranslationTableData): TranslationAction => {
        const { variables } = tableProps;

        if (variables && variables.form) {
            const custom = variables.form.getFieldValue([row.id, variables.language, "custom"]);// todo bla bla ?

            const hasBeenChanged = checkOnChanged(row, [row.id, variables.language, "value"]);

            if (hasBeenChanged) {
                if (variables.superAdmin) {
                    return TranslationAction.UPDATE;
                }

                if (row.default && custom) {
                    return TranslationAction.ADD;
                }

                if (!row.default && custom) {
                    return TranslationAction.UPDATE;
                }
            }

            if (!row.default && !custom && custom !== undefined) {
                return TranslationAction.DELETE;
            }
        }

        return TranslationAction.UNTOUCHED;
    };

    const onSaveValue = (row: TranslationTableData) => {
        const action = getRowAction(row);
        const { addTranslation, updateTranslation, removeTranslation } = handlers;
        const { variables } = tableProps;

        if (!variables || !variables.form) {
            return;
        }

        const updatedValue = variables.form.getFieldValue([row.id, variables.language, "value"]);

        if (action === TranslationAction.UNTOUCHED) {
            return;
        }

        if (action === TranslationAction.ADD) {
            addTranslation(row.key, updatedValue);
        }

        if (action === TranslationAction.UPDATE) {
            updateTranslation(row.id, row.key, updatedValue);
        }

        if (action === TranslationAction.DELETE) {
            removeTranslation(row.id);
        }
    };

    const renderHandlers = (row: TranslationTableData): Handlers => {
        const rowAction = getRowAction(row);
        return {
            children: (
                <Button
                    danger={rowAction === TranslationAction.DELETE}
                    disabled={rowAction === TranslationAction.UNTOUCHED}
                    type={
                        rowAction === TranslationAction.DELETE
                            ? "default"
                            : "primary"
                    }
                    onClick={() => onSaveValue(row)}
                >
                    <FormattedMessage
                        id={`translations.${tableProps.variables && tableProps.variables.superAdmin ? "generic." : ""}${rowAction}`}
                    />
                </Button>
            ),
            props: {
                colSpan: 2
            }
        };
    };

    const translationPopup = (genericTranslations: TranslationTableData["genericTranslations"]) => {
        const { variables } = tableProps;
        if (variables && variables.language) {
            const correctTranslation = (genericTranslations || []).find(t => t && variables && t.language === variables.language);

            return (
                <div>
                    <p>
                        {correctTranslation ? correctTranslation.text
                            : <FormattedMessage id="genericTranslation.notFound" />}
                    </p>
                </div>
            );
        }
    };

    const renderGenericTranslation = (row: TranslationTableData) => {
        if (row.default) {
            return;
        }

        return (
            <Popover
                content={translationPopup(row.genericTranslations)}
                title={<FormattedMessage id="genericTranslation.title" />}
            >
                <IsCustomIconStyle>
                    <GlobalOutlined />
                </IsCustomIconStyle>
            </Popover>
        );
    };

    const renderCorrectValue = (row: TranslationTableData) => {
        const { variables } = tableProps;
        if (variables && variables.language && variables.form) {
            if (!translationsMap[row.id]) {
                return null;
            }
            const defaultValue = translationsMap[row.id][variables.language];
            const valueFieldName = [row.id, variables.language, "value"];
            const customFieldName = [row.id, variables.language, "custom"];
            const custom = variables.form.getFieldValue(customFieldName);
            return (

                <TranslationUpdateFields>
                    <Form
                        form={variables.form}
                        onFieldsChange={([field]) => {
                            if (field) {
                                const fieldname = (field.name as string[]).join(".");
                                const isChanged = checkOnChanged(row, field.name as string[]);
                                const shouldUpdate = unsaved[fieldname] !== isChanged;
                                unsaved[fieldname] = isChanged;

                                if (shouldUpdate) {
                                    setUnsaved({ ...unsaved });
                                }
                            }
                        }}
                    >
                        <TranslationFormStyle>
                            <Form.Item style={{ margin: 0 }}>
                                <Field
                                    key={valueFieldName.join()}
                                    disabled={!variables.superAdmin ? custom === undefined ? Boolean(row.default) : Boolean(!custom) : false}
                                    initialValue={defaultValue}
                                    name={valueFieldName}
                                />
                            </Form.Item>
                            <Form.Item
                                key={customFieldName.join()}
                                initialValue={!row.default}
                                name={customFieldName}
                                valuePropName="checked"
                            >
                                <Checkbox><FormattedMessage id="translations.setCustom" /></Checkbox>
                            </Form.Item>
                        </TranslationFormStyle>
                    </Form>
                </TranslationUpdateFields>
            );
        }

        return <FormattedMessage id="translation.notFound" />;
    };

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="key"
                sorter
                dataIndex="key"
                title={<FormattedMessage id="key" />}
                width={300}
            />
            <Column
                key="text"
                dataIndex=""
                render={(_, row: TranslationTableData) => renderCorrectValue(row)}
                title={<FormattedMessage id="text" />}
            />
            <Column
                key="genericTranslations"
                align="left"
                dataIndex="genericTranslations"
                render={(_, row: TranslationTableData) => renderGenericTranslation(row)}
                title={" "}
                width={20}
            />
            <Column
                key="handlers"
                align="right"
                dataIndex=""
                render={(_, row: TranslationTableData) => renderHandlers(row)}
                title=" "
                width={240}
            />
        </Table>
    );
};
