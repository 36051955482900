import { theme } from "@style/theme";
import styled from "styled-components";
import { Row, Col } from "antd";

export const ViewCheckWrapper = styled.div`
    display: flex;
`;

export interface WidgetConfiguratorHeaderStyleProps {
    open: boolean;
}

export const WidgetConfiguratorHeaderStyle = styled.div<WidgetConfiguratorHeaderStyleProps>`
    display: flex;
    align-items: center;
    background: #cccccc;
    padding: 20px;
    border-radius: 4px 4px 0 0;

    & .ant-row {
        /* Global.css Reset */
        margin-bottom: 0px;
    }

    .ant-checkbox-wrapper, .ant-checkbox-wrapper .ant-checkbox + span {
        display: flex;
        align-items: center;
    }

    .ant-checkbox-wrapper .ant-checkbox {
        display: none;

        & + span {
            & .anticon {
                margin-right: 0.5em;
            }
        }
    }

    .ant-checkbox + span {
        color: ${theme.colors.error};
    }

    .ant-checkbox-checked + span {
        color: rgba(0, 0, 0, 0.65);
    }

`;

export const WidgetHeaderOptionsStyle = styled(Row)`
    align-items: center;
    width: 100%;
`;

export const WidgetSequenceConfigurator = styled.div`
    display: flex;
    margin-right: 15px;
    flex-direction: column;

    & .anticon {
        margin: 0px 0;
        color: #373737;
        cursor: pointer;

        &.anticon-caret-left {
            margin-right: 0.5em;
        }
        &.anticon-caret-right {
            margin-left: 0.5em;
        }
        &:hover {
            color: ${theme.primaryColor};
        }
    }
`;

export const WidgetHeaderIconsColStyle = styled(Col)`
    margin-left: auto;
    & > .ant-row {
        align-items: center;
    }
`;

export const WidgetConfigurationHeaderTitle = styled.span`
    color: #373737;
    font-weight: 600;
`;

export const WidgetConfigurationRemover = styled.div`
    align-items: center;
    cursor: pointer;

    & .anticon {
        position: relative;
        top: 1px;
        margin-right: 5px;
    }

    & > .ant-row.ant-form-item {
        margin: 0
    }

    & .ant-checkbox + span {
        padding: 0;
    }
`;

export const WidgetConfigurationCollapser = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 35px;
    align-items: center;
    cursor: pointer;

    &::before {
        left: 0;
        content: "";
        width: 1px;
        height: 20px;
        background: #DEDEDE;
    }
`;
