/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { SelectField } from "@components/field/selectField";
import { RelatedArticles } from "@components/relatedArticles/relatedArticles";
import { BaseTag } from "@pages/tagsPage/baseTagsPageContainer";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { Card, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CardSubtitle } from "../challengeGeneralTabStyle";
import DateAndTimesSettings from "./DateAndTimesSettings";

export interface ChallengeDurationFormValues {
    start: Moment;
    end: Moment;
}

export interface ChallengeSettingsFormValues {
    duration: ChallengeDurationFormValues;
    publishedOn: Moment;
    expiresOn?: Moment;
    setCustomExpire?: boolean;
    ultimateSyncTime: Moment;
    registration?: {
        participateOnStart: boolean;
    };
    hideProgressInList: boolean;
    hideProgressInDetails: boolean;
    hideProgressInInsights: boolean;
    hideInList: boolean;
    showFullDescription: boolean;
    priority: number;
    roles: string[];
    featured: boolean;
    activityTypes: string[];
    viewParticipants: boolean;
    viewParticipantCount: boolean;
    viewTeamCount: boolean;
    viewCompletedParticipants: boolean;
    challengeVoucherConfiguration: {
        required: boolean;
        url?: string | null;
        title?: { [lang: string]: { text: string; }; };
        description?: { [lang: string]: { text: string; }; };
    };
    enabledLocales: string[];
    totals: {
        distanceVisible: boolean;
        durationVisible: boolean;
        stepsVisible: boolean;
        caloriesVisible: boolean;
        elevationVisible: boolean;
        creditsEarnedVisible: boolean;
    };
    tags: string[];
    relatedContentId?: string[];
    superDays: {
        dates: { from: Moment; to: Moment; }[];
        title: { [lang: string]: string; };
        description: { [lang: string]: string; };
        multiplier: number;
    };
    autoParticipationEnabled: boolean;
    disableComments: boolean;
}

export interface ChallengeBasicSettingsCardProps {
    form: FormInstance;
    defaultValues: Partial<ChallengeSettingsFormValues>;
    disabled?: boolean;
    hidden?: boolean;
    languageErrors?: LanguageErrors;
    onBlur(): void;
    challengeParticipantCount?: number;
    editMode: boolean;
    tags?: BaseTag[];
}

export interface SharedProps {
    disabled: boolean | undefined;
    onChange: () => void;
}

export const ChallengeBasicSettingsCard: React.FC<ChallengeBasicSettingsCardProps> = (props) => {
    const {
        form,
        hidden,
        disabled,
        onBlur,
        defaultValues: { enabledLocales },
        challengeParticipantCount,
        editMode,
        tags
    } = props;

    const sharedProps: SharedProps = {
        disabled,
        onChange: onBlur
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="edit.basicChallengeSettings" />}
            />
            <DateAndTimesSettings
                challengeParticipantCount={challengeParticipantCount}
                editMode={editMode}
                enabledLocales={enabledLocales}
                form={form}
                {...sharedProps}
            />
            <Row style={{ flexDirection: "column", maxWidth: "685px" }}>
                <SelectField
                    id="tags"
                    label={<FormattedMessage id="tags" />}
                    mode="multiple"
                    name="tags"
                    {...sharedProps}
                >
                    {tags && tags.map(tag => (
                        <Select.Option
                            key={tag.id}
                            value={tag.id}
                        >
                            {getText(tag.title)}
                        </Select.Option>
                    ))}
                </SelectField>
                <CardSubtitle><FormattedMessage id="challenge.relatedContents" /></CardSubtitle>
                <RelatedArticles
                    label={<FormattedMessage id="webshop.relatedContentId" />}
                    mode="multiple"
                    name="relatedContentId"
                />
            </Row>
        </Card>
    );
};
