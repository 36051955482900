import styled from "styled-components";
import { theme } from "@style/theme";

export const UnsavedChangesStyle = styled.span`
    margin-right: 1em;
    font-size: 14px;
    font-weight: 400;
    [role="img"] {
        color: ${props => theme.primaryColor};
        margin-right: 0.5rem;
    }
`;
