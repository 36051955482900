import { Grid } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineVisualisation";
import { Card } from "antd";
import styled from "styled-components";

export const DisabledText = styled.span`
    opacity: 0.5;
`;

export const LeaderboardColumnTableStyles = styled.div`
    .ant-table-row > * {
        vertical-align: middle;
    }

    margin-bottom: 3em;
`;

export interface VisualisationdGridStyleProps {
    hidden?: boolean;
}

export const VisualisationdGridStyle = styled(Grid) <VisualisationdGridStyleProps>`
    width: 100%;

    .ant-tabs {
        margin-top: 1em;
    }

    p {
        margin: 10px 0 20px 0;
    }

    &:hover {
        z-index: unset;
    }
`;

export const VisualisationdCardstyle = styled(Card)`
    .ant-card-body {
        padding: 0;
    }
`;

export const AddColumnButtonWrapper = styled.div`
    margin-left: 0.5em;
    margin-top: 0.3em;
`;

export const VisualisationdGridImagesStyle = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Label = styled.span`
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 40px;
    font-family: "PingFang SC", sans-serif;
`;

export const ProfileFieldsTranslationWrapper = styled.div`
    border-left: 2px solid #D9D9D9;
    padding-left: 20px;
    margin-top: 20px;
`;
