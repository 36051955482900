import * as React from "react";
import { compose } from "redux";
import { withGetFormTemplates, GetFormTemplatesQueryResult } from "../../graphql2/types";
import { exportFactory, ExportProps } from "./export";
import { ExportContainerProps, withLocalState } from "./exportContainer";

export const withFormTemplate = withGetFormTemplates({
    options: (props: ExportContainerProps) => ({
        variables: {
            filter: {
                ids: [props.id],
                type: "event"
            },
            paging: {}
        }
    }),
    skip: (props: ExportContainerProps) => {
        return !props.shouldExport;
    }
});

export const ExportFormTemplateContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withFormTemplate
)(exportFactory({ targetProp: ["data", "formTemplates"] }));

export type ExportFormTemplateContainerprops = ExportContainerProps & MaybeElements<GetFormTemplatesQueryResult>;
