
import { AddRoleProps, GetApplicationsProps, RemoveRoleProps, UpdateRoleProps, withAddRole, withGetApplications, withRemoveRole, withUpdateRole } from "@graphql2/types";
import { RolesPage } from "@pages/rolesPage/rolesPage";
import * as React from "react";
import { compose } from "redux";

export interface RolesPageContainerProps { }

const options = {
    refetchQueries: ["getApplications"],
    awaitRefetchQueries: true
};

const withApplicationsQuery = withGetApplications({ name: "applications" });
const withRemoveRoleMutation = withRemoveRole({
    name: "removeRole",
    options
});
const withAddRoleMutation = withAddRole({
    name: "addRole",
    options
});
const withUpdateRoleMutation = withUpdateRole({
    name: "updateRole",
    options
});

export const RolesPageContainer = compose<React.ComponentClass<RolesPageContainerProps>>(
    withApplicationsQuery,
    withRemoveRoleMutation,
    withAddRoleMutation,
    withUpdateRoleMutation
)(RolesPage);

export type RolesPageProps =
    RolesPageContainerProps
    & GetApplicationsProps<{}, "applications">
    & RemoveRoleProps<{}, "removeRole">
    & AddRoleProps<{}, "addRole">
    & UpdateRoleProps<{}, "updateRole">;
