import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { SelectField } from "@components/field/selectField";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import { Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

export class FormFieldContent extends FormFieldBase {
    public renderBody() {
        const { index, deleteFormElement } = this.props;

        return (
            <React.Fragment>
                <FormItemFlex>
                    <SelectField
                        style={{ minWidth: 190 }}
                        name={[index, "type"]}
                        initialValue="h1"
                        {...this.sharedProps}
                        label={<FormattedMessage id="contentType" />}
                    >
                        <Select.Option
                            value="h1"
                            key="1"
                        >
                            <FormattedMessage id="heading_1" />
                        </Select.Option>
                        <Select.Option
                            value="h2"
                            key="2"
                        >
                            <FormattedMessage id="heading_2" />
                        </Select.Option>
                        <Select.Option
                            value="p"
                            key="3"
                        >
                            <FormattedMessage id="paragraph" />
                        </Select.Option>
                    </SelectField>
                    <DeleteFormField
                        as={DeleteOutlined}
                        onClick={deleteFormElement}
                    />
                </FormItemFlex>
                <FormItemFlex>
                    <Field
                        name={[index, "property"]}
                        initialValue={this.props.property}
                        {...this.sharedProps}
                        label={<FormattedMessage id="translationKey" />}
                    />
                </FormItemFlex>
            </React.Fragment>
        );
    }
}
