import { ContentHeader } from "@components/contentHeader/contentHeader";
import { getFormField } from "@utils/form/getFormField";
import { Alert, Card, Col, Divider } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as environment from "environment";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import * as React from "react";
import { Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";

const getExtensionsFormFields = (property: "challengeExtensions" | "treeVisualization", form: FormInstance) => {
    if (!environment[property]) {
        return null;
    }

    return Object.keys(environment[property].optionalProperties).reduce((acc: Record<string, JSX.Element | null>, key) => {
        const fieldSchema = environment[property].optionalProperties[key];
        const fieldName = ["data", key];
        const fieldLabel = capitalize(lowerCase(key));
        const fieldKey = fieldName.join();

        return { ...acc, [key]: getFormField(fieldSchema, fieldName, fieldLabel, fieldKey, form) };
    }, {});
};

export const ChallengeExtensionsTab: React.FC<{form: FormInstance;}> = ({ form }) => {
    const fields = useMemo(() => {
        return getExtensionsFormFields("challengeExtensions", form);
    }, []);

    const treeVisFields = useMemo(() => {
        return getExtensionsFormFields("treeVisualization", form);
    }, []);

    return (
        <Fragment>
            <Card>
                <ContentHeader
                    title={<FormattedMessage id="challenge.extensions" />}
                />
                <Col span={8}>
                    {fields && Object.keys(fields).map((key) => {
                        return fields[key];
                    })}
                </Col>
            </Card>
            { treeVisFields && <TreeVisualizationConfigFields treeVisFields={treeVisFields} />}
        </Fragment>
    );
};

const TreeVisualizationConfigFields = ({ treeVisFields }: {treeVisFields: Record<string, JSX.Element | null>;}) => {
    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="challenge.treeVisualization.title" />}
            />
            <Col span={16}>
                {treeVisFields.enableTreeVisualization}
                <Alert
                    showIcon
                    description={(
                        <a
                            aria-label="coordinates tool link"
                            href="https://www.keene.edu/campus/maps/tool/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FormattedMessage id="challenge.treeVisualization.toolToGetCoordinates" />
                        </a>
                    )}
                    message={<FormattedMessage id="challenge.treeVisualization.howToGetCoordinates" />}
                    type="info"
                />
                {treeVisFields.treesGeometry}
                <Alert
                    showIcon
                    message={<FormattedMessage id="challenge.treeVisualization.treeMultiplier" />}
                    type="info"
                />
                {treeVisFields.treeMultiplier}
            </Col>
            <Divider />
            <Col span={16}>
                <Alert
                    showIcon
                    description={<FormattedMessage id="challenge.treeVisualization.treeGrid" />}
                    message={<FormattedMessage id="warning" />}
                    type="warning"
                />
                {treeVisFields.enableTreeGrid}
            </Col>
            <Divider />
            <Col span={8}>
                {treeVisFields.customIcon}
            </Col>
        </Card>
    );
};
