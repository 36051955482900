import { Layout } from "antd";
import Icon from "@ant-design/icons";
import styled from "styled-components";

export const HeaderWrapper = styled(Layout.Header)`
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
`;

// TODO: replace icon
export const TriggerIcon = styled(Icon)`
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.85);
`;
