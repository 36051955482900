import { ArticleFormValues } from "@pages/addArticlesPage/addArticlesPage";
import * as moment from "moment";

export const emptyArticle: ArticleFormValues = {
    title: {},
    description: {},
    price: 0,
    imageUrl: "",
    orderRestrictions: {
        enableMaxItemsPerPerson: false,
        maxItemsPerPerson: 0,
        enableMaxItemsPeriod: false,
        maxItemsPeriod: ""
    },
    isDraft: false,
    publishOn: moment(),
    expiresOn: moment(),
    priority: 0,
    articleNumber: "",
    currentStock: 0,
    availableStock: 0,
    roles: undefined,
    countries: undefined,
    instruction: {},
    vouchers: [],
    restrictionDayAmount: 1,
    restrictionDayType: "days",
    type: "physical"
};
