import { GenericWidgetStyle } from "@components/dashboard/widgets/genericWidget/genericWidgetStyle";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { SelectField } from "@components/field/selectField";
import { Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { WidgetConfigStyle } from "../widgetStyle";

export interface GenericWidgetProps extends WidgetProps {

}

const SUB_TYPES = ["invite", "activityOverview", "social", "userCounter", "community", "calculator"] as const;

export const GenericWidget: React.FC<GenericWidgetProps> = (props) => {
    const { applications, widgetId } = props;

    return (
        <GenericWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <SelectField
                    name={["widgets", widgetId, "subType"]}
                    label={<FormattedMessage id="type" />}
                    id="subType"
                >
                    {SUB_TYPES.map(type => <Select.Option value={type} key={type}>{type}</Select.Option>)}
                </SelectField>
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
            </WidgetConfigStyle>
        </GenericWidgetStyle>
    );
};
