import { getCreditConfigCount, getCreditConfigs } from "@graphql2/graphql_op";
import { AddCreditConfigProps, GetCreditConfigCountProps, GetCreditConfigsProps, UpdateCreditConfigProps, withAddCreditConfig, withGetCreditConfigCount, withGetCreditConfigs, withUpdateCreditConfig } from "@graphql2/types";
import { cleanCache } from "@utils/cleanCacheHoc";
import { compose } from "redux";
import { CreditsPage } from "./creditsPage";

const getCreditConfigsQuery = withGetCreditConfigs({
    name: "creditConfigs"
});

const getCreditConfigCountQuery = withGetCreditConfigCount({
    name: "creditConfigCount"
});

const addCreditConfigMutation = withAddCreditConfig({
    name: "addCreditConfig",
    options: {
        refetchQueries: () => ["getCreditConfigs", "getCreditConfigCount"],
        awaitRefetchQueries: true,
        update: cleanCache([getCreditConfigs, getCreditConfigCount])
    }
});

const updateCreditConfigMutation = withUpdateCreditConfig({
    name: "updateCreditConfig",
    options: {
        refetchQueries: () => ["getCreditConfigs"],
        awaitRefetchQueries: true,
        update: cleanCache([getCreditConfigs])
    }
});

export const CreditsPageContainer = compose<React.ComponentClass<CreditsPageProps>>(
    getCreditConfigCountQuery,
    getCreditConfigsQuery,
    addCreditConfigMutation,
    updateCreditConfigMutation
)(CreditsPage);

export type CreditsPageProps =
    GetCreditConfigCountProps<{}, "creditConfigCount"> &
    GetCreditConfigsProps<{}, "creditConfigs"> &
    AddCreditConfigProps<{}, "addCreditConfig"> &
    UpdateCreditConfigProps<{}, "updateCreditConfig">
