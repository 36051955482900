import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { EventSubEventLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { Button, Divider } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EventFormValues } from "../addEventsPage";
import { CardInCard } from "../addEventsPageStyle";
import { EventSubEventFormValue } from "./eventSubEventsOverviewCard";

const titleField = (props: EventSubEventLanguageFieldProps) => (
    <Field
        key={`subEvents.${props.key}.title.${props.language}`}
        hidden={props.hidden}
        id={`subEvents.${props.key}.title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["subEvents", props.key, "title", props.language]}
        rules={[
            requiredrule,
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            }
        ]}
        {...props.sharedProps}
    />
);

interface SubEventContentCardProps {
    form: FormInstance;
    defaultValues: Partial<EventFormValues>;
    disabled?: boolean;
    onBlur(): void;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    back(): void;
    subEvent: EventSubEventFormValue;
    eventKey: number;
    changeSelectedLanguage(languageCode: string): void;
}

export const SubEventContentCard: React.FC<SubEventContentCardProps> = (props) => {
    const {
        form,
        back,
        disabled,
        onBlur,
        defaultValues,
        activeLanguage,
        changeSelectedLanguage,
        languageErrors,
        subEvent,
        eventKey
    } = props;

    const sharedProps = {
        disabled,
        onBlur
    };

    const langFieldProps = languages.map(language => ({
        form,
        sharedProps,
        defaultValues,
        language: language.code,
        hidden: language.code !== activeLanguage,
        subEvent,
        key: eventKey
    }));

    return (
        <CardInCard>
            <ContentHeader
                rightFields={[
                    <Button
                        key="back"
                        onClick={back}
                    >
                        <FormattedMessage id="back" />
                    </Button>
                ]}
                title={<FormattedMessage id="editContent" />}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
            />
            {langFieldProps.map(titleField)}
            <Divider />
            <Field
                id={`subEvents.${eventKey}.info`}
                label={<FormattedMessage id="info" />}
                name={["subEvents", eventKey, "info"]}
                rules={[{
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }]}
                {...sharedProps}
            />
        </CardInCard>
    );
};
