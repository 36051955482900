import * as React from "react";
import { UnsavedChangesStyle } from "@components/unsavedChanges/unsavedChangesStyle";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

export interface UnsavedChangesProps {

}

export const UnsavedChanges: React.FC<UnsavedChangesProps> = () => {
    return (
        <UnsavedChangesStyle>
            <ExclamationCircleFilled />
            <FormattedMessage id="unsaved" />
        </UnsavedChangesStyle>
    );
};
