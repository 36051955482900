import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm } from "antd";
import * as moment from "moment";

import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export interface AmbassadorConfigAmbassadorsTableData extends TableData {
    name: string;
    activities: string[];
    createdOn: number;
    place: string;
}

export interface AmbassadorConfigAmbassadorsTableProps extends TableComponentProps {
    dataSource: AmbassadorConfigAmbassadorsTableData[];
    handlers: TableHandlers & {
        removeAmbassador(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<AmbassadorConfigAmbassadorsTableData>;
}

export const AmbassadorConfigAmbassadorsTable: React.FC<AmbassadorConfigAmbassadorsTableProps> = ({ handlers, ...tableProps }) => {
    const renderHandlers = (row: AmbassadorConfigAmbassadorsTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <Link to={handlers.getEditLink(row.id)}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeAmbassador(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table pagination={{ showSizeChanger: true, showQuickJumper: true }} rowKey="id" {...tableProps}>
            <Column
                key="name"
                title={<FormattedMessage id="name" />}
                dataIndex="name"
            />
            <Column
                key="activities"
                title={<FormattedMessage id="activities" />}
                dataIndex="activities"
                render={(text) => `${text.map((t) => `${t}`)} `}
            />
            <Column
                key="createdOn"
                title={<FormattedMessage id="createdOn" />}
                dataIndex="createdOn"
                render={(text: string) => moment(Number(text)).format("DD/MM/YYYY HH:mm")}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: AmbassadorConfigAmbassadorsTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
