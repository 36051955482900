import * as React from "react";
import { compose } from "redux";
import { ExportProps, exportFactory } from "./export";
import { withLocalState, ExportContainerProps } from "./exportContainer";
import { GetContentTagsProps, withGetContentTags } from "../../graphql2/types";

export const withContentTag = withGetContentTags({
    skip(ownProps: ExportContainerProps) {
        return !ownProps.shouldExport;
    }
});

export const ExportContentTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withContentTag
)(exportFactory({ targetProp: ["data", "contentTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportContentTagContainerProps = ExportContainerProps & MaybeElements<GetContentTagsProps>;
