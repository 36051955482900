export interface LanguageErrors {
    [code: string]: boolean;
}

/**
 * example error object:
 * {
 *      title: {
 *          fr: {
 *              errors: [...]
 *          }
 *      },
 *      description: undefined
 *      content: [
 *          {
 *              title: {
 *                  fr: {
 *                      errors: [...]
 *                  }
 *              }
 *          }
 *      ]
 * }
 */

function recursiveCheck(currentParent, currentKey, outputObj) {

    const currentObject = currentParent[currentKey];

    if (!currentObject) {
        return;
    }

    if (currentObject.errors) {
        outputObj[currentKey] = true;

        return;
    }

    const childKeys = Object.keys(currentObject);
    if (childKeys) {
        childKeys.forEach(key => recursiveCheck(currentObject, key, outputObj));
    }
}

export const errorsToLanguageErrors = (errors: object): LanguageErrors => {
    const languageErrors: LanguageErrors = {};

    if (!errors) {
        return languageErrors;
    }

    Object.keys(errors).forEach(key => recursiveCheck(errors, key, languageErrors));

    return languageErrors;
};
