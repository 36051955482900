import * as React from "react";
import { Card, Spin, Avatar, List, Popconfirm, Modal, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import { UserConnectedProvidersCardStyle } from "@components/users/userConnectedProvidersCard/userConnectedProvidersCardStyle";
import { Flex } from "@components/flex/flex";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { GetUsersQuery } from "@graphql2/types";
import { formatDate } from "@utils/dateUtils";
import { TimeService } from "../../../utils/time";

export interface UserConnectedProvidersCardProps {
    unlinkProvider(userId: string, provider: string): void;
    resyncProvider(provider: string, from: number, to: number): void;
    users?: GetUsersQuery["users"] | null;
    usersLoading: boolean;
}

export const UserConnectedProvidersCard: React.FC<UserConnectedProvidersCardProps> = (props: UserConnectedProvidersCardProps) => {
    const { usersLoading, users, unlinkProvider, resyncProvider } = props;

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [chosenProvider, setChosenProvider] = React.useState<string | null | undefined>("");
    const [timeRange, setTimeRange] = React.useState<{rangeStart?: number; rangeEnd?: number;} | null>(null);

    const showModal = (provider) => {
        setIsModalVisible(true);
        setChosenProvider(provider);
    };

    const handleOk = () => {
        if (chosenProvider && timeRange?.rangeStart && timeRange?.rangeEnd) {
            resyncProvider(chosenProvider, timeRange.rangeStart, timeRange.rangeEnd);
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const formatAndSetRange = (dates) => {
        if (dates) {
            const rangeStart = formatDate(dates[0]);
            const rangeEnd = formatDate(dates[1], true);
            setTimeRange({ rangeStart, rangeEnd });
        } else {
            setTimeRange(null);
        }
    };

    if (usersLoading) {
        return <Card><Spin /></Card>;
    }

    const user = users && users[0];

    if (!user) {
        return null;
    }

    const { providers, id } = user;

    return (
        <Card title={<FormattedMessage id="connectedProviders" />}>
            <UserConnectedProvidersCardStyle>
                <List
                    itemLayout="horizontal"
                    dataSource={providers || []}
                    renderItem={p => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        shape="square"
                                        src={`https://res.cloudinary.com/energylab/image/upload/v1567517523/generic-v2/icons/${p && p.provider}.png`}
                                    />
                                )}
                                description={(
                                    <Flex alignItems="center" justifyContent="flex-end">
                                        <FormattedMessage
                                            id="lastSync"
                                            values={{ lastSync: TimeService.GET_TIMEAGO((p && p.syncedOn) || 0) }}
                                        />
                                        <ReloadOutlined onClick={() => { showModal(p && p.provider); }} />
                                        <Modal
                                            title={<FormattedMessage id="user.provider.resync.modal.header" />}
                                            visible={isModalVisible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            okButtonProps={{ disabled: !timeRange }}
                                        >
                                            <DatePicker.RangePicker
                                                onChange={(dates) => formatAndSetRange(dates)}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                            />
                                        </Modal>
                                        <Popconfirm
                                            title={<FormattedMessage id="deleteConfirm" />}
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() => unlinkProvider(id, (p && p.provider) || "")}
                                        >
                                            <DeleteOutlined />
                                        </Popconfirm>
                                    </Flex>
                                )}
                            />
                        </List.Item>
                    )}
                />
            </UserConnectedProvidersCardStyle>
        </Card>
    );
};
