// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import * as _ from "lodash";

export type ErrorDictionary = any;

const createNestedError = (nameList: (string | number)[], errors, existingObject = {}) => {
    if (nameList.length) {
        const [topLevel, ...rest] = nameList;
        return {
            ...existingObject,
            [topLevel]: createNestedError(rest, errors, existingObject[topLevel])
        };
    }

    return {
        ...existingObject,
        errors
    };
};

export const mapValidateErrorEntity = (errors?: ValidateErrorEntity | null): ErrorDictionary => {
    if (!errors || !errors.errorFields || !errors.errorFields.length) {
        return {};
    }

    return errors.errorFields.reduce(
        (map, error) => {
            const [topLevel, ...rest] = error.name;
            return {
                ...map,
                [topLevel]: createNestedError(rest, error.errors, map[topLevel])
            };
        },
        {}
    );
};

export const hasError = (errors: ErrorDictionary, path: (string | number)[]) => {
    return Boolean(_.get(errors, path));
};
