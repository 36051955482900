import { languages as languageCodes } from "environment";

export const languages: { code: string, label: string }[] = languageCodes.map(c => ({
    code: c.toLowerCase(),
    label: c.toUpperCase()
}));

export const languageCodeToObject = (languageCodes: string[]): { code: string, label: string }[] => {
    return languageCodes.map(c => ({
        code: c.toLowerCase(),
        label: c.toUpperCase()
    }));
};
