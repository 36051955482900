import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { getEmptyChallengeVoucherConfig } from "@utils/emptyItems/emptyChallenge";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Checkbox, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../../context/enabledLangContext/context";
import { useCurrentLanguage } from "../../../../../../hooks/useCurrentLanguage";

export interface ChallengeVoucherSettingsProps {
    form: FormInstance;
    disabled?: boolean;
    languageErrors?: LanguageErrors;
    onBlur(): void;
}

export const ChallengeVoucherSettings: React.FC<ChallengeVoucherSettingsProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const { form, disabled, onBlur } = props;
    const { enabledLanguages } = useEnabledLang();

    const challengeVoucherConfiguration = form.getFieldValue("challengeVoucherConfiguration");

    const sharedProps = {
        disabled,
        onBlur
    };

    let challengeVoucherConfigurationTitle = challengeVoucherConfiguration && challengeVoucherConfiguration.title;
    let challengeVoucherConfigurationDescription = challengeVoucherConfiguration && challengeVoucherConfiguration.description;
    if (challengeVoucherConfiguration && challengeVoucherConfiguration.required
        && !challengeVoucherConfigurationTitle && !challengeVoucherConfigurationDescription) {
        const emptyConfig = getEmptyChallengeVoucherConfig();
        challengeVoucherConfigurationTitle = emptyConfig.title;
        challengeVoucherConfigurationDescription = emptyConfig.description;
    }

    const titleAndDescription = React.useMemo(
        () => enabledLanguages.map(lang => (
            <React.Fragment>
                {challengeVoucherConfigurationTitle && (
                    <Field
                        hidden={selectedLanguage !== lang.code}
                        label={<FormattedMessage id="challengeVoucherConfiguration.title" />}
                        name={["challengeVoucherConfiguration", "title", lang.code, "text"]}
                        {...sharedProps}
                    />
                )}
                {challengeVoucherConfigurationDescription && (
                    <Field
                        hidden={selectedLanguage !== lang.code}
                        label={<FormattedMessage id="challengeVoucherConfiguration.description" />}
                        name={["challengeVoucherConfiguration", "description", lang.code, "text"]}
                        {...sharedProps}
                    />
                )}
            </React.Fragment>
        )), [enabledLanguages, selectedLanguage]
    );

    return (
        <React.Fragment>
            <ContentHeader
                title={<FormattedMessage id="edit.challengeVoucherSettings" />}
            />
            <Form.Item
                name={["challengeVoucherConfiguration", "required"]}
                valuePropName="checked"
                {...sharedProps}
            >
                <Checkbox
                    onChange={() => {
                        sharedProps.onBlur();
                    }}
                >
                    <FormattedMessage id="challengeVoucherConfiguration.required" />
                </Checkbox>
            </Form.Item>
            <Form.Item dependencies={[["challengeVoucherConfiguration", "required"], ["challengeVoucherConfiguration", "url"]]}>
                {() => {
                    const isRequired = form.getFieldValue(["challengeVoucherConfiguration", "required"]);
                    const hasUrl = form.getFieldValue(["challengeVoucherConfiguration", "url"]);

                    const fields: JSX.Element[] = [];

                    if (isRequired) {
                        fields.push(
                            <Field
                                label={<FormattedMessage id="challengeVoucherConfiguration.url" />}
                                name={["challengeVoucherConfiguration", "url"]}
                                {...sharedProps}
                            />
                        );

                        if (hasUrl) {
                            fields.push(
                                <React.Fragment>
                                    <TranslationTabs
                                        activeLanguage={selectedLanguage}
                                        handleChangeSelectedLanguage={changeSelectedLanguage}
                                        languages={enabledLanguages}
                                    />
                                    {titleAndDescription}
                                </React.Fragment>
                            );
                        }
                    }

                    return fields;
                }}
            </Form.Item>
        </React.Fragment>
    );
};
