/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { WarningOutlined } from "@ant-design/icons";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { AdminTooltip } from "@components/tooltip/index";
import { leaderboardVisualisationFactory } from "@pages/addChallengesPage/factories";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeVisualisationsFormValuesUnion } from "@pages/addChallengesPage/tabs/visualisations/challengeVisualisationsCard/challengeVisualisationsCard";
import { ChallengeVisualisationRow } from "@pages/addChallengesPage/tabs/visualisations/default/visualisationsDefaultCardStyle";
import { createSetFieldsValue } from "@utils/form/createSetFieldsValue";
import { setArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { LanguageObject } from "@utils/toLanguageObject";
import { Alert, Button, Checkbox, Col, Form, Select, Tooltip } from "antd";
import { RuleObject } from "antd/lib/form";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { StoreValue } from "antd/lib/form/interface";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export type UpdateVisualisationType = (visualisationIndex: number, newType: string) => void;

export interface VisualisationTypeFormProps {
    form?: FormInstance;
    visualisation?: ChallengeVisualisationsFormValuesUnion;
    existingVisualisations?: ChallengeVisualisationsFormValuesUnion[];
    conditions?: ChallengeConditionFormValuesUnion[];
    prefix: (string | number)[];
    index?: number;
    isTeamChallenge: boolean;
    noHiddenId?: boolean;
    add?(item): void;
    back?(): void;
    redirect?(): void;
    editVisualisation?(index: number): void;
    onChange(): void;
}

const requiredRule = {
    required: true,
    message: <FormattedMessage id="form.isrequired" />
};

export interface ChallengeVisualisationBaseFormValues {
    id: string;
    type?: string;
    place?: string;
    dataType?: string;
    clickable?: boolean;
    title: LanguageObject;
    description: LanguageObject;
    conditionId?: string;
}

interface Warning {
    field: string;
    warning: string | JSX.Element;
}

export const VisualisationTypeForm: React.FC<VisualisationTypeFormProps> = (props) => {
    const { noHiddenId, prefix, onChange, form: existingForm, existingVisualisations, add, back, redirect, isTeamChallenge, visualisation, index, editVisualisation } = props;

    interface VisualisationStructure {
        type: string;
        allowAllConditions: boolean;
        conditions: {
            [key: string]: string[];
        };
    }

    const VISUALISATION_STRUCTURE: VisualisationStructure[] = [
        {
            type: "timeline",
            allowAllConditions: true,
            conditions: {
                global: ["team", "teamUser", "user"]
            }
        },
        {
            type: "leaderboard",
            allowAllConditions: false,
            conditions: {
                global: ["team", "user", "teamUser"],
                conditions: ["teamUser", "team", "user"]
            }
        },
        {
            type: "grid",
            allowAllConditions: false,
            conditions: {
                global: ["team", "teamUser", "user"]
            }
        }
    ];

    // eslint-disable-next-line complexity
    const [currentType, setCurrentType] = React.useState<string | undefined>(visualisation && visualisation.type);
    const [currentCondition, setCurrentCondition] = React.useState<string | undefined>(visualisation && visualisation.conditionId);
    const [warning, setWarning] = React.useState<Warning | undefined>();

    let formToUse = existingForm;

    if (!existingForm) {
        const [form] = useForm();
        formToUse = form;
    }

    const { allowAllConditions, conditions } = VISUALISATION_STRUCTURE.find(item => item.type === currentType) || {};
    const dataTypes = allowAllConditions
        ? conditions && conditions.global
        : conditions && currentCondition && conditions[currentCondition];

    const sharedProps = {
        onBlur: onChange
    };

    const filteredDataTypes = () => {
        if (isTeamChallenge) {
            return (dataTypes || []).filter(dt => dt !== "user");
        }

        return (dataTypes || []).filter(dt => !dt.includes("team"));
    };

    const handleChangeDataType = () => {
        if (!prefix) {
            return null;
        }
        const typeVisualisation = existingForm?.getFieldValue([...prefix, "type"]);
        if (typeVisualisation === "grid") {
            const columns = existingForm?.getFieldValue([...prefix, "columns"]);
            if (columns) {
                existingForm?.setFields([{
                    name: [...prefix, "columns"],
                    value: columns.map(item => ({
                        ...item,
                        conditionId: undefined
                    }))
                }]);
            }
        }
    };

    const onSubmit = async () => {
        if (!formToUse) {
            return;
        }

        try {
            if (add) {
                await formToUse.validateFields();

                if (warning && editVisualisation) {
                    const { dataType } = formToUse.getFieldValue([...prefix]);
                    const existingIndex = (existingVisualisations || []).findIndex(vis => vis.dataType === dataType && vis.type === currentType && vis.conditionId === currentCondition);
                    editVisualisation(existingIndex);
                    setWarning(undefined);
                    formToUse.resetFields();
                    return;
                }

                switch (currentType) {
                    case "leaderboard":
                        add(leaderboardVisualisationFactory(formToUse.getFieldValue([...prefix])));
                        if (redirect) {
                            formToUse.resetFields();
                            redirect();
                        }
                        break;
                    default:
                        add(formToUse.getFieldValue([...prefix]));
                        if (redirect) {
                            formToUse.resetFields();
                            redirect();
                        }
                        break;
                }
            }

            if (back) {
                back();
            }
        } catch (err) {
            console.error("Errors", err);
        }
    };

    const onChangeType = (value: string) => {
        // TODO: check
        setCurrentType(value);
        if (formToUse) {
            formToUse.resetFields([[...prefix, "conditionId"], [...prefix, "dataType"]]);
        }

        if (formToUse && value === "grid") {
            formToUse.setFieldsValue(createSetFieldsValue([...prefix, "conditionId"], ""));
        }
    };

    const customValidator = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void) => {
        if (formToUse && currentType !== "grid") {
            const values = formToUse.getFieldValue([...prefix]);
            const existingIndex = (existingVisualisations || []).findIndex(vis => vis.dataType === values?.dataType && vis.type === currentType && vis.conditionId === currentCondition);

            if (existingIndex > -1) {
                setWarning({
                    field: "dataType",
                    warning: <FormattedMessage id="visualisation.mergingWarning" />
                });
                callback();
            } else {
                setWarning(undefined);
            }
        }

        callback();
    };

    const onChangeConditionId = (value) => {
        if (currentType === "leaderboard" && formToUse && existingForm) {
            setArrayFieldValue(formToUse, [
                ["visualisations"],
                ["isGlobal"]
            ], (index || 0), value === "global");
        }

        setCurrentCondition(value);
        onChange();
    };

    const formFields = (
        <React.Fragment>
            <ContentHeader
                rightFields={(back || add)
                    ? [
                        <Button
                            type="primary"
                            onClick={onSubmit}
                        >
                            <FormattedMessage id={back ? "back" : "add"} />
                        </Button>
                    ]
                    : false}
                title={<FormattedMessage id="general" />}
            />
            <ChallengeVisualisationRow gutter={16}>
                {!noHiddenId && (
                    <Field
                        hidden
                        name={[...prefix, "id"]}
                        {...sharedProps}
                    />
                )}
                <Col span={5}>
                    <SelectField
                        id="place"
                        label={<FormattedMessage id="place" />}
                        name={[...prefix, "place"]}
                        rules={[requiredRule]}
                        {...sharedProps}
                    >
                        <Select.Option value="detail"><FormattedMessage id="detail" /></Select.Option>
                        <Select.Option value="tour"><FormattedMessage id="tour" /></Select.Option>
                    </SelectField>
                </Col>
                <Col span={5}>
                    <SelectField
                        disabled={Boolean(existingForm)}
                        id="type"
                        label={<FormattedMessage id="type" />}
                        name={[...prefix, "type"]}
                        rules={[requiredRule,
                            {
                                validator: customValidator
                            }
                        ]}
                        onChange={onChangeType}
                        {...sharedProps}
                    >
                        {VISUALISATION_STRUCTURE.map(item => <Select.Option value={item.type}><FormattedMessage id={item.type} /></Select.Option>)}
                    </SelectField>
                </Col>
                <Col span={5}>
                    <SelectField
                        id="condition"
                        label={<FormattedMessage id="condition" />}
                        name={[...prefix, "conditionId"]}
                        rules={[requiredRule, { validator: customValidator }]}
                        onChange={onChangeConditionId}
                        {...sharedProps}
                    >
                        {allowAllConditions
                            && (props.conditions || []).map(condition => (
                                <Select.Option
                                    key={condition.id}
                                    value={condition.id}
                                >
                                    <ConditionTitle condition={condition} />
                                </Select.Option>
                            ))}
                        {((conditions && Object.keys(conditions)) || []).map(condition => <Select.Option value={condition}><FormattedMessage id={condition} /></Select.Option>)}
                    </SelectField>
                </Col>
                <Col span={5}>
                    <SelectField
                        id="dataType"
                        label={<FormattedMessage id="dataType" />}
                        name={[...prefix, "dataType"]}
                        rules={[requiredRule,
                            {
                                validator: customValidator
                            }
                        ]}
                        suffixIcon={(warning && warning.field === "dataType" && (
                            <Tooltip title={warning.warning}>
                                <WarningOutlined style={{ color: "rgba(255, 0, 0, 0.5" }} />
                            </Tooltip>
                        )) || null}
                        onChange={handleChangeDataType}
                        {...sharedProps}
                    >
                        {(filteredDataTypes() || []).map(item => <Select.Option value={item}><FormattedMessage id={item} /></Select.Option>)}
                    </SelectField>
                    <Form.Item dependencies={[[...prefix, "place"], [...prefix, "type"], [...prefix, "conditionId"], [...prefix, "dataType"]]}>
                        {(currentFormInstance) => {
                            const teamChallenge = isTeamChallenge;
                            const place = currentFormInstance.getFieldValue([...prefix, "place"]);
                            const type = currentFormInstance.getFieldValue([...prefix, "type"]);
                            const conditionId = currentFormInstance.getFieldValue([...prefix, "conditionId"]);
                            const dataType = currentFormInstance.getFieldValue([...prefix, "dataType"]);
                            if (teamChallenge && place === "detail" && type === "leaderboard" && conditionId === "global" && dataType === "teamUser") {
                                return (
                                    <Alert
                                        showIcon
                                        description={<FormattedMessage id="error.visualisation.dataType.description" />}
                                        message={<FormattedMessage id="error.visualisation.dataType.title" />}
                                        style={{ maxWidth: 350, width: 350 }}
                                        type="error"
                                    />
                                );
                            }
                            return null;
                        }}
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name={[...prefix, "clickable"]}
                        valuePropName="checked"
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox><FormattedMessage id="clickable" /></Checkbox>
                            <AdminTooltip
                                description={<FormattedMessage id="challenge.visualisations.timeline.clickable.tooltip" />}
                                type="info"
                            />
                        </div>
                    </Form.Item>
                </Col>
            </ChallengeVisualisationRow>
        </React.Fragment>
    );

    if (!existingForm) {
        return (
            <Form
                form={formToUse}
                name="newVisualisation"
            >
                {formFields}
            </Form>
        );
    }

    return formFields;
};
