import { Table, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, CheckCircleFilled, MinusCircleFilled, EditOutlined } from "@ant-design/icons";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "@components/table/tableStyle";
import { Link } from "react-router-dom";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { ExportGalleryContainer } from "@components/export/exportGalleryContainer";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";

export interface GalleryTableData extends TableData {
    title: string;
    type?: string | null;
    status: boolean;
    statusType: string;
}

export interface GalleryTableProps extends TableComponentProps {
    dataSource: GalleryTableData[];
    handlers: TableHandlers & {
        removeGallery(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<GalleryTableData>;
}

export const GalleryTable: React.FC<GalleryTableProps> = ({ handlers, ...tableProps }) => {
    const renderTitle = (text: string, id: string) => (
        <Link className="plainText" to={`/gallery/galleries/edit/${id}/general`}>
            {text}
        </Link>
    );

    const renderStatus = (status, statusType: string) => {
        if (status && statusType === "active") {
            return (
                <Tooltip title={statusType}>
                    <CheckCircleFilled className="green" />
                </Tooltip>
            );
        }

        return (
            <Tooltip title={statusType}>
                <MinusCircleFilled className={statusType} />
            </Tooltip>
        );
    };

    const renderHandlers = (row: GalleryTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <ExportGalleryContainer id={row.id} />
                <Link to={`/gallery/galleries/edit/${row.id}/general`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeGallery(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="expiresOn"
                title={<FormattedMessage id="status" />}
                dataIndex="status"
                width={95}
                render={(text, row: GalleryTableData) => renderStatus(text, row.statusType)}
                sorter
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                sorter
                render={(text, row: GalleryTableData) => renderTitle(text, row.id)}
            />
            <Column
                key="type"
                title={<FormattedMessage id="type" />}
                dataIndex="type"
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: GalleryTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
