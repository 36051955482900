export const ACTIVITY_TYPES = [
    {
        key: "walking",
        value: "Walk"
    },
    {
        key: "running",
        value: "Run"
    },
    {
        key: "cycling",
        value: "Cycle"
    },
    {
        key: "swimming",
        value: "Swim"
    },
    {
        key: "workout",
        value: "Workout"
    },
    {
        key: "daily",
        value: "Daily activities"
    },
    {
        key: "dailyWithoutWorkouts",
        value: "Daily Without Workouts"
    },
    {
        key: "volunteering",
        value: "Volunteering"
    }
];

export const UNIT_TYPES = [
    {
        group: "Distance",
        values: ["km", "m"]
    },
    {
        group: "Elevation",
        values: ["elevation"]
    },
    {
        group: "Duration",
        values: ["hours", "minutes"]
    },
    {
        group: "Calories",
        values: ["kcal", "cal"]
    },
    {
        group: "Steps",
        values: ["steps"]
    },
    {
        group: "creditsEarned",
        values: ["credits"]
    },
    {
        group: "workoutCount",
        values: ["workouts"]
    }
];

export const MERGED_UNIT_TYPES = UNIT_TYPES.reduce((acc, val) => [...acc, ...val.values], []);

export const ACTIVITY_TYPES_CHALLNGE = [
    {
        key: "other",
        value: "Other"
    },
    {
        key: "walking",
        value: "Walk"
    },
    {
        key: "running",
        value: "Run"
    },
    {
        key: "cycling",
        value: "Cycle"
    },
    {
        key: "swimming",
        value: "Swim"
    },
    {
        key: "volunteering",
        value: "Volunteering"
    }
];
export const reminderLabels = ["P1D", "P2D", "P7D", "P14D"];

export const checkboxTransformRule = ({
    transform: value => (value || undefined),
    type: "boolean"
});
