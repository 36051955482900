import { ArticleTable } from "@components/articles/articleTable";
import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { ArticleFragment } from "@graphql2/types";
import { ArticlesPageProps } from "@pages/articlesPage/articlesPageContainer";
import { AlertWrapperStyle, ArticlesPageStyle, BulkDeleteButtonStyle } from "@pages/articlesPage/articlesPageStyle";
import { translateArticles } from "@utils/mappers/articleMapper";
import { readFile } from "@utils/readFile";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Alert, Button, Card, Popconfirm, Spin, Upload, message } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface ArticleDeleteItem {
    id: string;
    title: string;
}

export const ArticlesPage: React.FC<ArticlesPageProps> = (props) => {
    const [itemsToDelete, setItemsToDelete] = useState<ArticleDeleteItem[]>();
    const [bulkDeleteinProgress, setBulkDeleteinProgress] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string>();
    const intl = useIntl();
    const { removeArticle, articles, paging, count } = props;

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const translatedArticles = React.useMemo(
        () => translateArticles(articles?.articles || []),
        [articles]
    );

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const handleBulkDeleteArticles = () => {
        if (!itemsToDelete || !itemsToDelete.length) {
            return null;
        }

        setBulkDeleteinProgress(true);

        itemsToDelete.forEach(async ({ id }) => {
            await removeArticle({ variables: { id } });
        });

        message.success("Articles removed");

        setBulkDeleteinProgress(false);
        setItemsToDelete([]);
    };

    const removeArticleHandler = async (id: string) => {
        await removeArticle({ variables: { id } });
    };

    const handleUpload = async (info: { file: File; }) => {
        try {
            const data = await readFile<ArticleFragment>(info.file);
            props.history.push("/articles/add/general", { import: data });
        } catch (error) {
            setUploadError("upload.tryAgain");
        }
    };

    const renderAlertContent = (toDelete: number) => {
        return (
            <Flex justifyContent="space-between">
                <Flex direction="column">
                    <strong>
                        <FormattedMessage
                            id="article.selection"
                            values={{ toDelete }}
                        />
                    </strong>
                </Flex>
                <Flex justifyContent="flex-end">
                    <Popconfirm
                        cancelText="No"
                        okText="Yes"
                        title={<FormattedMessage id="deleteConfirmArticles" />}
                        onConfirm={handleBulkDeleteArticles}
                    >
                        <BulkDeleteButtonStyle>
                            <FormattedMessage id="deleteArticles" />
                        </BulkDeleteButtonStyle>
                    </Popconfirm>
                </Flex>
            </Flex>
        );
    };

    return (
        <ArticlesPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <h1><FormattedMessage id="webshop.productOverview" /></h1>
                    <div>
                        <Upload
                            accept="application/json"
                            customRequest={handleUpload}
                        >
                            <Button
                                className="headerButton"
                                type="primary"
                            >
                                <FormattedMessage id="webshop.import" />
                            </Button>
                        </Upload>
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => {
                                props.history.push("/articles/add/general");
                            }}
                        >
                            <FormattedMessage id="webshop.addArticle" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>

            <Card>
                {uploadError && (
                    <Alert
                        message={<FormattedMessage id={uploadError} />}
                        type="error"
                    />
                )}
                <DynamicFilterBar
                    fields={[
                        {
                            type: "input",
                            search: true,
                            name: "search",
                            placeholder: intl.formatMessage({ id: "filter.textSearch" })
                        },
                        {
                            type: "input",
                            number: {},
                            name: "minPrice",
                            placeholder: intl.formatMessage({ id: "articles.filter.minPrice" })
                        },
                        {
                            type: "seperator",
                            name: "separator"
                        },
                        {
                            type: "input",
                            number: {},
                            name: "maxPrice",
                            placeholder: intl.formatMessage({ id: "articles.filter.maxPrice" })
                        }
                    ]}
                />
                {itemsToDelete && itemsToDelete.length
                    ? (
                        <AlertWrapperStyle>
                            <Spin spinning={bulkDeleteinProgress}>
                                <Alert
                                    showIcon
                                    message={renderAlertContent(itemsToDelete.length)}
                                    type="info"
                                />
                            </Spin>
                        </AlertWrapperStyle>
                    )
                    : null}
                <TableContainer
                    dataSource={translatedArticles}
                    handlers={{
                        removeArticle: removeArticleHandler,
                        handleSelectedArticlesOnChange: setItemsToDelete
                    }}
                    loading={(articles && articles.loading) || (count && count.loading)}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count && count.articleCount
                    }}
                    TableComponent={ArticleTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </ArticlesPageStyle>
    );
};
