import { autobind } from "core-decorators";
import * as React from "react";
import { Button, Card, Modal, Upload } from "antd";

import { FormattedMessage } from "react-intl";
import { VoucherImportPageStyle } from "@pages/voucherImportPage/voucherImportPageStyle";
import { readFile } from "@utils/readFile";
import { AddVoucherMutationVariables } from "@graphql2/types";
import { VoucherTable, VoucherTableData } from "@components/voucher/voucherTable";
import { TableContainer } from "@components/table/tableContainer";
import { VoucherImportProps } from "@pages/voucherImportPage/voucherImportPageContainer";
import { HeaderContent } from "@components/headerContent/headerContent";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { csvToVouchers } from "@pages/voucherImportPage/csvToVouchers";

interface VoucherImportState {
    showModal: boolean;
    vouchers?: AddVoucherMutationVariables[];
    vouchersTableData?: VoucherTableData[];
    processing: boolean;
    page: number;
}

@autobind
export class VoucherImport extends React.Component<VoucherImportProps, VoucherImportState> {
    public state: VoucherImportState = {
        showModal: false,
        page: 1,
        processing: false
    };

    public render() {
        const { vouchersTableData, vouchers, processing } = this.state;

        return (
            <VoucherImportPageStyle>
                <Button className="headerButton" type="primary" ghost onClick={this.showImport}>
                    <FormattedMessage id="voucherImport.import" />
                </Button>
                <Modal
                    visible={this.state.showModal}
                    width="1100px"
                    onCancel={this.hideImport}
                    onOk={this.upload}
                    okButtonProps={{ disabled: !vouchers || !vouchers.length || processing }}
                    okText={this.props.intl.formatMessage({ id: "voucherImport.import" })}
                >
                    <HeaderContent>
                        <h1>
                            <FormattedMessage id="vouchers" />
                        </h1>
                        <HeaderActions>
                            <a href="/assets/csv/vouchers.csv" download>
                                <Button className="headerButton" type="primary" ghost>
                                    <FormattedMessage id="voucherImport.example" />
                                </Button>
                            </a>
                            <Upload accept="application/csv" customRequest={this.handleUpload}>
                                <Button loading={processing} className="headerButton" type="primary">
                                    <FormattedMessage id="voucherImport.loadFile" />
                                </Button>
                            </Upload>
                        </HeaderActions>
                    </HeaderContent>
                    <Card>
                        <TableContainer
                            pagination={{ current: this.state.page, onChange: p => this.setState({ page: p }) }}
                            loading={processing}
                            TableComponent={VoucherTable}
                            dataSource={vouchersTableData || []}
                            handlers={{
                                denyEdit: this.denyEdit,
                                removeVoucher: this.removeVoucher
                            }}
                        />
                    </Card>
                </Modal>
            </VoucherImportPageStyle>
        );
    }

    public denyEdit(): boolean {
        return true;
    }

    private removeVoucher(_, index) {
        const { vouchersTableData, vouchers } = this.state;
        if (vouchersTableData) {
            vouchersTableData.splice(index, 1);
        }
        if (vouchers) {
            vouchers.splice(index, 1);
        }

        this.setState({ vouchersTableData, vouchers });
    }

    private showImport() {
        this.setState({ showModal: true });
    }

    private hideImport() {
        this.setState({ showModal: false });
    }

    private async handleUpload({ file }: { file: File }) {
        const data = await readFile(file);

        this.setState(csvToVouchers(data));
    }

    private async upload() {
        const { vouchersTableData, vouchers } = this.state;
        if (vouchers && vouchersTableData) {
            const toKeepInTable = {};
            this.setState({ processing: true });

            const error = (i: number, e) => {
                toKeepInTable[i] = true;
                vouchersTableData[i].error = e.message;
            };
            const cleanData = () => {
                const voucherErrorIndexes = Object.keys(toKeepInTable);
                const vouchersTableDataWithError = voucherErrorIndexes.map(v => vouchersTableData[v]);
                const vouchersWithError = voucherErrorIndexes.map(v => vouchers[v]);
                this.setState({ processing: false, vouchersTableData: vouchersTableDataWithError, vouchers: vouchersWithError });
            };
            const batchSize = 9;
            for (let i = 0; i < vouchers.length; i += batchSize) {
                await Promise.all(
                    vouchers
                        .slice(i, i + batchSize)
                        .map((v, vi) => this.props.addVoucher({ variables: v }).catch(e => error(vi + i, e)))
                );
            }
            cleanData();
        }
    }
}
