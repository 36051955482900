import * as React from "react";
import { Form, Input } from "antd";
import { FormItemProps, FormInstance, RuleObject } from "antd/lib/form";
import CKEditor from "ckeditor4-react";

import { NamePath } from "antd/lib/form/interface";
import { FieldInfo } from "./fieldStyle";
import { DisableOverlay, EditorWrapper } from "./ckeFieldStyle";

export interface CkeFieldProps extends FormItemProps {
    form?: FormInstance;
    formValues?: any;
    label: JSX.Element;
    info?: JSX.Element;
    disabled?: boolean;
    id?: string;
    value?: string;
    hidden?: boolean;
    hasError?: any;
    onChange?(value: string): void;
    onChangeUnsaved?(): void;
    onBlur?(): void;
}

export interface CkeFieldState {
    value: string | null;
}

export const CkeField: React.FC<CkeFieldProps> = (props) => {
    const { onBlur, hasError, ...inputProps } = props;

    const handleValueChange = (event): void => {
        const eventValue = event.editor.getData();
        const { onChangeUnsaved } = props;

        if (inputProps.form && inputProps.name) {
            inputProps.form.setFields([
                {
                    name: inputProps.name,
                    value: eventValue
                }
            ]);
        }

        if (onChangeUnsaved) {
            onChangeUnsaved();
        }
    };

    const currentValue = inputProps.form && inputProps.form.getFieldValue(inputProps.name as NamePath);
    const isRequired = inputProps.required || (inputProps.rules || []).find((rule: RuleObject) => rule.required === true);

    return (
        <React.Fragment>
            {!inputProps.hidden && <label className={isRequired ? "isRequired" : ""} htmlFor={inputProps.id}>{inputProps.label}</label>}
            {!inputProps.hidden && inputProps.info && <FieldInfo>{inputProps.info}</FieldInfo>}
            {/* Same approach as Modals, hide the div around to prevent unmount */}
            <EditorWrapper className={hasError ? "manual-error-styling" : ""} hidden={inputProps.hidden}>
                {inputProps.disabled && <DisableOverlay />}
                <CKEditor
                    config={{
                        disabled: inputProps.disabled,
                        entities: false,
                        basicEntities: false,
                        forcePasteAsPlainText: true,
                        pasteFromWordRemoveFontStyles: true,
                        pasteFromWordRemoveStyles: true,
                        allowedContent: true,
                        extraAllowedContent: "p(mso*,Normal)",
                        pasteFilter: null,
                        removeButtons: "Subscript,Superscript",
                        extraPlugins: "justify,showblocks"
                    }}
                    data={inputProps.initialValue || currentValue}
                    onChange={handleValueChange}
                    onBlur={onBlur}
                />
            </EditorWrapper>
            <div className="manual-error-text">
                {!inputProps.hidden && hasError?.errors}
            </div>
            <Form.Item
                hidden
                name={inputProps.name}
                rules={!inputProps.disabled ? inputProps.rules : undefined}
            >
                <Input />
            </Form.Item>
        </React.Fragment>
    );
};
