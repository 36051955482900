import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { FormElementProps } from "@components/formContentFields/formElement";
import { FormContentFieldBody, FormContentFieldHead, FormContentFieldSet } from "@components/formContentFields/formElementStyle";
import { OrderFieldButtons } from "@components/formContentFields/orderFieldButtons";
import { createOnChange } from "@utils/createOnChange";
import { Checkbox, Form } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import { requiredrule } from "@components/form/requiredRule";

export class FormFieldBase extends React.Component<FormElementProps> {
    public render() {
        const { index, condition, required, prefix, disableRequiredField } = this.props;

        return (
            <FormContentFieldSet>
                <FormContentFieldHead>
                    <OrderFieldButtons
                        conditionIndex={condition ? condition.sequence : 0}
                        index={index}
                        type={this.props.type}
                        moveFormElement={this.props.moveFormElement}
                    />
                    <Form.Item
                        hidden={disableRequiredField}
                        noStyle
                        name={[index, "required"]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            {...this.sharedProps}
                        >
                            <FormattedMessage id="form.isrequired" />
                        </Checkbox>
                    </Form.Item>
                </FormContentFieldHead>
                <FormContentFieldBody>
                    {this.renderBody()}
                </FormContentFieldBody>
            </FormContentFieldSet>
        );
    }

    protected get sharedProps() {
        const { onBlur } = this.props;

        return {
            rules: [requiredrule],
            onBlur
        };
    }

    public renderBody() {
        const { prefix, deleteFormElement } = this.props;

        return (
            <FormItemFlex>
                <Field
                    name={`${prefix}.title`}
                    initialValue={this.props.property}
                    {...this.sharedProps}
                    required
                    label={<FormattedMessage id="valueKey" />}
                />
                <DeleteFormField
                    as={DeleteOutlined}
                    onClick={deleteFormElement}
                />
            </FormItemFlex>
        );
    }
}
