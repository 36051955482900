import { RolesTableData } from "@components/roles/rolesTable";
import { GetApplicationsQuery } from "@graphql2/types";

export const applicationsToRoles = (applications: GetApplicationsQuery["applications"] = []): RolesTableData[] => {
    const roles: RolesTableData[] = [];
    applications.forEach(a => {
        if (a.roles) {
            a.roles.forEach(r => {
                roles.push({
                    // AntD table has a similar issue as Apollo Client, if the ID is not unique, it will be buggy
                    id: a.id + r.id,
                    roleId: r.id,
                    title: r.name,
                    applicationId: a.id,
                    application: a.name,
                    description: r.description
                });
            });
        }
    });

    return roles;
};
