import * as H from "history";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

export const LinkWrap = <S extends H.LocationState>(props: React.PropsWithoutRef<LinkProps<S>> & React.RefAttributes<HTMLAnchorElement>) => {
    return (
        <Link {...props}>
            {props.children}
        </Link>
    );
};
