import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

export class FormFieldTextArea extends FormFieldBase {
    public renderBody() {

        const { prefix, index, deleteFormElement } = this.props;

        return (
            <FormItemFlex>
                <Field
                    name={[index, "property"]}
                    initialValue={this.props.property}
                    {...this.sharedProps}
                    label={<FormattedMessage id="textArea" />}
                />
                <DeleteFormField
                    as={DeleteOutlined}
                    onClick={deleteFormElement}
                />
            </FormItemFlex>
        );
    }
}
