import { WidgetConfigurationHeaderTitle, WidgetConfigurationRemover, WidgetConfiguratorHeaderStyle, WidgetSequenceConfigurator, WidgetHeaderIconsColStyle, WidgetHeaderOptionsStyle } from "@components/dashboard/widgetConfiguratorHeader/widgetConfiguratorHeaderStyle";
import { Flex } from "@components/flex/flex";
import { Checkbox, Popconfirm, Form, Tag, Row, Col } from "antd";
import { EyeOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, EditOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { InternalNamePath } from "antd/lib/form/interface";
import { NestedArrayData, setNestArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";

export type MoveWidthSettingsWidgetFromColumn = (props: any, widgetIndex: number, columnTargetIndex: number, id?: string) => void;

export interface WidgetConfiguratorHeaderProps {
    form: FormInstance;
    widgetIndex: number;
    columnIndex: number;
    widget: WidgetsFormValuesUnion;
    prefix: InternalNamePath;
    hasError: boolean;
    onMove(hasMoved: boolean): void;
    move(from: number, to: number): void;
    remove(index: number): void;
    deleteWidget(widgetId: string): void;
    setEditWidgetId(id: string | null): void;
    onStatusChange(): void;
}

export const WidgetConfiguratorHeader: React.FC<WidgetConfiguratorHeaderProps> = (props) => {
    const { widget, prefix, widgetIndex, columnIndex, move, remove, deleteWidget, form, setEditWidgetId, hasError, onStatusChange } = props;

    // prefix should be ["devices", view, "columns", columnField.name, "widgets"]
    const [, view] = prefix;

    const movement = (align: "vertical" | "horizontal") => {
        onStatusChange();

        if (align === "vertical") {
            return (from: number, to: number) => move(from, to);
        }

        return (from: number, to: number) => {
            const columnWidget = form.getFieldValue([...prefix, widgetIndex]);
            const targetColumnName = ["devices", view, "columns", to, "widgets"];
            const targetColumn = form.getFieldValue(targetColumnName);
            if (!targetColumn) {
                // column does not exist, abort.
                return;
            }
            targetColumn.push(columnWidget);

            const target: NestedArrayData[] = [
                {
                    fields: [["devices", view as string, "columns"], ["widgets"]],
                    index: to
                }
            ];

            setNestArrayFieldValue(form, target, targetColumn);
            remove(widgetIndex);
        };
    };

    return (
        <WidgetConfiguratorHeaderStyle>
            <WidgetHeaderOptionsStyle>
                <Col>
                    <Row align="middle">
                        <Col>
                            <WidgetSequenceConfigurator>
                                <CaretUpOutlined onClick={() => movement("vertical")(widgetIndex, widgetIndex - 1)} />
                                <Flex>
                                    <CaretLeftOutlined onClick={() => movement("horizontal")(columnIndex, columnIndex - 1)} />
                                    <CaretRightOutlined onClick={() => movement("horizontal")(columnIndex, columnIndex + 1)} />
                                </Flex>
                                <CaretDownOutlined onClick={() => movement("vertical")(widgetIndex, widgetIndex + 1)} />
                            </WidgetSequenceConfigurator>
                        </Col>
                        <Col>
                            <WidgetConfigurationHeaderTitle>
                                <FormattedMessage id={`widgetPickerItem.${widget.type}`} />
                            </WidgetConfigurationHeaderTitle>
                        </Col>
                    </Row>
                </Col>
                <WidgetHeaderIconsColStyle>
                    <Row gutter={28}>
                        <Col>
                            {hasError && (
                                <Tag icon={<CloseCircleOutlined />} color="error">
                                    <FormattedMessage id="error" />
                                </Tag>
                            )}
                            <WidgetConfigurationRemover>
                                <Form.Item
                                    valuePropName="checked"
                                    name={[widgetIndex, "visible"]}
                                >
                                    <Checkbox onChange={onStatusChange}>
                                        <EyeOutlined /><FormattedMessage id="hide" />
                                    </Checkbox>
                                </Form.Item>
                            </WidgetConfigurationRemover>
                        </Col>
                        <Col>
                            <WidgetConfigurationRemover onClick={() => setEditWidgetId(widget.id)}>
                                <EditOutlined />
                                <FormattedMessage id="edit" />
                            </WidgetConfigurationRemover>
                        </Col>
                        <Col>
                            <Popconfirm
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={() => {
                                    remove(widgetIndex);
                                    deleteWidget(widget.id);
                                    onStatusChange();
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <WidgetConfigurationRemover>
                                    <DeleteOutlined />
                                    <FormattedMessage id="dashboardWidgetHeader.remove" />
                                </WidgetConfigurationRemover>
                            </Popconfirm>
                        </Col>
                    </Row>
                </WidgetHeaderIconsColStyle>
            </WidgetHeaderOptionsStyle>
        </WidgetConfiguratorHeaderStyle>
    );
};
