import * as React from "react";
import { compose } from "redux";
import { withGetGalleryTags, GetGalleryTagsProps } from "@graphql2/types";
import { ExportProps, exportFactory } from "./export";
import { withLocalState, ExportContainerProps } from "./exportContainer";

const withGalleryTag = withGetGalleryTags({
    skip(props: ExportContainerProps) {
        return !props.shouldExport;
    }
});

export const ExportGalleryTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withGalleryTag
)(exportFactory({ targetProp: ["data", "galleryTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportGalleryTagContainerProps = ExportContainerProps & MaybeElements<GetGalleryTagsProps>;
