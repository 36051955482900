import { PlusOutlined } from "@ant-design/icons";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { zeroValidation } from "@utils/validators/zeroValidation";
import { Button, Form, Select } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AddButton, DashboardInfoStyle, PlaceModal } from "./dashboardInfoStyle";

export interface DashboardInfoProps {
    onStatusChange(): void;
    places: string[];
    form: FormInstance
}

export const DashboardInfo:React.FC<DashboardInfoProps> = (props) => {
    const { onStatusChange, places, form } = props;

    const [placeAutoComplete, setPlaceAutoComplete] = React.useState(false);

    const [placeForm] = useForm();

    return (
        <DashboardInfoStyle>
            <Flex>
                <MarginRightDiv>
                    <DatePickerField
                        name="publishOn"
                        id="publishOn"
                        onChange={onStatusChange}
                        rules={[requiredrule]}
                        label={<FormattedMessage id="publishOn" />}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <DatePickerField
                        name="expiresOn"
                        id="expiresOn"
                        onChange={onStatusChange}
                        rules={[requiredrule]}
                        label={<FormattedMessage id="expiresOn" />}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <Field
                        name="sequence"
                        rules={[
                            requiredrule,
                            { validator: zeroValidation }
                        ]}
                        id="sequence"
                        numberType
                        min={1}
                        onBlur={onStatusChange}
                        label={<FormattedMessage id="sequence" />}
                    />
                </MarginRightDiv>
                <PlaceModal
                    visible={placeAutoComplete}
                    onCancel={() => setPlaceAutoComplete(false)}
                    title={<FormattedMessage id="dashboardInfo.add.place" />}
                >
                    <Form
                        onFinish={
                            () => {
                                form.setFields([{
                                    name: ["place"],
                                    value: placeForm.getFieldValue("place")
                                }]);
                                placeForm.resetFields();
                                setPlaceAutoComplete(false);
                            }
                        }
                        form={placeForm}
                    >
                        <Field
                            name="place"
                            rules={[
                                requiredrule
                            ]}
                        />
                        <Flex justifyContent="right">
                            <Button
                                key="add"
                                type="primary"
                                htmlType="submit"
                            >
                                <FormattedMessage id="add" />
                            </Button>
                        </Flex>
                    </Form>
                </PlaceModal>
                <MarginRightDiv style={{ width: "200px" }}>
                    <SelectField
                        name="place"
                        rules={[{
                            ...requiredrule,
                            message: <FormattedMessage id="dashboard.fillDashboard" />
                        }]}
                        id="place"
                        onChange={onStatusChange}
                        label={(
                            <React.Fragment>
                                <FormattedMessage id="place" />
                                <AddButton
                                    size="small"
                                    type="link"
                                    onClick={() => setPlaceAutoComplete(true)}
                                    icon={<PlusOutlined />}
                                />
                            </React.Fragment>
                        )}
                        placeholder="Dashboard Places"
                    >
                        {places.map((place) => (
                            <Select.Option key={place} value={place}>
                                {place}
                            </Select.Option>
                        ))}
                    </SelectField>
                </MarginRightDiv>
            </Flex>
        </DashboardInfoStyle>
    );
};
