import { Alert, Form, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Flex } from "@components/flex/flex";
import { SelectField } from "@components/field/selectField";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { ModeSelection } from "@components/dashboard/components/modeSelection/modeSelection";
import { WidgetConfigStyle } from "@components/dashboard/widgets/widgetStyle";
import { DefaultWidgetFields } from "@components/dashboard/widgets/defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "@components/dashboard/widgets/titleSubtitleDescriptionFields";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { DynamicPreviewWrapper } from "@components/dashboard/dynamicPreview/dynamicPreviewWrapper";
import { FixedContentFieldContainer } from "./fixedContentField/fixedContentFieldContainer";
import { ContentWidgetStyle } from "./contentWidgetStyle";

interface ContentWidgetProps extends WidgetProps {
}

const DISPLAY_TYPES = ["card", "list", "item"];

export const ContentWidget: React.FC<ContentWidgetProps> = (props) => {
    const { applications, form, widgetId } = props;
    const order = ["publishOn"];

    return (
        <ContentWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
                <SelectField
                    name={["widgets", widgetId, "displayType"]}
                    label={<FormattedMessage id="contentWidget.displayType" />}
                    id="displayType"
                >
                    {DISPLAY_TYPES.map(type => <Select.Option key={type} value={type}>{type}</Select.Option>)}
                </SelectField>
                <ModeSelection
                    widgetId={widgetId}
                    form={form}
                    moduleName="content"
                />
                <Form.Item
                    dependencies={[
                        ["widgets", widgetId, "mode"]
                    ]}
                >
                    {() => {
                        const mode = form.getFieldValue(["widgets", widgetId, "mode"]);
                        return (
                            mode === "dynamic"
                                ? (
                                    <DynamicPreviewWrapper
                                        titleId="contentWidget.dynamic"
                                        type={PreviewTypes.content}
                                        form={form}
                                        widgetId={widgetId}
                                        paginationOptions={{
                                            form,
                                            defaults: {
                                                fields: order
                                            },
                                            id: widgetId
                                        }}
                                    />
                                )
                                : (
                                    <FixedContentFieldContainer
                                        contentOptions={{
                                            form,
                                            id: widgetId
                                        }}
                                    />
                                )
                        );
                    }}
                </Form.Item>
                <Flex className="imageInherit" fullWidth margin="15px 0 0">
                    <Alert message={<FormattedMessage id="contentWidget.imageInherit" />} type="info" />
                </Flex>
            </WidgetConfigStyle>
        </ContentWidgetStyle>
    );
};
