import styled from "styled-components";
import { Flex } from "@components/flex/flex";

export const WhiteListPageStyle = styled.div``;

export const WhiteListModalWrapper = styled.div`
    .ant-modal-footer {
        display: none;
    }
`;

export const FlexButtonWrapper = styled(Flex)`
    button {
        width: 100%;
    }
`;
