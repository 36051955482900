import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { ArticleFilter, GetArticleCountProps, GetArticlesOverviewProps, RemoveArticleProps, withGetArticleCount, withGetArticlesOverview, withRemoveArticle } from "@graphql2/types";
import { ArticlesPage } from "@pages/articlesPage/articlesPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface ArticlesPageContainerProps {

}

interface ArticlesPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: ArticleFilter;
}

export const withArticles = withGetArticlesOverview({
    name: "articles",
    options({ paging, sort, filter }: ArticlesPageProps) {
        return {
            variables: {
                filter,
                paging,
                sort
            },
            fetchPolicy: "network-only"
        };
    }
});

export const withArticleCount = withGetArticleCount({
    name: "count",
    options({ filter }: ArticlesPageProps) {
        return {
            variables: {
                filter: {
                    search: filter?.search,
                    maxPrice: filter?.maxPrice,
                    minPrice: filter?.minPrice
                }
            }
        };
    }
});

export const withRemoveArticleMutation = withRemoveArticle({
    name: "removeArticle",
    options: {
        refetchQueries: ["getArticlesOverview", "getArticleCount"],
        awaitRefetchQueries: true
    }
});

export const ArticlesPageContainer = compose<React.ComponentClass<ArticlesPageContainerProps>>(
    withRouter,
    withParseDynamicFilterBar(undefined, { defaultSortSettings: { sortBy: "publishOn", sort: "DESC" } }),
    withArticles,
    withArticleCount,
    withRemoveArticleMutation
)(ArticlesPage);

export type ArticlesPageProps =
    ArticlesPageFilterBarProps &
    ArticlesPageContainerProps &
    GetArticlesOverviewProps<{}, "articles"> &
    GetArticleCountProps<{}, "count"> &
    RemoveArticleProps<{}, "removeArticle"> &
    RouteComponentProps;
