import { ExportEventTagContainer } from "@components/export/exportEventTagsContainer";
import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { withRemoveEventTag, withGetEventTags } from "../../graphql2/types";

export const withEventTags = withGetEventTags();
export const withRemoveEventTagMutation = withRemoveEventTag({
    name: "removeTag",
    options: {
        refetchQueries: ["getEventTags"],
        awaitRefetchQueries: true
    }
});

export const EventTagsPageContainer = createTagsPageContainer(
    withEventTags,
    withRemoveEventTagMutation,
    "/event/tags/add/general",
    "event",
    ExportEventTagContainer,
    ["data", "eventTags"]
);
