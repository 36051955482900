import { DashboardConfigWidgetChallenge, DashboardConfigWidgetChallengeInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetChallengeFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetChallenge, {
    title: LanguageObject;
    mode: string;
}>>;

export const mapChallengeStateWidgetToChallengeWidget = ({ mode, ...widget }: DashboardConfigWidgetChallengeFormValues): DashboardConfigWidgetChallengeInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title)
    };
};

export const mapChallengeWidgetToChallengeStateWidget = (challengeWidget: DashboardConfigWidgetChallenge): DashboardConfigWidgetChallengeFormValues => {
    return {
        ...challengeWidget,
        mode: (challengeWidget.challengeIds && challengeWidget.challengeIds.length > 0)
            ? "fixed"
            : "dynamic",
        type: challengeWidget.type as ViewType,
        title: toLanguageObject(challengeWidget.title)
    };
};
