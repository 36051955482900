import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { Checkbox, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export function getFormField(
    fieldSchema: { type: "string" | "boolean" | "number" | "image" ; enum?: string[]; mode?: "tags" | "multiple"; },
    fieldName: NamePath,
    fieldLabel: string,
    fieldKey: string,
    form: FormInstance
) {
    if (fieldSchema.enum) {
        return (
            <SelectField
                showArrow
                id="target"
                label={fieldLabel}
                mode={fieldSchema.mode}
                name={fieldName}
            >
                {
                    fieldSchema.enum.map(enumItem => {
                        return (
                            <Select.Option
                                key={enumItem}
                                value={enumItem}
                            >
                                <FormattedMessage
                                    id={enumItem}
                                />
                            </Select.Option>
                        );
                    })
                }
            </SelectField>
        );
    }

    switch (fieldSchema.type) {
        case "number":
        case "string":
            return (
                <Field
                    key={fieldKey}
                    label={fieldLabel}
                    name={fieldName}
                    numberType={fieldSchema.type === "number"}
                />
            );
        case "boolean":
            return (
                <Form.Item
                    key={fieldKey}
                    name={fieldName}
                    valuePropName="checked"
                >
                    <Checkbox>{fieldLabel}</Checkbox>
                </Form.Item>
            );
        case "image":
            return (
                <React.Fragment>
                    <p>{fieldLabel}</p>
                    <Form.Item
                        key={fieldKey}
                        name={fieldName}
                    >
                        <ImageUpload
                            form={form}
                            image={form.getFieldValue(fieldName)}
                            name={fieldName}
                        />
                    </Form.Item>
                </React.Fragment>
            );
        default:
            return null;
    }
}
