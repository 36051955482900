import { JSONData } from "@energylab/schematics";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import React from "react";
import { FieldsMapper } from "../../fieldsMapper";
import { RemoveWrapper } from "../removeWrapper";
import { ListFieldWrapperProps } from "./listFieldWrapper";

type ListItemProps = {
    field: FormListFieldData;
    remove: FormListOperation["remove"];
    fieldOptions: ListFieldWrapperProps;

}

export const ListItem: React.FC<ListItemProps> = (props) => {
    const { field, remove, fieldOptions } = props;
    const { disable, form, intl, jsonData, name, namePath, fieldName } = fieldOptions;
    const { items, uniqueItems } = jsonData;

    return (
        <RemoveWrapper
            key={field.name}
            onRemove={() => remove(field.name)}
        >
            <FieldsMapper
                required
                disable={disable}
                form={form}
                intl={intl}
                jsonData={items as JSONData}
                name={[field.name]}
                namePath={name !== undefined ? [...namePath, fieldName] : [field.name]}
                uniqueItems={uniqueItems}
            />
        </RemoveWrapper>
    );
};
