import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { getAmbassadorConfigCount, getAmbassadorConfigs } from "@graphql2/graphql_op";
import { AddAmbassadorConfigProps, GetAmbassadorConfigsProps, GetAmbassadorsProps, RemoveAmbassadorConfigProps, RemoveAmbassadorProps, UpdateAmbassadorConfigProps, withAddAmbassadorConfig, withGetAmbassadorConfigs, withGetAmbassadors, withRemoveAmbassador, withRemoveAmbassadorConfig, withUpdateAmbassadorConfig } from "@graphql2/types";
import { AddAmbassadorConfigPage } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface AddAmbassadorConfigPageContainerProps {
    editMode?: boolean;
}

interface AmbassadorsConfigFilterBarProps {
    filter?: {
        textSearch?: string;
    };
}

export interface AddAmbassadorConfigRouteProps extends RouteComponentProps<{ id: string; }, {}, { import: boolean; }> { }

const REFETCH_QUERIES = {
    refetchQueries: () => [
        "getAmbassadorConfigs",
        {
            query: getAmbassadorConfigCount,
            variables: {
                filter: {}
            }
        }
    ],
    awaitRefetchQueries: true,
    update: cleanCache([getAmbassadorConfigs])
};

const withAmbassadorConfig = withGetAmbassadorConfigs({
    name: "ambassadorConfigs",
    options: (props: AddAmbassadorConfigRouteProps) => ({
        variables: {
            filter: {
                ids: [props.match.params.id]
            }
        }
    }),
    skip: (props: AddAmbassadorConfigRouteProps) => {
        return !props.match.params.id;
    }
});

const withAmbassadors = withGetAmbassadors({
    name: "ambassadors",
    options: (props: AddAmbassadorConfigPageProps) => ({
        variables: {
            filter: {
                ...props.filter,
                place: props.ambassadorConfigs?.ambassadorConfigs
                    && props.ambassadorConfigs.ambassadorConfigs[0]
                    && props.ambassadorConfigs.ambassadorConfigs[0].place
            }
        }
    }),
    skip: (props: AddAmbassadorConfigPageProps) => {
        return !props.ambassadorConfigs
            || !props.ambassadorConfigs.ambassadorConfigs
            || !props.ambassadorConfigs.ambassadorConfigs[0]
            || !props.ambassadorConfigs.ambassadorConfigs[0].place
            || !props.match.params.id;
    }
});

const withAddAmbassadorConfigMutation = withAddAmbassadorConfig({
    name: "addAmbassadorConfig",
    options: REFETCH_QUERIES
});
const withUpdateAmbassadorConfigMutation = withUpdateAmbassadorConfig({
    name: "updateAmbassadorConfig",
    options: REFETCH_QUERIES
});
const withRemoveAmbassadorConfigMutation = withRemoveAmbassadorConfig({
    name: "removeAmbassadorConfig",
    options: REFETCH_QUERIES
});
const withRemoveAmbassadorMutation = withRemoveAmbassador({
    name: "removeAmbassador",
    options: {
        refetchQueries: ["getAmbassadors"],
        awaitRefetchQueries: true
    }
});

export const AddAmbassadorConfigPageContainer = compose<React.ComponentClass<AddAmbassadorConfigPageContainerProps>>(
    withRouter,
    withAmbassadorConfig,
    withParseDynamicFilterBar(),
    withRemoveAmbassadorMutation,
    withRemoveAmbassadorConfigMutation,
    withUpdateAmbassadorConfigMutation,
    withAddAmbassadorConfigMutation,
    withAmbassadors
)(AddAmbassadorConfigPage);

export type AddAmbassadorConfigPageProps =
    AddAmbassadorConfigPageContainerProps
    & AmbassadorsConfigFilterBarProps
    & AddAmbassadorConfigRouteProps
    & RemoveAmbassadorConfigProps<{}, "removeAmbassadorConfig">
    & RemoveAmbassadorProps<{}, "removeAmbassador">
    & UpdateAmbassadorConfigProps<{}, "updateAmbassadorConfig">
    & AddAmbassadorConfigProps<{}, "addAmbassadorConfig">
    & MaybeElements<GetAmbassadorConfigsProps<{}, "ambassadorConfigs">>
    & MaybeElements<GetAmbassadorsProps<{}, "ambassadors">>;
