import { languages } from "@pages/translationsPage/constants/languages";
import { useState } from "react";
import * as React from "react";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { Field } from "@components/field/field";
import { FormattedMessage } from "react-intl";

export const TranslationFields = ({ langs, fields }:
                                           {
                                               langs: Array<{code: string, label: string}> | null,
                                               fields: Array<{ path: string | string[], label: string }>
                                           }) => {
    const languagesSource = langs || languages;
    const [activeLang, setActiveLang] = useState<string| undefined>(languagesSource?.[0]?.code);

    return (
        <React.Fragment>
            <TranslationTabs
                activeLanguage={activeLang}
                handleChangeSelectedLanguage={setActiveLang}
                languages={languagesSource}
            />
            {
                languagesSource.map(lang => fields.map(field => (
                    <Field
                        name={[...Array.isArray(field.path) ? field.path : [field.path], lang.code]}
                        type="text"
                        label={<FormattedMessage id={field.label} />}
                        id={field.label}
                        hidden={lang.code !== activeLang}
                    />
                )))
            }
        </React.Fragment>
    );
};
