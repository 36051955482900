import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

export class FormFieldNumber extends FormFieldBase {
    public renderBody() {
        const { intl, min, max, index, deleteFormElement } = this.props;

        return (
            <React.Fragment>
                <FormItemFlex>
                    <Field
                        name={[index, "property"]}
                        initialValue={this.props.property}
                        {...this.sharedProps}
                        label={<FormattedMessage id="valueKey" />}
                    />
                    <DeleteFormField
                        as={DeleteOutlined}
                        onClick={deleteFormElement}
                    />
                </FormItemFlex>
                <FormItemFlex>
                    <Field
                        name={[index, "min"]}
                        initialValue={min}
                        placeholder={intl.formatMessage({ id: "min.placeholder" })}
                        {...this.sharedProps}
                        label={<FormattedMessage id="min" />}
                    />

                </FormItemFlex>
                <FormItemFlex>
                    <Field
                        name={[index, "max"]}
                        placeholder={intl.formatMessage({ id: "max.placeholder" })}
                        initialValue={max}
                        {...this.sharedProps}
                        label={<FormattedMessage id="max" />}
                    />
                </FormItemFlex>
            </React.Fragment>
        );
    }
}
