import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ChallengeTeamLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface DescriptionCardPropsFormValues {
    description: LanguageObject;
    title: string;
}

export interface DescriptionCardProps {
    form?: FormInstance;
    hidden?: boolean;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const descriptionField = (props: ChallengeTeamLanguageFieldProps) => (
    <Field
        name={["description", props.language]}
        hidden={props.hidden}
        key={`description.${props.language}`}
        label={<FormattedMessage id="description" />}
        info={maxCharacters()}
        id={`description.${props.language}`}
        {...props.sharedProps}
    />
);

export class DescriptionCard extends React.Component<DescriptionCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { changeSelectedLanguage, hidden, form, languageErrors, disabled, activeLanguage, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        if (!form) {
            return;
        }

        const langFieldProps = languages.map(language => ({ form, sharedProps, defaultValues: {}, language: language.code, hidden: language.code !== activeLanguage }));

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="titleAndDescription" />}
                />
                <Field
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    name={["title"]}
                    label={<FormattedMessage id="title" />}
                    {...sharedProps}
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                    errors={languageErrors}
                />
                {langFieldProps.map(descriptionField)}
            </Card>
        );
    }
}
