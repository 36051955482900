import { Field } from "@components/field/field";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface PersonalJourneyFormProps {
    disabled?: boolean;
    onBlur(): void;
}

export const PersonalJourneyForm: React.FC<PersonalJourneyFormProps> = (props) => (
    <React.Fragment>
        <FormattedMessage tagName="h2" id="personalJourneySettings" />
        <Field
            name="duration"
            rules={[
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                },
                {
                    pattern: /^[1-9]\d*$/,
                    message: <FormattedMessage id="form.warning.negativeValue" />
                }
            ]}
            label={<FormattedMessage id="duration" />}
            id="duration"
            numberType
            {...props}
        />
    </React.Fragment>
);
