
import { Checkbox, Col, Form, Row, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { CkeField } from "@components/field/ckeField";
import { Field } from "@components/field/field";
import { FormItemFlex } from "@components/field/formConditionFieldStyle";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { getText } from "@utils/getText";
import { reminderLabels } from "../../../../constants";
import { EventProjectSettingsCardProps } from "./eventProjectSettingsCard";
import { ColHeader, ColItem } from "./eventProjectSettingsCardStyles";

export const DescriptionFields = (props: EventProjectSettingsCardProps) => {
    const { form, prefix, projectIndex, languageErrors, activeLanguage, changeSelectedLanguage, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Flex
            direction="column"
            style={{ flex: 1 }}
        >
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
            />
            {languages.map(language => {
                return (
                    <CkeField
                        key={`projects.${projectIndex}.description.${language.code}`}
                        form={form}
                        hasError={form.getFieldsError([[...prefix, projectIndex, "description", language.code]]).find(val => Boolean(val.errors.length))}
                        hidden={language.code !== activeLanguage}
                        id={`projects.${projectIndex}.description.${language.code}`}
                        label={<FormattedMessage id="description" />}
                        name={["projects", projectIndex, "description", language.code]}
                        {...sharedProps}
                    />
                );
            })}
        </Flex>
    );
};

export const GeneralSettingsFields = (props: EventProjectSettingsCardProps) => {
    const { project, projectIndex, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Flex>
            <Flex direction="column">
                <Form.Item
                    initialValue={project.subscriptionEnabled || false}
                    name={[...prefix, projectIndex, "subscriptionEnabled"]}
                    valuePropName="checked"
                >
                    <Checkbox
                        {...sharedProps}
                    >
                        <FormattedMessage id="subscriptionEnabled" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    initialValue={project.viewParticipants || false}
                    name={[...prefix, projectIndex, "viewParticipantCount"]}
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="viewParticipantCount" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    initialValue={project.viewParticipants || false}
                    name={[...prefix, projectIndex, "viewParticipants"]}
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="viewParticipants" />
                    </Checkbox>
                </Form.Item>
            </Flex>
            <Flex direction="column">
                <Form.Item
                    name={[...prefix, projectIndex, "waitingListEnabled"]}
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="waitingListEnabled" />
                    </Checkbox>
                </Form.Item>
            </Flex>
            <Flex direction="column">
                <Form.Item
                    initialValue={project.featured || false}
                    name={[...prefix, projectIndex, "featured"]}
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="featured" />
                    </Checkbox>
                </Form.Item>
            </Flex>
        </Flex>
    );
};

export const TagsFields = (props: EventProjectSettingsCardProps) => {
    const { eventTags, projectIndex, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Flex>
            <div>
                <SelectField
                    id={`projects.${projectIndex}.tags`}
                    label={<FormattedMessage id="tags" />}
                    mode="multiple"
                    name={[...prefix, projectIndex, "tags"]}
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    {...sharedProps}
                    disabled={!eventTags || !eventTags.length}
                >
                    {eventTags
                        ? eventTags.map(t => (
                            <Select.Option
                                key={t.id}
                                value={t.id}
                            >
                                {t.title}
                            </Select.Option>
                        ))
                        : null}
                </SelectField>
            </div>
        </Flex>
    );
};

export const RolesFields = (props: EventProjectSettingsCardProps) => {
    const { roles, projectIndex, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Flex>
            <div>
                <SelectField
                    {...sharedProps}
                    filterOption={(input, option) => (option as any) && Object.values(option as any).some(el => (el as any).toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                    id={`projects.${projectIndex}.roles`}
                    label={<FormattedMessage id="roles" />}
                    mode="multiple"
                    name={[...prefix, projectIndex, "roles"]}
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                >
                    {roles.map(r => (
                        <Select.Option
                            key={r.id}
                            value={r.id}
                        >
                            {r.name}
                        </Select.Option>
                    ))}
                </SelectField>
            </div>
        </Flex>
    );
};

export const RemindersFields = (props: EventProjectSettingsCardProps) => {
    const { projectIndex, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Row gutter={16}>
            <Col>
                <SelectField
                    {...sharedProps}
                    disabled={!reminderLabels || !reminderLabels.length}
                    id={`projects.${projectIndex}.reminders`}
                    label={<FormattedMessage id="reminders" />}
                    mode="multiple"
                    name={[...prefix, projectIndex, "reminders"]}
                >
                    {reminderLabels
                        ? (reminderLabels.map(r => {
                            return (
                                <Select.Option
                                    key={r}
                                    value={r}
                                >
                                    <FormattedMessage id={r} />
                                </Select.Option>
                            );
                        }))
                        : null}
                </SelectField>
            </Col>
            <Col>
                <Field
                    {...sharedProps}
                    numberType
                    id={`projects.${projectIndex}.daysToFinalEditReminder`}
                    label={<FormattedMessage id="daysToFinalEditReminder" />}
                    name={[...prefix, projectIndex, "daysToFinalEditReminder"]}
                />
            </Col>
        </Row>
    );
};

export const FormTemplatesFields = (props: EventProjectSettingsCardProps) => {
    const { form, projectIndex, formTemplates, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    const formSubscriptionEnabledFormName = [...prefix, projectIndex, "formSubscriptionEnabled"];

    return (
        <FormItemFlex>
            <Form.Item dependencies={[formSubscriptionEnabledFormName]}>
                {() => {
                    const formSubscriptionEnabled = form.getFieldValue(formSubscriptionEnabledFormName);
                    return (
                        <SelectField
                            id={`projects.${projectIndex}.formTemplates`}
                            label={<FormattedMessage id="formTemplates" />}
                            mode="multiple"
                            name={[...prefix, projectIndex, "formTemplates"]}
                            {...sharedProps}
                            disabled={!formSubscriptionEnabled || !reminderLabels || !reminderLabels.length}
                        >
                            {formTemplates
                                ? (formTemplates.map(f => {
                                    if (f === null) {
                                        return;
                                    }

                                    return (
                                        <Select.Option
                                            key={f.id}
                                            value={f.id}
                                        >
                                            {getText(f.title || {})}
                                        </Select.Option>
                                    );
                                }))
                                : null}
                        </SelectField>
                    );
                }}
            </Form.Item>
            <Form.Item
                name={formSubscriptionEnabledFormName}
                valuePropName="checked"
            // rules={[checkboxTransformRule]}
            >
                <Checkbox {...sharedProps}>
                    <FormattedMessage id="formSubscriptionEnabled" />
                </Checkbox>
            </Form.Item>
        </FormItemFlex>
    );
};

export const SubEventsFields = (props: EventProjectSettingsCardProps) => {
    const { project, projectIndex, prefix, subEvents, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };
    const { subEvents: projectSubEvents } = project;
    const [maxParticipantsDisabled, setMaxParticipantsDisabled] = React.useState<Record<string, boolean>>(() => {
        const idToMaxParticipantsDisabled = {};
        subEvents.forEach((subEvent) => {
            const foundProjectSubEvent = projectSubEvents.find((projectSubEvent) => projectSubEvent.id === subEvent.id);
            idToMaxParticipantsDisabled[subEvent.id] = foundProjectSubEvent?.maxParticipantsDisabled ?? false;
        });
        return idToMaxParticipantsDisabled;
    });

    return (
        <Row >

            <ColHeader span={6}>
                <FormattedMessage id="title" />
            </ColHeader>
            <ColHeader span={2}>
                <FormattedMessage id="maxParticipantsDisabled" />
            </ColHeader>
            <ColHeader span={6}>
                <FormattedMessage id="maxParticipants" />
            </ColHeader>
            <ColHeader span={6}>
                <FormattedMessage id="participantOffset" />
            </ColHeader>
            <ColHeader span={2}>
                <FormattedMessage id="participationFull" />
            </ColHeader>
            <ColHeader span={2}>
                <FormattedMessage id="subscriptionEnabled" />
            </ColHeader>
            {subEvents.map((subEvent, subEventIndex) => {
                const foundProjectSubEvent = projectSubEvents.find((projectSubEvent) => projectSubEvent.id === subEvent.id);

                const participantOffset = maxParticipantsDisabled[subEvent.id] ? 0 : (foundProjectSubEvent?.participantOffset || 0);
                const maxParticipants = maxParticipantsDisabled[subEvent.id] ? 0 : (foundProjectSubEvent?.maxParticipants || 0);

                return (
                    <React.Fragment>
                        <ColItem span={6}>
                            {subEvent.title[0].text ?? subEvent.id}
                        </ColItem>
                        <Field
                            {...sharedProps}
                            hidden
                            initialValue={foundProjectSubEvent?.id ?? subEvent.id}
                            name={[...prefix, projectIndex, "subEvents", subEventIndex, "id"]}
                            type="hidden"
                        />
                        <ColItem span={2}>
                            <Form.Item
                                initialValue={maxParticipantsDisabled[subEvent.id]}
                                name={[...prefix, projectIndex, "subEvents", subEventIndex, "maxParticipantsDisabled"]}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    {...sharedProps}
                                    onChange={(value) => setMaxParticipantsDisabled((prevState) => ({ ...prevState, [subEvent.id]: value.target.checked }))}
                                />
                            </Form.Item>
                        </ColItem>
                        <ColItem span={6}>
                            <Field
                                {...sharedProps}
                                numberType
                                disabled={maxParticipantsDisabled[subEvent.id]}
                                id={`projects.${projectIndex}.${subEvent.id}.maxParticipants`}
                                initialValue={maxParticipants}
                                name={[...prefix, projectIndex, "subEvents", subEventIndex, "maxParticipants"]}
                            // normalize={value => value.toString()}
                            />
                        </ColItem>
                        <ColItem span={6}>
                            <Field
                                {...sharedProps}
                                numberType
                                disabled={maxParticipantsDisabled[subEvent.id]}
                                id={`projects.${projectIndex}.${subEvent.id}.participantOffset`}
                                initialValue={participantOffset}
                                name={[...prefix, projectIndex, "subEvents", subEventIndex, "participantOffset"]}
                            // normalize={value => value.toString()}
                            />
                        </ColItem>
                        <ColItem span={2}>
                            ,
                            <Form.Item
                                initialValue={foundProjectSubEvent?.participationFull || false}
                                name={[...prefix, projectIndex, "subEvents", subEventIndex, "participationFull"]}
                                valuePropName="checked"
                            >
                                <Checkbox {...sharedProps} />
                            </Form.Item>
                        </ColItem>
                        <ColItem span={2}>
                            <Form.Item
                                initialValue={foundProjectSubEvent?.subscriptionEnabled || false}
                                name={[...prefix, projectIndex, "subEvents", subEventIndex, "subscriptionEnabled"]}
                                valuePropName="checked"
                            >
                                <Checkbox {...sharedProps} />
                            </Form.Item>
                        </ColItem>
                    </React.Fragment>
                );
            })}
        </Row>
    );
};

export const HiddenFields = (props: EventProjectSettingsCardProps) => {
    const { projectIndex, prefix, disabled, onBlur } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <Field
            {...sharedProps}
            hidden
            name={[...prefix, projectIndex, "projectId"]}
            type="hidden"
        />
    );
};

