import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { TeamChallengeCard } from "@pages/addChallengesPage/tabs/team/challengeTeamCard";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

export interface ChallengeNameTabProps {
    form: FormInstance;
    defaultProps: ChallengePageDefaultProps;
    hidden: boolean;
    editMode: boolean;
}

export const ChallengTeamTab: React.FC<ChallengeNameTabProps> = (props) => {
    const {
        form,
        hidden,
        editMode,
        defaultProps
    } = props;

    return (
        <TeamChallengeCard
            {...defaultProps}
            editMode={editMode}
            form={form}
            hidden={hidden}
        />
    );
};
