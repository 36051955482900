import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import * as moment from "moment";
import { ArticleFormValues } from "@pages/addArticlesPage/addArticlesPage";
import { ArticleTableData } from "@components/articles/articleTable";
import * as Showdown from "showdown";
import { formatDate } from "@utils/dateUtils";
import { ArticleOveviewFragment, ArticleFragment, AddArticleMutationVariables, Maybe } from "@graphql2/types";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { emptyArticle } from "@utils/emptyItems/emptyArticle";
import { getText } from "../getText";

const converter: Showdown.Converter = new Showdown.Converter({
    simplifiedAutoLink: true,
    tables: true
});

const getStatusType = (expiresOn: Maybe<number> | undefined, publishOn:Maybe<number> | undefined, isDraft:Maybe<boolean> | undefined) => {
    if (expiresOn && expiresOn < moment().valueOf()) return "expired";
    if (isDraft) {
        return "draft";
    }
    if (publishOn && publishOn > moment().valueOf()) {
        return "notPublishedYet";
    }
    return "active";
};

const calculateMaxItemsPeriod = (orderRestrictions, restrictionDayAmount, restrictionDayType) => {
    if (orderRestrictions.enableMaxItemsPerPerson && orderRestrictions.enableMaxItemsPeriod && restrictionDayAmount && restrictionDayType) {
        return moment.duration({ [restrictionDayType]: restrictionDayAmount }).toISOString();
    }
    return null;
};

const covertInstructionsToMarkdown = (voucher) => {
    if (voucher && voucher.instruction) {
        voucher.instruction.map(inst => ({
            ...inst,
            text: converter.makeMarkdown(inst.text || "")
        }));

        return voucher.instruction;
    }

    return false;
};

export function articleToFormValues(article?: ArticleFragment | null): ArticleFormValues {
    if (!article) {
        return emptyArticle;
    }

    const {
        id,
        title,
        description,
        articleNumber,
        image,
        price,
        currentStock,
        availableStock,
        roles,
        orderRestrictions,
        countries,
        isDraft,
        publishOn,
        expiresOn,
        voucher,
        type,
        relatedContents,
        priority
    } = article;

    let maxItemsPeriod = 0;
    if (orderRestrictions && orderRestrictions.maxItemsPeriod) {
        maxItemsPeriod = moment.duration(orderRestrictions.maxItemsPeriod).asDays();
    }

    const voucherInstructions = covertInstructionsToMarkdown(voucher);

    return {
        id,
        price,
        imageUrl: image,
        title: toLanguageObject(title),
        description: toLanguageObject(description),
        orderRestrictions: {
            enableMaxItemsPerPerson: (orderRestrictions && orderRestrictions.enableMaxItemsPerPerson) || false,
            enableMaxItemsPeriod: (orderRestrictions && orderRestrictions.enableMaxItemsPeriod) || false,
            maxItemsPerPerson: orderRestrictions && orderRestrictions.maxItemsPerPerson ? orderRestrictions.maxItemsPerPerson : undefined,
            maxItemsPeriod: orderRestrictions && orderRestrictions.maxItemsPeriod ? orderRestrictions.maxItemsPeriod : undefined
        },
        isDraft: isDraft || false,
        publishOn: moment(publishOn || undefined),
        expiresOn: moment(expiresOn || undefined),
        priority: priority || 0,
        articleNumber,
        currentStock,
        availableStock,
        roles: roles?.filter(getOutFalseValues),
        countries: countries?.filter(getOutFalseValues),
        instruction: voucherInstructions ? toLanguageObject(voucherInstructions) : {},
        vouchers: voucher && voucher.vouchers ? voucher.vouchers.filter(getOutFalseValues) : undefined,
        restrictionDayAmount: maxItemsPeriod,
        restrictionDayType: "days",
        type,
        relatedContentId: relatedContents?.[0]?.id
    };
}

export function translateArticles(originals: ArticleOveviewFragment[]): ArticleTableData[] {
    return originals.map(article => {
        const { id, title, price, expiresOn, publishOn, isDraft, thirdParty, priority } = article;

        return {
            id,
            title: getText(title),
            price,
            currentStock: article.currentStock,
            thirdParty: thirdParty || "none",
            status: !(isDraft || (expiresOn && expiresOn < moment().valueOf()) || (publishOn && publishOn > moment().valueOf())) || false,
            statusType: getStatusType(expiresOn, publishOn, isDraft),
            priority: priority || 0
        };
    });
}

export function formValuesToArticle(formValues: any): AddArticleMutationVariables {
    const {
        title,
        description,
        articleNumber,
        imageUrl,
        price,
        currentStock,
        roles,
        orderRestrictions,
        countries,
        isDraft,
        publishOn,
        expiresOn,
        priority,
        restrictionDayAmount,
        restrictionDayType,
        vouchers,
        instruction,
        type,
        relatedContentId
    } = formValues;

    const validInstructions = toTextByLanguage(instruction).filter(v => v.text && v.text.length).length > 0;

    if (validInstructions) {
        Object.keys(instruction).forEach(i => {
            instruction[i] = converter.makeHtml(instruction[i] || "");
        });
    }

    return {
        image: imageUrl || "",
        description: toTextByLanguage(description),
        title: toTextByLanguage(title),
        publishOn: formatDate(publishOn),
        expiresOn: formatDate(expiresOn),
        priority,
        price,
        currentStock,
        availableStock: currentStock,
        roles: roles || [],
        orderRestrictions: {
            enableMaxItemsPerPerson: orderRestrictions.enableMaxItemsPerPerson || false,
            enableMaxItemsPeriod: orderRestrictions.enableMaxItemsPeriod || false,
            maxItemsPerPerson: orderRestrictions.enableMaxItemsPerPerson && orderRestrictions.maxItemsPerPerson ? orderRestrictions.maxItemsPerPerson : null,
            maxItemsPeriod: calculateMaxItemsPeriod(orderRestrictions, restrictionDayAmount, restrictionDayType)
        },
        countries,
        isDraft: isDraft || false,
        articleNumber: articleNumber || "",
        vouchers: vouchers ? vouchers.map(v => v.code) : [],
        instructions: validInstructions ? toTextByLanguage(instruction) : undefined,
        type,
        contentsIds: relatedContentId ? [relatedContentId] : []
    };
}
