import { getFlows } from "@graphql2/graphql_op";
import { AddFlowProps, GetFlowsProps, RemoveFlowProps, UpdateFlowProps, withAddFlow, withGetFlows, withRemoveFlow, withUpdateFlow } from "@graphql2/types";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { AddFlowsPage } from "./addFlowsPage";

type AddFlowsRouteProps = RouteComponentProps<{ id: string; }>;

const withFlows = withGetFlows({
    name: "flows",
    options({ match: { params: { id } } }: AddFlowsPageProps) {
        return {
            variables: {
                filter: {
                    flowId: id
                }
            }
        };
    },
    skip(props: AddFlowsPageProps) {
        return !props.match.params.id;
    }
});

const withUpdateFlowMutation = withUpdateFlow({
    name: "updateFlow",
    options: {
        refetchQueries: () => ["getFlows"],
        awaitRefetchQueries: true,
        update: cleanCache([getFlows])
    }
});

const withAddFlowMutation = withAddFlow({
    name: "addFlow",
    options: {
        refetchQueries: () => ["getFlows", "getFlowsCount"],
        awaitRefetchQueries: true,
        update: cleanCache([getFlows])
    }
});

const withRemoveFlowMutation = withRemoveFlow({
    name: "removeFlow",
    options: {
        refetchQueries: () => ["getFlows", "getFlowsCount"],
        awaitRefetchQueries: true,
        update: cleanCache([getFlows])
    }
});

export const AddFlowsPageContainer = compose<React.ComponentClass>(
    withRouter,
    withFlows,
    withUpdateFlowMutation,
    withAddFlowMutation,
    withRemoveFlowMutation
)(AddFlowsPage);

export type AddFlowsPageProps =
    AddFlowsRouteProps &
    GetFlowsProps<{}, "flows"> &
    UpdateFlowProps<{}, "updateFlow"> &
    AddFlowProps<{}, "addFlow"> &
    RemoveFlowProps<{}, "removeFlow">;
