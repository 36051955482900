import { FormFieldCheckbox } from "@components/formContentFields/formFieldTypes/formFieldCheckbox";
import { FormFieldContent } from "@components/formContentFields/formFieldTypes/formFieldContent";
import { FormFieldDate } from "@components/formContentFields/formFieldTypes/formFieldDate";
import { FormFieldLink } from "@components/formContentFields/formFieldTypes/formFieldLink";
import { FormFieldNumber } from "@components/formContentFields/formFieldTypes/formFieldNumber";
import { FormFieldRadio } from "@components/formContentFields/formFieldTypes/formFieldRadio";
import { FormFieldSelect } from "@components/formContentFields/formFieldTypes/formFieldSelect";
import { FormFieldTextArea } from "@components/formContentFields/formFieldTypes/formFieldTextArea";
import { FormFieldTextField } from "@components/formContentFields/formFieldTypes/formFieldTextField";
import { FormFieldUpload } from "@components/formContentFields/formFieldTypes/formFieldUpload";
import { ChallengeFormConditionFormValues, FormTemplateElementFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { InjectedIntl } from "@utils/override.types";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

export interface FormElementProps extends FormTemplateElementFormValues {
    form: FormInstance;
    condition?: ChallengeFormConditionFormValues;
    index: number;
    intl: InjectedIntl;
    disableRequiredField?: boolean;
    moveFormElement(from: number, to: number): void;
    deleteFormElement(): void;
    prefix: (string | number)[];
    onBlur(): void;
}

export interface FormElementOptionProps {
    condition: ChallengeFormConditionFormValues;
    sequence: number;
}

export class FormElementField extends React.Component<FormElementProps> {
    public render() {
        return (
            <React.Fragment>
                {this.renderField()}
            </React.Fragment>
        );
    }

    private renderField() {
        switch (this.props.type) {
            case "select":
                return <FormFieldSelect {...this.props} />;

            case "radio":
                return <FormFieldRadio {...this.props} />;

            case "checkbox":
                return <FormFieldCheckbox {...this.props} />;

                // case "checkboxList"
                // case "password"
                // case "slider"
                // case "cascader"
                // case "uploadAvatar"
                // case "row"

            case "upload":
            case "fileUpload": // remove?
                return <FormFieldUpload {...this.props} />;

            case "a":
            case "link":
                return (
                    <FormFieldLink
                        disableRequiredField
                        {...this.props}
                    />
                );

            case "content": // remove?
            case "h1":
            case "h2":
            case "p":
                return (
                    <FormFieldContent
                        {...this.props}
                        disableRequiredField
                        type="content"
                    />
                );

            case "text":
                return <FormFieldTextField {...this.props} />;

            case "number":
                return <FormFieldNumber {...this.props} />;

            case "date":
                return <FormFieldDate {...this.props} />;

            case "textarea":
            case "textArea":
                return <FormFieldTextArea {...this.props} />;

            default:
        }
    }
}
