import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export const DEVICES: ViewType[] = ["desktop", "tablet", "mobile"];

export const DEFAULT_PLACES: string[] = [
    "dashboard",
    "articles_header",
    "benefits_header",
    "challenges_header",
    "events_header",
    "gallery_header",
    "dashboard_header",
    "leaderboard_header",
    "myspace_header",
    "myactivities_header",
    "profile_header",
    "webshop_header",
    "contact_header",
];
