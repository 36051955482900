import * as environment from "environment";

export const getLanguageHeader = () => {
    const chosenLanguage = localStorage.getItem("language");

    if (chosenLanguage) {
        return chosenLanguage;
    }

    const availableLanguages = environment.languages;

    if (!availableLanguages || availableLanguages.length < 1) {
        throw new Error("Missing available languages!");
    }

    if (availableLanguages.indexOf("en") > -1) {
        return "en";
    }

    if (availableLanguages.indexOf("nl") > -1) {
        return "nl";
    }

    if (availableLanguages[0]) {
        return availableLanguages[0];
    }
};