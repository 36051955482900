import Popconfirm, { PopconfirmProps } from "antd/lib/popconfirm";
import React from "react";
import { FormattedMessage } from "react-intl";

interface ChallengeTeamPopupProps {
    disableOkButton?: boolean
    onConfirm(): void
    children: PopconfirmProps["children"]
}

export const ChallengeTeamPopup: React.FC<ChallengeTeamPopupProps> = (props) => {
    const { disableOkButton, onConfirm, children } = props;

    return (
        <Popconfirm
            placement="topRight"
            okButtonProps={{
                disabled: disableOkButton
            }}
            overlayStyle={{
                width: 500
            }}
            title={
                <FormattedMessage id="challengeTeam.table.remove.popup.title" />
            }
            okText={
                <FormattedMessage id="challengeTeam.table.remove.popup.ok" />
            }
            cancelText={
                <FormattedMessage id="challengeTeam.table.remove.popup.cancel" />
            }
            onConfirm={onConfirm}
        >
            {children}
        </Popconfirm>
    );
};
