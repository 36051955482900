import { Table } from "@components/table/table";
import { TableFilterProps } from "@components/table/tableFilters";
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from "history";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { TablePaginationConfig } from "antd/lib/table";
import FormComponentProps from "antd/lib/form";
import { GetApplicationsProps, withGetApplications, ApplicationFragment } from "@graphql2/types";

export interface TableData {
    id: string;
    hasError?: boolean;
}

export interface TableHandlers {
    [key: string]: Function;
}

export interface FilterFields {
    placeholder: string;
    key: string;
    type: "search" | "select" | "datepicker";
    styles?: object;
    options?: string[];
}

export interface TableComponentProps {
    dataSource: TableData[];
    loading?: boolean;
    handlers?: TableHandlers;
    history?: History;
    pagination?: TablePaginationConfig;
    variables?: Record<string, any>,
    onChange?(pagination, filters, sorter): void;
    applications?: ApplicationFragment[];
}

export interface TableContainerProps {
    loading?: boolean;
    tableFilter?: Partial<TableFilterProps>;
    TableComponent: React.FC<TableComponentProps> | React.ComponentClass<TableComponentProps>;
    dataSource: TableData[];
    handlers?: TableHandlers;
    applications?: ApplicationFragment[];
    totalCount?: number;
    extraComponents?: Record<string, React.ComponentType>;
    variables?: Record<string, any>;
    pagination?: TablePaginationConfig;
    errors?: Record<string, unknown>[];
    onChange?(pagination, filters, sorter): void;
}

const withApplications = withGetApplications({
    name: "applications",
    skip: ({ tableFilter }: TableContainerProps) => {
        return !(tableFilter && tableFilter.filterOn && tableFilter.filterOn.userRoles);
    }
});

export type TableProps =
    TableContainerProps
    & MaybeElements<GetApplicationsProps<{}, "applications">>
    & FormComponentProps
    & RouteComponentProps;

export const TableContainer = compose<React.FC<TableContainerProps | React.ComponentClass<TableContainerProps>>>(
    withRouter,
    withApplications
)(Table);
