/* eslint-disable react/no-array-index-key */
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { FormTemplateFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { FormConditionTranslations, TranslationField, TranslationOptionField, TranslationOptions, TreeWrapper, ValueKey } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/translations/challengeFormConditionTranslationsStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface FormTranslationsProps {
    formTemplate: FormTemplateFormValues;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    form: FormInstance;
    defaultValues: Partial<FormTemplateFormValues>;
    hidden: boolean;
    changeSelectedLanguage(languageCode: string): void;
    onChange?: () => void;
}

export interface TranslationProps extends ChallengeLanguageFieldProps {
    formTemplate: FormTemplateFormValues;
}

const titleField = (props: TranslationProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        {...props.sharedProps}
    />
);

const errorField = (props: TranslationProps) => (
    <Field
        key={`errorMessages.${props.language}`}
        hidden={props.hidden}
        id={`errorMessages.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="errorMessage" />}
        name={["errorMessages", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

export class FormTranslations extends React.Component<FormTranslationsProps> {
    public render() {
        const {
            formTemplate,
            changeSelectedLanguage,
            languageErrors,
            defaultValues,
            form,
            activeLanguage,
            onChange,
            hidden
        } = this.props;

        const sharedProps = {
            onBlur: onChange,
            rules: [requiredrule]
        };
        const elements = form.getFieldValue("elements") || formTemplate.elements || [];

        const langFieldProps = languages.map(language => ({ form, sharedProps, defaultValues, language: language.code, hidden: language.code !== activeLanguage, formTemplate }));

        return (
            <FormConditionTranslations hidden={hidden}>
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(errorField)}
                {
                    elements && elements.map((el, elementIndex) => {
                        return langFieldProps.map((props) => (
                            <TreeWrapper key={props.language}>
                                <ValueKey>
                                    <TranslationField
                                        key={`title.${elementIndex}`}
                                        hidden={props.hidden}
                                        label={el.property ? el.property : ""}
                                        name={["elements", elementIndex, "title", props.language]}
                                        type="text"
                                        {...sharedProps}
                                    />
                                </ValueKey>
                                <TranslationOptions>
                                    {el.options && el.options.map((option, optionIndex) => (
                                        <TranslationOptionField
                                            key={optionIndex}
                                            hidden={props.hidden}
                                            label={option.value ? option.value : ""}
                                            name={["elements", elementIndex, "options", optionIndex, "title", props.language]}
                                            type="text"
                                            {...sharedProps}
                                        />
                                    ))}
                                </TranslationOptions>
                            </TreeWrapper>
                        ));
                    })
                }
            </FormConditionTranslations>
        );
    }
}
