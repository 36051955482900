import { TextByLanguage } from "@graphql2/types";

export function toTextByLanguage(langObject?: { [lang: string]: string }): TextByLanguage[] {
    if (!langObject) {
        return [];
    }

    return Object.keys(langObject).map(key => ({
        language: key,
        text: langObject[key] || ""
    }));
}
