import styled from "styled-components";

export const AddDashboardPageStyle = styled.div`
    h3 {
        color: #A0C63D;
        margin: 15px 0 0 0;
        font-weight: 600;
    }
`;

export const DashboardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
