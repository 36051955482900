import uuidv4 from "uuid/v4";
import { ChallengeLeaderboardVisualisationFormValues } from "../tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisation";
import { ChallengeVisualisationBaseFormValues } from "../tabs/visualisations/visualisationTypeForm/visualisationTypeForm";

export const visualisationFactory = (props: Partial<ChallengeVisualisationBaseFormValues>) => {
    return {
        id: uuidv4(),
        ...props
    };
};

export const leaderboardVisualisationFactory = (props: Partial<ChallengeVisualisationBaseFormValues>): ChallengeLeaderboardVisualisationFormValues => {
    return {
        ...props,
        isGlobal: props.conditionId === "global",
        conditions: [],
        numberOfItemsEnabled: false,
        numberOfItems: 0
    };
};
