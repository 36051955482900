import { useGetSessionQuery } from "@graphql2/types";
import React, { createContext, useReducer } from "react";
import { AuthAction, AuthActionTypes } from "./actions";
import { authReducer } from "./reducer";

export type SessionData = {
    expirationDate: number | null;
    projectId: string | null;
    userId: string | null;
    roles: string[];
}

export type AuthState = {
    session: SessionData|undefined;
    isAuthStateUpdated: boolean;
}

const initialSessionState = {
    expirationDate: null,
    projectId: null,
    userId: null,
    roles: []
};

const initialAuthState = {
    session: initialSessionState,
    isAuthStateUpdated: false
};

const AuthContext = createContext<{
    authState: AuthState;
    authDispatch: React.Dispatch<AuthAction>;
}>({
    authState: initialAuthState,
    authDispatch: () => null
});

const AuthProvider: React.FC = ({ children }) => {
    const { loading, data, error } = useGetSessionQuery();
    const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
    const [isSessionLoaded, setIsSessionLoaded] = React.useState(false);

    React.useEffect(() => {
        if (authState.isAuthStateUpdated) {
            setIsSessionLoaded(true);
        }
    }, [authState.isAuthStateUpdated]);

    React.useEffect(() => {
        if (loading) {
            return;
        }

        if (error) {
            authDispatch({ type: AuthActionTypes.LOGOUT });
            localStorage.removeItem("token");
        }

        if (data) {
            authDispatch({ type: AuthActionTypes.UPDATE_SESSION_INFO, payload: data.session });
        }
    }, [loading, data, error]);

    if (!isSessionLoaded) {
        return null;
    }

    return (
        <AuthContext.Provider value={{ authState, authDispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const { authState: { session } } = React.useContext(AuthContext);

    return {
        expirationDate: session?.expirationDate ?? null,
        projectId: session?.projectId ?? "",
        userId: session?.userId ?? "",
        roles: session?.roles ?? []
    };
};

export { AuthContext, AuthProvider, useAuth };
