import * as React from "react";
import { compose } from "redux";
import { exportFactory, ExportProps } from "./export";
import { ExportContainerProps, withLocalState } from "./exportContainer";
import { withGetChallengeTags, GetChallengeTagsProps } from "../../graphql2/types";

export const withChallengeTag = withGetChallengeTags({
    skip(props: ExportContainerProps) {
        return !props.shouldExport;
    }
});

export const ExportChallengeTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withChallengeTag
)(exportFactory({ targetProp: ["data", "challengeTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportChallengeTagContainerProps = ExportContainerProps & MaybeElements<GetChallengeTagsProps>;
