
import { getChallengeCount, getChallengesBasic } from "@graphql2/graphql_op";
import { cleanCache } from "@utils/cleanCacheHoc";
import { FormInstance, FormProps } from "antd/lib/form";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
    AddChallengeProps,
    ChallengeFragment,
    ExportChallengeParticipationFormConditionDataProps,
    GetApplicationsProps,
    GetChallengeParticipantCountProps,
    GetChallengeTagsProps,
    GetChallengesDetailProps,
    RemoveChallengeProps,
    ResyncChallengeProps,
    SendMilestoneEmailProps,
    UpdateChallengeProps,
    withAddChallenge,
    withExportChallengeParticipationFormConditionData,
    withGetApplications,
    withGetChallengeParticipantCount,
    withGetChallengeTags,
    withGetChallengesDetail,
    withRemoveChallenge,
    withResyncChallenge,
    withSendMilestoneEmail,
    withUpdateChallenge
} from "../../graphql2/types";
import { AddChallengesPage } from "./addChallengesPage";

export interface AddChallengesPageContainerProps { }

export const ADD_CHALLENGE_TABS = {
    general: "general",
    advanced: "advanced",
    conditions: "conditions",
    team: "team",
    milestones: "milestones",
    prizes: "prizes",
    visualisations: "visualisations",
    extensions: "extensions"
};

type AddChallengeRouteProps = RouteComponentProps<{
    tab: string;
    id: string;
    itemIndex?: string;
}>;

const REFETCH_QUERIES = {
    refetchQueries: () => [
        {
            query: getChallengeCount,
            variables: {
                filter: {},
                paging: {}
            }
        }
    ],
    awaitRefetchQueries: true,
    update: cleanCache([getChallengeCount, getChallengesBasic])
};

export const withApplications = withGetApplications({
    name: "applications"
});

export const withChallenges = withGetChallengesDetail({
    name: "challenges",
    options: (props: AddChallengeRouteProps) => {
        return {
            variables: {
                filter: {
                    ids: props.match.params.id ? [props.match.params.id] : []
                },
                paging: {}
            }
        };
    },
    skip: (props: AddChallengeRouteProps) => {
        return !props.match.params.id;
    }
});

export const withChallengeParticipantCount = withGetChallengeParticipantCount({
    name: "challengeParticipantCount",
    options(props: AddChallengeRouteProps) {
        return {
            variables: {
                filter: {
                    challengeIds: props.match.params.id ? [props.match.params.id] : []
                }
            }
        };
    }
});

const withExportChallengeFormDataMutation = withExportChallengeParticipationFormConditionData({
    name: "exportChallengeConditionsFormData"
});

export const withAddChallengeMutation = withAddChallenge({
    name: "addChallenge",
    options: REFETCH_QUERIES
});

export const withUpdateChallengeMutation = withUpdateChallenge({
    name: "updateChallenge",
    options: REFETCH_QUERIES
});

export const withResyncChallengeMutation = withResyncChallenge({
    name: "resyncChallenge"
});

export const withChallengeTags = withGetChallengeTags({
    name: "tags"
});

export const withSendMilestoneEmailMutation = withSendMilestoneEmail({
    name: "sendMilestoneEmail"
});

export const withRemoveChallengeMutation = withRemoveChallenge({
    name: "removeChallenge",
    options: REFETCH_QUERIES
});

export const AddChallengesPageContainer = compose<React.ComponentClass<AddChallengesPageContainerProps>>(
    withRouter,
    withChallenges,
    withChallengeParticipantCount,
    withApplications,
    withUpdateChallengeMutation,
    withChallengeTags,
    withSendMilestoneEmailMutation,
    withAddChallengeMutation,
    withRemoveChallengeMutation,
    withResyncChallengeMutation,
    withExportChallengeFormDataMutation
)(AddChallengesPage);

export type AddChallengesRouteProps = RouteComponentProps<{ tab: string; id: string; }, {}, { import: ChallengeFragment; }>;

export type AddChallengesPageProps = AddChallengesPageContainerProps
    & FormProps
    & { form: FormInstance; }
    & ExportChallengeParticipationFormConditionDataProps<{}, "exportChallengeConditionsFormData">
    & RemoveChallengeProps<{}, "removeChallenge">
    & ResyncChallengeProps<{}, "resyncChallenge">
    & MaybeElements<GetChallengesDetailProps<{}, "challenges">>
    & GetChallengeParticipantCountProps<{}, "challengeParticipantCount">
    & AddChallengeRouteProps
    & AddChallengeProps<{}, "addChallenge">
    & UpdateChallengeProps<{}, "updateChallenge">
    & GetApplicationsProps<{}, "applications">
    & GetChallengeTagsProps<{}, "tags">
    & AddChallengesRouteProps
    & SendMilestoneEmailProps<{}, "sendMilestoneEmail">;
