import { UserApplicationFilter } from "@graphql2/types";

// Antd select components will only accept a string as value.
// This functions will convert `${applicationId}_${roleId}` to the correct filter input
export const createApplicationsFilter = (filterRoles: string[]): UserApplicationFilter[] => {
    return filterRoles.map(filterRole => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [roleName, applicationId, roleId] = filterRole.split("_");
        const withoutRole = roleId === "none";
        return {
            applicationId,
            roleId: withoutRole ? undefined : roleId,
            withoutRole
        };
    });
};
