import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { getAmbassadorConfigCount } from "@graphql2/graphql_op";
import { AmbassadorConfigFilter, GetAmbassadorConfigCountProps, GetAmbassadorConfigsProps, RemoveAmbassadorConfigProps, withGetAmbassadorConfigCount, withGetAmbassadorConfigs, withRemoveAmbassadorConfig } from "@graphql2/types";
import { AmbassadorsPage } from "@pages/ambassadorsPage/ambassadorsPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

const createAmbassadorPageFilter = (objectFilter: any): AmbassadorConfigFilter => {
    const createdFilter: AmbassadorConfigFilter = {};
    if (objectFilter.isPrimary) {
        createdFilter.sequence = 0;
    }

    return createdFilter;
};

interface AmbassadorsPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: AmbassadorConfigFilter;
}

export const withAmbassadorConfigs = withGetAmbassadorConfigs({
    name: "ambassadorConfigs",
    options({ filter, paging, sort }: AmbassadorsPageProps) {
        return {
            variables: {
                filter,
                paging,
                sort
            }
        };
    }
});

export const withAmbassadorConfigCount = withGetAmbassadorConfigCount({
    name: "count",
    options({ filter }: AmbassadorsPageProps) {
        return {
            variables: {
                filter
            }
        };
    }
});

export const withRemoveAmbassadorConfigMutation = withRemoveAmbassadorConfig({
    name: "removeAmbassadorConfig",
    options: {
        refetchQueries: () => [
            "getAmbassadorConfigs",
            {
                query: getAmbassadorConfigCount,
                variables: {
                    filter: {}
                }
            }
        ],
        awaitRefetchQueries: true
    }
});

export const AmbassadorsPageContainer = compose<React.ComponentClass>(
    withRouter,
    withParseDynamicFilterBar(createAmbassadorPageFilter),
    withAmbassadorConfigs,
    withRemoveAmbassadorConfigMutation,
    withAmbassadorConfigCount
)(AmbassadorsPage);

export type AmbassadorsPageProps =
    & AmbassadorsPageFilterBarProps
    & GetAmbassadorConfigsProps<{}, "ambassadorConfigs">
    & GetAmbassadorConfigCountProps<{}, "count">
    & RemoveAmbassadorConfigProps<{}, "removeAmbassadorConfig">
    & RouteComponentProps;
