/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
import { requiredrule } from "@components/form/requiredRule";
import { FormElementField } from "@components/formContentFields/formElement";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { RightMenu, SideBar } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { FormConditionFields } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/structure/challengeFormConditionStructureStyle";
import { InjectedIntl } from "@utils/override.types";
import { Form, Menu } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { formElementFactory } from "../../../../factories/formElementFactory";

export interface EditChallengeFormConditionState {
}

export interface DeleteFormConditionFieldProps {
    condition: ChallengeFormConditionFormValues;
    elementIndex?: number;
    optionIndex?: number;
    elementType: string;
}

export interface ChallengeFormConditionStructureProps {
    condition: ChallengeFormConditionFormValues;
    form: FormInstance;
    intl: InjectedIntl;
    hidden: boolean;
    prefix: (string | number)[];
    index: number;
    onBlur(): void;
}

export const ChallengeFormConditionStructure: React.FC<ChallengeFormConditionStructureProps> = (props) => {
    const { form, condition, onBlur, intl, hidden, prefix } = props;

    const sharedProps = {
        onBlur,
        intl,
        rules: [requiredrule]
    };

    const addFormElement = (data, i: number) => {
        if (!condition || isNaN(condition.sequence)) {
            return;
        }

        onBlur();

        return formElementFactory({
            type: data.key,
            index: i
        });
    };

    return (
        <Form.List name={[...prefix, "elements"]}>
            {(fields, { add, remove, move }) => {
                const formElements = form.getFieldValue([...prefix, "elements"]);
                const content = (
                    <FormConditionFields hidden={hidden}>
                        {(formElements || []).map((element, i) => {
                            if (!element.type) {
                                return;
                            }
                            return (
                                <FormElementField
                                    key={i}
                                    condition={condition}
                                    deleteFormElement={() => remove(i)}
                                    form={form}
                                    index={i}
                                    moveFormElement={move}
                                    prefix={[...prefix, "elements", i]}
                                    {...element}
                                    {...sharedProps}
                                />
                            );
                        })}
                    </FormConditionFields>
                );

                const sidebar = (
                    <SideBar hidden={hidden}>
                        <RightMenu
                            className="sider-right"
                            mode="inline"
                        >
                            <FormattedMessage
                                id="inputFields"
                                tagName="h3"
                            />

                            <Menu.Item
                                key="checkbox"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="checkbox" />
                            </Menu.Item>

                            <Menu.Item
                                key="radio"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="radio" />
                            </Menu.Item>

                            <Menu.Item
                                key="text"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="text" />
                            </Menu.Item>

                            <Menu.Item
                                key="textarea"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="textArea" />
                            </Menu.Item>

                            <Menu.Item
                                key="date"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="date" />
                            </Menu.Item>

                            <Menu.Item
                                key="select"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="select" />
                            </Menu.Item>

                            <Menu.Item
                                key="number"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="number" />
                            </Menu.Item>

                            <Menu.Item
                                key="upload"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="fileUpload" />
                            </Menu.Item>

                            <FormattedMessage
                                id="contentBlocks"
                                tagName="h3"
                            />

                            <Menu.Item
                                key="p"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="content" />
                            </Menu.Item>

                            <Menu.Item
                                key="a"
                                onClick={(e) => add(addFormElement(e, formElements.length))}
                            >
                                <FormattedMessage id="link" />
                            </Menu.Item>

                        </RightMenu>
                    </SideBar>
                );
                return (
                    <React.Fragment>
                        {content}
                        {sidebar}
                    </React.Fragment>
                );
            }}
        </Form.List>
    );
};
