export interface WidgetItemFactoryProps {
    widgetType: "statistics" | "caroussel";
    type: string;
    backgroundOption?: "color" | "image";
}

export const widgetItemFactory = (props: WidgetItemFactoryProps) => {
    const { widgetType, type } = props;

    if (widgetType === "caroussel") {
        return {
            type,
            backgroundOption: "image"
        };
    }

    if (widgetType === "statistics") {
        return {
            statisticType: type
        };
    }
};
