import uuidv4 from "uuid/v4";

export interface FormConditionFactoryProps {

}

export const formConditionFactory = (index: number) => {
    return {
        id: uuidv4(),
        type: "form",
        startTime: undefined,
        endTime: undefined,
        image: "",
        sequence: index,
        formTemplate: {
            id: "",
            title: {},
            errorMessages: {},
            elements: []
        }
    };
};
