import "@babel/polyfill";
import * as OfflinePluginRuntime from "@lcdp/offline-plugin/runtime";
import * as React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import "unfetch/polyfill";
import { App } from "./app";

OfflinePluginRuntime.install({
    onUpdateReady: () => {
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        window.location.reload();
    }
});

const reactContainer = document.getElementById("app-container");

render(
    <AppContainer>
        <App />
    </AppContainer>,
    reactContainer
);

// Hot Module Replacement API
if (module.hot) {
    module.hot.accept(() => {
        // eslint-disable-next-line global-require
        const NextApp = require<{ default: typeof App; }>("./app").default;
        render(
            <AppContainer>
                <NextApp />
            </AppContainer>,
            reactContainer
        );
    });
}
