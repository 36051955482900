import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { EventParticipantFragment } from "@graphql2/types";
import { getText } from "@utils/getText";
import { Avatar, Button, Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface ParticipantsTableData extends TableData {
    approved: boolean;
    registrationComplete: boolean;
    onWaitingList: boolean;
    participatedOn: number;
    avatar: string;
    name: string;
    email: string;
    userId: string;
    eventId: string;
    subEventId: string;
    subEvent: EventParticipantFragment["subEvent"] | null | undefined;
}

export interface ParticipantsTableProps extends TableComponentProps {
    dataSource: ParticipantsTableData[];
    handlers: TableHandlers & {
        updateEventApproval(userId: string, eventId: string, subEventId: string, approved: boolean): void;
        removeEventParticipation(userId: string, eventId: string, subEventId: string): void;
    };
}

export class ParticipantsTable extends React.Component<ParticipantsTableProps> {
    public render() {
        return (
            <Table
                pagination={{ showSizeChanger: true, showQuickJumper: true }}
                rowKey="id"
                {...this.props}
            >
                <Column
                    key="approved"
                    dataIndex="approved"
                    render={this.renderApproved}
                    sorter={(a: ParticipantsTableData, b: ParticipantsTableData) => Number(b.approved) - Number(a.approved)}
                    title={<FormattedMessage id="approved" />}
                    width={100}
                />
                <Column
                    key="registrationComplete"
                    dataIndex="registrationComplete"
                    render={this.renderRegistrationComplete}
                    sorter={(a: ParticipantsTableData, b: ParticipantsTableData) => Number(b.registrationComplete) - Number(a.registrationComplete)}
                    title={<FormattedMessage id="registrationComplete" />}
                />
                <Column
                    key="onWaitingList"
                    dataIndex="onWaitingList"
                    render={this.renderOnWaitingList}
                    sorter={(a: ParticipantsTableData, b: ParticipantsTableData) => Number(b.onWaitingList) - Number(a.onWaitingList)}
                    title={<FormattedMessage id="onWaitingList" />}
                />
                <Column
                    key="avatar"
                    dataIndex="avatar"
                    render={this.renderAvatar}
                    width={30}
                />
                <Column
                    key="email"
                    dataIndex="email"
                    render={this.renderEmail}
                    sorter={(a: ParticipantsTableData, b: ParticipantsTableData) => a.email.localeCompare(b.email)}
                    title={<FormattedMessage id="email" />}
                />
                <Column
                    key="name"
                    dataIndex="name"
                    sorter={(a: ParticipantsTableData, b: ParticipantsTableData) => a.name.localeCompare(b.name)}
                    title={<FormattedMessage id="name" />}
                />
                <Column
                    key="subevent"
                    dataIndex="subEventId"
                    render={this.renderSubEvent}
                    title={<FormattedMessage id="subevent" />}
                    width={100}
                />
                <Column
                    key="participatedOn"
                    dataIndex="participatedOn"
                    render={this.renderTime}
                    title={<FormattedMessage id="participatedOn" />}
                    width={150}
                />
                <Column
                    key="handlers"
                    dataIndex=""
                    render={(_, row: ParticipantsTableData) => {
                        const { handlers } = this.props;
                        const { userId, eventId, subEventId, approved } = row;

                        if (!userId || !eventId || !subEventId) {
                            return null;
                        }

                        return {
                            children: (
                                <Flex
                                    alignItems="center"
                                    gap={10}
                                    justifyContent="flex-end"
                                >
                                    <Popconfirm
                                        cancelText="No"
                                        okText="Yes"
                                        title={<FormattedMessage id={`event.participation.${approved ? "deny" : "approve"}Confirm`} />}
                                        onConfirm={() => handlers.updateEventApproval(userId, eventId, subEventId, !approved)}
                                    >
                                        <Button
                                            danger={approved}
                                            size="small"
                                        >
                                            <FormattedMessage id={`event.participation.${approved ? "deny" : "approve"}`} />
                                        </Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        cancelText="No"
                                        okText="Yes"
                                        title={<FormattedMessage id="event.participation.deleteConfirm" />}
                                        onConfirm={() => handlers.removeEventParticipation(userId, eventId, subEventId)}
                                    >
                                        <Button
                                            danger
                                            size="small"
                                            type="primary"
                                        >
                                            <FormattedMessage id="event.participation.delete" />
                                        </Button>
                                    </Popconfirm>
                                </Flex>
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                    title=""
                    // width={120}
                />
            </Table>
        );
    }

    private renderApproved(approved) {
        return approved
            ? <CheckCircleFilled className="green" />
            : <MinusCircleFilled />;
    }

    private renderRegistrationComplete(registrationComplete) {
        return registrationComplete
            ? <CheckCircleFilled className="green" />
            : <MinusCircleFilled />;
    }

    private renderOnWaitingList(onWaitingList) {
        return onWaitingList
            ? <CheckCircleFilled className="green" />
            : <MinusCircleFilled />;
    }

    private renderAvatar(avatar: string, row) {
        return (
            <Avatar
                shape="square"
                src={avatar}
            >
                {!avatar && row.name && row.name.split(" ").map(r => r.substr(0, 1))}
            </Avatar>
        );
    }

    private renderEmail(email: string, row) {
        return <Link to={`/users/detail/${row.userId}`}>{email}</Link>;
    }

    private renderSubEvent(_: any, record: ParticipantsTableData): string {
        const { subEvent } = record;

        return subEvent && subEvent.title ? getText(subEvent.title) : "";
    }

    private renderTime(time: number) {
        return moment(time).format("DD/MM/YY HH:mm");
    }
}
