/* eslint-disable jsx-a11y/label-has-associated-control */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { ContentSettingsCardProps } from "@pages/addContentsPage/general/contentSettingsCardContainer";
import { AutoComplete, Card, Checkbox, Form, Select, Switch } from "antd";
import { autobind } from "core-decorators";
import * as environment from "environment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SwitchWrapper } from "./contentSettingsCardStyles";

export interface ContentSettingsFormValues {
    publishOn: Moment;
    expiresOn: Moment;
    isPublic: boolean;
    isDraft: boolean;
    roles?: string[] | null;
    type: string;
    url?: string;
    tags?: string[];
    userId?: string | null;
    featured: boolean;
    enabledLocales: string[];
    disableComments: boolean;
}

export interface ContentSettingsCardState {
}

@autobind
export class ContentSettingsCard extends React.Component<ContentSettingsCardProps, ContentSettingsCardState> {
    public state: ContentSettingsCardState = {
    };

    public render() {
        const { form, hidden, disabled, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="content.settings" />}
                />
                {this.renderGeneral(form, sharedProps)}
            </Card>
        );
    }

    private renderGeneral(form, sharedProps) {
        const { contentTags, onBlur, setEnabledLocales, resetEnabledLocales } = this.props;

        return (
            <Flex direction="column">
                <h3><FormattedMessage id="content.status" /></h3>
                {this.renderMode(sharedProps)}
                <h3><FormattedMessage id="content.specifications" /></h3>
                {this.renderSpecifications(sharedProps)}
                <h3><FormattedMessage id="visibility" /></h3>
                <Flex alignItems="end">
                    <Flex direction="column" margin="0 8px 0 0">
                        <DatePickerField
                            name="publishOn"
                            validateTrigger={["onChange", "onSubmit"]}
                            rules={[
                                {
                                    validator: this.validateExpiresOn
                                },
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                            label={<FormattedMessage id="publishOn" />}
                        />
                    </Flex>
                    <Flex direction="column" margin="0 8px 0 0">
                        <DatePickerField
                            name="expiresOn"
                            validateTrigger={["onChange", "onSubmit"]}
                            rules={[
                                {
                                    validator: this.validateExpiresOn
                                },
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                            label={<FormattedMessage id="expiresOn" />}
                        />
                    </Flex>
                    <Flex direction="column" margin="0 8px 0 0">
                        <SelectField
                            name="enabledLocales"
                            placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                            label={<FormattedMessage id="enabledLocales" />}
                            id="enabledLocales"
                            mode="multiple"
                            {...sharedProps}
                            onChange={(value) => {
                                onBlur();
                                if (value.length) {
                                    setEnabledLocales(value as string[]);
                                } else {
                                    resetEnabledLocales();
                                }
                            }}
                        >
                            {environment.languages.map(lang => <Select.Option key={lang} value={lang}>{lang}</Select.Option>)}
                        </SelectField>
                    </Flex>
                    <SwitchWrapper direction="column" margin="0 8px 0 0">
                        <Form.Item
                            name={["isPublic"]}
                            valuePropName="checked"
                        >
                            <Switch
                                {...sharedProps}
                                checkedChildren={<FormattedMessage id="challenges.isPublic" />}
                                unCheckedChildren={<FormattedMessage id="challenges.isPrivate" />}
                            />
                        </Form.Item>
                    </SwitchWrapper>
                    <Form.Item
                        name="disableComments"
                        valuePropName="checked"
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="disableComments" />
                        </Checkbox>
                    </Form.Item>
                </Flex>
                <h3><FormattedMessage id="content.tagsAndAuthors" /></h3>
                <Flex>
                    <Flex direction="column" margin="0 8px 0 0">
                        <SelectField
                            placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                            mode="multiple"
                            label={<FormattedMessage id="content.tags" />}
                            id="tags"
                            name="tags"
                            enabled={contentTags && contentTags.length}
                            {...sharedProps}
                        >
                            {contentTags ? contentTags.map(contentTag => {
                                return <Select.Option value={contentTag.id} key={contentTag.id}>{contentTag.title}</Select.Option>;
                            }) : null}
                        </SelectField>
                    </Flex>
                    <Flex direction="column" margin="0 8px 0 0">
                        {this.renderAuthorPicker(sharedProps)}
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    private validateExpiresOn(rule, value: Moment | undefined, callback: (error?: string | JSX.Element) => void) {
        const publishOn = this.props.form.getFieldValue(["publishOn"]);
        const expiresOn = this.props.form.getFieldValue(["expiresOn"]);
        if ((publishOn && expiresOn) && publishOn.valueOf() <= expiresOn.valueOf()) {
            return callback();
        }

        callback(<FormattedMessage id="form.error.expireDateMustBeLaterThanPublishDate" />);
    }

    private renderMode(sharedProps) {
        return (
            <Flex>
                <Form.Item
                    name="isDraft"
                    valuePropName="checked"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="content.isDraft" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="featured"
                    valuePropName="checked"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="content.featured" />
                    </Checkbox>
                </Form.Item>
            </Flex>
        );
    }

    private renderAuthorPicker(sharedProps) {
        const { users, singleUser, setLocalState } = this.props;

        return (
            <SelectField
                label={<FormattedMessage id="content.author" />}
                onSearch={userSearch => {
                    setLocalState({
                        filter: {
                            userSearch
                        }
                    });
                }}
                showSearch
                allowClear
                placeholder="Select users"
                name={["userId"]}
                filterOption={false}
                {...sharedProps}
            >
                {users && users.users && Array.isArray(users.users) ? (
                    users.users.map(u => <Select.Option value={u.id} key={u.id}>{u.displayName}</Select.Option>)
                ) : singleUser && singleUser.users && singleUser.users[0] && singleUser.users[0].id && (
                    <Select.Option value={singleUser.users[0].id} key={singleUser.users[0].id}>{singleUser.users[0].displayName}</Select.Option>
                )}
            </SelectField>
        );
    }

    private renderSpecifications(sharedProps) {
        const { form, roles } = this.props;

        return (
            <Flex>
                <Flex direction="column" margin="0 8px 0 0">
                    <Field
                        type="text"
                        name="url"
                        label={<FormattedMessage id="url" />}
                        id="url"
                        {...sharedProps}
                    />
                </Flex>
                <Flex direction="column" margin="0 8px 0 0">
                    <label><FormattedMessage id="type" /></label>
                    <Form.Item
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                    >
                        <AutoComplete
                            options={(environment.contentTypes || []).map(type => ({ value: type, label: type }))}
                            value={form.getFieldValue("type")}
                        />
                    </Form.Item>
                </Flex>
                <Flex direction="column">
                    <SelectField
                        placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                        label={<FormattedMessage id="webshop.roles" />}
                        id="roles"
                        name="roles"
                        mode="multiple"
                        filterOption={(input, option) => option && Object.values(option).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                        {...sharedProps}
                    >
                        {roles.map(role => <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>)}
                    </SelectField>
                </Flex>
            </Flex>
        );
    }
}
