import { FieldInfo } from "@components/field/fieldStyle";
import { Col } from "antd";
import React from "react";
import { CardSubtitle } from "../styles";
import { formatName } from "../utils";

type NameAndDescriptionProps = {
    label?: string | number;
    description?: string;
}

export const NameAndDescription: React.FC<NameAndDescriptionProps> = ({ description, label }) => {
    const labelStarCase = formatName(label?.toString());
    return (
        <Col>
            <CardSubtitle>
                {labelStarCase}
            </CardSubtitle>
            {
                description && (
                    <FieldInfo>{description}</FieldInfo>
                )
            }
        </Col>
    );
};
