import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { GenericWidgetStyle } from "../genericWidget/genericWidgetStyle";
import { WidgetProps } from "../models";
import { WidgetConfigStyle } from "../widgetStyle";
import { CallToActionTextField } from "./fields/callToActionTextField";
import { TitleDescriptionFields } from "./fields/titleDescriptionFields";
import { UrlImageFields } from "./fields/urlImageFields";

export interface PopupWidgetProps extends WidgetProps { }

export const PopupWidget: React.FC<PopupWidgetProps> = (props) => {
    const { applications, widgetId, errors, form } = props;

    const prefix = ["widgets", widgetId];

    return (
        <GenericWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage
                    id="widget.title"
                    tagName="h3"
                />
                <TitleDescriptionFields
                    languageErrors={errors && (errors.title || errors.description)}
                    prefix={prefix}
                />
                <FormattedMessage
                    id="widget.settings"
                    tagName="h3"
                />
                <CallToActionTextField
                    languageErrors={errors && errors.callToActionText}
                    prefix={prefix}
                />
                <UrlImageFields
                    form={form}
                    prefix={prefix}
                />
                <RolesDropdown
                    options={applications}
                    widgetId={widgetId}
                />
            </WidgetConfigStyle>
        </GenericWidgetStyle>
    );
};
