import { Layout } from "antd";
import styled from "styled-components";

export const MainLayoutContent = styled(Layout.Content)`
    .ant-layout-header {
        background: #fff;
        font-size: 1.2em;
        font-weight: 600;

        padding: 5px 39px;
        height: auto;
        & + .ant-tabs {
            background: #fff;
            padding: 0px 39px;
            .ant-tabs-bar {
                margin: 0;
            }
        }
    }

    .ant-card {
        margin: 26px 39px;
    }
`;

export const MainLayout = styled(Layout)<{ push?: number; }>`
    ${({ push }) => push && `margin-left: ${push}px;`}
`;
