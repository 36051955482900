import { WidgetProps } from "@components/dashboard/widgets/models";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { compose } from "redux";
import { withGetChallengeTitles, GetChallengeTitlesProps } from "@graphql2/types";
import { ChallengeVisualisationWidget } from "./challengeVisualisationWidget";

export interface ChallengeVisualisationWidgetContainerProps extends WidgetProps {
    challengeId?: string;
}

const withChallenges = withGetChallengeTitles({
    name: "challenges",
    options(ownProps: FormInstance & ChallengeVisualisationWidgetContainerProps) {
        if (!ownProps.challengeId) {
            return {};
        }
        return {
            variables: {
                filter: {
                    ids: [ownProps.challengeId]
                }
            }
        };
    },
    skip(props: FormInstance & ChallengeVisualisationWidgetContainerProps) {
        return !props.challengeId;
    }
});

export const ChallengeVisualisationWidgetContainer = compose<React.ComponentClass<ChallengeVisualisationWidgetContainerProps>>(
    withChallenges
)(ChallengeVisualisationWidget);

export type ChallengeVisualisationWidgetProps =
    ChallengeVisualisationWidgetContainerProps
    & FormInstance
    & MaybeElements<GetChallengeTitlesProps<{}, "challenges">>;
