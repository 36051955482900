import { DatePicker, Layout, Menu } from "antd";
import styled from "styled-components";

const { Content, Sider } = Layout;

export const SideBar = styled(Sider)`
    width: 130px;
    background-color: #fff;
`;

export const Label = styled.span`
    display: block;
`;

export const LeftMenu = styled(Menu)`
    height: 100%;

    li.ant-menu-item.ant-menu-item-selected {
        background-color: transparent;
    }
`;

export const RightMenu = styled(Menu)`
    height: 100%;
    text-align: right;
    border-right: none;

    h3 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 40px;
        font-family: "PingFang SC", sans-serif;
    }
`;

export const FormConditionFields = styled(Content)`
    padding: 0 30px;
`;

export const ChallengeFormConditionLayout = styled(Layout)`
    padding: 24px 0;
    background: #fff;
    border-top: "1px solid #d5d5d5";
    display: flex;
    flex-flow: row;
`;

export const DatePickerStretched = styled(DatePicker)`
    padding: 0;
    width: 100%;
`;
