import { Flex } from "@components/flex/flex";
import { FormTemplateTable } from "@components/formTemplates/formTemplatesTable";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { FormTemplateFragment } from "@graphql2/types";
import { FormsPageProps } from "@pages/formsPage/formsPageContainer";
import { getText } from "@utils/getText";
import { readFile } from "@utils/readFile";
import { Button, Card, Upload, message } from "antd";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export const FormsPage: React.FC<FormsPageProps> = (props) => {
    const intl = useIntl();
    const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 });

    const handleUpload = async (info: { file: File; }) => {
        const data = await readFile<FormTemplateFragment>(info.file);
        props.history.push("/event/forms/add", { import: data });
    };

    const removeFormTemplate = async (id: string) => {
        try {
            await props.removeFormTemplate({ variables: { id } });
            message.success(intl.formatMessage({ id: "templateRemoved" }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "templateRemoveFailed" }));
        }
    };

    const { history, formTemplatesQueryResult } = props;
    const { formTemplates, loading: loadingFormTemplates } = formTemplatesQueryResult;

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="formTemplates.overview"
                        tagName="h1"
                    />
                    <div>
                        <Upload
                            accept="application/json"
                            customRequest={handleUpload}
                        >
                            <Button
                                className="headerButton"
                                type="primary"
                            >
                                <FormattedMessage id="formTemplate.import" />
                            </Button>
                        </Upload>
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => history.push("/event/forms/add")}
                        >
                            <FormattedMessage id="formTemplate.addFormTemplate" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card title={<FormattedMessage id="forms" />}>
                <TableContainer
                    dataSource={formTemplates
                        ? formTemplates.map((formTemplate) => ({
                            id: formTemplate ? formTemplate.id : "",
                            title: formTemplate ? getText(formTemplate.title || {}) : ""
                        }))
                        : []}
                    handlers={{
                        removeFormTemplate
                    }}
                    loading={loadingFormTemplates}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: formTemplates?.length
                    }}
                    TableComponent={FormTemplateTable}
                    onChange={(newPagination) => setPagination({
                        current: newPagination.current !== pagination.current ? newPagination.current : 1,
                        pageSize: newPagination.pageSize
                    })}
                />
            </Card>
        </div>
    );
};
