import { Table, Popconfirm, Col } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableIcon } from "@components/table/tableStyle";

export interface SocialTagsTableData extends TableData {
    tag: string;
}

export interface SocialTagsTableProps extends TableComponentProps {
    dataSource: SocialTagsTableData[];
    handlers: TableHandlers & {
        removeTag(tag: string): void;
        updateTag(tag: string): void;
    };
}

export class SocialTagsTable extends React.Component<SocialTagsTableProps> {
    public get socialTagColumns(): ColumnProps<SocialTagsTableData>[] {
        return [
            {
                key: "tag",
                title: <FormattedMessage id="tag" />,
                dataIndex: "tag"
            },
            {
                title: " ",
                key: "handlers",
                dataIndex: "",
                width: 120,
                render: (text, row, index) => {
                    const { handlers } = this.props;

                    return {
                        children: (
                            <Flex justifyContent="flex-end" alignItems="center">
                                <a onClick={() => handlers.updateTag(row.tag)}>
                                    <TableIcon as={EditOutlined} />
                                </a>
                                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeTag(row.tag)} okText="Yes" cancelText="No">
                                    <TableIcon as={DeleteOutlined} />
                                </Popconfirm>
                            </Flex>
                        ),
                        props: {
                            colSpan: 2
                        }
                    };
                }
            }
        ];
    }

    public render() {
        return <Table pagination={{ showSizeChanger: true, showQuickJumper: true }} columns={this.socialTagColumns} rowKey="id" {...this.props} />;
    }
}
