import { FormTemplateOptionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";

export interface FormElementFactoryProps {
    type: string;
    index: number;
}

export const formElementFactory = (props: FormElementFactoryProps) => {
    const { type, index } = props;

    let options: FormTemplateOptionFormValues[] | undefined;

    if (type === "dropdown" || type === "radio") {
        options = [];
    }

    return {
        type,
        property: `${type}${index}`,
        options,
        defaultValue: "",
        title: {},
        required: false
    };
};
