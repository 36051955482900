import * as React from "react";
import { compose } from "redux";

import { withGetEventTags, GetEventTagsProps } from "@graphql2/types";
import { ExportProps, exportFactory } from "./export";
import { withLocalState, ExportContainerProps } from "./exportContainer";

export const withEventTags = withGetEventTags();

export const ExportEventTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withEventTags
)(exportFactory({ targetProp: ["data", "eventTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportEventTagContainerProps = ExportContainerProps & GetEventTagsProps;
