import { SelectField } from "@components/field/selectField";
import { BorderedRow } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCardStyle";
import { Col, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTIVITY_TYPES, UNIT_TYPES } from "../../../../../../constants";

export interface ConditionsFormProps {
    hidden: boolean;
    form: FormInstance;
    onBlur(): void;
}
export const ConditionsForm: React.FC<ConditionsFormProps> = (props) => {
    const { hidden, ...sharedProps } = props;

    return (
        <BorderedRow
            gutter={16}
            hidden={hidden}
        >
            <Col span={8}>
                <SelectField
                    label={<FormattedMessage id="type" />}
                    mode="multiple"
                    name={["endless", "activityTypes"]}
                    placeholder="Type"
                    {...sharedProps}
                >
                    {ACTIVITY_TYPES.map((activityType, key) => (
                        <Select.Option
                            // eslint-disable-next-line react/no-array-index-key
                            key={key.toString()}
                            value={activityType.key}
                        >
                            {activityType.value}
                        </Select.Option>
                    ))}
                </SelectField>
            </Col>
            <Col span={8}>
                <SelectField
                    label={<FormattedMessage id="unitType" />}
                    name={["endless", "unitType"]}
                    placeholder="Unit type"
                    {...sharedProps}
                >
                    {UNIT_TYPES.map((activityType) => activityType.values.map(value => (
                        <Select.Option
                            key={value}
                            value={value}
                        >
                            {value}
                        </Select.Option>
                    )))}
                </SelectField>
            </Col>
            <Col span={8}>
                <SelectField
                    label={<FormattedMessage id="rankingAlgorithm" />}
                    name={["endless", "rankingAlgorithm"]}
                    placeholder="Ranking algorithm"
                    {...sharedProps}
                >
                    <Select.Option
                        key="average"
                        value="average"
                    >
                        <FormattedMessage id="average" />
                    </Select.Option>
                    <Select.Option
                        key="total"
                        value="total"
                    >
                        <FormattedMessage id="total" />
                    </Select.Option>
                </SelectField>
            </Col>
        </BorderedRow>
    );
};
