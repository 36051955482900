import { EventsGroupTable } from "@components/eventsGroup/eventsGroupTable";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { translateEventsGroups } from "@utils/mappers/eventGroupMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Button, Card } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EventGroupPageProps } from "./eventGroupPageContainer";

export const EventGroupPage: React.FC<EventGroupPageProps> = (props) => {
    const { history, eventGroups, eventGroupCount, eventTitles, removeEventGroup, paging } = props;

    const updateUrlQuery = useUpdateQuery({ replace: true });
    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const removeEventGroupHandler = async (id: string) => {
        await removeEventGroup({ variables: { id } });
    };

    const translatedEventsGroups = React.useMemo(() => {
        return translateEventsGroups(eventGroups?.eventGroups, eventTitles?.events);
    }, [eventGroups?.eventGroups, eventTitles?.events]);

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage id="eventsGroup.overview" tagName="h1" />
                    <Button
                        onClick={() => history.push("/event/groups/add")}
                        className="headerButton"
                        type="primary"
                    >
                        <FormattedMessage id="eventsGroup.addGroup" />
                    </Button>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: eventGroupCount.eventGroupCount ?? 0
                    }}
                    onChange={updateTableSettings}
                    loading={eventGroups?.loading || eventGroupCount.loading || eventTitles?.loading}
                    TableComponent={EventsGroupTable}
                    dataSource={translatedEventsGroups}
                    handlers={{ removeEventGroup: removeEventGroupHandler }}
                />
            </Card>
        </div>
    );
};
