import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useEnabledLang } from "@context/enabledLangContext/context";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { Col, Row, Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { VisualisationdGridStyle } from "./editChallengeLeaderboardVisualisationStyle";
import { ProfileFieldsNameMapper, SelectedProfileFieldsTranslationsMapper } from "./profileFIeldsForms";

interface GlobalLeaderboardVisualisationFieldsProps {
    form: FormInstance;
    index: number;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    onChange(): void;
    changeSelectedLanguage(languageCode: string): void;
}

export const GlobalLeaderboardVisualisationFields: React.FC<GlobalLeaderboardVisualisationFieldsProps> = (props) => {
    const { index, activeLanguage, languageErrors, changeSelectedLanguage, onChange, form } = props;

    const { enabledLanguages } = useEnabledLang();

    const sharedProps = {
        onBlur: onChange
    };

    const descriptionField = (descriptionProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.globalSettings.description.${descriptionProps.language}`}
            hidden={descriptionProps.hidden}
            id={`visualisations.globalSettings.description.${descriptionProps.language}`}
            label={<FormattedMessage id="description" />}
            name={["visualisations", index, "globalSettings", "description", descriptionProps.language]}
            {...descriptionProps.sharedProps}
        />
    );

    const existingConditions = form.getFieldValue("conditions");
    const existingUnitTypes: string[] = Array.from(
        new Set(existingConditions.filter(condition => !condition.formTemplate).map(c => c.unit))
    );

    const langFieldProps: ChallengeLanguageFieldProps[] = enabledLanguages.map(language => ({
        form,
        sharedProps,
        defaultValues: {},
        language: language.code,
        hidden: language.code !== activeLanguage
    }));

    const conditionPrefixName = ["visualisations", index, "conditions", 0];
    const getlanguageErrorsForColumnNames = (input: LanguageErrors|undefined) => {
        if (!input) {
            return undefined;
        }

        const errors = {};

        Object.keys(input.columns).forEach(column => {
            Object.keys(input.columns[column].header).forEach(lang => {
                if (!errors[lang]) {
                    errors[lang] = input.columns[column].header[lang];
                }
            });
        });

        return errors;
    };

    return (
        <React.Fragment>
            <VisualisationdGridStyle>
                <Row
                    align="top"
                    gutter={30}
                >
                    <Col span={14}>
                        <Flex
                            alignItems="space-between"
                            direction="column"
                        >
                            <FormattedMessage
                                id="challenge.visualisations.leaderboard.unitFilter"
                                tagName="h1"
                            />
                            <FormattedMessage
                                id="challenge.visualisations.leaderboard.unitFilter.description"
                                tagName="p"
                            />
                            <SelectField
                                allowClear
                                id={`visualisations.${index}.availableUnitTypeRankings`}
                                label={<FormattedMessage id="unitType" />}
                                mode="multiple"
                                name={["visualisations", index, "availableUnitTypeRankings"]}
                            >

                                {existingUnitTypes.map(u => (
                                    <Select.Option
                                        key={u}
                                        value={u}
                                    >
                                        {u}
                                    </Select.Option>
                                ))}
                            </SelectField>
                            <ProfileFieldsNameMapper
                                form={form}
                                prefix={conditionPrefixName}
                                sharedProps={sharedProps}
                            />
                        </Flex>
                    </Col>
                    <Col span={10}>
                        <Flex
                            alignItems="space-between"
                            direction="column"
                        >
                            <FormattedMessage
                                id="leaderboard.info"
                                tagName="h1"
                            />
                            <TranslationTabs
                                activeLanguage={activeLanguage}
                                errors={getlanguageErrorsForColumnNames(languageErrors)}
                                handleChangeSelectedLanguage={changeSelectedLanguage}
                                languages={enabledLanguages}
                            />
                            {langFieldProps.map(descriptionField)}
                            <Form.Item
                                dependencies={[...conditionPrefixName, "columnNames"]}
                            >
                                {() => (
                                    <SelectedProfileFieldsTranslationsMapper
                                        columnsPathName={[...conditionPrefixName, "columns"]}
                                        form={form}
                                        langFieldProps={langFieldProps}
                                        prefix={conditionPrefixName}
                                        sharedProps={sharedProps}
                                    />
                                )}
                            </Form.Item>
                        </Flex>

                    </Col>
                </Row>
            </VisualisationdGridStyle>
            {/* <VisualisationdGridStyle>
                <FormattedMessage id="rows" tagName="h1" />
                <Form.Item
                    name={["visualisations", index, "item", "top", "enabled"]}
                    valuePropName="checked"
                    {...sharedProps}
                >
                    <Checkbox><FormattedMessage id="visualisation.leaderboard.highlistUsers" /></Checkbox>
                </Form.Item>
                <Field
                    name={["visualisations", index, "item", "top", "count"]}
                    label={<FormattedMessage id="visualisation.leaderboard.amountUsers" />}
                    numberType
                    min={0}
                    {...sharedProps}
                />
            </VisualisationdGridStyle> */}
        </React.Fragment>
    );
};
