import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { ChallengePage } from "@pages/challengesPage/challengesPage";
import { getFrontendRoleIdFromString } from "@utils/applicationUtils";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import { languages } from "environment";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { ChallengeFilter, GetApplicationsProps, GetChallengeCountProps, GetChallengesBasicProps, RemoveChallengeProps, withGetApplications, withGetChallengeCount, withGetChallengesBasic, withRemoveChallenge } from "../../graphql2/types";

export interface ChallengePageContainerProps {
    id: string;
}

interface ChallengesPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: ChallengeFilter;
}

const createChallengePageFilter = (objectFilter: any): ChallengeFilter => {
    const createdFilter: ChallengeFilter = {
        textSearch: objectFilter.textSearch,
        activity: objectFilter.activity,
        enabledLocales: objectFilter.enabledLocales,
        roles: (objectFilter.userRoles && [objectFilter.userRoles].flat().map(getFrontendRoleIdFromString)) || []
    };

    if (objectFilter.enabledLocales && !Array.isArray(objectFilter.enabledLocales)) {
        createdFilter.enabledLocales = [objectFilter.enabledLocales];
    }
    if (!objectFilter.enabledLocales) {
        createdFilter.enabledLocales = [...languages];
    }

    return createdFilter;
};

export const withChallenges = withGetChallengesBasic({
    options({ filter, paging, sort }: ChallengePageProps) {
        return {
            variables: {
                filter,
                paging,
                sort,
                timestamp: new Date().getTime()
            }
        };
    }
});

export const withChallengeCount = withGetChallengeCount({
    name: "count",
    options({ filter }: ChallengePageProps) {
        return {
            variables: {
                filter
            }
        };
    }
});

export const withRemoveChallengeMutation = withRemoveChallenge({
    name: "removeChallenge",
    options: {
        refetchQueries: () => ["getChallenges", "getChallengesBasic", "getChallengeCount"],
        awaitRefetchQueries: true
    }
});

const withApplications = withGetApplications({
    name: "applications"
});

export const mapStateToProps = (ownProps: GetChallengesBasicProps) => ({
    challenges: (ownProps.data && ownProps.data.challenges) || undefined
});

export const ChallengePageContainer = compose<React.ComponentClass<ChallengePageContainerProps>>(
    withRouter,
    withParseDynamicFilterBar(createChallengePageFilter, { defaultSortSettings: { sortBy: "duration.start", sort: "DESC" } }),
    withChallenges,
    withChallengeCount,
    withRemoveChallengeMutation,
    withApplications
)(ChallengePage);

export type ChallengePageProps = ChallengesPageFilterBarProps
    & ChallengePageContainerProps
    & RemoveChallengeProps<{}, "removeChallenge">
    & GetChallengesBasicProps
    & GetChallengeCountProps<{}, "count">
    & GetApplicationsProps<{}, "applications">
    & RouteComponentProps;
