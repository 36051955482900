import { AddCheckupPage } from "@pages/addCheckupPage/addCheckupPage";
import { Checkup_location_admin } from "@utils/roles";
import { FormProps } from "antd/lib/form";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { AddCheckupLocationAvailabilityProps, GetCheckupLocationAvailabilitiesProps, GetCheckupLocationsProps, GetUsersProps, UpdateCheckupLocationAvailabilityProps, withAddCheckupLocationAvailability, withGetCheckupLocationAvailabilities, withGetCheckupLocations, withGetUsers, withUpdateCheckupLocationAvailability } from "../../graphql2/types";

export interface AddCheckupPageContainerProps {
    id: string;
}

type AddCheckupRouteProps = RouteComponentProps<{ id: string; }>;

const withCheckups = withGetCheckupLocationAvailabilities({
    options: (props: AddCheckupRouteProps) => ({
        variables: {
            filter: {
                ids: props.match.params.id ? [props.match.params.id] : []
            }
        }
    }),
    skip: (props: AddCheckupRouteProps) => {
        return !props.match.params.id;
    }
});

const withUpdateCheckupLocationAvailabilityMutation = withUpdateCheckupLocationAvailability({
    name: "updateCheckupLocationAvailability"
});

const withAddCheckupLocationAvailabilityMutation = withAddCheckupLocationAvailability({
    name: "addCheckupLocationAvailability",
    options: {
        refetchQueries: ["getCheckupLocations", "getCheckupLocationCount"],
        awaitRefetchQueries: true
    }
});

const withUsers = withGetUsers({
    name: "users",
    options: () => ({
        variables: {
            filter: {
                applications: [
                    {
                        applicationId: "admin",
                        roleId: Checkup_location_admin
                    }
                ]
            },
            paging: {}
        }
    })
});

const withCheckupLocations = withGetCheckupLocations({
    name: "checkupLocations"
});

export const AddCheckupPageContainer = compose<React.ComponentClass<AddCheckupPageContainerProps>>(
    withCheckups,
    withUpdateCheckupLocationAvailabilityMutation,
    withAddCheckupLocationAvailabilityMutation,
    withCheckupLocations,
    withUsers
)(AddCheckupPage);

export type AddCheckupPageProps =
    AddCheckupPageContainerProps
    & FormProps
    & MaybeElements<GetCheckupLocationAvailabilitiesProps>
    & GetUsersProps<{}, "users">
    & GetCheckupLocationsProps<{}, "checkupLocations">
    & AddCheckupRouteProps
    & AddCheckupLocationAvailabilityProps<{}, "addCheckupLocationAvailability">
    & UpdateCheckupLocationAvailabilityProps<{}, "updateCheckupLocationAvailability">;
