import { Application } from "@graphql2/types";

export interface UserApplicationInput {
    id: string;
    roles: string[];
}

export interface ApplicationAction {
    action: "add" | "delete";
    id: string;
}

export interface RoleAction {
    action: "add" | "delete";
    role: string;
    applicationId: string;
}

export const createNewApplications = (existingApplications: Application[], applicationAction?: ApplicationAction, role?: RoleAction): UserApplicationInput[] => {
    const applicationsCopy = [...existingApplications];
    let userApplicationInput: UserApplicationInput[] = applicationsCopy.map(({ id, roles }) => ({ id, roles: roles.map(r => r.id) }));

    if (applicationAction) {
        if (applicationAction.action === "add") {
            userApplicationInput.push({
                id: applicationAction.id,
                roles: []
            });
        } else if (applicationAction.action === "delete") {
            userApplicationInput = userApplicationInput.filter(a => a.id !== applicationAction.id);
        }
    }

    if (role) {
        const applicationToChangeIndex = userApplicationInput.findIndex(a => a.id === role.applicationId);
        if (applicationToChangeIndex !== -1) {
            const target = userApplicationInput[applicationToChangeIndex];
            if (role.action === "add") {
                target.roles.push(role.role);
            } else if (role.action === "delete") {
                target.roles = target.roles.filter(r => r !== role.role);
            }
        }
    }

    return userApplicationInput;
};
