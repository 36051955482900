import { AmbassadorConfigAmbassadorsTable } from "@components/ambassador/ambassadorConfigAmbassadorsTable";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { SpinStyle } from "@components/style/globalStyles";
import { TableContainer } from "@components/table/tableContainer";
import { AdminTooltip } from "@components/tooltip/index";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { AddAmbassadorConfigPageProps } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPageContainer";
import { AddAmbassadorConfigPageStyle } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPageStyle";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { emptyAmbassadorConfig } from "@utils/emptyItems/emptyAmbassadorConfig";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { ambassadorConfigToFormValues, formValuesToAmbassadorConfig, translateAmbassadors } from "@utils/mappers/ambassadorMapper";
import { Breadcrumb, Button, Card, Form, Popconfirm, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ACTIVITY_TYPES } from "../../constants";
import { ContentCard, ContentFormValues } from "./general/contentCard";
import { GeneralSettingsCard, GeneralSettingsFormValues } from "./general/generalSettings";

export type ErrorObj = object;

export type AmbassadorErrors = {
    place: ErrorObj;
};

export interface AddAmbassadorConfigPageState {
    selectedLanguage: string;
    unsaved: boolean;
}

export type AmbassadorConfigFormValues = {
    id: string;
    heroImage?: string | null;
} & GeneralSettingsFormValues & ContentFormValues;

export const AddAmbassadorConfigPage: React.FC<AddAmbassadorConfigPageProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setErrors] = useErrors();
    const [unsaved, setUnsaved] = React.useState<boolean>(false);
    const [form] = useForm();
    const intl = useIntl();

    const onBlur = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const { ambassadorConfigs, ambassadors, editMode, match: { params: { id } }, history } = props;
    const ambassadorConfig = React.useMemo(
        () => {
            if (ambassadorConfigs && ambassadorConfigs.ambassadorConfigs && ambassadorConfigs.ambassadorConfigs[0]) {
                return ambassadorConfigToFormValues(ambassadorConfigs.ambassadorConfigs[0]);
            }

            return emptyAmbassadorConfig;
        },
        [ambassadorConfigs]
    );
    const translatedAmbassadors = React.useMemo(
        () => {
            if (ambassadors && ambassadors.ambassadors && ambassadorConfig.id) {
                return translateAmbassadors(ambassadors.ambassadors || []);
            }

            return [];
        },
        [ambassadors]
    );

    if (ambassadorConfigs?.loading || ambassadors?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if (!ambassadorConfigs?.ambassadorConfigs?.length && id) {
        props.history.push("/404");
    }

    const ambassadorsForm = form.getFieldsValue();

    const onSubmit = async () => {
        try {
            const values = await form.validateFields() as AmbassadorConfigFormValues;

            if (id) {
                await props.updateAmbassadorConfig({ variables: { ...formValuesToAmbassadorConfig(values), id } });
                handleSuccess("ambassador.ambassadorConfigUpdated", () => {
                    setUnsaved(false);
                });
                history.push("/ambassador/ambassadorConfigs");
            } else {
                await props.addAmbassadorConfig({ variables: { ...formValuesToAmbassadorConfig(values) } });
                handleSuccess("ambassador.ambassadorConfigAdded", () => {
                    setUnsaved(false);
                });
                history.push("/ambassador/ambassadorConfigs");
            }
        } catch (errors) {
            const errMsg = id ? "ambassador.ambassadorConfigUpdateFailed" : "ambassador.ambassadorConfigAddFailed";
            handleError(errMsg);
            setErrors(errors);
        }
    };

    const onFailed = (err) => {
        setErrors(err);
    };

    const removeAmbassadorConfig = async () => {
        if (id) {
            await props.removeAmbassadorConfig({ variables: { id } });
            props.history.push("/ambassador/ambassadorConfigs");
        }
    };

    const getEditLink = (ambassadorId: string) => {
        return `/ambassador/ambassadors/edit/${id}/${ambassadorId}`;
    };

    const removeAmbassador = async (ambassadorId: string) => {
        await props.removeAmbassador({ variables: { id: ambassadorId } });
    };

    const defaultProps = {
        onSubmit,
        changeSelectedLanguage,
        onBlur,
        defaultValues: ambassadorConfig
    };

    const AddAmbassadorButton = (
        <Button
            className="headerButton"
            disabled={!editMode}
            type="primary"
        >
            <FormattedMessage id="ambassador.addAmbassador" />
        </Button>
    );

    const WrappedAmbassadorButton = (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            {!editMode ? (
                <AdminTooltip
                    description={<FormattedMessage id="ambassador.addAmbassador.tooltip" />}
                    header={<FormattedMessage id="ambassador.addAmbassadorConfig" />}
                    type="info"
                >
                    {AddAmbassadorButton}
                </AdminTooltip>
            ) : (
                <Link to={`/ambassador/ambassadors/add/${id}`}>
                    {AddAmbassadorButton}
                </Link>
            )}
        </React.Fragment>
    );

    return (
        <AddAmbassadorConfigPageStyle>
            <HeaderContent>
                <HeaderActions>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/ambassador/ambassadorConfigs">
                                <FormattedMessage id="overview" />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {getText(ambassadorsForm?.title || ambassadorConfig?.title, selectedLanguage)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        {unsaved && <UnsavedChanges key="warning" />}
                        <Button
                            key="button1"
                            type="primary"
                            onClick={onSubmit}
                        >
                            <FormattedMessage id="saveChanges" />
                        </Button>
                        {
                            id
                                ? (
                                    <Popconfirm
                                        cancelText="No"
                                        okText="Yes"
                                        placement="bottomRight"
                                        title={<FormattedMessage id="deleteConfirm" />}
                                        onConfirm={removeAmbassadorConfig}
                                    >
                                        <Button
                                            danger
                                            className="headerButton"
                                        >
                                            <FormattedMessage
                                                id="ambassadorConfig.delete"
                                            />
                                        </Button>
                                    </Popconfirm>
                                )
                                : null
                        }
                    </div>
                </HeaderActions>
            </HeaderContent>
            <Form
                hideRequiredMark
                form={form}
                initialValues={ambassadorConfig}
                onFinish={onSubmit}
                onFinishFailed={onFailed}
            >
                <React.Fragment>
                    <GeneralSettingsCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        hidden={false}
                    />
                    <ContentCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        form={form}
                        languageErrors={errorsToLanguageErrors(fieldErrors)}
                    />
                    <Form.Item name="heroImage">
                        {form && (
                            <CoverImageCard
                                {...defaultProps}
                                cropSettings={{
                                    aspect: ImageRatios.DEFAULT,
                                    zoom: true,
                                    rotate: true
                                }}
                                defaultImage={defaultProps.defaultValues.heroImage || ""}
                                form={form}
                                formField="heroImage"
                                hidden={false}
                                titleId="reliveImage"
                            />
                        )}
                    </Form.Item>
                    <Card>
                        <ContentHeader
                            rightFields={[WrappedAmbassadorButton]}
                            title={<FormattedMessage id="ambassador.ambassadors" />}
                        />
                        <DynamicFilterBar fields={[
                            {
                                name: "textSearch",
                                type: "input",
                                search: true,
                                placeholder: intl.formatMessage({ id: "filter.textSearch" })
                            },
                            {
                                name: "activityTypes",
                                type: "select",
                                multiple: true,
                                options: ACTIVITY_TYPES.map(at => ({
                                    value: at.key,
                                    name: at.value
                                })),
                                placeholder: intl.formatMessage({ id: "ambassadors.filter.activityTypes" }),
                                span: 5
                            }
                        ]}
                        />
                        <TableContainer
                            dataSource={translatedAmbassadors || []}
                            handlers={{
                                getEditLink,
                                removeAmbassador
                            }}
                            loading={ambassadors?.loading}
                            TableComponent={AmbassadorConfigAmbassadorsTable}
                        />
                    </Card>
                </React.Fragment>
            </Form>
        </AddAmbassadorConfigPageStyle>
    );
};
