import styled from "styled-components";

interface ChallengeVisualisationProgressProps {
    width?: string;
}

export const ChallengeVisualisationProgress = styled.div<ChallengeVisualisationProgressProps>`
    width: ${props => props.width || "100%"};
    margin-left: 5px;

    .ant-slider-with-marks {
        margin-bottom: 1rem;
    }
`;
