import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeActivityConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityCondition";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { getText } from "@utils/getText";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ConditionTitleDataProps {
    condition?: ChallengeConditionFormValuesUnion;
}

export class ConditionTitle extends React.Component<ConditionTitleDataProps> {
    public render() {
        const { condition } = this.props;

        if (!condition) {
            return <FormattedMessage id="global" />;
        }

        if (objectTypeCheck<ChallengeFormConditionFormValues>(condition, "form")) {
            return this.renderFormConditionTitle(condition) || "";
        }

        return this.renderActivityConditionTitle(condition) || "";
    }

    private renderFormConditionTitle(condition: ChallengeFormConditionFormValues) {
        return condition && condition.formTemplate && condition.formTemplate.title && getText(condition.formTemplate.title) ? `Form: ${getText(condition.formTemplate.title)}` : `Empty form: ${condition.id}`;
    }

    /*
        See tests for cases.
        This isn't translated to make testing more easy
    */
    private renderActivityConditionTitle(condition: ChallengeActivityConditionFormValues) {
        const activity = condition.activities.join(" / ");
        const conditionVal = `${condition.value}${condition.unit} `;
        const inOneGoRepeat = condition.inOneGo && condition.inOneGo.enabled && condition.inOneGo.repeat ? `${condition.inOneGo.repeat} time(s) ` : "";
        const inOneGo = condition.inOneGo && condition.inOneGo.enabled ? "in one go " : "";
        const timeSpan = condition.timespan && condition.timespan.enabled ? `in ${condition.timespan.value} ${condition.timespan.unit}(s) ` : "";
        const repeat = condition.repeated && condition.repeated.enabled ? `repeat this ${condition.repeated.value} time(s) ` : "";
        const sequential = condition.repeated && condition.repeated.enabled && condition.repeated.sequential ? "which should be done in direct sequence " : "";
        const notSequential = condition.repeated && condition.repeated.enabled && !condition.repeated.sequential ? "but there can be time between them " : "";

        const title = `${activity} ${conditionVal}${inOneGo}${inOneGoRepeat}${timeSpan}${repeat}${sequential}${notSequential}`;
        return title === " 0 " ? `Empty activity: ${condition.id}` : `Activity: ${title}`;
    }
}
