/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form } from "antd";
import Checkbox, { CheckboxProps } from "antd/lib/checkbox";
import * as React from "react";
import { FormItemProps } from "antd/lib/form";
import { DescriptionTooltip } from "@components/tooltip/descriptionTooltip";

export interface FieldProps extends Omit<CheckboxProps, "name">, Pick<FormItemProps, "rules" | "initialValue" | "name" | "dependencies"| "shouldUpdate"> {
    label:string|JSX.Element
    required?: boolean;
    description?: string;
}

export const CheckboxField: React.FC<FieldProps> = (props) => {
    const { name, rules, dependencies, shouldUpdate, description, label, initialValue, ...selectProps } = props;

    return (
        <Form.Item
            shouldUpdate={shouldUpdate}
            name={name}
            rules={!selectProps.disabled ? rules : undefined}
            dependencies={dependencies}
            valuePropName="checked"
            initialValue={initialValue}
        >
            <Checkbox {...selectProps}>
                <span>
                    {label}
                </span>
                {
                    description && (
                        <DescriptionTooltip description={description} />
                    )
                }
            </Checkbox>
        </Form.Item>
    );
};
