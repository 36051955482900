import { DeleteOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TableIcon } from "@components/table/tableStyle";
import { AdminTooltip } from "@components/tooltip/index";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { Button, Popover, Table, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex } from "../flex/flex";

export interface ChallengeMilestonesTableProps {
    dataSource?: ChallengeMilestonesTableData[];
    deleteMilestone(index: number): void;
    editMilestone(index: number);
    testMilestoneEmailProps: MilestoneEmailTestProps;
}

type validateMilestoneEmailResult = {
    isTestable: boolean;
    tooltipText: string;
}
export interface MilestoneEmailTestProps {
    validateMilestoneEmail(milestoneId: string): validateMilestoneEmailResult;
    triggerMilestoneEmail(milestoneId: string): Promise<void>;
}

export interface ChallengeMilestonesTableData {
    id: string;
    percentageForCondition?: string | null;
    condition?: ChallengeConditionFormValuesUnion;
    hasError: boolean;
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<ChallengeMilestonesTableData>;
}

export const ChallengeMilestonesTable: React.FC<ChallengeMilestonesTableProps> = (
    {
        deleteMilestone,
        editMilestone,
        testMilestoneEmailProps: {
            validateMilestoneEmail,
            triggerMilestoneEmail
        },
        ...tableProps
    }
) => {
    const intl = useIntl();
    const getMilestoneIcon = (milestoneId: string) => {
        const { isTestable, tooltipText } = validateMilestoneEmail(milestoneId);
        return (
            <AdminTooltip
                description={<FormattedMessage id={tooltipText} />}
                header={<FormattedMessage id="challengeTabs.milestones.trigger.tooltip.header" />}
                placement="left"
                type="info"
            >
                {
                    isTestable ? (
                        <TableIcon
                            as={SendOutlined}
                            onClick={() => triggerMilestoneEmail(milestoneId)}
                        />
                    ) : (
                        <TableIcon
                            disabled
                            as={SendOutlined}
                            style={{ color: "lightGrey" }}
                        />
                    )
                }
            </AdminTooltip>
        );
    };

    const renderHandlers = (row): Handlers => ({
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                {getMilestoneIcon(row.id)}
                <TableIcon
                    as={EditOutlined}
                    onClick={
                        () => {
                            editMilestone(row.index);
                        }
                    }
                />
                <TableIcon
                    as={DeleteOutlined}
                    onClick={() => deleteMilestone(row.index)}
                />
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="percentageForCondition"
                dataIndex="percentageForCondition"
                title={<FormattedMessage id="progress" />}
                width={50}
            />
            <Column
                key="condition"
                dataIndex="condition"
                render={(c) => (
                    <ConditionTitle condition={c} />
                )}
                title={<FormattedMessage id="condition" />}
            />
            <Column
                key="id"
                dataIndex="id"
                render={milestoneId => (
                    <Popover content={<FormattedMessage id="clickToCopy" />}>
                        <Button onClick={() => {
                            navigator.clipboard.writeText(milestoneId);
                            message.success(intl.formatMessage({ id: "valueCopied" }));
                        }}
                        >
                            {milestoneId}
                        </Button>
                    </Popover>
                )}
                title={<FormattedMessage id="id" />}
                width={320}
            />
            {renderErrorColumn()}
            <Column
                dataIndex=""
                render={(_text, _row) => renderHandlers(_row)}
                title=" "
                width={90}
            />
        </Table>
    );
};
