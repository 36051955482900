import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

import { getGalleriesOverview } from "@graphql2/graphql_op";
import { AddGalleryImageProps, AddGalleryProps, GalleryFragment, GetApplicationsProps, GetGalleriesProps, GetGalleryTagsProps, RemoveGalleryImageProps, RemoveGalleryProps, UpdateGalleryImageProps, UpdateGalleryProps, withAddGallery, withAddGalleryImage, withGetApplications, withGetGalleries, withGetGalleryTags, withRemoveGallery, withRemoveGalleryImage, withUpdateGallery, withUpdateGalleryImage } from "@graphql2/types";
import { AddGalleriesPage } from "@pages/addGalleriesPage/addGalleriesPage";
import { cleanCache } from "@utils/cleanCacheHoc";

export const ADD_GALLERY_TABS = {
    general: "general",
    images: "images",
    comments: "comments"
};

export interface AddGalleriesPageContainerProps {
}

export type AddGalleriesRouteProps = RouteComponentProps<{ tab: string; id: string; }, {}, { import: GalleryFragment; }>;

export const withGalleriesQuery = withGetGalleries({
    name: "galleriesData",
    options: (props: AddGalleriesRouteProps) => ({
        variables: {
            filter: {
                ids: props.match.params.id ? [props.match.params.id] : []
            },
            paging: {}
        }
    }),
    skip: (props: AddGalleriesRouteProps) => {
        return !props.match.params.id;
    }
});

const REFETCH_QUERIES = {
    refetchQueries: [
        "getGalleriesOverview",
        "getGalleriesOverviewCount"
    ],
    awaitRefetchQueries: true
};

const withAddGalleryMutation = withAddGallery({
    name: "addGallery",
    options: {
        update: cleanCache([getGalleriesOverview])
    }
});
const withUpdateGalleryMutation = withUpdateGallery({
    name: "updateGallery"
});
const withRemoveGalleryMutation = withRemoveGallery({
    name: "removeGallery",
    options: {
        ...REFETCH_QUERIES,
        update: cleanCache([getGalleriesOverview])
    }
});
const withGetGalleryTagsQuery = withGetGalleryTags({
    name: "tagsData"
});
const withGetApplicationsQuery = withGetApplications({
    name: "applicationsData"
});
const withRemoveGalleryImageMutation = withRemoveGalleryImage({
    name: "removeGalleryImage",
    options: REFETCH_QUERIES
});
const withAddGalleryImageMutation = withAddGalleryImage({
    name: "addGalleryImage"
});
const withUpdateGalleryImageMutation = withUpdateGalleryImage({
    name: "updateGalleryImage"
});

export const AddGalleriesPageContainer = compose<React.ComponentClass<AddGalleriesPageContainerProps>>(
    withRouter,
    withGalleriesQuery,
    withAddGalleryMutation,
    withUpdateGalleryMutation,
    withRemoveGalleryMutation,
    withGetGalleryTagsQuery,
    withGetApplicationsQuery,
    withRemoveGalleryImageMutation,
    withAddGalleryImageMutation,
    withUpdateGalleryImageMutation
)(AddGalleriesPage);

export type AddGalleriesPageProps =
    AddGalleriesPageContainerProps
    & AddGalleriesRouteProps
    & GetApplicationsProps<{}, "applicationsData">
    & GetGalleryTagsProps<{}, "tagsData">
    & MaybeElements<GetGalleriesProps<{}, "galleriesData">>
    & AddGalleryProps<{}, "addGallery">
    & UpdateGalleryProps<{}, "updateGallery">
    & RemoveGalleryProps<{}, "removeGallery">
    & RemoveGalleryImageProps<{}, "removeGalleryImage">
    & AddGalleryImageProps<{}, "addGalleryImage">
    & UpdateGalleryImageProps<{}, "updateGalleryImage">;
