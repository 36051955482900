import { Alert, Form, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChallengeWidgetStyle } from "@components/dashboard/widgets/challengeWidget/challengeWidgetStyle";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { SelectField } from "@components/field/selectField";
import { ModeSelection } from "@components/dashboard/components/modeSelection/modeSelection";
import { Flex } from "@components/flex/flex";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { DynamicPreviewWrapper } from "@components/dashboard/dynamicPreview/dynamicPreviewWrapper";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { FixedChallengesFieldContainer } from "./fixedChallengesField/fixedChallengesFieldContainer";

interface ChallengeWidgetProps extends WidgetProps {

}

const DISPLAY_TYPES = ["card", "carrousel", "feature"];

export const ChallengeWidget: React.FC<ChallengeWidgetProps> = (props) => {
    const { applications, form, widgetId } = props;
    const order = ["id", "duration.start", "duration.end", "publishOn", "priority"];

    return (
        <ChallengeWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
                <SelectField
                    name={["widgets", widgetId, "displayType"]}
                    label={<FormattedMessage id="challengeWidget.displayType" />}
                    id="displayType"
                >
                    {DISPLAY_TYPES.map(type => <Select.Option value={type} key={type}>{type}</Select.Option>)}
                </SelectField>
                <ModeSelection
                    widgetId={widgetId}
                    form={form}
                    moduleName="challenge"
                />
                <Form.Item
                    dependencies={[
                        ["widgets", widgetId, "mode"]
                    ]}
                >
                    {() => {
                        const mode = form.getFieldValue(["widgets", widgetId, "mode"]);
                        return (
                            mode === "dynamic"
                                ? (
                                    <DynamicPreviewWrapper
                                        titleId="challengeWidget.dynamic"
                                        type={PreviewTypes.challenge}
                                        form={form}
                                        widgetId={widgetId}
                                        paginationOptions={{
                                            defaults: {
                                                fields: order
                                            },
                                            id: widgetId,
                                            form
                                        }}
                                    />
                                )
                                : (
                                    <FixedChallengesFieldContainer
                                        challengeOptions={{
                                            id: widgetId,
                                            form
                                        }}
                                    />
                                )
                        );
                    }}
                </Form.Item>
                <Flex className="imageInherit" fullWidth margin="15px 0 0">
                    <Alert message={<FormattedMessage id="challengeWidget.imageInherit" />} type="info" />
                </Flex>
            </WidgetConfigStyle>
        </ChallengeWidgetStyle>
    );
};
