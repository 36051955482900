import { SelectFieldLabel } from "@components/field/selectField";
import styled from "styled-components";

export const AddJourneysPageStyle = styled.div``;

export const MarginRightDiv = styled.div`
    margin-right: 1em;

    ${SelectFieldLabel} {
        margin: 0;
    }
`;
