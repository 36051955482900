/* eslint-disable react/prop-types */
import { SocialWidgetStyle } from "@components/dashboard/widgets/socialWidget/socialWidgetStyle";
import { Field } from "@components/field/field";
import { Flex } from "@components/flex/flex";
import { Checkbox, Form, Select, Spin } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { SelectField } from "@components/field/selectField";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { SocialWidgetProps } from "./socialWidgetContainer";

export const SocialWidget: React.FC<SocialWidgetProps> = props => {
    const { applications, form, widgetId, socialTags } = props;

    const setSocialTypes = (checked: boolean, type: string) => {
        const socialTypes = form.getFieldValue(["widgets", widgetId, "socialTypes"]);
        let types: string[] | undefined;
        if (checked) {
            types = [...socialTypes, type];
        } else {
            types = [...socialTypes.filter(e => e !== type)];
        }
        form.setFields([{
            name: ["widgets", widgetId, "socialTypes"],
            value: types
        }]);
    };

    return (
        <SocialWidgetStyle>
            <RolesDropdown
                widgetId={widgetId}
                options={applications}
            />
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <Form.Item label={<FormattedMessage id="socialWidget.types" />}>
                    <Flex direction="column">
                        <Flex>
                            <Checkbox onChange={e => setSocialTypes(e.target.checked, "twitter")}>
                                <FormattedMessage id="socialWidget.twitter" />
                            </Checkbox>
                        </Flex>
                        <Flex>
                            <Checkbox onChange={e => setSocialTypes(e.target.checked, "instagram")}>
                                <FormattedMessage id="socialWidget.instagram" />
                            </Checkbox>
                        </Flex>
                    </Flex>
                </Form.Item>
                <SelectField
                    label={<FormattedMessage id="socialWidget.tags" />}
                    name={["widgets", widgetId, "tags"]}
                    showArrow
                    showSearch
                    notFoundContent={socialTags && socialTags.loading ? <Spin size="small" /> : null}
                    mode="multiple"
                    id="tags"
                    filterOption={false}
                >
                    {(socialTags?.socialTags || []).filter(st => st && st.tag).map(socialTag => socialTag.tag && (
                        <Select.Option value={socialTag.tag} key={socialTag.tag || undefined}>{socialTag.tag}</Select.Option>
                    ))}
                </SelectField>
                <Field
                    name={["widgets", widgetId, "socialTypes"]}
                    id="socialTypes"
                    hidden
                />
            </WidgetConfigStyle>
        </SocialWidgetStyle>
    );
};
