import { CarousselWidgetPaneStyle } from "@components/dashboard/widgets/carousselWidget/carousselWidgetStyle";
import { Field } from "@components/field/field";
import { Flex } from "@components/flex/flex";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ImageType, ImageUpload } from "@components/imageUpload/imageUpload";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { DashboardConfigWidgetCarousselItem } from "@utils/mappers/widgets/carousselWidgetMapper";
import { Alert, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SelectField } from "../../../field/selectField";
import { FixedChallengesFieldContainer } from "../challengeWidget/fixedChallengesField/fixedChallengesFieldContainer";
import { FixedContentFieldContainer } from "../contentWidget/fixedContentField/fixedContentFieldContainer";
import { FixedEventsFieldContainer } from "../eventWidget/fixedEventsField/fixedEventsFieldContainer";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";

export interface CarousselWidgetPaneProp {
    widgetId: string;
    field: FormListFieldData;
    pane: DashboardConfigWidgetCarousselItem;
    form: FormInstance;
}

const createNewPanesWithImage = (panes: DashboardConfigWidgetCarousselItem[], index: number, image: ImageType) => {
    const paneItemCopy = panes[index];
    const updatedPaneItem = {
        ...paneItemCopy,
        background: { ...paneItemCopy.background, url: image }
    };

    const updatedPanesList = [...panes];
    updatedPanesList[index] = updatedPaneItem;
    return updatedPanesList;
};

export const CarousselWidgetPane: React.FC<CarousselWidgetPaneProp> = (props) => {
    const { form, field, pane, widgetId } = props;
    const { type, background } = pane;
    const [backgroundOptionValue, setBackgroundOptionValue] = React.useState(pane.backgroundOption);
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();

    const langFieldProps = languages.map(language => ({
        language: language.code, hidden: language.code !== selectedLanguage
    }));

    const image = background && background.url;
    const setImage = (i: ImageType) => {
        const items = form.getFieldValue(["widgets", widgetId, "items"]) || [];
        if (!items) {
            throw Error("Editing pane of non existing widget.items");
        }
        form.setFields([{
            name: ["widgets", widgetId, "items"],
            value: createNewPanesWithImage(items, field.name, i || null)
        }]);
    };

    const handleChangeBackgroundOption = (value) => {
        form.setFields([{
            name: ["widgets", widgetId, "items", field.key, "background"],
            value: undefined
        }]);
        setBackgroundOptionValue(value);
    };

    let fieldComponent: null | JSX.Element = null;
    switch (type) {
        case "content":
            fieldComponent = (
                <FixedContentFieldContainer
                    noSort
                    singleMode
                    contentOptions={{
                        id: widgetId,
                        form
                    }}
                    itemKey={field.name}
                />
            );
            break;
        case "challenge":
            fieldComponent = (
                <FixedChallengesFieldContainer
                    singleMode
                    challengeOptions={{
                        id: widgetId,
                        form
                    }}
                    itemKey={field.name}
                />
            );
            break;
        case "event":
            fieldComponent = (
                <FixedEventsFieldContainer
                    singleMode
                    eventOptions={{
                        id: widgetId,
                        form
                    }}
                    itemKey={field.name}
                />
            );
            break;
        default:
    }
    const descriptionName = languages.map(language => ["widgets", widgetId, "items", field.name, "description", language.code]);

    return (
        <CarousselWidgetPaneStyle>
            {fieldComponent}
            <TitleSubtitleDescriptionCard
                itemKey={field.name}
                prefix={[field.name]}
                widgetId={widgetId}
            />
            <Form.Item
                noStyle
                dependencies={descriptionName}
            >
                {() => {
                    const descriptions = descriptionName.map(name => form.getFieldValue(name));
                    if (descriptions.some(Boolean)) {
                        return (
                            <Alert
                                showIcon
                                message={<FormattedMessage id="carousselWidget.item.description.warning" />}
                                type="warning"
                            />
                        );
                    }
                    return null;
                }}
            </Form.Item>
            {pane.type === "caroussel" && (
                <React.Fragment>
                    <FormattedMessage
                        id="carousselWidgetPane.urlSettings"
                        tagName="h3"
                    />
                    <Field
                        id="url"
                        label={<FormattedMessage id="carousselWidgetPane.url" />}
                        name={[field.name, "url", "url"]}
                    />
                    <SelectField
                        showArrow
                        id="target"
                        label={<FormattedMessage id="carousselWidgetPane.target" />}
                        name={[field.name, "url", "target"]}
                    >
                        <Select.Option
                            key="_self"
                            value="_self"
                        >
                            <FormattedMessage id="target._self" />
                        </Select.Option>
                        <Select.Option
                            key="_blank"
                            value="_blank"
                        >
                            <FormattedMessage id="target._blank" />
                        </Select.Option>
                    </SelectField>
                </React.Fragment>
            )}
            <FormattedMessage
                id="carousselWidgetPane.backgroundSettings"
                tagName="h3"
            />
            {backgroundOptionValue && (
                <SelectField
                    showArrow
                    id="background.option"
                    label={<FormattedMessage id="carousselWidgetPane.backgroundOption" />}
                    name={[field.name, "backgroundOption"]}
                    onChange={handleChangeBackgroundOption}
                >
                    <Select.Option
                        key="_color"
                        value="color"
                    >
                        <FormattedMessage id="carousselWidgetPane.backgroundOption.color" />
                    </Select.Option>
                    <Select.Option
                        key="_image"
                        value="image"
                    >
                        <FormattedMessage id="carousselWidgetPane.backgroundOption.image" />
                    </Select.Option>
                </SelectField>
            )}
            {backgroundOptionValue === "color" && (
                <Field
                    id="background.color"
                    label={<FormattedMessage id="carousselWidgetPane.color" />}
                    name={[field.name, "background", "color"]}
                />
            )}
            {backgroundOptionValue === "image" && (
                <React.Fragment>
                    <Form.Item label={<FormattedMessage id="carousselWidgetPane.image" />}>
                        <ImageUpload
                            cropSettings={{
                                aspect: ImageRatios.DEFAULT,
                                rotate: true,
                                zoom: true
                            }}
                            form={form}
                            image={image}
                            name={["widgets", widgetId, "items", field.name, "background", "url"]}
                            onChange={setImage}
                        />
                    </Form.Item>
                    <Field
                        id="background.alt"
                        label={<FormattedMessage id="carousselWidgetPane.alt" />}
                        name={[field.name, "background", "alt"]}
                    />
                    <Flex
                        fullWidth
                        className="imageInherit"
                        margin="15px 0 0"
                    >
                        <Alert
                            message={<FormattedMessage id="carousselWidget.imageInherit" />}
                            type="info"
                        />
                    </Flex>
                </React.Fragment>
            )}
            {(pane.type === "caroussel" || pane.type === "content") && (
                <React.Fragment>
                    <TranslationTabs
                        activeLanguage={selectedLanguage}
                        handleChangeSelectedLanguage={changeSelectedLanguage}
                        languages={languages}
                    />
                    {langFieldProps.map(langProps => (
                        <Field
                            hidden={langProps.hidden}
                            id={`callToAction.text.${langProps.language}`}
                            label={<FormattedMessage id="callToAction.text" />}
                            name={[field.name, "callToAction", "text", langProps.language]}
                        />
                    ))}
                </React.Fragment>
            )}
        </CarousselWidgetPaneStyle>
    );
};
