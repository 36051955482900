import { ContentHeader } from "@components/contentHeader/contentHeader";
import { ColorPreviewField } from "@components/field/colorPreviewField";
import { Flex } from "@components/flex/flex";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { createOnChange } from "@utils/createOnChange";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface TagSettingsFormValues {
    color: string;
}

export interface TagSettingsCardProps {
    defaultValues: Partial<TagSettingsFormValues>;
    disabled?: boolean;
    unsaved?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onChange?(key: string): void;
    form: FormInstance;
}

export const TagSettingsCard: React.FC<TagSettingsCardProps> = (props => {
    const { hidden, disabled, unsaved, onChange, form } = props;
    const intl = useIntl();

    const sharedProps = {
        disabled,
        onChange: onChange ? createOnChange(onChange, "contentTagSettings") : undefined
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                rightFields={unsaved && [
                    <UnsavedChanges key="warning" />
                ]}
                title={<FormattedMessage id="contentTag.contentTagSettings" />}
            />
            <Flex direction="column">
                <Flex>
                    <ColorPreviewField
                        name="color"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        {...sharedProps}
                        form={form}
                        label={<FormattedMessage id="color" />}
                        placeholder={intl.formatMessage({ id: "contentTags.colorFormat" })}
                    />
                </Flex>
            </Flex>
        </Card>
    );
});
