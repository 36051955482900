/* eslint-disable react/jsx-no-useless-fragment */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TableIcon } from "@components/table/tableStyle";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeVisualisationBaseFormValues } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Flex } from "../flex/flex";
import { TableComponentProps, TableData } from "../table/tableContainer";

export interface VisualisationsTableDataSource extends TableData {
    place?: string;
    type?: string;
    condition?: string;
    dataType?: string;
    description?: JSX.Element;
    hasError: boolean;
}

export interface VisualisationsTableProps extends TableComponentProps {
    dataSource: VisualisationsTableDataSource[];
    onChange(): void;
    conditions?: ChallengeConditionFormValuesUnion[];
    errors?: ErrorDictionary;
    deleteVisualisation(index: number): void;
    editVisualisation(index: number): void;
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<ChallengeVisualisationBaseFormValues>;
}

export const VisualisationsTable: React.FC<VisualisationsTableProps> = ({ conditions, deleteVisualisation, editVisualisation, onChange, ...tableProps }) => {
    const renderCondition = (row: VisualisationsTableDataSource): { children: JSX.Element; } => {
        return (
            {
                children: (
                    <React.Fragment>
                        {row.type === "leaderboard" ? row.condition : <ConditionTitle condition={conditions && conditions.find(condition => condition.id === row.condition)} />}
                    </React.Fragment>
                )
            }
        );
    };

    const renderHandlers = (index: number): Handlers => ({
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <TableIcon
                    as={EditOutlined}
                    onClick={() => editVisualisation(index)}
                />
                <TableIcon
                    as={DeleteOutlined}
                    onClick={() => {
                        onChange();
                        deleteVisualisation(index);
                    }}
                />
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            expandable={{
                expandedRowRender: record => record.description,
                rowExpandable: record => Boolean(record.description)
            }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="place"
                dataIndex="place"
                title={<FormattedMessage id="place" />}
            />
            <Column
                key="type"
                dataIndex="type"
                title={<FormattedMessage id="type" />}
            />
            <Column
                key="condition"
                dataIndex="condition"
                render={(_, row: VisualisationsTableDataSource) => renderCondition(row)}
                title={<FormattedMessage id="condition" />}
            />
            <Column
                key="dataType"
                dataIndex="dataType"
                title={<FormattedMessage id="dataType" />}
            />
            {renderErrorColumn()}
            <Column
                dataIndex=""
                render={(_text, _row, index) => renderHandlers(index)}
                title=" "
                width={100}
            />
        </Table>
    );
};
