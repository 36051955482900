import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";

import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { ChronoRaceEvent } from "@graphql2/types";
import { InjectedIntl } from "@utils/override.types";
import { Row, Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CardInCard } from "../addEventsPageStyle";
import { momentValueComparison } from "../dateValidator/dateValidator";
import { LimitedFlexCell, StyledFormItem } from "../dateValidator/dateValidatorStyle";

interface SubEventsSettingsCardProps {
    form: FormInstance;
    intl: InjectedIntl;
    disabled?: boolean;
    selectedChronoRaceEvent?: ChronoRaceEvent;
    onBlur(): void;
    subEventKey: number;
    selectedProjectSubEventIndex: number;
}

export const SubEventsSettingsCard: React.FC<SubEventsSettingsCardProps> = (props) => {
    const {
        intl,
        form,
        subEventKey,
        selectedChronoRaceEvent,
        disabled,
        onBlur,
        selectedProjectSubEventIndex
    } = props;

    const sharedProps = {
        disabled,
        onBlur
    };

    return (
        <CardInCard title={<FormattedMessage id="subEvent.settings" />}>
            <Flex
                className="eventSettings"
                direction="column"
            >
                <FormattedMessage
                    id="subEvent.dateAndTimes"
                    tagName="h3"
                />
                <Row gutter={16}>
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            format="YYYY-MM-DD"
                            label={<FormattedMessage id="start" />}
                            name={["subEvents", subEventKey, "date"]}
                            rules={[
                                requiredrule,
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["subEvents", subEventKey, "endDate"]
                                        ],
                                        "",
                                        "before",
                                        undefined,
                                        "minutes"
                                    )
                                },
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["start"]
                                        ],
                                        intl.formatMessage({ id: "subEvent.start.validation" }),
                                        "after"
                                    )
                                },
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["end"]
                                        ],
                                        intl.formatMessage({ id: "subEvent.startAfter.validation" }),
                                        "before"
                                    )
                                }
                            ]}
                            showNow={false}
                        />
                    </LimitedFlexCell>
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            format="YYYY-MM-DD"
                            label={<FormattedMessage id="end" />}
                            name={["subEvents", subEventKey, "endDate"]}
                            rules={[
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["subEvents", subEventKey, "date"]
                                        ],
                                        "",
                                        "after",
                                        undefined,
                                        "minutes"
                                    )
                                },
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["end"]
                                        ],
                                        intl.formatMessage({ id: "subEvent.end.validation" }),
                                        "before"
                                    )
                                }
                            ]}
                            showNow={false}
                        />
                    </LimitedFlexCell>
                    <Form.Item
                        dependencies={[
                            ["subEvents", subEventKey, "date"],
                            ["subEvents", subEventKey, "endDate"],
                            ["subEvents", subEventKey, "validator"]
                        ]}
                    >
                        {() => {
                            form.validateFields([
                                ["subEvents", subEventKey, "date"],
                                ["subEvents", subEventKey, "endDate"],
                                ["subEvents", subEventKey, "validator"]
                            ]);
                            return (
                                <StyledFormItem
                                    name={["subEvents", subEventKey, "validator"]}
                                    rules={
                                        !disabled
                                            ? [
                                                {
                                                    validator: momentValueComparison(
                                                        form,
                                                        [
                                                            ["subEvents", subEventKey, "date"]
                                                        ],
                                                        intl.formatMessage({ id: "event.duration.validation" }),
                                                        "after",
                                                        ["subEvents", subEventKey, "endDate"],
                                                        "minutes"
                                                    )
                                                }
                                            ]
                                            : []
                                    }
                                />
                            );
                        }}
                    </Form.Item>
                </Row>
                <Field
                    {...sharedProps}
                    hidden
                    name={["subEvents", subEventKey, "sequence"]}
                />
                <Field
                    {...sharedProps}
                    hidden
                    name={["subEvents", subEventKey, "id"]}
                />
                <SelectField
                    {...sharedProps}
                    hidden={!selectedChronoRaceEvent?.subEvents?.length}
                    name={["subEvents", subEventKey, "chronoraceSubEventId"]}
                    placeholder="Select a chronorace subevent"
                >
                    {selectedChronoRaceEvent?.subEvents?.map((chronoRaceSubEvent) => {
                        if (!chronoRaceSubEvent?.id) {
                            return null;
                        }

                        return (
                            <Select.Option
                                key={chronoRaceSubEvent.id}
                                value={chronoRaceSubEvent.id}
                            >
                                {chronoRaceSubEvent.title}
                            </Select.Option>
                        );
                    })}
                </SelectField>

                {selectedProjectSubEventIndex >= 0 && (
                    <Field
                        {...sharedProps}
                        hidden
                        name={["projects", 0, "subEvents", selectedProjectSubEventIndex, "id"]}
                    />
                )}
            </Flex>
        </CardInCard>
    );
};
