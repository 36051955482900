import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import * as React from "react";

interface DescriptionTooltipProps {
    description: string
}

export const DescriptionTooltip: React.FC<DescriptionTooltipProps> = ({ description }) => {
    return (
        <Tooltip
            placement="right"
            title={description}
        >
            <InfoCircleOutlined style={{ padding: 5 }} />
        </Tooltip>
    );
};
