import styled from "styled-components";

export const BalanceStyle = styled.div`
    span {
        margin-right: 20px;
    }
`;

export const WalletWrapper = styled.div`
    margin: 26px 39px;

    .ant-collapse {
        background: #A0C63D;
    }

    .ant-collapse-arrow {
        color: white !important;
    }
`;

export const WalletLabel = styled.span`
    color: white;
`;
