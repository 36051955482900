
import { getUsersOverview } from "@graphql2/graphql_op";
import { AddUserProps, withAddUser } from "@graphql2/types";
import { AddUserPage } from "@pages/addUserPage/addUserPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

export interface AddUserPageContainerProps { }

const withAddUserMutation = withAddUser({
    name: "addUser",
    options: {
        update: cleanCache([getUsersOverview])
    }
});

export const AddUserPageContainer = compose<React.ComponentClass<AddUserPageContainerProps>>(
    withAddUserMutation
)(AddUserPage);

export type AddUserPageProps =
    AddUserPageContainerProps
    & AddUserProps<{}, "addUser">
    & RouteComponentProps;
