import styled from "styled-components";
import { Flex } from "@components/flex/flex";

export const RolesPageStyle = styled.div`

`;

export const RoleModalWrapper = styled.div`
    .ant-modal-footer {
        display: none;
    }
`;

export const FlexButtonWrapper = styled(Flex)`
    button {
        width: 100%;
    }
`;
