import * as React from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";

import { InjectedIntlProps } from "@utils/override.types";
import { LanguageObject } from "@utils/toLanguageObject";
import { localState, LocalStateProps } from "@utils/withLocalState";
import { GetEventParticipantsProps, RemoveEventParticipationProps, UpdateEventApprovalProps, withGetEventParticipants, withRemoveEventParticipation as withRemoveEventParticipationMutation, withUpdateEventApproval as withUpdateEventApprovalMutation } from "../../../graphql2/types";
import { ParticipantsCard } from "./participantsCard";

export interface ParticipantsCardContainerProps {
    eventId: string;
    eventTitle: LanguageObject;
    subEventIds: string[];
}
export interface LocalState {
    filterSubEventIds: string[];
}

const withLocalState = localState<LocalState>({
    filterSubEventIds: []
});

const withParticipants = withGetEventParticipants({
    name: "eventParticipants",
    options: (props: ParticipantsCardContainerProps & LocalState) => ({
        errorPolicy: "all",
        variables: {
            filter: {
                eventId: props.eventId,
                subEventIds: props.filterSubEventIds.length ? props.filterSubEventIds : props.subEventIds
            }
        }
    }),
    skip: (props: ParticipantsCardContainerProps) => {
        return !props.eventId || !props.subEventIds.length;
    }
});

const withRemoveEventParticipation = withRemoveEventParticipationMutation({
    name: "removeEventParticipation",
    options: {
        refetchQueries: ["getEventParticipants"],
        awaitRefetchQueries: true
    }
});

const withUpdateEventApproval = withUpdateEventApprovalMutation({
    name: "updateEventApproval",
    options: {
        refetchQueries: ["getEventParticipants"],
        awaitRefetchQueries: true
    }
});

export const ParticipantsCardContainer = compose<React.ComponentClass<ParticipantsCardContainerProps>>(
    withLocalState,
    withParticipants,
    withRemoveEventParticipation,
    withUpdateEventApproval,
    injectIntl
)(ParticipantsCard);

export type ParticipantsCardProps =
    ParticipantsCardContainerProps
    & MaybeElements<GetEventParticipantsProps<{}, "eventParticipants">>
    & UpdateEventApprovalProps<{}, "updateEventApproval">
    & RemoveEventParticipationProps<{}, "removeEventParticipation">
    & LocalStateProps<LocalState>
    & InjectedIntlProps;
