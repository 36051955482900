import { Table, Popconfirm } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { ExportFormTemplateContainer } from "@components/export/exportFormTemplateContainer";
import Column from "antd/lib/table/Column";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableIcon } from "@components/table/tableStyle";

export interface FormTemplateTableData extends TableData {
    title: string;
}

export interface FormTemplateTableProps extends TableComponentProps {
    dataSource: FormTemplateTableData[];
    handlers: TableHandlers & {
        removeFormTemplate(id: string): void;
    };
}

export class FormTemplateTable extends React.Component<FormTemplateTableProps> {
    public render() {
        return (
            <Table pagination={{ showSizeChanger: true, showQuickJumper: true }} rowKey="id" {...this.props}>
                <Column
                    key="title"
                    title={<FormattedMessage id="title" />}
                    dataIndex="title"
                    sorter={(a: FormTemplateTableData, b: FormTemplateTableData) => a.title.localeCompare(b.title)}
                    render={this.renderTitle}
                />
                <Column
                    title=""
                    key="handlers"
                    dataIndex=""
                    width={120}
                    render={(_, row: FormTemplateTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <Flex justifyContent="flex-end" alignItems="center">
                                    <ExportFormTemplateContainer id={row.id} />
                                    <Link to={`/event/forms/edit/${row.id}`}>
                                        <TableIcon as={EditOutlined} />
                                    </Link>
                                    <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeFormTemplate(row.id)} okText="Yes" cancelText="No">
                                        <TableIcon as={DeleteOutlined} />
                                    </Popconfirm>
                                </Flex>
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                />
            </Table>
        );
    }

    private renderTitle(text: string, row: FormTemplateTableData) {
        return (
            <Link className="plainText" to={`/event/forms/edit/${row.id}`}>
                {text}
            </Link>
        );
    }
}
