import * as React from "react";
import styled from "styled-components";

const IconsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const ProviderIcon = styled.img`
    width: 20px;
    margin-right: 5px;
`;

export const ProvidersIcons = (userProviders: string[]) => {
    const iconSource = (provider: string) => (
        `https://res.cloudinary.com/energylab/image/upload/v1611071800/development/icons/${provider}.png`
    );
    return (
        <IconsContainer>
            {userProviders.map(userProvider => (
                <ProviderIcon src={iconSource(userProvider)} />
            ))}
        </IconsContainer>
    );
};

export default ProvidersIcons;
