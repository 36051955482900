import { WidgetProps } from "@components/dashboard/widgets/models";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { zeroValidation } from "@utils/validators/zeroValidation";
import { Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { WidgetConfigStyle, WidgetStyle } from "../widgetStyle";
import { ActivityWidgetStyle, RowFlex } from "./activityWidgetStyle";
import { historyAggregationOptions, historyChartTypeOptions, summaryAlgorithmDisplayTypeOptions, summaryAlgorithmOptions, unitTypeOptions } from "./constants/options";

export interface ActivityWidgetProps extends WidgetProps {

}

export const ActivityWidget: React.FC<ActivityWidgetProps> = (props) => {
    const { widgetId } = props;
    // todo fix errors

    return (
        <WidgetStyle>
            <WidgetConfigStyle>
                <ActivityWidgetStyle>
                    <DefaultWidgetFields
                        id={widgetId}
                    />
                    <FormattedMessage id="widget.title" tagName="h3" />
                    <TitleSubtitleDescriptionCard
                        disableDescription
                        disableSubtitle
                        widgetId={widgetId}
                    />
                    <FormattedMessage id="goal" tagName="h3" />
                    <RowFlex>
                        <SelectField
                            name={["widgets", widgetId, "goal", "unitType"]}
                            rules={[requiredrule]}
                            label={<FormattedMessage id="goal.unitType" />}
                            id="goal.unitType"
                        >
                            {unitTypeOptions.map(unitType => (
                                <Select.Option value={unitType} key={unitType}>
                                    <FormattedMessage id={`goal.unitType.${unitType}`} />
                                </Select.Option>
                            ))}
                        </SelectField>
                        <Field
                            rules={[
                                requiredrule,
                                { validator: zeroValidation }
                            ]}
                            name={["widgets", widgetId, "goal", "value"]}
                            numberType
                            id="goal.value"
                            min={0}
                            label={<FormattedMessage id="goal.value" />}
                        />
                    </RowFlex>
                    <FormattedMessage id="history" tagName="h3" />
                    <RowFlex>
                        <Field
                            id="history.duration"
                            rules={[requiredrule]}
                            name={["widgets", widgetId, "history", "duration"]}
                            label={(
                                <React.Fragment>
                                    <FormattedMessage id="history.duration" />
                                    &nbsp;
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.digi.com/resources/documentation/digidocs/90001488-13/Default.htm#reference/r_iso_8601_duration_format.htm"
                                    >
                                        ISO 8601?
                                    </a>
                                    &nbsp;
                                    (<FormattedMessage id="history.messageForNonFamiliar" />)
                                </React.Fragment>
                            )}
                        />

                        <SelectField
                            name={["widgets", widgetId, "history", "aggregation"]}
                            rules={[requiredrule]}
                            label={<FormattedMessage id="history.aggregation" />}
                            id="history.aggregation"
                        >
                            {historyAggregationOptions.map(aggregation => (
                                <Select.Option value={aggregation} key={aggregation}>
                                    <FormattedMessage id={`history.aggregation.${aggregation}`} />
                                </Select.Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name={["widgets", widgetId, "history", "chartType"]}
                            rules={[requiredrule]}
                            label={<FormattedMessage id="history.chartType" />}
                            id="history.chartType"
                        >
                            {historyChartTypeOptions.map(chartType => (
                                <Select.Option value={chartType} key={chartType}>
                                    <FormattedMessage id={`history.chartType.${chartType}`} />
                                </Select.Option>
                            ))}
                        </SelectField>
                    </RowFlex>
                    <FormattedMessage id="summary" tagName="h3" />
                    <RowFlex>
                        <SelectField
                            name={["widgets", widgetId, "summary", "algorithm"]}
                            rules={[requiredrule]}
                            label={<FormattedMessage id="summary.algorithm" />}
                            id="summary.algorithm"
                        >
                            {summaryAlgorithmOptions.map(algorithm => (
                                <Select.Option value={algorithm} key={algorithm}>
                                    <FormattedMessage id={`summary.algorithm.${algorithm}`} />
                                </Select.Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name={["widgets", widgetId, "summary", "algorithmDisplayType"]}
                            rules={[requiredrule]}
                            label={<FormattedMessage id="summary.algorithmDisplayType" />}
                            id="summary.algorithmDisplayType"
                        >
                            {summaryAlgorithmDisplayTypeOptions.map(algorithmDisplayType => (
                                <Select.Option value={algorithmDisplayType} key={algorithmDisplayType}>
                                    <FormattedMessage id={`summary.algorithmDisplayType.${algorithmDisplayType}`} />
                                </Select.Option>
                            ))}
                        </SelectField>
                    </RowFlex>
                </ActivityWidgetStyle>
            </WidgetConfigStyle>
        </WidgetStyle>
    );
};
