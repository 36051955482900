import ExportUsersToCsvButton from "@components/export/ExportToCsv/exportUsersToCsvButton";
import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { UsersTable } from "@components/users/usersTable";
import { UsersPageProps } from "@pages/usersPage/usersPageContainer";
import { mapUserOverview } from "@utils/mappers/userMapper";
import { removeUserFn } from "@utils/removeUserFn";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Button, Card } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export const UsersPage: React.FC<UsersPageProps> = (props) => {
    const {
        users: { loading: loadingUsers, users },
        userCount: { loading: loadingUserCount, userCount },
        history,
        removeUser,
        paging,
        applications
    } = props;

    const intl = useIntl();
    const updateUrlQuery = useUpdateQuery({ replace: true });

    const sortedApplications = React.useMemo(
        () => {
            if (!applications || !applications.applications) {
                return;
            }

            return applications.applications.map((application) => {
                return {
                    ...application,
                    roles: [...application.roles].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                };
            });
        },
        [applications && applications.applications]
    );

    const removeUserFnWrapper = async (id: string, anonymize: boolean) => {
        await removeUserFn(id, removeUser, undefined, anonymize);
    };

    const updateTableSettings = (pagination: TablePaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="users.overview"
                        tagName="h1"
                    />
                    <div>
                        <ExportUsersToCsvButton />
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => history.push("/users/add")}
                        >
                            <FormattedMessage id="users.addUser" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <DynamicFilterBar fields={[
                    {
                        name: "userSearch",
                        type: "input",
                        search: true,
                        placeholder: intl.formatMessage({ id: "filter.textSearch" })
                    },
                    {
                        name: "userRoles",
                        type: "select",
                        placeholder: intl.formatMessage({ id: "contents.filter.userRoles" }),
                        options: (sortedApplications || []).map(application => ({
                            name: application.name,
                            options: application.roles.map(role => ({
                                name: role.name,
                                value: `${role.name}_${application.id}_${role.id}`
                            }))
                        })),
                        multiple: true
                    }
                ]}
                />
                <TableContainer
                    dataSource={mapUserOverview(users || [])}
                    handlers={{
                        removeUser: removeUserFnWrapper
                    }}
                    loading={loadingUsers || loadingUserCount}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: userCount
                    }}
                    TableComponent={UsersTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
