/* eslint-disable max-lines-per-function */
import { Badge, Breadcrumb, Button, Form, Popconfirm, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ContentTabs } from "@components/contentTabs/contentTabs";
import { DashboardConfigColumnPicker } from "@components/dashboard/dashboardConfigColumnPicker/dashboardConfigColumnPicker";
import { DashboardInfo } from "@components/dashboard/dashboardInfo/dashboardInfo";
import { DashboardWidgetConfigurator, ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { LoaderComponent } from "@components/loaderComponent/loaderComponent";
import { AddDashboardPageProps } from "@pages/addDashboardPage/addDashboardPageContainer";
import { AddDashboardPageStyle, DashboardContentWrapper } from "@pages/addDashboardPage/addDashboardPageStyle";
import { dashboardToFormValues, formValuesToDashboard } from "@utils/mappers/dashboardMapper";

import { LoadingOutlined } from "@ant-design/icons";
import { WidgetConfigurator } from "@components/dashboard/dashboardWidgetConfigurator/getWidgetConfigurator";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { FormModal } from "@components/modal/modal";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { UpdateDashboardConfigurationMutationVariables } from "@graphql2/types";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { DEFAULT_PLACES, DEVICES } from "@pages/addDashboardPage/constants";
import { WidgetObject, WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import _ from "lodash";
import { Link } from "react-router-dom";

export interface BaseWidgetsFormValues {
    id: string;
    type: string;
}

export interface DeviceSettingWidget {
    id: string;
    visible?: boolean;
}

export interface Column {
    widgets: DeviceSettingWidget[];
}

export interface DeviceSetting {
    columns: Column[];
    widths: number[];
}

export type DeviceSettings = Record<ViewType, DeviceSetting>;

export interface DashboardFormValues {
    id?: string;
    devices: DeviceSettings;
    place: string;
    expiresOn: moment.Moment;
    publishOn: moment.Moment;
    sequence: number;
    widgets: WidgetObject;
}

export const ADD_DASHBOARD_TABS: Record<string, ViewType> = {
    desktop: DEVICES[0],
    tablet: DEVICES[1],
    mobile: DEVICES[2]
};

const HeaderTab = ({ hasErrors, view }: { hasErrors: any; view: string; }) => <Badge dot={hasErrors}><FormattedMessage id={view} /></Badge>;

const emptyDashboard = (): DashboardFormValues => ({
    id: "",
    place: "",
    publishOn: moment(),
    sequence: 1,
    devices: {
        desktop: {
            columns: [
                { widgets: [] },
                { widgets: [] }
            ],
            widths: [1, 2]
        },
        tablet: {
            columns: [
                { widgets: [] },
                { widgets: [] }
            ],
            widths: [1, 1]
        },
        mobile: {
            columns: [
                { widgets: [] }
            ],
            widths: [1]
        }
    },
    widgets: {},
    expiresOn: moment().add(1, "year")
});

// tslint:disable max-func-body-length
export const AddDashboardPage: React.FC<AddDashboardPageProps> = (props) => {
    const {
        applications,
        addDashboardConfiguration,
        dashboards,
        dashboardConfigurations,
        editMode,
        match,
        history,
        removeDashboardConfiguration,
        updateDashboardConfiguration,
        match: { params: { id, device } }
    } = props;

    const intl = useIntl();

    if (dashboards?.loading || applications?.loading) {
        return <LoadingOutlined />;
    }

    if (!dashboards?.dashboardConfigurations?.length && match.params.id) {
        props.history.push("/404");
    }

    const defaultValues = React.useMemo(
        () => createDefaultValues(dashboardToFormValues, emptyDashboard(), null, dashboards?.loading, dashboards?.dashboardConfigurations?.[0]),
        [dashboards]
    );

    const [fieldErrors, setError] = useErrors();
    const [unsaved, setSavedStatus] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const needsSaving = () => setSavedStatus(true);

    React.useEffect(() => {
        if (!device) {
            history.push("/dashboards/add/desktop");
        }
    }, []);

    const places = React.useMemo(
        () => {
            if (!dashboardConfigurations || !dashboardConfigurations.dashboardConfigurations) {
                return [];
            }

            // create an object with interface { [place: string] : boolean } to quickly get the unique places.
            const optionsFromConfigs = Object.keys(
                dashboardConfigurations.dashboardConfigurations.reduce((p, c) => ({ ...p, [c.place]: true }), {})
            );

            const options = [...optionsFromConfigs, ...DEFAULT_PLACES];

            const uniqueOptions = Array.from(new Set(options));

            return uniqueOptions;
        },
        [dashboardConfigurations]
    );

    const [form] = useForm();
    const [showModal, setModalVisibility] = React.useState(false);
    const [widths, setWidths] = React.useState(defaultValues.devices[device].widths);
    // Only use this if you don't have to sync the form (setting initial or syncing after onOk modal)
    const [widgets, setWidgetsWithoutFormSync] = React.useState(defaultValues.widgets);
    // Please don't use directSetEditWidgetId, as this will cause the cancel button to break.
    const [editWidgetId, directSetEditWidgetId] = React.useState<null | string>(null);
    const [addWidgetMode, setAddWidgetMode] = React.useState<boolean>(false);
    const [previousWidgetState, setPreviousWidgetState] = React.useState<null | WidgetsFormValuesUnion>(null);

    const setWidgets = (value: WidgetObject) => {
        form.setFieldsValue({ widgets: value });
        setWidgetsWithoutFormSync(value);
    };

    const setEditWidgetId = (widgetId: string | null) => {
        if (widgetId) {
            setPreviousWidgetState(form.getFieldValue(["widgets", widgetId]));
        }
        directSetEditWidgetId(widgetId);
    };

    React.useEffect(
        () => {
            setWidths(defaultValues.devices[device].widths);
        },
        [defaultValues]
    );

    const tabs = defaultValues
        // TODO: Can these tabs contain errors?
        ? DEVICES.map((platform) => (
            <HeaderTab
                hasErrors={false}
                view={platform}
            />
        ))
        : [];

    const setWidthsForView = (value: number[], targetDevice = device) => {
        setWidths(value);
        needsSaving();
        const devices: DeviceSettings = form.getFieldValue("devices");
        const { columns } = devices[targetDevice];

        // If columns are not the same size
        if (columns.length !== value.length) {
            const oldLastIndex = columns.length - 1;
            const newLastIndex = value.length - 1;
            // Delete old columns + Move widgets from deleted columns.
            if (columns.length > value.length) {
                for (let i = oldLastIndex; i > newLastIndex; i -= 1) {
                    columns[newLastIndex].widgets = [...columns[newLastIndex].widgets, ...columns[i].widgets];
                    delete columns[i];
                }
            }
            // Add new columns
            if (columns.length < value.length) {
                for (let i = oldLastIndex; i < newLastIndex; i += 1) {
                    columns.push({ widgets: [] });
                }
            }
        }

        // remove <empty> records
        devices[targetDevice].columns = columns.filter(c => c);
        devices[targetDevice].widths = value;
        form.setFieldsValue({ devices });
    };

    const addWidget = (widget: WidgetsFormValuesUnion) => {
        const widgetsObject = {
            ...widgets,
            [widget.id]: widget
        };

        const deviceObject: DeviceSettings = form.getFieldValue("devices");
        // Add widget to other columns as well
        Object.keys(deviceObject).forEach((key: ViewType) => {
            if (key === device) {
                return;
            }
            deviceObject[key].columns[0].widgets.push({
                id: widget.id,
                visible: true
            });
        });

        form.setFieldsValue({ widgets: widgetsObject });
        setWidgets(widgetsObject);
        setAddWidgetMode(true);
        setModalVisibility(true);
    };

    const revertWidget = (widgetId: string, value: WidgetsFormValuesUnion | null) => {
        form.setFieldsValue({
            widgets: {
                [widgetId]: value
            }
        });
        setEditWidgetId(null);
    };

    const deleteWidget = (widgetId: string, skipDeviceCheck?: boolean) => {
        const deviceObject: DeviceSettings = form.getFieldValue("devices");
        Object.keys(deviceObject).forEach((key: ViewType) => {
            if (!skipDeviceCheck && key === device) {
                return;
            }
            const columnIndex = deviceObject[key].columns.findIndex(column => column.widgets.find(widget => widget.id === widgetId));
            const newWidgets = deviceObject[key].columns[columnIndex].widgets.filter(widget => widget.id !== widgetId);
            deviceObject[key].columns[columnIndex].widgets = newWidgets;
        });
        const widgetsObject = _.omit(widgets, widgetId);
        // Reset fields, as a setFieldsValue with empty objects will be ignored
        // Set fields in setWidgets will remove collateral damage from resetFields
        form.resetFields(["widgets", widgetId]);
        setEditWidgetId(null);
        setWidgets(widgetsObject);
        setSavedStatus(true);
    };

    const widgetModalOnOk = async (widgetId: string) => {
        let widgetFieldErrors: []|undefined;
        try {
            await form.validateFields();
        } catch (error) {
            widgetFieldErrors = error?.errorFields?.filter(err => err?.name?.[1] === widgetId);
            if (widgetFieldErrors?.length) {
                const newFieldErrors = { ...error };
                newFieldErrors.errorFields = [...widgetFieldErrors];
                setError(newFieldErrors);
            }
        }

        if (!widgetFieldErrors || !widgetFieldErrors.length) {
            setError({});
            setEditWidgetId(null);
            setAddWidgetMode(false);
            setWidgetsWithoutFormSync({ ...form.getFieldValue("widgets") });
            needsSaving();
        }
    };

    const changeSelectedTab = (index: number | string) => {
        const targetDevice = DEVICES[index] || "desktop";
        const deviceColumns = form.getFieldValue(["devices", targetDevice, "widths"]);
        setWidthsForView(deviceColumns, targetDevice);
        if (editMode) {
            history.push(`/dashboards/edit/${targetDevice}/${match.params.id}`);
        } else {
            history.push(`/dashboards/add/${targetDevice}`);
        }
    };

    const removeDashboard = async (removeId?: string) => {
        if (removeId) {
            await removeDashboardConfiguration({ variables: { id: removeId } });
            history.push("/dashboards/overview");
        }
    };

    const submitForm = async (values: DashboardFormValues) => {
        setIsSaving(true);

        try {
            await form.validateFields();
            const mappedValues = formValuesToDashboard(values);
            const updateValues = () => (editMode
                ? updateDashboardConfiguration({ variables: { ...mappedValues as UpdateDashboardConfigurationMutationVariables } })
                : addDashboardConfiguration({ variables: { ...mappedValues } })
            );
            const updateMsg = editMode ? "dashboard.updated" : "dashboard.added";
            mappedValues.id = id;
            await updateValues();
            message.success(intl.formatMessage({ id: updateMsg }));
            setSavedStatus(false);
            if (!editMode) {
                history.push("/dashboards/overview");
                return;
            }
        } catch (errors) {
            setError(errors);
            message.error(intl.formatMessage({ id: "dashboard.failed" }));
            console.error(errors);
        }

        setIsSaving(false);
    };

    return (
        <LoaderComponent loading={Boolean(dashboards && dashboards.loading) || Boolean(applications && applications.loading) || Boolean(dashboardConfigurations && dashboardConfigurations.loading)}>
            <AddDashboardPageStyle>
                <Form
                    form={form}
                    initialValues={defaultValues}
                    layout="vertical"
                >
                    <HeaderContent>
                        <HeaderActions>
                            {editMode
                                ? (
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Link to="/dashboards/overview">
                                                <FormattedMessage id="overview" />
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            {defaultValues.place}
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                ) : <FormattedMessage id="dashboard.addDashboard" />}

                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    key="button1"
                                    loading={isSaving}
                                    type="primary"
                                    onClick={() => submitForm(form.getFieldsValue())}
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                                {editMode && (
                                    <Popconfirm
                                        cancelText="No"
                                        okText="Yes"
                                        placement="bottomRight"
                                        title={<FormattedMessage id="deleteConfirm" />}
                                        onConfirm={async () => removeDashboard(id)}
                                    >
                                        <Button
                                            className="headerButton"
                                            type="ghost"
                                        >
                                            <FormattedMessage id="dashboard.delete" />
                                        </Button>
                                    </Popconfirm>
                                )}
                            </div>
                        </HeaderActions>
                        <Flex justifyContent="space-between">
                            <ContentTabs
                                currentTab={DEVICES.indexOf(device)}
                                handleTabSelectedLanguage={changeSelectedTab}
                                tabs={tabs}
                            />
                        </Flex>
                    </HeaderContent>
                    <DashboardContentWrapper>
                        <DashboardInfo
                            form={form}
                            places={places}
                            onStatusChange={needsSaving}
                        />
                        <DashboardWidgetConfigurator
                            addWidget={addWidget}
                            deleteWidget={deleteWidget}
                            errors={fieldErrors}
                            form={form}
                            setEditWidgetId={setEditWidgetId}
                            view={device}
                            widgets={widgets}
                            onStatusChange={needsSaving}
                        />
                        <DashboardConfigColumnPicker
                            setWidths={setWidthsForView}
                            view={device}
                            widths={widths}
                        />
                        {Object.keys(widgets).map(widgetId => (
                            <FormModal
                                key={widgetId}
                                cancelButtonProps={{
                                    onClick: () => {
                                        setModalVisibility(false);
                                        if (addWidgetMode) {
                                            setAddWidgetMode(false);
                                            return deleteWidget(widgetId, true);
                                        }
                                        return revertWidget(widgetId, previousWidgetState);
                                    }
                                }}
                                closable={false}
                                hidden={editWidgetId !== widgetId}
                                okButtonProps={{
                                    onClick: () => widgetModalOnOk(widgetId)
                                }}
                                visible={showModal}
                            >
                                <WidgetConfigurator
                                    errors={fieldErrors.widgets?.[widgetId]}
                                    form={form}
                                    roles={getFrontendRoles(applications.applications || [])}
                                    widgetId={widgetId}
                                />
                            </FormModal>
                        ))}
                    </DashboardContentWrapper>
                </Form>
            </AddDashboardPageStyle>
        </LoaderComponent>
    );
};
