import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

export interface ModalStyleProps {
    hidden?: boolean;
}

export const ModalStyle = styled.div<ModalStyleProps>`
    ${props => props.hidden ? "display: none;" : ""}
`;

export const CloseIcon = styled(CloseOutlined)`
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;

      &:hover {
        color: rgb(0, 0, 0);
      }
`;

export const StyledButton = styled(Button)`
    margin-top: 0 !important;
    margin-left: auto;
    padding: 0;
`;

export const Sentinal = styled.div`
    width: 0px;
    height: 0px;
    overflow: hidden;
`;
