import { getContentCount, getContents } from "@graphql2/graphql_op";
import { AddContentProps, ContentFragment, GetApplicationsProps, GetContentsProps, GetContentTagsProps, RemoveContentProps, UpdateContentProps, UserFilter, withAddContent, withGetApplications, withGetContents, withGetContentTags, withRemoveContent, withUpdateContent } from "@graphql2/types";
import { AddContentsPage } from "@pages/addContentsPage/addContentsPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import { localState, LocalStateProps } from "@utils/withLocalState";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export const ADD_CONTENT_TABS = {
    general: "general",
    comments: "comments"
};

export interface AddContentsPageContainerProps { }

interface LocalState {
    filter?: UserFilter;
}

const withLocalState = localState<LocalState>({
    filter: {
        userSearch: undefined
    }
});

export type AddContentsRouteProps = RouteComponentProps<{ tab: string; id: string; }, {}, { import?: ContentFragment; }>;

const REFETCH_QUERIES = {
    refetchQueries: () => [
        "getContents",
        {
            query: getContentCount,
            variables: {
                filter: {}
            }
        }
    ],
    awaitRefetchQueries: true
};

export const withContents = withGetContents({
    name: "contents",
    options: (props: AddContentsRouteProps) => ({
        variables: {
            filter: {
                ids: props.match.params.id ? [props.match.params.id] : []
            },
            paging: {}
        }
    }),
    skip: (props: AddContentsRouteProps) => {
        return !props.match.params.id;
    }
});

const withApplications = withGetApplications({ name: "applications" });
const withContentTags = withGetContentTags({
    name: "contentTags"
});

const withAddContentMutation = withAddContent({
    name: "addContent",
    options: {
        ...REFETCH_QUERIES,
        update: cleanCache([getContents, getContentCount])
    }
});

const withUpdateContentMutation = withUpdateContent({
    name: "updateContent",
    options: {
        ...REFETCH_QUERIES,
        update: cleanCache([getContents, getContentCount])
    }
});

const withRemoveContentMutation = withRemoveContent({
    name: "removeContent",
    options: {
        ...REFETCH_QUERIES,
        update: cleanCache([getContents, getContentCount])
    }
});

export const AddContentsPageContainer = compose<React.ComponentClass<AddContentsPageContainerProps>>(
    withRouter,
    withLocalState,
    withApplications,
    withContentTags,
    withAddContentMutation,
    withUpdateContentMutation,
    withRemoveContentMutation,
    withContents
)(AddContentsPage);

export type AddContentsPageProps =
    AddContentsPageContainerProps
    & MaybeElements<GetContentsProps<{}, "contents">>
    & AddContentProps<{}, "addContent">
    & UpdateContentProps<{}, "updateContent">
    & RemoveContentProps<{}, "removeContent">
    & AddContentsRouteProps
    & GetApplicationsProps<{}, "applications">
    & GetContentTagsProps<{}, "contentTags">
    & LocalStateProps<LocalState>;
