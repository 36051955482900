
import { localState, LocalStateProps } from "@utils/withLocalState";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { compose } from "redux";
import { TagsTableData } from "@components/tags/tagsTable";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ContentSettingsCard } from "@pages/addContentsPage/general/contentSettingsCard";
import { withGetUsers, GetUsersProps, UserFilter, ApplicationFragment } from "@graphql2/types";

export interface ContentSettingsCardContainerProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    roles: ApplicationFragment["roles"];
    contentTags: TagsTableData[];
    userId?: string | null;
    setEnabledLocales(locales: string[]): void;
    resetEnabledLocales(): void;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

interface LocalState {
    filter?: UserFilter;
}

type ContentSettingsCombinedProps = LocalState & ContentSettingsCardContainerProps;

const withLocalState = localState<LocalState>({
    filter: {
        userSearch: undefined
    }
});

const withUsers = withGetUsers({
    name: "users",
    options: (props: LocalState) => ({
        variables: {
            filter: props.filter
        }
    }),
    skip: (props: LocalState) => {
        return !props.filter || !props.filter.userSearch || props.filter.userSearch.length < 2;
    }
});

const withUsersSingleUser = withGetUsers({
    name: "singleUser",
    options: (props: ContentSettingsCombinedProps) => ({
        variables: {
            filter: {
                ids: props.userId ? [props.userId] : []
            }
        }
    }),
    skip: (props: ContentSettingsCombinedProps) => {
        return !props.userId;
    }
});

export const ContentsSettingsCardContainer = compose<React.ComponentClass<ContentSettingsCardContainerProps>>(
    withLocalState,
    withUsersSingleUser,
    withUsers
)(ContentSettingsCard);

export type ContentSettingsCardProps =
    & ContentSettingsCardContainerProps
    & LocalStateProps<LocalState>
    & MaybeElements<GetUsersProps<{}, "users">>
    & MaybeElements<GetUsersProps<{}, "singleUser">>;
