import { DocumentNode, OperationDefinitionNode } from "graphql";
import { ApolloCache } from "@apollo/client";
import { FieldNode } from "graphql/language/ast";
import { clone, omit } from "lodash";

export type GenericMutation = (
    { __typename?: "RootMutation" }
    & {
        [key: string]: (
            { __typename?: string }
            & any
        )
    }
);

interface GenericCacheDataStructure {
    data: {
        data: {
            [key: string]: any;
            ROOT_QUERY: {
                [key: string]: any;
            };
            ROOT_MUTATION: {
                [key: string]: any;
            };
        }
    }
}

export const cleanCache = (docs: DocumentNode[]) => (proxy: ApolloCache<GenericMutation>) => {
    const names = docs.map(d => {
        const operation = d.definitions.find(({ kind }) => kind === "OperationDefinition") as OperationDefinitionNode;
        // creates an array of objects with properties that can be used as exact propertynames, or the start of one
        return operation.selectionSet.selections
            .filter(({ kind }) => kind === "Field")
            .map((item: FieldNode) => {
                if (item.arguments && item.arguments.length) {
                    return {
                        exact: false,
                        property: `${item.name.value}(`
                    };
                }

                return {
                    exact: true,
                    property: item.name.value
                };
            }).pop();
    });

    // tslint:disable-next-line:no-any
    const q = clone((proxy as any as GenericCacheDataStructure).data.data.ROOT_QUERY);
    const cache = (proxy as any as GenericCacheDataStructure).data.data;

    // will filter through the root queries and only keep property of those that match
    const keys = Object.keys(q)
        .filter(k => names.some(name => name && (name.exact ? k === name.property : k.startsWith(name.property))));

    cache.ROOT_QUERY = omit(q, keys);
};
