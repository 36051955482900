/* eslint-disable no-nested-ternary */
import { FieldInfo } from "@components/field/fieldStyle";
import { DescriptionTooltip } from "@components/tooltip/descriptionTooltip";
import { Form, Input, InputNumber } from "antd";
import { FormItemProps, RuleObject } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";

// TODO: Use correct types
// TODO: make sure "initialValue" is not allowed
export interface FieldProps extends FormItemProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    value?: any;
    label?: JSX.Element | string;
    info?: React.ReactNode;
    placeholder?: string;
    suffix?: string;
    disabled?: boolean;
    numberType?: boolean;
    min?: number;
    max?: number;
    step?: number;
    formatter?: any;
    parser?: any;
    // onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
    onChange?: any;
    onBlur?: any;
    type?: string;
    rows?: number;
    description?: string;
}

export const Field: React.FC<FieldProps> = (props) => {
    const { onClick, description, ...inputProps } = props;

    const isRequired = inputProps.required || (inputProps.rules || []).find((rule: RuleObject) => rule.required === true);

    return (
        <div onClick={onClick}>
            {!inputProps.hidden && inputProps.label && (
                <label
                    className={isRequired ? "isRequired" : ""}
                    htmlFor={inputProps.id}
                >
                    {inputProps.label}
                </label>
            )}
            {!inputProps.hidden && description && <DescriptionTooltip description={description} />}
            {!inputProps.hidden && inputProps.info && <FieldInfo>{inputProps.info}</FieldInfo>}
            <Form.Item
                hidden={inputProps.hidden}
                initialValue={inputProps.initialValue}
                name={inputProps.name}
                required={inputProps.required}
                rules={!inputProps.disabled ? inputProps.rules : undefined}
            >
                {inputProps.numberType
                    ? (
                        <InputNumber
                            disabled={inputProps.disabled}
                            max={inputProps.max}
                            min={inputProps.min}
                            step={inputProps.step}
                            onBlur={inputProps.onBlur}
                            onChange={inputProps.onChange}
                        />
                    )
                    : !inputProps.rows ? (
                        <Input
                            disabled={inputProps.disabled}
                            placeholder={inputProps.placeholder}
                            onBlur={inputProps.onBlur}
                            onChange={inputProps.onChange}
                        />
                    )
                        : (
                            <TextArea
                                disabled={inputProps.disabled}
                                placeholder={inputProps.placeholder}
                                rows={inputProps.rows}
                                onBlur={inputProps.onBlur}
                                onChange={inputProps.onChange}
                            />
                        )}
            </Form.Item>
        </div>
    );
};
