const isValidHexColor = (input: string) => {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;

    return hexRegex.test(input);
};

export const validateHexColor = (rule, value, callback) => {
    if (value && !isValidHexColor(value)) {
        callback("Enter valid hex code");
    } else {
        callback();
    }
};
