import { cloudinaryUpload } from "@utils/cloudinary";
import { FormInstance } from "antd/lib/form/Form";
import { NamePath } from "antd/lib/form/interface";
import { createSetFieldsValue } from "./form/createSetFieldsValue";
import { NestedArrayData, setArrayFieldValue, setNestArrayFieldValue } from "./form/setArrayFieldValue";

export type StoreImageSettings = NestedArrayData | NestedArrayData[];

export const storeImage = async (onChange: undefined | (() => void), form: FormInstance, fieldKey: NamePath, image?: string, callback?: (url?: string) => void, settings?: NestedArrayData | NestedArrayData[]) => {
    if (onChange) {
        onChange();
    }

    let value: string | null = null;

    if (image) {
        const response = await cloudinaryUpload(image);
        if (response && response.url) {
            value = response.url;
        }
    }

    if (settings) {
        if (Array.isArray(settings)) {
            setNestArrayFieldValue(form, settings, value);
        } else {
            setArrayFieldValue(form, settings.fields, settings.index, value);
        }
    } else {
        form.setFieldsValue(createSetFieldsValue(fieldKey, value));
    }

    if (callback) {
        callback(value || undefined);
    }
};
