import { ArrowLeftOutlined } from "@ant-design/icons";
import { ChallengeParticipantsTable } from "@components/challengeParticipantsTable/challengeParticipantsTable";
import ExportChallengeParticipantsToCsvButton from "@components/export/ExportToCsv/exportChallengeParticipantsToCsvButton";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { HeaderActions, HeaderTitle } from "@pages/addChallengesPage/addChallengesPageStyle";
import { translateChallengeTitles } from "@utils/mappers/challengeMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Breadcrumb, Card, message } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ChallengeParticipantsPageProps } from "./challengeParticipantsPageContainer";

export type RemoveFromTeamArgs = {userId: string; teamId: string;}
export type RemoveParticipantArgs = {userId: string; challengeId: string;}

export const ChallengeParticipantsPage: React.FC<ChallengeParticipantsPageProps> = (props) => {
    const {
        challengeParticipants,
        participantsCount,
        removeFromChallengeTeam,
        removeChallengeParticipation,
        challengeTitles,
        pageSize,
        match } = props;

    const intl = useIntl();

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const removeMemberFromTeam = async ({ userId, teamId }: RemoveFromTeamArgs) => {
        try {
            await removeFromChallengeTeam({
                variables: {
                    userId,
                    teamId
                }
            });
            message.success(intl.formatMessage({ id: "removeSuccess" }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "removeFailed" }));
            console.error(error);
        }
    };

    const removeChallengeParticipant = async ({ userId, challengeId }: RemoveParticipantArgs) => {
        try {
            await removeChallengeParticipation({
                variables: {
                    userId,
                    challengeId
                }
            });
            message.success(intl.formatMessage({ id: "removeSuccess" }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "removeFailed" }));
            console.error(error);
        }
    };

    const challenge = challengeTitles && challengeTitles.challenges && challengeTitles.challenges[0] && translateChallengeTitles(challengeTitles.challenges)[0];

    return (
        <div>
            <HeaderContent>
                <HeaderActions>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/challenges">
                                <FormattedMessage id="overview" />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {challenge && challenge.title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderActions>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <HeaderTitle to="/challenges">
                        <ArrowLeftOutlined />
                        <FormattedMessage
                            id="participants"
                            tagName="h1"
                        />
                    </HeaderTitle>
                    <ExportChallengeParticipantsToCsvButton challengeId={match.params.id} />
                </Flex>
            </HeaderContent>

            <Card>
                <TableContainer
                    dataSource={challengeParticipants.challengeParticipants || []}
                    handlers={{ removeMemberFromTeam, removeChallengeParticipant }}
                    loading={(challengeParticipants && challengeParticipants.loading) || (participantsCount && participantsCount.loading) || (challengeTitles && challengeTitles.loading)}
                    pagination={{
                        pageSize,
                        total: participantsCount && participantsCount.challengeParticipantCount
                    }}
                    TableComponent={ChallengeParticipantsTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
