import { AddVoucherMutationVariables, UpdateVoucherMutationVariables, VoucherFragment } from "@graphql2/types";
import { formatDate } from "@utils/dateUtils";
import { VoucherFormValues } from "@utils/emptyItems/emptyVoucher";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { getText } from "@utils/getText";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import moment from "moment";

export const mapToVoucherTableData = (voucher: VoucherFragment) => ({
    ...voucher,
    title: voucher.title ? getText(voucher.title) : ""
});

export function mapVoucherToFormValues(voucher: VoucherFragment): VoucherFormValues {
    const { id, title, code, enabled, voucherStart, voucherEnd, discount, maxUsage, maxUsagePerUser, enabledMaxUsage, enabledOn, enabledMaxUsagePerUser, assignments, enableVoucherPeriod } = voucher;

    return {
        id,
        title: toLanguageObject(title),
        code,
        enabled,
        voucherStart: moment(voucherStart || undefined),
        voucherEnd: moment(voucherEnd || undefined),
        maxUsage,
        enabledMaxUsage,
        enabledMaxUsagePerUser,
        maxUsagePerUser,
        discount,
        enabledOn: (enabledOn && enabledOn?.filter(getOutFalseValues).map(enOn => ({ ...enOn, ids: enOn.ids?.filter(getOutFalseValues) }))) || [],
        assignments: (assignments && assignments?.filter(getOutFalseValues).map(asm => ({ ...asm, roles: asm.roles?.filter(getOutFalseValues) }))) || [],
        enableVoucherPeriod,
        enabledDiscount: Boolean(discount)
    };
}

export function mapFormValuesToAddVoucher(values: VoucherFormValues): AddVoucherMutationVariables {
    const {
        enabled,
        maxUsage,
        enabledOn,
        assignments,
        discount,
        enabledMaxUsage,
        voucherStart,
        code,
        title,
        enabledMaxUsagePerUser,
        maxUsagePerUser,
        voucherEnd,
        enableVoucherPeriod
    } = values;

    return {
        enabled,
        maxUsage,
        enabledMaxUsage,
        maxUsagePerUser,
        enabledMaxUsagePerUser,
        discount: discount && discount.type ? {
            type: discount.type,
            amount: discount.amount || 0
        } : null,
        voucherStart: formatDate(voucherStart),
        voucherEnd: formatDate(voucherEnd, true),
        code,
        title: toTextByLanguage(title),
        enabledOn: enabledOn || [],
        assignments: assignments || [],
        enableVoucherPeriod
    };
}

export function mapFormValuesToUpdateVoucher(values: VoucherFormValues, id: string): UpdateVoucherMutationVariables {
    return {
        id,
        ...mapFormValuesToAddVoucher(values)
    };
}
