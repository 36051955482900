import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { MdeField } from "@components/field/mdeField";
import { SelectField } from "@components/field/selectField";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useEnabledLang } from "@context/enabledLangContext/context";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { TieBreakerLanguageFieldProps } from "@utils/languageFieldProps";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { LanguageObject } from "@utils/toLanguageObject";
import { Checkbox, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface TieBreaker {
    active: boolean;
    title: LanguageObject;
    ask: "allMembers" | "captain";
    description: LanguageObject;
    question1: LanguageObject;
    question2: LanguageObject;
}

export interface ChallengeTieBreakerFormValues {
    tieBreaker: TieBreaker;
}

interface ChallengeTieBreakerSettingsProps {
    form: FormInstance;
    errors?: ErrorDictionary;
    onBlur(): void;
}

export const ChallengeTieBreakerSettings: React.FC<ChallengeTieBreakerSettingsProps> = (props) => {
    const {
        form,
        onBlur,
        errors
    } = props;
    const { enabledLanguages } = useEnabledLang();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();

    const sharedProps = {
        onBlur,
        disabled: !form.getFieldValue(["tieBreaker", "active"])
    };

    const languageFieldProps: TieBreakerLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map((language, index) => (
            {
                form,
                index,
                defaultValues: form.getFieldValue(["tieBreaker"]) || {},
                sharedProps,
                language: language.code,
                hidden: language.code !== selectedLanguage
            }
        )), [enabledLanguages, selectedLanguage]
    );

    const tieBreakerTitleField = (nprops: TieBreakerLanguageFieldProps) => (
        <Field
            key={`title.${nprops.index}.${nprops.language}`}
            hidden={nprops.hidden}
            label={<FormattedMessage id="tieBreakerTitle" />}
            name={["tieBreaker", "title", nprops.language]}
            rules={[{
                required: Boolean(form.getFieldValue(["tieBreaker", "active"])),
                message: <FormattedMessage id="form.isrequired" />
            }]}
            {...nprops.sharedProps}
        />
    );

    const tieBreakerDescriptionField = (nprops: TieBreakerLanguageFieldProps) => (
        <MdeField
            key={`description.${nprops.index}.${nprops.language}`}
            compatibilityMode
            form={props.form}
            hidden={nprops.hidden}
            initialValue={nprops.defaultValues && nprops.defaultValues.description ? nprops.defaultValues.description[nprops.language] : ""} // Custom field so still needs initialValue
            label={<FormattedMessage id="tieBreakerDescription" />}
            name={["tieBreaker", "description", nprops.language]}
            {...nprops.sharedProps}
        />
    );

    const tieBreakerQuestion1Field = (nprops: TieBreakerLanguageFieldProps) => (
        <Field
            key={`question1.${nprops.index}.${nprops.language}`}
            hidden={nprops.hidden}
            label={<FormattedMessage id="tieBreakerQuestion1" />}
            name={["tieBreaker", "question1", nprops.language]}
            rules={[{
                required: Boolean(form.getFieldValue(["tieBreaker", "active"])),
                message: <FormattedMessage id="form.isrequired" />
            }]}
            {...nprops.sharedProps}
        />
    );

    const tieBreakerQuestion2Field = (nprops: TieBreakerLanguageFieldProps) => (
        <Field
            key={`question2.${nprops.index}.${nprops.language}`}
            hidden={nprops.hidden}
            label={<FormattedMessage id="tieBreakerQuestion2" />}
            name={["tieBreaker", "question2", nprops.language]}
            rules={[{
                required: Boolean(form.getFieldValue(["tieBreaker", "active"])),
                message: <FormattedMessage id="form.isrequired" />
            }]}
            {...nprops.sharedProps}
        />
    );

    const challenge = form.getFieldValue([]);
    return (
        <React.Fragment>
            <ContentHeader
                title={<FormattedMessage id="tieBreaker" />}
            />
            <Form.Item
                name={["tieBreaker", "active"]}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={false}
                    onChange={onBlur}
                >
                    <FormattedMessage id="addTieBreaker" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                dependencies={[["tieBreaker", "active"]]}
                hidden={!challenge.team.enabled || challenge.team.everyone}
            >
                {() => {
                    const isTieBreakerActive = form.getFieldValue(["tieBreaker", "active"]);
                    return (
                        <SelectField
                            disabled={!isTieBreakerActive}
                            label={<FormattedMessage id="tieBreaker.askTo" />}
                            name={["tieBreaker", "ask"]}
                        >
                            <Select.Option
                                key={0}
                                value="captain"
                            >
                                <FormattedMessage id="tieBreaker.captain" />
                            </Select.Option>
                            <Select.Option
                                key={1}
                                value="allMembers"
                            >
                                <FormattedMessage id="tieBreaker.allMembers" />
                            </Select.Option>
                        </SelectField>
                    );
                }}
            </Form.Item>
            <Form.Item dependencies={[["tieBreaker", "active"]]}>
                {() => {
                    const tieBreaker = form.getFieldValue(["tieBreaker"]);

                    const tieBreakerLanguageFieldProps = languageFieldProps.map(lfp => ({
                        ...lfp,
                        sharedProps: {
                            ...sharedProps,
                            disabled: !tieBreaker.active
                        }
                    }));

                    return (
                        <React.Fragment>
                            <TranslationTabs
                                activeLanguage={selectedLanguage}
                                errors={(errors && errors.tieBreaker && errorsToLanguageErrors(errors.tieBreaker)) || {}}
                                handleChangeSelectedLanguage={changeSelectedLanguage}
                                languages={enabledLanguages}
                            />
                            {tieBreakerLanguageFieldProps.map(tieBreakerTitleField)}
                            {tieBreakerLanguageFieldProps.map(tieBreakerDescriptionField)}
                            {tieBreakerLanguageFieldProps.map(tieBreakerQuestion1Field)}
                            {tieBreakerLanguageFieldProps.map(tieBreakerQuestion2Field)}
                        </React.Fragment>
                    );
                }}
            </Form.Item>
        </React.Fragment>
    );
};
