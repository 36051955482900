import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { SwitchWrapper } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCardStyle";
import { Card } from "antd";
import styled from "styled-components";

export const AddEventsPageStyle = styled.div`
    h3 {
        color: #A0C63D;
        margin: 15px 0 0 0;
        font-weight: 600;
    }

    ${HeaderContent} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    ${HeaderActions} {
        width: 100%;
    }
`;

export const SelectWrapper = styled.div`
    .ant-select-dropdown {
        width: fit-content !important;
    }
`;

export const EventSettingsGroup = styled(Flex)`
    margin-bottom: 25px;

    h3 {
        width: 150px;
        margin-right: 50px;
    }

    .ant-form-item-children {
        display: flex;
        flex-direction: column;
    }
`;

export const EventSubEventsOverviewCardTopBar = styled.div`
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
`;

export const CardInCard = styled(Card)`
    margin: 26px 0px !important;
    margin-top: 0 !important;
    border: 0;
    background: white;
    padding: 0;
    border: 0px solid #e8e8e8;

    .ant-row + .ant-row {
        margin-left: 0 !important;
    }

    & .ant-card-body {
        padding-left: 0;
        padding-right: 0;
    }

    ${Flex} .ant-row + .ant-row {
        margin-left: 1em !important;
    }

    ${SwitchWrapper} {
        margin-top: 14px;
    }

    ${EventSettingsGroup} h3 {
        width: 200px;
        margin-right: 80px;
    }
`;

export const SubEventCardStyle = styled(Card)`
    background: #f0f2f5;
    padding: 0 !important;
    border: 0 !important;
    margin: 26px 7px !important;
    margin-top: 0 !important;

    & ${CardInCard} {
        background: #fff !important;
        border: 1px solid #e8e8e8;

        & .ant-card-body {
            padding: 30px;
        }
    }
`;
