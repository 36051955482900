/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { RelatedArticles } from "@components/relatedArticles/relatedArticles";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Checkbox, Row, Select, Form, DatePicker, Alert } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as environment from "environment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationFragment } from "@graphql2/types";

export interface ProductSettingsFormValues {
    price: number;
    relatedContentId?: string;
    orderRestrictions: {
        enableMaxItemsPerPerson?: boolean;
        maxItemsPerPerson?: number;
        enableMaxItemsPeriod?: boolean;
        maxItemsPeriod?: string;
    };
    currentStock: number;
    availableStock: number;
    publishOn: Moment;
    expiresOn: Moment;
    priority: number;
    countries?: string[] | null;
    roles?: string[] | null;
    isDraft: boolean;
    articleNumber?: string;
    restrictionDayAmount: number;
    restrictionDayType: string;
    type: string;
}

export interface ProductSettingsCardProps {
    form: FormInstance;
    disabled?: boolean;
    stockSettingAvailable?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    roles: ApplicationFragment["roles"];
    changeSelectedLanguage(languageCode: string): void;
    onChange(): void;
}

export interface ProductSettingsCardState {
}

export const ProductSettingsCard: React.FC<ProductSettingsCardProps> = (props) => {
    const { form, disabled, stockSettingAvailable, onChange, roles } = props;

    const sharedProps = {
        onBlur: onChange,
        disabled
    };

    const validateExpiresOn = (rule, value: Moment | undefined, callback: (error?: string | undefined) => void) => {
        const publishOn = form.getFieldValue("publishOn");
        const expiresOn = form.getFieldValue("expiresOn");

        if ((publishOn && expiresOn) && publishOn.valueOf() <= expiresOn.valueOf()) {
            return callback();
        }

        callback("true");
    };

    const validateAvailableStock = (rule, value: string, callback: (error?: string | undefined) => void) => {
        const currentStock = form.getFieldValue("currentStock");

        if (currentStock >= 0) {
            return callback();
        }
        callback("true");
    };

    const renderMaxItemsPerPerson = () => {
        const shouldDisable = !form.getFieldValue(["orderRestrictions", "enableMaxItemsPerPerson"]);
        return (
            <Field
                name={["orderRestrictions", "maxItemsPerPerson"]}
                label={<FormattedMessage id="orderRestrictions.maxItemsPerPerson" />}
                numberType
                min={0}
                {...sharedProps}
                disabled={shouldDisable}
                rules={[
                    {
                        required: !shouldDisable,
                        message: <FormattedMessage id="form.isrequired" />
                    }
                ]}
            />
        );
    };

    const renderDayRestrictionsEnabled = () => {
        const shouldDisable = !form.getFieldValue(["orderRestrictions", "enableMaxItemsPerPerson"]);
        return (
            <Form.Item
                name={["orderRestrictions", "enableMaxItemsPeriod"]}
                valuePropName="checked"
                {...sharedProps}
            >
                <Checkbox
                    disabled={shouldDisable}
                    onChange={() => {
                        sharedProps.onBlur();
                    }}
                >
                    <FormattedMessage id="orderRestrictions.enableMaxItemsPeriod" />
                </Checkbox>
            </Form.Item>
        );
    };

    const renderDayRestrictions = () => {
        const maxItemsPeriodDisabled = !form.getFieldValue(["orderRestrictions", "enableMaxItemsPeriod"]);
        const maxItemsPerPersonDisabled = !form.getFieldValue(["orderRestrictions", "enableMaxItemsPerPerson"]);

        return (
            <React.Fragment>
                <Field
                    name="restrictionDayAmount"
                    rules={[
                        {
                            required: !maxItemsPerPersonDisabled || !maxItemsPeriodDisabled,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    label={<FormattedMessage id="orderRestrictions.maxItemsPeriod" />}
                    id="maxItemsPeriod"
                    numberType
                    {...sharedProps}
                    min={0}
                    disabled={maxItemsPerPersonDisabled || maxItemsPeriodDisabled}
                />
                <SelectField
                    name="restrictionDayType"
                    rules={[
                        {
                            required: !maxItemsPerPersonDisabled || !maxItemsPeriodDisabled,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    label={<FormattedMessage id="orderRestrictions.restrictionDayType" />}
                    id="restrictionDayType"
                    {...sharedProps}
                    disabled={maxItemsPerPersonDisabled || maxItemsPeriodDisabled}
                >
                    <Select.Option value="days" key="days">Day(s)</Select.Option>
                </SelectField>
            </React.Fragment>

        );
    };

    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="productSettings" />}
            />
            <Flex direction="column">
                <h3><FormattedMessage id="webshop.ThankYouMessage" /></h3>
                <RelatedArticles name="relatedContentId" label={<FormattedMessage id="webshop.relatedContentId" />} />
                <h3><FormattedMessage id="webshop.isDraft" /></h3>
                <Flex>
                    <Form.Item
                        name="isDraft"
                        valuePropName="checked"
                    >
                        <Checkbox
                            {...sharedProps}
                            onChange={() => {
                                sharedProps.onBlur();
                            }}
                        >
                            <FormattedMessage id="webshop.isDraft" />
                        </Checkbox>
                    </Form.Item>
                </Flex>
                <h3><FormattedMessage id="webshop.priceInfo" /></h3>
                <Field
                    name="price"
                    label={<FormattedMessage id="price" />}
                    numberType
                    min={0}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                />
                <h3><FormattedMessage id="webshop.type" /></h3>
                <SelectField
                    name="type"
                    label={<FormattedMessage id="webshop.productType" />}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                >
                    <Select.Option value="physical" key="physical">Physical</Select.Option>
                    <Select.Option value="voucher" key="voucher">Voucher</Select.Option>
                </SelectField>
                {stockSettingAvailable ? (
                    <h3><FormattedMessage id="webshop.stockInfo" /></h3>
                ) : (
                    <Alert
                        message={<FormattedMessage id="webshop.info.voucherNoStock" />}
                        type="info"
                        showIcon
                    />
                )}
                <Form.Item>
                    <Field
                        name="currentStock"
                        hidden={!stockSettingAvailable}
                        rules={[
                            {
                                validator: validateAvailableStock,
                                message: <FormattedMessage id="webshop.error.stockTooSmall" />
                            }
                        ]}
                        id="currentStock"
                        numberType
                        min={0}
                        {...sharedProps}
                    />
                </Form.Item>
                <h3><FormattedMessage id="visibility" /></h3>
                <Flex>
                    <Row>
                        <Form.Item
                            name="publishOn"
                            rules={[
                                {
                                    validator: validateExpiresOn,
                                    message: <FormattedMessage id="form.error.expireDateMustBeLaterThanPublishDate" />
                                },
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                            label={<FormattedMessage id="publishOn" />}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item
                            name="expiresOn"
                            rules={[
                                {
                                    validator: validateExpiresOn,
                                    message: <FormattedMessage id="form.error.expireDateMustBeLaterThanPublishDate" />
                                },
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                            label={<FormattedMessage id="expiresOn" />}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Row>
                </Flex>
                <Field
                    name="priority"
                    numberType
                    label={<FormattedMessage id="priorityInList" />}
                    min={0}
                    id="priority"
                    {...sharedProps}
                />
                <h3><FormattedMessage id="webshop.orderAndDeliverySettings" /></h3>
                <Form.Item
                    name={["orderRestrictions", "enableMaxItemsPerPerson"]}
                    valuePropName="checked"
                    {...sharedProps}
                >
                    <Checkbox
                        onChange={() => {
                            sharedProps.onBlur();
                        }}
                    >
                        <FormattedMessage id="orderRestrictions.enableMaxItemsPerPerson" />
                    </Checkbox>
                </Form.Item>
                <Form.Item noStyle dependencies={[["orderRestrictions", "enableMaxItemsPerPerson"]]}>
                    {() => renderDayRestrictionsEnabled()}
                </Form.Item>
                <Flex>
                    <Form.Item noStyle dependencies={[["orderRestrictions", "enableMaxItemsPerPerson"]]}>
                        {() => renderMaxItemsPerPerson()}
                    </Form.Item>
                    <Form.Item
                        noStyle
                        dependencies={[
                            ["orderRestrictions", "enableMaxItemsPerPerson"],
                            ["orderRestrictions", "enableMaxItemsPeriod"]
                        ]}
                    >
                        {() => renderDayRestrictions()}
                    </Form.Item>
                </Flex>
                <h3><FormattedMessage id="webshop.userResitrictions" /></h3>
                <Flex>
                    <Form.Item>
                        <SelectField
                            name="countries"
                            placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                            mode="multiple"
                            label={<FormattedMessage id="webshop.countries" />}
                            id="countries"
                            {...sharedProps}
                            disabled={!environment.countries || !environment.countries.length}
                        >
                            {environment.countries ? environment.countries.map(country => {
                                return <Select.Option value={country.code} key={country.code}>{country.name}</Select.Option>;
                            }) : null}
                        </SelectField>
                    </Form.Item>
                    <Form.Item>
                        <SelectField
                            name="roles"
                            placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                            label={<FormattedMessage id="webshop.roles" />}
                            id="roles"
                            mode="multiple"
                            filterOption={(input, option) => Object.values(option ?? []).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                            {...sharedProps}
                        >
                            {roles.map(role => <Select.Option value={role.id} key={role.id}>{role.name}</Select.Option>)}
                        </SelectField>
                    </Form.Item>
                </Flex>
            </Flex>
        </Card>
    );
};
