import { ContentHeader } from "@components/contentHeader/contentHeader";
import { JourneyContentTable } from "@components/journeyContentTable/journeyContentTable";
import { Content } from "@pages/addJourneysPage/content/editJourneyContentCard";
import { Button, Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableContainer } from "@components/table/tableContainer";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";

export interface JourneyContentCardProps {
    content: Content[];
    hidden?: boolean;
    errors?: ErrorDictionary;
    editContent(index: number): void;
    add(): void;
    remove(index: number): void;
    move(from: number, to: number): void;
}

export const JourneyContentCard: React.FC<JourneyContentCardProps> = (props) => {
    const { hidden, content, errors, ...handlers } = props;

    const mapDataSource = () => {
        return content.map((c, i) => ({
            ...c,
            hasError: hasError(errors, [i])
        }));
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="editContent" />}
                rightFields={[<Button key="add" onClick={handlers.add}><FormattedMessage id="addContent" /></Button>]}
            />
            <TableContainer
                TableComponent={JourneyContentTable}
                dataSource={mapDataSource()}
                {...handlers}
            />
        </Card>
    );
};
