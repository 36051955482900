import { getFileExtension } from "./getFileExtension";
import * as Papa from "papaparse";

export async function readFile<S>(file: File) {
    const parseMap = {
        csv: (raw: string) => raw,
        json: (raw: string) => JSON.parse(raw)
    };

    return new Promise<S>((resolve, reject) => {
        const reader = new FileReader();
        const extension = getFileExtension(file.name);

        reader.onload = () => {
            if (!reader.result) {
                reject(new Error("Unable to read file"));
            }

            resolve(parseMap[extension](reader.result));
        };

        reader.onerror = reject;

        if (!parseMap[extension]) {
            return reject(new Error("Unknown file format"));
        }

        reader.readAsText(file);
    });
}

export const csvToJson = <RowType = Record<string,string>>(csv, { withHeader } = {
    withHeader: true
}) => {
    const result = Papa.parse<RowType>(csv, {
        header: withHeader,
        dynamicTyping: true
    });
    
    if (result.errors.length) {
        console.error(result.errors);
    }

    return result.data;
};
