import styled from "styled-components";
import { WidgetStyle } from "../widgetStyle";
import { Flex } from "@components/flex/flex";

export const SocialWidgetStyle = styled(WidgetStyle)`
    .socialModeSwitch {
        margin: 14px 0;

        & .ant-select {
            margin-top: 10px;
        }
    }

    ${Flex} {
        .ant-form-item {
            width: 100%;
        }
    }
`;
