import { MilestoneEmailTestProps } from "@components/challengeMilestonesTable/challengeMilestonesTable";
import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { ChallengeMilestonesCard } from "./challengeMilestonesCard/challengeMilstonesCard";
import { EditChallengeMilestone } from "./editChallengeMilestone/editChallengeMilestone";

export interface ChallengeMilestonesTabProps {
    defaultProps: ChallengePageDefaultProps;
    form: FormInstance;
    hidden: boolean;
    editMilestone(index: number): void;
    milestoneIndex?: number;
    errors?: ErrorDictionary;
    selectedLanguage: string;
    backFromEdit(): void;
    testMilestoneEmailProps: MilestoneEmailTestProps;
}

export const ChallengeMilestonesTab: React.FC<ChallengeMilestonesTabProps> = (props) => {
    const {
        form,
        hidden,
        milestoneIndex,
        editMilestone,
        selectedLanguage,
        errors,
        defaultProps,
        backFromEdit,
        testMilestoneEmailProps
    } = props;

    const conditions = form.getFieldValue("conditions");
    const fetchedMilestones = form.getFieldValue("milestones");

    return (
        <React.Fragment>
            <ChallengeMilestonesCard
                {...defaultProps}
                editMilestone={editMilestone}
                errors={errors}
                form={form}
                hidden={hidden || milestoneIndex !== undefined}
                testMilestoneEmailProps={testMilestoneEmailProps}
            />

            {fetchedMilestones && fetchedMilestones.map((milestone, index) => (
                <EditChallengeMilestone
                    {...defaultProps}
                    key={milestone.id}
                    activeLanguage={selectedLanguage}
                    back={backFromEdit}
                    conditions={conditions}
                    form={form}
                    hidden={hidden || index !== milestoneIndex}
                    index={index}
                    languageErrors={(hasError(errors, [index]) && errorsToLanguageErrors({
                        conditions: errors[index]
                    })) || {}}
                    milestone={milestone}
                />
            ))}
        </React.Fragment>
    );
};
