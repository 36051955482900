import { SortProps } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { WidgetSortProps } from "@components/dashboard/widgets/widgetSort";
import { GetEventTitlesProps, withGetEventTitles } from "@graphql2/types";
import { InjectedIntlProps } from "@utils/override.types";
import { LocalStateProps, localState } from "@utils/withLocalState";
import moment from "moment";
import * as React from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { FixedEventsField } from "./fixedEventsField";

interface LocalState {
    search?: string;
    sort: SortProps;
    events?: string[];
}

const withLocalState = localState<LocalState>({
    search: undefined,
    sort: {
        field: "start",
        order: "",
        fields: ["id", "start", "end", "publishOn", "registration.end"]
    }
});

export interface FixedEventsFieldContainerProps {
    singleMode?: boolean;
    itemKey?: number;
    eventOptions: WidgetSortProps;
    forceSingle?: boolean;
    addSort?: boolean;
}

const withEvents = withGetEventTitles({
    name: "eventTitles",
    options: {
        variables: {
            filter: {
                published: true,
                endsAfter: moment().startOf("day").valueOf()
            }
        }
    }
});

export const FixedEventsFieldContainer = compose<React.ComponentClass<FixedEventsFieldContainerProps>>(
    withLocalState,
    withEvents,
    injectIntl
)(FixedEventsField);

export type FixedEventsFieldProps =
    FixedEventsFieldContainerProps
    & InjectedIntlProps
    & LocalStateProps<LocalState>
    & MaybeElements<GetEventTitlesProps<{}, "eventTitles">>;
