import { ParticipantsTable } from "@components/events/participantsTable";
import ExportEventParticipantsToCsvButton from "@components/export/ExportToCsv/exportEventParticipantsToCsvButton";
import { TableContainer } from "@components/table/tableContainer";
import { tableParticipants } from "@utils/mappers/eventMapper";
import { parseError } from "@utils/parseError";
import { Card, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ParticipantsCardProps } from "./participantsCardContainer";
import { ParticipantsCardStyle, ParticipantsCardTitle } from "./participantsCardStyle";

@autobind
export class ParticipantsCard extends React.Component<ParticipantsCardProps> {
    public render() {
        const { eventParticipants, eventId } = this.props;
        const formData = eventParticipants?.eventParticipants?.length && eventParticipants.eventParticipants[0].formData;
        const formDataKeys = formData ? Object.keys(formData) : [];

        return (
            <ParticipantsCardStyle>
                <Card title={(
                    <ParticipantsCardTitle>
                        <FormattedMessage id="event.participants" />
                        <ExportEventParticipantsToCsvButton
                            eventId={eventId}
                            formDataKeys={formDataKeys}
                        />
                    </ParticipantsCardTitle>
                )}
                >
                    <TableContainer
                        dataSource={tableParticipants(eventParticipants?.eventParticipants || [])}
                        handlers={{
                            updateEventApproval: this.updateEventApproval,
                            removeEventParticipation: this.removeEventParticipation
                        }}
                        loading={eventParticipants?.loading}
                        TableComponent={ParticipantsTable}
                    />
                </Card>
            </ParticipantsCardStyle>
        );
    }

    private async updateEventApproval(userId: string, eventId: string, subEventId: string, approved: boolean) {
        const { updateEventApproval, intl } = this.props;

        try {
            await updateEventApproval({ variables: { userId, eventId, subEventId, approved } });
            message.success(intl.formatMessage({ id: "event.approvalUpdated" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "event.approvalUpdateFailed" }));
            console.error(err);
        }
    }

    private async removeEventParticipation(userId: string, eventId: string, subEventId: string) {
        const { removeEventParticipation, intl } = this.props;

        try {
            await removeEventParticipation({ variables: { userId, eventId, subEventId } });
            message.success(intl.formatMessage({ id: "event.participation.removed" }));
        } catch (err) {
            const submitError = parseError(err, intl);
            message.error(intl.formatMessage({ id: submitError }));
            console.error(err);
        }
    }
}
