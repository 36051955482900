import styled from "styled-components";

export const UploadImageSection = styled.div`
    margin: 0 0 2rem 0;
`;

export const PrizeConfigFields = styled.div`

    .ant-row.ant-form-item {
        margin-bottom: 1rem;
    }

    .ant-row.ant-form-item.hidden {
        margin-bottom: 0;
    }
`;
