import { CarousselWidgetStyle } from "@components/dashboard/widgets/carousselWidget/carousselWidgetStyle";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { Field } from "@components/field/field";
import { Checkbox, Form } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CarousselWidgetPanes } from "@components/dashboard/widgets/carousselWidget/carousselWidgetPanes";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";

export interface Pane {
    title: string;
    slideRender: React.ReactNode;
    key: string;
    type: string;
    imageLoading: boolean;
    image?: string;
    imageUrl?: string;
}

export interface CarousselWidgetProps extends WidgetProps {

}

export const CarousselWidget: React.FC<CarousselWidgetProps> = (props) => {
    const { errors, form, widgetId } = props;

    return (
        <CarousselWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    languageErrors={errors && errors.title}
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <Form.Item
                    name={["widgets", widgetId, "fullWidth"]}
                    valuePropName="checked"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                >
                    <Checkbox>
                        <FormattedMessage id="carousselWidget.fullWidth" />
                    </Checkbox>
                </Form.Item>
                <Field
                    name={["widgets", widgetId, "interval"]}
                    label={<FormattedMessage id="carousselWidget.interval" />}
                    id="interval"
                    numberType
                    min={0}
                />
                <Form.List name={["widgets", widgetId, "items"]}>
                    {(field, { add, remove }) => (
                        <CarousselWidgetPanes
                            errors={widgetId && errors && errors.widgets && errors.widgets[widgetId]}
                            widgetId={widgetId}
                            form={form}
                            fields={field}
                            add={add}
                            remove={remove}
                        />
                    )}
                </Form.List>
            </WidgetConfigStyle>
        </CarousselWidgetStyle>
    );
};
