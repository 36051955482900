import styled from "styled-components";

export interface TimelinePreviewStyleProps {
    progress: number;

    backgroundImage?: string;
    splitterImage?: string;
    foregroundImage?: string;
    finishedImage?: string;
}

export const TimelinePreviewStyle = styled.div<TimelinePreviewStyleProps>`
    position: relative;

    & > div {
        display: relative;
        z-index: 2;
    }

    .selector {
        z-index: 4;
        position: absolute;
    }

    .background-selector {
        left: 10px;
        bottom: 10px;
    }

    .splitter-selector {
        bottom: 35%;
        left: ${props => props.progress}%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .flag-selector {
        top: 35%;
        left: ${props => props.progress}%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .foreground-selector {
        bottom: 10px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .finished-selector {
        bottom: 10px;
        right: 10px;
    }

    div#comparison {
      min-height: 250px;
    }

    div#comparison figure { 
      background-image: url("${props => props.backgroundImage}"); 
      background-size: cover;
      /* position: relative; */
      /* font-size: 0; */
      width: 100%; 
      height: 100%;
      margin: 0; 
    }

    div#comparison img{
      width: 100%;
      height: auto; 
      visibility: hidden;
      position: relative;
      z-index: -1;
    }

    div#comparison figure img#foreground { 
      position: ${props => (props.backgroundImage ? "absolute" : "relative")};
      left: 0px;
      top: 0px;
    }

    div#comparison figure div#divisor { 
      background-image: url("${props => props.foregroundImage}");
      background-size: cover;
      position: absolute;
      width: ${props => props.progress}%; 
      overflow: hidden;
      bottom: 0;
      height: 100%;
    }

    div#comparison figure #splitter { 
      height: 100%;
      width: auto;
      position: absolute;
      left: ${props => props.progress}%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 0;
      visibility: visible;
      z-index: 1;
    }

    div#comparison figure #flagContainer {
      position: absolute;
      left: ${props => props.progress}%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #flagContainer #flag {
      width: auto;
      visibility: visible;
      z-index: 1;
    }

    #flagContainer #progress {
        position: absolute;
        z-index: 2;
    }
    
    input[type=range]{
      -webkit-appearance:none;
      -moz-appearance:none;
      position: absolute;
      top: 0; 
      left: 0;
      background-color: rgba(255,255,255,0.0);
      width: 102%; 
      height: 100%;
      z-index: 2;
    }
    input[type=range]:focus { 
      outline: none; 
    }
    input[type=range]:active { 
      outline: none;  
    }
    
    input[type=range]::-moz-range-track { 
      -moz-appearance:none;
      height:15px;
      width: 98%;
      background-color: none; 
      position: relative;
      outline: none;    
      }

    input[type=range]::active { 
      border: none; 
      outline: none;
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance:none;
      width: 20px; height: 15px;   
      background: #fff;
      border-radius: 0;
    }

    input[type=range]::-moz-range-thumb {
      -moz-appearance: none;
      width: 20px;
      height: 15px;
      background: #fff;
      border-radius: 0;
    }   

    input[type=range]:focus::-webkit-slider-thumb {
      background: rgba(255,255,255,0.0);
    }

    input[type=range]:focus::-moz-range-thumb {
      background: rgba(255,255,255,0.0);
    }    
`;
