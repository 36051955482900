import { FormInstance, RuleObject } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { Moment } from "moment";

export type Validator = (rule: RuleObject, value: Moment | undefined, callback: (error?: string | undefined) => void) => void
export type DateValidator = (
    from: FormInstance<unknown>,
    valuesNameToCompare: (NamePath)[],
    errorMsg: string,
    compare: "before" | "after",
    valueName?: NamePath,
    compareAccuracy?: "days" | "minutes",
) => Validator

export const momentValueComparison: DateValidator = (
    form,
    valuesNameToCompare,
    errorMsg,
    compare,
    valueName,
    compareAccuracy = "days"
) => (_, valueFromData, callback) => {
    const value = valueName ? form.getFieldValue(valueName) : valueFromData;

    for (let i = 0; i < valuesNameToCompare.length; i += 1) {
        const compareValue = form.getFieldValue(valuesNameToCompare[i]);

        if (compare === "after") {
            if (compareValue && value?.isBefore(compareValue, compareAccuracy)) {
                return callback(errorMsg);
            }
        } else if (compareValue && value?.isAfter(compareValue, compareAccuracy)) {
            return callback(errorMsg);
        }
    }
    return callback();
};
