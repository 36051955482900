/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { AddCheckupPageStyle } from "@pages/addCheckupPage/addCheckupPageStyle";
import { coachTypes } from "@utils/coachTypes";
import { getText } from "@utils/getText";
import { DatePicker, Form, Modal, Select, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Field } from "@components/field/field";
import { useAuth } from "@context/authContext/context";
import { GetCheckupLocationsQuery, GetUsersQuery } from "@graphql2/types";
import { CheckupLocationAvailabilityFormValues } from "@pages/addCheckupPage/addCheckupPage";
import { handleError } from "@utils/form/handlers";
import { History } from "history";
import { Moment } from "moment";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export interface CheckupLocationAvailabilityModalFormValues {
    id?: string;
    coach: string;
    date: Moment;
    time: Moment;
    duration: {
        hours: number;
        minutes: number;
    };
    location: string;
    coachType: string;
}

export interface CheckupLocationAvailabilityModalProps {
    history: History;
    id?: string;
    defaultValues: Partial<CheckupLocationAvailabilityModalFormValues>;
    locations: GetCheckupLocationsQuery["checkupLocations"] | undefined;
    coaches: GetUsersQuery["users"] | undefined;

    onSubmit(values: CheckupLocationAvailabilityFormValues): void;
}

const timeFormat = "HH:mm";

export const CheckupLocationAvailabilityModal: FC<CheckupLocationAvailabilityModalProps> = props => {
    const { id, defaultValues, coaches, locations } = props;
    const [form] = useForm();
    const { userId } = useAuth();

    if (!form || (id && !defaultValues.id)) {
        return null;
    }

    const close = () => {
        props.history.goBack();
    };

    return (
        <Modal
            visible
            closable={false}
            title={<FormattedMessage id={id ? "checkup.edit" : "checkup.add"} />}
            width="1000px"
            onCancel={close}
            onOk={async () => {
                try {
                    const f = await form.validateFields() as CheckupLocationAvailabilityFormValues;
                    props.onSubmit(f);
                } catch (error) {
                    handleError(<FormattedMessage id="checkups.formError" />);
                }
            }}
        >
            <AddCheckupPageStyle>
                <Form
                    form={form}
                    initialValues={defaultValues}
                >
                    <SelectField
                        id="coach"
                        label={<FormattedMessage id="coach" />}
                        name="coach"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                    >
                        <Select.Option
                            key={userId ?? ""}
                            value={userId ?? ""}
                        >
                            You
                        </Select.Option>
                        {coaches && coaches.map(coach => (
                            <Select.Option
                                key={coach.id}
                                value={coach.id}
                            >
                                {coach.displayName || `${coach.givenName} ${coach.familyName}`}
                            </Select.Option>
                        ))}
                    </SelectField>
                    <Flex>
                        <Flex direction="inherit">
                            <div>
                                <label><FormattedMessage id="date" /></label>
                                <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id="form.isrequired" />
                                        }
                                    ]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </div>
                            <div>
                                <label><FormattedMessage id="starttime" /></label>
                                <Form.Item
                                    name="time"
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id="form.isrequired" />
                                        }
                                    ]}
                                >
                                    <TimePicker format={timeFormat} />
                                </Form.Item>
                            </div>
                            <div>
                                <label><FormattedMessage id="checkup.duration" /></label>
                                <Field
                                    formatter={value => `${value} h`}
                                    max={23}
                                    min={0}
                                    name={["duration", "hours"]}
                                    parser={value => value && Number(value.replace(" h", "")) || 0}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id="form.isrequired" />
                                        }
                                    ]}
                                />
                            </div>
                            <div>
                                <label>&nbsp;</label>
                                <Field
                                    formatter={value => `${value} m`}
                                    max={59}
                                    min={0}
                                    name={["duration", "minutes"]}
                                    parser={value => value && Number(value.replace(" m", "")) || 0}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id="form.isrequired" />
                                        }
                                    ]}
                                />
                            </div>
                        </Flex>
                    </Flex>
                    <SelectField
                        id="location"
                        label={<FormattedMessage id="location" />}
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                    >
                        {locations && locations.map(location => (
                            <Select.Option
                                key={location.id}
                                value={location.id}
                            >
                                {getText(location.title)}
                            </Select.Option>
                        ))}
                    </SelectField>
                    <SelectField
                        id="coachType"
                        label={<FormattedMessage id="coachType" />}
                        name="coachType"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                    >
                        {coachTypes.map(({ value, label }) => (
                            <Select.Option
                                key={value}
                                value={value}
                            >
                                {label}
                            </Select.Option>
                        ))}
                    </SelectField>
                </Form>
            </AddCheckupPageStyle>
        </Modal>
    );
};
