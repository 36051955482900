import { requiredrule } from "@components/form/requiredRule";
import { Input, Form } from "antd";
import * as React from "react";

export interface DefaultWidgetFieldsProps {
    id: string;
}
export const DefaultWidgetFields: React.FC<DefaultWidgetFieldsProps> = (props) => {
    const { id } = props;

    return (
        <div>
            <Form.Item
                hidden
                name={["widgets", id, "type"]}
                rules={[requiredrule]}
            >
                <Input type="hidden" />
            </Form.Item>
        </div>
    );
};
