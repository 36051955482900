import styled from "styled-components";
import { Flex } from "@components/flex/flex";

export const UserRolesApplicationsCardStyle = styled.div`
    display: flex;

    .ant-row + .ant-row {
        margin-left: 0 !important;
    }

    .ant-card-grid {
        box-shadow: none;
        border-right: 1px solid #E8E8E8;

        &:nth-child(1) {
            padding-left: 0;
        }

        &:last-child {
            border-right: 0 solid #E8E8E8;
        }
    }
`;

export const AccessWrapper = styled(Flex)`
    margin-bottom: 10px;

    & span {
        padding-left: 10px;
    }
`;

export const RolesWrapper = styled(Flex)`
    margin-bottom: 10px;
`;
