import { Ambassador_admin, Article_admin, Challenge_admin, Checkup_location_admin, Content_admin, Credits_admin, Dashboard_admin, Event_Super_admin, Event_admin, Flow_admin, Gallery_admin, Global_goal_admin, Journey_admin, Project_admin, Social_admin, Translation_admin, Translation_super_admin, User_Super_admin, User_admin, Voucher_admin } from "@utils/roles";
import { useHasRole } from "@utils/useHasRole";

export const useGetAllRoles = () => {
    const hasRole = useHasRole();

    const roles = {
        Event_Super_admin: hasRole(Event_Super_admin),
        User_admin: hasRole(User_admin),
        User_Super_admin: hasRole(User_Super_admin),
        Event_admin: hasRole(Event_admin),
        Challenge_admin: hasRole(Challenge_admin),
        Global_goal_admin: hasRole(Global_goal_admin),
        Content_admin: hasRole(Content_admin),
        Social_admin: hasRole(Social_admin),
        Gallery_admin: hasRole(Gallery_admin),
        Journey_admin: hasRole(Journey_admin),
        Checkup_location_admin: hasRole(Checkup_location_admin),
        Voucher_admin: hasRole(Voucher_admin),
        Translation_admin: hasRole(Translation_admin),
        Translation_super_admin: hasRole(Translation_super_admin),
        Article_admin: hasRole(Article_admin),
        Ambassador_admin: hasRole(Ambassador_admin),
        Dashboard_admin: hasRole(Dashboard_admin),
        Credits_admin: hasRole(Credits_admin),
        Project_admin: hasRole(Project_admin),
        Flow_admin: hasRole(Flow_admin)
    };

    return roles;
};
