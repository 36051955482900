import { Card } from "antd";
import styled from "styled-components";

export const AddCardStyles = styled(Card)`
    height: 100%;
    flex: 1;
    margin: 20px 0 !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
    background-color: #FAFAFA;
    border: 1px dashed #D5D5D5;
    width: 100%;
    min-height: inherit;
    cursor: pointer;

    &:hover {
        background-color: #F2F2F2;
    }
`;

export const CardContent = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: rgba(40, 40, 40, .5);

    .anticon {
        margin-right: 5px;
    }
`;
