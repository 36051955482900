import styled from "styled-components";
import { theme } from "@style/theme";

export const CommentCardStyle = styled.div`

    .anticon {
        cursor: pointer;
    }

    i {
        font-style: italic;
        font-weight: 600;
    }

    .red {
        color: ${props => theme.colors.error};
    }

    .green {
        color: ${props => theme.primaryColor};
    }

    .moderatedAndReported, .moderated {
        color: ${props => theme.colors.error};
    }

    .reported {
        color: #FDBF2D;
    }

    .active {
        color: ${props => theme.primaryColor};
    }
`;
