import { AddVoucherProps, withAddVoucher } from "@graphql2/types";
import { VoucherImport } from "@pages/voucherImportPage/voucherImport";
import { InjectedIntlProps } from "@utils/override.types";
import * as React from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";

export interface VoucherImportPageContainerProps {

}

const withAddVoucherMutation = withAddVoucher({
    name: "addVoucher",
    options: {
        refetchQueries: ["getVouchers"],
        awaitRefetchQueries: true
    }
});

export const VoucherImportPageContainer = compose<React.ComponentClass<VoucherImportPageContainerProps>>(
    withAddVoucherMutation,
    injectIntl
)(VoucherImport);

export type VoucherImportProps =
    VoucherImportPageContainerProps
    & AddVoucherProps<{}, "addVoucher">
    & InjectedIntlProps;
