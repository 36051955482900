import { DashboardWidgetConfiguratorStyle, WidgetsColumnCover } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfiguratorStyle";
import { Form, Col, Row, Input } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormInstance } from "antd/lib/form";
import { WidgetObject, WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";
import { DashboardWidgetPreviewCard } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetPreviewCard";
import { FormListFieldData } from "antd/lib/form/FormList";
import { DEVICES } from "@pages/addDashboardPage/constants";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { WidgetPicker } from "../widgetPicker/widgetPicker";

export type ViewType = "desktop" | "tablet" | "mobile";

export interface DashboardWidgetConfiguratorProps {
    form: FormInstance;
    view: ViewType;
    widgets: WidgetObject;
    errors?: ErrorDictionary;
    addWidget(widget: WidgetsFormValuesUnion): void;
    deleteWidget(widgetId: string): void;
    onStatusChange(): void;
    setEditWidgetId(widgetId: string | null): void;
}

const spanTotal = 24;

@autobind
export class DashboardWidgetConfigurator extends React.Component<DashboardWidgetConfiguratorProps> {
    public render() {
        const { view } = this.props;

        return (
            <DashboardWidgetConfiguratorStyle>
                {DEVICES.map(v => (
                    <Row gutter={16} hidden={v !== view} key={v}>
                        <Form.List name={["devices", v, "columns"]}>
                            {(columnFields) => (
                                <React.Fragment>
                                    {this.renderColumns(columnFields, v)}
                                </React.Fragment>
                            )}
                        </Form.List>
                        <Form.Item name={["devices", v, "widths"]}>
                            <Input type="hidden" />
                        </Form.Item>
                    </Row>
                ))}
            </DashboardWidgetConfiguratorStyle>
        );
    }

    private renderColumns(columnFields: FormListFieldData[], view: ViewType) {
        const { form, addWidget, setEditWidgetId } = this.props;
        const widths: number[] = form.getFieldValue(["devices", view, "widths"]);
        const total = widths.reduce((a, b) => a + b, 0);
        
        return columnFields.map((columnField) => {
            const current: number = form.getFieldValue(["devices", view, "widths", columnField.name]);
            const columnWidth = (spanTotal / total) * current;
            return (
                <Col key={columnField.fieldKey} span={columnWidth}>
                    <WidgetsColumnCover>
                        <Form.List name={[columnField.name, "widgets"]}>
                            {(widgetFields, { add, move, remove }) => (
                                <React.Fragment>
                                    {this.renderWidgets(widgetFields, columnField, view, move, remove)}
                                    <WidgetPicker
                                        addWidget={add}
                                        addWidgetToRoot={addWidget}
                                        setEditWidgetId={setEditWidgetId}
                                        view={view}
                                    />
                                </React.Fragment>
                            )}
                        </Form.List>
                    </WidgetsColumnCover>
                </Col>
            );
        });
    }

    private renderWidgets(
        widgetFields: FormListFieldData[],
        columnField: FormListFieldData,
        view: ViewType,
        move: (from: number, to: number) => void,
        remove: (index: number) => void
    ) {
        const { form, widgets, setEditWidgetId, deleteWidget, errors, onStatusChange } = this.props;
        const widgetsPath = ["devices", view, "columns", columnField.name, "widgets"];

        return widgetFields.map((widgetField, widgetIndex) => {
            const columnWidget = form.getFieldValue([...widgetsPath, widgetField.name]);
            const widget = widgets[columnWidget.id];

            if (!widget) {
                console.error("Widget doesn't exist");
                return null;
            }

            return (
                <DashboardWidgetPreviewCard
                    key={widgetField.key}
                    columnIndex={columnField.name}
                    form={form}
                    widgetIndex={widgetIndex}
                    widget={widget}
                    prefix={widgetsPath}
                    onMove={onStatusChange}
                    move={move}
                    remove={remove}
                    onStatusChange={onStatusChange}
                    deleteWidget={deleteWidget}
                    setEditWidgetId={setEditWidgetId}
                    hasError={hasError(errors, ["widgets", columnWidget.id])}
                />
            );
        });
    }
}
