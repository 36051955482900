import { getText } from "@utils/getText";
import * as moment from "moment";
import { AddCheckupLocationAvailabilityMutationVariables, GetCheckupLocationsQuery } from "@graphql2/types";

function findDelimeter(firstLine: string): "," | ";" {
    const commaItems = firstLine.split(",");
    const semiColonItems = firstLine.split(";");

    if (commaItems.length > semiColonItems.length) {
        return ",";
    }

    return ";";
}

export function findDateFormat(date: string): string {
    if (!date || date.indexOf("/") === -1) {
        throw Error("Not a valid format");
    }

    const dateParts = date.split("/");
    const dayFormat = "D".repeat(dateParts[0].length);
    const monthFormat = "M".repeat(dateParts[1].length);
    const yearFormat = "Y".repeat(dateParts[2].length);

    return `${dayFormat}/${monthFormat}/${yearFormat}`;
}

export function csvToCheckups(csv: string, locations: GetCheckupLocationsQuery["checkupLocations"], coachId: string): AddCheckupLocationAvailabilityMutationVariables[] {
    const lines = csv.split("\n");

    if (!lines || !lines[0]) {
        return [];
    }
    const delimiter = findDelimeter(lines[0]);
    const lineParts = lines.map(line => line.split(delimiter));
    const header = lineParts.shift();

    let locationIndex: number = -1;
    let dateIndex: number = -1;
    let hourIndex: number = -1;
    let durationIndex: number = -1;
    let coachTypeIndex: number = -1;

    if (header) {
        header.forEach((key, index) => {
            switch (key.replace("\n", "")) {
                case "location":
                    locationIndex = index;
                    break;
                case "date":
                    dateIndex = index;
                    break;
                case "hour":
                    hourIndex = index;
                    break;
                case "duration":
                    durationIndex = index;
                    break;
                case "coachType":
                    coachTypeIndex = index;
                    break;
                default:
            }
        });
    }

    if (locationIndex === -1) {
        throw Error("No locationIndex found");
    }

    if (dateIndex === -1) {
        throw Error("No dateIndex found");
    }

    if (hourIndex === -1) {
        throw Error("No hourIndex found");
    }

    if (durationIndex === -1) {
        throw Error("No durationIndex found");
    }

    if (coachTypeIndex === -1) {
        throw Error("No coachTypeIndex found");
    }

    const locationLookup = locations.reduce(
        (p, c) => ({
            ...p,
            [getText(c.title).toLowerCase()]: c.id
        }),
        {}
    );

    return lineParts.filter(p => p && p[0]).map(linePart => {
        const format = findDateFormat(linePart[dateIndex]);
        const from = moment(linePart[dateIndex], format);
        const hourParts = linePart[hourIndex].split(":");
        from.set("hour", Number(hourParts[0]));
        from.set("minute", Number(hourParts[1]));
        const durationParts = linePart[durationIndex].split("h");
        const hourDuration = Number(durationParts[0]);
        const minDuration = Number(durationParts[1].replace("m", ""));
        const to = moment(from).add(hourDuration, "hour").add(minDuration, "minute");
        return {
            checkupLocationId: locationLookup[linePart[locationIndex].toLowerCase()],
            coachId,
            coachType: linePart[coachTypeIndex].toLowerCase(),
            cost: {
                amount: 0,
                description: []
            },
            from: from.valueOf(),
            to: to.valueOf()
        };
    });
}
