import styled from "styled-components";
import { Flex } from "@components/flex/flex";

export const SocialPageStyle = styled.div`
    .ant-table-tbody > tr > td:nth-child(2) {
        padding-top: 0;
    }

    .ant-table-row .anticon {
        margin-left: 10px
    }
`;

export const FlexButtonWrapper = styled(Flex)`
    button {
        margin-top: 15px;
        width: 100%;
    }
`;

export interface IgImageProps {
    image: string;
}

export const IGImage = styled.div<IgImageProps>`
    position: absolute;
    background: url(${props => props.image});
    background-size: cover;
    width: 70px;
    height: 70px;
`;
