import { CreditConfigFormValues } from "@pages/creditsPage/creditsPage";
import moment from "moment";

export const emptyCreditConfig: CreditConfigFormValues = {
    enableCollectionPeriod: false,
    from: moment(),
    to: moment(),
    enableSpentCreditsLimit: false,
    spentCreditsLimitValue: undefined,
    countForLastXDays: undefined,
    countInCollectionPeriod: false
};
