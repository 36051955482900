import uuidv4 from "uuid/v4";

export interface ActivityConditionFactoryProps {

}

export const activityConditionFactory = () => {
    return {
        id: uuidv4(),
        type: "activity",
        startTime: undefined,
        endTime: undefined,
        activities: [],
        value: 0,
        unit: "",
        unitType: "",
        inOneGo: {
            enabled: false,
            repeat: 0
        },
        timespan: {
            enabled: false,
            unit: "",
            value: 0
        },
        repeated: {
            enabled: false,
            sequential: false,
            value: 0
        }
    };
};
