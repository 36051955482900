import styled from "styled-components";
import { theme } from "@style/theme";

export const ParticipantsCardStyle = styled.div`
`;

export const ParticipantsCardTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
