import { Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData } from "@components/table/tableContainer";
import { OrderIconWrapper } from "@components/challengeConditionsTable/challengeConditionsTableStyle";
import Column from "antd/lib/table/Column";
import { CaretUpFilled, CaretDownFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormListOperation } from "antd/lib/form/FormList";
import { renderErrorColumn } from "@components/table/errorColumn";

export interface SubEventsTableData extends TableData {
    sequence: number;
    eventId: string;
    title: string;
    hasError: boolean;
}

export interface SubEventsTableProps extends TableComponentProps {
    dataSource: SubEventsTableData[];
    moveSubEvent: FormListOperation["move"];
    editMode?: boolean;
    deleteContent: FormListOperation["remove"];
    editContent(index: number): void;
    isNotEditable?: boolean
}

export const SubEventsTable: React.FC<SubEventsTableProps> = (props) => {
    return (
        <Table<SubEventsTableData> pagination={{ showSizeChanger: true, showQuickJumper: true }} rowKey="id" {...props}>
            <Column
                title=""
                key="sequenceChanger"
                dataIndex=""
                width={70}
                render={(_text, _row, index) => ({
                    children: (
                        <OrderIconWrapper>
                            <CaretUpFilled onClick={() => props.moveSubEvent(index, index - 1)} />
                            <CaretDownFilled onClick={() => props.moveSubEvent(index, index + 1)} />
                        </OrderIconWrapper>
                    )
                })}
            />
            <Column<SubEventsTableData>
                key="sequence"
                title={<FormattedMessage id="sequence" />}
                dataIndex="sequence"
                width={150}
                render={(_text, row) => row.sequence}
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
            />
            {renderErrorColumn()}
            <Column<SubEventsTableData>
                title=""
                dataIndex=""
                render={(_text, row) => ({
                    children: (
                        <Flex justifyContent="flex-end" alignItems="center">
                            <TableIcon onClick={() => props.editContent(row.sequence)} as={EditOutlined} />
                            {!props.isNotEditable && <TableIcon onClick={() => props.deleteContent(row.sequence)} as={DeleteOutlined} />}
                        </Flex>
                    ),
                    props: {
                        colSpan: 2
                    }
                })}
            />
        </Table>
    );
};

