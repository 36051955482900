import styled from "styled-components";

export const FileDragAndDropStyle = styled.div`
    text-align: center;

    img {
        width: 200px;
        max-width: 100%;
        display: block;
        margin: 1em auto;
    }

    .ant-btn {
        margin-top: 16px;
    }

    .anticon {
        font-size: 2em;
        display: block;
        margin: 0 auto;
    }
`;
