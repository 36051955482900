import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, CheckCircleFilled, MinusCircleFilled, EditOutlined } from "@ant-design/icons";

import { Flex } from "@components/flex/flex";
import { TableIcon } from "@components/table/tableStyle";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";

export interface DashboardTableData extends TableData {
    place: string;
    dashboardsAmount: number;
    sequence?: number | null;
    numberOfColumns: {
        desktop: number;
        tablet: number;
        mobile: number;
    };
    status: boolean;
    statusType: string;
}

export interface DashboardTableProps extends TableComponentProps {
    dataSource: DashboardTableData[];
    handlers: TableHandlers & {
        removeDashboard(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<DashboardTableData>;
}

// tslint:disable-next-line: max-func-body-length
export const DashboardTable: React.FC<DashboardTableProps> = ({ handlers, ...tableProps }) => {
    const renderPlace = (text, row: DashboardTableData) => (
        <Link className="plainText" to={`/dashboards/edit/desktop/${row.id}`}>
            {text}
        </Link>
    );

    const renderSequence = (sequence, row: DashboardTableData) => (
        <Link className="plainText" to={`/dashboards/edit/desktop/${row.id}`}>
            {sequence}
        </Link>
    );

    const renderStatus = (status, statusType) => {
        if (status && statusType === "active") {
            return (
                <Tooltip title={statusType}>
                    <CheckCircleFilled className="green" />
                </Tooltip>
            );
        }

        return (
            <Tooltip title={statusType}>
                <MinusCircleFilled className={statusType} />
            </Tooltip>
        );
    };

    const renderNumberOfColumns = (amount, row: DashboardTableData): JSX.Element => (
        <React.Fragment>
            {Object.keys(amount).map(type => (
                <FormattedMessage key={type} id={type}>
                    {txt => (
                        <p>
                            <Link className="plainText" to={`/dashboards/edit/${type}/${row.id}`}>
                                {`${txt}: ${amount[type]}`}
                            </Link>
                        </p>
                    )}
                </FormattedMessage>
            ))}
        </React.Fragment>
    );

    const renderHandlers = (row: DashboardTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <Link to={`/dashboards/edit/desktop/${row.id}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeDashboard(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="expiresOn"
                title={<FormattedMessage id="status" />}
                dataIndex="status"
                width={95}
                render={(text, row: DashboardTableData) => renderStatus(text, row.statusType)}
                sorter
            />
            <Column
                key="place"
                title={<FormattedMessage id="place" />}
                dataIndex="place"
                sorter
                render={renderPlace}
            />
            <Column
                key="sequence"
                title={<FormattedMessage id="sequence" />}
                dataIndex="sequence"
                render={renderSequence}
                sorter
            />
            <Column
                key="numberOfColumns"
                title={<FormattedMessage id="numberOfColumns" />}
                dataIndex="numberOfColumns"
                render={renderNumberOfColumns}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: DashboardTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
