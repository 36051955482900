/* eslint-disable complexity */
import { ChronoTableData } from "@components/events/chronoTable";
import { EventTableData } from "@components/events/eventTable";
import { ParticipantsTableData } from "@components/events/participantsTable";
import { SubEventsTableData } from "@components/events/subEventsTable";
import {
    AddEventMutationVariables,
    ChronoRaceEventFragment,
    EventFragment,
    EventParticipantFragment,
    EventProjectDetails,
    GetEventTitlesQuery,
    GetEventsOverviewQuery,
    Role,
    SubEventProjectDetails
} from "@graphql2/types";
import { EventFormValues, SubEventErrors } from "@pages/addEventsPage/addEventsPage";
import {
    EventProjectsFormValue,
    EventSubEventFormValue
} from "@pages/addEventsPage/subEvents/eventSubEventsOverviewCard";
import { getEmptyEvent } from "@utils/emptyItems/emptyEvent";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { getText } from "@utils/getText";
import { hasError } from "@utils/mapValidateErrorEntity";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import * as moment from "moment";

export const translateEventTitles = (originals: GetEventTitlesQuery["events"]) => {
    return originals.map((event) => {
        const { id, title, start, end } = event;

        return {
            id,
            title: title ? getText(title) : "",
            start,
            end
        };
    });
};

export const getParticipantsNumber = (event: GetEventsOverviewQuery["events"][0]|null, projectId: string) => {
    let participants: number|undefined;
    if (!event) {
        return participants;
    }

    const { projects } = event;

    if (projects) {
        const project = projects.find(p => (p && p.projectId) === projectId);
        participants = project?.participants ?? undefined;
    }
    return participants;
};

const mapEventRolesIdsToNames = (eventProjects: EventProjectDetails[], roles: Role[]): Role["name"][] => {
    const eventRolesNames: string[] = [];

    eventProjects.forEach((project) => {
        project?.roles?.forEach((projectRole) => {
            const roleObject = roles.find((role) => role.id === projectRole);

            if (roleObject?.name) {
                eventRolesNames.push(roleObject.name);
            }
        });
    });

    return eventRolesNames;
};

export const translateEvents = (events: GetEventsOverviewQuery["events"], projectId: string, roles?: Role[]): Array<EventTableData & { roles?: Role["name"][]; }> => {
    return events.map(event => {
        const { id, title, chronoraceEventId, start, end, registration, publishedOn, projects } = event;

        const participants = getParticipantsNumber(event, projectId);
        const now = Date.now();
        const startTime = start || 0;
        const endTime = end || 0;
        const { start: registerStart, end: registerEnd } = registration || { start: 0, end: 0 };
        const registrationsOpen = (registerStart || 0) <= now && now <= (registerEnd || 0) ? 1 : 0;

        return {
            id,
            registrationsOpen,
            chrono: Boolean(chronoraceEventId),
            title: title ? getText(title) : "",
            time: [startTime, endTime],
            participants,
            status: (publishedOn && publishedOn < moment().valueOf()) || false,
            statusType: (publishedOn && publishedOn > moment().valueOf()) ? "notPublishedYet" : "active",
            ...(roles && { roles: mapEventRolesIdsToNames((projects as EventProjectDetails[] | undefined) ?? [], roles) })
        };
    });
};

export const projectEventSubEventsToFormValues = (events: SubEventProjectDetails[]): EventProjectsFormValue["subEvents"] => {
    if (!events) {
        return [];
    }

    return events.map(event => ({
        id: event.id || "",
        maxParticipants: event.maxParticipants || 0,
        participantOffset: event.participantOffset || 0,
        participationFull: Boolean(event.participationFull),
        subscriptionEnabled: Boolean(event.subscriptionEnabled),
        maxParticipantsDisabled: Boolean(event.maxParticipantsDisabled)
    }));
};

export const eventSubEventsToFormValues = (events: EventFragment["subEvents"]): EventFormValues["subEvents"] => {
    if (!events) {
        return [];
    }

    return events.filter(getOutFalseValues).map(event => ({
        chronoraceSubEventId: event && event.chronoraceSubEventId,
        date: moment(event.date || undefined),
        endDate: moment(event.endDate || undefined),
        id: (event && event.id) || "",
        info: (event && event.info) || "",
        sequence: (event && event.sequence) || 0,
        title: (event && toLanguageObject(event.title)) || {}
    }));
};

export const eventProjectToFormValues = (projects: EventFragment["projects"]): EventFormValues["projects"] => {
    if (!projects) {
        return [];
    }

    return projects.filter(getOutFalseValues).map(p => ({
        description: (p.description && toLanguageObject(p.description)) || {},
        featured: Boolean(p && p.featured),
        formSubscriptionEnabled: Boolean(p && p.formSubscriptionEnabled),
        formTemplates: (p && p.formTemplateIds?.filter(getOutFalseValues)) || [],
        participants: (p && p.participants) || 0,
        participantsOnWaitingList: (p && p.participantsOnWaitingList) || 0,
        projectId: (p && p.projectId) || "",
        reminders: (p && p.reminders?.filter(getOutFalseValues)) || [],
        daysToFinalEditReminder: (p && p.daysToFinalEditReminder) || null,
        roles: (p && p.roles?.filter(getOutFalseValues)) || [],
        subscriptionEnabled: Boolean(p && p.subscriptionEnabled),
        tags: p && p.tags ? p.tags.filter(getOutFalseValues).map(pt => pt.id) : [],
        viewParticipantCount: p && Boolean(p.viewParticipantCount),
        viewParticipants: p && Boolean(p.viewParticipants),
        waitingListEnabled: p && Boolean(p.waitingListEnabled),
        subEvents: projectEventSubEventsToFormValues((p && p.subEvents?.filter(getOutFalseValues)) || [])
    }));
};

export const eventToFormValues = (event: EventFragment): EventFormValues => {
    if (!event) {
        return getEmptyEvent();
    }

    const {
        id,
        title,
        description,
        image,
        start,
        end,
        registration,
        expiresOn,
        activityTypes,
        hideInList,
        disableComments,
        url,
        projects,
        subEvents,
        publishedOn
    } = event;

    return {
        id,
        title: toLanguageObject(title),
        description: toLanguageObject(description),
        image: image || "",
        start: moment(start || undefined),
        end: moment(end || undefined),
        registration: {
            start: moment((registration && registration.start) || undefined),
            end: moment((registration && registration.end) || undefined)
        },
        publishedOn: moment(publishedOn),
        expiresOn: moment(expiresOn),
        activityTypes: activityTypes?.filter(getOutFalseValues) || [],
        hideInList: Boolean(hideInList),
        disableComments: Boolean(disableComments),
        subscriptionEnabled: false,
        viewParticipantCount: false,
        viewParticipants: false,
        enableWaitingList: false,
        organizer: event.organizator || "",
        url: url || "",
        subEvents: eventSubEventsToFormValues(subEvents),
        projects: eventProjectToFormValues(projects)
    };
};

const getEndDate = (endDate: moment.Moment|undefined|null, startDate: moment.Moment): number => {
    const result: moment.Moment = endDate || startDate;
    return result.utc().endOf("day").valueOf();
};

export const formValuesToEvent = (formValues: EventFormValues): AddEventMutationVariables => {
    const {
        title,
        description,
        start,
        end,
        registration,
        publishedOn,
        expiresOn,
        subEvents,
        projects
    } = formValues;

    return {
        ...formValues,
        title: toTextByLanguage(title),
        description: toTextByLanguage(description),
        start: start.utc().valueOf(),
        end: end.utc().valueOf(),
        registration: {
            start: registration.start.utc().valueOf(),
            end: registration.end.utc().valueOf()
        },
        publishedOn: publishedOn.utc().valueOf(),
        expiresOn: expiresOn.utc().valueOf(),
        subEvents: subEvents && subEvents.map(subEvent => ({
            ...subEvent,
            title: toTextByLanguage(subEvent.title),
            date: subEvent.date.utc().valueOf(),
            endDate: getEndDate(subEvent.endDate, subEvent.date)
        })),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        projects: projects && projects.map(({ participants, participantsOnWaitingList, ...project }) => {
            return ({
                ...project,
                description: toTextByLanguage(project.description),
                subEvents: (project.subEvents || []).map(s => ({
                    ...s
                }))
            });
        }),
        organizator: formValues.organizer
    };
};

export const translateSubEvents = (
    subEvents: EventSubEventFormValue[],
    eventId: string,
    errors: SubEventErrors[]
): SubEventsTableData[] => {
    return (subEvents || []).map((subEvent, i) => {
        const { sequence, title, id } = subEvent;

        return {
            id,
            sequence: i,
            eventId,
            title: title ? getText(title) : "",
            hasError: hasError(errors, [sequence])
        };
    });
};

export const mapToChronoTable = (chronoEvents: ChronoRaceEventFragment[]): ChronoTableData[] => {
    return chronoEvents.map(event => {
        const { id, title, start, end, registrationAllowed } = event;

        return {
            id: String(id),
            allowRegistration: Boolean(registrationAllowed),
            title: title || "",
            time: [(start || 0), (end || 0)]
        };
    });
};

export const tableParticipants = (eventParticipants: EventParticipantFragment[]): ParticipantsTableData[] => {
    return eventParticipants.map(ep => {
        const {
            approved,
            registrationComplete,
            onWaitingList,
            participatedOn,
            user,
            eventId,
            subEventId,
            subEvent
        } = ep;

        return {
            id: "",
            approved: Boolean(approved),
            registrationComplete: Boolean(registrationComplete),
            onWaitingList: Boolean(onWaitingList),
            participatedOn: participatedOn || 0,
            avatar: (user && user.avatar) || "",
            name: user && user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : "/",
            email: (user && user.email) || "",
            userId: (user && user.id) || "",
            eventId: eventId || "",
            subEventId: subEventId || "",
            subEvent: subEvent || null
        };
    });
};
