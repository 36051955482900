
import { GetApplicationsProps, GetFlowsCountProps, GetFlowsProps, GetSessionProps, RemoveFlowProps, withGetFlows, withGetFlowsCount, withGetSession, withRemoveFlow } from "@graphql2/types";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { FlowsPage } from "./flowsPage";

interface FlowsPageFilterBarProps extends GraphQLPagingAndSort {}

const withFlowsCount = withGetFlowsCount({
    name: "count",
    options({ session: { session } }: FlowsPageProps) {
        return {
            variables: {
                filter: {
                    projectId: session?.projectId
                }
            }
        };
    }
});

const withSession = withGetSession({
    name: "session"

});

const withFlows = withGetFlows({
    name: "flows",
    options({ paging, sort, session: { session } }: FlowsPageProps) {
        return {
            variables: {
                paging,
                sort,
                filter: {
                    projectId: session?.projectId
                }
            }
        };
    }
});

const withRemoveFlowMutation = withRemoveFlow({
    name: "removeFlow",
    options: {
        refetchQueries: () => ["getFlows", "getFlowsCount"],
        awaitRefetchQueries: true
    }
});

export const FlowsPageContainer = compose<React.ComponentClass>(
    withRouter,
    withSession,
    withFlows,
    withFlowsCount,
    withRemoveFlowMutation
)(FlowsPage);

export type FlowsPageProps =
    FlowsPageFilterBarProps &
    RouteComponentProps &
    GetSessionProps<{}, "session"> &
    GetApplicationsProps<{}, "applications"> &
    GetFlowsProps<{}, "flows"> &
    GetFlowsCountProps<{}, "count"> &
    RemoveFlowProps<{}, "removeFlow">;
