/* eslint-disable react/prop-types */
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { HeaderContent } from "@components/headerContent/headerContent";
import { RolesTable } from "@components/roles/rolesTable";
import { TableContainer } from "@components/table/tableContainer";
import { useAuth } from "@context/authContext/context";
import { ApplicationFragment } from "@graphql2/types";
import { RolesPageProps } from "@pages/rolesPage/rolesPageContainer";
import { FlexButtonWrapper, RolesPageStyle } from "@pages/rolesPage/rolesPageStyle";
import { applicationsToRoles } from "@utils/mappers/roleMapper";
import { Button, Card, Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generateRoleId } from "./util/generateRoleId";

export type RoleType = /* ApplicationFragment["roles"] */ ApplicationFragment["roles"][0] & { applicationId: string; };

export const RolesPage: React.FC<RolesPageProps> = (props) => {
    const { applications: { loading: applicationsLoading, applications } } = props;
    const [showModal, setShowModal] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [role, setRole] = React.useState<RoleType | undefined>();
    const [form] = useForm();
    const intl = useIntl();
    const { projectId } = useAuth();

    const selectRole = (r) => {
        form.setFieldsValue(r);
        setShowModal(true);
        setRole(r);
    };

    const clearRole = () => {
        setRole(undefined);
        setShowModal(false);
        form.resetFields();
    };

    const removeRole = async (roleId: string, applicationId: string) => {
        setSaving(true);

        try {
            await props.removeRole({ variables: { roleId, applicationId, projectId } });
            message.success(intl.formatMessage({ id: "role.removed" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "role.removeFailed" }));
            console.error(err);
        }

        setSaving(false);
    };

    const submitForm = async (values: RoleType) => {
        const { addRole, updateRole } = props;

        setSaving(true);
        if (role) {
            try {
                await updateRole({ variables: { ...values, projectId } });
                message.success(intl.formatMessage({ id: "role.updated" }));
                clearRole();
            } catch (err) {
                message.error(intl.formatMessage({ id: "role.updateFailed" }));
            }
        } else {
            try {
                await addRole({ variables: { ...values, id: generateRoleId(applications || [], values.applicationId), projectId } });
                message.success(intl.formatMessage({ id: "role.added" }));
                clearRole();
            } catch (err) {
                message.error(intl.formatMessage({ id: "role.addFailed" }));
            }
        }

        setSaving(false);
    };

    return (
        <RolesPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage id="roles" />
                    <div>
                        <Button
                            key="button1"
                            type="primary"
                            onClick={() => setShowModal(true)}
                        >
                            <FormattedMessage id="role.addRole" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    dataSource={applicationsToRoles(applications)}
                    handlers={{
                        removeRole,
                        selectRole
                    }}
                    loading={applicationsLoading}
                    TableComponent={RolesTable}
                    tableFilter={{}}
                />
            </Card>
            <Modal
                className="noModalFooter"
                visible={showModal}
                onCancel={clearRole}
            >
                <Form
                    form={form}
                    onFinish={submitForm}
                >
                    <Field
                        hidden
                        disabled={Boolean(role && role.id)}
                        id="id"
                        label={<FormattedMessage id="id" />}
                        name="id"
                        type="text"
                    />
                    <Field
                        id="id"
                        label={<FormattedMessage id="name" />}
                        name="name"
                        rules={[requiredrule]}
                        type="text"
                    />
                    <Field
                        id="id"
                        label={<FormattedMessage id="description" />}
                        name="description"
                        type="text"
                    />
                    <SelectField
                        id="applicationId"
                        label={<FormattedMessage id="applicationId" />}
                        name="applicationId"
                    >
                        {applications && applications.map(a => a.name.indexOf("admin") === -1 && (
                            <Select.Option
                                key={a.id}
                                value={a.id}
                            >
                                {a.name}
                            </Select.Option>
                        ))}
                    </SelectField>
                    <FlexButtonWrapper
                        fullWidth
                        justifyContent="center"
                        margin="20px 0"
                    >
                        <Button
                            key="button1"
                            htmlType="submit"
                            loading={saving}
                            type="primary"
                        >
                            <FormattedMessage id="role.save" />
                        </Button>
                    </FlexButtonWrapper>
                </Form>
            </Modal>
        </RolesPageStyle>
    );
};
