import { WidgetType } from "@components/dashboard/widgetPicker/widgetsList/widgets";
import { WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";
import { toLanguageObject } from "@utils/toLanguageObject";
import uuidv4 from "uuid/v4";

const getEmptyChallengeWidget = () => ({
    id: uuidv4(),
    mode: "fixed",
    title: toLanguageObject(),
    challengeIds: [],
    displayType: "card",
    fullWidth: false,
    paging: {
        size: 5
    },
    sort: null,
    type: "challenge"
});

const getEmptyEventWidget = () => ({
    id: uuidv4(),
    mode: "fixed",
    title: toLanguageObject(),
    eventIds: [],
    displayType: "card",
    fullWidth: false,
    paging: {
        size: 5
    },
    sort: null,
    type: "event"
});

const getEmptyContentWidget = () => ({
    id: uuidv4(),
    mode: "fixed",
    title: toLanguageObject(),
    contentIds: [],
    displayType: "card",
    fullWidth: false,
    paging: {
        size: 5
    },
    sort: null,
    type: "content"
});

const getEmptyGenericWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    description: toLanguageObject(),
    subType: "userCounter",
    fullWidth: false,
    type: "generic"
});

const getEmptyVideoWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    video: toLanguageObject(),
    source: "video",
    fullWidth: false,
    type: "video"
});

const getEmptyActivityWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    fullWidth: false,
    type: "activity",
    history: {
        duration: "P7D"
    }
});

const getEmptyCarousselWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    fullWidth: false,
    type: "caroussel"
});

const getEmptyChallengeVisualisationWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    fullWidth: false,
    type: "challengeVisualisation"
});

const getEmptyStatisticsWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    fullWidth: false,
    type: "statistics",
    items: []
});

const getEmptyCommunityWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    fullWidth: false,
    type: "community",
    disableNewUsersView: false,
    disableUsersCount: false,
    disableInviteAbility: false
});

const getEmptyPopupWidget = () => ({
    id: uuidv4(),
    title: toLanguageObject(),
    callToActionText: toLanguageObject(),
    description: toLanguageObject(),
    url: "",
    image: "",
    fullWidth: false,
    type: "popup"
});

export const widgetFactory = (type: WidgetType): WidgetsFormValuesUnion => {
    switch (type) {
        case "challenge":
            return getEmptyChallengeWidget() as WidgetsFormValuesUnion;

        case "event":
            return getEmptyEventWidget() as WidgetsFormValuesUnion;

        case "content":
            return getEmptyContentWidget() as WidgetsFormValuesUnion;

        case "caroussel":
            return getEmptyCarousselWidget() as WidgetsFormValuesUnion;

        case "video":
            return getEmptyVideoWidget() as WidgetsFormValuesUnion;

        case "activity":
            return getEmptyActivityWidget() as WidgetsFormValuesUnion;

        case "challengeVisualisation":
            return getEmptyChallengeVisualisationWidget() as WidgetsFormValuesUnion;

        case "statistics":
            return getEmptyStatisticsWidget() as WidgetsFormValuesUnion;

        case "community":
            return getEmptyCommunityWidget() as WidgetsFormValuesUnion;

        case "popup":
            return getEmptyPopupWidget() as WidgetsFormValuesUnion;

        case "generic":
        default:
            return getEmptyGenericWidget() as WidgetsFormValuesUnion;
    }
};
