import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { InternalNamePath } from "antd/lib/form/interface";
import { TitleSubTitleDescriptionFieldsStyle } from "./widgetStyle";
import { useCurrentLanguage } from "../../../hooks/useCurrentLanguage";

export interface TitleSubtitleDescriptionFormValues {
    title: LanguageObject;
    subtitle: LanguageObject;
    description: LanguageObject;
}

export interface TitleSubtitleDescriptionCardProps {
    unsaved?: boolean;
    languageErrors?: LanguageErrors;
    disableTitle?: boolean;
    disableDescription?: boolean;
    disableSubtitle?: boolean;
    itemKey?: number;
    widgetId: string;
    prefix?: InternalNamePath;
}

export interface TitleSubtitleDescriptionCardState {
    selectedLanguage: string;
    values: Record<string, string>;
}

export const TitleSubtitleDescriptionCard: React.FC<TitleSubtitleDescriptionCardProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const { languageErrors, widgetId, disableDescription, disableSubtitle, itemKey, prefix } = props;
    const langFieldProps = languages.map(language => ({
        itemKey, language: language.code, hidden: language.code !== selectedLanguage
    }));

    const path = prefix || ["widgets", widgetId];

    return (
        <TitleSubTitleDescriptionFieldsStyle>
            <TranslationTabs
                activeLanguage={selectedLanguage}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
                errors={languageErrors}
            />
            {langFieldProps.map(langProps => (
                <React.Fragment key={langProps.language}>
                    <Field
                        name={[...path, "title", langProps.language]}
                        hidden={langProps.hidden}
                        label={<FormattedMessage id="title" />}
                        id={`title.${langProps.language}`}
                    />
                    {!disableSubtitle && (
                        <Field
                            name={[...path, "subtitle", langProps.language]}
                            hidden={langProps.hidden}
                            label={<FormattedMessage id="subtitle" />}
                            info={maxCharacters()}
                            id={`subtitle.${langProps.language}`}
                        />
                    )}
                    {!disableDescription && (
                        <Field
                            name={[...path, "description", langProps.language]}
                            hidden={langProps.hidden}
                            label={<FormattedMessage id="description" />}
                            info={maxCharacters()}
                            id={`description.${langProps.language}`}
                        />
                    )}
                </React.Fragment>
            ))}
        </TitleSubTitleDescriptionFieldsStyle>
    );
};
