/* eslint-disable complexity */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { FormModal } from "@components/modal/modal";
import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { ChallengeLeaderboardVisualisationFormValues, EditChallengeLeaderboardVisualisationCard } from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisation";
import { EditChallengeTimelineGoal } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineGoal/editChallengeTimelineGoal";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { setArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "../conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { ChallengeVisualisationsCard } from "./challengeVisualisationsCard/challengeVisualisationsCard";
import { ChallengeGridVisualisationFormValues, EditChallengeGridVisualisationCard } from "./editChallengeGridVisualisation/editChallengeGridVisualisation";
import { EditChallengeTimelineCounter } from "./editChallengeTimelineVisualisation/editChallengeTimelineCounter/editChallengeTimelineCounter";
import { EditChallengeTimelineRepeater } from "./editChallengeTimelineVisualisation/editChallengeTimelineRepeater/editChallengeTimelineRepeater";
import { ChallengeTimelineVisualisationFormValues, EditChallengeTimelineVisualisationCard } from "./editChallengeTimelineVisualisation/editChallengeTimelineVisualisation";

export interface ChallengeVisualisationsTabProps {
    defaultProps: ChallengePageDefaultProps;
    hidden: boolean;
    editVisualisation(index: number): void;
    form: FormInstance;
    visualisationIndex?: number;
    selectedLanguage: string;
    errors?: ErrorDictionary;
    changeSelectedLanguage(language): void;
    backFromEdit(): void;
}

// tslint:disable-next-line:max-func-body-length
export const ChallengeVisualisationsTab: React.FC<ChallengeVisualisationsTabProps> = (props) => {
    const {
        form,
        hidden,
        editVisualisation,
        backFromEdit,
        visualisationIndex,
        selectedLanguage,
        errors,
        defaultProps
    } = props;

    const [counterIndex, setCounterIndex] = React.useState<number>();

    const [showTimelineForegroundImage, setShowTimelineForegroundImage] = React.useState<boolean>(false);
    const [showTimelineBackgroundImage, setShowTimelineBackgroundImage] = React.useState<boolean>(false);
    const [showTimelineSplitterImage, setShowTimelineSplitterImage] = React.useState<boolean>(false);
    const [showTimelineFlagImage, setShowTimelineFlagImage] = React.useState<boolean>(false);
    const [showTimelineFinishedImage, setShowTimelineFinishedImage] = React.useState<boolean>(false);

    const [showTimelineRepeater, setShowTimelineRepeater] = React.useState<boolean>();
    const [showTimelineGoal, setShowTimelineGoal] = React.useState<boolean>();
    const visualisations = form.getFieldValue("visualisations");
    const conditions = form.getFieldValue("conditions");

    const renderImageField = (fields: string[], defaultValue: string, label: string, index: number, required?: boolean) => {
        const name = ["visualisations", (index || 0), ...fields];
        return (
            <Form.Item dependencies={[name]}>
                {() => (
                    <div>
                        <Label><FormattedMessage id={label} /></Label>
                        <ImageUpload
                            form={form}
                            image={form.getFieldValue(name) || defaultValue}
                            name={name}
                            required={required}
                        />
                    </div>
                )}
            </Form.Item>
        );
    };

    const renderImageModal = (
        hideModal: boolean,
        onCancel: (n: boolean) => void,
        onOk: (n: boolean) => void,
        fields: string[],
        defaultValue: string,
        index: number,
        required: boolean
    ) => {
        return (
            <FormModal
                cancelButtonProps={{ onClick: () => {
                    onCancel(false);
                } }}
                hidden={hideModal}
                okButtonProps={{ onClick: () => {
                    onOk(false);
                } }}
            >
                {
                    renderImageField(
                        fields,
                        form.getFieldValue(["visualisations", index, ...fields]) || defaultValue,
                        `challenge.visualisations.timeline.${fields[0]}`,
                        index,
                        required
                    )
                }
            </FormModal>
        );
    };

    return (
        <React.Fragment>
            <ChallengeVisualisationsCard
                {...defaultProps}
                editChallengeVisualisation={editVisualisation}
                errors={errors}
                form={form}
                hidden={hidden || visualisationIndex !== undefined}
            />

            {visualisations && visualisations.map((visualisation: ChallengeTimelineVisualisationFormValues | ChallengeLeaderboardVisualisationFormValues | ChallengeGridVisualisationFormValues, index: number) => {
                const sharedProps = {
                    key: index,
                    conditions,
                    index,
                    back: backFromEdit,
                    hidden: hidden || index !== visualisationIndex,
                    ...defaultProps
                };

                if (objectTypeCheck<ChallengeTimelineVisualisationFormValues>(visualisation, "timeline")) {
                    const timelineCardSharedProps = {
                        close: () => {
                            setCounterIndex(undefined); setShowTimelineRepeater(false); setShowTimelineGoal(false);
                        },
                        visualisationIndex: index,
                        ...defaultProps
                    };

                    return (
                        <React.Fragment key={index}>
                            <EditChallengeTimelineVisualisationCard
                                activeLanguage={selectedLanguage}
                                deleteGoal={() => {
                                    setArrayFieldValue(form, [["visualisations"], ["banner", "goal"]], index, null);
                                }}
                                deleteRepeater={() => {
                                    setArrayFieldValue(form, [["visualisations"], ["banner", "repeater"]], index, null);
                                }}
                                editBackgroundImage={() => (setShowTimelineBackgroundImage(true))}
                                editCounter={setCounterIndex}
                                editFinishedImage={() => (setShowTimelineFinishedImage(true))}
                                editFlagImage={() => (setShowTimelineFlagImage(true))}
                                editForegroundImage={() => (setShowTimelineForegroundImage(true))}
                                editGoal={() => {
                                    setShowTimelineGoal(true);
                                }}
                                editRepeater={() => {
                                    setShowTimelineRepeater(true);
                                }}
                                editSplitterImage={() => (setShowTimelineSplitterImage(true))}
                                form={form}
                                languageErrors={errorsToLanguageErrors(errors)}
                                visualisation={visualisation}
                                {...sharedProps}
                            />

                            {
                                renderImageModal(
                                    !showTimelineForegroundImage || sharedProps.hidden,
                                    setShowTimelineForegroundImage,
                                    setShowTimelineForegroundImage,
                                    ["foregroundImage"],
                                    visualisation.foregroundImage,
                                    timelineCardSharedProps.visualisationIndex,
                                    true
                                )
                            }
                            {
                                renderImageModal(
                                    !showTimelineBackgroundImage || sharedProps.hidden,
                                    setShowTimelineBackgroundImage,
                                    setShowTimelineBackgroundImage,
                                    ["backgroundImage"],
                                    visualisation.backgroundImage,
                                    timelineCardSharedProps.visualisationIndex,
                                    true
                                )
                            }
                            {
                                renderImageModal(
                                    !showTimelineSplitterImage || sharedProps.hidden,
                                    setShowTimelineSplitterImage,
                                    setShowTimelineSplitterImage,
                                    ["splitterImage"],
                                    "",
                                    timelineCardSharedProps.visualisationIndex,
                                    false
                                )
                            }
                            {
                                renderImageModal(
                                    !showTimelineFlagImage || sharedProps.hidden,
                                    setShowTimelineFlagImage,
                                    setShowTimelineFlagImage,
                                    ["flag", "image"],
                                    visualisation.flag?.image ?? "",
                                    timelineCardSharedProps.visualisationIndex,
                                    false
                                )
                            }
                            {
                                renderImageModal(
                                    !showTimelineFinishedImage || sharedProps.hidden,
                                    setShowTimelineFinishedImage,
                                    setShowTimelineFinishedImage,
                                    ["successImage"],
                                    visualisation.successImage,
                                    timelineCardSharedProps.visualisationIndex,
                                    false
                                )
                            }

                            {visualisation.banner && visualisation.banner.counters && visualisation.banner.counters.map((counter, i) => (
                                <EditChallengeTimelineCounter
                                    key={i}
                                    counter={counter}
                                    counterIndex={i}
                                    form={form}
                                    hidden={sharedProps.hidden || i !== counterIndex}
                                    {...timelineCardSharedProps}
                                />
                            ))}
                            {visualisation.banner && visualisation.banner.repeater && (
                                <EditChallengeTimelineRepeater
                                    form={form}
                                    hidden={sharedProps.hidden || !showTimelineRepeater}
                                    repeater={visualisation.banner && visualisation.banner.repeater}
                                    {...timelineCardSharedProps}
                                />
                            )}
                            {visualisation.banner && visualisation.banner.goal && (
                                <EditChallengeTimelineGoal
                                    form={form}
                                    goal={visualisation.banner.goal}
                                    hidden={sharedProps.hidden || !showTimelineGoal}
                                    {...timelineCardSharedProps}
                                />
                            )}
                        </React.Fragment>
                    );
                }

                if (objectTypeCheck<ChallengeLeaderboardVisualisationFormValues>(visualisation, "leaderboard")) {
                    return (
                        <EditChallengeLeaderboardVisualisationCard
                            activeLanguage={selectedLanguage}
                            errors={errors}
                            form={form}
                            languageErrors={errors && errors[index] && errorsToLanguageErrors(errors[index].description)}
                            {...sharedProps}
                        />
                    );
                }

                if (objectTypeCheck<ChallengeGridVisualisationFormValues>(visualisation, "grid")) {
                    return (
                        <Form.Item
                            dependencies={[["visualisations", index, "dataType"]]}
                        >
                            {() => (
                                <EditChallengeGridVisualisationCard
                                    activeLanguage={selectedLanguage}
                                    form={form}
                                    languageErrors={errors && errors[index] && errorsToLanguageErrors(errors[index])}
                                    visualisation={visualisation}
                                    {...sharedProps}
                                />
                            )}
                        </Form.Item>
                    );
                }

                return null;
            })}
        </React.Fragment>
    );
};
