import { EditOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableComponentProps, TableContainer, TableData } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { ChallengeTeamPageProps } from "@pages/challengeTeamPage/challengeTeamPageContainer";
import { getText } from "@utils/getText";
import { onTableChange } from "@utils/withPagingAndSort";
import { Breadcrumb, Button, Card, Spin, Table } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import { GetChallengeTeamsQuery } from "graphql2/types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface TeamTableData extends TableData {

}

const mapTeams = (challengeTeams?: GetChallengeTeamsQuery["challengeTeams"]) => (challengeTeams ? challengeTeams.map(team => ({
    id: team && team.id,
    ...team
})) : []) as TeamTableData[];

const renderHandlers = (row, challengeId) => {
    const { id } = row;
    return {
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <Link to={`/challenges/teams/${id}/edit/${challengeId}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    };
};

const ChallengeTeamTable: React.FC<TableComponentProps> = ({ variables, ...tableProps }) => (
    <Table
        rowKey="id"
        {...tableProps}
    >
        <Column
            key="title"
            dataIndex="title"
            title={<FormattedMessage id="challengeTeamsTable.title" />}
        />
        <Column
            key="captainUser.displayName"
            dataIndex={["captainUser", "displayName"]}
            title={<FormattedMessage id="challengeTeamsTable.captain" />}
        />
        <Column
            key="memberCount"
            dataIndex="memberCount"
            render={(value, row: TeamTableData) => variables && variables.challengeId && (
                <Link to={`/challenges/challenge/${variables.challengeId}/teams/${row.id}/members`}>
                    <FormattedMessage
                        id="challengeTeams.viewMembers"
                        values={{ memberCount: value }}
                    />
                </Link>
            )}
            title={<FormattedMessage id="challengeTeamsTable.members" />}
        />
        <Column
            key=""
            render={(value, row: TeamTableData) => renderHandlers(row, variables && variables.challengeId)}
        />
    </Table>
);

export const ChallengeTeamPage: React.FC<ChallengeTeamPageProps> = (props) => {
    const { challenges, challengeTeams, challengeTeamCount, match, filterQuery } = props;

    const challengeTitle = challenges?.challenges?.[0].title && getText(challenges.challenges[0].title);

    function updateTableSettings(pagination: PaginationConfig, filters: Record<any, string[]>) {
        const { paging: { limit, skip } } = onTableChange(pagination, filters);

        filterQuery.updateUrlQuery({ pageSize: limit, skip });
    }

    if (challengeTeams && challengeTeams.loading) {
        return <Spin />;
    }

    return (
        <React.Fragment>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/challenges">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/challenges/edit/${match.params.id}/general`}>
                                    {challengeTitle}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <FormattedMessage id="teams" />
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </HeaderActions>
                    <HeaderActions>
                        <Link to={`/challenges/teams/${match.params.id}/add`}>
                            <Button
                                key="button1"
                                type="primary"
                            >
                                <FormattedMessage id="challengeTeamsTable.addTeam" />
                            </Button>
                        </Link>
                    </HeaderActions>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    dataSource={mapTeams(challengeTeams.challengeTeams)}
                    loading={challengeTeams.loading}
                    pagination={{
                        current: filterQuery.skip ? filterQuery.skip / filterQuery.pageSize + 1 : 1,
                        pageSize: filterQuery.pageSize,
                        total: challengeTeamCount && challengeTeamCount.challengeTeamCount
                    }}
                    TableComponent={ChallengeTeamTable}
                    variables={{
                        challengeId: match.params.id
                    }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={updateTableSettings}
                />
            </Card>
        </React.Fragment>
    );
};
