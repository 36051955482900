import { Popconfirm, Table, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, WarningFilled, CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import * as moment from "moment";
import { Link } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";

export interface VoucherTableData extends TableData {
    code: string;
    enabled: boolean;
    title: string;
    maxUsage: number;
    maxUsagePerUser: number;
    enableVoucherPeriod: boolean;
    enabledMaxUsage: boolean;
    enabledMaxUsagePerUser: boolean;
    usageCount: number;
    voucherEnd?: number | null;
    voucherStart?: number | null;
    discount?: { amount: number; type: string } | null;
    error?: string;
}

export interface VoucherTableProps extends TableComponentProps {
    dataSource: VoucherTableData[];
    handlers: TableHandlers & {
        denyEdit?(): boolean;
        removeVoucher(id: string, index?: number): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<VoucherTableData>;
}

// tslint:disable-next-line: max-func-body-length
export const VoucherTable: React.FC<VoucherTableProps> = ({ handlers, ...tableProps }) => {
    const renderTitle = (text: string, id: string) => (
        <Link className="plainText" to={`/vouchers/edit/${id}`}>
            {text}
        </Link>
    );

    const renderError = (error?: string) => {
        if (!error) {
            return null;
        }

        return (
            <Tooltip title={error}>
                <WarningFilled className="red" />
            </Tooltip>
        );
    };

    const renderStatus = (enabled: boolean) => {
        if (enabled) {
            return (
                <Tooltip title="enabled">
                    <CheckCircleFilled className="green" />
                </Tooltip>
            );
        }

        return (
            <Tooltip title="disabled">
                <MinusCircleFilled className="gray" />
            </Tooltip>
        );
    };

    const renderHandlers = (row: VoucherTableData, index: number): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                {
                    !(handlers.denyEdit && handlers.denyEdit())
                        ? (
                            <Link to={`/vouchers/edit/${row.id}`}>
                                <TableIcon as={EditOutlined} />
                            </Link>
                        )
                        : null
                }
                <Popconfirm
                    title={<FormattedMessage id="deleteConfirm" />}
                    onConfirm={() => handlers.removeVoucher(row.id, index)}
                    okText="Yes"
                    cancelText="No"
                >
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table pagination={{ showSizeChanger: true, showQuickJumper: true }} rowKey="id" {...tableProps}>
            <Column
                key="error"
                dataIndex="error"
                width={15}
                render={(_, row: VoucherTableData) => renderError(row.error)}
            />
            <Column
                key="enabled"
                title={<FormattedMessage id="status" />}
                dataIndex="enabled"
                width={95}
                sorter
                render={(_, row: VoucherTableData) => renderStatus(row.enabled)}
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                sorter
                render={(text, row: VoucherTableData) => renderTitle(text, row.id)}
            />
            <Column
                key="code"
                title={<FormattedMessage id="code" />}
                dataIndex="code"
                sorter
            />
            <Column
                key="maxUsage"
                title={<FormattedMessage id="usage" />}
                dataIndex="maxUsage"
                sorter
                render={(_, row: VoucherTableData) => row.usageCount + (row.enabledMaxUsage ? `/${row.maxUsage}` : "")}
            />
            <Column
                key="maxUsagePerUser"
                title={<FormattedMessage id="maxUsagePerUser" />}
                dataIndex="maxUsagePerUser"
                sorter
                render={(_, row: VoucherTableData) => (row.enabledMaxUsagePerUser ? `${row.maxUsagePerUser}` : "")}
            />
            <Column
                key="voucherStart"
                title={<FormattedMessage id="start" />}
                dataIndex="voucherStart"
                sorter
                render={(_, row: VoucherTableData) => row.voucherStart && moment(Number(row.voucherStart)).format("DD/MM/YYYY")}
            />
            <Column
                key="voucherEnd"
                title={<FormattedMessage id="end" />}
                dataIndex="voucherEnd"
                sorter
                render={(_, row: VoucherTableData) => row.voucherEnd && moment(Number(row.voucherEnd)).format("DD/MM/YYYY")}
            />
            <Column
                key="discount.amount"
                title={<FormattedMessage id="discount" />}
                sorter
                dataIndex="discount"
                render={(_, row: VoucherTableData) => (row.discount && `${row.discount.amount} ${row.discount.type}`)}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: VoucherTableData, index: number) => renderHandlers(row, index)}
            />
        </Table>
    );
};
