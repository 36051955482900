import { Flex } from "@components/flex/flex";
import { Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { TagsTableData } from "@components/tags/tagsTable";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { LanguageObject } from "@utils/toLanguageObject";
import { FormInstance } from "antd/lib/form";
import { ApplicationFragment, GetFormTemplatesOverviewQuery } from "../../../../graphql2/types";
import { EventFormValues } from "../../addEventsPage";
import { EventSettingsGroup } from "../../addEventsPageStyle";
import { EventProjectsFormValue } from "../../subEvents/eventSubEventsOverviewCard";
import { DescriptionFields, FormTemplatesFields, GeneralSettingsFields, HiddenFields, RemindersFields, RolesFields, SubEventsFields, TagsFields } from "./eventProjectSettingsCardFields";

export interface EventSettingsFormValues {
    projectId: string;
    description: LanguageObject;
    subscriptionEnabled: boolean;
    formSubscriptionEnabled: boolean;
    formTemplates: string[];
    roles: string[];
    tags: [];
    viewParticipants: boolean;
    viewParticipantCount: boolean;
    waitingListEnabled: boolean;
    featured: boolean;
}

export interface EventProjectSettingsCardProps {
    activeLanguage: string;
    form: FormInstance;
    defaultValues: Partial<EventFormValues>;
    project: EventProjectsFormValue;
    roles: ApplicationFragment["roles"];
    eventTags: TagsTableData[];
    projectIndex: number;
    formTemplates?: GetFormTemplatesOverviewQuery["formTemplates"];
    disabled?: boolean;
    hidden?: boolean;
    languageErrors?: LanguageErrors;
    title?: string | JSX.Element;
    prefix: (number | string)[];
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
    isChronoRaceEvent: boolean;
    subEvents: any;
}

export const EventProjectSettingsCard = (props: EventProjectSettingsCardProps) => {
    const { hidden, title, prefix } = props;

    if (prefix.length) {
        return (
            <Card
                hidden={hidden}
                title={title || <FormattedMessage id="projectSettings" />}
            >
                <EventProjectSettingsCardFields {...props} />
            </Card>
        );
    }

    return <EventProjectSettingsCardFields {...props} />;
};

const EventProjectSettingsCardFields = (props: EventProjectSettingsCardProps) => {
    const { isChronoRaceEvent } = props;

    return (
        <Flex
            className="eventSettings"
            direction="column"
        >
            {isChronoRaceEvent && (
                <EventSettingsGroup>
                    <FormattedMessage
                        id="description"
                        tagName="h3"
                    />
                    <DescriptionFields {...props} />
                </EventSettingsGroup>
            )}
            <EventSettingsGroup>
                <FormattedMessage
                    id="projectSettings"
                    tagName="h3"
                />
                <GeneralSettingsFields {...props} />
            </EventSettingsGroup>
            <EventSettingsGroup>
                <FormattedMessage
                    id="roles"
                    tagName="h3"
                />
                <RolesFields {...props} />
            </EventSettingsGroup>
            <EventSettingsGroup>
                <FormattedMessage
                    id="tags"
                    tagName="h3"
                />
                <TagsFields {...props} />
            </EventSettingsGroup>
            <EventSettingsGroup>
                <FormattedMessage
                    id="reminders"
                    tagName="h3"
                />
                <RemindersFields {...props} />
            </EventSettingsGroup>
            <EventSettingsGroup>
                <FormattedMessage
                    id="formTemplates"
                    tagName="h3"
                />
                <FormTemplatesFields {...props} />
            </EventSettingsGroup>
            <EventSettingsGroup>
                <FormattedMessage
                    id="subevents"
                    tagName="h3"
                />
                <SubEventsFields {...props} />
            </EventSettingsGroup>
            <HiddenFields {...props} />
        </Flex>
    );
};
