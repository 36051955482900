import styled from "styled-components";

export const WidgetStyle = styled.div`
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    margin: 20px 12px;
    border-radius: 4px;
`;

export const WidgetConfigStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #F5F5F5;
    border-radius: 0 0 4px 4px;

    b {
        font-weight: bold;
    }

    .ant-form {
        width: 100%;
    }
`;

export const TitleSubTitleDescriptionFieldsStyle = styled.div``;
