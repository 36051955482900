import * as React from "react";

import { SocialPage } from "@pages/socialPage/socialPage";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
    AddSocialFilterWordProps,
    AddSocialTagProps,
    GetSocialFilterWordsProps,
    GetSocialTagsProps,
    RemoveSocialFilterWordProps,
    RemoveSocialTagProps,
    UpdateSocialFilterWordProps,
    UpdateSocialTagProps,
    withAddSocialFilterWord,
    withAddSocialTag,
    withGetSocialFilterWords,
    withGetSocialTags,
    withRemoveSocialFilterWord,
    withRemoveSocialTag,
    withUpdateSocialFilterWord,
    withUpdateSocialTag
} from "../../graphql2/types";

export interface SocialPageContainerProps { }

export type SocialRouteProps = RouteComponentProps<{ tab: string; }>;

export const SOCIAL_TABS = {
    general: "general",
    instagram: "instagram",
    twitter: "twitter"
};

const socialTagsOptions = {
    refetchQueries: ["getSocialTags"],
    awaitRefetchQueries: true
};

const socialFilterWordsOptions = {
    refetchQueries: ["getSocialFilterWords"],
    awaitRefetchQueries: true
};

const withSocialTags = withGetSocialTags({ name: "socialTags" });
const withSocialFilterWords = withGetSocialFilterWords({ name: "socialFilterWords" });
const withAddSocialTagMutation = withAddSocialTag({
    name: "addSocialTag",
    options: socialTagsOptions
});
const withUpdateSocialTagMutation = withUpdateSocialTag({
    name: "updateSocialTag",
    options: socialTagsOptions
});
const withRemoveSocialTagMutation = withRemoveSocialTag({
    name: "removeSocialTag",
    options: socialTagsOptions
});
const withAddSocialFilterWordMutation = withAddSocialFilterWord({
    name: "addSocialFilterWord",
    options: socialFilterWordsOptions
});
const withUpdateSocialFilterWordMutation = withUpdateSocialFilterWord({
    name: "updateSocialFilterWord",
    options: socialFilterWordsOptions
});
const withRemoveSocialFilterWordMutation = withRemoveSocialFilterWord({
    name: "removeSocialFilterWord",
    options: socialFilterWordsOptions
});

export const SocialPageContainer = compose<React.ComponentClass<SocialPageContainerProps>>(
    withRouter,
    withSocialTags,
    withSocialFilterWords,
    withAddSocialTagMutation,
    withUpdateSocialTagMutation,
    withRemoveSocialTagMutation,
    withAddSocialFilterWordMutation,
    withUpdateSocialFilterWordMutation,
    withRemoveSocialFilterWordMutation
)(SocialPage);

export type SocialPageProps =
    SocialPageContainerProps
    & SocialRouteProps
    & GetSocialTagsProps<{}, "socialTags">
    & GetSocialFilterWordsProps<{}, "socialFilterWords">
    & AddSocialTagProps<{}, "addSocialTag">
    & UpdateSocialTagProps<{}, "updateSocialTag">
    & RemoveSocialTagProps<{}, "removeSocialTag">
    & AddSocialFilterWordProps<{}, "addSocialFilterWord">
    & UpdateSocialFilterWordProps<{}, "updateSocialFilterWord">
    & RemoveSocialFilterWordProps<{}, "removeSocialFilterWord">;
