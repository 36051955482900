import { cloudinaryPreset } from "environment";

export interface ImageUploadResponse {
    error: boolean;
    url?: string;
}

export async function cloudinaryUpload(image: string | Blob) {
    return new Promise<ImageUploadResponse>((resolve, reject) => {
        if (!cloudinaryPreset) {
            return reject(new Error("No cloudinary preset defined"));
        }

        const cloudName = process.env.CLOUDINARY_CLOUD_NAME || "energylab";

        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        xhr.onerror = (e) => {
            console.error(e);
            reject(new Error("Error during upload"));
        };

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                const responseUrl = response.secure_url;

                return resolve({
                    error: false,
                    url: responseUrl
                });
            }
        };

        fd.append("upload_preset", cloudinaryPreset);
        fd.append("tags", "admin_upload");
        fd.append("file", image);
        xhr.send(fd);
    });
}
