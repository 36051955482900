import styled from "styled-components";

export const WidgetPickerStyle = styled.div`
    border-top: 1px solid #DEDEDE;
    display: flex;
    flex-direction: column;
    margin: 20px 12px;
    padding-top: 12px;

    label {
        font-size: 14px;
        padding: 8px 0;
    }

    .ant-select {
        width: 100% !important;
        min-width: auto;
    }
`;
