import { ContentTable } from "@components/content/contentTable";
import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { ContentsPageProps } from "@pages/contentsPage/contentsPageContainer";
import { getFrontendApplication } from "@utils/applicationUtils";
import { translateContents } from "@utils/mappers/contentMapper";
import { readFile } from "@utils/readFile";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Alert, Button, Card, Upload } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as environment from "environment";
import { languages } from "environment";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ContentFragment } from "../../graphql2/types";

export const ContentsPage: React.FC<ContentsPageProps> = (props) => {
    const { data, count: { contentCount }, applications, paging } = props;
    const intl = useIntl();

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const [uploadError, setUploadError] = useState<string>();

    const translatedContents = React.useMemo(
        () => translateContents(data?.contents || []),
        [data]
    );

    const contentTypeSource = environment.contentTypes || Array.from(new Set(translatedContents.map(({ type }) => type).filter(type => type) as string[]));

    const handleUpload = async (info: { file: File; }) => {
        try {
            const importData = await readFile<ContentFragment>(info.file);
            props.history.push("/content/contents/add/general", { import: importData });
        } catch (error) {
            setUploadError("upload.tryAgain");
        }
    };

    const updateTableSettings = async (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const sortedApplications = React.useMemo(
        () => {
            if (!applications || !applications.applications) {
                return;
            }

            const frontendApplications = getFrontendApplication(applications.applications);

            if (!frontendApplications) {
                return;
            }

            return [{
                ...frontendApplications,
                roles: [...frontendApplications.roles].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
            }];
        },
        [applications && applications.applications]
    );

    const removeContent = async (id: string) => {
        await props.removeContent({ variables: { id } });
    };

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <h1><FormattedMessage id="contents.overview" /></h1>
                    <div>
                        <Upload
                            accept="application/json"
                            customRequest={handleUpload}
                        >
                            <Button
                                className="headerButton"
                                type="primary"
                            >
                                <FormattedMessage id="content.import" />
                            </Button>
                        </Upload>
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => {
                                props.history.push("/content/contents/add/general");
                            }}
                        >
                            <FormattedMessage id="content.addContent" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                {uploadError && (
                    <Alert
                        message={<FormattedMessage id={uploadError} />}
                        type="error"
                    />
                )}
                <DynamicFilterBar fields={[
                    {
                        name: "textSearch",
                        type: "input",
                        search: true,
                        placeholder: intl.formatMessage({ id: "filter.textSearch" })
                    },
                    {
                        name: "contentType",
                        type: "select",
                        placeholder: intl.formatMessage({ id: "contents.filter.contentType" }),
                        options: contentTypeSource.map(ct => ({
                            name: ct,
                            value: ct
                        }))
                    },
                    {
                        name: "enabledLocales",
                        type: "select",
                        multiple: true,
                        placeholder: intl.formatMessage({ id: "filter.enabledLocales" }),
                        options: languages.map(lang => ({
                            value: lang,
                            name: lang
                        })),
                        span: 5
                    },
                    {
                        name: "userRoles",
                        type: "select",
                        placeholder: intl.formatMessage({ id: "contents.filter.userRoles" }),
                        options: (sortedApplications || []).map(application => ({
                            name: application.name,
                            options: application.roles.map(role => ({
                                name: role.name,
                                value: `${role.name}_${application.id}_${role.id}`
                            }))
                        })),
                        multiple: true
                    }
                ]}
                />
                <TableContainer
                    applications={applications?.applications || []}
                    dataSource={translatedContents}
                    handlers={{
                        removeContent
                    }}
                    loading={data?.loading}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: contentCount
                    }}
                    TableComponent={ContentTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
