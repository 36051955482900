import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { AmbassadorConfigLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CkeField } from "@components/field/ckeField";
import { FormInstance } from "antd/lib/form";
import { AmbassadorConfigFormValues } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPage";

export interface ContentFormValues {
    title: LanguageObject;
    intro: LanguageObject;
    ambassadorIntro: LanguageObject;
    caption: LanguageObject;
}

export interface ContentCardProps {
    form: FormInstance;
    hidden?: boolean;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    defaultValues: AmbassadorConfigFormValues;
    onBlur(): void;
    onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    changeSelectedLanguage(languageCode: string): void;
}

const titleField: React.FC<AmbassadorConfigLanguageFieldProps> = (props) => (
    <Field
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        hidden={props.hidden}
        key={`title.${props.language}`}
        label={<FormattedMessage id="title" />}
        info={maxCharacters()}
        id={`title.${props.language}`}
        {...props.sharedProps}
    />
);

const introField = (props: AmbassadorConfigLanguageFieldProps) => (
    <CkeField
        name={["intro", props.language]}
        hidden={props.hidden}
        key={`intro.${props.language}`}
        label={<FormattedMessage id="intro" />}
        info={maxCharacters()}
        id={`intro.${props.language}`}
        disabled={props.sharedProps.disabled}
        onChangeUnsaved={props.sharedProps.onChange}
        onBlur={props.sharedProps.onBlur}
        initialValue={props.defaultValues.intro && props.defaultValues.intro[props.language]}
        form={props.sharedProps.form}
    />
);

// TODO: fix CkeFields
const ambassadorIntroField = (props: AmbassadorConfigLanguageFieldProps) => (
    <CkeField
        name={["ambassadorIntro", props.language]}
        hidden={props.hidden}
        rules={[
            // {
            //     required: true,
            //     message: <FormattedMessage id="form.isrequired" />
            // }
        ]}
        key={`ambassadorIntro.${props.language}`}
        label={<FormattedMessage id="ambassadorIntro" />}
        info={maxCharacters()}
        id={`ambassadorIntro.${props.language}`}
        disabled={props.sharedProps.disabled}
        onBlur={props.sharedProps.onBlur}
        onChangeUnsaved={props.sharedProps.onChange}
        initialValue={props.defaultValues.ambassadorIntro && props.defaultValues.ambassadorIntro[props.language]}
        form={props.sharedProps.form}
    />
);

const captionField = (props: AmbassadorConfigLanguageFieldProps) => (
    <Field
        name={["caption", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        hidden={props.hidden}
        key={`caption.${props.language}`}
        label={<FormattedMessage id="caption" />}
        info={maxCharacters()}
        id={`caption.${props.language}`}
        {...props.sharedProps}
    />
);

export class ContentCard extends React.Component<ContentCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { changeSelectedLanguage, hidden, languageErrors, disabled, defaultValues, activeLanguage, form, onBlur } = this.props;

        const sharedProps = {
            disabled,
            form,
            onBlur
        };

        const langFieldProps = languages.map(language => ({
            sharedProps,
            defaultValues,
            language: language.code,
            hidden: language.code !== activeLanguage
        }));

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="content" />}
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                    errors={languageErrors}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(captionField)}
                {langFieldProps.map(introField)}
                {langFieldProps.map(ambassadorIntroField)}
            </Card>
        );
    }
}
