import { Logout } from "@components/header/logout/logout";
import { LogoutProps as LogoutPropsGraphql, withLogout } from "@graphql2/types";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface LogoutContainerProps { }

const logoutMutation = withLogout({
    name: "logout"
});

export const LogoutContainer = compose<React.ComponentClass<LogoutContainerProps>>(
    withRouter,
    logoutMutation
)(Logout);

export type LogoutProps =
    LogoutContainerProps &
    RouteComponentProps &
    LogoutPropsGraphql<{}, "logout">;
