import * as React from "react";
import { Card, Select, Image, List } from "antd";
import { FormInstance } from "antd/lib/form";
import { FALLBACK_IMAGE } from "@components/imageUpload/multi/multiImageUpload";
import { getText } from "@utils/getText";
import * as strip from "remove-markdown-and-html";
import { FormattedMessage } from "react-intl";
import { CommentCardContainer } from "@components/comments/commentCard/commentCardContainer";

interface ImageCommentsProps {
    form: FormInstance;
}

export const ImageComments: React.FC<ImageCommentsProps> = (props) => {
    const { form } = props;

    const [imageId, setImageId] = React.useState<string | undefined>();
    const savedImages = (form.getFieldValue("images") || []).filter(image => image.id);

    return (
        <Card title={<FormattedMessage id="gallery.image.comments" />}>
            <Select onChange={(value: string) => setImageId(value)} size="large" className="imageSelect" style={{ width: "100%" }}>
                {savedImages.map(image => (
                    <Select.Option value={image.id} key={image.id}>
                        <List.Item.Meta
                            avatar={<Image width={50} height={50} src={image.url} fallback={FALLBACK_IMAGE} />}
                            title={strip(getText(image.title)) || <FormattedMessage id="gallery.image.noTitle" />}
                        />
                    </Select.Option>
                ))}
            </Select>
            {imageId && <CommentCardContainer noCard typeId={imageId} type="galleryImage" />}
        </Card>
    );
};
