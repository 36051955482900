export const widgetsList = [
    "challenge",
    "event",
    "content",
    "caroussel",
    "generic",
    "video",
    "challengeVisualisation",
    "activity",
    "statistics",
    "community",
    "popup"
] as const;

export type WidgetType = typeof widgetsList[number]
