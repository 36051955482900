import { getProjectConfigs } from "@graphql2/graphql_op";
import { CreateProjectConfigProps, DeleteProjectConfigProps, GetProjectConfigsProps, ProjectConfig, UpdateProjectConfigProps, withCreateProjectConfig, withDeleteProjectConfig, withGetProjectConfigs, withUpdateProjectConfig } from "@graphql2/types";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import { ConfigPage } from "./configPage";

export interface ArticlesPageContainerProps {
    place: "main" | "routes" | "themes";
    schemasNames: string[];
}

const getProjectConfigsQuery = withGetProjectConfigs({
    name: "projectConfigs",
    options: (props: ArticlesPageContainerProps) => ({
        variables: {
            places: [props.place]
        }
    })
});

const createProjectConfigMutation = withCreateProjectConfig({
    name: "createProjectConfig",
    options: (props: ArticlesPageContainerProps) => ({
        refetchQueries: () => [
            {
                query: getProjectConfigs,
                variables: {
                    places: [props.place]
                }
            }
        ]
    })
});

const updateProjectConfigsQueryMutation = withUpdateProjectConfig({
    name: "updateProjectConfig"
});

const deleteProjectConfigsQueryMutation = withDeleteProjectConfig({
    name: "deleteProjectConfig",
    options: (props: ArticlesPageContainerProps) => ({
        refetchQueries: () => [
            {
                query: getProjectConfigs,
                variables: {
                    places: [props.place]
                }
            }
        ]
    })
});

export type AddConfigRouteProps = RouteComponentProps<{ tab: string; id: string; }, {}, { import?: ProjectConfig; }>;

export const ConfigPageContainer = compose<React.ComponentClass<ConfigPageProps>>(
    getProjectConfigsQuery,
    deleteProjectConfigsQueryMutation,
    updateProjectConfigsQueryMutation,
    createProjectConfigMutation
)(ConfigPage);

export type ConfigPageProps =
    ArticlesPageContainerProps
    & GetProjectConfigsProps<{}, "projectConfigs">
    & UpdateProjectConfigProps<{}, "updateProjectConfig">
    & CreateProjectConfigProps<{}, "createProjectConfig">
    & DeleteProjectConfigProps<{}, "deleteProjectConfig">
    & AddConfigRouteProps
    & RouteComponentProps;
