import * as React from "react";

export type LocalStateProps<TState> = {
    setLocalState(state: Partial<TState>): void;
} & TState;

export const localState = <TState = {}>(initalState: TState) => <P extends {}>(Component: React.ComponentClass<{ setLocalState(state: TState): void }>) =>
    class WithLocalState extends React.Component<P, TState> {
        public state: TState = initalState;

        constructor(props) {
            super(props);

            this.setLocalState = this.setLocalState.bind(this);
        }

        public render() {
            return React.createElement(Component, { ...(this.props as {}), ...(this.state as {}), setLocalState: this.setLocalState });
        }

        private setLocalState(state: TState) {
            this.setState({
                ...(state as {})
            });
        }
    };
