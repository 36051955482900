import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { CardSubtitle } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityConditionStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Checkbox, Col, Form, Row } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface GeneralCardProps {
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
}

export const GeneralCard: React.FC<GeneralCardProps> = (props) => {
    const { languageErrors, activeLanguage, changeSelectedLanguage } = props;

    return (
        <Card className="smallMargin">
            <ContentHeader title={<FormattedMessage id="general" />} />
            <Col span={8}>
                <Form.Item
                    name="enabled"
                    valuePropName="checked"
                >
                    <Checkbox><FormattedMessage id="voucher.enabled" /></Checkbox>
                </Form.Item>
            </Col>
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
            />
            {
                languages.map(lang => (
                    <Field
                        hidden={activeLanguage !== lang.code}
                        label={<FormattedMessage id="title" />}
                        name={["title", lang.code]}
                        rules={[requiredrule]}
                    />
                ))
            }
            <CardSubtitle><FormattedMessage id="voucher.code" /></CardSubtitle>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={8}>
                    <Field
                        label={<FormattedMessage id="voucher.code" />}
                        name="code"
                        rules={[requiredrule]}
                    />
                </Col>
            </Row>
        </Card>
    );
};
