import { ChallengeConditionsTable, ChallengeConditionsTableData } from "@components/challengeConditionsTable/challengeConditionsTable";
import { ChallengeConditionInOneGo, ChallengeConditionRepeat, ChallengeConditionTimespan, FormTemplateFragment } from "@graphql2/types";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { BorderedRow } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCardStyle";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import * as React from "react";

export interface ConditionsTableProps {
    hidden: boolean;
    conditions?: ChallengeConditionFormValuesUnion[];
    errors?: ErrorDictionary;
    move(from: number, to: number): void;
    remove(index: number): void;
    editCondition(index: number): void;
    onDownload(id: string): void;
}

export interface ConditionsTableData {
    id?: string | null;
    sequence?: number | null;
    type?: string | null;
    startTime?: number | null;
    endTime?: number | null;
    activities?: string[] | null;
    value?: number | null;
    unitType?: string | null;
    unit?: string | null;
    inOneGo?: ChallengeConditionInOneGo | null;
    timespan?: ChallengeConditionTimespan | null;
    repeated?: ChallengeConditionRepeat | null;
    image?: string | null;
    formTemplate?: FormTemplateFragment;
}

export const ConditionsTable: React.FC<ConditionsTableProps> = (props) => {
    const { hidden, conditions, onDownload, editCondition, remove, move, errors } = props;

    const mapConditionsDataSource = (dataSource: ChallengeConditionFormValuesUnion[] | undefined): ChallengeConditionsTableData[] => {
        return dataSource ? dataSource.map((data, index) => {
            return {
                id: data.id,
                sequence: index,
                condition: data,
                hasError: hasError(errors, [index])
            };
        }) : [];
    };

    return (
        <BorderedRow hidden={hidden}>
            <ChallengeConditionsTable
                dataSource={mapConditionsDataSource(conditions)}
                deleteCondition={remove}
                editCondition={editCondition}
                moveCondition={move}
                onDownload={onDownload}
            />
        </BorderedRow>
    );
};
