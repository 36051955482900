import {
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import Tooltip, { AbstractTooltipProps } from "antd/lib/tooltip";
import React from "react";
import {
    Description,
    ErrorColors,
    Header,
    InfoColors,
    Title,
    WarningColors,
    Wrapper
} from "./styles";

interface TooltipProps {
    hide?: boolean;
    type: string;
    header?: string | JSX.Element;
    description: string | JSX.Element;
    placement?: AbstractTooltipProps["placement"];
    children?: JSX.Element;
    customStyles?: {
        padding: string;
    }
}

export const AdminTooltip: React.FC<TooltipProps> = ({ hide, type, header, description, placement, children, customStyles }) => {
    if (hide) {
        if (children) {
            return children;
        }
        return null;
    }

    const getTypeProps = () => {
        switch (type) {
            case "error":
                return {
                    styles: { ...ErrorColors, ...customStyles },
                    icon: <CloseCircleOutlined style={{ color: ErrorColors.icon }} />
                };
            case "warning":
                return {
                    styles: { ...WarningColors, ...customStyles },
                    icon: <ExclamationCircleOutlined style={{ color: WarningColors.icon }} />
                };
            case "info":
                return {
                    styles: { ...InfoColors, ...customStyles },
                    icon: <InfoCircleOutlined style={{ color: InfoColors.icon }} />
                };
            default:
                return {};
        }
    };

    const { styles, icon } = getTypeProps();

    const Content = () => (
        <React.Fragment>
            {header && (
                <Header>
                    {icon}
                    <Title>
                        {header}
                    </Title>
                </Header>
            )}
            <Description>
                {description}
            </Description>
        </React.Fragment>
    );

    return (
        <Wrapper styles={styles}>
            <Tooltip
                title={Content}
                placement={placement || "right"}
                getPopupContainer={(triggerNode) => triggerNode}
            >
                {children || icon}
            </Tooltip>
        </Wrapper>
    );
};
