import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { AdminTooltip } from "@components/tooltip/index";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Checkbox, Select, Form, Row, Col } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AmbassadorConfigVisibility, AmbassadorConfigImage } from "@graphql2/types";
import { shapes } from "../constants/shapes";
import { sizes } from "../constants/sizes";
import { SequenceWrapper } from "../addAmbassadorConfigPageStyle";

export interface GeneralSettingsFormValues {
    place: string;
    visibility: AmbassadorConfigVisibility | null;
    image: AmbassadorConfigImage | null;
}

export interface GeneralSettingsCardProps {
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    onBlur(): void;
}

export const GeneralSettingsCard: React.FC<GeneralSettingsCardProps> = (props) => {
    const { hidden, disabled, onBlur } = props;

    const sharedProps = { disabled, onBlur };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="ambassador.general" />}
            />
            <Flex fullWidth>
                <Flex margin="0 60px 0 0" fullWidth direction="column">
                    <SelectField
                        name={["image", "shape"]}
                        label={<FormattedMessage id="image.shape" />}
                        id="image.shape"
                        {...sharedProps}
                    >
                        {shapes.map(s => <Select.Option value={s.toLowerCase()} key={s.toLowerCase()}>{s}</Select.Option>)}
                    </SelectField>
                    <SelectField
                        name={["image", "size"]}
                        label={<FormattedMessage id="image.size" />}
                        id="image.size"
                        {...sharedProps}
                    >
                        {sizes.map(s => <Select.Option value={s.toLowerCase()} key={s.toLowerCase()}>{s}</Select.Option>)}
                    </SelectField>
                </Flex>
                <Flex margin="0 0 0 60px" fullWidth direction="column">
                    <Form.Item
                        name={["visibility", "icons"]}
                        valuePropName="checked"
                        rules={[{
                            transform: value => (value || undefined),
                            type: "boolean"
                        }]}
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="visibility.icons" />
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={["visibility", "name"]}
                        valuePropName="checked"
                        rules={[{
                            transform: value => (value || undefined),
                            type: "boolean"
                        }]}
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="visibility.name" />
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={["visibility", "info"]}
                        valuePropName="checked"
                        rules={[{
                            transform: value => (value || undefined),
                            type: "boolean"
                        }]}
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="visibility.info" />
                        </Checkbox>
                    </Form.Item>
                </Flex>
            </Flex>
            <Row gutter={10}>
                <Col span={5}>
                    <Field
                        name="place"
                        rules={[{
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }]}
                        label={<FormattedMessage id="place" />}
                        id="place"
                        {...sharedProps}
                    />
                </Col>
                <Col span={5}>
                    <Field
                        name="sequence"
                        numberType
                        label={(
                            <SequenceWrapper>
                                <FormattedMessage id="sequence" />
                                <AdminTooltip
                                    description={<FormattedMessage id="sequence.ambassadorConfig.info" />}
                                    type="info"
                                    customStyles={{ padding: "0 0 0 5px" }}
                                />
                            </SequenceWrapper>
                        )}
                        id="sequence"
                        {...sharedProps}
                    />
                </Col>
            </Row>
        </Card>
    );
};
