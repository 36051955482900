import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import * as moment from "moment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export interface CheckupTableData extends TableData {
    location: string;
    coach: string;
    date: Moment;
    duration: number;
    type: string;
}

export interface CheckupTableProps extends TableComponentProps {
    dataSource: CheckupTableData[];
    handlers: TableHandlers & {
        removeCheckup(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<CheckupTableData>;
}

export const CheckupTable: React.FC<CheckupTableProps> = ({ handlers, ...tableProps }) => {
    const renderHandlers = (row: CheckupTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <Link to={`/checkups/edit/${row.id}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeCheckup(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table rowKey="id" {...tableProps}>
            <Column
                key="location"
                title={<FormattedMessage id="location" />}
                dataIndex="location"
            />
            <Column
                key="coach"
                title={<FormattedMessage id="coach" />}
                dataIndex="coach"
                render={(text, row: CheckupTableData) => {
                    return (
                        <Link className="plainText" to={`/checkups/edit/${row.id}`}>
                            {text}
                        </Link>
                    );
                }}
            />
            <Column
                key="date"
                title={<FormattedMessage id="date" />}
                dataIndex="date"
                render={(_, row: CheckupTableData) => moment(row.date).format("DD/MM/YYYY HH:mm")}
            />
            <Column
                key="duration"
                title={<FormattedMessage id="checkup.duration" />}
                render={(_, row: CheckupTableData) => moment.duration(row.duration, "millisecond").humanize(false)}
            />
            <Column
                key="type"
                title={<FormattedMessage id="type" />}
                dataIndex="type"
            />
            <Column
                title=" "
                dataIndex=""
                render={(_, row: CheckupTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
