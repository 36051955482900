import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { AdminTooltip } from "@components/tooltip";
import { useGlobalActions } from "@context/enabledLangContext/actions";
import { Checkbox, DatePicker, Form, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as environment from "environment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTIVITY_TYPES_CHALLNGE } from "../../../../../constants";
import { CardSubtitle, DateAndTimesWrapper, SettingsRowWrapper } from "../challengeGeneralTabStyle";
import { SharedProps } from "./challengeBasicSettingsCard";

interface IDateAndTimesSettings extends SharedProps {
    form: FormInstance;
    enabledLocales: string[] | undefined;
    challengeParticipantCount: number | undefined;
    editMode: boolean;
}

const DateAndTimesSettings = ({ form, enabledLocales, challengeParticipantCount, editMode, ...sharedProps }: IDateAndTimesSettings) => {
    const [updateEnabledLanguages, resetEnabledLanguages] = useGlobalActions();

    const [isTooltipVisible, setIsTooltipVisible] = React.useState<boolean>(false);

    const setTooltipOnChange = () => {
        if (challengeParticipantCount && challengeParticipantCount > 0) {
            setIsTooltipVisible(true);
        }
    };

    React.useEffect(() => {
        if (enabledLocales && enabledLocales.length) {
            updateEnabledLanguages(enabledLocales);
        }
    }, [enabledLocales]);

    const validateUltimateSyncTime = (rule, value: Moment, callback: (error?: string | undefined) => void) => {
        const end = form.getFieldValue(["duration", "end"]) as Moment | null;

        if (value && end && end.isAfter(value)) {
            return callback("true"); // TODO?
        }

        return callback();
    };

    const validateOnExpireTime = (rule, value: Moment, callback: (error?: string | undefined) => void) => {
        const end: Moment | null = form.getFieldValue(["duration", "end"]);
        const setCustomExpireField = form.getFieldValue("setCustomExpire");
        if (setCustomExpireField && value && end && end.startOf("d").isAfter(value)) {
            return callback("true"); // TODO?
        }

        return callback();
    };

    const validateOnAfterStart = (rule, value: Moment, callback: (error?: string | undefined) => void) => {
        const start: Moment | null = form.getFieldValue(["duration", "start"]);
        if (start && value && start.isAfter(value)) {
            return callback("true");
        }
        return callback();
    };

    return (
        <React.Fragment>
            <Row
                align="middle"
                style={{ marginBottom: 0 }}
            >
                <CardSubtitle>
                    <FormattedMessage id="dateAndTimes" />
                </CardSubtitle>
                {isTooltipVisible && (
                    <AdminTooltip
                        customStyles={{ padding: "20px 0 0 5px" }}
                        description={<FormattedMessage id="challenge.tabs.general.startDate.tooltip" />}
                        type="warning"
                    />
                )}
            </Row>
            <Row align="middle">
                <SettingsRowWrapper>
                    <DateAndTimesWrapper>
                        <Form.Item
                            label={<FormattedMessage id="start" />}
                            name={["duration", "start"]}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                        >
                            <DatePicker onChange={setTooltipOnChange} />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="end" />}
                            name={["duration", "end"]}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                },
                                {
                                    validator: validateOnAfterStart,
                                    message: <FormattedMessage id="challenge.end.afterStart" />
                                }
                            ]}
                            {...sharedProps}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="challenge.ultimateSync" />}
                            name="ultimateSyncTime"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                },
                                {
                                    validator: validateUltimateSyncTime,
                                    message: <FormattedMessage id="challenge.ultimateSync.afterEnd" />
                                }
                            ]}
                            {...sharedProps}
                        >
                            <DatePicker />
                        </Form.Item>
                    </DateAndTimesWrapper>
                    <DateAndTimesWrapper>
                        <Form.Item
                            label={<FormattedMessage id="publishOn" />}
                            name="publishedOn"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="form.isrequired" />
                                }
                            ]}
                            {...sharedProps}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item dependencies={["setCustomExpire"]}>
                            {() => {
                                const customExpireEnabled = Boolean(form.getFieldValue("setCustomExpire"));

                                return (
                                    <Form.Item
                                        label={<FormattedMessage id="expiresOn" />}
                                        name="expiresOn"
                                        rules={[
                                            {
                                                required: customExpireEnabled,
                                                message: <FormattedMessage id="form.isrequired" />
                                            },
                                            {
                                                validator: validateOnExpireTime,
                                                message: <FormattedMessage id="challenge.expiresOn.afterEnd" />
                                            }
                                        ]}
                                        {...sharedProps}
                                    >
                                        <DatePicker disabled={!customExpireEnabled} />
                                    </Form.Item>

                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            name="setCustomExpire"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...sharedProps}
                            >
                                <FormattedMessage id="challenge.setCustomExpire" />
                            </Checkbox>
                        </Form.Item>
                    </DateAndTimesWrapper>
                    <Form.Item
                        name={["registration", "participateOnStart"]}
                        valuePropName="checked"
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="challenge.countActivitiesStartStop" />
                        </Checkbox>
                    </Form.Item>
                    <Flex alignItems>
                        <Form.Item
                            name="autoParticipationEnabled"
                            valuePropName="checked"
                        >
                            <Checkbox
                                {...sharedProps}
                                disabled={editMode}
                            >
                                <FormattedMessage id="everyoneAutoParticipates" />
                            </Checkbox>
                        </Form.Item>
                        <AdminTooltip
                            customStyles={{ padding: "8px 0 0 0" }}
                            description={<FormattedMessage id="warning.disabledChangeInEditMode" />}
                            hide={!editMode}
                            type="warning"
                        />
                    </Flex>
                    <SelectField
                        id="activityTypes"
                        label={<FormattedMessage id="activityTypes" />}
                        mode="multiple"
                        name="activityTypes"
                        placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                        rules={[{
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }]}
                        {...sharedProps}
                    >
                        {ACTIVITY_TYPES_CHALLNGE
                            ? ACTIVITY_TYPES_CHALLNGE.map(a => (
                                <Select.Option
                                    key={a.key}
                                    value={a.key}
                                >
                                    {a.value}
                                </Select.Option>
                            ))
                            : null}
                    </SelectField>
                    <SelectField
                        id="enabledLocales"
                        label={<FormattedMessage id="enabledLocales" />}
                        mode="multiple"
                        name="enabledLocales"
                        placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                        {...sharedProps}
                        onChange={(value) => {
                            sharedProps.onChange();
                            if (value.length) {
                                updateEnabledLanguages(value as string[]);
                            } else {
                                resetEnabledLanguages();
                            }
                        }}
                    >
                        {environment.languages.map(lang => (
                            <Select.Option
                                key={lang}
                                value={lang}
                            >
                                {lang}
                            </Select.Option>
                        ))}
                    </SelectField>
                </SettingsRowWrapper>
            </Row>
        </React.Fragment>
    );
};

export default DateAndTimesSettings;
