import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { FormInstance } from "antd/lib/form";
import { autobind } from "core-decorators";
import * as React from "react";

export interface EventCoverImageFormValues {
    image: string;
}

export interface EventCoverImageCardProps {
    form: FormInstance;
    defaultValues: Partial<EventCoverImageFormValues>;
    disabled?: boolean;
    hidden?: boolean;
    hasError?: ErrorObj;
    onSubmit(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    onBlur(): void;
}

export interface EventCoverImageCardState {
    image?: string;
    imageUrl?: string;
    loading?: boolean;
}

@autobind
export class EventCoverImageCard extends React.Component<EventCoverImageCardProps, EventCoverImageCardState> {
    public state: EventCoverImageCardState = {};

    public shouldComponentUpdate(props, state) {
        return (
            this.props.defaultValues.image !== props.defaultValues.image
            || this.props.disabled !== props.disabled
            || this.props.hidden !== props.hidden
            || this.props.hasError !== props.hasError
            || this.state.image !== state.image
            || this.state.imageUrl !== state.imageUrl
        );
    }

    public render() {
        const { form, defaultValues, hidden, disabled } = this.props;

        return (
            <CoverImageCard
                required
                cropSettings={{
                    aspect: ImageRatios.DEFAULT,
                    zoom: true,
                    rotate: true
                }}
                defaultImage={defaultValues.image ?? ""}
                disabled={disabled}
                form={form}
                formField="image"
                hidden={hidden}
                titleId="coverImage"
            />
        );
    }
}
