import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Col, Divider, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface OverviewCardProps {
    form: FormInstance;
    languageErrors: LanguageErrors;
}

export const OverviewCard = ({ form, languageErrors }: OverviewCardProps) => {
    const [selectedLanguage, setLanguage] = useCurrentLanguage();

    const validateOnAfterStart = (rule, value: Moment, callback: (error?: string | undefined) => void) => {
        const start: Moment | null = form.getFieldValue(["start"]);
        if (start && value && start.isAfter(value)) {
            return callback("invalid");
        }

        return callback();
    };

    return (
        <Card>
            <ContentHeader title={<FormattedMessage id="general" />} />
            <Col span={16}>
                <TranslationTabs
                    activeLanguage={selectedLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={setLanguage}
                    languages={languages}
                />
                {
                    languages.map(lang => (
                        <Field
                            hidden={selectedLanguage !== lang.code}
                            label={<FormattedMessage id="title" />}
                            name={["title", lang.code]}
                            rules={[requiredrule]}
                        />
                    ))
                }
            </Col>
            <Divider />
            <Row>
                <Col span={6}>
                    <DatePickerField
                        disabled={false}
                        label={<FormattedMessage id="start" />}
                        name="start"
                        rules={[requiredrule]}
                        validateTrigger={["onChange", "onSubmit"]}
                    />
                </Col>
                <Col span={6}>
                    <DatePickerField
                        disabled={false}
                        label={<FormattedMessage id="end" />}
                        name="end"
                        rules={[
                            requiredrule,
                            {
                                validator: validateOnAfterStart,
                                message: <FormattedMessage id="challenge.end.afterStart" />
                            }
                        ]}
                        validateTrigger={["onChange", "onSubmit"]}
                    />
                </Col>
            </Row>
            <Col span={16}>
                <Field
                    numberType
                    label={<FormattedMessage id="flows.reminderDaysDelay" />}
                    min={0}
                    name="reminderDaysDelay"
                />
            </Col>
        </Card>
    );
};
