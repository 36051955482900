import styled from "styled-components";
import { Form } from "antd";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";

export const LimitedFlexCell = styled(MarginRightDiv)`
    max-width: 144px;
    min-height: 84px;
`;

export const StyledFormItem = styled(Form.Item)`
    .ant-form-item-control-input{
        min-height: 23px
    }
`;
