import * as queryString from "query-string";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useUrlQuery = <T extends queryString.ParsedQuery<string | boolean | number | undefined>>(defaultValues: Partial<T> = {}): T => {
    const { search } = useLocation();
    const normSearch = search.replace("?", "");

    const query = useMemo(
        () => {
            const queryWithArrays = queryString.parse(normSearch, {
                arrayFormat: "comma",
                parseBooleans: true,
                parseNumbers: true
            });

            return { ...defaultValues, ...queryWithArrays };
        },
        [search]
    );

    return query as T;
};

export const useUpdateQuery = <T extends queryString.ParsedQuery<string | boolean | number | undefined>>(options = { replace: true }) => {
    const history = useHistory();
    const query = useUrlQuery();
    const { replace } = options;

    return useCallback(
        (patch: Partial<T>): void => {
            const resetValues:({[key:string]:number;}) = {};

            if (patch.skip && query.skip === patch.skip) {
                resetValues.skip = 0;
            }

            const newQuery = { ...query, ...patch, ...resetValues };
            const newSearch = queryString.stringify(newQuery, {
                arrayFormat: "comma",
                skipNull: true,
                skipEmptyString: true
            });

            if (replace) {
                history.replace({ search: newSearch });
            } else {
                history.push({ search: newSearch });
            }
        },
        [history, query, replace]
    );
};
