import { Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TableComponentProps, TableData } from "@components/table/tableContainer";
import Column from "antd/lib/table/Column";

interface OrderedArticlesMapped {
    articleId: string;
    articleTitle: string;
    articleAmount: number;
}

export interface OrdersTableData extends TableData {
    id: string;
    userId: string;
    userEmail: string;
    orderedArticles: OrderedArticlesMapped[];

}

export interface OrdersTableProps extends TableComponentProps {
    dataSource: OrdersTableData[];
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ ...tableProps }) => {
    const renderUserEmail = (text: string, id: string) => (
        <Link className="plainText" to={`/users/detail/${id}`}>
            {text}
        </Link>
    );

    const renderArticleTitle = (articles: OrderedArticlesMapped[]) => (
        <React.Fragment>
            {articles.map((article, index) => (
                <React.Fragment>
                    <Link className="plainText" to={`/articles/edit/${article.articleId}/general`}>
                        {article.articleTitle}
                    </Link>
                    {index !== (articles.length - 1) && <span>, </span>}
                </React.Fragment>

            ))}
        </React.Fragment>
    );

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="userEmail"
                title={<FormattedMessage id="user" />}
                dataIndex="userEmail"
                width={200}
                render={(text, row: OrdersTableData) => renderUserEmail(text, row.userId)}
            />
            <Column
                key="orderedArticles"
                title={<FormattedMessage id="orderedArticles" />}
                dataIndex="orderedArticles"
                width={200}
                render={(text, row: OrdersTableData) => renderArticleTitle(row.orderedArticles)}
            />
        </Table>
    );
};
