import * as React from "react";
import { compose } from "redux";
import { GetApplicationsProps, withGetApplications, Application, UserApplicationInput } from "@graphql2/types";
import { UserRolesApplicationsCard } from "./userRolesApplicationsCard";

export interface UserRolesApplicationsCardContainerProps {
    forceLoading?: boolean;
    onUpdate(options: UserApplicationInput[]): void;
    existingRoles: Pick<Application, "id" | "name" | "project" | "roles">[];
}

export const UserRolesApplicationsCardContainer = compose<React.ComponentClass<UserRolesApplicationsCardContainerProps>>(
    withGetApplications({
        name: "applications"
    })
)(UserRolesApplicationsCard);

export type UserRolesApplicationsCardProps = UserRolesApplicationsCardContainerProps
    & GetApplicationsProps<{}, "applications">;
