import { Field } from "@components/field/field";
import { Flex } from "@components/flex/flex";
import { GetEventsQuery } from "@graphql2/types";
import { SwitchWrapper } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCardStyle";
import { Checkbox, Col, Row, Switch } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CardInCard, EventSettingsGroup } from "../addEventsPageStyle";

interface SubEventProjectCardProps {
    form: FormInstance;
    projectTitle?: string;
    projectIndex: number;
    disabled?: boolean;
    onBlur(): void;
    selectedProjectSubEventIndex: number;
    selectedProjectSubEvent: NonNullable<NonNullable<NonNullable<GetEventsQuery["events"][0]["projects"]>[0]>["subEvents"]>[0];
}

export const SubEventProjectCard: React.FC<SubEventProjectCardProps> = (props) => {
    const {
        projectTitle,
        projectIndex,
        disabled,
        onBlur,
        form,
        selectedProjectSubEventIndex,
        selectedProjectSubEvent
    } = props;

    const sharedProps = {
        disabled,
        onBlur
    };

    const maxParticipantsDisabledNamePath = [
        "projects",
        projectIndex,
        "subEvents",
        selectedProjectSubEventIndex,
        "maxParticipantsDisabled"
    ];

    return (
        <CardInCard title={projectTitle}>
            <EventSettingsGroup>
                <FormattedMessage
                    id="subEvent.subscription"
                    tagName="h3"
                />
                <Flex direction="column">
                    <SwitchWrapper>
                        <Form.Item
                            initialValue={
                                selectedProjectSubEvent?.subscriptionEnabled
                                || false
                            }
                            name={
                                ["projects",
                                    projectIndex,
                                    "subEvents",
                                    selectedProjectSubEventIndex,
                                    "subscriptionEnabled"
                                ]
                            }
                            valuePropName="checked"
                        >
                            <Switch
                                {...sharedProps}
                                onChange={sharedProps.onBlur}
                            />
                        </Form.Item>
                        <FormattedMessage id="subscriptionEnabled" />
                    </SwitchWrapper>
                    <SwitchWrapper>
                        <Form.Item
                            initialValue={
                                selectedProjectSubEvent?.participationFull
                                || false
                            }
                            name={
                                [
                                    "projects",
                                    projectIndex,
                                    "subEvents",
                                    selectedProjectSubEventIndex,
                                    "participationFull"
                                ]
                            }
                            valuePropName="checked"
                        >
                            <Switch
                                {...sharedProps}
                                onChange={sharedProps.onBlur}
                            />
                        </Form.Item>
                        <FormattedMessage id="participationFull" />
                    </SwitchWrapper>
                </Flex>
            </EventSettingsGroup>
            <Flex
                className="eventSettings"
                direction="column"
            >
                <FormattedMessage
                    id="subEvent.participants"
                    tagName="h3"
                />
                <Row
                    align="middle"
                    gutter={16}
                >
                    <Form.Item dependencies={[maxParticipantsDisabledNamePath]}>
                        {() => {
                            const disableFields = form.getFieldValue(maxParticipantsDisabledNamePath) || sharedProps.disabled;
                            return (
                                <Flex>
                                    <Col>
                                        <Field
                                            {...sharedProps}
                                            numberType
                                            disabled={disableFields}
                                            id={`projects.${projectIndex}.maxParticipants`}
                                            label={<FormattedMessage id="maxParticipants" />}
                                            min={0}
                                            name={
                                                ["projects",
                                                    projectIndex,
                                                    "subEvents",
                                                    selectedProjectSubEventIndex,
                                                    "maxParticipants"
                                                ]
                                            }
                                            rules={[{
                                                required: true,
                                                message: <FormattedMessage id="form.isrequired" />
                                            }]}
                                        />
                                    </Col>
                                    <Col>
                                        <Field
                                            {...sharedProps}
                                            numberType
                                            disabled={disableFields}
                                            id={`projects.${projectIndex}.participantOffset`}
                                            label={<FormattedMessage id="participantOffset" />}
                                            min={0}
                                            name={
                                                [
                                                    "projects",
                                                    projectIndex,
                                                    "subEvents",
                                                    selectedProjectSubEventIndex,
                                                    "participantOffset"
                                                ]
                                            }
                                            rules={[{
                                                required: true,
                                                message: <FormattedMessage id="form.isrequired" />
                                            }]}
                                        />
                                    </Col>
                                </Flex>
                            );
                        }}
                    </Form.Item>
                    <Col>
                        <Form.Item
                            name={maxParticipantsDisabledNamePath}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                <FormattedMessage id="maxParticipantsDisabled" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Flex>
        </CardInCard>
    );
};
