import { TableIcon } from "@components/table/tableStyle";
import { Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { DeleteOutlined, EditOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { getText } from "@utils/getText";
import { OrderIconWrapper } from "@components/challengeConditionsTable/challengeConditionsTableStyle";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TableComponentProps, TableData } from "../table/tableContainer";
import { Flex } from "../flex/flex";

export interface JourneyContentTableData extends TableData {
    type?: string | null;
    title: string;
}

export interface JourneyContentTableProps extends TableComponentProps {
    dataSource: JourneyContentTableData[];
    editContent(index: number): void;
    remove(index: number): void;
    move(from: number, to: number): void;
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<JourneyContentTableData>;
}

export const JourneyContentTable: React.FC<JourneyContentTableProps> = ({ editContent, remove, move, ...tableProps }) => {
    const renderHandlers = (index: number): Handlers => ({
        children: (
            <Flex justifyContent="flex-end">
                <React.Fragment key={index}>
                    <TableIcon onClick={() => editContent(index)} as={EditOutlined} />
                    <TableIcon onClick={() => remove(index)} as={DeleteOutlined} />
                </React.Fragment>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table rowKey="id" {...tableProps}>
            <Column
                width={50}
                key="sequence"
                title=" "
                render={(t, r, index) => (
                    <OrderIconWrapper>
                        <CaretUpOutlined onClick={() => move(index, index - 1)} />
                        <CaretDownOutlined onClick={() => move(index, index + 1)} />
                    </OrderIconWrapper>
                )}
            />
            <Column
                width={150}
                key="type"
                title={<FormattedMessage id="type" />}
                dataIndex="type"
                filters={[
                    {
                        text: "Tab",
                        value: "tab"
                    },
                    {
                        text: "Content list",
                        value: "contentList"
                    },
                    {
                        text: "Disclaimer list",
                        value: "disclaimerList"
                    },
                    {
                        text: "USP list",
                        value: "USPList"
                    },
                    {
                        text: "Corner image",
                        value: "cornerImage"
                    }
                ]}
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                render={(_text) => getText(_text)}
            />
            {renderErrorColumn()}
            <Column
                width={90}
                title=" "
                dataIndex=""
                render={(_text, _row, index) => renderHandlers(index)}
            />
        </Table>
    );
};
