import { NamePath } from "antd/lib/form/interface";
import { FixedContentFieldProps } from "@components/dashboard/widgets/contentWidget/fixedContentField/fixedContentFieldContainer";
import { requiredrule } from "@components/form/requiredRule";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { WidgetSort } from "@components/dashboard/widgets/widgetSort";
import { WidgetSearchTitlesComponent } from "@components/dashboard/widgets/widgetSearchTitlesComponent";
import { Form, Spin, Alert } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { debounce } from "lodash";

export const FixedContentField: React.FC<FixedContentFieldProps> = (props) => {
    const {
        contentOptions,
        contentTitles,
        singleMode,
        itemKey,
        sort,
        setLocalState,
        addSort,
        forceSingle
    } = props;

    const { id } = contentOptions;
    const rules = [requiredrule];

    const { contents, loading: isLoading, error } = contentTitles;

    const debouncedState = debounce(setLocalState, 500);

    let name: NamePath = ["widgets", id, "contentIds"];
    let label = "contentWidget.addContents";

    if (singleMode && (itemKey || itemKey === 0)) {
        name = [itemKey, "typeId"];
    }

    if (singleMode) {
        label = "contentWidget.addContent";
    }

    if (forceSingle && singleMode) {
        name = ["widgets", id, "contentId"];
    }

    return (
        <React.Fragment>
            <Form.Item>
                <div style={error ? { display: "none" } : undefined}>
                    <WidgetSearchTitlesComponent
                        isLoading={isLoading}
                        data={contents || []}
                        name={name}
                        rules={rules}
                        showArrow
                        showSearch
                        notFoundContent={isLoading ? <Spin /> : null}
                        mode={!singleMode ? "multiple" : undefined}
                        id={name.join(".")}
                        filterOption={false}
                        optionFilterProp="children"
                        label={<FormattedMessage id={label} />}
                    />
                    {addSort && (
                        <WidgetSort
                            type={PreviewTypes.content}
                            updateParentState={debouncedState}
                            defaults={sort}
                            {...contentOptions}
                        />
                    )}
                </div>
                {error && <Alert type="error" message={<FormattedMessage id="dashboard.norole" />} />}
            </Form.Item>
        </React.Fragment>
    );
};
