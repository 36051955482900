
import { Spin, Select } from "antd";
import * as React from "react";
import { FieldProps, SelectField } from "@components/field/selectField";
import { debounce } from "@utils/debounce";
import { getText } from "@utils/getText";
import { GetEventTitlesQuery, GetChallengeTitlesQuery, GetContentTitlesQuery } from "@graphql2/types";
import { FormattedMessage } from "react-intl";

interface WidgetSearchTitlesComponentProps extends FieldProps {
    data: (GetEventTitlesQuery["events"][0] | GetChallengeTitlesQuery["challenges"][0] | GetContentTitlesQuery["contents"][0])[];
    isLoading: boolean;
}

export const WidgetSearchTitlesComponent: React.FC<WidgetSearchTitlesComponentProps> = (props) => {
    const { data, isLoading, ...selectProps } = props;
    const [searchedValue, setSearchedValue] = React.useState<string>("");

    const titlesSearch = (value) => {
        setSearchedValue(value);
    };

    const defaultValues = React.useMemo(
        () => {
            if (!searchedValue) {
                return data;
            }
            const titles = data?.filter(item => {
                const someEvent = item?.title?.some(title => title?.text?.toLowerCase().includes(searchedValue.toLowerCase()));
                return Boolean(someEvent);
            });
            return titles;
        },
        [searchedValue, data]
    );

    return (
        <SelectField
            {...selectProps}
            notFoundContent={<FormattedMessage id="widget.noDataFound" />}
            onSearch={debounce(titlesSearch, 500)}
        >
            {isLoading
                ? <Select.Option value=""><Spin /></Select.Option>
                : (defaultValues || []).map((item) => (
                    <Select.Option value={item.id} key={item.id}>{getText(item.title || [])}</Select.Option>
                ))}
        </SelectField>
    );
};
