import { JSONData } from "@energylab/schematics";
import { InjectedIntl } from "@utils/override.types";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { FieldName, FieldsMapper } from "../fieldsMapper";
import { CardWrapper } from "./cardWrapper";

type GenericFieldWrapperProps = {
    disable: boolean;
    disableTitle: boolean;
    displayName: string;
    form: FormInstance;
    intl: InjectedIntl;
    jsonData: JSONData;
    name: FieldName;
    namePath: (string | number)[];
    required: boolean;
    fieldName: string | number;
};

export const GenericFieldWrapper: React.FC<GenericFieldWrapperProps> = (props) => {
    const { disable, disableTitle, displayName, form, intl, jsonData, name, namePath, fieldName } = props;
    const description = jsonData.description ?? "";
    const properties = jsonData.properties ?? [];
    return (
        <CardWrapper
            description={description}
            disableTitle={disableTitle}
            name={displayName}
        >
            {
                Object.entries(properties).map(([key, value]) => (
                    <FieldsMapper
                        key={key}
                        disable={disable}
                        form={form}
                        intl={intl}
                        jsonData={value as JSONData}
                        name={[...name, key]}
                        namePath={name !== undefined ? [...namePath, fieldName] : namePath}
                        required={jsonData.required?.some(r => r === key)}
                    />
                ))
            }
        </CardWrapper>
    );
};
