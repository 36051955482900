import moment from "moment";
import { AuthAction, AuthActionTypes } from "./actions";
import { AuthState } from "./context";

export const authReducer = (state: AuthState, action: AuthAction) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return {
                ...state,
                session: {
                    projectId: state.session?.projectId ?? null,
                    userId: state.session?.userId ?? null,
                    roles: state.session?.roles ?? [],
                    expirationDate: moment().add(1, "minutes").unix()
                }
            };

        case AuthActionTypes.LOGOUT:
        {
            localStorage.removeItem("token");
            return {
                session: {
                    expirationDate: null,
                    projectId: null,
                    userId: null,
                    roles: []
                },
                isAuthStateUpdated: true
            };
        }

        case AuthActionTypes.UPDATE_SESSION_INFO:
            return {
                session: {
                    expirationDate: action.payload?.expiresOn ?? null,
                    projectId: action.payload?.projectId ?? null,
                    userId: action.payload?.userId ?? null,
                    roles: action.payload?.roles ?? []
                },
                isAuthStateUpdated: true
            };
        default:
            return state;
    }
};
