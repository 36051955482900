/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { HiddenContainer } from "@components/hiddenContainer/hiddenContainer";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { ADD_ARTICLE_TABS, AddArticlesPageProps } from "@pages/addArticlesPage/addArticlesPageContainer";
import { AddArticlesPageStyle } from "@pages/addArticlesPage/addArticlesPageStyle";
import { ProductSettingsCard, ProductSettingsFormValues } from "@pages/addArticlesPage/general/productSettingsCard";
import {
    TitleAndDescriptionCard,
    TitleAndDescriptionFormValues
} from "@pages/addArticlesPage/general/titleAndDescriptionCard";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyArticle } from "@utils/emptyItems/emptyArticle";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { articleToFormValues, formValuesToArticle } from "@utils/mappers/articleMapper";
import { Badge, Breadcrumb, Button, Form, Spin, message } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { useErrors } from "../../hooks/useErrors";
import { VoucherCard, VoucherFormValues } from "./vouchers/voucherCard";
import { VoucherInstructionCard, VoucherInstructionFormValues } from "./vouchers/voucherInstructionCard";

export type ErrorObj = object;
export interface AddArticlesPageState {
    ref: FormInstance | null;
    selectedLanguage: string;
    currentTab: number;
    type?: string;
    editContentIndex?: number;
    unsaved: {
        titleAndDescription: boolean;
        coverImage: boolean;
        productSettings: boolean;
        voucher: boolean;
        voucherInstruction: boolean;
    };
    errors?: {
        title: ErrorObj;
        description: ErrorObj;
        price: ErrorObj;
        currentStock: ErrorObj;
        expiresOn: ErrorObj;
        publishOn: ErrorObj;
        imageUrl: ErrorObj;
    };
}

const articleErrorMap = (tab: string, fieldErrors: any) => {
    switch (tab) {
        case ADD_ARTICLE_TABS.general:
            return Boolean(
                fieldErrors.description
                || fieldErrors.title
                || fieldErrors.imageUrl
                || fieldErrors.price
                || fieldErrors.currentStock
                || fieldErrors.publishOn
                || fieldErrors.expiresOn
            );
        case ADD_ARTICLE_TABS.voucher:
            return Boolean(
                fieldErrors.instruction
                || fieldErrors.vouchers
            );
        default:
            return Boolean(fieldErrors[tab]);
    }
};

export type ArticleFormValues = {
    id?: string;
    imageUrl: string;
} & TitleAndDescriptionFormValues & ProductSettingsFormValues & VoucherInstructionFormValues & VoucherFormValues;

/* tslint:disable */
export const AddArticlesPage: React.FC<AddArticlesPageProps> = (props) => {
    const intl = useIntl();
    const [form] = useForm();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [isSaving, setIsSaving] = React.useState(false);
    const [enabledSave, setEnabledSave] = React.useState<boolean>();
    const [fieldErrors, setError, resetErrors] = useErrors();

    const {
        articles,
        applications,
        match,
        location
    } = props;

    const [productType, setProductType] = React.useState<string>("physical");

    React.useEffect(() => {
        setProductType(articles?.articles?.[0].type ?? "physical");
    }, [articles?.articles?.[0].type]);

    const article = React.useMemo(
        () => createDefaultValues(articleToFormValues, emptyArticle, location.state?.import, articles?.loading, articles?.articles?.[0]),
        [articles]
    );

    const tabs: string[] = [
        ADD_ARTICLE_TABS.general,
        ADD_ARTICLE_TABS.voucher
    ];

    const currentTab = tabs.indexOf(match.params.tab);

    const articleForm = form.getFieldsValue();

    const changeSelectedTab = (index: number | string) => {
        props.history.push(
            `/articles/${match.params.id
                ? `edit/${match.params.id}`
                : "add"
            }/${tabs[index]}`
        );
    };

    const handleFormChange = (formFieldChange: Record<string, unknown> & { type?: "physical" | "voucher"; }) => {
        if (formFieldChange.type) {
            setProductType(formFieldChange.type);
        }
    };

    const submitForm = async (values: ArticleFormValues) => {
        setIsSaving(true);

        const transformedValues = () => (props.match.params.id
            ? props.updateArticle({ variables: { ...formValuesToArticle(values), id: props.match.params.id } })
            : props.addArticle({ variables: { ...formValuesToArticle(values) } }));
        const errMsg = props.match.params.id ? "webshop.articleUpdateFailed" : "webshop.articleAddFailed";
        const updateMsg = props.match.params.id ? "webshop.articleUpdated" : "webshop.articleAdded";
        const url = (response) => (props.match.params.id ? "/articles" : `/articles/edit/${response.id}`);

        try {
            await form.validateFields();
            const response = await transformedValues();
            handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                const { history } = props;
                history.push(url(response));
            });
        } catch (errors) {
            handleError(<FormattedMessage id={errMsg} />, () => {
                resetErrors();
            });
            setError(errors);
            message.error(intl.formatMessage({ id: "error.checkHighlightedFields" }));
        }

        setIsSaving(false);
    };

    const onFailed = (err) => {
        setError(err);
    };

    const defaultProps = {
        changeSelectedLanguage,
        onChange: () => {
            if (enabledSave) {
                return;
            }
            setEnabledSave(true);
        }
    };

    function webshopTabs() {
        const articleType = form.getFieldValue("type") || article.type;

        if (articleType === "physical") {
            return [
                <Badge dot={articleErrorMap(ADD_ARTICLE_TABS.general, fieldErrors)}><FormattedMessage id={ADD_ARTICLE_TABS.general} /></Badge>
            ];
        }

        return [
            <Badge dot={articleErrorMap(ADD_ARTICLE_TABS.general, fieldErrors)}><FormattedMessage id={ADD_ARTICLE_TABS.general} /></Badge>,
            <Badge dot={articleErrorMap(ADD_ARTICLE_TABS.voucher, fieldErrors)}><FormattedMessage id={ADD_ARTICLE_TABS.voucher} /></Badge>
        ];
    }

    if (articles?.loading || applications?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if (!articles?.articles?.length && match.params.id) {
        props.history.push("/404");
    }

    const generalTabActive = match.params.tab === ADD_ARTICLE_TABS.general;
    const voucherTabActive = match.params.tab === ADD_ARTICLE_TABS.voucher;

    const roles = getFrontendRoles(applications.applications || []);

    return (
        <AddArticlesPageStyle>
            <Form
                hideRequiredMark
                autoComplete="off"
                form={form}
                initialValues={article}
                onFinish={submitForm}
                onFinishFailed={onFailed}
                onValuesChange={handleFormChange}
            >
                <React.Fragment>
                    <HeaderContent>
                        <HeaderActions>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/articles">
                                        <FormattedMessage id="overview" />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {getText(articleForm.title || article.title, selectedLanguage)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div>
                                {enabledSave && <UnsavedChanges key="warning" />}
                                <Button
                                    className="headerButton"
                                    htmlType="submit"
                                    loading={isSaving}
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                                <Button
                                    className="headerButton"
                                    type="ghost"
                                >
                                    <FormattedMessage id="articles.delete" />
                                </Button>
                            </div>
                        </HeaderActions>
                        <Flex
                            fullWidth
                            justifyContent="space-between"
                        >
                            <Form.Item
                                noStyle
                                dependencies={[["type"]]}
                            >
                                {() => webshopTabs && (
                                    <ContentTabs
                                        currentTab={currentTab}
                                        handleTabSelectedLanguage={changeSelectedTab}
                                        tabs={webshopTabs()}
                                    />
                                )}
                            </Form.Item>
                        </Flex>
                    </HeaderContent>
                    <HiddenContainer hidden={!generalTabActive}>
                        <TitleAndDescriptionCard
                            {...defaultProps}
                            activeLanguage={selectedLanguage}
                            languageErrors={errorsToLanguageErrors({
                                title: fieldErrors && fieldErrors.title,
                                description: fieldErrors && fieldErrors.description
                            })}
                        />
                        <CoverImageCard
                            form={form}
                            {...defaultProps}
                            cropSettings={{
                                aspect: ImageRatios.DEFAULT,
                                zoom: true,
                                rotate: true
                            }}
                            defaultImage={article.imageUrl}
                            formField="imageUrl"
                            titleId="webshop.productImage"
                            onBlur={defaultProps.onChange}
                        />
                        <ProductSettingsCard
                            form={form}
                            {...defaultProps}
                            activeLanguage={selectedLanguage}
                            languageErrors={errorsToLanguageErrors({
                                price: fieldErrors && fieldErrors.price,
                                currentStock: fieldErrors && fieldErrors.currentStock,
                                publishOn: fieldErrors && fieldErrors.publishOn,
                                expiresOn: fieldErrors && fieldErrors.expiresOn
                            })}
                            roles={roles}
                            stockSettingAvailable={productType === "physical"}
                        />
                    </HiddenContainer>
                    {productType === "voucher" && (
                        <HiddenContainer hidden={!voucherTabActive}>
                            <VoucherInstructionCard
                                form={form}
                                {...defaultProps}
                                activeLanguage={selectedLanguage}
                                languageErrors={errorsToLanguageErrors({
                                    instruction: fieldErrors.instruction
                                })}
                            />
                            <VoucherCard
                                form={form}
                                {...defaultProps}
                            />
                        </HiddenContainer>
                    )}
                </React.Fragment>
            </Form>
        </AddArticlesPageStyle>
    );
};
/* tslint:enable */
