/* eslint-disable react/no-array-index-key */
import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { ChallengePrizesCard } from "@pages/addChallengesPage/tabs/prizes/challengePrizesCard/challengePrizesCard";
import { EditChallengePrize } from "@pages/addChallengesPage/tabs/prizes/editChallengePrize/editChallengePrize";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

export interface ChallengePrizesTabProps {
    form: FormInstance;
    editPrize(index: number): void;
    defaultProps: ChallengePageDefaultProps;
    errors?: ErrorDictionary;
    prizeIndex?: number;
    selectedLanguage: string;
    hidden: boolean;
    backFromEdit(): void;
}

export const ChallengPrizesTab: React.FC<ChallengePrizesTabProps> = (props) => {
    const {
        form,
        errors,
        selectedLanguage,
        editPrize,
        hidden,
        prizeIndex,
        defaultProps,
        backFromEdit
    } = props;

    const prizes = form.getFieldValue(["rewards", "prizes"]);

    return (
        <React.Fragment>
            <ChallengePrizesCard
                {...defaultProps}
                editPrize={editPrize}
                errors={errors}
                form={form}
                hidden={hidden || prizeIndex !== undefined}
            />
            {
                (prizes || []).map((prize, index) => {
                    return (
                        <EditChallengePrize
                            key={index}
                            activeLanguage={selectedLanguage}
                            back={backFromEdit}
                            form={form}
                            hidden={hidden || index !== prizeIndex}
                            index={index}
                            languageErrors={(hasError(errors, ["rewards", "prizes", index])
                                && errorsToLanguageErrors(errors.rewards.prizes[index])) || {}}
                            prize={prize}
                            {...defaultProps}
                        />
                    );
                })
            }
        </React.Fragment>
    );
};
