
import { MainPage } from "@pages/mainPage/mainPage";

import * as React from "react";

import { compose } from "redux";

export interface MainPageContainerProps {

}

export const MainPageContainer = compose<React.ComponentClass<MainPageContainerProps>>(

)(MainPage);

export type MainPageProps =
    MainPageContainerProps;
