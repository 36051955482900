import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { FormTemplateFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { ChallengeFormConditionLayout, LeftMenu, SideBar } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { AddFormTemplatesPageProps } from "@pages/addFormTemplatesPage/addFormTemplatesPageContainer";
import { AddFormTemplatesPageStyle } from "@pages/addFormTemplatesPage/addFormTemplatesPageStyle";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyFormTemplate } from "@utils/emptyItems/emptyFormTemplate";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { formTemplateToFormValues, formValuesToFormTemplate } from "@utils/mappers/formTemplateMapper";
import { Breadcrumb, Button, Card, Form, Menu, Popconfirm, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { FormStructure } from "./form/formStructure";
import { FormTranslations } from "./form/formTranslations";

export type ErrorObj = object;

export interface AddFormTemplatesPageState {
    selectedLanguage: string;
    activeMenuItem: string;
    unsaved: {
        formTemplate: boolean;
    };
    errors?: {
        title: ErrorObj;
        errorMessages: ErrorObj;
    };
}

export const AddFormTemplatesPage: React.FC<AddFormTemplatesPageProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();

    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setError] = useErrors();
    const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
    const [activeMenuItem, setActiveMenuItem] = React.useState<string>("1");

    const { formTemplatesQueryResults, editMode, history, removeFormTemplate, match, location } = props;

    const formTemplate = React.useMemo(
        () => createDefaultValues(formTemplateToFormValues, emptyFormTemplate, location.state?.import, formTemplatesQueryResults?.loading, formTemplatesQueryResults?.formTemplates?.[0]),
        [formTemplatesQueryResults]
    );

    const sharedProps = {
        intl
    };

    if (formTemplatesQueryResults?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if ((!formTemplatesQueryResults?.formTemplates?.length && match.params.id) || !formTemplate) {
        props.history.push("/404");
    }

    if (!formTemplate || !formTemplate.title) {
        return null;
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const errMsg = match.params.id ? "formTemplate.formTemplateUpdateFailed" : "formTemplate.formTemplateAddFailed";
        const updateMsg = match.params.id ? "formTemplate.formTemplateUpdated" : "formTemplate.formTemplateAdded";
        const url = () => ("/event/forms");

        try {
            await form.validateFields();
            const values: any = form.getFieldsValue();

            const transformValues = () => (match.params.id
                ? props.updateFormTemplate({ variables: { ...formValuesToFormTemplate(values), id: match.params.id } })
                : props.addFormTemplate({ variables: { ...formValuesToFormTemplate(values) } })
            );

            const response = await transformValues();
            if (response) {
                handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                    history.push(url());
                });
            }
        } catch (errors) {
            setError(errors);
            handleError(<FormattedMessage id={errMsg} />);
            message.error(<FormattedMessage id="error.checkHighlightedFields" />);
        }
    };

    const renderFormContentTab = (e) => {
        setActiveMenuItem(e.key);
    };

    const _removeFormTemplate = async (id?: string) => {
        if (!id) {
            return;
        }

        try {
            await removeFormTemplate({ variables: { id } });
            message.success(intl.formatMessage({ id: "templateRemoved" }));
            history.push("/event/forms");
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "templateRemoveFailed" }));
        }
    };

    const fetchedFormTemplate = form.getFieldsValue();

    return (
        <AddFormTemplatesPageStyle>
            <HeaderContent>
                <HeaderActions>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/event/forms">
                                <FormattedMessage id="overview" />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {getText(formTemplate.title, selectedLanguage)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        {unsavedChanges && <UnsavedChanges key="warning" />}
                        <Button
                            key="button1"
                            type="primary"
                            onClick={submitForm}
                        >
                            <FormattedMessage id="saveChanges" />
                        </Button>
                        {editMode && (
                            <Popconfirm
                                cancelText="No"
                                okText="Yes"
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={async () => _removeFormTemplate(formTemplate.id)}
                            >
                                <Button
                                    danger
                                    className="headerButton"
                                >
                                    <FormattedMessage id="formTemplate.delete" />
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </HeaderActions>
            </HeaderContent>
            <Form
                hideRequiredMark
                form={form}
                initialValues={formTemplate}
                onFieldsChange={() => {
                    if (!unsavedChanges) {
                        setUnsavedChanges(true);
                    }
                }}
                onFinish={submitForm}
            >
                <Card>
                    <ChallengeFormConditionLayout>
                        <SideBar>
                            <LeftMenu
                                className="sider-left"
                                defaultSelectedKeys={["1"]}
                                mode="inline"
                                onClick={renderFormContentTab}
                            >
                                <Menu.Item key="1">
                                    <FormattedMessage id="structure" />
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <FormattedMessage id="translations" />
                                </Menu.Item>
                            </LeftMenu>
                        </SideBar>
                        {form && (
                            <FormStructure
                                form={form}
                                hidden={!(activeMenuItem === "1")}
                                {...sharedProps}
                            />
                        )}
                        {form && (
                            <FormTranslations
                                activeLanguage={selectedLanguage}
                                changeSelectedLanguage={changeSelectedLanguage}
                                defaultValues={fetchedFormTemplate as FormTemplateFormValues}
                                form={form}
                                formTemplate={fetchedFormTemplate as FormTemplateFormValues}
                                hidden={!(activeMenuItem === "2")}
                                languageErrors={errorsToLanguageErrors({
                                    formTemplate: fieldErrors
                                })}
                                {...sharedProps}
                            />
                        )}
                    </ChallengeFormConditionLayout>
                </Card>
            </Form>
        </AddFormTemplatesPageStyle>
    );
};
