import { PlusCircleOutlined } from "@ant-design/icons";
import { FormListOperation } from "antd/lib/form/FormList";
import React from "react";

type AddListItemProps = {
    disable: boolean;
    add: FormListOperation["add"];
}

export const AddListItem: React.FC<AddListItemProps> = ({ disable, add }) => {
    return (
        <PlusCircleOutlined
            disabled={disable}
            style={{
                padding: 10,
                fontSize: 25
            }}
            onClick={() => add()}
        />
    );
};
