/* eslint-disable react/no-array-index-key */
import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { ChallengeConditionsCard } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeActivityConditionFormValues, EditChallengeActivityConditionCard } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityCondition";
import { ChallengeFormConditionFormValues, EditChallengeFormConditionCard } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

export interface ChallengeConditionsTabProps {
    defaultProps: ChallengePageDefaultProps;
    hidden: boolean;
    form: FormInstance;
    selectedLanguage: string;
    editCondition(index: number): void;
    onDownload(id: string): void;
    errors?: ErrorDictionary;
    conditionIndex?: number;
    backFromEdit(): void;
}

export const ChallengConditionsTab: React.FC<ChallengeConditionsTabProps> = (props) => {
    const {
        form,
        hidden,
        onDownload,
        backFromEdit,
        conditionIndex,
        selectedLanguage,
        editCondition,
        errors,
        defaultProps
    } = props;

    const fetchedConditions = form.getFieldValue("conditions");
    return (
        <React.Fragment>
            <ChallengeConditionsCard
                {...defaultProps}
                editCondition={editCondition}
                errors={errors}
                form={form}
                hidden={hidden || conditionIndex !== undefined}
                languageErrors={errorsToLanguageErrors({
                    conditions: errors && errors.conditions
                })}
                onDownload={onDownload}
            />

            {(fetchedConditions || []).map((condition, index) => {
                if (objectTypeCheck<ChallengeActivityConditionFormValues>(condition, "activity")) {
                    return (
                        <EditChallengeActivityConditionCard
                            key={index}
                            back={backFromEdit}
                            condition={condition}
                            form={form}
                            hidden={hidden || index !== conditionIndex}
                            index={index}
                            {...defaultProps}
                        />
                    );
                }
                if (objectTypeCheck<ChallengeFormConditionFormValues>(condition, "form")) {
                    return (
                        <EditChallengeFormConditionCard
                            key={index}
                            activeLanguage={selectedLanguage}
                            back={backFromEdit}
                            condition={condition}
                            errors={hasError(errors, [index]) && errors[index]}
                            form={form}
                            hidden={hidden || index !== conditionIndex}
                            index={index}
                            {...defaultProps}
                        />
                    );
                }
                return null;
            })}

        </React.Fragment>
    );
};
