import { Header } from "@components/header/header";
import { MainLayout, MainLayoutContent } from "@components/main/mainStyles";
import { Nav } from "@components/nav/nav";
import { Layout } from "antd";
import * as React from "react";

const NAV_BUFFER = {
    shrink: 80,
    expand: 200
};

export const Main: React.FC = ({ children }) => {
    const [hideMenu, setVisibility] = React.useState(false);

    return (
        <Layout className="full-height">
            <Nav collapsed={hideMenu} />
            <MainLayout push={hideMenu ? NAV_BUFFER.shrink : NAV_BUFFER.expand}>
                <Header
                    navCollapsed={hideMenu}
                    toggle={() => {
                        setVisibility(prevState => !prevState);
                    }}
                />
                <MainLayoutContent>
                    {children}
                </MainLayoutContent>
            </MainLayout>
        </Layout>
    );
};
