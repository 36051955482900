/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { ChallengeFormConditionLayout, DatePickerStretched, Label, LeftMenu, SideBar } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { ChallengeFormConditionStructure } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/structure/challengeFormConditionStructure";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { InjectedIntl } from "@utils/override.types";
import { storeImage } from "@utils/storeImage";
import { LanguageObject } from "@utils/toLanguageObject";
import { Badge, Button, Card, Col, Form, Menu, Row } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useCurrentLanguage } from "../../../../../hooks/useCurrentLanguage";
import { hiddenConditionInputs } from "../conditionUtil";
import { ChallengeFormConditionTranslations } from "./translations/challengeFormConditionTranslations";

export interface FormTemplateOptionFormValues {
    value: string;
    title: LanguageObject;
}

export interface FormTemplateElementFormValues {
    type: string | "select" | "checkbox" | "upload" | "fileUpload" | "a" | "content" | "text" | "radio" | "date" | "number" | "textarea";
    property: string;
    options?: FormTemplateOptionFormValues[];
    defaultValue: string;
    title: LanguageObject;
    required: boolean;
    min?: number | null;
    max?: number | null;
}

export interface FormTemplateFormValues {
    id: string;
    title: LanguageObject;
    errorMessages: LanguageObject;
    elements?: FormTemplateElementFormValues[];
}

export interface ChallengeFormConditionFormValues {
    id: string;
    sequence: number;
    type: string;
    startTime?: Moment | null;
    endTime?: Moment | null;
    image: string;
    credits?: number|null;
    formTemplate: FormTemplateFormValues;
}

export interface EditChallengeFormConditionFormValues {
    conditions: ChallengeFormConditionFormValues[];
}

export interface EditChallengeFormConditionState {
    activeMenuItem: string;
    selectedLanguage: string;
}

export interface EditChallengeFormConditionProps {
    errors?: ErrorDictionary;
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    hasError?: ErrorObj;
    activeLanguage: string;
    condition: ChallengeFormConditionFormValues;
    index: number;
    intl: InjectedIntl;
    back(): void;
    onBlur(): void;
    changeSelectedLanguage(languageCode: string): void;
}

export const EditChallengeFormConditionCard: React.FC<EditChallengeFormConditionProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [activeMenuItem, setActiveMenuItem] = React.useState<string>("1");

    const { form, onBlur, hidden, index, intl, back, errors } = props;

    const condition = form.getFieldValue(["conditions", index]);

    const sharedProps = {
        onBlur,
        intl
    };

    const renderFormContentTab = (e) => {
        setActiveMenuItem(e.key);
    };

    const renderFormConditionLayout = () => {
        const hasLanguageErrors = hasError(errors, ["formTemplate", "errorMessages"]);
        const hasStructureErrors = hasError(errors, ["formTemplate", "elements"]);

        const fetchedCondition = form.getFieldValue(["conditions", index]);
        return (
            <ChallengeFormConditionLayout>
                <SideBar>
                    <LeftMenu
                        className="sider-left"
                        defaultSelectedKeys={["1"]}
                        mode="inline"
                        onClick={renderFormContentTab}
                    >
                        <Menu.Item key="1">
                            <FormattedMessage id="structure" />
                            <Badge dot={hasStructureErrors} />
                        </Menu.Item>
                        <Menu.Item key="2">
                            <FormattedMessage id="translations" />
                            <Badge dot={hasLanguageErrors} />
                        </Menu.Item>
                    </LeftMenu>
                </SideBar>

                <ChallengeFormConditionStructure
                    condition={fetchedCondition}
                    form={form}
                    hidden={!(activeMenuItem === "1")}
                    index={index}
                    prefix={["conditions", index, "formTemplate"]}
                    {...sharedProps}
                />

                <ChallengeFormConditionTranslations
                    activeLanguage={selectedLanguage}
                    changeSelectedLanguage={changeSelectedLanguage}
                    condition={fetchedCondition}
                    errors={errors}
                    form={form}
                    hidden={!(activeMenuItem === "2")}
                    index={index}
                    prefix={["conditions", index, "formTemplate"]}
                    {...sharedProps}
                />

            </ChallengeFormConditionLayout>
        );
    };

    return (
        <React.Fragment>
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="general" />}
                />
                {/* TODO: Check "type"="flex" should no longer be needed */}
                <Row
                    gutter={16}
                    justify="start"
                >
                    <Col span={8}>
                        <FormattedMessage id="startingFrom" />
                        <Form.Item
                            name={["conditions", index, "startTime"]}
                            {...sharedProps}
                        >
                            <DatePickerStretched />
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <FormattedMessage id="endOn" />
                        <Form.Item
                            name={["conditions", index, "endTime"]}
                            {...sharedProps}
                        >
                            <DatePickerStretched />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Label><FormattedMessage id="coverImage" /></Label>
                        <FileDragAndDropContainer
                            defaultImage={condition.image}
                            onChange={async (image): Promise<void> => storeImage(() => onBlur(), form, ["conditions", index, "image"], image, undefined, { fields: [["conditions"], ["image"]], index })}
                        />
                        <Field
                            hidden
                            id={`conditions.${index}.image`}
                            name={["conditions", index, "image"]}
                        />
                    </Col>
                </Row>
            </Card>
            <Card hidden={hidden}>
                <ContentHeader
                    rightFields={
                        [
                            <Button
                                key="back"
                                onClick={back}
                            >
                                <FormattedMessage id="back" />
                            </Button>
                        ]
                    }
                    title={<FormattedMessage id="formContent" />}
                />
                {hiddenConditionInputs(form, condition, index, "form")}
                <Form.Item dependencies={[["conditions", index, "formTemplate", "elements"]]}>
                    {() => renderFormConditionLayout()}
                </Form.Item>
            </Card>
        </React.Fragment>
    );
};
