import { Button, Modal } from "antd";
import styled from "styled-components";

export const DashboardInfoStyle = styled.div`
    padding: 20px;
    border-radius: 6px;
    background: #373737;
    width: 100%;

    label {
        color: #FFFFFF !important;
    }
`;

export const AddButton = styled(Button)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
`;

export const PlaceModal = styled(Modal)`
    .ant-modal-footer {
        display: none;
    }
`;
