import { ExportChallengeContainer } from "@components/export/exportChallengeContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import { DeleteOutlined, UserOutlined, EditOutlined, CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { getFrontendRoleNamesFromIds } from "@utils/applicationUtils";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { Flex } from "../flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";

export interface ChallengeTableData extends TableData {
    visible: boolean;
    title: string;
    activity: string;
    start: number;
    hasTeam: boolean;
    end: number;
    requireVoucher?: boolean | null;
    roles: string[];
}

export interface ChallengeTableProps extends TableComponentProps {
    dataSource: ChallengeTableData[];
    handlers: TableHandlers & {
        removeChallenge(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<ChallengeTableData>;
}

const renderStatus = (visible: boolean) => <FormattedMessage id={visible ? "visible" : "invisible"} />;

export const ChallengeTable: React.FC<ChallengeTableProps> = ({ handlers, applications, ...tableProps }) => {
    const renderTitle = (text: string, row: ChallengeTableData) => (
        <Link className="plainText" to={`/challenges/edit/${row.id}/general`}>
            {text}
        </Link>
    );

    const renderTeam = (text: string, row: ChallengeTableData) => (
        row.hasTeam ? (
            <Link className="plainText" to={`/challenges/${row.id}/teams`}>
                <FormattedMessage id="challengeTable.manageTeams" />
            </Link>
        ) : null
    );

    const renderHandlers = (_, row: ChallengeTableData): Handlers => ({
        children: (
            <Flex alignItems="center" justifyContent="flex-end">
                <ExportChallengeContainer id={row.id} />
                <Link to={`/challenges/${row.id}/participants`}>
                    <TableIcon as={UserOutlined} />
                </Link>
                {row.hasTeam ? (
                    <Link to={`/challenges/${row.id}/teams`}>
                        <TableIcon as={EyeOutlined} />
                    </Link>
                ) : <TableIcon as={EyeOutlined} disabled style={{ color: "grey" }} type="eye" theme="outlined" />}
                <Link to={`/challenges/edit/${row.id}/general`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeChallenge(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    const renderRoles = (row: ChallengeTableData): string => {
        if (applications) {
            return getFrontendRoleNamesFromIds(applications, row.roles).join(", ");
        }
        return "";
    };

    return (
        <Table rowKey="id" {...tableProps}>
            <Column
                key="status"
                title={<FormattedMessage id="status" />}
                dataIndex="status"
                width={90}
                render={(_, row: ChallengeTableData) => renderStatus(row.visible)}
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                width={300}
                render={renderTitle}
            />
            <Column
                key="teamMembers"
                title={<FormattedMessage id="team" />}
                dataIndex="teamMembers"
                width={300}
                render={renderTeam}
            />
            <Column
                key="roles"
                title={<FormattedMessage id="roles" />}
                width={300}
                dataIndex="roles"
                render={(_, row: ChallengeTableData) => renderRoles(row)}
            />
            <Column
                key="activity"
                title={<FormattedMessage id="activity" />}
                dataIndex="activity"
                width={150}
            />
            <Column
                key="duration.start"
                title={<FormattedMessage id="start" />}
                dataIndex="start"
                width={120}
                sorter
                render={(_, row: ChallengeTableData) => moment(row.start).format("DD/MM/YYYY")}
            />
            <Column
                key="duration.end"
                title={<FormattedMessage id="end" />}
                dataIndex="end"
                width={120}
                sorter
                render={(_, row: ChallengeTableData) => moment(row.end).format("DD/MM/YYYY")}
            />
            <Column
                key="Voucher"
                title={<FormattedMessage id="voucher" />}
                dataIndex="requireVoucher"
                width={90}
                render={(text: string) => (text ? <CheckOutlined /> : <CloseOutlined />)}
            />
            <Column
                title=""
                dataIndex=""
                render={(_, row: ChallengeTableData) => renderHandlers(_, row)}
            />

        </Table>
    );
};
