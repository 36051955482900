import { languages as envLanguages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Badge, Tabs } from "antd";
import * as React from "react";

export interface TranslationTabsProps {
    languages?: Language[];
    activeLanguage?: string;
    errors?: LanguageErrors;
    handleChangeSelectedLanguage(languageCode: string): void;
}

export interface Language {
    code: string;
    label: string;
}

export const TranslationTabs: React.FC<TranslationTabsProps> = (props) => {
    const { handleChangeSelectedLanguage, errors, activeLanguage } = props;

    React.useEffect(() => {
        if (props.languages && props.languages.length) {
            const activeLanguageExists = props.languages.find(lang => lang.code === activeLanguage);

            if (!activeLanguageExists) {
                handleChangeSelectedLanguage(props.languages[0].code);
            }
        }
    }, [props.languages]);

    const languages = (props.languages && props.languages.length) ? props.languages : envLanguages;

    if (!languages || !languages.length) {
        return null;
    }

    return (
        <Tabs onChange={handleChangeSelectedLanguage} activeKey={activeLanguage}>
            {languages.map((language) => <Tabs.TabPane tab={<Badge dot={errors && errors[language.code]}>{language.label}</Badge>} key={language.code} />)}
        </Tabs>
    );
};
