import styled from "styled-components";
import { Flex } from "@components/flex/flex";
import { WidgetStyle } from "../widgetStyle";

export const ChallengeWidgetStyle = styled(WidgetStyle)`

    & ${Flex} {
        .ant-form-item {
            width: 100%;
        }
    }
`;
