import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { EventsPage } from "@pages/eventsPage/eventsPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import moment from "moment";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { EventFilter, GetApplicationsProps, GetEventCountProps, GetEventsOverviewProps, RemoveEventProps, withGetApplications, withGetEventCount, withGetEventsOverview, withRemoveEvent } from "../../graphql2/types";

interface EventsPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: EventFilter;
}

export const ONE_WEEK_AGO_DATE = moment().subtract(7, "days");

const createEventsPageFilter = (objectFilter: any) => {
    const createdFilter = {
        textSearch: objectFilter.textSearch,
        endsAfter: objectFilter.endsAfter ? moment(objectFilter.endsAfter).valueOf() : ONE_WEEK_AGO_DATE.valueOf(),
        isChronorace: objectFilter.isChronorace
    };

    return createdFilter;
};

const withApplications = withGetApplications({
    name: "applications"
});

const withEventsOverview = withGetEventsOverview({
    name: "events",
    options({ filter, paging, sort }: EventsPageProps) {
        return {
            variables: {
                filter,
                paging,
                sort
            }
        };
    }
});

const withEventCount = withGetEventCount({
    name: "count",
    options({ filter }: EventsPageProps) {
        return {
            variables: {
                filter
            }
        };
    }
});

const withRemoveEventMutation = withRemoveEvent({
    name: "removeEvent",
    options: {
        refetchQueries: ["getEvents", "getEventsOverview", "getEventCount"],
        awaitRefetchQueries: true
    }
});

export const EventsPageContainer = compose<React.ComponentClass>(
    withRouter,
    withParseDynamicFilterBar(createEventsPageFilter, { defaultSortSettings: { sortBy: "publishedOn", sort: "DESC" } }),
    withApplications,
    withEventsOverview,
    withEventCount,
    withRemoveEventMutation
)(EventsPage);

export type EventsPageProps = EventsPageFilterBarProps
    & RouteComponentProps
    & GetApplicationsProps<{}, "applications">
    & GetEventCountProps<{}, "count">
    & RemoveEventProps<{}, "removeEvent">
    & GetEventsOverviewProps<{}, "events">
    & GetEventCountProps<{}, "count">;
