import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { maxCharacters } from "@utils/maxCharacters";
import { validateMaxChar } from "@utils/validators/validateMaxChar";
import { InternalNamePath } from "antd/lib/form/interface";
import React from "react";
import { FormattedMessage } from "react-intl";

export interface CallToActionTextFieldProps {
    languageErrors?: LanguageErrors;
    itemKey?: number;
    prefix: InternalNamePath;
}

export const CallToActionTextField: React.FC<CallToActionTextFieldProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const { languageErrors, itemKey, prefix } = props;
    const langFieldProps = languages.map(language => ({
        itemKey, language: language.code, hidden: language.code !== selectedLanguage
    }));

    return (
        <React.Fragment>
            <TranslationTabs
                activeLanguage={selectedLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
            />
            {langFieldProps.map(langProps => (
                <Field
                    key={langProps.language}
                    required
                    hidden={langProps.hidden}
                    id={`callToActionText.${langProps.language}`}
                    info={maxCharacters(15)}
                    label={<FormattedMessage id="callToActionText" />}
                    name={[...prefix, "callToActionText", langProps.language]}
                    rules={[
                        requiredrule,
                        { validator: validateMaxChar(15) }
                    ]}
                />
            ))}
        </React.Fragment>
    );
};
