import { FormInstance } from "antd/lib/form";
import _ from "lodash";
import { createSetFieldsValue } from "./createSetFieldsValue";

export type NestedArrayFields = string[][];

export interface NestedArrayData {
    fields: NestedArrayFields;
    index: number;
}

export const setArrayFieldValue = (form: FormInstance, fields: NestedArrayFields, index: number, value) => {
    const firstItem: string[] = fields[0];
    const currentData = form.getFieldValue(firstItem);

    const newData = _.clone(currentData);
    if (!fields[1].length) {
        newData[index] = value;
    } else {
        _.set(newData[index], fields[1].join("."), value);
    }

    form.setFieldsValue(createSetFieldsValue(firstItem, newData));
};

export const setNestArrayFieldValue = (form: FormInstance, items: NestedArrayData[], value) => {
    if (!items.length) {
        return;
    }

    if (items.length < 2) {
        setArrayFieldValue(form, items[0].fields, items[0].index, value);
        return;
    }

    const firstItem = items[0].fields[0];
    const currentData = form.getFieldValue(firstItem);

    const newData = _.clone(currentData);

    const setArray: string[] = items[0].fields[1];
    for (let i = 1; i < items.length; i += 1) {
        const element = items[i];
        setArray.push(...element.fields[0], element.index.toString(), ...element.fields[1]);
    }

    _.set(newData[items[0].index], setArray, value);
    form.setFieldsValue(createSetFieldsValue(firstItem, newData));
};
