import { LogoutProps } from "@components/header/logout/logoutContainer";
import { LogoutStyle } from "@components/header/logout/logoutStyle";
import { useAuthActions } from "@context/authContext/actions";
import * as environment from "environment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const Logout: React.FC<LogoutProps> = ({ history, logout }) => {
    const { logoutAction } = useAuthActions();

    const handleLogout = async () => {
        const { data } = await logout();
        if (!data?.logout.success) {
            return;
        }
        logoutAction();
        if (environment.adfsUrl) {
            const { adfsUrl, anonymousToken } = environment;
            window.location.href = `${adfsUrl}?jwt=${anonymousToken}`;
        } else {
            history.push("/login");
        }
    };

    return (
        <LogoutStyle onClick={handleLogout}>
            <FormattedMessage id="logout" />
        </LogoutStyle>
    );
};
