import { DashboardConfigCarousselWidgetItemCaption, DashboardConfigWidgetBackground, DashboardConfigWidgetCarousselInput, DashboardConfigWidgetUrl, DashboardConfigWidgetCaroussel } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigCarousselWidgetItemCaptionState = Modify<DashboardConfigCarousselWidgetItemCaption, {
}>;

export type DashboardConfigWidgetCarousselItem = {
    type: string;
    typeId?: string | null;
    interval?: string | null;
    url?: DashboardConfigWidgetUrl | null;
    background?: DashboardConfigWidgetBackground | null;
    backgroundOption: "color" | "image";
    title: LanguageObject;
    subtitle: LanguageObject;
    description: LanguageObject;
    callToAction?: {
        text: LanguageObject;
    } | null;
};

export type DashboardConfigWidgetCarousselFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetCaroussel, {
    title: LanguageObject;
    items?: DashboardConfigWidgetCarousselItem[] | null;
    interval?: number;
}>>;

export const mapCarousselStateWidgetToCarousselWidget = (widget: DashboardConfigWidgetCarousselFormValues): DashboardConfigWidgetCarousselInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title),
        interval: widget.interval ? String(Number(widget.interval) * 1000) : "0",
        items: (widget.items || []).map(({ title, subtitle, description, backgroundOption, callToAction, ...widgetItem }) => {
            let optionals = {};

            if (callToAction && callToAction.text) {
                optionals = {
                    callToAction: {
                        text: callToAction.text ? toTextByLanguage(callToAction.text) : []
                    }
                };
            }

            return {
                ...widgetItem,
                caption: {
                    title: title ? toTextByLanguage(title) : [],
                    subtitle: subtitle ? toTextByLanguage(subtitle) : [],
                    description: description ? toTextByLanguage(description) : []
                },
                ...optionals
            };
        })
    };
};

export const mapCarousselWidgetToCarousselStateWidget = (carousselWidget: DashboardConfigWidgetCaroussel): DashboardConfigWidgetCarousselFormValues => {
    return {
        ...carousselWidget,
        title: toLanguageObject(carousselWidget.title),
        type: carousselWidget.type as ViewType,
        interval: carousselWidget.interval ? (Number(carousselWidget.interval) / 1000) : 0,
        items: (carousselWidget.items || []).map(({ caption, callToAction, ...widgetItem }) => ({
            ...widgetItem,
            title: caption && caption.title ? toLanguageObject(caption.title) : {},
            subtitle: caption && caption.subtitle ? toLanguageObject(caption.subtitle) : {},
            description: caption && caption.description ? toLanguageObject(caption.description) : {},
            backgroundOption: widgetItem.background && widgetItem.background.url ? "image" : "color",
            callToAction: callToAction && callToAction.text && {
                text: toLanguageObject(callToAction.text)
            }
        }))
    };
};
