import * as React from "react";
import { compose } from "redux";

import { ExportProps, exportFactory } from "./export";
import { getByIdQuery, withLocalState, ExportContainerProps } from "./exportContainer";
import { withGetJourneys, GetJourneysProps } from "../../graphql2/types";

export const withJourney = withGetJourneys(getByIdQuery);

export const ExportJourneyContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withJourney
)(exportFactory({ targetProp: ["data", "journeys"] }));

export type ExportJourneyContainerprops = ExportContainerProps & MaybeElements<GetJourneysProps>;
