/* eslint-disable no-mixed-operators */

import { FormTemplateFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { AddFormTemplateMutationVariables, FormTemplateFragment } from "../../graphql2/types";

export const formTemplateToFormValues = (formTemplate: Partial<FormTemplateFragment>): FormTemplateFormValues => {
    return {
        id: formTemplate && formTemplate.id || "",
        elements: formTemplate && formTemplate.elements && formTemplate.elements.map((element) => {
            let required = element?.required || false;
            if (["a", "link", "content", "h1", "h2", "p"].some(type => type === element?.type)) {
                required = false;
            }
            return ({
                ...element,
                title: toLanguageObject(element && element.title || ""),
                defaultValue: element && element.defaultValue || "",
                property: element && element.property || "",
                type: element && element.type || "",
                required,
                options: element && element.options
                    ? element.options.map(option => ({
                        value: option && option.value || "",
                        title: toLanguageObject(option ? option.title : "")
                    }))
                    : []
            });
        }) || [],
        title: toLanguageObject(formTemplate && formTemplate.title),
        errorMessages: toLanguageObject(formTemplate && formTemplate.errorMessages)
    };
};

// TODO: This gave compile errors, is projectId required or not?
export const formValuesToFormTemplate = (formValues: FormTemplateFormValues): AddFormTemplateMutationVariables => ({
    ...formValues,
    type: "event",
    title: toTextByLanguage(formValues.title),
    errorMessages: toTextByLanguage(formValues.errorMessages),
    elements: formValues.elements && formValues.elements.map(element => ({
        ...element,
        title: toTextByLanguage(element && element.title || ""),
        options: element && element.options
            ? element.options.map(option => ({
                ...option,
                title: toTextByLanguage(option && option.title || "")
            }))
            : []
    })) || []
});
