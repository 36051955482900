import { getApplications, getUserCount, getUsers, getUsersOverview } from "@graphql2/graphql_op";
import { GetUsersProps, ImpersonateUserProps, RemoveUserProps, RequestValidateEmailProps, ResyncProviderProps, UnlinkProviderProps, UpdateUserProps, ValidateEmailProps, withGetUsers, withImpersonateUser, withRemoveUser, withRequestValidateEmail, withResyncProvider, withUnlinkProvider, withUpdateUser, withValidateEmail } from "@graphql2/types";
import { UserDetailPage } from "@pages/userDetailPage/userDetailPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface UserDetailPageContainerProps { }

type UserDetailPageRouteProps = RouteComponentProps<{ id: string; }>;

const withUser = withGetUsers({
    name: "users",
    options: (ownProps: UserDetailPageRouteProps) => ({
        variables: {
            filter: {
                ids: [ownProps.match.params.id]
            }
        }
    })
});

const withResyncProviderMutation = withResyncProvider({
    name: "resyncProvider"
});

const withUpdateUserMutation = withUpdateUser({
    name: "updateUser",
    options: (ownProps: UserDetailPageRouteProps) => ({
        update: cleanCache([getUsers, getApplications]),
        refetchQueries: [{
            query: getUsers,
            variables: {
                filter: {
                    ids: [ownProps.match.params.id]
                }
            }
        }],
        awaitRefetchQueries: true
    })
});
const withRemoveUserMutation = withRemoveUser({
    name: "removeUser",
    options: {
        refetchQueries: [{
            query: getUserCount,
            variables: {
                filter: {}
            }
        }],
        awaitRefetchQueries: true,
        update: cleanCache([getUsersOverview])
    }
});
const withImpersonateUserMutation = withImpersonateUser({ name: "impersonateUser" });
const withUnlinkProviderMutation = withUnlinkProvider({
    name: "unlinkProvider",
    options: (ownProps: UserDetailPageRouteProps) => ({
        refetchQueries: [{
            query: getUsers,
            variables: {
                filter: {
                    ids: [ownProps.match.params.id]
                }
            }
        }],
        awaitRefetchQueries: true

    })
});
const withValidateEmailMutation = withValidateEmail({
    name: "validateEmail",
    options: (ownProps: UserDetailPageRouteProps) => ({
        refetchQueries: [{
            query: getUsers,
            variables: {
                filter: {
                    ids: [ownProps.match.params.id]
                }
            }
        }],
        awaitRefetchQueries: true
    })
});

const withRequestValidateEmailMutation = withRequestValidateEmail({ name: "requestValidateEmail" });

export const UserDetailPageContainer = compose<React.ComponentClass<UserDetailPageContainerProps>>(
    withRouter,
    withUser,
    withUpdateUserMutation,
    withRemoveUserMutation,
    withImpersonateUserMutation,
    withUnlinkProviderMutation,
    withResyncProviderMutation,
    withValidateEmailMutation,
    withRequestValidateEmailMutation
)(UserDetailPage);

export type UserDetailPageProps = UserDetailPageContainerProps
    & UserDetailPageRouteProps
    & GetUsersProps<{}, "users">
    & UpdateUserProps<{}, "updateUser">
    & RemoveUserProps<{}, "removeUser">
    & ImpersonateUserProps<{}, "impersonateUser">
    & UnlinkProviderProps<{}, "unlinkProvider">
    & ResyncProviderProps<{}, "resyncProvider">
    & ValidateEmailProps<{}, "validateEmail">
    & RequestValidateEmailProps<{}, "requestValidateEmail">;
