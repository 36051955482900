import { JourneyTable } from "@components/journeyTable/journeyTable";
import { TableContainer } from "@components/table/tableContainer";
import { JourneysPageProps } from "@pages/journeysPage/journeysPageContainer";
import { JourneysPageStyle } from "@pages/journeysPage/journeysPageStyle";
import { readFile } from "@utils/readFile";
import { Alert, Button, Card, Upload } from "antd";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { onTableChange, getUpdateUrlQueryParams } from "@utils/withPagingAndSort";
import { HeaderContent } from "@components/headerContent/headerContent";
import { Flex } from "@components/flex/flex";
import { JourneyFragment } from "@graphql2/types";
import { translateJourneys } from "@utils/mappers/journeyMapper";

export function JourneysPage(props: JourneysPageProps) {
    const [uploadError, setUploadError] = useState<string>();
    const { filterQuery, count, removeJourney, data } = props;

    const translatedJourneys = React.useMemo(
        () => translateJourneys(data?.journeys || []),
        [data]
    );

    const handleUpload = async (info: { file: File }) => {
        try {
            const importData = await readFile<JourneyFragment>(info.file);
            props.history.push("/journeys/add/general", { import: importData });
        } catch (error) {
            setUploadError("upload.tryAgain");
        }
    };

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        filterQuery.updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const removeJourneyFn = async (id: string) => {
        await removeJourney({ variables: { id } });
    };

    return (
        <JourneysPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <h1><FormattedMessage id="journeys" /></h1>
                    <div>
                        <Upload
                            accept="application/json"
                            customRequest={handleUpload}
                        >
                            <Button className="headerButton" type="primary">
                                <FormattedMessage id="journeys.import" />
                            </Button>
                        </Upload>
                        <Link to="/journeys/add/general">
                            <Button className="headerButton" type="primary">
                                <FormattedMessage id="journeys.add" />
                            </Button>
                        </Link>
                    </div>
                </Flex>
            </HeaderContent>

            <Card>
                {uploadError && <Alert type="error" message={<FormattedMessage id={uploadError} />} />}
                <TableContainer
                    loading={count?.loading}
                    TableComponent={JourneyTable}
                    dataSource={translatedJourneys}
                    pagination={{
                        current: filterQuery.skip ? filterQuery.skip / filterQuery.pageSize + 1 : 1,
                        pageSize: filterQuery.pageSize,
                        total: count?.journeyCount
                    }}
                    onChange={updateTableSettings}
                    handlers={{
                        removeJourney: removeJourneyFn
                    }}
                />
            </Card>
        </JourneysPageStyle>
    );
}
