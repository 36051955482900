import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

import { AddTagsPage } from "@pages/addTagsPage/addTagsPage";
import { withExtraProps } from "@pages/tagsPage/baseTagsPageContainer";

import { AddAmbassadorTagProps, AddChallengeTagProps, AddContentTagProps, AddEventTagProps, AddGalleryTagProps, AddJourneyTagProps, GetAmbassadorTagsProps, GetChallengeTagsProps, GetContentTagsProps, GetEventTagsProps, GetGalleryTagsProps, GetJourneyTagsProps, RemoveAmbassadorTagProps, RemoveChallengeTagProps, RemoveContentTagProps, RemoveEventTagProps, RemoveGalleryTagProps, RemoveJourneyTagProps, UpdateAmbassadorTagProps, UpdateChallengeTagProps, UpdateContentTagProps, UpdateEventTagProps, UpdateGalleryTagProps, UpdateJourneyTagProps } from "@graphql2/types";

export const ADD_TAGS_TABS = {
    general: "general"
};

export interface AddTagsPageContainerProps {
    callbackUrl: string;
    path: string[];
}

export type AddTagsRouteProps = RouteComponentProps<{ tab: string; id: string; }>;

export function createAddTagsPageContainer({ withAddTag, withUpdateTag, withTags, withRemoveTag, callbackUrl, path }) {
    return compose<React.ComponentClass<AddTagsPageContainerProps>>(
        withRouter,
        withAddTag,
        withUpdateTag,
        withTags,
        withRemoveTag,
        withExtraProps({ callbackUrl, path })
    )(AddTagsPage);
}

export type AddTagsPageProps =
    AddTagsPageContainerProps
    & {
        isLoading: boolean;
        path: string[];
    }
    & RouteComponentProps<{ id: string; }, {}, { import: any; }>
    & (GetContentTagsProps | GetEventTagsProps | GetGalleryTagsProps | GetJourneyTagsProps | GetAmbassadorTagsProps | GetChallengeTagsProps)
    & (RemoveContentTagProps<{}, "removeTag"> | RemoveEventTagProps<{}, "removeTag"> | RemoveGalleryTagProps<{}, "removeTag"> | RemoveJourneyTagProps<{}, "removeTag"> | RemoveAmbassadorTagProps<{}, "removeTag"> | RemoveChallengeTagProps<{}, "removeTag">)
    & (AddContentTagProps<{}, "addTag"> | AddEventTagProps<{}, "addTag"> | AddGalleryTagProps<{}, "addTag"> | AddJourneyTagProps<{}, "addTag"> | AddAmbassadorTagProps<{}, "addTag"> | AddChallengeTagProps<{}, "addTag">)
    & (UpdateContentTagProps<{}, "updateTag"> | UpdateEventTagProps<{}, "updateTag"> | UpdateGalleryTagProps<{}, "updateTag"> | UpdateJourneyTagProps<{}, "updateTag"> | UpdateAmbassadorTagProps<{}, "updateTag"> | UpdateChallengeTagProps<{}, "updateTag">);

