import * as React from "react";
import { getDataFromProperties } from "./getDataFromProperties";
import { MergedTranslation } from "@components/translations/translationsList/translationsListContainer";
import { Translation } from "../graphql2/types";

// Merge generic and platform translations into one
export const createTranslationsMerge = (genericPath: string[], platformPath: string[], newPath: string) => {
    return (Component) => {
        return (props) => {
            let genericTranslations: Translation[] | null = getDataFromProperties(props, genericPath);
            const platformTranslations: Translation[] | null = getDataFromProperties(props, platformPath);

            if (props.superAdmin && genericTranslations) {
                const newProps = {
                    ...props,
                    [newPath]: genericTranslations
                };
                return <Component {...newProps} />;
            }

            if (!genericTranslations || !platformTranslations) {
                return <Component {...props} />;
            }

            const merged: MergedTranslation[] = genericTranslations.map(gt => {
                const platformTranslation = platformTranslations.find(pt => pt.key === gt.key);

                if (platformTranslation) {
                    return {
                        ...platformTranslation,
                        genericTranslations: [
                            ...gt.translations
                        ]
                    }
                }

                return gt;
            });

            const newProps = {
                ...props,
                [newPath]: merged
            };

            return <Component {...newProps} />
        }
    }
}