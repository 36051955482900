import { Badge } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface Tab {
    name: string;
    hasErrors?: boolean;
}

export const createTabs = (tabs: Tab[]) => tabs.map(({ name, hasErrors }) => (
    <Badge dot={hasErrors}>
        <FormattedMessage id={name} />
    </Badge>
));
