import { useAuth } from "@context/authContext/context";

export const useIsLoggedIn = (): boolean => {
    const { expirationDate } = useAuth();
    if (!expirationDate) {
        return false;
    }

    return (Date.now() / 1000) < expirationDate;
};
