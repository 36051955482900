import { SortProps } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { FixedChallengesField } from "@components/dashboard/widgets/challengeWidget/fixedChallengesField/fixedChallengesField";
import { WidgetSortProps } from "@components/dashboard/widgets/widgetSort";
import { GetChallengeTitlesProps, withGetChallengeTitles } from "@graphql2/types";
import { LocalStateProps, localState } from "@utils/withLocalState";
import * as moment from "moment";
import * as React from "react";
import { compose } from "redux";

interface LocalState {
    search?: string;
    ids?: string[];
    sort: SortProps;
}

const withLocalState = localState<LocalState>({
    search: undefined,
    ids: undefined,
    sort: {
        field: "priority",
        order: "",
        fields: ["priority"]
    }
});

export interface FixedChallengesFieldContainerProps {
    singleMode?: boolean;
    itemKey?: number;
    addSort?: boolean;
    forceSingle?: boolean;
    challengeOptions: WidgetSortProps;
    onlyChallengesWithVisualisations?: boolean;
    onChange?: (id: string) => void;
}

const withCurrentTitles = withGetChallengeTitles({
    name: "currentTitles",
    options: ({ addSort, sort }: FixedChallengesFieldContainerProps & LocalState) => {
        const sortOptions = addSort ? {
            field: sort.field,
            order: sort.order
        } : null;

        return {
            variables: {
                sort: sortOptions,
                filter: {
                    endsAfter: moment().startOf("day").valueOf()
                }
            }
        };
    }
});

export const FixedChallengesFieldContainer = compose<React.ComponentClass<FixedChallengesFieldContainerProps>>(
    withLocalState,
    withCurrentTitles
)(FixedChallengesField);

export type FixedChallengesFieldProps =
    FixedChallengesFieldContainerProps
    & LocalStateProps<LocalState>
    & GetChallengeTitlesProps<{}, "currentTitles">;
