import { GalleryTable } from "@components/gallery/galleryTable";
import { TableContainer } from "@components/table/tableContainer";
import { HeaderContent } from "@components/headerContent/headerContent";
import { GalleriesPageProps } from "@pages/galleriesPage/galleriesPageContainer";
import { readFile } from "@utils/readFile";
import { Alert, Button, Card, Upload } from "antd";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { onTableChange, getCurrentTablePage, getPageSize, getUpdateUrlQueryParams } from "@utils/withPagingAndSort";
import { Flex } from "@components/flex/flex";
import { translateGalleries } from "@utils/mappers/galleryMapper";
import { GalleryFragment } from "@graphql2/types";
import { useUpdateQuery } from "@utils/useUrlQuery";

export const GalleriesPage: React.FC<GalleriesPageProps> = (props) => {
    const [uploadError, setUploadError] = useState<string>();
    const { paging, count, galleries } = props;

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const translatedGalleries = React.useMemo(
        () => translateGalleries(galleries?.galleries as GalleryFragment[] || []),
        [galleries]
    );

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const handleUpload = async (info: { file: File }) => {
        try {
            const importData = await readFile<GalleryFragment>(info.file);
            props.history.push("/gallery/galleries/add/general", { import: importData });
        } catch (error) {
            setUploadError("upload.tryAgain");
        }
    };

    const removeGallery = async (id: string) => {
        await props.removeGallery({ variables: { id } });
    };

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <h1><FormattedMessage id="galleries.overview" /></h1>
                    <div>
                        <Upload accept="application/json" customRequest={handleUpload}>
                            <Button className="headerButton" type="primary">
                                <FormattedMessage id="galleries.import" />
                            </Button>
                        </Upload>
                        <Button
                            onClick={() => {
                                props.history.push("/gallery/galleries/add/general");
                            }}
                            className="headerButton"
                            type="primary"
                        >
                            <FormattedMessage id="galleries.addGallery" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                {uploadError && <Alert type="error" message={<FormattedMessage id={uploadError} />} />}
                <TableContainer
                    tableFilter={{}}
                    loading={galleries.loading}
                    TableComponent={GalleryTable}
                    dataSource={translatedGalleries || []}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count && count.galleryCount
                    }}
                    onChange={updateTableSettings}
                    handlers={{
                        removeGallery
                    }}
                />
            </Card>
        </div>
    );
};
