import { getChallengeTeamCount, getChallengeTeams } from "@graphql2/graphql_op";
import { FilterQueryStateProps, filterQueryState } from "@utils/withFilterQueryState";
import { FilterSortPagingQuery } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { AddChallengeTeamProps, GetChallengeTeamsProps, GetContentsProps, RemoveChallengeTeamProps, UpdateChallengeTeamProps, withAddChallengeTeam, withGetChallengeTeams, withGetContents, withRemoveChallengeTeam, withUpdateChallengeTeam } from "../../graphql2/types";
import { AddChallengesTeam } from "./addChallengesTeamPage";

const withFilterQueryState = filterQueryState<FilterSortPagingQuery>({
    pageSize: 100
});

export const ADD_CHALLENGE_TEAM_TABS = {
    general: "general"
};

export interface AddChallengeTeamPageContainerProps {
    editMode?: boolean;
}

export const withContentsById = withGetContents({
    name: "contentById",
    options({ challengeTeams, match }: AddChallengeTeamPageProps) {
        const team = challengeTeams?.challengeTeams?.find(t => t.id === match.params.teamId);
        return {
            variables: {
                filter: {
                    ids: team && team.contentId ? [team.contentId] : null
                }
            }
        };
    },
    skip({ challengeTeams, match }: AddChallengeTeamPageProps) {
        const team = challengeTeams?.challengeTeams?.find(t => t.id === match.params.teamId);
        return !team || !team.contentId;
    }
});

export const withContentsBySearch = withGetContents({
    name: "content",
    options({ filterQuery }: AddChallengeTeamPageProps) {
        return {
            variables: {
                filter: {
                    textSearch: filterQuery.textSearch
                }
            }
        };
    },
    skip({ filterQuery }: AddChallengeTeamPageProps) {
        return !filterQuery.textSearch;
    }
});

export const withChallengeTeams = withGetChallengeTeams({
    name: "challengeTeams",
    options: (props: AddChallengeTeamPageProps) => {
        return {
            variables: {
                filter: {
                    ids: [props.match.params.teamId]
                }
            }
        };
    },
    skip: (props: AddChallengeTeamPageProps) => {
        return !props.match.params.teamId;
    }
});

export const addChallengeTeamMutation = withAddChallengeTeam({
    name: "addTeam",
    options: (props: AddChallengeTeamPageProps) => {
        return {
            refetchQueries: () => [{
                query: getChallengeTeams,
                variables: {
                    filter: {
                        challengeId: props.match.params.id
                    },
                    paging: {
                        limit: props.filterQuery.pageSize,
                        skip: props.filterQuery.skip
                    }
                }
            }, {
                query: getChallengeTeamCount,
                variables: {
                    filter: {
                        challengeId: props.match.params.id
                    }
                }
            }],
            awaitRefetchQueries: true
        };
    }
});

export const updateChallengeTeamMutation = withUpdateChallengeTeam({
    name: "updateTeam"
});

const withRemoveChallengeTeamMutation = withRemoveChallengeTeam({
    name: "removeTeam",
    options: {
        refetchQueries: ["getChallengeTeams", "getChallengeTeamCount"],
        awaitRefetchQueries: true
    }
});

export const AddChallengesTeamPageContainer = compose<React.ComponentClass<AddChallengeTeamPageContainerProps>>(
    withRouter,
    withFilterQueryState,
    addChallengeTeamMutation,
    updateChallengeTeamMutation,
    withRemoveChallengeTeamMutation,
    withChallengeTeams,
    withContentsById,
    withContentsBySearch
)(AddChallengesTeam);

export type AddChallengeTeamPageProps = FilterQueryStateProps<FilterSortPagingQuery>
    & AddChallengeTeamProps<{}, "addTeam">
    & UpdateChallengeTeamProps<{}, "updateTeam">
    & RemoveChallengeTeamProps<{}, "removeTeam">
    & MaybeElements<GetChallengeTeamsProps<{}, "challengeTeams">>
    & MaybeElements<GetContentsProps<{}, "content">>
    & MaybeElements<GetContentsProps<{}, "contentById">>
    & AddChallengeTeamPageContainerProps
    & RouteComponentProps<{ id: string; teamId: string; }>;
