import * as React from "react";
import { compose } from "redux";

import { ExportProps, exportFactory } from "./export";
import { withLocalState, ExportContainerProps } from "./exportContainer";
import { withGetJourneyTags, GetJourneyTagsProps } from "../../graphql2/types";

export const withJourneyTag = withGetJourneyTags({
    skip(props: ExportContainerProps) {
        return !props.shouldExport;
    }
});

export const ExportJourneyTagContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withJourneyTag
)(exportFactory({ targetProp: ["data", "journeyTags"], targetSelector: (tags, id) => tags.find(t => t.id === id) }));

export type ExportJourneyTagContainerProps = ExportContainerProps & MaybeElements<GetJourneyTagsProps>;
