import { compose } from "redux";
import moment from "moment";
import { DynamicPreviewComponent } from "@components/dashboard/dynamicPreview/dynamicPreview";
import { WidgetSortProps } from "@components/dashboard/widgets/widgetSort";
import { SelectValue } from "antd/lib/select";
import { withGetContentTitles, GetContentTitlesProps, withGetChallengeTitles, GetChallengeTitlesProps, withGetEventTitles, GetEventTitlesProps } from "@graphql2/types";
import { FormInstance } from "antd/lib/form";

export interface DynamicPreviewContainerProps {
    titleId: string;
    type: PreviewTypes;
    paginationOptions: WidgetSortProps;
    form: FormInstance;
    widgetId: string;
    limit: number;
    sort?: Omit<SortProps, "fields">;
}

export enum PreviewTypes {
    challenge = "Challenge",
    event = "Event",
    content = "Content"
}

export interface SortProps {
    order: string;
    field: string;
    fields: SelectValue[];
}

const withEvents = withGetEventTitles<DynamicPreviewProps>({
    name: "eventTitles",
    options: ({ limit, paginationOptions: { defaults }, sort }) => {
        const fieldIsSet = sort?.field || (defaults && defaults.field);
        const sortOptions = fieldIsSet ? {
            sort: {
                field: fieldIsSet,
                order: sort?.order || (defaults && defaults.order)
            }
        } : {};

        return {
            variables: {
                paging: {
                    limit
                },
                filter: {
                    published: true
                },
                ...sortOptions
            }
        };
    },
    skip: (props) => {
        return props.type !== PreviewTypes.event;
    }
});

const withChallengeTitles = withGetChallengeTitles<DynamicPreviewProps>({
    name: "challengeTitles",
    options: ({ paginationOptions: { defaults }, limit, sort }) => {
        const fieldIsSet = sort?.field || (defaults && defaults.field);

        const sortOptions = fieldIsSet ? {
            sort: {
                field: fieldIsSet,
                order: sort?.order || (defaults && defaults.order)
            }
        } : {};

        return {
            variables: {
                paging: {
                    limit
                },
                filter: {
                    endsAfter: moment().startOf("day").valueOf()
                },
                ...sortOptions
            }
        };
    },
    skip: (props) => {
        return props.type !== PreviewTypes.challenge;
    }
});

const withContents = withGetContentTitles<DynamicPreviewProps>({
    name: "contentTitles",
    options: ({ limit, sort, paginationOptions: { defaults } }) => {
        const fieldIsSet = sort?.field || (defaults && defaults.field);
        const sortOptions = fieldIsSet ? {
            sort: {
                field: fieldIsSet,
                order: sort?.order || (defaults && defaults.order)
            }
        } : {};

        return {
            variables: {
                ...sortOptions,
                filter: {
                    expireAfter: moment().startOf("day").valueOf()
                },
                paging: {
                    limit
                }
            }
        };
    },
    skip: (props) => {
        return props.type !== PreviewTypes.content;
    }
});

export const DynamicPreviewContainer = compose<React.ComponentClass<DynamicPreviewContainerProps>>(
    withContents,
    withChallengeTitles,
    withEvents
)(DynamicPreviewComponent);

export type DynamicPreviewProps =
    DynamicPreviewContainerProps
    & MaybeElements<GetEventTitlesProps<{}, "eventTitles">>
    & MaybeElements<GetContentTitlesProps<{}, "contentTitles">>
    & MaybeElements<GetChallengeTitlesProps<{}, "challengeTitles">>
