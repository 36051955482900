export interface ProjectSubEventFactoryProps {
    id: string;
}

export const projectSubEventFactory = (props: ProjectSubEventFactoryProps) => {
    return {
        id: props.id,
        subscriptionEnabled: true,
        maxParticipants: 0,
        participationFull: false,
        participantOffset: 0,
        maxParticipantsDisabled: true
    };
};
