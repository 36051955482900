import styled from "styled-components";

export const DashboardWidgetConfiguratorStyle = styled.div`
    padding: 25px 0;
`;

export const WidgetsColumnCover = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFF;
    padding: 20px 0;
    border-radius: 4px;
`;

export const NoWidgetsFound = styled.div`
    font-size: 14px;
    padding-left: 12px;
`;
