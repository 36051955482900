import { useUpdateQuery, useUrlQuery } from "@utils/useUrlQuery";
import { FilterSortPagingQuery } from "@utils/withPagingAndSort";
import * as React from "react";

export type FilterQueryStateProps<TState> = {
    filterQuery: { updateUrlQuery(state: TState): void; } & TState;
};

export const filterQueryState = <TState extends FilterSortPagingQuery>(initialState: TState) => (Component: React.FC<{ filterQuery: { updateUrlQuery(state: TState): void; }; }>) => (props: {}) => {
    const urlParams = useUrlQuery<TState>(initialState);
    const updateUrlQuery = useUpdateQuery<TState>({ replace: true });

    return React.createElement(Component, {
        ...props,
        filterQuery: {
            ...urlParams,
            updateUrlQuery
        }
    });
};
