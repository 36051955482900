import { ExportChallengeTagContainer } from "@components/export/exportChallengeTagsContainer";
import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { withGetChallengeTags, withRemoveChallengeTag } from "../../graphql2/types";
import { getChallengeTags } from "../../graphql2/graphql_op";

export const withChallengeTags = withGetChallengeTags();
export const withRemoveChallengeTagMutation = withRemoveChallengeTag({
    name: "removeTag",
    options: {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: getChallengeTags }]
    }
});

export const ChallengeTagsPageContainer = createTagsPageContainer(
    withChallengeTags,
    withRemoveChallengeTagMutation,
    "/challenges/tags/add/general",
    "challenges",
    ExportChallengeTagContainer,
    ["data", "challengeTags"]
);
