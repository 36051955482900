import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { GetDashboardsOverviewCountProps, GetDashboardsOverviewProps, RemoveDashboardConfigurationProps, withGetDashboardsOverview, withGetDashboardsOverviewCount, withRemoveDashboardConfiguration } from "@graphql2/types";
import { DashboardsPage } from "@pages/dashboardsPage/dashboardsPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

const withDashboardConfigurations = withGetDashboardsOverview({
    name: "dashboardConfigurations",
    options: ({ sort, paging }: DashboardsPageProps) => ({
        variables: {
            paging,
            sort
        }
    })
});

const withDashboardConfigurationCount = withGetDashboardsOverviewCount({
    name: "count"
});

const withRemoveDashboardMutation = withRemoveDashboardConfiguration({
    name: "removeDashboardConfiguration",
    options: {
        refetchQueries: ["getDashboardsOverview", "getDashboardOverviewCount"],
        awaitRefetchQueries: true
    }
});

export const DashboardsPageContainer = compose<React.ComponentClass>(
    withParseDynamicFilterBar(undefined, { defaultSortSettings: { sortBy: "duration.start", sort: "DESC" } }),
    withDashboardConfigurations,
    withDashboardConfigurationCount,
    withRemoveDashboardMutation
)(DashboardsPage);

export type DashboardsPageProps = GraphQLPagingAndSort
    & GetDashboardsOverviewProps<{}, "dashboardConfigurations">
    & GetDashboardsOverviewCountProps<{}, "count">
    & RemoveDashboardConfigurationProps<{}, "removeDashboardConfiguration">
    & RouteComponentProps;
