import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { useErrors } from "@hooks/useErrors";
import { Button, Form, Popconfirm, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";

import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useAuth } from "@context/authContext/context";
import { createDefaultValues } from "@utils/createDefaultValues";
import { FlowFormValues, getEmptyFlow } from "@utils/emptyItems/emptyFlow";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { mapFlowToFormValues, mapFormValuesToAddFlows, mapFormValuesToUpdateFlows } from "@utils/mappers/flowsMapper";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AddFlowsPageProps } from "./addFlowsPageContainer";
import { OverviewCard } from "./cards/overviewCard";
import { StepsCard } from "./cards/stepsCard/stepsCard";

export const AddFlowsPage = ({ match, history, updateFlow, addFlow, removeFlow, flows }: AddFlowsPageProps) => {
    const [unsaved, setUnsaved] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const [form] = useForm();
    const [fieldErrors, setErrors] = useErrors();
    const intl = useIntl();
    const { projectId } = useAuth();

    const flow = React.useMemo(() => createDefaultValues(mapFlowToFormValues, getEmptyFlow(), flows?.flows?.[0], flows?.loading, flows?.flows?.[0]), [flows?.flows?.[0]]);

    React.useEffect(() => {
        if (flow) {
            form.setFieldsValue(flow);
        }
    }, [flow]);

    const onSubmit = async (values: FlowFormValues) => {
        const errMsg = match.params.id ? "flows.flowsUpdateFailed" : "flows.flowsAddFailed";
        const successMsg = match.params.id ? "flows.flowsUpdated" : "flows.flowsAdded";

        try {
            setIsSaving(true);
            const transformValues = async () => (
                match.params.id
                    ? updateFlow({ variables: mapFormValuesToUpdateFlows(values, projectId, match.params.id) })
                    : addFlow({ variables: mapFormValuesToAddFlows(values, projectId) })
            );

            const response = await transformValues();

            if (response) {
                handleSuccess(intl.formatMessage({ id: successMsg }), () => {
                    history.push("/flows");
                });
                setIsSaving(false);
            }
            setErrors({});
        } catch (err) {
            setIsSaving(false);
            setErrors(err);
            message.error(intl.formatMessage({ id: errMsg }));
        }
    };

    const onFailed = (err) => {
        setErrors(err);
    };

    const onFieldsChange = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const onDelete = async () => {
        try {
            await removeFlow({ variables: { flowId: match.params.id, projectId } });
            handleSuccess(<FormattedMessage id="flows.flowsRemoved" />, () => {
                history.push("/flows");
            });
        } catch (error) {
            handleError(<FormattedMessage id="flows.flowsRemovedFailed" />);
        }
    };

    if (flows?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    return (
        <Form
            form={form}
            initialValues={flow}
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            onFinishFailed={onFailed}
        >
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="overview"
                    />
                    <div>
                        {unsaved && <UnsavedChanges key="warning" />}
                        <Button
                            htmlType="submit"
                            loading={isSaving}
                            type="primary"
                        >
                            <FormattedMessage id="saveChanges" />
                        </Button>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            title={<FormattedMessage id="deleteConfirm" />}
                            onConfirm={onDelete}
                        >
                            <Button
                                className="headerButton"
                                disabled={!match.params.id}
                                type="ghost"
                            >
                                <FormattedMessage id="flows.delete" />
                            </Button>
                        </Popconfirm>
                    </div>
                </Flex>
            </HeaderContent>
            <OverviewCard
                form={form}
                languageErrors={errorsToLanguageErrors({
                    title: fieldErrors.title
                })}
            />
            <StepsCard
                fieldErrors={fieldErrors}
                form={form}
                stepsData={flow?.steps}
            />
        </Form>
    );
};

