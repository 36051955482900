/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { FormInstance } from "antd/lib/form";
import { Card, Button, Form, Table, Select } from "antd";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { FormattedMessage } from "react-intl";
import { Flex } from "@components/flex/flex";
import { TableIcon } from "@components/table/tableStyle";
import { DeleteOutlined } from "@ant-design/icons";
import { requiredrule } from "@components/form/requiredRule";
import { SelectField } from "@components/field/selectField";
import { TranslatedChallengeTitles } from "@utils/mappers/challengeMapper";
import { LocalStateProps } from "@utils/withLocalState";
import { debounce } from "@utils/debounce";
import { CardInfo } from "@components/style/globalStyles";
import { LocalState } from "../addVoucherPageContainer";

interface VoucherEnabledOnCardProps {
    form: FormInstance;
    challenges: TranslatedChallengeTitles[];
    challengesLoading: boolean;
    setLocalState: LocalStateProps<LocalState>["setLocalState"];
}

export const VoucherTypes = {
    challengeParticipation: "challengeParticipation"
};

export const VoucherEnabledOnCard: React.FC<VoucherEnabledOnCardProps> = (props) => {
    const { form, challenges, challengesLoading, setLocalState } = props;
    const enableOnTypes: string[] = [VoucherTypes.challengeParticipation];

    const enableOnSearch = (value: string) => {
        setLocalState({ filter: { textSearch: value } });
    };

    const enableOnSearchDebounced = debounce(enableOnSearch, 400);

    const addNewEnabledOn = (add: (data) => void) => {
        add({
            type: enableOnTypes[0],
            ids: []
        });
    };

    const renderHandlers = (row, remove: (index) => void) => {
        return (
            <Flex justifyContent="flex-end" alignItems="center">
                <a onClick={() => remove(row.index)}>
                    <TableIcon as={DeleteOutlined} />
                </a>
            </Flex>
        );
    };

    const renderTypeField = (row) => {
        return (
            <SelectField
                name={[row.index, "type"]}
                id="voucher.enabledOnType"
                rules={[requiredrule]}
            >
                {
                    enableOnTypes.map(type => <Select.Option value={type} key={type}>{type}</Select.Option>)
                }
            </SelectField>
        );
    };

    const renderIdsField = (row) => {
        return (
            <Form.Item noStyle dependencies={[[row.index, "type"]]}>
                {() => {
                    const type = form.getFieldValue(["enabledOn", row.index, "type"]);
                    return (
                        <SelectField
                            loading={challengesLoading}
                            name={[row.index, "ids"]}
                            mode="multiple"
                            id="voucher.enabledOnIds"
                            rules={[requiredrule]}
                            onSearch={enableOnSearchDebounced}
                            showSearch
                            showArrow={false}
                            filterOption={false}
                            notFoundContent={null}
                            defaultActiveFirstOption={false}
                        >
                            {
                                !!(type === VoucherTypes.challengeParticipation && challenges)
                                && challenges.map(challenge => (
                                    <Select.Option
                                        key={challenge.id}
                                        value={challenge.id}
                                    >
                                        {challenge.title}
                                    </Select.Option>
                                ))
                            }
                        </SelectField>
                    );
                }}
            </Form.Item>

        );
    };

    return (
        <Card>
            <Form.List name={["enabledOn"]}>
                {(fields, { add, remove }) => {
                    const dataSource = (form.getFieldValue("enabledOn") || []).map((a, i) => ({
                        index: i,
                        ids: a.ids,
                        type: a.type
                    }));
                    return (
                        <React.Fragment>
                            <Form.Item noStyle dependencies={[["enabledOn"]]}>
                                {() => {
                                    const selectedEnabledOnTypes = (form.getFieldValue("enabledOn") || []).map(enOn => enOn.type);
                                    const disabled = Boolean(enableOnTypes.filter(type => selectedEnabledOnTypes.includes(type)).length);
                                    return (
                                        <ContentHeader
                                            title={<FormattedMessage id="voucher.enabledOn" />}
                                            subTitle={(
                                                <CardInfo>
                                                    <FormattedMessage id="voucher.enabledOn.info" />
                                                </CardInfo>
                                            )}
                                            rightFields={[
                                                <Button
                                                    disabled={disabled}
                                                    onClick={() => addNewEnabledOn(add)}
                                                    type="primary"
                                                >
                                                    <FormattedMessage id="addNewRow" />
                                                </Button>
                                            ]}
                                        />
                                    );
                                }}
                            </Form.Item>
                            <Table dataSource={dataSource}>
                                <Table.Column
                                    key="type"
                                    title={<FormattedMessage id="voucher.enabledOnType" />}
                                    dataIndex="type"
                                    width={250}
                                    render={(_, row) => renderTypeField(row)}
                                />
                                <Table.Column
                                    key="ids"
                                    title={<FormattedMessage id="voucher.enabledOnIds" />}
                                    dataIndex="ids"
                                    render={(_, row) => renderIdsField(row)}
                                />
                                <Table.Column
                                    title=""
                                    key="handlers"
                                    dataIndex=""
                                    width={50}
                                    render={(_, row) => renderHandlers(row, remove)}
                                />
                            </Table>
                        </React.Fragment>
                    );
                }}
            </Form.List>

        </Card>
    );
};
