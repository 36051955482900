import { Tabs } from "antd";
import * as React from "react";

export interface ContentTabsProps {
    tabs: (string | JSX.Element)[];
    currentTab?: number;
    handleTabSelectedLanguage(key: string | number): void;
}

export const ContentTabs: React.FC<ContentTabsProps> = (props) => {
    const { handleTabSelectedLanguage, tabs, currentTab } = props;

    return (
        <Tabs onChange={handleTabSelectedLanguage} defaultActiveKey={String(currentTab)}>
            {tabs.map((tab, index) => <Tabs.TabPane tab={tab} key={String(index)} />)}
        </Tabs>
    );
};
