import { EventsGroupTableData } from "@components/eventsGroup/eventsGroupTable";
import { EventGroup, GetEventTitlesQuery } from "@graphql2/types";
import { emptyEventGroup } from "@utils/emptyItems/emptyEventGroup";
import { getText } from "@utils/getText";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";

export type EventGroupFormValues = {
    title: {[key:string]: string };
    events?: string[];
    subscriptionsCount: number;
    color?: string;
   }

const cachedEventTitles = new Map<string, GetEventTitlesQuery["events"][0]["title"]>();

const getEventById = (id: string, eventTitles: GetEventTitlesQuery["events"]) => {
    if (cachedEventTitles.has(id)) {
        return {
            id,
            title: getText(cachedEventTitles.get(id))
        };
    }
    const selectedEvent = eventTitles.find(event => event.id === id);
    if (selectedEvent) {
        cachedEventTitles.set(id, selectedEvent.title);
        return { id, title: getText(selectedEvent.title) };
    }
};

export const translateEventsGroups = (eventGroups: EventGroup[]|undefined, eventTitles: GetEventTitlesQuery["events"]|undefined): EventsGroupTableData[] => {
    if (!eventGroups) {
        return [];
    }

    return eventGroups.map(eventGroup => {
        const { id, name, eventIds, subscriptionsCount } = eventGroup;
        const events = eventIds?.map(eventId => getEventById(eventId, eventTitles ?? [])).filter(event => event !== undefined) as { id: string; title: string; }[];
        return {
            id,
            title: getText(name),
            events,
            subscriptionsCount
        };
    });
};

export const eventGroupToFormValues = (eventGroup?: EventGroup | null): EventGroupFormValues => {
    if (!eventGroup) {
        return emptyEventGroup;
    }
    const { name, eventIds, subscriptionsCount, color } = eventGroup;

    return {
        title: toLanguageObject(name),
        events: eventIds ?? undefined,
        subscriptionsCount,
        color: color ?? undefined
    };
};

export const formValuesToEventGroup = (formValues: EventGroupFormValues): Omit<EventGroup, "id"> => {
    const { title, events, subscriptionsCount, color } = formValues;
    return {
        name: toTextByLanguage(title),
        eventIds: events ?? [],
        subscriptionsCount: subscriptionsCount ?? 1,
        color: color ?? undefined
    };
};
