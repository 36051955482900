import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { withAddGalleryTag, withGetGalleryTags, withUpdateGalleryTag } from "@graphql2/types";
import { withRemoveGalleryTagMutation } from "@pages/tagsPage/galleryTagsPageContainer";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getGalleryTags } from "@graphql2/graphql_op";

export const AddGalleryTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddGalleryTag({
        name: "addTag",
        options: {
            update: cleanCache([getGalleryTags])
        }
    }),
    withUpdateTag: withUpdateGalleryTag({
        name: "updateTag"
    }),
    withTags: withGetGalleryTags({
        skip: (props: AddTagsRouteProps) => {
            return !props.match.params.id;
        }
    }),
    withRemoveTag: withRemoveGalleryTagMutation,
    callbackUrl: "/gallery/tags",
    path: ["data", "galleryTags"]
});
