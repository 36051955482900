import * as React from "react";
import { compose } from "redux";
import { Gallery, GetUsersProps, withGetUsersLight } from "@graphql2/types";
import type { FormInstance } from "antd/lib/form";
import { GalleryImageOverviewCard } from "./galleryImageOverviewCard";

interface GalleryImageOverviewCardContainerProps {
        activeLanguage: string;
        form: FormInstance;
        hidden: boolean;
        errors: any;
        changeSelectedLanguage(languageCode: string): void;
        defaultValues: Gallery;
}

const withUsers = withGetUsersLight({
    name: "users",
    options: (props: GalleryImageOverviewCardContainerProps) => ({
        variables: {
            filter: {
                ids: props.defaultValues.images.map(image => image.userId || "").filter(Boolean) || []
            }
        }
    })
});

export const GalleryImageOverviewCardContainer = compose<React.ComponentClass<GalleryImageOverviewCardContainerProps>>(
    withUsers
)(GalleryImageOverviewCard);

export type GalleryImageOverviewCardProps =
    GalleryImageOverviewCardContainerProps
    & GetUsersProps<{}, "users">
