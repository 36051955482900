/* eslint-disable react/prop-types */
import { SelectField } from "@components/field/selectField";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ModeSwitch } from "./modeSelectionStyles";

export interface ModeSelectionProps {
    widgetId: string;
    form: FormInstance;
    moduleName: "challenge" | "content" | "event";
}

export const ModeSelection: React.FC<ModeSelectionProps> = (props) => {
    const { widgetId, form, moduleName } = props;

    return (
        <ModeSwitch>
            <SelectField
                name={["widgets", widgetId, "mode"]}
                label={<FormattedMessage id={`${moduleName}Widget.mode`} />}
                getPopupContainer={(trigger: HTMLElement) => trigger}
                onChange={val => {
                    form.setFields([{
                        name: ["widgets", widgetId, `${moduleName}Ids`],
                        value: []
                    }]);
                }}
                showArrow
            >
                <Select.Option value="fixed"><FormattedMessage id={`${moduleName}Widget.fixed`} /></Select.Option>
                <Select.Option value="dynamic"><FormattedMessage id={`${moduleName}Widget.dynamic`} /></Select.Option>
            </SelectField>
        </ModeSwitch>
    );
};
