import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { useAuth } from "@context/authContext/context";
import { UserApplicationInput, UserPrivacyInput } from "@graphql2/types";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { AddUserPageProps } from "@pages/addUserPage/addUserPageContainer";
import { AddUserPageStyle } from "@pages/addUserPage/addUserPageStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { Button, Card, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface AddUserPageState {
    saving: boolean;
    storingImage: boolean;
    imageUrl?: string;
}

export interface AddUserFormValues {
    email: string;
    locale: string;
    avatar: string;
    givenName: string;
    familyName: string;
    profile?: JSON;
    privacy: UserPrivacyInput;
    applications: UserApplicationInput[];
    projectId: string;
}

export const AddUserPage: React.FC<AddUserPageProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();
    const { projectId } = useAuth();
    const [isSaving, setIsSaving] = React.useState(false);
    const [avatar] = React.useState();
    const { addUser, history } = props;

    const submitForm = async (values: AddUserFormValues) => {
        setIsSaving(true);

        try {
            await addUser({
                variables: {
                    ...values,
                    applications: [{
                        id: "frontend",
                        roles: []
                    }],
                    privacy: {
                        publicDisplayNameType: "fullname",
                        showActivities: false,
                        showAsNewMember: false
                    },
                    projectId
                }
            });
            message.success(intl.formatMessage({ id: "user.added" }));
            history.push("/users/overview");
        } catch (err) {
            message.error(intl.formatMessage({ id: "user.addFailed" }));
        }

        setIsSaving(false);
    };

    return (
        <AddUserPageStyle>
            <Form
                form={form}
                initialValues={{ locale: languages[0].code }}
                onFinish={submitForm}
            >
                <HeaderContent>
                    <Flex justifyContent="space-between">
                        <FormattedMessage id="addNewUser" />
                        <div>
                            <Button
                                className="headerButton"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="user.add" />
                            </Button>
                        </div>
                    </Flex>
                </HeaderContent>
                <Card title={<FormattedMessage id="general" />}>
                    <Flex>
                        <MarginRightDiv>
                            <Field
                                id="givenName"
                                label={<FormattedMessage id="givenName" />}
                                name="givenName"
                                rules={[requiredrule]}
                                type="text"
                            />
                        </MarginRightDiv>
                        <MarginRightDiv>
                            <Field
                                id="familyName"
                                label={<FormattedMessage id="familyName" />}
                                name="familyName"
                                rules={[requiredrule]}
                                type="text"
                            />
                        </MarginRightDiv>
                    </Flex>
                    <Flex>
                        <MarginRightDiv>
                            <Field
                                id="email"
                                label={<FormattedMessage id="email" />}
                                name="email"
                                rules={[requiredrule, { type: "email" }]}
                                type="text"
                            />
                        </MarginRightDiv>
                        <MarginRightDiv>
                            <Form.Item>
                                <SelectField
                                    id="locale"
                                    label={<FormattedMessage id="locale" />}
                                    name="locale"
                                    rules={[requiredrule]}
                                >
                                    {languages.map(l => (
                                        <Select.Option
                                            key={l.code}
                                            value={l.code}
                                        >
                                            {l.label}
                                        </Select.Option>
                                    ))}
                                </SelectField>
                            </Form.Item>
                        </MarginRightDiv>
                    </Flex>
                    <Flex>
                        <MarginRightDiv>
                            <SelectField
                                id="sex"
                                label={<FormattedMessage id="sex" />}
                                name={["profile", "sex"]}
                                rules={[requiredrule]}
                            >
                                {/* TODO: translations */}
                                <Select.Option value="male">male</Select.Option>
                                <Select.Option value="female">female</Select.Option>
                            </SelectField>
                        </MarginRightDiv>
                        <MarginRightDiv>
                            <DatePickerField
                                id="profile.birthDate"
                                label={<FormattedMessage id="profile.birthDate" />}
                                name={["profile", "birthDate"]}
                                rules={[requiredrule]}
                            />
                        </MarginRightDiv>
                    </Flex>
                    <FormattedMessage
                        id="avatar"
                        tagName="label"
                    />
                    <ImageUpload
                        cropSettings={{
                            aspect: ImageRatios.AVATAR,
                            zoom: true,
                            rotate: true
                        }}
                        form={form}
                        image={avatar}
                        name="avatar"
                    />
                </Card>
            </Form>
        </AddUserPageStyle>
    );
};
