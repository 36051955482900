import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Card, Checkbox, DatePicker, Row } from "antd";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Moment } from "moment";
import Form, { FormInstance } from "antd/lib/form";
import { Flex } from "@components/flex/flex";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DisableState } from "../creditsPage";

export type CollectionPeriodFormValues = {
    enableCollectionPeriod: boolean;
    from: Moment;
    to: Moment;
}

export type CollectionPeriodCardProps = {
    form: FormInstance;
    checkBoxChangeHandler: (event: CheckboxChangeEvent) => void
    dateChangeHandler: () => void;
    disableState: DisableState
}

export const CollectionPeriodCard: FC<CollectionPeriodCardProps> = (props) => {
    const { form, checkBoxChangeHandler, dateChangeHandler, disableState: { collectionPeriodDates } } = props;

    const validateDates = (rule, value: Moment | undefined, callback: (error?: string | undefined) => void) => {
        if (collectionPeriodDates) {
            return callback();
        }
        const fromValue = form.getFieldValue("from");
        const toValue = form.getFieldValue("to");

        if ((fromValue && toValue) && fromValue.valueOf() >= toValue.valueOf()) {
            callback("true");
        }

        return callback();
    };

    const renderDates = () => {
        return (
            <Flex>
                <Row>
                    <Form.Item
                        name="from"
                        dependencies={["to"]}
                        rules={[
                            {
                                validator: validateDates,
                                message: <FormattedMessage id="form.error.toDateMustBeLaterThanFromDate" />
                            },
                            {
                                required: !collectionPeriodDates,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        label={<FormattedMessage id="from" />}
                    >
                        <DatePicker
                            disabled={collectionPeriodDates}
                            onChange={dateChangeHandler}
                        />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item
                        name="to"
                        dependencies={["from"]}
                        rules={[
                            {
                                validator: validateDates,
                                message: <FormattedMessage id="form.error.toDateMustBeLaterThanFromDate" />
                            },
                            {
                                required: !collectionPeriodDates,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        label={<FormattedMessage id="to" />}
                    >
                        <DatePicker
                            disabled={collectionPeriodDates}
                            onChange={dateChangeHandler}
                        />
                    </Form.Item>
                </Row>
            </Flex>
        );
    };
    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="webshop.credits.collectionPeriod" />}
            />
            <Form.Item
                name="enableCollectionPeriod"
                valuePropName="checked"
            >
                <Checkbox onChange={checkBoxChangeHandler}>
                    <FormattedMessage id="webshop.credits.collectionPeriod.enable" />
                </Checkbox>
            </Form.Item>
            {renderDates()}
        </Card>
    );
};
