/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { hiddenConditionInputs } from "@pages/addChallengesPage/tabs/conditions/conditionUtil";
import { CardSubtitle, CheckboxRepeat, CheckboxWrapper, DatePickerLabel, DatePickerStretched, RepeatsRow } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityConditionStyle";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { setArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { Alert, Button, Card, Checkbox, Col, Form, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTIVITY_TYPES, MERGED_UNIT_TYPES } from "../../../../../constants";

export interface ChallengeActivityConditionFormValues {
    id: string;
    type: string;
    sequence: number;
    startTime?: Moment | null;
    endTime?: Moment | null;
    unit: string;
    unitType: string;
    value: number;
    credits?: number|null;
    activities: string[];
    repeated: {
        enabled: boolean;
        value: number;
        sequential: boolean;
    };
    timespan: {
        enabled: boolean;
        value: number;
        unit: string;
    };
    inOneGo: {
        enabled: boolean;
        repeat: number;
    };
}

export interface EditChallengeActivityConditionProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    hasError?: ErrorObj;
    condition: ChallengeActivityConditionFormValues;
    index: number;
    back(): void;
    onBlur(): void;
}

export const EditChallengeActivityConditionCard: React.FC<EditChallengeActivityConditionProps> = (props) => {
    const { form, onBlur, hidden, condition, disabled, index, back } = props;

    const sharedProps = {
        disabled,
        index,
        onBlur
    };

    const checkForException = (values: string[], exceptionKey: string) => {
        const dailyKeyPosition = values.indexOf(exceptionKey);
        if (values.length && dailyKeyPosition === values.length - 1) {
            return [exceptionKey];
        }

        return values.filter(v => v !== exceptionKey);
    };

    const handleActivityOnChange = (values: string[]) => {
        const exceptionKey = "daily";
        setArrayFieldValue(
            form,
            [
                ["conditions"],
                ["activities"]
            ],
            sharedProps.index,
            checkForException(values, exceptionKey)
        );
    };

    React.useEffect(() => {
        if (condition.activities.includes("daily")) {
            setArrayFieldValue(
                form,
                [
                    ["conditions"],
                    ["activities"]
                ],
                sharedProps.index,
                ["daily"]
            );
        }
    }, []);

    const renderConditionUnitSelect = () => {
        return (
            <SelectField
                id={`condition.${sharedProps.index}.unit`}
                label={<FormattedMessage id="unitType" />}
                name={["conditions", sharedProps.index, "unit"]}
                rules={[{
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }]}
                {...sharedProps}
            >
                {MERGED_UNIT_TYPES.map((value, key) => (
                    <Select.Option
                        key={key.toString()}
                        value={value}
                    >
                        <FormattedMessage
                            id={value}
                            values={{ time: 0 }}
                        />
                    </Select.Option>
                ))}
            </SelectField>
        );
    };

    const renderActivityDetails = () => {
        return (
            <React.Fragment>
                <CardSubtitle><FormattedMessage id="activity" /></CardSubtitle>
                <Row gutter={16}>
                    <Col span={6}>
                        <SelectField
                            autoClearSearchValue
                            label={<FormattedMessage id="activityTypes" />}
                            mode="multiple"
                            name={["conditions", sharedProps.index, "activities"]}
                            rules={[{
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }]}
                            showArrow={false}
                            onChange={handleActivityOnChange}
                            {...sharedProps}
                        >
                            {ACTIVITY_TYPES.map((activityType, key) => (
                                <Select.Option
                                    key={key.toString()}
                                    value={activityType.key}
                                >
                                    {activityType.value}
                                </Select.Option>
                            ))}
                        </SelectField>
                    </Col>
                    <Col>
                        <Field
                            numberType
                            id={`condition.${sharedProps.index}.value`}
                            label={<FormattedMessage id="value" />}
                            min={0}
                            name={["conditions", sharedProps.index, "value"]}
                            {...sharedProps}
                        />
                    </Col>
                    <Col span={6}>
                        {renderConditionUnitSelect()}
                    </Col>
                    <Col span={6}>
                        <Field
                            numberType
                            id={`condition.${sharedProps.index}.inOneGo.repeat`}
                            label={<FormattedMessage id="repetition" />}
                            min={0}
                            name={["conditions", sharedProps.index, "inOneGo", "repeat"]}
                            {...sharedProps}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name={["conditions", sharedProps.index, "inOneGo", "enabled"]}
                            valuePropName="checked"
                        >
                            <Checkbox
                                id={`condition.${sharedProps.index}.inOneGo.enabled`}
                                {...sharedProps}
                            >
                                <FormattedMessage id="completeEachRepetitionOneWorkout" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderActivityTimespan = () => {
        const time = form.getFieldValue(["conditions", sharedProps.index, "timespan.value"]);
        const timespanEnabled = Boolean(form.getFieldValue(["conditions", sharedProps.index, "timespan", "enabled"]));

        return (
            <React.Fragment>
                <CardSubtitle><FormattedMessage id="optionalTimespanToCompleteConidition" /></CardSubtitle>
                <CheckboxWrapper>
                    <Form.Item
                        name={["conditions", sharedProps.index, "timespan", "enabled"]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    setArrayFieldValue(
                                        form,
                                        [["conditions"], ["repeated", "enabled"]],
                                        sharedProps.index,
                                        false
                                    );
                                    setArrayFieldValue(
                                        form,
                                        [["conditions"], ["repeated", "value"]],
                                        sharedProps.index,
                                        0
                                    );
                                    setArrayFieldValue(
                                        form,
                                        [["conditions"], ["repeated", "sequential"]],
                                        sharedProps.index,
                                        false
                                    );
                                }
                            }}
                            {...sharedProps}
                        >
                            <FormattedMessage id="addTimespanToConidition" />
                        </Checkbox>
                    </Form.Item>
                </CheckboxWrapper>
                <Row gutter={16}>
                    <Col>
                        <Field
                            numberType
                            id="timespan"
                            min={0}
                            name={["conditions", sharedProps.index, "timespan", "value"]}
                            {...sharedProps}
                            disabled={!timespanEnabled}
                        />
                    </Col>
                    <Col span={8}>
                        <SelectField
                            id="timespanUnit"
                            name={["conditions", sharedProps.index, "timespan", "unit"]}
                            {...sharedProps}
                            disabled={!timespanEnabled}
                        >
                            <Select.Option value="days">
                                <FormattedMessage
                                    id="days"
                                    values={{ time: time || 0 }}
                                />
                            </Select.Option>
                            <Select.Option value="weeks">
                                <FormattedMessage
                                    id="weeks"
                                    values={{ time: time || 0 }}
                                />
                            </Select.Option>
                            <Select.Option value="months">
                                <FormattedMessage
                                    id="months"
                                    values={{ time: time || 0 }}
                                />
                            </Select.Option>
                        </SelectField>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderOptionalRepeats = () => {
        const timespanEnabled = Boolean(form.getFieldValue(["conditions", sharedProps.index, "timespan", "enabled"]));
        const repeatedEnabled = Boolean(form.getFieldValue(["conditions", sharedProps.index, "repeated", "enabled"]));

        return (
            <React.Fragment>
                <CardSubtitle><FormattedMessage id="optionalRepeats" /></CardSubtitle>
                <CheckboxWrapper>
                    <Form.Item
                        name={["conditions", sharedProps.index, "repeated", "enabled"]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            {...sharedProps}
                            disabled={!timespanEnabled}
                        >
                            <FormattedMessage id="repeatTimespan" />
                        </Checkbox>
                    </Form.Item>
                </CheckboxWrapper>
                <RepeatsRow gutter={16}>
                    <Col>
                        <Field
                            numberType
                            id="repeatTimespanValue"
                            min={0}
                            name={["conditions", sharedProps.index, "repeated", "value"]}
                            {...sharedProps}
                            disabled={!repeatedEnabled || !timespanEnabled}
                        />
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={["conditions", sharedProps.index, "repeated", "sequential"]}
                            valuePropName="checked"
                        >
                            <CheckboxRepeat
                                {...sharedProps}
                                disabled={!repeatedEnabled || !timespanEnabled}
                            >
                                <FormattedMessage id="sequentialRepeat" />
                            </CheckboxRepeat>
                        </Form.Item>
                    </Col>
                </RepeatsRow>
                {repeatedEnabled && (
                    <Alert
                        closable
                        showIcon
                        description={<FormattedMessage id="repeatWarning" />}
                        message={<FormattedMessage id="warning" />}
                        style={{ maxWidth: 1000 }}
                        type="warning"
                    />
                )}
            </React.Fragment>
        );
    };

    const renderActivitySettings = () => {
        return (
            <React.Fragment>
                <CardSubtitle><FormattedMessage id="activityConditionSettings" /></CardSubtitle>
                <Row gutter={16}>
                    <Col span={8}>
                        <DatePickerLabel><FormattedMessage id="startConditionFrom" /></DatePickerLabel>
                        <Form.Item
                            name={["conditions", sharedProps.index, "startTime"]}
                        >
                            <DatePickerStretched
                                {...sharedProps}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <DatePickerLabel><FormattedMessage id="conditionToBeCompletedBefore" /></DatePickerLabel>
                        <Form.Item
                            name={["conditions", sharedProps.index, "endTime"]}
                        >
                            <DatePickerStretched
                                {...sharedProps}
                            />
                        </Form.Item>

                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderOptionalCreditReward = () => {
        return (
            <React.Fragment>
                <CardSubtitle><FormattedMessage id="creditReward" /></CardSubtitle>
                <Field
                    numberType
                    id={`condition.${sharedProps.index}.credits`}
                    min={0}
                    name={["conditions", sharedProps.index, "credits"]}
                    {...sharedProps}
                />
            </React.Fragment>
        );
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                rightFields={[
                    <Button
                        key="back"
                        onClick={back}
                    >
                        <FormattedMessage id="back" />
                    </Button>
                ]}
                title={<FormattedMessage id="activityCondition" />}
            />

            {hiddenConditionInputs(form, condition, index, "activity")}

            {renderActivityDetails()}
            <Form.Item
                noStyle
                dependencies={[["conditions", sharedProps.index, "timespan", "enabled"]]}
            >
                {() => renderActivityTimespan()}
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={
                    [
                        ["conditions", sharedProps.index, "repeated", "enabled"],
                        ["conditions", sharedProps.index, "timespan", "enabled"]
                    ]
                }
            >
                {() => renderOptionalRepeats()}
            </Form.Item>
            {renderActivitySettings()}
            {renderOptionalCreditReward()}
        </Card>
    );
};
