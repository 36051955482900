import { getEventGroupCount, getEventGroups } from "@graphql2/graphql_op";
import { AddEventGroupProps, GetEventGroupsProps, GetEventTitlesProps, RemoveEventGroupProps, UpdateEventGroupProps, withAddEventGroup, withGetEventGroups, withGetEventTitles, withRemoveEventGroup, withUpdateEventGroup } from "@graphql2/types";
import { cleanCache } from "@utils/cleanCacheHoc";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { AddEventGroupPage } from "./addEventGroupPage";

interface AddEventGroupPageRouteProps extends RouteComponentProps<{ id: string|undefined; }> { }

const withEventGroups = withGetEventGroups({
    name: "eventGroups",
    options: (props: AddEventGroupPageRouteProps) => ({
        variables: {
            filter: {
                id: props.match.params.id
            }
        }
    }),
    skip(props: AddEventGroupPageRouteProps) {
        return !props.match.params.id;
    }
});

const withEventTitles = withGetEventTitles({
    name: "eventTitles",
    options: {
        variables: {
            filter: {
                published: true,
                endsAfter: moment().startOf("day").valueOf()
            }
        }
    }
});

const withAddEventGroupMutation = withAddEventGroup({
    name: "addEventGroup",
    options: {
        refetchQueries: () => ["getEventGroups"],
        awaitRefetchQueries: true,
        update: cleanCache([getEventGroups, getEventGroupCount])
    }
});

const withUpdateEventGroupMutation = withUpdateEventGroup({
    name: "updateEventGroup",
    options: {
        refetchQueries: () => ["getEventGroups"],
        awaitRefetchQueries: true,
        update: cleanCache([getEventGroups])
    }
});

const withRemoveEventGroupMutation = withRemoveEventGroup({
    name: "removeEventGroup",
    options: {
        refetchQueries: () => ["getEventGroups"],
        awaitRefetchQueries: true,
        update: cleanCache([getEventGroups, getEventGroupCount])
    }
});

export const AddEventGroupPageContainer = compose<React.ComponentClass<AddEventGroupPageRouteProps>>(
    withRouter,
    withEventGroups,
    withEventTitles,
    withAddEventGroupMutation,
    withUpdateEventGroupMutation,
    withRemoveEventGroupMutation
)(AddEventGroupPage);

export type AddEventGroupPageProps =
    AddEventGroupPageRouteProps &
    MaybeElements<GetEventGroupsProps<{}, "eventGroups">> &
    GetEventTitlesProps<{}, "eventTitles"> &
    AddEventGroupProps<{}, "addEventGroup"> &
    UpdateEventGroupProps<{}, "updateEventGroup"> &
    RemoveEventGroupProps<{}, "removeEventGroup">;
