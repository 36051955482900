import lodash from "lodash";

export const formatName = (label?: string | number) => lodash.upperFirst(lodash.lowerCase(label?.toString()));

export const setFields = (namePath: (string | number)[], value: unknown) => {
    const key = namePath[0];
    namePath.shift();
    if (!namePath.length) {
        return { [key]: value };
    }
    return { [key]: setFields(namePath, value) };
};

export const omit = (object: Object, namePath: (string | number)[], value: string | number) => {
    const key = namePath[0];
    if (!namePath.length) {
        return lodash.omit(object, [value]);
    }
    namePath.shift();
    const newObject = { ...object };
    newObject[key] = omit(object[key], namePath, value);
    return newObject;
};

