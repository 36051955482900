import { EmptyPage } from "@pages/addChallengesPage/tabs/visualisations/noChallengeConditions/noChallengeConditionsStyle";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const NoChallengeConditions: React.FC = () => {
    return (
        <EmptyPage>
            <h1><FormattedMessage id="noChallengeConditionsYet" /></h1>
            <p><FormattedMessage id="visualtionsNeedChallengeConditions" /></p>
        </EmptyPage>
    );
};
