import { Card, Row } from "antd";
import styled from "styled-components";

export const PrizesCard = styled(Card)`

    h2 {
        font-family: "PingFang SC", sans-serif;
        font-size: 16px;
        font-weight: 700;
    }

    .ant-row.ant-form-item:not(:last-child) {
        margin-bottom: 1rem;
    }

    .ant-row.ant-form-item.hidden {
        margin-bottom: 0;
    }
`;

export const CardWrapper = styled.div`

    height: 100%;

    .ant-card {
        margin: 20px 0;
        min-height: inherit;
        display: flex;
        flex-flow:column;
        justify-content: space-between;
        height: 100%;
    }

    .ant-card-cover {
        min-height: 200px;
        padding: 24px;
    }
`;

export interface PrizeCardCoverImageProps {
    backgroundImage?: string;
}

export const CardImage = styled.div`
    min-height: 200px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${(props: PrizeCardCoverImageProps) => (props.backgroundImage ? props.backgroundImage : "")});
`;

export const PrizeCard = styled.div`
    border: 1px solid #e8e8e8;
    max-height: 420px;
    display: flex;
    flex-flow:column;
    justify-content: space-between;
`;

export const PrizeCardCoverImage = styled.div`
    min-height: 260px;
    min-width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
`;

export const PrizeCardDetails = styled.div`
    min-height: 100px;
    max-width: 100%;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 24px;
`;

export const PrizeCardActions = styled.div`
    background-color: #fafafa;
    height: 48px;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon {
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        line-height: 22px;
        width: 50%;

        :first-of-type {
            border-right: 1px solid #e8e8e8;
        }
    }
`;

export const CardSubtitle = styled.h2`

    span {
        font-family: "pingFang", sans-serif;
        font-size: .75rem;
        font-weight: 600;
        color: #A0C63D;
    }
`;

// tslint:disable-next-line:no-any
export const RowTieBreaker = styled(Row as any)`
    border-top: 1px solid #e8e8e8;
`;
