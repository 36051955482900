/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export interface AmbassadorTableData extends TableData {
    place: string;
}

export interface AmbassadorTableProps extends TableComponentProps {
    dataSource: AmbassadorTableData[];
    handlers: TableHandlers & {
        removeAmbassadorConfig(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<AmbassadorTableData>;
}

export const AmbassadorTable: React.FC<AmbassadorTableProps> = ({ handlers, history, ...tableProps }) => {
    const renderTitle = (text: string, id: string) => (
        <Link className="plainText" to={`/ambassador/ambassadorConfigs/edit/${id}`}>
            {text}
        </Link>
    );

    const renderHandlers = (row: AmbassadorTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <a onClick={e => {
                    e.stopPropagation();
                    if (history) {
                        history.push(`/ambassador/ambassadorConfigs/edit/${row.id}`);
                    }
                }}
                >
                    <TableIcon as={EditOutlined} />
                </a>
                <Popconfirm
                    title={<FormattedMessage id="deleteConfirm" />}
                    okText="Yes"
                    cancelText="No"
                    onCancel={e => e && e.stopPropagation()}
                    onConfirm={e => {
                        if (e) {
                            e.stopPropagation();
                        }
                        handlers.removeAmbassadorConfig(row.id);
                    }}
                >
                    <a onClick={e => e.stopPropagation()}>
                        <TableIcon as={DeleteOutlined} />
                    </a>
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="place"
                title={<FormattedMessage id="place" />}
                dataIndex="place"
                sorter
                render={(text, row: AmbassadorTableData) => renderTitle(text, row.id)}
            />
            <Column
                key="sequence"
                title={<FormattedMessage id="sequence" />}
                dataIndex="sequence"
                sorter
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: AmbassadorTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
