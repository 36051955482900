import { DeleteOutlined } from "@ant-design/icons";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { GetChallengeParticipantsQuery } from "@graphql2/types";
import { RemoveFromTeamArgs, RemoveParticipantArgs } from "@pages/challengeParticipantsPage/challengeParticipantsPage";
import { Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface ChallengeParticipantsTableData extends TableData {
    userId: GetChallengeParticipantsQuery["challengeParticipants"][0]["userId"];
    user: GetChallengeParticipantsQuery["challengeParticipants"][0]["user"];
    team: GetChallengeParticipantsQuery["challengeParticipants"][0]["team"];
    challenge: GetChallengeParticipantsQuery["challengeParticipants"][0]["challenge"];
    createdOn?: number | null;
    progress?: number | null;
    tieBreakerAnswers?: GetChallengeParticipantsQuery["challengeParticipants"][0]["tieBreakerAnswers"] | null;
}

export interface ChallengeParticipantsTableProps extends TableComponentProps {
    dataSource: ChallengeParticipantsTableData[];
    handlers: TableHandlers & {
        removeMemberFromTeam(config: RemoveFromTeamArgs): void;
        removeChallengeParticipant(config: RemoveParticipantArgs): void;
    };
}

const renderTitle = (text: string, row: ChallengeParticipantsTableData) => (
    <Link
        className="plainText"
        to={`/users/detail/${row.user.id}`}
    >
        {text}
    </Link>
);

const renderHandlers = (_, row: ChallengeParticipantsTableData, handlers: ChallengeParticipantsTableProps["handlers"]) => {
    const handleRemoval = () => {
        if (row.team?.id) {
            handlers?.removeMemberFromTeam({
                teamId: row.team.id,
                userId: row.userId
            });
        } else {
            handlers?.removeChallengeParticipant({
                challengeId: row.challenge.id,
                userId: row.userId
            });
        }
    };

    if (row.challenge.autoParticipationEnabled) {
        return null;
    }

    return {
        children: (
            <Popconfirm
                cancelText="No"
                okText="Yes"
                title={<FormattedMessage id="deleteConfirm" />}
                onConfirm={handleRemoval}
            >
                <DeleteOutlined style={{ marginRight: "12px" }} />
            </Popconfirm>
        ),
        props: {
            colSpan: 2
        }
    };
};

export const ChallengeParticipantsTable: React.FC<ChallengeParticipantsTableProps> = (tableProps) => {
    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="givenName"
                dataIndex={["user", "givenName"]}
                render={renderTitle}
                title={<FormattedMessage id="user.givenName" />}
            />
            <Column
                key="familyName"
                dataIndex={["user", "familyName"]}
                render={renderTitle}
                title={<FormattedMessage id="user.familyName" />}
            />
            <Column
                key="participatedOn"
                dataIndex="createdOn"
                render={(timestamp: number) => moment(timestamp).format("DD/MM/YYYY")}
                title={<FormattedMessage id="participatedOn" />}
            />
            <Column
                key="progress"
                dataIndex="progress"
                title={<FormattedMessage id="progress" />}
            />
            <Column
                key="answer1"
                dataIndex={["tieBreakerAnswers", "answer1"]}
                title={<FormattedMessage id="answer1" />}
            />
            <Column
                key="answer2"
                dataIndex={["tieBreakerAnswers", "answer2"]}
                title={<FormattedMessage id="answer2" />}
            />
            <Column
                key="handlers"
                dataIndex=""
                render={(_, row: ChallengeParticipantsTableData) => renderHandlers(_, row, tableProps.handlers)}
                title=""
                width={120}
            />
        </Table>
    );
};
