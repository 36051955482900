/* eslint-disable jsx-a11y/control-has-associated-label */
import { CheckupTable } from "@components/checkupTable/checkupTable";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { useAuth } from "@context/authContext/context";
import { CheckupPageProps } from "@pages/checkupPage/checkupPageContainer";
import { CheckupPageStyle } from "@pages/checkupPage/checkupPageStyle";
import { findDateFormat } from "@utils/csvToCheckups";
import { getText } from "@utils/getText";
import { translateCheckupLocationAvailabilities } from "@utils/mappers/checkupMapper";
import { csvToJson, readFile } from "@utils/readFile";
import { onTableChange } from "@utils/withPagingAndSort";
import { Alert, Button, Card, Checkbox, Upload } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface CheckupPageState {
    uploadError?: string;
}

interface CheckupCSV {
    location: string;
    date: string;
    hour: string;
    duration: string;
    coachType: string;
}

export const CheckupPage: React.FC<CheckupPageProps> = (props) => {
    const { data: { loading, checkupLocationAvailabilities }, pageSize, count: { checkupLocationAvailabilityCount }, setLocalState, filter, checkupLocations } = props;
    const { userId } = useAuth();
    const [uploadError, setUploadError] = React.useState<string>();

    const translatedCheckupLocationAvailabilities = React.useMemo(
        () => translateCheckupLocationAvailabilities(checkupLocationAvailabilities || []),
        [checkupLocationAvailabilities, loading]
    );

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        setLocalState({
            ...onTableChange(pagination, filters, sorter),
            filter
        });
    };

    const toggleHideFinished = (e: CheckboxChangeEvent) => {
        let date: number | undefined;

        if (e.target.checked) {
            date = Date.now();
        }

        setLocalState({
            filter: {
                from: date
            }
        });
    };

    const handleUploadResult = async (csv: string) => {
        const { addCheckupLocationAvailability } = props;
        const locationLookup = (checkupLocations.checkupLocations || []).reduce(
            (p, c) => ({
                ...p,
                [getText(c.title).toLowerCase()]: c.id
            }),
            {}
        );

        const [checkup] = csvToJson<CheckupCSV>(csv);

        const checkupLocationId = locationLookup[checkup.location.toLowerCase()];
        if (!checkupLocationId) {
            setUploadError("checkup.noLocationId");
            return;
        }

        const format = findDateFormat(checkup.date);
        const from = moment(checkup.date, format);
        const hourParts = checkup.hour.split(":");
        from.set("hour", Number(hourParts[0]));
        from.set("minute", Number(hourParts[1]));
        const durationParts = checkup.duration.split("h");
        const hourDuration = Number(durationParts[0]);
        const minDuration = Number(durationParts[1].replace("m", ""));
        const to = moment(from).add(hourDuration, "hour").add(minDuration, "minute");

        const data = {
            checkupLocationId,
            coachId: userId ?? "",
            coachType: checkup.coachType.toLowerCase(),
            cost: {
                amount: 0,
                description: []
            },
            from: from.valueOf(),
            to: to.valueOf()
        };

        await addCheckupLocationAvailability({ variables: data });
    };

    const handleUpload = async (info: { file: File; }) => {
        try {
            const data = await readFile<string>(info.file);
            await handleUploadResult(data);
        } catch (error) {
            setUploadError(error.message || "upload.tryAgain");
        }
    };

    const removeCheckup = async (id: string) => {
        const { removeCheckupLocationAvailability } = props;
        await removeCheckupLocationAvailability({ variables: { id } });
    };

    return (
        <CheckupPageStyle>
            <HeaderContent>
                <h1><FormattedMessage id="checkups" /></h1>
                <div>
                    <a
                        download
                        href="/assets/csv/checkups.csv"
                    >
                        <Button
                            ghost
                            className="headerButton"
                            type="primary"
                        >
                            <FormattedMessage id="checkups.example" />
                        </Button>
                    </a>
                    <Upload
                        accept="application/csv"
                        customRequest={handleUpload}
                        showUploadList={false}
                    >
                        <Button
                            className="headerButton"
                            type="primary"
                        >
                            <FormattedMessage id="checkups.upload" />
                        </Button>
                    </Upload>
                </div>
            </HeaderContent>
            <Card>
                {uploadError && (
                    <Alert
                        message={<FormattedMessage id={uploadError} />}
                        type="error"
                    />
                )}
                <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ gap: 10 }}
                >
                    <Checkbox
                        defaultChecked
                        name="hideFinished"
                        onChange={toggleHideFinished}
                    >
                        <FormattedMessage id="checkup.hideFinished" />
                    </Checkbox>
                    <Link to="/checkups/add">
                        <Button type="primary">
                            <FormattedMessage id="add" />
                        </Button>
                    </Link>
                </Flex>

                <TableContainer
                    dataSource={translatedCheckupLocationAvailabilities}
                    handlers={{
                        removeCheckup
                    }}
                    loading={loading}
                    pagination={{
                        pageSize,
                        total: checkupLocationAvailabilityCount
                    }}
                    TableComponent={CheckupTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </CheckupPageStyle>
    );
};
