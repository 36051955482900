import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { Sort as GraphQLSort, Paging as GraphQLPaging } from "@graphql2/types";

export interface GraphQLPagingAndSort {
    sort?: GraphQLSort;
    paging?: GraphQLPaging
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC"
}

export interface Paging {
    offset: number;
    limit: number;
}

export interface Sort {
    field: string;
    order: SortOrder;
}

export type PagingAndSortComponentProps = {
    // tslint:disable-next-line:no-any
    onTableChange(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>): void;
} & PagingAndSortState;

export interface PagingAndSortState {
    pageSize: number;
    paging: Paging | null;
    sort: Sort[] | null;
}

export interface SetupOptions {
    pageSize: number;
    sort?: Sort[];
}

export const pagingAndSort = (options: SetupOptions) => <P extends {}>(Component: React.ComponentClass<PagingAndSortComponentProps>) => class WithPagingAndSort extends React.Component<P, PagingAndSortState> {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    constructor(props: P) {
        super(props);

        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            paging: {
                limit: options.pageSize,
                offset: 0
            },
            sort: options.sort || [],
            pageSize: options.pageSize
        };

        this.onTableChange = this.onTableChange.bind(this);
    }

    public render() {
        return <Component {...this.props} {...this.state} onTableChange={this.onTableChange} />;
    }

    // tslint:disable-next-line:no-any
    private onTableChange(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) {
        let sort: Sort[] | null = null;

        if (sorter.columnKey) {
            sort = [{
                field: String(sorter.columnKey),
                order: sorter.order === "ascend" ? SortOrder.ASC : SortOrder.DESC
            }];
        }

        const pageSize = pagination.pageSize || 10;

        this.setState({
            paging: {
                limit: pageSize,
                offset: ((pagination.current || 1) - 1) * pageSize
            },
            sort,
            pageSize
        });
    }
};

export interface SortAndPaging {
    paging: {
        skip: number;
        limit: number;
    };
    sort?: Sort | undefined;
    pageSize?: number;
    filter?: any;
}

export type FilterSortPagingQuery = {
    pageSize: number;
    skip?: number;
    textSearch?: string;
    sortBy?: string;
    sort?: string;
    tags?: string[];
    locales?: string[];
    roles?: string[];
};

export const defaultSortAndPagingSettings: SortAndPaging = {
    pageSize: 10,
    paging: {
        skip: 0,
        limit: 10
    }
};

export const defaultTablePaginationSettings: PaginationConfig = {
    showQuickJumper: true,
    showSizeChanger: true
};

export const getCurrentTablePage = (paging?: GraphQLPaging) => {
    return paging?.skip && paging?.limit ? paging.skip / paging.limit + 1 : 1;
};

export const getPageSize = (paging?: GraphQLPaging) => {
    return paging?.limit || undefined;
};

export const onTableChange = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter?: SorterResult<any>): SortAndPaging => {
    let sort: Sort | undefined;

    if (sorter && sorter.columnKey && (sorter.order === "ascend" || sorter.order === "descend")) {
        sort = {
            field: String(sorter.columnKey),
            order: sorter.order === "ascend" ? SortOrder.ASC : SortOrder.DESC
        };
    }

    const pageSize = pagination.pageSize || 10;

    return {
        paging: {
            limit: pageSize,
            skip: ((pagination.current || 1) - 1) * pageSize
        },
        sort,
        pageSize,
        filter: filters
    };
};

export const getUpdateUrlQueryParams = (inputParams: SortAndPaging) => {
    const { paging: { limit, skip }, sort } = inputParams;

    return {
        pageSize: limit,
        skip,
        sort: sort && sort.order,
        sortBy: sort && sort.field
    };
};
