import { EventParticipant, useGetEventParticipantsLazyQuery } from "@graphql2/types";
import { toLanguageObject } from "@utils/toLanguageObject";
import moment from "moment";
import * as React from "react";
import { ExportToCsvButton } from "./exportToCsvButton";

interface ExportEventParticipantsToCsvButtonProps {
    eventId: string;
    formDataKeys: string[];
}

const ExportEventParticipantsToCsvButton: React.FC<ExportEventParticipantsToCsvButtonProps> = (props) => {
    const { eventId, formDataKeys } = props;

    const [getParticipants, { loading, data }] = useGetEventParticipantsLazyQuery({
        variables: {
            filter: {
                eventId
            },
            sort: {
                field: "id",
                order: "ASC"
            }
        }
    });

    // eslint-disable-next-line complexity
    const mapEntryToCsvRow = (participant: EventParticipant) => {
        let userId = participant?.user?.id;
        if (!userId) {
            userId = "";
        } else {
            userId = userId.length !== 24 ? "deleted_user" : userId;
        }

        const {
            givenName,
            familyName,
            email,
            locale,
            locked,
            avatar,
            lastLogin,
            registeredOn
        } = participant?.user ?? {};

        const {
            birthDate,
            sex,
            nationality,
            location
        } = participant?.user?.profile;

        const {
            onWaitingList,
            participatedOn,
            approved,
            registrationComplete,
            formData
        } = participant;

        const companyName = participant?.user?.company?.name;

        const eventTitles = toLanguageObject(participant?.event?.title);
        const eventTitle = eventTitles.en ?? eventTitles.nl;
        const subEventTitles = toLanguageObject(participant?.subEvent?.title);
        const subEventTitle = subEventTitles.en ?? subEventTitles.nl;
        const subEventInfo = participant?.subEvent?.info;

        const formDataValues = formData ? Object.values(formData).map((value => JSON.stringify(value))) : [];

        return [
            userId,
            email ?? "",
            givenName ?? "",
            familyName ?? "",
            birthDate ? moment(birthDate).toISOString() : "",
            sex ?? "",
            nationality ?? "",
            location ?? "",
            locale ?? "",
            locked ?? "",
            avatar ? `"${avatar}"` : "",
            lastLogin ? moment(lastLogin).toISOString() : "",
            registeredOn ? moment(registeredOn).toISOString() : "",
            onWaitingList ?? "",
            participatedOn ? moment(participatedOn).toISOString() : "",
            approved ?? "",
            registrationComplete ?? "",
            companyName ?? "",
            eventTitle ?? "",
            subEventTitle ?? "",
            subEventInfo ?? "",
            ...formDataValues
        ].join(";");
    };

    return (
        <ExportToCsvButton
            columnNames={[
                "id",
                "email",
                "name",
                "last name",
                "birthDate",
                "sex",
                "nationality",
                "location",
                "locale",
                "locked",
                "avatar",
                "last login",
                "registered on",
                "on waiting list",
                "participated on",
                "approved",
                "registration complete",
                "company name",
                "event title",
                "subevent title",
                "subevent info",
                ...formDataKeys
            ]}
            dataToDownload={data?.eventParticipants}
            downloadedElementId={eventId}
            exportFileName="event_participants"
            lazyDataQuery={getParticipants}
            loading={loading}
            mapEntryToCsvRow={mapEntryToCsvRow}
        />
    );
};

export default ExportEventParticipantsToCsvButton;
