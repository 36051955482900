import styled from "styled-components";
import { LinkWrap } from "@components/linkWrap";

export const AddAmbassadorsPageStyle = styled.div`

`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const BackLink = styled(LinkWrap)`
    padding-right: 10px;
`;
