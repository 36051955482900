import { Content } from "@pages/addJourneysPage/content/editJourneyContentCard";
import { languages } from "@pages/translationsPage/constants/languages";
import uuidv4 from "uuid/v4";

let counter = 0;

export interface ContentFactoryProps {

}

export const contentFactory = (): Content => {
    counter += 1;

    const title = languages.reduce(
        (prev, lang) => ({
            ...prev,
            [lang.code]: `Title ${counter}`
        }),
        {}
    );

    return {
        id: uuidv4(),
        description: {},
        imageCaption: "",
        imageUrl: "",
        title,
        type: "tab",
        url: ""
    };
};
