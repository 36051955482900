import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { AddContentField, FormValueKey, FormValueOptions, Tree } from "@components/formContentFields/formElementStyle";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { formOptionFactory } from "@pages/addChallengesPage/factories";

export class FormFieldSelect extends FormFieldBase {
    public renderBody() {
        const { prefix, index, property, form, deleteFormElement } = this.props;

        return (
            <Tree>
                <FormValueKey>
                    <FormItemFlex>
                        <Field
                            name={[index, "property"]}
                            initialValue={property}
                            {...this.sharedProps}
                            label={<FormattedMessage id="selectValueKey" />}
                        />
                        <DeleteFormField
                            as={DeleteOutlined}
                            onClick={deleteFormElement}
                        />
                    </FormItemFlex>
                    <Form.List name={[index, "options"]}>
                        {(fields, { add, remove, move }) => {
                            const renderFields = fields.map((option, i) => {
                                return (
                                    <FormItemFlex>
                                        <Field
                                            name={[i, "value"]}
                                            key={i}
                                            {...this.sharedProps}
                                            label={<FormattedMessage id="valueKey" />}
                                        />
                                        <DeleteFormField
                                            as={DeleteOutlined}
                                            onClick={() => remove(i)}
                                        />
                                    </FormItemFlex>
                                );
                            });

                            return (
                                <FormValueOptions>
                                    {renderFields}
                                    <AddContentField onClick={() => add(formOptionFactory(fields.length))}>
                                        <FormattedMessage id="addSelectValue" />
                                    </AddContentField>
                                </FormValueOptions>
                            );
                        }}
                    </Form.List>
                </FormValueKey>
            </Tree>
        );
    }
}
