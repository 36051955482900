import { Button } from "antd";
import * as React from "react";

export interface TimelineAddElementProps {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    className?: string;
    handleClick?: () => void;
    icon?: React.ReactNode;
    height?: string;
}

export const TimelineAddElement: React.FC<TimelineAddElementProps> = (props) => {
    const { title, subTitle, className, handleClick, icon, height } = props;

    return (
        <Button
            style={{
                height: height || "default"
            }}
            type="primary"
            icon={icon}
            className={className}
            onClick={handleClick}
        >
            <span>
                {title && <strong> {title} </strong>}
                {title ? <br /> : ""}
                {subTitle}
            </span>
        </Button>
    );
};
