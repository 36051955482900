import * as React from "react";

import { compose } from "redux";

import { ChangeVisibleTwitterFeedProps, GetTwitterFeedCountProps, GetTwitterFeedProps, Paging, Sort, TwitterFeedFilter, withChangeVisibleTwitterFeed, withGetTwitterFeed, withGetTwitterFeedCount } from "@graphql2/types";
import { FilterQueryStateProps, filterQueryState } from "@utils/withFilterQueryState";
import { FilterSortPagingQuery, defaultSortAndPagingSettings } from "@utils/withPagingAndSort";
import { TwitterCard } from "./twitterCard";

export interface TwitterCardContainerProps {
    hidden: boolean;
}

export interface LocalState {
    filter?: TwitterFeedFilter;
    paging?: Paging;
    sort?: Sort;
}

const withFilterQueryState = filterQueryState<FilterSortPagingQuery>({
    pageSize: Number(defaultSortAndPagingSettings.pageSize),
    skip: 0,
    sortBy: "date",
    sort: "DESC",
    textSearch: ""
});

const withTwitterFeed = withGetTwitterFeed({
    name: "twitterFeed",
    options(ownProps: TwitterCardProps) {
        return {
            variables: {
                filter: ownProps.filterQuery.textSearch ? { search: ownProps.filterQuery.textSearch } : null,
                paging: {
                    skip: ownProps.filterQuery.skip || 0,
                    limit: ownProps.filterQuery.pageSize || 10
                },
                sort: {
                    field: ownProps.filterQuery.sortBy,
                    order: ownProps.filterQuery.sort || "DESC"
                }
            }
        };
    }
});

const withHideTwitterFeed = withChangeVisibleTwitterFeed({ name: "changeVisibleTwitterFeed" });
const withTwitterFeedCount = withGetTwitterFeedCount({
    name: "count",
    options(ownProps: TwitterCardProps) {
        return {
            variables: {
                filter: ownProps.filterQuery.textSearch ? { search: ownProps.filterQuery.textSearch } : null
            }
        };
    }
});

export const TwitterCardContainer = compose<React.ComponentClass<TwitterCardContainerProps>>(
    withFilterQueryState,
    withTwitterFeedCount,
    withTwitterFeed,
    withHideTwitterFeed
)(TwitterCard);

export type TwitterCardProps =
    TwitterCardContainerProps
    & FilterQueryStateProps<FilterSortPagingQuery>
    & GetTwitterFeedProps<{}, "twitterFeed">
    & GetTwitterFeedCountProps<{}, "count">
    & ChangeVisibleTwitterFeedProps<{}, "changeVisibleTwitterFeed">;
