import { DashboardConfigWidgetContent, DashboardConfigWidgetContentInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetContentFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetContent, {
    title: LanguageObject;
    mode: string;
}>>;

export const mapContentStateWidgetToContentWidget = ({ mode, ...widget }: DashboardConfigWidgetContentFormValues): DashboardConfigWidgetContentInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title)
    };
};

export const mapContentWidgetToContentStateWidget = (contentWidget: DashboardConfigWidgetContent): DashboardConfigWidgetContentFormValues => {
    return {
        ...contentWidget,
        mode: (contentWidget.contentIds && contentWidget.contentIds.length > 0)
            ? "fixed"
            : "dynamic",
        type: contentWidget.type as ViewType,
        title: toLanguageObject(contentWidget.title)
    };
};
