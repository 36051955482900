import * as React from "react";
import { autobind } from "core-decorators";
import { FormInstance } from "antd/lib/form/Form";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Select } from "antd";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { FormattedMessage } from "react-intl";
import { Flex } from "@components/flex/flex";
import { getText } from "@utils/getText";
import { SelectField } from "@components/field/selectField";
import { GetContentsQuery } from "../../../graphql2/types";

export interface ChallengeTeamSettingsFormValues {
    contentId?: string;
}

export interface ChallengeTeamSettingsCardProps {
    form: FormInstance;
    defaultValues: Partial<ChallengeTeamSettingsFormValues>;
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    loadingContent?: boolean;
    content?: GetContentsQuery["contents"];
    changeSelectedLanguage(languageCode: string): void;
    onSearch(search: string): void;
    onBlur(): void;
}

@autobind
export class ChallengeTeamSettingsCard extends React.Component<ChallengeTeamSettingsCardProps> {
    public render() {
        const { hidden, disabled, onSearch, loadingContent, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="challengeTeam.challengeTeamSettings" />}
                />
                <Flex direction="column">
                    <h3><FormattedMessage id="challengeTeam.challengeTeamSettings" /></h3>
                    <Flex>
                        <SelectField
                            {...sharedProps}
                            label={<FormattedMessage id="challengeTeam.contentId" />}
                            name={["contentId"]}
                            showSearch
                            notFoundContent={null}
                            onSearch={onSearch}
                            filterOption={false}
                            loading={loadingContent}
                            allowClear
                        >
                            {this.renderContentOptions()}
                        </SelectField>
                    </Flex>
                </Flex>
            </Card>
        );
    }

    private renderContentOptions() {
        const { content, loadingContent } = this.props;
        if (content && content.length && !loadingContent) {
            return content.map(c => <Select.Option value={c.id}>{c.title && getText(c.title)}</Select.Option>);
        }
    }
}
