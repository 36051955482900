import * as React from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { autobind } from "core-decorators";

import { debounce } from "@utils/debounce";
import { languages } from "@pages/translationsPage/constants/languages";
import { Application, UserApplicationFilter } from "@graphql2/types";
import { createApplicationsFilter } from "@pages/usersPage/createApplicationsFilter";
import { TableFilterStyle } from "./tableStyle";
import { SelectField } from "../field/selectField";

type FilterOnType<D> =
    boolean | { defaultValue: D };

interface Tag {
    id: string;
    title: string;
}

export interface TableFilterProps {
    filterOn: {
        title?: FilterOnType<TableFilter["title"]>;
        activityType?: FilterOnType<TableFilter["activityType"]>;
        publishedFrom?: FilterOnType<TableFilter["publishedFrom"]>;
        contentTypeSource?: string[] | { source: string[]; defaultValue: TableFilter["contentType"] };
        tags?: Tag[] | { source: Tag[]; defaultValue: TableFilter["tags"] };
        locales?: FilterOnType<TableFilter["locales"]>;
        userRoles?: FilterOnType<TableFilter["userRoles"]>
    };
    applications?: Application[];
    updateFilter?(filter: TableFilter): void;
}

export interface TableFilter {
    title?: string;
    publishedFrom?: number;
    activityType?: string;
    contentType?: string;
    tags?: string[];
    locales?: string[];
    userRoles?: string[];
}

export interface TableFilterState {

}

@autobind
class TableFilterComponent extends React.Component<TableFilterProps, TableFilterState> {
    private formRef = React.createRef<FormInstance>();

    private debouncedUpdateForm: () => void;

    private activities: string[] = [
        "none",
        "walking",
        "running",
        "cycling",
        "swimming",
        "workout"
    ];

    public constructor(props: TableFilterProps) {
        super(props);

        this.debouncedUpdateForm = debounce(this.updateForm, 500);
    }

    // tslint:disable-next-line: max-func-body-length
    public render() {
        const { filterOn, applications } = this.props;

        return (
            <TableFilterStyle>
                <Form ref={this.formRef} layout="inline">
                    {filterOn && (
                        <React.Fragment>
                            {filterOn.title && (
                                <Form.Item
                                    initialValue={filterOn.title === true ? undefined : filterOn.title.defaultValue}
                                    name="searchTitle"
                                >
                                    <Input.Search
                                        placeholder="Search by title"
                                        onChange={this.debouncedUpdateForm}
                                    />
                                </Form.Item>
                            )}
                            {filterOn.publishedFrom && (
                                <Form.Item name="datepickerPublished">
                                    <DatePicker
                                        placeholder="Published from"
                                        onChange={this.debouncedUpdateForm}
                                    />
                                </Form.Item>
                            )}
                            {filterOn.activityType && (
                                <Form.Item name="selectActivity" style={{ marginRight: 0 }}>
                                    <SelectField
                                        showSearch
                                        style={{ width: "200px" }}
                                        placeholder="Activity types"
                                        onChange={this.debouncedUpdateForm}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option && option.props.children ? (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)}
                                    >
                                        {this.activities.map((activity, key) => {
                                            return (
                                                <Select.Option
                                                    key={`option_${activity}_${key}`}
                                                    value={activity}
                                                >{activity}
                                                </Select.Option>
                                            );
                                        })}
                                    </SelectField>
                                </Form.Item>
                            )}
                            {filterOn.contentTypeSource && (
                                <Form.Item
                                    name="selectContentType"
                                    initialValue={(Array.isArray(filterOn.contentTypeSource)
                                        ? null
                                        : filterOn.contentTypeSource.defaultValue)}
                                    style={{ marginRight: 0 }}
                                >
                                    <SelectField
                                        style={{ width: "200px" }}
                                        placeholder="Content types"
                                        onChange={this.debouncedUpdateForm}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option && option.props.children ? (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)}
                                    >
                                        <Select.Option value="none">All</Select.Option>
                                        {
                                            (Array.isArray(filterOn.contentTypeSource)
                                                ? filterOn.contentTypeSource
                                                : filterOn.contentTypeSource.source)
                                                .map((contentType, key) => {
                                                    return (
                                                        <Select.Option
                                                            key={`option_${contentType}_${key}`}
                                                            value={contentType}
                                                        >{contentType}
                                                        </Select.Option>
                                                    );
                                                })
                                        }
                                    </SelectField>
                                </Form.Item>
                            )}
                            {filterOn.locales && (
                                <Form.Item
                                    name="locales"
                                    initialValue={filterOn.locales === true ? undefined : filterOn.locales.defaultValue}
                                    style={{ marginRight: 0 }}
                                >
                                    <SelectField
                                        allowClear
                                        style={{ width: "200px" }}
                                        placeholder="Locales"
                                        onChange={this.debouncedUpdateForm}
                                        mode="multiple"
                                        showArrow
                                    >
                                        {
                                            languages
                                                .map(({ code }, key) => {
                                                    return (
                                                        <Select.Option
                                                            key={`option_${code}_${key}`}
                                                            value={code}
                                                        >{code}
                                                        </Select.Option>
                                                    );
                                                })
                                        }
                                    </SelectField>
                                </Form.Item>
                            )}
                            {filterOn.userRoles && applications && (
                                <Form.Item
                                    name="userRoles"
                                    initialValue={filterOn.userRoles === true ? undefined : filterOn.userRoles.defaultValue}
                                    style={{ marginRight: 0 }}
                                >
                                    <SelectField
                                        style={{ width: "200px" }}
                                        placeholder="Roles"
                                        onChange={this.debouncedUpdateForm}
                                        mode="multiple"
                                        showArrow
                                    >
                                        {
                                            // TODO: NRGLB-4658 translations
                                            applications.map((application) => {
                                                return (
                                                    <Select.OptGroup label={application.name} key={application.id}>
                                                        <Select.Option value={`${application.id}_none`}>
                                                            No roles for {application.name}
                                                        </Select.Option>
                                                        {application.roles.map(role => (
                                                            <Select.Option
                                                                key={`option_${application.id}_${role.id}`}
                                                                value={`${application.id}_${role.id}`}
                                                            >
                                                                {role.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                );
                                            })
                                        }
                                    </SelectField>
                                </Form.Item>
                            )}
                        </React.Fragment>
                    )}
                </Form>
            </TableFilterStyle>
        );
    }

    private updateForm() {
        const {
            filterOn,
            updateFilter
        } = this.props;

        const filter: TableFilter = {};

        const formInstance = this.formRef.current;
        if (filterOn && formInstance) {
            filter.title = formInstance.getFieldValue("searchTitle");

            if (formInstance.getFieldValue("selectActivity") !== "none") {
                filter.activityType = formInstance.getFieldValue("selectActivity");
            }

            if (formInstance.getFieldValue("selectContentType") !== "none") {
                filter.contentType = formInstance.getFieldValue("selectContentType");
            }

            if (formInstance.getFieldValue("datepickerPublished")) {
                filter.publishedFrom = new Date(formInstance.getFieldValue("datepickerPublished")).getTime();
            }
            if (formInstance.getFieldValue("locales") !== "none") {
                filter.locales = formInstance.getFieldValue("locales");
            }
            const userRoles = formInstance.getFieldValue("userRoles");
            if (userRoles) {
                filter.userRoles = userRoles;
            }
        }

        if (updateFilter) {
            updateFilter(filter);
        }
    }
}

export const TableFilters = TableFilterComponent;
