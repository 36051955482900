import { Flex } from "@components/flex/flex";
import { DashboardConfigWidgetCarousselItem } from "@utils/mappers/widgets/carousselWidgetMapper";
import { Menu, Tabs, Button, Dropdown, Badge } from "antd";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { FormListFieldData } from "antd/lib/form/FormList";
import { FormInstance } from "antd/lib/form";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import { CarousselWidgetPane } from "@components/dashboard/widgets/carousselWidget/carousselWidgetPane";
import { widgetItemFactory } from "@pages/addDashboardPage/factories/widgetItemFactory";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";

export interface CarousselWidgetPaneProps {
    errors: ErrorDictionary;
    widgetId: string;
    fields: FormListFieldData[];
    form: FormInstance
    add(widget): void;
    remove(index): void;
}

interface CarousselWidgetMenuProps {
    add: (widget) => void,
    setVisible: () => void;
}

const CarousselWidgetMenu: React.FC<CarousselWidgetMenuProps> = (props) => {
    const { add, setVisible } = props;

    return (
        <Menu
            onClick={e => {
                add(widgetItemFactory({
                    widgetType: "caroussel",
                    type: e.key as string
                }));
                setVisible();
            }}
        >
            <Menu.Item key="caroussel"><FormattedMessage id="carousselWidget.genericCarousselItem" /></Menu.Item>
            <Menu.Item key="content"><FormattedMessage id="carousselWidget.contentCarousselItem" /></Menu.Item>
            <Menu.Item key="challenge"><FormattedMessage id="carousselWidget.challengeCarousselItem" /></Menu.Item>
            <Menu.Item key="event"><FormattedMessage id="carousselWidget.eventCarousselItem" /></Menu.Item>
        </Menu>
    );
};

export const CarousselWidgetPanes: React.FC<CarousselWidgetPaneProps> = (props) => {
    const { widgetId, form, fields, add, remove, errors } = props;
    const [activeKey, setActiveKey] = React.useState("0");
    const [visibleMenu, setVisibleMenu] = React.useState(false);

    const handleVisible = () => {
        setVisibleMenu(prevVisible => !prevVisible);
    };

    const handleEditTabs = (targetKey, action) => {
        if (action === "remove") {
            remove(Number(targetKey));
            if (activeKey === targetKey || (Number(activeKey) > fields.length - 2)) {
                setActiveKey("0");
            }
        }
    };

    return (
        <Flex direction="column" className="tabscover" justifyContent="flex-start" alignItems="flex-start">
            <Dropdown
                className="dropDownCarousselPicker"
                onVisibleChange={handleVisible}
                visible={visibleMenu}
                overlay={<CarousselWidgetMenu add={add} setVisible={handleVisible} />}
            >
                <Button>
                    <PlusOutlined />
                    <FormattedMessage id="carousselWidget.add" />&nbsp;
                    <DownOutlined />
                </Button>
            </Dropdown>
            <Tabs
                onChange={setActiveKey}
                activeKey={activeKey}
                type="editable-card"
                hideAdd
                onEdit={handleEditTabs}
            >
                {fields.map((paneField) => {
                    const pane = form.getFieldValue(["widgets", widgetId, "items", paneField.name]) as DashboardConfigWidgetCarousselItem;
                    const hasError = Boolean(errors && errors.items && errors.items[paneField.name]);
                    const paneNumber = paneField.name + 1;
                    return (
                        <Tabs.TabPane
                            tab={(
                                <React.Fragment>
                                    <Badge dot={hasError}>Slide <FormattedNumber value={paneNumber} /></Badge>
                                </React.Fragment>
                            )}
                            key={`${paneField.name}`}
                            forceRender
                        >
                            <CarousselWidgetPane
                                widgetId={widgetId}
                                field={paneField}
                                pane={pane}
                                form={form}
                            />
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Flex>
    );
};
