import styled from "styled-components";

export const FormContentFieldSet = styled.div`
    border: 1px solid #D5D5D5;
    border-radius: 2px;
    margin-bottom: 20px;

    .ant-checkbox-wrapper {
        margin: 5px 0;
    }
`;

export const FormContentFieldHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    padding: 0 1rem;
    min-height: 40px;

    span {
        font-size: .875rem;
        font-family: "PingFang SC", sans-serif;
        font-weight: 600;
        margin-right: auto;
    }
`;

export const OrderFieldButtonsStyle = styled.div`
    display: flex;
    flex-flow: column;
    font-size: 12px;
    margin-right: 16px;

    .anticon {
        cursor: pointer;
    }
`;

export const FormContentFieldBody = styled.div`
    background-color: #fff;
    padding: 1rem;

    .ant-row.ant-form-item {
        margin-bottom: 1rem;
    }
`;

export const AddContentField = styled.span`
    display: block;
    font-family: "PingFang SC", sans-serif;
    font-size: 12px;
    color: rgba(40,40,40, .5);
    font-weight: 400;
    cursor: pointer;

    &:hover {
        color: #A0C63D;
    }
`;

export const Tree = styled.div`
    display: block;
    height: auto;
`;

export const FormValueKey = styled.div`
    overflow-y: hidden;
    position: relative;

    :before {
        content: "";
        width: 1px;
        border-left: 1px solid #d9d9d9;
        height: calc(100% - 93px);
        position: absolute;
        left: 0;
        top: 40px;
        margin: 22px 0;
    }
`;

export const FormValueOptions = styled.div`
    display: flex;
    flex-flow: column;
    padding-left: 20px;
    position: relative;

    .ant-row.ant-form-item {
        :before {
            content: " ";
            width: 10px;
            background: #d9d9d9;
            height: 1px;
            position: absolute;
            left: -20px;
            top: 70%;
            margin: 0 21px 0 0;
        }
    }

`;

export const OptionKey = styled.div`
    padding-left: 20px;
    position: relative;

    &:before {
        content: " ";
        width: 10px;
        background: #d9d9d9;
        height: 1px;
        position: absolute;
        left: 0;
        top: 70%;
        margin: 0 21px 0 0;
    }
`;
