import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { ErrorObj } from "@pages/addChallengesPage/addChallengesPage";
import { Label } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { MilestoneFormValues } from "@pages/addChallengesPage/tabs/milestones/challengeMilestonesCard/challengeMilstonesCard";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { MilestoneLanguageFieldProps } from "@utils/languageFieldProps";
import { Button, Card, Checkbox, Col, Form, Slider, message } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useEnabledLang } from "../../../../../context/enabledLangContext/context";
import { ChallengeConditionFormValuesUnion } from "../../conditions/challengeConditionsCard/challengeConditionsCard";
import { ConditionSelection, HiddenMilestoneInputs, tipFormatter } from "../milestoneUtil";
import { ChallengeVisualisationProgress } from "./editChallengeMilestoneStyle";

export interface EditChallengeMilestoneProps {
    milestone: MilestoneFormValues;
    index: number;
    disabled?: boolean;
    form: FormInstance;
    hidden?: boolean;
    hasError?: ErrorObj;
    activeLanguage: string;
    languageErrors: LanguageErrors;
    conditions?: ChallengeConditionFormValuesUnion[];
    back(): void;
    onBlur(): void;
    changeSelectedLanguage(languageCode: string): void;
}

const milestoneTitleField = (props: MilestoneLanguageFieldProps) => (
    <Field
        key={`${props.index}${props.language}`}
        hidden={props.hidden}
        id={`milestones.${props.index}.title.${props.language}`}
        label={<FormattedMessage id="title" />}
        name={["milestones", (props.index || 0), "title", props.language]}
        rules={[{
            required: true,
            message: <FormattedMessage id="form.isrequired" />
        }]}
        {...props.sharedProps}
    />
);

const milestoneDescriptionField = (props: MilestoneLanguageFieldProps) => (
    <Field
        key={`${props.index}${props.language}`}
        hidden={props.hidden}
        id={`milestones.${props.index}.description.${props.language}`}
        label={<FormattedMessage id="description" />}
        name={["milestones", (props.index || 0), "description", props.language]}
        rules={[{
            required: true,
            message: <FormattedMessage id="form.isrequired" />
        }]}
        {...props.sharedProps}
    />
);

export const EditChallengeMilestone: React.FC<EditChallengeMilestoneProps> = (props) => {
    const {
        index,
        disabled,
        milestone,
        conditions,
        form,
        hidden,
        activeLanguage,
        languageErrors,
        onBlur,
        changeSelectedLanguage,
        back } = props;
    const history = useHistory();
    const intl = useIntl();
    const milestonesUrl = `${history.location.pathname.split("/milestones")[0]}/milestones`;
    const { enabledLanguages } = useEnabledLang();
    const [notificationSettingsVisible, setNotificationSettingsVisible] = React.useState(false);

    React.useEffect(() => {
        if (form) {
            setNotificationSettingsVisible(form.getFieldValue(["milestones", index, "notificationsEnabled"]));
        }
    }, [form]);

    const sharedProps = {
        disabled,
        onBlur
    };

    const languageFieldProps: MilestoneLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                form,
                index,
                defaultValues: milestone,
                sharedProps,
                language: language.code,
                hidden: language.code !== activeLanguage
            }
        )), [enabledLanguages, activeLanguage]
    );

    const renderProgressSelector = () => {
        return (
            <React.Fragment>
                <Label>
                    <FormattedMessage id="progress" />
                </Label>
                <ChallengeVisualisationProgress width="250px">
                    <Form.Item
                        name={["milestones", index, "percentage"]}
                    >
                        <Slider
                            marks={{
                                0: "0%",
                                100: "100%"
                            }}
                            tipFormatter={tipFormatter}
                            onAfterChange={sharedProps.onBlur}
                        />
                    </Form.Item>
                </ChallengeVisualisationProgress>
            </React.Fragment>
        );
    };

    const renderNotificationSettings = () => {
        if (!notificationSettingsVisible) {
            return null;
        }
        return (
            <Form.Item>
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />
                {languageFieldProps.map(milestoneTitleField)}
                {languageFieldProps.map(milestoneDescriptionField)}
                <Col>
                    <Label>
                        <FormattedMessage id="notificationImage" />
                    </Label>
                    <ImageUpload
                        form={form}
                        image={milestone.imageUrl}
                        name={["milestones", index, "imageUrl"]}
                        required={false}
                    />
                    <Field
                        hidden
                        id="imageUrl"
                        name={["milestones", index, "imageUrl"]}
                    />
                </Col>
            </Form.Item>
        );
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                rightFields={[
                    <Button
                        type="primary"
                        onClick={() => {
                            navigator.clipboard.writeText(milestone.id);
                            message.success(intl.formatMessage({ id: "valueCopied" }));
                        }}
                    >
                        <FormattedMessage id="copyId" />
                    </Button>,
                    <Button
                        key="back"
                        onClick={
                            () => {
                                history.push(milestonesUrl);
                                back();
                            }
                        }
                    >
                        <FormattedMessage id="back" />
                    </Button>
                ]}
                title={<FormattedMessage id="configMilestone" />}
            />

            <HiddenMilestoneInputs
                index={index}
                milestone={milestone}
            />

            <ConditionSelection
                conditions={conditions}
                form={form}
                index={index}
                milestone={milestone}
                sharedProps={sharedProps}
            />
            {renderProgressSelector()}
            <Form.Item
                name={["milestones", index, "mailEnabled"]}
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="mail.sendout" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={["milestones", index, "notificationsEnabled"]}
                valuePropName="checked"
            >
                <Checkbox
                    onChange={(e) => setNotificationSettingsVisible(e.target.checked)}
                    {...sharedProps}
                >
                    <FormattedMessage id="milestone.notifications.enable" />
                </Checkbox>
            </Form.Item>
            {renderNotificationSettings()}
        </Card>
    );
};
