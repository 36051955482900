import * as moment from "moment";
import { FormattedDate } from "react-intl";
import * as React from "react";

export class TimeService {
    public static GET_TIMEAGO(timestamp: number, allowDate?: boolean) {
        const now = new Date().getTime();
        let delta = Math.abs(now - timestamp) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        if (days > 3 && allowDate) {
            return <FormattedDate value={timestamp} />;
        }

        if (days > 0) {
            return `${days}d`;
        }

        if (hours > 0) {
            return `${hours}h`;
        }

        if (minutes > 0) {
            return `${minutes}m`;
        }

        return `${seconds}s`;
    }
}
