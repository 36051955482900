import { CheckCircleFilled, DeleteOutlined, EditOutlined, MinusCircleFilled } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { CreditTypeItem } from "@utils/mappers/creditsMapper";
import { Popconfirm, Table } from "antd";
import Column, { ColumnProps } from "antd/lib/table/Column";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface CreditsTableProps extends TableComponentProps {
    dataSource: CreditTypeItem[];
    handlers: TableHandlers & {
        removeCreditConfigType(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<CreditTypeItem>;
}

export const CreditsTable: React.FC<CreditsTableProps> = ({ handlers, ...tableProps }) => {
    const renderType = (text: string) => (
        <Link className="plainText" to={`/credits/edit/${text}`}>
            {text}
        </Link>
    );

    const renderEnabled = (enabled: boolean) => {
        return enabled
            ? <CheckCircleFilled className="green" />
            : <MinusCircleFilled />;
    };

    const renderHandlers = (row: CreditTypeItem): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <Link to={`/credits/edit/${row.id}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeCreditConfigType(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="type"
                title={<FormattedMessage id="type" />}
                dataIndex="type"
                width={200}
                render={renderType}
            />
            <Column
                key="version"
                title={<FormattedMessage id="version" />}
                dataIndex="version"
                width={200}
            />
            <Column
                key="enabled"
                title={<FormattedMessage id="enabled" />}
                dataIndex="enabled"
                width={120}
                render={renderEnabled}
            />
            <Column
                key="capping"
                title={<FormattedMessage id="capping" />}
                dataIndex="capping"
                width={120}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: CreditTypeItem) => renderHandlers(row)}
            />
        </Table>
    );
};
