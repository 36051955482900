/* eslint-disable max-lines-per-function */
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { GetContentsQuery } from "@graphql2/types";
import { ChallengeTeamPopup } from "@pages/challengeTeamPage/challengeTeamPopup";
import { createDefaultValues } from "@utils/createDefaultValues";
import { debounce } from "@utils/debounce";
import { emptyChallengeTeam } from "@utils/emptyItems/emptyChallengeTeam";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { challengeTeamToFormValues, formValuesToAddChallengeTeam, formValuesToUpdateChallengeTeam } from "@utils/mappers/challengeMapper";
import { Button, Form, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { useErrors } from "../../hooks/useErrors";
import { AddChallengeTeamPageProps } from "./addChallengesTeamPageContainer";
import { AddChallengesTeamPageStyle } from "./addChallengesTeamPageStyle";
import { ChallengeTeamSettingsCard } from "./general/challengeTeamSettingsCard";
import { DescriptionCard } from "./general/descriptionCard";

export type ErrorObj = object;

export type ChallengeTeamErrors = {
    title: ErrorObj;
    description: ErrorObj;
    contentId: ErrorObj;
    imageUrl: ErrorObj;
};

export const AddChallengesTeam: React.FC<AddChallengeTeamPageProps> = (props) => {
    const [form] = useForm();
    const intl = useIntl();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [isSaving, setIsSaving] = React.useState(false);
    const [fieldErrors] = useErrors();

    const { match, history, challengeTeams, content, filterQuery, contentById } = props;
    const [unsaved, setUnsaved] = React.useState<boolean>(false);

    const mergedContent = React.useMemo(() => {
        const merged: GetContentsQuery["contents"] = [];

        if (content && content.contents) {
            merged.push(...content.contents);
        }

        if (contentById && contentById.contents) {
            merged.push(...contentById.contents);
        }

        return merged;
    }, [content, contentById]);

    const challengeTeam = React.useMemo(
        () => {
            const team = challengeTeams?.challengeTeams?.find(t => t.id === match.params.teamId);
            return createDefaultValues(challengeTeamToFormValues, emptyChallengeTeam, undefined, challengeTeams?.loading, team);
        },
        [challengeTeams]
    );

    const teamMembers = React.useMemo(() => challengeTeams?.challengeTeams?.find(t => t.id === match.params.teamId)?.memberCount ?? 0, [challengeTeams]);

    const onBlur = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const contentSearch = (search: string) => {
        if (search) {
            filterQuery.updateUrlQuery({ textSearch: search, pageSize: 100 });
        }
    };

    const debouncedContentSearch = debounce(contentSearch, 500);

    const handleSuccess = (id: string) => {
        message.success(intl.formatMessage({ id }));
        history.push(`/challenges/${match.params.id}/teams`);
    };

    const handleError = (id: string) => {
        message.error(intl.formatMessage({ id }));
    };

    const submitForm = async (values) => {
        if (!form) {
            return;
        }

        setIsSaving(true);

        try {
            const transformValues = () => (match.params.teamId
                ? props.updateTeam({ variables: { ...formValuesToUpdateChallengeTeam({ ...values, id: match.params.teamId }) } })
                : props.addTeam({ variables: { ...formValuesToAddChallengeTeam({ ...values, challengeId: match.params.id }) } })
            );

            const response = await transformValues();

            if (response) {
                handleSuccess(match.params.teamId ? "challengeTeam.updated" : "challengeTeam.added");
            }
        } catch (err) {
            handleError(match.params.id ? "challengeTeam.updateFailed" : "challengeTeam.addedFailed");
        }

        setIsSaving(false);
    };

    const removeTeam = async () => {
        try {
            const response = await props.removeTeam({
                variables: {
                    id: match.params.teamId
                }
            });

            if (response.data && response.data.removeChallengeTeam.success) {
                handleSuccess("challengeTeam.removed");
            }
        } catch {
            handleError("challengeTeam.removedFailed");
        }
    };

    if (!challengeTeam) {
        return <Spin />;
    }

    const defaultProps = {
        form,
        defaultValues: challengeTeam,
        disabled: false,
        changeSelectedLanguage,
        onBlur
    };

    return (
        <AddChallengesTeamPageStyle>
            <Form
                hideRequiredMark
                form={form}
                initialValues={challengeTeam}
                onFinish={submitForm}
            >
                <React.Fragment>
                    <HeaderContent>
                        <Flex justifyContent="space-between">
                            <div>
                                {match.params.teamId
                                    ? (
                                        <FormattedMessage
                                            id="challengeTeam.editTeam"
                                            tagName="h1"
                                        />
                                    )
                                    : (
                                        <FormattedMessage
                                            id="challengeTeam.addTeam"
                                            tagName="h1"
                                        />
                                    )}
                            </div>
                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    key="button1"
                                    htmlType="submit"
                                    loading={isSaving}
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                                {
                                    match.params.teamId
                                    && (
                                        <ChallengeTeamPopup
                                            disableOkButton={Boolean(teamMembers)}
                                            onConfirm={removeTeam}
                                        >
                                            <Button
                                                className="headerButton"
                                                type="default"
                                            >
                                                <FormattedMessage id="contentTag.delete" />
                                            </Button>
                                        </ChallengeTeamPopup>
                                    )
                                }
                            </div>
                        </Flex>
                    </HeaderContent>

                    <DescriptionCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        languageErrors={errorsToLanguageErrors({
                            description: fieldErrors.description,
                            title: fieldErrors.title
                        })}
                    />
                    <CoverImageCard
                        {...defaultProps}
                        defaultImage={challengeTeam.imageUrl || ""}
                        formField="imageUrl"
                        titleId="challengeTeam.image"
                    />
                    <ChallengeTeamSettingsCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        content={mergedContent}
                        languageErrors={errorsToLanguageErrors({
                            contentId: fieldErrors.contentId
                        })}
                        loadingContent={content?.loading || contentById?.loading}
                        onSearch={debouncedContentSearch}
                    />
                </React.Fragment>
            </Form>
        </AddChallengesTeamPageStyle>
    );
};
