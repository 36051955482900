import { HeaderContent } from "@components/headerContent/headerContent";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { AddVouchersPageProps } from "@pages/addVoucherPage/addVoucherPageContainer";
import { AddVouchersPageStyle } from "@pages/addVoucherPage/addVoucherPageStyle";
import { SpinStyle } from "@pages/articlesPage/articlesPageStyle";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyVoucher } from "@utils/emptyItems/emptyVoucher";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { translateChallengeTitles } from "@utils/mappers/challengeMapper";
import {
    mapFormValuesToAddVoucher,
    mapFormValuesToUpdateVoucher,
    mapVoucherToFormValues
} from "@utils/mappers/voucherMapper";
import { InjectedIntl } from "@utils/override.types";
import { Breadcrumb, Button, Form, Popconfirm, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { useErrors } from "../../hooks/useErrors";
import { GeneralCard } from "./cards/generalCard";
import { SettingsCard } from "./cards/settingsCard";
import { VoucherAssignmentsCard } from "./cards/voucherAssignmentsCard";
import { VoucherEnabledOnCard } from "./cards/voucherEnabledOnCard";

type ErrorObj = object;

export type VoucherErrors = {
    title: ErrorObj;
};

export interface VoucherPageDefaultProps {
    disabled: boolean;
    intl: InjectedIntl;
    changeSelectedLanguage(languageCode: string): void;
}

export const AddVouchersPage: FC<AddVouchersPageProps> = (props) => {
    const { vouchers, applications, challengeTitlesById, challengeTitlesBySearch, removeVoucher, match, history, setLocalState } = props;

    const [form] = useForm();
    const intl = useIntl();
    const [selectedLanguage, setLanguage] = useCurrentLanguage();
    const [unsaved, setUnsaved] = useState(false);
    const [fieldErrors, setErrors] = useErrors();
    const [isSaving, setIsSaving] = useState(false);

    const voucher = React.useMemo(
        () => createDefaultValues(mapVoucherToFormValues, emptyVoucher, null, vouchers?.loading, vouchers?.vouchers?.[0]),
        [vouchers]
    );

    const translatedChallenges = React.useMemo(
        () => [
            ...translateChallengeTitles(challengeTitlesById?.challenges || []),
            ...translateChallengeTitles(challengeTitlesBySearch?.challenges || [])
        ].filter((c, i, a) => a.findIndex(challenge => (challenge.id === c.id)) === i),
        [challengeTitlesById, challengeTitlesBySearch]
    );

    if (vouchers?.loading) {
        return (
            <SpinStyle>
                <Spin />
            </SpinStyle>
        );
    }

    if (!vouchers?.vouchers?.length && match.params.id) {
        props.history.push("/404");
    }

    if (!voucher) {
        return null;
    }

    const voucherForm = form.getFieldsValue();

    const roles = getFrontendRoles(applications.applications || []);

    const submitForm = async (values) => {
        const errMsg = match.params.id ? "voucher.voucherUpdateFailed" : "voucher.voucherAddFailed";
        const updateMsg = match.params.id ? "voucher.voucherUpdated" : "voucher.voucherCreated";
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const url = (response) => ("/vouchers");

        setIsSaving(true);

        try {
            const transformValues = () => (match.params.id
                ? props.updateVoucher({ variables: mapFormValuesToUpdateVoucher(values, match.params.id) })
                : props.addVoucher({ variables: mapFormValuesToAddVoucher(values) })
            );

            const response = await transformValues();
            if (response) {
                handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                    history.push(url(response));
                });
            }
        } catch (errors) {
            setErrors(errors);
            handleError(<FormattedMessage id={errMsg} />);
            message.error(intl.formatMessage({ id: "error.checkHighlightedFields" }));
        }

        setIsSaving(false);
    };

    const onDelete = async () => {
        try {
            await removeVoucher({ variables: { id: props.match.params.id } });
            handleSuccess(<FormattedMessage id="voucher.voucherRemoved" />, () => {
                history.push("/vouchers");
            });
        } catch (error) {
            handleError(<FormattedMessage id="voucher.voucherRemovedFailed" />);
        }
    };

    return (
        <AddVouchersPageStyle>
            <Form
                hideRequiredMark
                form={form}
                initialValues={voucher}
                onFieldsChange={() => {
                    if (!unsaved) {
                        setUnsaved(true);
                    }
                }}
                onFinish={submitForm}
            >
                <HeaderContent>
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/vouchers">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {getText(voucherForm?.title || voucher.title, selectedLanguage)}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                            {unsaved && <UnsavedChanges key="warning" />}
                            <Button
                                key="button1"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            <Popconfirm
                                cancelText="No"
                                okText="Yes"
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={onDelete}
                            >
                                <Button
                                    className="headerButton"
                                    type="ghost"
                                >
                                    <FormattedMessage id="voucher.delete" />
                                </Button>
                            </Popconfirm>
                        </div>
                    </HeaderActions>
                </HeaderContent>
                <GeneralCard
                    activeLanguage={selectedLanguage}
                    changeSelectedLanguage={setLanguage}
                    languageErrors={errorsToLanguageErrors({
                        start: fieldErrors.title
                    })}
                />
                <SettingsCard form={form} />
                <VoucherAssignmentsCard
                    form={form}
                    roles={roles}
                />
                <VoucherEnabledOnCard
                    challenges={translatedChallenges}
                    challengesLoading={challengeTitlesBySearch?.loading || Boolean(challengeTitlesById?.loading)}
                    form={form}
                    setLocalState={setLocalState}
                />
            </Form>
        </AddVouchersPageStyle>
    );
};
