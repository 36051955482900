/* eslint-disable react/prop-types */
import * as React from "react";
import { Select } from "antd";
import { FormattedMessage } from "react-intl";

import { SelectField } from "@components/field/selectField";
import { ApplicationFragment } from "@graphql2/types";

interface RolesDropdownProps {
    widgetId: string;
    options: ApplicationFragment["roles"];
}

export const RolesDropdown: React.FC<RolesDropdownProps> = ({ options, widgetId }) => (options.length > 0 ? (
    <SelectField
        name={["widgets", widgetId, "roles"]}
        label={<FormattedMessage id="webshop.roles" />}
        id="roles"
        mode="multiple"
        filterOption={(input, option) => Object.values(option ?? []).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
    >
        {options.map((role) => <Select.Option value={role.id} key={role.id}>{role.name}</Select.Option>)}
    </SelectField>
) : null);
