import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getJourneyTags } from "@graphql2/graphql_op";
import { withRemoveJourneyTagMutation } from "@pages/tagsPage/journeyTagsPageContainer";
import { withGetJourneyTags, withAddJourneyTag, withUpdateJourneyTag } from "../../graphql2/types";

export const withJourneyTags = withGetJourneyTags({
    skip: (props: AddTagsRouteProps) => {
        return !props.match.params.id;
    }
});

const withAddJourneyTagMutation = withAddJourneyTag({
    name: "addTag",
    options: {
        update: cleanCache([getJourneyTags])
    }
});

const withUpdateJourneyTagMutation = withUpdateJourneyTag({
    name: "updateTag"
});

export const AddJourneyTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddJourneyTagMutation,
    withUpdateTag: withUpdateJourneyTagMutation,
    withTags: withJourneyTags,
    withRemoveTag: withRemoveJourneyTagMutation,
    callbackUrl: "/journeys/tags",
    path: ["data", "journeyTags"]
});
