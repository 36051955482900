import { PageNotFoundPage } from "@pages/pageNotFoundPage/pageNotFoundPage";
import { useHasRole } from "@utils/useHasRole";
import { useIsLoggedIn } from "@utils/useIsLoggedIn";
import * as environment from "environment";
import { debug } from "environment";
import * as React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

export interface PrivateRouteProps extends RouteProps {
    roles?: string[];
    superAdmin?: true|string;
    editMode?: boolean;
    experimental?: boolean;
    // tslint:disable-next-line
    component: React.ComponentType<RouteComponentProps<{ any; }>> | React.ComponentType<any>;
}

const checkComponent = (component): component is React.ComponentClass => component !== undefined;

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const { component: Component, render, roles, editMode, superAdmin, experimental, ...rest } = props;
    const loggedIn = useIsLoggedIn();
    const hasRole = useHasRole();

    return (
        <Route
            {...rest}
            render={routeProps => {
                if (environment.adfsUrl) {
                    const { adfsUrl, anonymousToken } = environment;
                    window.location.href = `${adfsUrl}?jwt=${anonymousToken}`;
                    return null;
                }

                if (!loggedIn) {
                    const { pathname } = routeProps.location;
                    const redirectUrl = pathname.length > 1 ? `?redirect=${pathname}` : "";
                    return (
                        <Redirect to={`/login${redirectUrl}`} />
                    );
                }
                if (experimental && !debug) {
                    console.warn("This route is experimental, redirecting to root.");
                    return (
                        <Redirect to="/" />
                    );
                }
                if (!roles || (roles && roles.some(role => hasRole(role)))) {
                    if (checkComponent(Component)) {
                        return (
                            <Component
                                {...routeProps}
                                editMode={editMode}
                                superAdmin={superAdmin === true ? true : hasRole(superAdmin)}
                            />
                        );
                    }
                    if (render) {
                        return render(routeProps);
                    }
                    return <PageNotFoundPage />;
                }
                return <Redirect to="/" />;
            }}
        />
    );
};
