import { DeleteOutlined } from "@ant-design/icons";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { Button, Card, Checkbox, Col, Form, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";
import Text from "antd/es/typography/Text";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface ProfileFieldsCardProps {
    rootFieldName: string;
    form: FormInstance;
}

const FIELD_TYPES = ["enum", "text", "number", "boolean"];
export const ProfileFieldsCard: React.FC<ProfileFieldsCardProps> = ({ form, rootFieldName }) => {
    const [selectedFieldType, setSelectedFieldType] = useState<string>(FIELD_TYPES[0]);
    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="challenge.profileFieldToComplete" />}
            />

            <Form.List name={rootFieldName}>
                {(fields, { add, remove }) => {
                    const formValues = form.getFieldValue(rootFieldName);
                    return (
                        <React.Fragment>
                            <Form
                                autoComplete="off"
                                initialValues={{ profileFieldToAdd: selectedFieldType }}
                                layout="inline"
                            >
                                <SelectField
                                    inline
                                    showArrow
                                    id="target"
                                    label={<FormattedMessage id="challenge.profileFieldTypeToAdd" />}
                                    name="profileFieldToAdd"
                                    onChange={e => setSelectedFieldType(e as string)}
                                >
                                    {
                                        FIELD_TYPES.map(enumItem => {
                                            return (
                                                <Select.Option
                                                    key={enumItem}
                                                    value={enumItem}
                                                >
                                                    <FormattedMessage
                                                        id={enumItem}
                                                    />
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </SelectField>
                                <Form.Item>
                                    <Button onClick={() => add({ type: selectedFieldType })}>
                                        <FormattedMessage id="add" />
                                    </Button>
                                </Form.Item>
                            </Form>
                            {fields.map((formItem, i) => (
                                <Row
                                    key={[rootFieldName, i, "type"].join()}
                                    gutter={12}
                                >
                                    <Col>
                                        <AlignElementWrapper>
                                            <Text><FormattedMessage id={formValues[formItem.name]?.type || "unknown"} /></Text>
                                        </AlignElementWrapper>
                                    </Col>
                                    <Col>
                                        <Field
                                            hidden
                                            label="type"
                                            name={[formItem.name, "type"]}
                                            rules={[requiredrule]}
                                        />
                                    </Col>
                                    <Col>
                                        <Field
                                            label={(
                                                <FormattedMessage
                                                    id="name"
                                                />
                                            )}
                                            name={[formItem.name, "name"]}
                                            rules={[requiredrule]}
                                        />
                                    </Col>
                                    <Col>
                                        <Field
                                            label={(
                                                <FormattedMessage
                                                    id="label"
                                                />
                                            )}
                                            name={[formItem.name, "label"]}
                                            rules={[requiredrule]}
                                        />
                                    </Col>
                                    {
                                        formValues[formItem.name].type === "enum" && (
                                            <Col>
                                                <SelectField
                                                    showArrow
                                                    label={(
                                                        <FormattedMessage
                                                            id="enum"
                                                        />
                                                    )}
                                                    mode="tags"
                                                    name={[formItem.name, "list"]}
                                                    rules={[requiredrule]}
                                                />
                                            </Col>
                                        )
                                    }
                                    <Col>
                                        <AlignElementWrapper>
                                            <Form.Item
                                                name={[formItem.name, "required"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    <FormattedMessage
                                                        id="required"
                                                    />
                                                </Checkbox>
                                            </Form.Item>
                                        </AlignElementWrapper>
                                    </Col>
                                    <Col>
                                        <AlignElementWrapper>
                                            <Button
                                                danger
                                                type="default"
                                                onClick={() => remove(i)}
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        </AlignElementWrapper>
                                    </Col>

                                </Row>
                            ))}
                        </React.Fragment>
                    );
                }}

            </Form.List>
        </Card>
    );
};

const AlignElementWrapper = styled.div`
  display: flex;
  height: 100%;

  > button, > div {
    margin-top: 20px;
  }

  > span {
    margin-top: 25px;
  }
`;

