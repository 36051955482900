import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { SelectField } from "@components/field/selectField";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import { Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

export class FormFieldUpload extends FormFieldBase {
    public renderBody() {
        const { intl, index, deleteFormElement } = this.props;

        return (
            <React.Fragment>
                <FormItemFlex>
                    <Field
                        name={[index, "property"]}
                        {...this.sharedProps}
                        label={<FormattedMessage id="valueKey" />}
                    />
                    <DeleteFormField
                        as={DeleteOutlined}
                        onClick={deleteFormElement}
                    />
                </FormItemFlex>
                <FormItemFlex>
                    <SelectField
                        style={{ minWidth: 190 }}
                        name={[index, "extensions"]}
                        {...this.sharedProps}
                        label={<FormattedMessage id="fileType" />}
                    >
                        <Select.Option
                            value="image"
                            key="1"
                        ><FormattedMessage id="image" />
                        </Select.Option>
                        <Select.Option
                            value="documents"
                            key="2"
                        ><FormattedMessage id="document" />
                        </Select.Option>
                    </SelectField>
                </FormItemFlex>
                <FormItemFlex>
                    <Field
                        name={[index, "maxFileSize"]}
                        placeholder={intl.formatMessage({ id: "maxFileSize" })}
                        {...this.sharedProps}
                        numberType
                        label={<FormattedMessage id="maxFileSizeInMB" />}
                    />
                </FormItemFlex>
            </React.Fragment>
        );
    }
}
