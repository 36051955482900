import { ContentHeader } from "@components/contentHeader/contentHeader";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { Card, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { ImageCropProps } from "@components/imageUpload/imageCrop/imageCrop";

import { requiredrule } from "@components/form/requiredRule";

export interface CoverImageCardProps {
    defaultImage: string;
    form: FormInstance;
    formField: string | (string | number)[];
    titleId: string;
    disabled?: boolean;
    hidden?: boolean;
    hasError?: ErrorObj;
    required?: boolean;
    showSaveButton?: boolean;
    cropSettings?: ImageCropProps;
    onBlur?(): void;
}

export interface CoverImageCardState {
    image?: string;
    loading?: boolean;
}

export const CoverImageCard: React.FC<CoverImageCardProps> = (props) => {
    const { defaultImage, hidden, form: formInstance, required, formField, titleId, cropSettings, onBlur, disabled } = props;
    return (
        <Card hidden={hidden}>
            <ContentHeader title={<FormattedMessage id={titleId} />} />
            <Form.Item name={formField} rules={required && !disabled ? [requiredrule] : []}>
                <ImageUpload
                    disabled={disabled}
                    cropSettings={cropSettings}
                    form={formInstance}
                    image={defaultImage}
                    name={formField}
                    onChange={onBlur}
                    required={Boolean(required)}
                />
            </Form.Item>
        </Card>
    );
};
