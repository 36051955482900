import { Flex } from "@components/flex/flex";
import styled from "styled-components";

export const UserInfoCardStyle = styled.div`
    .ant-avatar {
        margin-right: 15px !important;
    }
`;

export const UserInfoCardLabel = styled.span`
    color: #B7B7B7;
    width: 50%;
`;

export const UserInfoCardValue = styled.span`
    display: flex;
    overflow-wrap: anywhere;
`;

export const UserInfoCardRow = styled(Flex)`
    margin: 8px 0;

    &:nth-child(2) {
        margin-top: 30px;
    }

    &:nth-child(1) {
        ${UserInfoCardLabel} {
            font-size: 16px;
        }
    }
`;
