import { DashboardTable } from "@components/dashboard/dashboardOverviewTable";
import { TableContainer } from "@components/table/tableContainer";
import { DashboardsPageProps } from "@pages/dashboardsPage/dashboardsPageContainer";
import { DashboardsPageStyle } from "@pages/dashboardsPage/dashboardsPageStyle";
import { Button, Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { onTableChange, getCurrentTablePage, getPageSize, getUpdateUrlQueryParams } from "@utils/withPagingAndSort";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { HeaderContent } from "@components/headerContent/headerContent";
import { Flex } from "@components/flex/flex";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { mapDashboardsOverview } from "../../utils/mappers/dashboardMapper";

export const DashboardsPage: React.FC<DashboardsPageProps> = (props) => {
    const { history, paging, count, dashboardConfigurations } = props;

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const removeDashboard = async (id: string) => {
        await props.removeDashboardConfiguration({ variables: { id } });
    };

    return (
        <DashboardsPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage id="dashboards.overview" tagName="h1" />
                    <div>
                        <Button
                            onClick={() => history.push("/dashboards/add/desktop")}
                            className="headerButton"
                            type="primary"
                        >
                            <FormattedMessage id="dashboard.addDashboard" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count?.dashboardConfigurationCount
                    }}
                    onChange={updateTableSettings}
                    loading={dashboardConfigurations?.loading}
                    dataSource={mapDashboardsOverview(dashboardConfigurations?.dashboardConfigurations || [])}
                    TableComponent={DashboardTable}
                    handlers={{
                        removeDashboard
                    }}
                />
            </Card>
        </DashboardsPageStyle>

    );
};
