import { WidgetProps } from "@components/dashboard/widgets/models";
import { SocialWidget } from "@components/dashboard/widgets/socialWidget/socialWidget";
import * as React from "react";
import { compose } from "redux";
import { withGetSocialTags, GetSocialTagsProps } from "@graphql2/types";

export interface SocialWidgetContainerProps extends WidgetProps {

}

const withSocialTags = withGetSocialTags({
    name: "socialTags"
});

export const SocialWidgetContainer = compose<React.ComponentClass<SocialWidgetContainerProps>>(
    withSocialTags
)(SocialWidget);

export type SocialWidgetProps = SocialWidgetContainerProps
    & GetSocialTagsProps<{}, "socialTags">;
