import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { InjectedIntl } from "@utils/override.types";
import { Button, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { FormattedMessage } from "react-intl";

type AddNewKeyProps = {
    addNew(key: string): void;
    intl: InjectedIntl;
    disable: boolean;
    keys: string[];
    enumArray?: string[];
}

export const AddNewKey: React.FC<AddNewKeyProps> = (props) => {
    const { disable, intl, keys, enumArray, addNew } = props;
    const [nameForm] = useForm();
    return (
        <Form
            form={nameForm}
            name="key"
            onSubmitCapture={e => {
                e.preventDefault();
                const values = nameForm.getFieldsValue();
                const key = values?.key;
                const errors = Boolean(nameForm.getFieldError("key").length);
                if (!key || errors) {
                    return message.warning(intl.formatMessage({ id: "key.empty" }));
                }
                addNew(key);
                nameForm.resetFields();
            }}
        >
            <Flex
                direction="row"
                gap={10}
            >
                {enumArray
                    ? (
                        <SelectField
                            disabled={disable}
                            name="key"
                            placeholder="Key"
                            rules={[
                                {
                                    validator: (rules, value: string | undefined, callback) => {
                                        if (value !== value?.replace(" ", "")) {
                                            return callback(intl.formatMessage({ id: "key.gaps" }));
                                        }
                                        return callback();
                                    }
                                }
                            ]}
                        >
                            {enumArray.filter(v => !keys.includes(v)).map(e => {
                                const valueToDisplay = e?.toString() ?? "";
                                return (
                                    <Select.Option
                                        key={valueToDisplay}
                                        value={valueToDisplay}
                                    >
                                        {valueToDisplay}
                                    </Select.Option>
                                );
                            })}
                        </SelectField>
                    )
                    : (
                        <Field
                            disabled={disable}
                            name="key"
                            placeholder="Key"
                            rules={[
                                {
                                    validator: (rules, value: string | undefined, callback) => {
                                        if (value !== value?.replace(" ", "")) {
                                            return callback(intl.formatMessage({ id: "key.gaps" }));
                                        }
                                        return callback();
                                    }
                                }
                            ]}
                        />
                    )}
                <Form.Item dependencies={[["key"]]}>
                    {() => (
                        <Button
                            disabled={!nameForm.getFieldValue("key")}
                            htmlType="submit"
                            type="primary"
                        >
                            <FormattedMessage id="add" />
                        </Button>
                    )}
                </Form.Item>
            </Flex>
        </Form>
    );
};
