/* eslint-disable no-mixed-operators */
import * as React from "react";
import { Modal, List, Button, Radio, Form } from "antd";
import { FormattedMessage } from "react-intl";
import { WarningOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { ImportTranslationsModalProps } from "./importTranslationsModalContainer";
import { ImportTranslationActionsStyle, ExistingTranslationsStyle, ProceedStyle, UpdateOptionsStyle } from "./importTranslationsModalStyle";

// eslint-disable-next-line max-lines-per-function
export const ImportTranslationsModal: React.FC<ImportTranslationsModalProps> = (props) => {
    const { data, onAbort, onSubmit, uploading, keys, importedTranslations } = props;
    const { translations, loading } = data ?? {};
    const [overwriteExisting, setOverwriteExisting] = React.useState<boolean>(false);
    const [form] = useForm();

    const importHasDuplicates = new Set(keys).size !== keys.length;

    React.useEffect(() => {
        if (!loading && translations && !translations.length && !importHasDuplicates) {
            onSubmit(false, []);
        }
    }, [translations, loading, importHasDuplicates]);

    const renderExistingTranslations = () => {
        if (translations && translations.length) {
            return (
                <React.Fragment>
                    <ExistingTranslationsStyle>
                        <FormattedMessage tagName="p" id="bulkUploadTranslations.somethingWentWrong.description" />
                        <List
                            size="small"
                            bordered
                            dataSource={translations && translations.map(t => t.key)}
                            renderItem={(item: any) => <List.Item>{item}</List.Item>}
                        />
                    </ExistingTranslationsStyle>
                    <ProceedStyle><WarningOutlined /><FormattedMessage id="bulkUploadTranslations.somethingWentWrong.actions" /></ProceedStyle>
                    <UpdateOptionsStyle>
                        <Radio.Group value={overwriteExisting} onChange={value => setOverwriteExisting(value.target.value)}>
                            <Radio value={false}><FormattedMessage id="bulkUploadTranslations.radio.ignoreExisting" /></Radio>
                            <Radio value><FormattedMessage id="bulkUploadTranslations.radio.updateExisting" /></Radio>
                        </Radio.Group>
                    </UpdateOptionsStyle>
                </React.Fragment>
            );
        }
    };

    const renderDuplicateListItem = (item) => (
        <List.Item>
            <Form.Item>
                <p>{item.key}</p>
                <Form.Item
                    name={`${item.key.split(".").join("+")}`}
                    rules={[{
                        required: true,
                        message: <FormattedMessage id="bulkUploadTranslations.duplicate.sleectOne" />
                    }]}
                >
                    <Radio.Group>
                        {item.translations.map(t => (
                            <Radio value={t.ogIndex}>
                                {Object.keys(t.languages).map(l => <span>{l}: {t.languages[l]} </span>)}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
            </Form.Item>
        </List.Item>
    );

    const renderDuplicateCorrection = () => {
        if (importHasDuplicates) {
            const duplicateKeys = keys.reduce((acc: string[], v, i, arr) => (arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc), []);

            const dupesAndTranslations = duplicateKeys.map(k => {
                const possibleTranslations = importedTranslations.map((t, index) => {
                    const { key, ...languages } = t;

                    return {
                        key,
                        languages,
                        ogIndex: index
                    };
                }).filter(t => t.key === k);

                return {
                    key: k,
                    translations: possibleTranslations
                };
            }).filter(dk => {
                if (overwriteExisting) {
                    return true;
                }

                return translations && !translations.find(translation => translation.key === dk.key);
            });

            if (
                dupesAndTranslations && dupesAndTranslations.length
            ) {
                return (
                    <ExistingTranslationsStyle>
                        <FormattedMessage tagName="p" id="bulkUploadTranslations.duplicateImportKeys.description" />
                        <ProceedStyle>
                            <WarningOutlined />
                        </ProceedStyle>
                        <UpdateOptionsStyle>
                            <Form>
                                <List
                                    size="small"
                                    bordered
                                    dataSource={dupesAndTranslations}
                                    renderItem={renderDuplicateListItem}
                                />
                            </Form>
                        </UpdateOptionsStyle>
                    </ExistingTranslationsStyle>
                );
            }
        }
    };

    const validateAndSubmit = () => {
        const errors = form.getFieldsError();
        const values = form.getFieldsValue();
        let newValues;

        if (!errors.length || !translations) {
            return;
        }

        if (values) {
            newValues = {};
            Object.keys(values).forEach(k => {
                const key = k.split("+").join(".");
                newValues[key] = values[k];
            });
        }

        onSubmit(overwriteExisting, translations, newValues);
    };

    return (
        <Modal
            title={<FormattedMessage id="bulkUploadTranslations.somethingWentWrong.title" />}
            visible={Boolean(!loading && (translations && translations.length) || importHasDuplicates)}
            footer={null}
            closable={false}
        >
            {renderExistingTranslations()}
            {renderDuplicateCorrection()}
            <ImportTranslationActionsStyle>
                <Button disabled={uploading} onClick={() => onAbort()}><FormattedMessage id="bulkUploadTranslations.actions.abort" /></Button>
                <Button
                    loading={uploading}
                    className={overwriteExisting && "warning" || undefined}
                    icon={overwriteExisting && "warning" || undefined}
                    type="primary"
                    onClick={validateAndSubmit}
                >
                    {overwriteExisting
                        ? <FormattedMessage id="bulkUploadTranslations.actions.updateExisting" />
                        : <FormattedMessage id="bulkUploadTranslations.actions.ignoreExisting" />}
                </Button>
            </ImportTranslationActionsStyle>
        </Modal>
    );
};
