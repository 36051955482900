import Icon from "@ant-design/icons";
import styled from "styled-components";

interface TableIcon {
    disabled?: boolean;
}

// TODO: replace icon
export const TableIcon = styled(Icon) <TableIcon>`
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

export const TableStyle = styled.div`
    .ant-table-content {
        overflow: auto;
    }
`;

export const TableFilterStyle = styled.div`
    margin-bottom: 1rem;
`;
