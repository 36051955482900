import { LanguageObject } from "@utils/toLanguageObject";

export type ChallengeTeamFormValues = {
    id?: string;
    title: string;
    description?: LanguageObject;
    profileValue?: string;
    imageUrl?: string;
    contentId?: string;
};

export const emptyChallengeTeam: ChallengeTeamFormValues = {
    title: "",
    description: {}
};
