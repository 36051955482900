import { Select, Spin } from "antd";
import * as React from "react";
import { SelectField } from "@components/field/selectField";
import moment from "moment";
import { useGetContentTitlesLazyQuery } from "@graphql2/types";
import { getText } from "@utils/getText";

interface RelatedArticlesProps {
    name: string;
    mode?: "multiple";
    label: string | JSX.Element;
}

const DATE_START_OF_TODAY = moment().startOf("day").valueOf();

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({ name, mode, label }) => {
    const [fetchContents, { loading, data: contentTitles }] = useGetContentTitlesLazyQuery({
        variables: {
            filter: {
                expireAfter: DATE_START_OF_TODAY
            }
        }
    });

    return (
        <SelectField
            allowClear
            loading={loading}
            name={name}
            mode={mode}
            label={label}
            onFocus={() => {
                if (!loading && !contentTitles) {
                    fetchContents();
                }
            }}
        >
            {
                loading
                    ? (
                        <Select.Option value="">
                            <Spin />
                        </Select.Option>
                    )
                    : contentTitles?.contents.map(content => {
                        return (
                            <Select.Option value={content.id} key={content.id}>
                                {getText(content.title)}
                            </Select.Option>
                        );
                    })
            }
        </SelectField>
    );
};
