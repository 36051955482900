import { JSONData } from "@energylab/schematics";
import { CreditConfigInput, CreditConfigType, GetCreditConfigsQuery } from "@graphql2/types";
import { CreditVersion } from "@pages/addCreditTypePage/addCreditTypePage";
import { CreditConfigFormValues } from "@pages/creditsPage/creditsPage";
import { formatDate } from "@utils/dateUtils";
import { emptyCreditConfig } from "@utils/emptyItems/emptyCreditConfig";
import { FormInstance } from "antd/lib/form";
import moment from "moment";

export type CreditTypeItem = {
    id: string;
    type: string;
    version: string;
    enabled: boolean;
    capping?: number;
}

type CreditConfig = GetCreditConfigsQuery["creditConfigs"][0]|undefined
type CreditTypes = GetCreditConfigsQuery["creditConfigs"][0]["types"]
type Capping = GetCreditConfigsQuery["creditConfigs"][0]["capping"]
type CollectionPeriod = GetCreditConfigsQuery["creditConfigs"][0]["collectionPeriod"]
type SpentCreditsLimit = GetCreditConfigsQuery["creditConfigs"][0]["spentCreditsLimit"]

type TranslateCreditsConfigFn = (types: CreditTypes, capping: Capping) => CreditTypeItem[]

export const translateCreditsConfig: TranslateCreditsConfigFn = (types, capping) => {
    if (!types || !types.length) {
        return [];
    }
    const resultTypes: CreditTypeItem[] = types.map(({ type, version, enabled }) => ({ type, version, id: type, enabled: Boolean(enabled) }));
    if (!capping || !capping.length) {
        return resultTypes;
    }
    capping?.forEach(capItem => {
        capItem.types?.forEach(typeName => {
            const index = resultTypes.findIndex(resultType => {
                return resultType.type === typeName;
            });
            if (index > -1) {
                resultTypes[index].capping = capItem.capping || undefined;
            }
        });
    });

    return resultTypes;
};

export const creditConfigToFormValues = (creditConfig: CreditConfig): CreditConfigFormValues => {
    if (!creditConfig) {
        return emptyCreditConfig;
    }
    const { collectionPeriod, spentCreditsLimit } = creditConfig;

    return {
        enableCollectionPeriod: Boolean(collectionPeriod),
        from: moment(collectionPeriod?.from),
        to: moment(collectionPeriod?.to),
        enableSpentCreditsLimit: Boolean(spentCreditsLimit),
        spentCreditsLimitValue: spentCreditsLimit?.value,
        countForLastXDays: spentCreditsLimit?.countForLastXDays || undefined,
        countInCollectionPeriod: spentCreditsLimit?.countInCollectionPeriod || undefined
    };
};

type FormValuesToCreditConfigFn = (formValues: CreditConfigFormValues, types: CreditTypes, capping: Capping) => CreditConfigInput

export const formValuesToCreditConfig: FormValuesToCreditConfigFn = (formValues, types, capping) => {
    let result: CreditConfig = { types, capping };
    if (formValues.enableCollectionPeriod) {
        const collectionPeriod: CollectionPeriod = {
            from: formatDate(formValues.from),
            to: formatDate(formValues.to)
        };
        result = { ...result, collectionPeriod };
    }

    if (formValues.enableSpentCreditsLimit) {
        const spentCreditsLimit: SpentCreditsLimit = {
            value: formValues.spentCreditsLimitValue || 0,
            countForLastXDays: formValues.countForLastXDays ? formValues.countForLastXDays : null,
            countInCollectionPeriod: formValues.countInCollectionPeriod || false
        };
        result = { ...result, spentCreditsLimit };
    }
    return result;
};

export const findConfigType = (name: string|undefined, configTypes: CreditConfigType[]|undefined|null): CreditConfigType|undefined => {
    if (name && configTypes) {
        return configTypes.find((configType => configType.type === name));
    }
    return undefined;
};

export const getCappingValueForConfigType = (type: string|undefined, capping: Capping): number|undefined => {
    if (!capping || !capping.length || !type) {
        return undefined;
    }

    const selectedCapping = capping.find(capItem => capItem.types?.includes(type));
    return selectedCapping?.capping ?? undefined;
};

type TypeConfigProperty = {
        type: "string"|"number"|"boolean"|"object";
        enum?: string[];
        maximum?: number;
        minimum?: number;
        step?: number;
        default?: number|string|boolean|null;
}

type ArrayTypeConfigProperty = {
        type: "array";
        items: {
            properties: {[key: string]: TypeConfigProperty;};
            required?: string[];
            type: string;
        };
}
type TypeConfig = {
    properties: {[key: string]: TypeConfigProperty|ArrayTypeConfigProperty;};
    required?: string[];
}

const commonProperties: {[key: string]: TypeConfigProperty;} = {
    type: {
        type: "string"
    },
    enabled: {
        type: "boolean"
    }
};

const genericTypeConfig: TypeConfig = {
    properties: {
        credits: {
            type: "number",
            minimum: 0
        }
    },
    required: ["type", "credits"]
};

const activityV1TypeConfig: TypeConfig = {
    properties: {
        roundCredits: {
            type: "boolean"
        },
        creditsDecimals: {
            type: "number",
            minimum: 0
        },
        clientFactor: {
            type: "number",
            minimum: 0
        },
        creditsPerStep: {
            type: "number",
            minimum: 0
        },
        activityConversions: {
            type: "array",
            items: {
                properties: {
                    activity: {
                        type: "string"
                    },
                    kcalPerHour: {
                        type: "number",
                        minimum: 0
                    },
                    creditsPerKm: {
                        type: "number",
                        minimum: 0
                    }
                },
                type: "object",
                required: ["activity", "kcalPerHour"]
            }
        }
    },
    required: ["type", "roundCredits", "creditsDecimals", "clientFactor", "creditsPerStep"]
};

const activityV2TypeConfig: TypeConfig = {
    properties: {
        roundCredits: {
            type: "boolean"
        },
        creditsDecimals: {
            type: "number",
            minimum: 0
        },
        creditsPerKcal: {
            type: "number",
            minimum: 0
        },
        creditsPerStep: {
            type: "number",
            minimum: 0
        },
        manualCreditsPerKcal: {
            type: "number",
            minimum: 0
        },
        manualCreditsPerStep: {
            type: "number",
            minimum: 0
        }
    },
    required: ["type", "roundCredits", "creditsDecimals", "creditsPerKcal", "creditsPerStep", "manualCreditsPerKcal", "manualCreditsPerStep"]
};

const activityV3TypeConfig: TypeConfig = {
    properties: {
        creditsPerKcal: {
            type: "number",
            minimum: 0
        },
        hoursPerStep: {
            type: "number",
            minimum: 0
        },
        kcalPerHourForSteps: {
            type: "number",
            minimum: 0
        },
        kcalPerHour: {
            type: "array",
            items: {
                properties: {
                    activity: {
                        type: "string"
                    },
                    kcalPerHour: {
                        type: "number",
                        minimum: 0
                    }
                },
                type: "object",
                required: ["activity", "kcalPerHour"]
            }
        }
    },
    required: ["type", "creditsPerKcal", "hoursPerStep", "kcalPerHourForSteps"]
};

const typesConfigMap = new Map<CreditVersion, TypeConfig>([
    ["generic", genericTypeConfig],
    ["activityV1", activityV1TypeConfig],
    ["activityV2", activityV2TypeConfig],
    ["activityV3", activityV3TypeConfig]
]);

const calculateStep = (defaultValue: number) => {
    const numberofZeros = defaultValue.toString().split(".")[1]?.length || 0;
    if (numberofZeros) {
        return Number(`0.${"0".repeat(numberofZeros - 1)}1`);
    }
    return 1;
};

const getFormValuesForType = (typeConfig: TypeConfig, creditConfigType: CreditConfigType|undefined, form: FormInstance): JSONData => {
    const { properties: customProperties, required } = typeConfig;
    const properties = { ...commonProperties, ...customProperties };
    if (creditConfigType) {
        Object.entries(creditConfigType).forEach(([key, value]) => {
            if (properties[key]) {
                const property = properties[key];
                if (property.type === "array") {
                    const currenttypeSpecificProps = form.getFieldValue(["typeSpecificProps"]);
                    form.setFieldsValue({
                        typeSpecificProps: {
                            ...currenttypeSpecificProps,
                            [key]: value
                        }
                    });
                } else {
                    property.default = value;
                }
                if (property.type === "number") {
                    property.step = calculateStep(value as unknown as number);
                }
            }
        });
    }

    return {
        properties,
        required,
        type: "object"
    };
};

export const creditConfigTypeToFormValues = (creditVersion: CreditVersion, creditConfigType: CreditConfigType|undefined, form: FormInstance): JSONData|undefined => {
    if (!creditVersion) {
        return undefined;
    }
    const typeConfig = typesConfigMap.get(creditVersion);
    if (!typeConfig) {
        return undefined;
    }

    return getFormValuesForType(typeConfig, creditConfigType, form);
};

const updateCapping = (oldCapping: Capping, newValue: number|null, type: string): Capping => {
    if (!oldCapping || !oldCapping.length) {
        if (newValue) {
            return [{
                capping: newValue,
                types: [type]
            }];
        }
        return oldCapping;
    }
    const newCapping = oldCapping.slice();
    const capItemIdxToUpdate = newCapping.findIndex(capItem => capItem.types?.includes(type));
    if (capItemIdxToUpdate > -1) {
        newCapping.splice(capItemIdxToUpdate, 1, { ...newCapping[capItemIdxToUpdate], capping: newValue });
    } else {
        newCapping.push({ capping: newValue, types: [type] });
    }
    return newCapping;
};

const updateTypes = (oldTypes: CreditConfigType[]|undefined|null, newType: CreditConfigType, editedConfigType?: string): CreditConfigType[] => {
    if (!oldTypes || !oldTypes.length) {
        return [newType];
    }

    const newTypes = oldTypes.slice();
    const typeEdited = editedConfigType && newType.type !== editedConfigType;
    const typeIdxToUpdate = newTypes.findIndex(typeItem => {
        if (typeEdited) {
            return typeItem.type === editedConfigType;
        }
        return typeItem.type === newType.type;
    });
    if (typeIdxToUpdate > -1) {
        newTypes.splice(typeIdxToUpdate, 1, newType);
    } else {
        newTypes.push(newType);
    }
    return newTypes;
};

export type CreditConfigTypeFormValues = {
    version: CreditVersion;
    capping: number|null;
    typeSpecificProps: Omit<CreditConfigType, "version">;
}

export const formValuesToCreditConfigType = (values: CreditConfigTypeFormValues, oldCreditConfig: CreditConfig, editedConfigType?: string) => {
    const { version, capping: newCappingValue, typeSpecificProps } = values;
    const newCapping = updateCapping(oldCreditConfig?.capping, newCappingValue, typeSpecificProps.type);
    const newTypes = updateTypes(oldCreditConfig?.types, { ...typeSpecificProps, version }, editedConfigType);

    return {
        ...oldCreditConfig,
        capping: newCapping,
        types: newTypes
    };
};

const deleteCapping = (oldCapping: Capping, type: string) => {
    if (!oldCapping || !oldCapping.length) {
        return oldCapping;
    }
    const capItemIdxToDelete = oldCapping.findIndex(capItem => capItem.types?.includes(type));
    if (capItemIdxToDelete === -1) {
        return oldCapping;
    }
    const newCapping = oldCapping.slice();
    const newCappingItem = newCapping[capItemIdxToDelete];
    const newCappingItemTypes = newCappingItem.types?.filter(typeItem => typeItem !== type);
    if (!newCappingItemTypes || !newCappingItemTypes.length) {
        newCapping.splice(capItemIdxToDelete, 1);
    } else {
        newCapping.splice(capItemIdxToDelete, 1, { capping: newCappingItem.capping, types: newCappingItemTypes });
    }
    return newCapping;
};

export const creditConfigOnCreditConfigTypeDelete = (id: string, creditConfig: CreditConfig) => {
    const types = creditConfig?.types;
    if (!types || !types.length) {
        return undefined;
    }
    const updatedTypes = types.filter(type => type.type !== id);
    const updatedCapping = deleteCapping(creditConfig?.capping, id);
    return { ...creditConfig, types: updatedTypes, capping: updatedCapping };
};
