import { VisualisationsTable, VisualisationsTableDataSource } from "@components/challengeVisualisationsTable/visualsationsTable";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { ChallengeVisualisationBaseFormValues, VisualisationTypeForm } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { getText } from "@utils/getText";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { Card, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChallengeLeaderboardVisualisationFormValues } from "../editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisation";
import { ConditionsLeaderboardInfoStyle } from "./visualisationsDefaultCardStyle";

export interface VisualisationsDefaultCardProps {
    form: FormInstance;
    hidden?: boolean;
    errors?: ErrorDictionary;
    editVisualisation(index: number): void;
    onBlur(): void;
}

export interface AddVisualisationForm {
    newVisualisation?: ChallengeVisualisationBaseFormValues;
}

export const VisualisationsDefaultCard: React.FC<VisualisationsDefaultCardProps> = (props) => {
    const { form, editVisualisation, onBlur, hidden, errors } = props;

    const isTeamChallenge = form.getFieldValue(["team", "enabled"]);

    const conditionsLeaderboardInfo = (visualisation: ChallengeLeaderboardVisualisationFormValues) => {
        const conditions = form.getFieldValue("conditions");
        const description = getText(visualisation.description);

        return (
            <ConditionsLeaderboardInfoStyle>
                {description && (
                    <p>
                        <FormattedMessage
                            id="description"
                            tagName="span"
                        />
:
                        {" "}
                        {description}
                    </p>
                )}
                <FormattedMessage
                    id="conditions"
                    tagName="h2"
                />
                {visualisation.conditions && visualisation.conditions.length
                    ? (
                        <ul>
                            {visualisation.conditions.map(c => {
                                const condition = (conditions || []).find(cond => c.conditionId === cond.id);
                                return (
                                    <li>
                                        <ConditionTitle condition={condition} />
                                        {" "}
                                        {getText(c.title) ? `(Title: ${getText(c.title)})` : ""}
                                    </li>
                                );
                            })}
                        </ul>
                    )
                    : <FormattedMessage id="leaderboard.conditions.empty" />}
            </ConditionsLeaderboardInfoStyle>
        );
    };

    const visualisationsToTableDataSource = (visualisations: ChallengeVisualisationBaseFormValues[]): VisualisationsTableDataSource[] => {
        return visualisations.map((v, index) => {
            return {
                id: String(index),
                place: v.place,
                type: v.type,
                condition: v.conditionId || "",
                dataType: v.dataType,
                hasError: hasError(errors, [index]),
                description: (v.conditionId === "conditions" && conditionsLeaderboardInfo(v as any)) || undefined
            };
        });
    };

    return (
        <Form.List name={["visualisations"]}>
            {(fields, { add, remove }) => {
                const visualisations = form.getFieldValue("visualisations");
                const conditions = form.getFieldValue("conditions");
                return (
                    <React.Fragment>
                        <Card hidden={hidden}>
                            <VisualisationTypeForm
                                add={add}
                                conditions={conditions}
                                editVisualisation={editVisualisation}
                                existingVisualisations={visualisations}
                                isTeamChallenge={isTeamChallenge}
                                prefix={["newVisualisation"]}
                                redirect={() => editVisualisation(visualisations.length)}
                                onChange={onBlur}
                            />
                        </Card>

                        <Card hidden={hidden}>
                            <ContentHeader
                                title={<FormattedMessage id="visualisations" />}
                            />
                            <VisualisationsTable
                                conditions={conditions}
                                dataSource={visualisationsToTableDataSource(visualisations || [])}
                                deleteVisualisation={remove}
                                editVisualisation={editVisualisation}
                                errors={errors}
                                onChange={onBlur}
                            />
                        </Card>
                    </React.Fragment>
                );
            }}
        </Form.List>
    );
};
