import { FormsPage } from "@pages/formsPage/formsPage";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { GetFormTemplatesOverviewProps, RemoveFormTemplateProps, withGetFormTemplatesOverview, withRemoveFormTemplate } from "../../graphql2/types";

export interface FormsPageContainerProps { }

const withFormTemplates = withGetFormTemplatesOverview({
    name: "formTemplatesQueryResult",
    options: () => ({
        variables: {
            filter: {
                type: "event"
            }
        }
    })
});

export const FormsPageContainer = compose<React.ComponentClass<FormsPageContainerProps>>(
    withRouter,
    withFormTemplates,
    withRemoveFormTemplate({
        name: "removeFormTemplate",
        options: {
            refetchQueries: () => ["getFormTemplatesOverview"],
            awaitRefetchQueries: true
        }
    })
)(FormsPage);

export type FormsPageProps =
    FormsPageContainerProps
    & RouteComponentProps
    & RemoveFormTemplateProps<{}, "removeFormTemplate">
    & GetFormTemplatesOverviewProps<{}, "formTemplatesQueryResult">;
