import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { ExportJourneyTagContainer } from "@components/export/exportJourneyTagsContainer";
import { withGetJourneyTags, withRemoveJourneyTag } from "../../graphql2/types";

export const withJourneyTags = withGetJourneyTags({
    name: "tags"
});
export const withRemoveJourneyTagMutation = withRemoveJourneyTag({
    options: {
        refetchQueries: ["getJourneyTags"],
        awaitRefetchQueries: true
    },
    name: "removeTag"
});

export const JourneyTagsPageContainer = createTagsPageContainer(
    withJourneyTags,
    withRemoveJourneyTagMutation,
    "/journeys/tags/add/general",
    "journeys",
    ExportJourneyTagContainer,
    ["tags", "journeyTags"]
);
