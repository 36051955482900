import styled from "styled-components";

export const DashboardConfigColumnPickerStyle = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 50px;

    h2 {
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 30px;
    }
`;

export const DashboardConfigColumns = styled.div`
    display: flex;
    padding: 10px 0;
    margin-bottom: 15px;
`;

export interface DashboardConfigColumnItemProps {
    active: boolean;
}

export const DashboardConfigColumnItem = styled.div<DashboardConfigColumnItemProps>`
    display: flex;
    font-size: 24px;
    padding: 10px 15px;
    color: ${props => props.active ? "#A0C63D" : "#D5D5D5"};
    cursor: pointer;
`;

export const DashboardConfigColumnSizes = styled.div`
    display: flex;
    padding-top: 15px;
`;

export interface DashboardConfigColumnSizeItemSpanProps {
    width: number;
}

export const DashboardConfigColumnSizeItemSpan = styled.span<DashboardConfigColumnSizeItemSpanProps>`
    display: flex;
    width: ${props => `${props.width}%`};
    margin: 2px;
`;

export interface DashboardConfigColumnSizeItemProps {
    active: boolean;
}

export const DashboardConfigColumnSizeItem = styled.a<DashboardConfigColumnSizeItemProps>`
    display: flex;
    width: 40px;
    height: 30px;
    margin: 0 10px;
    cursor: pointer;

    span {
        background: ${props => props.active ? "#A0C63D" : "#D5D5D5"};
    }
`;
