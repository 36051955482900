import { Field } from "@components/field/field";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { Flex } from "@components/flex/flex";
import { ChallengeVisualisationFont, FontForm } from "@components/fontForm/fontForm";
import { FormModal } from "@components/modal/modal";
import { Label } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { storeImage } from "@utils/storeImage";
import { Checkbox, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ChallengeTimelineVisualisationGoal {
    image: string;
    font?: ChallengeVisualisationFont;
    currentValue: boolean;
    endValue: boolean;
}

export interface EditChallengeTimelineGoalProps {
    hidden: boolean;
    goal: ChallengeTimelineVisualisationGoal;
    form: FormInstance;
    visualisationIndex: number;
    close(): void;
    onBlur(): void;
}

export const EditChallengeTimelineGoal: React.FC<EditChallengeTimelineGoalProps> = (props) => {
    const { hidden, goal, form, onBlur, visualisationIndex, close } = props;
    const onChangeCurry = onBlur;

    const prefix = ["visualisations", visualisationIndex, "banner", "goal"];

    // TODO: works but doesnt trigger rerender/refetch!

    return (
        <FormModal
            cancelButtonProps={{ onClick: close }}
            hidden={hidden}
            okButtonProps={{ onClick: close }}
        >
            <div>
                <Label><FormattedMessage id="image" /></Label>
                <FileDragAndDropContainer
                    defaultImage={goal.image}
                    onChange={async (image): Promise<void> => storeImage(onChangeCurry, form, `${prefix.join(".")}.image`, image, undefined, {
                        fields: [["visualisations"], ["banner", "goal", "image"]],
                        index: visualisationIndex
                    })}
                />
                <Field
                    hidden
                    id="imageUrl"
                    name={[...prefix, "image"]}
                />
            </div>
            <FontForm
                font={goal.font}
                form={form}
                prefix={[...prefix, "font"]}
            />
            <Flex>
                <Form.Item
                    name={[...prefix, "currentValue"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage id="challenge.visualisations.showCurrent" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name={[...prefix, "endValue"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage id="challenge.visualisations.showEnd" />
                    </Checkbox>
                </Form.Item>
            </Flex>
        </FormModal>
    );
};
