import { ChallengeFormValues } from "@pages/addChallengesPage/addChallengesPage";
import { languages } from "@pages/translationsPage/constants/languages";
import * as moment from "moment";

export const getEmptyChallengeVoucherConfig = () => (
    {
        required: false,
        description: languages.reduce<{}>((result, { code }) => ({ ...result, [code]: { text: "" } }), {}),
        title: languages.reduce<{}>((result, { code }) => ({ ...result, [code]: { text: "" } }), {}),
        url: ""
    }
);

export const emptyChallenge: ChallengeFormValues = {
    id: "",
    title: {},
    subtitle: {},
    description: {},
    conditionsInOrder: false,
    rewards: {
        credits: 0,
        prizes: []
    },
    difficultyLevel: 1,
    duration: {
        end: moment().add(28, "days"),
        start: moment()
    },
    registration: {
        participateOnStart: true
    },
    tieBreaker: {
        active: false,
        ask: "captain",
        description: {},
        question1: {},
        question2: {},
        title: {}
    },
    conditions: [],
    ultimateSyncTime: moment().add(29, "days"),
    milestones: [],
    roles: [],
    team: {
        enabled: false,
        fixedTeams: false,
        maxMembers: 0,
        profileProperty: "",
        inviteCodeEnabled: false
    },
    endless: {
        enabled: false,
        activityTypes: [],
        rankingAlgorithm: "",
        unitType: ""
    },
    priority: 0,
    exceedConditionLimit: false,
    featured: false,
    hideInList: false,
    hideProgressInDetails: false,
    hideProgressInInsights: false,
    hideProgressInList: false,
    showFullDescription: false,
    imageUrl: "",
    publishedOn: moment().subtract(7, "days"),
    expiresOn: moment().add(28, "days"),
    visualisations: [],
    tags: [],
    activityTypes: [],
    viewParticipants: false,
    viewParticipantCount: false,
    viewTeamCount: false,
    viewCompletedParticipants: false,
    challengeVoucherConfiguration: getEmptyChallengeVoucherConfig(),
    enabledLocales: [],
    totals: {
        stepsVisible: false,
        elevationVisible: false,
        durationVisible: false,
        distanceVisible: false,
        creditsEarnedVisible: false,
        caloriesVisible: false
    },
    superDays: {
        dates: [],
        title: {},
        description: {},
        multiplier: 1
    },
    autoParticipationEnabled: false,
    disableComments: false
};
