import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { getContentCount } from "@graphql2/graphql_op";
import { ContentsPage } from "@pages/contentsPage/contentsPage";
import { getFrontendRoleIdFromString } from "@utils/applicationUtils";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import { languages } from "environment";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
    ContentFilter,
    GetApplicationsProps,
    GetContentCountProps,
    GetContentTagsProps,
    GetContentsProps,
    RemoveContentProps,
    withGetApplications,
    withGetContentCount,
    withGetContents,
    withRemoveContent
} from "../../graphql2/types";

export interface Tag {
    id: string;
    status: boolean;
    statusType: string;
    title: string;
    type?: string;
}

export interface ContentsPageContainerProps {

}

interface ContentsPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: ContentFilter;
}

const createContentsPageFilter = (objectFilter: any): ContentFilter => {
    const createdFilter: ContentFilter = {
        textSearch: objectFilter.textSearch,
        type: objectFilter.contentType,
        enabledLocales: objectFilter.enabledLocales,
        roles: (objectFilter.userRoles && [objectFilter.userRoles].flat().map(getFrontendRoleIdFromString)) || []
    };

    if (objectFilter.enabledLocales && !Array.isArray(objectFilter.enabledLocales)) {
        createdFilter.enabledLocales = [objectFilter.enabledLocales];
    }
    if (!objectFilter.enabledLocales) {
        createdFilter.enabledLocales = [...languages];
    }

    return createdFilter;
};

export const withContents = withGetContents({
    options({ sort, paging, filter }: ContentsPageProps) {
        return {
            variables: {
                filter,
                paging,
                sort
            }
        };
    }
});

export const withContentCount = withGetContentCount({
    name: "count",
    options({ filter }: ContentsPageProps) {
        return {
            variables: {
                filter
            }
        };
    }
});

export const withRemoveContentMutation = withRemoveContent({
    name: "removeContent",
    options: {
        refetchQueries: () => [
            "getContents",
            {
                query: getContentCount,
                variables: {
                    filter: {}
                }
            }
        ],
        awaitRefetchQueries: true
    }
});

const withApplications = withGetApplications({
    name: "applications"
});

export const ContentsPageContainer = compose<React.ComponentClass<ContentsPageContainerProps>>(
    withRouter,
    withApplications,
    withParseDynamicFilterBar(createContentsPageFilter, { defaultSortSettings: { sortBy: "publishOn", sort: "DESC" } }),
    withContents,
    withContentCount,
    withRemoveContentMutation
)(ContentsPage);

export type ContentsPageProps =
    ContentsPageFilterBarProps &
    ContentsPageContainerProps &
    GetContentTagsProps<{}, "contentTags"> &
    RemoveContentProps<{}, "removeContent"> &
    GetContentCountProps<{}, "count"> &
    GetContentsProps &
    GetApplicationsProps<{}, "applications"> &
    RouteComponentProps;
