import styled from "styled-components";
import { Flex } from "@components/flex/flex";
import { WidgetStyle } from "../widgetStyle";

export const StatisticsWidgetStyle = styled(WidgetStyle)`
     .carousselModeSwitch {
        margin: 14px 0;

        & .ant-select {
            margin-top: 10px;
        }
    }

    .tabscover {
        position: relative;
    }

    .dropDownCarousselPicker {
        border: none;
        box-shadow: none;
        padding-right: 0;
        margin: 15px 0;
        position: relative;
        width: 100%;
        background: #A0C63D;
        color: #FFFFFF;

        &:hover, &:focus {
            background: #A0C63D;
            color: #FFFFFF;
        }
    }

    ${Flex} {
        .ant-form-item {
            width: 100%;
        }
    }
`;

export const StatisticsWidgetPaneStyle = styled.div`
    h3 {
        color: #A0C63D;
        margin: 10px 0 0 0;
        font-weight: 600;
        font-size: 120%;
        text-align: center;
    }

    h2 {
        color: #A0C63D;
        margin: 20px 0 0 0;
        font-weight: 600;
        font-size: 110%;
    }

    .ant-row + .ant-row {
        margin-left: 0 !important;
    }
`;
