import { Moment } from "moment";

/*  This file is meant to hold any helper functions to format dates, datetimes, durations, and more. */

/*
    FORMATDATE
    Used for dates, that shouldn't include a time (antd datepicker automatically includes current time),
    set to UTC for back-end
*/
export const formatDate = (date: Moment, endOf?: true) => {
    if (endOf) {
        return date.utc().endOf("day").valueOf();
    }

    return date.utc().startOf("day").valueOf();
};
