import { GenericWidget } from "@components/dashboard/widgets/genericWidget/genericWidget";
import { VideoWidget } from "@components/dashboard/widgets/videoWidget/videoWidget";
import { ApplicationFragment } from "@graphql2/types";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { ActivityWidget } from "../widgets/activityWidget/activityWidget";
import { CarousselWidget } from "../widgets/carousselWidget/carousselWidget";
import { ChallengeVisualisationWidgetContainer } from "../widgets/challengeVisualisationWidget/challengeVisualisationWidgetContainer";
import { ChallengeWidget } from "../widgets/challengeWidget/challengeWidget";
import { CommunityWidget } from "../widgets/communityWidget/communityWidget";
import { ContentWidget } from "../widgets/contentWidget/contentWidget";
import { EventWidget } from "../widgets/eventWidget/eventWidget";
import { PopupWidget } from "../widgets/popupWidget/popupWidget";
import { SocialWidgetContainer } from "../widgets/socialWidget/socialWidgetContainer";
import { StatisticsWidget } from "../widgets/statisticsWidget/statisticsWidget";

export interface WidgetConfiguratorProps {
    errors: ErrorDictionary;
    form: FormInstance;
    roles: ApplicationFragment["roles"];
    widgetId: string;
}

export const WidgetConfigurator: React.FC<WidgetConfiguratorProps> = (props): JSX.Element => {
    const {
        errors,
        form,
        roles,
        widgetId
    } = props;

    const sharedProps = {
        errors,
        form,
        applications: roles,
        widgetId
    };

    const type = form.getFieldValue(["widgets", widgetId, "type"]);

    if (type === "activity") {
        return <ActivityWidget {...sharedProps} />;
    }

    if (type === "caroussel") {
        return <CarousselWidget {...sharedProps} />;
    }

    if (type === "challenge") {
        return <ChallengeWidget {...sharedProps} />;
    }

    if (type === "content") {
        return <ContentWidget {...sharedProps} />;
    }

    if (type === "event") {
        return <EventWidget {...sharedProps} />;
    }

    if (type === "generic") {
        return <GenericWidget {...sharedProps} />;
    }

    if (type === "social") {
        return <SocialWidgetContainer {...sharedProps} />;
    }

    if (type === "video") {
        return <VideoWidget {...sharedProps} />;
    }

    if (type === "challengeVisualisation") {
        const challengeIdPath = ["widgets", widgetId, "challengeId"];
        return (
            <Form.Item dependencies={[challengeIdPath]}>
                {() => (
                    <ChallengeVisualisationWidgetContainer
                        {...sharedProps}
                        challengeId={sharedProps.form.getFieldValue(challengeIdPath)}
                    />
                )}
            </Form.Item>
        );
    }

    if (type === "statistics") {
        return <StatisticsWidget {...sharedProps} />;
    }

    if (type === "community") {
        return <CommunityWidget {...sharedProps} />;
    }

    if (type === "popup") {
        return <PopupWidget {...sharedProps} />;
    }

    console.warn(`Unknown type ${type}`);

    return <div>{`Unknown type ${type}`}</div>;
};
