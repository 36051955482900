import { CommentCardProps, LocalState } from "@components/comments/commentCard/commentCardContainer";
import { CommentCardStyle } from "@components/comments/commentCard/commentCardStyle";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { TableFilter } from "@components/table/tableFilters";
import { CommentFragment } from "@graphql2/types";
import { tableComments } from "@utils/mappers/commentMapper";
import { Comment_admin } from "@utils/roles";
import { Card, message } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CommentsTable } from "../commentsTable";

@autobind
export class CommentCard extends React.Component<CommentCardProps> {
    public render() {
        const { hasRole } = this.props;
        if (!hasRole(Comment_admin)) {
            return (
                <CommentCardStyle>
                    <HeaderContent>
                        <FormattedMessage
                            id="comments.notAuthorized"
                            tagName="h1"
                        />
                    </HeaderContent>
                </CommentCardStyle>
            );
        }

        const { comments, noCard } = this.props;

        const table = (
            <TableContainer
                dataSource={(comments && tableComments(comments.comments as CommentFragment[] || [])) || []}
                handlers={{
                    moderateComment: this.moderateComment,
                    removeComment: this.removeComment
                }}
                loading={comments?.loading}
                TableComponent={CommentsTable}
                tableFilter={{
                    updateFilter: this.applyFilter
                }}
            />
        );

        if (noCard) {
            return table;
        }

        return (
            <CommentCardStyle>
                <Card title={<FormattedMessage id="comments" />}>
                    {table}
                </Card>
            </CommentCardStyle>
        );
    }

    private async removeComment(id: string, userId?: string) {
        const { removeComment, intl } = this.props;

        if (id && userId) {
            await removeComment({ variables: { id, userId } });
            message.success(intl.formatMessage({ id: "comment.isRemoved" }));
        }
    }

    private async moderateComment(id: string) {
        const { updateComment, intl } = this.props;

        if (id) {
            await updateComment({
                variables: {
                    id,
                    moderated: true
                }
            });
            message.success(intl.formatMessage({ id: "comment.isModerated" }));
        }
    }

    private applyFilter(filter: TableFilter) {
        const data: LocalState = {
            search: ""
        };

        if (filter.title && data.filter) {
            data.search = filter.title;
        }

        this.props.setLocalState(data);
    }
}
