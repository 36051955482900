import styled from "styled-components";
import { Card } from "antd";

export const PageNotFoundStyle = styled(Card)`
    background: #fff;
    font-size: 1.2em;
    font-weight: 600;

    h2 {
        margin-top: 0;
        font-size: 5em;
        color: #A0C63D;
    }

    p {
        margin-bottom: 1em;
    }
`;