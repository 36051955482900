import { HeaderContent } from "@components/headerContent/headerContent";
import { AddTranslationsContainer } from "@components/translations/addTranslations/addTranslationsContainer";
import { ImportTranslationsContainer } from "@components/translations/importTranslations/importTranslationsContainer";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { TranslationsListContainer } from "@components/translations/translationsList/translationsListContainer";
import { languages } from "@pages/translationsPage/constants/languages";
import { debounce } from "@utils/debounce";
import { Button, Card, Divider, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { SearchBar } from "./translationsPageStyle";

export interface TranslationsPageProps {
    superAdmin?: boolean;
}

export interface TranslationsPageState {
    selectedLanguage: string;
    search: string;
    searchOnValue?: boolean;
    enableKeysFilter?: boolean;
    selectedKeys?: string[];
}

export const TranslationsPage: React.FC<TranslationsPageProps> = ({ superAdmin }) => {
    const intl = useIntl();
    const [form] = useForm();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();
    const [enableKeysFilter, setEnableKeysFilter] = useState<boolean>();
    const [selectedLanguage, setSelectedLanguage] = useCurrentLanguage();
    const [search, setSearch] = useState<string>("");
    const setDebounceSearch = debounce((val) => {
        setSearch(val);
    }, 1000);

    function changeSelectedLanguage(languageCode: string) {
        const values = form.isFieldsTouched();

        if (values) {
            Modal.confirm({
                width: 500,
                title: intl.formatMessage({ id: "losingChanges.title" }),
                content: intl.formatMessage({ id: "losingChanges.description" }),
                okText: intl.formatMessage({ id: "losingChanges.continue" }),
                cancelText: intl.formatMessage({ id: "losingChanges.cancel" }),
                onOk: () => {
                    form.resetFields();
                    changeSelectedLanguage(languageCode);
                }
            });
        } else {
            setSelectedLanguage(languageCode);
        }
    }

    return (
        <div>
            <HeaderContent>
                <FormattedMessage
                    id="translations"
                    tagName="h1"
                />
                {(superAdmin
                    && (
                        <ImportTranslationsContainer
                            onSubmit={(keys) => {
                                setSelectedKeys(keys);
                                setEnableKeysFilter(true);
                                setSearch("");
                            }}
                        />
                    ))
                    || null}
            </HeaderContent>
            <Card>
                {(superAdmin
                    && (
                        <AddTranslationsContainer
                            filterEnabled={enableKeysFilter}
                            keys={selectedKeys}
                            onChangeKeys={(keys) => setSelectedKeys(keys)}
                            onSubmit={() => {
                                setEnableKeysFilter(true);
                                setSearch("");
                            }}
                        />
                    ))
                    || null}
                <TranslationTabs
                    activeLanguage={selectedLanguage}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                />
                <SearchBar>
                    <Input.Search
                        placeholder={intl.formatMessage({ id: "translationsPage.searchByTitle" })}
                        width={300}
                        onChange={e => setDebounceSearch(e.target.value)}
                    />
                    {enableKeysFilter
                        && (
                            <Button onClick={() => {
                                setSelectedKeys(undefined);
                                setEnableKeysFilter(false);
                            }}
                            >
                                <FormattedMessage id="translationsPage.disableKeysFilter" />
                            </Button>
                        )}
                </SearchBar>
                <Divider />
                <TranslationsListContainer
                    form={form}
                    keys={enableKeysFilter && selectedKeys && selectedKeys.length ? selectedKeys : null}
                    search={search}
                    selectedLanguage={selectedLanguage}
                    superAdmin={superAdmin}
                />
            </Card>
        </div>
    );
};
