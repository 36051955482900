import styled from "styled-components";

export const ColorPreviewFieldWrapper = styled.div`
    position: relative;
`;

export interface ColorPreviewProps {
    backgroundColor?: string;
}

export const ColorPreview = styled.div`

    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 1em;
        right: 0.7em;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${(props: ColorPreviewProps) => props.backgroundColor || ""};
    }
`;
