import { DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { TableComponentProps } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { GetProviderActivitiesQuery } from "@graphql2/types";
// eslint-disable-next-line camelcase
import { User_Super_admin } from "@utils/roles";
import { useHasRole } from "@utils/useHasRole";
import { Avatar, Popconfirm, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";

export type ProviderActivitiesTableData = Omit<GetProviderActivitiesQuery["providerActivities"][0], "id"|"segmentId"> & { id: string; segmentId: string;}

interface ProviderActivitiesTableProps extends TableComponentProps {
    dataSource: ProviderActivitiesTableData[];
    handlers: {
        removeActivity(id: string, segmentId: string): void;
    };
}

const parseProviderName = (name: string) => name.replace(/[^a-zA-Z]/g, "");

const renderSource = (source: string|undefined) => {
    return (
        <Avatar
            alt={source}
            shape="square"
            src={source ? `https://res.cloudinary.com/energylab/image/upload/v1567517523/generic-v2/icons/${parseProviderName(source)}.png` : undefined}
        />
    );
};

const renderHandlers = (row: ProviderActivitiesTableData, handlers: ProviderActivitiesTableProps["handlers"], hasRole: (role: string | undefined) => boolean) => {
    if (hasRole(User_Super_admin)) {
        return (
            <Popconfirm
                cancelText="No"
                okText="Yes"
                title={<FormattedMessage id="deleteConfirm" />}
                onConfirm={() => handlers.removeActivity(row.id, row.segmentId)}
            >
                <TableIcon as={DeleteOutlined} />
            </Popconfirm>
        );
    }

    return (
        <Tooltip title={<FormattedMessage id="providerActivities.needsuperUser" />}>
            <TableIcon as={LockOutlined} />
        </Tooltip>
    );
};

export const ProviderActivitiesTable: React.FC<ProviderActivitiesTableProps> = (props) => {
    const hasRole = useHasRole();
    return (
        <Table
            rowKey={(record: ProviderActivitiesTableData) => `${record.id}#${record.segmentId}#${record.source}`}
            {...props}
        >
            <Column
                key="source"
                dataIndex="source"
                render={renderSource}
                sorter={(a: ProviderActivitiesTableData, b: ProviderActivitiesTableData) => (a.source || "").localeCompare(b.source || "")}
                title={<FormattedMessage id="source" />}
            />
            <Column
                key="activity"
                dataIndex="activity"
                sorter={(a: ProviderActivitiesTableData, b: ProviderActivitiesTableData) => (a.activity || "").localeCompare(b.activity || "")}
                title={<FormattedMessage id="activity" />}
            />
            <Column
                key="start"
                dataIndex="start"
                render={(_, row: ProviderActivitiesTableData) => moment(row.start).format("DD/MM/YYYY HH:mm:ss")}
                sorter={(a: ProviderActivitiesTableData, b: ProviderActivitiesTableData) => Number(a.start) - Number(b.start)}
                title={<FormattedMessage id="start" />}
                width={160}
            />
            <Column
                key="end"
                dataIndex="end"
                render={(_, row: ProviderActivitiesTableData) => moment(row.end).format("DD/MM/YYYY HH:mm:ss")}
                sorter={(a: ProviderActivitiesTableData, b: ProviderActivitiesTableData) => Number(a.end) - Number(b.end)}
                title={<FormattedMessage id="end" />}
                width={160}
            />
            <Column
                key="steps"
                dataIndex="steps"
                title={<FormattedMessage id="steps" />}
            />
            <Column
                key="distance"
                dataIndex="distance"
                render={(_, row: ProviderActivitiesTableData) => `${(((row.distance ?? 0) / 1000).toFixed(2))} km`}
                title={<FormattedMessage id="distance" />}
            />
            <Column
                key="calories"
                dataIndex="calories"
                render={(_, row: ProviderActivitiesTableData) => `${((row.calories ?? 0) / 1000).toFixed(0)} kcal`}
                title={<FormattedMessage id="calories" />}
            />
            <Column
                key="handlers"
                dataIndex=""
                render={(_, row: ProviderActivitiesTableData) => renderHandlers(row, props.handlers, hasRole)}
                title=""
                width={120}
            />
        </Table>
    );
};
