import Form from "antd/lib/form";
import { FormInstance } from "antd/lib/form/Form";
import { ColorPreviewFieldWrapper } from "@components/field/colorPreviewFieldStyle";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ChromePicker } from "react-color";
import * as React from "react";
import { Field, FieldProps } from "./field";

export interface ColorPreviewFieldProps extends FieldProps {
    label?: string | JSX.Element;
    info?: string | JSX.Element;
    disabled?: boolean;
    id?: string;
    value?: string;
    placeholder?: string;
    hidden?: boolean;
    onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
    form: FormInstance;
    colorPickerPlacement?: TooltipPlacement
}

export const ColorPreviewField: React.FC<ColorPreviewFieldProps> = (props) => {
    const { label, info, name = "", placeholder, form, colorPickerPlacement, ...inputProps } = props;
    return (
        <ColorPreviewFieldWrapper className={inputProps.hidden ? "hidden" : ""}>
            <Form.Item dependencies={[name]}>
                {() => (
                    <Popover
                        getPopupContainer={trigger => trigger.parentElement!}
                        placement={colorPickerPlacement || "rightTop"}
                        content={(
                            <Form.Item name={name}>
                                <ChromePicker
                                    disableAlpha
                                    color={form.getFieldValue(name)}
                                    onChange={(color) => form.setFields([{ name, value: color.hex }])}
                                />
                            </Form.Item>
                        )}
                        trigger="click"
                        overlayClassName="popover-without-padding"
                    >
                        <Field
                            info={info}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            {...inputProps}
                        />
                    </Popover>
                )}
            </Form.Item>
        </ColorPreviewFieldWrapper>
    );
};
