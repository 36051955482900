import { ContentHeader } from "@components/contentHeader/contentHeader";
import { CkeField } from "@components/field/ckeField";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../context/enabledLangContext/context";

export interface ChallengeTitleAndDescriptionFormValues {
    title: LanguageObject;
    subtitle: LanguageObject;
    description: LanguageObject;
    enabledLocales: string[];
}

export interface TitleAndDescriptionCardProps {
    form: FormInstance;
    hidden?: boolean;
    defaultValues: Partial<ChallengeTitleAndDescriptionFormValues>;
    disabled?: boolean;
    languageErrors?: LanguageErrors;
    customErrors?: any;
    onBlur(): void;
}

const detailField = (props: ChallengeLanguageFieldProps) => (
    <CkeField
        key={`detailField.${props.language}`}
        form={props.form}
        hasError={props.extra?.description?.[`${props.language}`]}
        hidden={props.hidden}
        initialValue={props.defaultValues && props.defaultValues.description ? (props.defaultValues.description[props.language] || "") : ""} // still required as this is a custom component
        label={<FormattedMessage id="detail" />}
        name={["description", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        onChangeUnsaved={props.sharedProps.onChange}
        {...props.sharedProps}
    />
);

const subTitleField = (props: ChallengeLanguageFieldProps) => (
    <Field
        key={`subTitle.${props.language}`}
        hidden={props.hidden}
        id={`subTitle.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="subTitle" />}
        name={["subtitle", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            },
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            }
        ]}
        {...props.sharedProps}
    />
);

const titleField = (props: ChallengeLanguageFieldProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

export const TitleAndDescriptionCard: React.FC<TitleAndDescriptionCardProps> = (props) => {
    const { hidden, form, languageErrors, disabled, onBlur, defaultValues, customErrors } = props;
    const { enabledLanguages } = useEnabledLang();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();

    const sharedProps = {
        disabled,
        onBlur
    };

    // eslint-disable-next-line max-len

    const langFieldProps: ChallengeLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(
            language => ({ form, sharedProps, defaultValues, language: language.code, hidden: language.code !== selectedLanguage, extra: customErrors })
        ),
        [enabledLanguages, selectedLanguage, customErrors]
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="titleAndDescription" />}
            />
            <TranslationTabs
                activeLanguage={selectedLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />

            {langFieldProps.map(titleField)}
            {langFieldProps.map(subTitleField)}
            {langFieldProps.map(detailField)}
        </Card>
    );
};
