import { useAuth } from "@context/authContext/context";

export const useHasRole = () => {
    const { roles } = useAuth();

    const hasRole = (role: string|undefined): boolean => {
        let _hasRole = false;

        if (role && roles.length > 0) {
            _hasRole = roles.indexOf(role) !== -1;
        }

        return _hasRole;
    };

    return hasRole;
};
