import { ContentTableData } from "@components/content/contentTable";
import { ContentsFormValues } from "@pages/addContentsPage/addContentsPage";
import { formatDate } from "@utils/dateUtils";
import { emptyContent } from "@utils/emptyItems/emptyContent";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import * as environment from "environment";
import * as moment from "moment";
import { AddContentMutationVariables, ContentFragment, GetContentsQuery, GetContentTitlesQuery } from "../../graphql2/types";
import { getOutFalseValues } from "../../utils/getOutFalseValues";
import { getText } from "../getText";

const getStatusType = (expiresOn?: number|null, publishOn?: number|null, isDraft?: boolean|null) => {
    if (expiresOn && expiresOn < moment().valueOf()) {
        return "expired";
    }

    if (isDraft) {
        return "draft";
    }

    if (publishOn && publishOn > moment().valueOf()) {
        return "notPublishedYet";
    }

    return "active";
};

export const translateContents = (originals: GetContentsQuery["contents"]): ContentTableData[] => {
    return originals.map(content => {
        const { id, title, type, isDraft, expiresOn, publishOn, enabledLocales, roles } = content;

        return {
            id,
            title: title ? getText(title) : "",
            type,
            languages: enabledLocales && enabledLocales.join(", "),
            roles: roles?.filter(getOutFalseValues) ?? [],
            status: !(isDraft || (expiresOn && expiresOn < moment().valueOf()) || (publishOn && publishOn > moment().valueOf())) || false,
            statusType: getStatusType(expiresOn, publishOn, isDraft)
        };
    });
};

export const translateContentTitles = (originals: GetContentTitlesQuery["contents"]) => {
    return originals.map(content => {
        const { id, title, publishOn } = content;

        return {
            id,
            title: title ? getText(title) : "",
            isPublished: Boolean(publishOn && publishOn < Date.now())
        };
    });
};

export const formValuesToContent = (formValues: ContentsFormValues): AddContentMutationVariables => {
    const {
        title,
        preview,
        content,
        publishOn,
        expiresOn,
        url,
        imageUrl,
        isPublic,
        roles,
        tags,
        type,
        isDraft,
        userId,
        featured,
        enabledLocales,
        disableComments
    } = formValues;

    return {
        title: toTextByLanguage(title),
        preview: toTextByLanguage(preview),
        content: toTextByLanguage(content),
        isDraft: isDraft || false,
        publishOn: formatDate(publishOn),
        expiresOn: formatDate(expiresOn),
        attachments: [],
        isPublic,
        imageUrl: imageUrl || "",
        url: url || "",
        type: type || "",
        roles: roles || [],
        tags: tags || [],
        userId: userId || "",
        featured: featured || false,
        enabledLocales,
        disableComments
    };
};

export const contentToFormValues = (contentItem?: ContentFragment | null): ContentsFormValues => {
    if (!contentItem) {
        return emptyContent;
    }

    const {
        id,
        title,
        preview,
        content,
        publishOn,
        expiresOn,
        isPublic,
        url,
        imageUrl,
        roles,
        tags,
        type,
        isDraft,
        featured,
        userId,
        enabledLocales,
        disableComments
    } = contentItem;

    const rolesWithoutNull: string[] | null | undefined = roles && roles.filter(r => r !== null) as string[];

    return {
        id,
        title: toLanguageObject(title),
        preview: toLanguageObject(preview),
        content: toLanguageObject(content),
        publishOn: moment(publishOn || undefined),
        expiresOn: moment(expiresOn || undefined),
        url: url || "",
        isPublic: isPublic ?? false,
        imageUrl: imageUrl || "",
        roles: rolesWithoutNull || [],
        tags: tags ? tags.map(tag => tag.id) : [],
        type: type || "",
        isDraft: isDraft || false,
        featured: featured || false,
        userId: userId || null,
        enabledLocales: !enabledLocales || !enabledLocales.length
            ? environment.languages
            : enabledLocales,
        disableComments: Boolean(disableComments)
    };
};
