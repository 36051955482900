import styled from "styled-components";

export const CardSubtitle = styled.h2`

    span {
        font-family: "pingFang", sans-serif;
        font-size: .75rem;
        font-weight: 600;
        color: #A0C63D;
    }
`;

export const SettingsRowWrapper = styled.div`

    .ant-row .ant-checkbox-wrapper {
        margin: 7.5px 0;
    }

    span {
        line-height: 20px;
        font-size: .75rem;
        color: #282828;
        font-weight: 400;
    }
`;

export const DeleteRangeIconWrapper = styled.span`
    margin-left: 10px;
`;

export const AddRangeIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12px;
`;

export const Section = styled.div`
    margin-bottom: 24px;
`;
