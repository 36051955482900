import * as React from "react";
import { FieldInfo } from "@components/field/fieldStyle";
import { DatePicker, Form } from "antd";
import { DescriptionTooltip } from "@components/tooltip/descriptionTooltip";
import * as moment from "moment";
import { FormItemProps, RuleObject } from "antd/lib/form";

type Renderable = string | JSX.Element;

export interface FieldProps extends FormItemProps {
    label: Renderable;
    info?: Renderable;
    disabled?: boolean;
    id?: string;
    format?: string;
    showTime?: object;
    showNow?: boolean;
    // TODO: SinglePickerProps find equivalent
    value?: any;
    placeholder?: string;
    type?: string;
    description?: string
    onBlur?(): void;
    onChange?(date: moment.Moment | null, dateString: string): void;
}

export class DatePickerField extends React.Component<FieldProps> {
    public shouldComponentUpdate(props) {
        const { value: newValue, disabled: newDisabled } = props;
        const { value: oldValue, disabled: oldDisabled } = this.props;

        return (
            newValue !== oldValue
            || newDisabled !== oldDisabled
        );
    }

    public render() {
        const { label, onChange, onBlur, format, showTime, showNow, value, description, ...datePickerProps } = this.props;

        const isRequired = datePickerProps.required || (datePickerProps.rules || []).find((rule: RuleObject) => rule.required === true);

        return (
            <React.Fragment>
                {!datePickerProps.hidden && label && (
                    <label className={isRequired ? "isRequired" : ""} htmlFor={datePickerProps.id}>
                        {label}
                    </label>
                )}
                {!datePickerProps.hidden && description && <DescriptionTooltip description={description} />}
                {!datePickerProps.hidden && datePickerProps.info && <FieldInfo>{datePickerProps.info}</FieldInfo>}
                <Form.Item
                    dependencies={datePickerProps.dependencies}
                    hidden={datePickerProps.hidden}
                    name={datePickerProps.name}
                    initialValue={datePickerProps.initialValue}
                    rules={!datePickerProps.disabled ? datePickerProps.rules : undefined}
                    trigger={datePickerProps.trigger}
                >
                    <DatePicker
                        disabled={datePickerProps.disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        format={format}
                        showTime={showTime}
                        showNow={showNow}
                        value={value}
                    />
                </Form.Item>
            </React.Fragment>
        );
    }
}
