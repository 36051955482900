import { localState, LocalStateProps } from "@utils/withLocalState";

import { ExportProps } from "./export";

export interface LocalState {
    shouldExport: boolean;
}

export const withLocalState = localState<LocalState>({
    shouldExport: false
});

export type ExportContainerProps = ExportProps & LocalStateProps<LocalState>;

export const getByIdQuery = {
    options(props: ExportContainerProps) {
        return {
            variables: {
                filter: {
                    ids: [props.id]
                },
                paging: {}
            }
        };
    },
    skip(props: ExportContainerProps) {
        return !props.shouldExport;
    }
};

export const getByPlaceQuery = {
    options(props: ExportContainerProps) {
        return {
            variables: {
                filter: {
                },
                paging: {},
                places: [props.id]
            }
        };
    },
    skip(props: ExportContainerProps) {
        return !props.shouldExport;
    }
};
