import { AccessWrapper, RolesWrapper, UserRolesApplicationsCardStyle } from "@components/users/userRolesApplicationsCard/userRolesApplicationsCardStyle";
import { useAuth } from "@context/authContext/context";
import { Application } from "@graphql2/types";
import { createNewApplications } from "@utils/createNewApplications";
import { Card, Checkbox, Form, Spin, Switch } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { UserRolesApplicationsCardProps } from "./userRolesApplicationsCardContainer";

const DEFAULT_STATE = {
    projectApplications: [],
    roleData: {}
};

function prepareRoleData(applications: Application[], userApplications?: Application[]) {
    return applications.reduce((roleData, application) => {
        const roles = application.roles ?? [];
        const currentApplication = (userApplications || []).filter(userApp => (userApp.id === application.id));
        const rolesList: Record<string, boolean> = roles.reduce((roleObject, currentRole) => {
            const userCurrentApplication = (userApplications || []).filter(elem => elem.name === currentApplication?.[0]?.name);
            return {
                ...roleObject,
                [currentRole.id]: (userCurrentApplication?.[0]?.roles || []).some(role => role.id === currentRole.id)
            };
        }, {});
        const areRolesEmpty = Object.keys(rolesList).filter(roleName => (Boolean(rolesList[roleName]))).length === 0;
        const proposedRoleData = areRolesEmpty ? {} : rolesList;
        return {
            ...roleData,
            [application.id]: currentApplication.length > 0 ? proposedRoleData : undefined
        };
    }, {});
}

function userRoleDispatcher(state, action) {
    const { applications, edit, existingRoles, userRoles, projectId } = action;
    if (!action) {
        return state;
    }

    let roleData = prepareRoleData(applications, existingRoles);
    if (edit) {
        roleData = {
            ...state.roleData,
            ...userRoles
        };
    }

    return {
        roleData,
        projectApplications: applications.filter(application => application.project.id === projectId)
    };
}

export const UserRolesApplicationsCard: React.FC<UserRolesApplicationsCardProps> = ({
    forceLoading,
    onUpdate,
    existingRoles,
    applications: { applications, loading, error }
}) => {
    const { projectId } = useAuth();
    const [{ roleData, projectApplications }, updateRoleData] = React.useReducer(userRoleDispatcher, DEFAULT_STATE);
    React.useEffect(() => {
        if (applications) {
            updateRoleData({
                applications,
                existingRoles,
                projectId
            });
        }
    }, [applications, existingRoles]);

    if (loading) {
        return <Card><Spin /></Card>;
    }

    if (!existingRoles || !applications || !projectApplications || error) {
        return null;
    }
    return (
        <Form>
            <Card title={<FormattedMessage id="applicationsAndRoles" />}>
                <UserRolesApplicationsCardStyle>
                    {projectApplications.map((projectApplication) => {
                        const projectDetails = roleData[projectApplication.id];
                        return (
                            <Card.Grid
                                key={`${projectApplication.name}_${projectApplication.id}`}
                                style={{ width: `${100 / projectApplications.length}%` }}
                            >
                                <AccessWrapper>
                                    <Form.Item
                                        label={<h3>{projectApplication.name}</h3>}
                                        labelAlign="left"
                                        name={[projectApplication.id]}
                                    >
                                        <Switch
                                            checked={Boolean(projectDetails)}
                                            loading={forceLoading}
                                            onChange={switchState => {
                                                updateRoleData({
                                                    edit: true,
                                                    applications,
                                                    userRoles: {
                                                        [projectApplication.id]: switchState ? {} : undefined
                                                    },
                                                    projectId
                                                });
                                                onUpdate(
                                                    createNewApplications(
                                                        existingRoles,
                                                        {
                                                            action: switchState ? "add" : "delete",
                                                            id: projectApplication.id
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                </AccessWrapper>
                                <RolesWrapper direction="column">
                                    {projectApplication.roles.map(role => {
                                        return (
                                            <Form.Item
                                                key={`${projectApplication.id}_${role.id}_${role.name}`}
                                                noStyle
                                                dependencies={[projectApplication.id]}
                                            >
                                                {() => {
                                                    const isChecked = Boolean(projectDetails?.[role.id]);
                                                    return (
                                                        <Form.Item
                                                            initialValue={isChecked}
                                                            label={role.name}
                                                            name={[projectApplication.id, role.id]}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox
                                                                disabled={forceLoading || !projectDetails}
                                                                onChange={({ target }) => {
                                                                    updateRoleData({
                                                                        edit: true,
                                                                        applications,
                                                                        userRoles: {
                                                                            [projectApplication.id]: {
                                                                                [role.id]: target.checked
                                                                            }
                                                                        },
                                                                        projectId
                                                                    });
                                                                    onUpdate(
                                                                        createNewApplications(
                                                                            existingRoles,
                                                                            undefined,
                                                                            {
                                                                                action: target.checked ? "add" : "delete",
                                                                                applicationId: projectApplication.id,
                                                                                role: role.id
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    );
                                                }}
                                            </Form.Item>
                                        );
                                    })}
                                </RolesWrapper>
                            </Card.Grid>
                        );
                    })}
                </UserRolesApplicationsCardStyle>
            </Card>
        </Form>
    );
};
