import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { VoucherTable } from "@components/voucher/voucherTable";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { VoucherImportPageContainer } from "@pages/voucherImportPage/voucherImportPageContainer";
import { VoucherPageProps } from "@pages/voucherPage/voucherPageContainer";
import { VoucherPageStyle } from "@pages/voucherPage/voucherPageStyle";
import { mapToVoucherTableData } from "@utils/mappers/voucherMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Button, Card, message } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export const VouchersPage: React.FC<VoucherPageProps> = (props) => {
    const { history, vouchers, count, paging } = props;
    const intl = useIntl();

    const updateUrlQuery = useUpdateQuery({ replace: true });

    function updateTableSettings(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    }

    const vouchersTableData = React.useMemo(
        () => (vouchers?.vouchers || []).map(mapToVoucherTableData),
        [vouchers]
    );

    const exportVouchers = async () => {
        const baseFields = [
            "id",
            "code",
            "enableVoucherPeriod",
            "voucherStart",
            "voucherEnd",
            "enabled",
            "enabledMaxUsage",
            "maxUsage",
            "usageCount",
            "discount",
            "enabledMaxUsagePerUser",
            "maxUsagePerUser",
            "enabledOnChallenge",
            "enabledOnJourney",
            "assignments"
        ];

        const format = baseFields
            .concat(languages.map(({ code }) => `title${code[0].toUpperCase() + code.slice(1)}`))
            .join(";");
        try {
            await props.exportVoucher({ variables: { format } });
            message.success(intl.formatMessage({ id: "voucher.exportSuccess" }));
        } catch (e) {
            message.error(intl.formatMessage({ id: "voucher.exportFail" }));
        }
    };

    return (
        <VoucherPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <h1><FormattedMessage id="vouchers" /></h1>
                    <HeaderActions>
                        <VoucherImportPageContainer />
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={exportVouchers}
                        >
                            <FormattedMessage id="voucher.export" />
                        </Button>
                        <Button
                            ghost
                            className="headerButton"
                            type="primary"
                            onClick={
                                () => {
                                    history.push("/vouchers/add");
                                }
                            }
                        >
                            <FormattedMessage id="voucher.create" />
                        </Button>
                    </HeaderActions>
                </Flex>
            </HeaderContent>
̵̵̵
            <Card>
                <DynamicFilterBar
                    fields={[
                        {
                            type: "input",
                            search: true,
                            name: "textSearch",
                            placeholder: intl.formatMessage({ id: "filter.textSearch" })
                        }
                    ]}
                />
                <TableContainer
                    dataSource={vouchersTableData || []}
                    handlers={{
                        removeVoucher: (id) => props.removeVoucher({ variables: { id } })
                    }}
                    loading={vouchers && vouchers.loading}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count && count.voucherCount
                    }}
                    TableComponent={VoucherTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </VoucherPageStyle>
    );
};
