import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { GetEventTitlesQuery } from "@graphql2/types";
import { getText } from "@utils/getText";
import { validateHexColor } from "@utils/validators/validateHex";
import { Card, Form, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface GeneralSettingsCardProps {
    disabled?: boolean;
    onChange(): void;
    eventTitles: Omit<GetEventTitlesQuery["events"][0], "start" | "end">[]|undefined
}

export const GeneralSettingsCard: React.FC<GeneralSettingsCardProps> = (props) => {
    const { disabled, onChange, eventTitles } = props;
    const sharedProps = {
        disabled,
        onBlur: onChange
    };

    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="settings" />}
            />
            <Flex>
                <div>
                    <SelectField
                        name="events"
                        placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                        mode="multiple"
                        label={<FormattedMessage id="events" />}
                        id="events"
                        {...sharedProps}
                        disabled={!eventTitles || !eventTitles.length}
                    >
                        {(eventTitles && eventTitles.length)
                            && eventTitles.map(event => <Select.Option key={event.id} value={event.id}>{getText(event.title)}</Select.Option>)}
                    </SelectField>
                </div>
            </Flex>
            <Form.Item>
                <Field
                    name="subscriptionsCount"
                    label={<FormattedMessage id="eventsGroup.subscriptionLimit" />}
                    numberType
                    min={1}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Field
                    name="color"
                    label={<FormattedMessage id="eventsGroup.color" />}
                    rules={[
                        { validator: validateHexColor }
                    ]}
                />
            </Form.Item>
        </Card>
    );
};
