const isValidURL = (string: string) => {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

    return (res !== null);
};

export const urlValidation = (rule, value, callback) => {
    if (value && !isValidURL(value)) {
        callback("Enter a valid url");
    } else {
        callback();
    }
};
