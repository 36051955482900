import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Flex } from "@components/flex/flex";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { InjectedIntl } from "@utils/override.types";
import { Card, Checkbox, Form } from "antd";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface JourneyVisibilityFormValues {
    publishOn: Moment;
    expiresOn: Moment;
    comingSoon: boolean;
    hideInList: boolean;
}

export interface VisibilityCardProps {
    hidden?: boolean;
    disabled?: boolean;
    intl: InjectedIntl;
    onBlur(): void;
}

export class VisibilityCard extends React.Component<VisibilityCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { hidden, disabled, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="visibility" />}
                />
                <Flex>
                    <MarginRightDiv>
                        <DatePickerField
                            name="publishOn"
                            {...sharedProps}
                            label={<FormattedMessage id="publishOn" />}
                        />
                    </MarginRightDiv>
                    <div>
                        <DatePickerField
                            name="expiresOn"
                            rules={[
                                {
                                    validator: this.validateExpiresOn
                                }
                            ]}
                            {...sharedProps}
                            label={<FormattedMessage id="expiresOn" />}
                        />
                    </div>
                </Flex>
                <Form.Item
                    name="comingSoon"
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="comingSoon" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="hideInList"
                    rules={[{
                        transform: value => (value || undefined),
                        type: "boolean"
                    }]}
                    valuePropName="checked"
                >
                    <Checkbox {...sharedProps}>
                        <FormattedMessage id="hideInList" />
                    </Checkbox>
                </Form.Item>
            </Card>
        );
    }

    private validateExpiresOn(rule, value: Moment | undefined, callback: (error?: string) => void) {
        if (value && value.valueOf()) {
            return callback();
        }
        const { intl } = this.props;
        callback(intl.formatMessage({ id: "form.isrequired" }));
    }
}
