import styled from "styled-components";

export const OrderIconWrapper = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;

    .anticon {
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
`;
