import { DownloadOutlined, ExportOutlined, UploadOutlined } from "@ant-design/icons";
import { ExportConfigContainer } from "@components/export/exportConfigContainer";
import { Flex } from "@components/flex/flex";
import { formatName } from "@components/genericFields/utils";
import { HeaderContent } from "@components/headerContent/headerContent";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { JSONData, fetchedJson, validateData } from "@energylab/schematics/lib";
import { useImpersonateUserMutation } from "@graphql2/types";
import { SpinStyle } from "@pages/articlesPage/articlesPageStyle";
import { deepCleanEmptyValues } from "@utils/deepCleanEmptyValues";
import { readFile } from "@utils/readFile";
import { Badge, Button, Form, Modal, Spin, Tabs, Upload, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAuth } from "@context/authContext/context";
import { ProjectConfig } from "../../graphql2/types";
import { ConfigPageProps } from "./configPageContainer";
import { ConfigPageStyle } from "./configPageStyle";
import { MapFields } from "./mapFields";

type SchemasType = { [key: string]: JSONData; }

const disableElements = {};

export const ConfigPage: React.FC<ConfigPageProps> = (props) => {
    const { projectConfigs: { loading, projectConfigs }, createProjectConfig, updateProjectConfig, deleteProjectConfig, place, schemasNames, location, history } = props;

    const [activeKey, setActiveKey] = React.useState(schemasNames[0]);
    const [schemas, setSchemas] = React.useState<SchemasType>();
    const [unsaved, setUnsaved] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const [form] = useForm();
    const intl = useIntl();
    const { projectId, userId } = useAuth();

    const projectConfig = projectConfigs?.[0];

    const [getUrl, { loading: urlLoading }] = useImpersonateUserMutation();

    const config = React.useMemo(() => {
        const importDataURL = location.state?.import;

        if (importDataURL) {
            form.setFieldsValue(importDataURL.data);
            window.history.replaceState({}, document.title);
            setUnsaved(true);
            return importDataURL;
        }

        return projectConfig;
    }, [projectConfigs]);

    let configType = "theme";
    if (location.pathname.includes("main")) {
        configType = "main";
    } else if (location.pathname.includes("routes")) {
        configType = "routes";
    }

    React.useEffect(() => {
        const schemasPromisesArray = schemasNames.map((schemaName) => fetchedJson(schemaName));
        Promise.all(schemasPromisesArray).then(values => {
            const reducedValues = values.reduce<SchemasType>((object, value) => (value ? { ...object, ...value } : object), {});
            setSchemas(reducedValues);
        }).catch(e => {
            console.error(e);
            message.error(intl.formatMessage({ id: "error.fetchingSchema" }));
        });
    }, []);

    const handleUpload = async (info: { file: File; }) => {
        try {
            const importData = await readFile<ProjectConfig>(info.file);
            history.push(history.location.pathname, { import: importData });
        } catch (error) {
            message.error(intl.formatMessage({ id: "upload.tryAgain" }));
        }
    };

    if (loading) {
        return (
            <SpinStyle>
                <Spin />
            </SpinStyle>
        );
    }

    if (!schemas || !projectConfigs) {
        return null;
    }

    const validateValues = async (values) => {
        const filteredData = deepCleanEmptyValues(values);
        const data = JSON.parse(JSON.stringify(filteredData));
        const hasErrors = schemasNames.map(tab => {
            const schema = schemas[tab];
            if (!schema || disableElements[tab]) {
                return null;
            }
            return validateData(data, schema as JSON);
        }).some(response => response && !response?.valid);

        return { data, hasErrors };
    };

    return (
        <ConfigPageStyle>
            <Form
                form={form}
                initialValues={config?.data}
                onFinish={async (values) => {
                    const { data, hasErrors } = await validateValues(values);

                    if (hasErrors) {
                        message.error(intl.formatMessage({ id: "config.error.validation" }));
                        return;
                    }

                    if (isEmpty(data)) {
                        if (!projectConfig) {
                            setUnsaved(false);
                            return message.success(intl.formatMessage({ id: "config.remove.success" }));
                        }
                        const response = await (await deleteProjectConfig({ variables: { configId: projectConfig?.id } }));
                        if (response.errors || !response.data?.removeProjectConfig.success) {
                            message.error(intl.formatMessage({ id: "config.remove.error" }));
                            return;
                        }
                        message.success(intl.formatMessage({ id: "config.remove.success" }));
                        return;
                    }
                    const request = projectConfig
                        ? await updateProjectConfig({ variables: { configId: projectConfig?.id, data } })
                        : await createProjectConfig({ variables: { place, data } });

                    if (request.errors) {
                        console.error(request.errors);
                        message.error(intl.formatMessage({ id: "config.save.error" }));
                        return null;
                    }
                    message.success(intl.formatMessage({ id: "config.save.success" }));
                    setUnsaved(false);
                }}
                onFinishFailed={() => {
                    message.error(intl.formatMessage({ id: "config.fields.error" }));
                }}
                onValuesChange={() => !unsaved && setUnsaved(true)}
            >
                <HeaderContent>
                    <Flex justifyContent="space-between">
                        <Flex gap="10">
                            <FormattedMessage
                                id="config"
                                tagName="h1"
                            />
                            <FormattedMessage
                                id={configType}
                                tagName="h1"
                            />
                        </Flex>
                        <Flex
                            alignItems="baseline"
                            gap={10}
                        >
                            <Form.Item
                                noStyle
                                shouldUpdate
                            >
                                {() => (
                                    <Button
                                        disabled={isEmpty(form.getFieldsValue())}
                                        icon={<ExportOutlined />}
                                        loading={urlLoading}
                                        type="ghost"
                                        onClick={async () => {
                                            try {
                                                const values = await form.validateFields();
                                                const { data, hasErrors } = await validateValues(values);

                                                if (hasErrors) {
                                                    message.error(intl.formatMessage({ id: "config.error.validation" }));
                                                    return;
                                                }

                                                if (!data) {
                                                    return;
                                                }

                                                const stringified = JSON.stringify(data);
                                                const response = await getUrl({
                                                    variables: {
                                                        applicationId: "frontend",
                                                        projectId: projectId || "",
                                                        userId: userId || ""
                                                    }
                                                });
                                                const url = response?.data?.impersonateUser.applicationUrl;

                                                if (url) {
                                                    const { origin } = new URL(url);
                                                    window.open(`${origin}?${place}Config=${encodeURI(stringified)}`, "_blank");
                                                }
                                            } catch (e) {
                                                message.error(intl.formatMessage({ id: "somethingWentWrong" }));
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="config.liveExample" />
                                    </Button>
                                )}
                            </Form.Item>
                            <Upload
                                accept="application/json"
                                customRequest={handleUpload}
                            >
                                <Button
                                    className="headerButton"
                                    icon={<UploadOutlined />}
                                    type="primary"
                                >
                                    <FormattedMessage id="import" />
                                </Button>
                            </Upload>
                            <Modal
                                footer={[
                                    <Button onClick={() => setIsModalOpen(false)}>
                                        <FormattedMessage id="cancel" />
                                    </Button>,
                                    <ExportConfigContainer
                                        buttonDownload
                                        id={configType}
                                        onFinish={() => setIsModalOpen(false)}
                                    />
                                ]}
                                visible={isModalOpen}
                                onCancel={() => setIsModalOpen(false)}
                                onOk={() => setIsModalOpen(false)}
                            >
                                <FormattedMessage id="config.export.modal.content" />
                            </Modal>
                            <Button
                                disabled={!projectConfigs?.[0]}
                                icon={<DownloadOutlined />}
                                onClick={() => setIsModalOpen(true)}
                            >
                                <FormattedMessage id="export" />
                            </Button>
                            <div>
                                {unsaved && <UnsavedChanges key="warning" />}
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                >
                                    <FormattedMessage id="saveChanges" />
                                </Button>
                            </div>
                        </Flex>
                    </Flex>
                    <Tabs
                        activeKey={activeKey}
                        onChange={setActiveKey}
                    >
                        {schemasNames.map((tab) => {
                            const keys = Object.entries(schemas[tab]?.properties ?? []).map(([key]) => key);
                            return (
                                <Tabs.TabPane
                                    key={tab}
                                    tab={(
                                        <Form.Item
                                            noStyle
                                            shouldUpdate
                                        >
                                            {() => {
                                                const fields = form.getFieldsError().filter(field => field.name.some(n => keys.includes(n.toString())));
                                                const haveError = fields.some(field => Boolean(field.errors.length));
                                                return (
                                                    <Badge dot={haveError}>
                                                        {formatName(tab)}
                                                    </Badge>
                                                );
                                            }}
                                        </Form.Item>
                                    )}
                                />
                            );
                        })}
                    </Tabs>
                </HeaderContent>
                {schemasNames.map(tab => {
                    const tabSchema = schemas[tab];
                    if (!tabSchema) {
                        return null;
                    }
                    return (
                        <MapFields
                            key={tab}
                            disableElements={disableElements}
                            form={form}
                            hidden={tab !== activeKey}
                            initialValues={config?.data}
                            intl={intl}
                            schema={tabSchema}
                            setWarningUnsavedChanges={setUnsaved}
                            tabName={tab}
                        />
                    );
                })}
            </Form>
        </ConfigPageStyle>
    );
};
