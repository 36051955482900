import { UsersTableData } from "@components/users/usersTable";
import { GetUsersOverviewQuery } from "@graphql2/types";

export const mapUserOverview = (users: GetUsersOverviewQuery["users"]): UsersTableData[] => {
    return users.map(u => (u && {
        id: u.id,
        avatar: u.avatar ?? undefined,
        name: u.givenName && u.familyName ? `${u.givenName} ${u.familyName}` : "",
        email: u.email,
        lastLogin: Number(u.lastLogin),
        roles: (u.applications && u.applications[0] && u.applications[0].roles.map(r => r.name)) || [],
        blocked: Boolean(u.locked),
        providers: (u.providers && u.providers.map(p => p && p.provider as string)) || []
    }));
};
