import { ContentHeader } from "@components/contentHeader/contentHeader";
import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { TagsTableData } from "@components/tags/tagsTable";
import { AdminTooltip } from "@components/tooltip";
import { ApplicationFragment, ChronoRaceEventFragment } from "@graphql2/types";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { InjectedIntl } from "@utils/override.types";
import { Card, Checkbox, Form, Select, message } from "antd";
import { FormInstance } from "antd/lib/form";
import { autobind } from "core-decorators";
import * as moment from "moment";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTIVITY_TYPES } from "../../../constants";
import { EventSettingsGroup, SelectWrapper } from "../addEventsPageStyle";
import { momentValueComparison } from "../dateValidator/dateValidator";
import { LimitedFlexCell, StyledFormItem } from "../dateValidator/dateValidatorStyle";

export interface EventSettingsFormValues {
    subscriptionEnabled: boolean;
    viewParticipantCount: boolean;
    viewParticipants: boolean;
    enableWaitingList: boolean;
    start: Moment;
    end: Moment;
    registration: {
        start: Moment;
        end: Moment;
    };
    publishedOn: Moment;
    expiresOn: Moment;
    activityTypes: string[];
    hideInList: boolean;
    disableComments: boolean;
    roles?: string[] | null;
    tags?: string[];
    organizer: string;
    url: string;
    chronoraceEventId?: number;
}

export interface EventSettingsCardProps {
    intl: InjectedIntl;
    form: FormInstance;
    defaultValues: Partial<EventSettingsFormValues>;
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    roles: ApplicationFragment["roles"];
    eventTags: TagsTableData[];
    chronoRaceId?: number;
    superAdmin: boolean;
    chronoRaceEvents: ChronoRaceEventFragment[];
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
    setChronoRaceIdForm: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type SharedProps = {
    disabled?: boolean;
    onBlur(): void;
}

export interface EventSettingsCardState {

}

@autobind
export class EventSettingsCard extends React.Component<EventSettingsCardProps, EventSettingsCardState> {
    public render() {
        const { hidden, disabled, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="settings" />}
                />
                <Flex
                    className="eventSettings"
                    direction="column"
                >
                    <EventSettingsGroup>
                        <FormattedMessage
                            id="dateAndTimes"
                            tagName="h3"
                        />
                        {this.renderDateAndTimes(sharedProps)}
                    </EventSettingsGroup>
                    <EventSettingsGroup>
                        <FormattedMessage
                            id="activityTypes"
                            tagName="h3"
                        />
                        {this.renderActivityTypes(sharedProps)}
                    </EventSettingsGroup>
                    <EventSettingsGroup>
                        <FormattedMessage
                            id="other"
                            tagName="h3"
                        />
                        {this.renderOther(sharedProps)}
                    </EventSettingsGroup>
                </Flex>
            </Card>
        );
    }

    private renderDateAndTimes(sharedProps) {
        const { form, intl, disabled } = this.props;
        return (
            <Flex direction="column">
                <Flex
                    alignItems="stretch"
                    margin="0 20px 0"
                >
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            label={<FormattedMessage id="start" />}
                            name="start"
                            rules={[
                                requiredrule,
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["end"]
                                        ],
                                        "",
                                        "before",
                                        undefined,
                                        "minutes"
                                    )
                                }
                            ]}
                        />
                    </LimitedFlexCell>
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            label={<FormattedMessage id="end" />}
                            name="end"
                            rules={[
                                requiredrule,
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["start"]
                                        ],
                                        "",
                                        "after",
                                        undefined,
                                        "minutes"
                                    )
                                }
                            ]}
                        />
                    </LimitedFlexCell>
                    <Form.Item dependencies={[["start"], ["end"], ["durationValidator"]]}>
                        {() => {
                            form.validateFields([["start"], ["end"], ["durationValidator"]]);
                            return (
                                <StyledFormItem
                                    name={["durationValidator"]}
                                    rules={
                                        disabled
                                            ? [
                                                {
                                                    validator: momentValueComparison(
                                                        form,
                                                        [
                                                            ["start"]
                                                        ],
                                                        intl.formatMessage({ id: "event.duration.validation" }),
                                                        "after",
                                                        ["end"]
                                                    )
                                                }
                                            ]
                                            : []
                                    }
                                />
                            );
                        }}
                    </Form.Item>
                </Flex>
                <Flex
                    alignItems="stretch"
                    margin="0 20px 0"
                >
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            dependencies={[["start"]]}
                            label={<FormattedMessage id="registration.start" />}
                            name={["registration", "start"]}
                            rules={[
                                requiredrule,
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["registration", "end"]
                                        ],
                                        "",
                                        "before"
                                    )
                                },
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["start"]
                                        ],
                                        intl.formatMessage({ id: "event.register.from.validation" }),
                                        "before"
                                    )
                                }
                            ]}
                        />
                    </LimitedFlexCell>
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            dependencies={[["end"]]}
                            label={<FormattedMessage id="registration.end" />}
                            name={["registration", "end"]}
                            rules={[
                                requiredrule,
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["registration", "start"]
                                        ],
                                        "",
                                        "after"
                                    )
                                },
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["end"]
                                        ],
                                        intl.formatMessage({ id: "event.register.until.validation" }),
                                        "before"
                                    )
                                }
                            ]}
                        />
                    </LimitedFlexCell>
                    <Form.Item dependencies={[["registration", "start"], ["registration", "end"], ["registration", "validator"]]}>
                        {() => {
                            form.validateFields([["registration", "start"], ["registration", "end"], ["registration", "validator"]]);
                            return (
                                <StyledFormItem
                                    name={["registration", "validator"]}
                                    rules={
                                        disabled
                                            ? [
                                                {
                                                    validator: momentValueComparison(
                                                        form,
                                                        [
                                                            ["registration", "start"]
                                                        ],
                                                        intl.formatMessage({ id: "event.register.start.end" }),
                                                        "after",
                                                        ["registration", "end"]
                                                    )
                                                }]
                                            : []
                                    }
                                />
                            );
                        }}
                    </Form.Item>
                </Flex>
                <Flex
                    alignItems="stretch"
                    margin="0 20px 0"
                >
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            dependencies={[["expiresOn"], ["registration", "start"], ["start"]]}
                            label={<FormattedMessage id="publishOn" />}
                            name="publishedOn"
                            rules={[
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["start"],
                                            ["registration", "start"]
                                        ],
                                        intl.formatMessage({ id: "event.visible.from.validation" }),
                                        "before"
                                    )
                                },
                                requiredrule
                            ]}
                        />
                    </LimitedFlexCell>
                    <LimitedFlexCell>
                        <DatePickerField
                            {...sharedProps}
                            dependencies={[["publishOn"], ["registration", "end"], ["end"]]}
                            label={<FormattedMessage id="expiresOn" />}
                            name="expiresOn"
                            rules={[
                                {
                                    validator: momentValueComparison(
                                        form,
                                        [
                                            ["end"],
                                            ["registration", "end"]
                                        ],
                                        intl.formatMessage({ id: "event.visible.until.validation" }),
                                        "after"
                                    )
                                },
                                requiredrule
                            ]}
                        />
                    </LimitedFlexCell>
                </Flex>
            </Flex>
        );
    }

    private renderActivityTypes(sharedProps: SharedProps) {
        return (
            <Flex>
                <div>
                    <SelectField
                        {...sharedProps}
                        id="activityTypes"
                        label={<FormattedMessage id="activityTypes" />}
                        mode="multiple"
                        name="activityTypes"
                        rules={[requiredrule]}
                    >
                        {ACTIVITY_TYPES.map((activityType, key) => (
                            <Select.Option
                                // eslint-disable-next-line react/no-array-index-key
                                key={key.toString()}
                                value={activityType.key}
                            >
                                {activityType.value}
                            </Select.Option>
                        ))}
                    </SelectField>
                </div>
            </Flex>
        );
    }

    private renderOther(sharedProps: SharedProps) {
        const { chronoRaceEvents, chronoRaceId, superAdmin, intl } = this.props;

        return (
            <Flex direction="column">
                <Flex>
                    <div>
                        <Field
                            {...sharedProps}
                            key="organizer"
                            id="organizer}"
                            label={<FormattedMessage id="organizer" />}
                            name="organizer"
                            rules={[requiredrule]}
                        />
                    </div>
                </Flex>
                <Flex>
                    <div>
                        <Form.Item
                            noStyle
                            shouldUpdate
                        >
                            {formInstance => {
                                const shouldDisable = Boolean(formInstance.getFieldValue(["projects", "0", "subscriptionEnabled"]));
                                return (
                                    <Field
                                        {...sharedProps}
                                        key="url"
                                        disabled={shouldDisable || sharedProps.disabled}
                                        id="url"
                                        label={
                                            (
                                                <Flex>
                                                    <MarginRightDiv>
                                                        <FormattedMessage id="url" />
                                                    </MarginRightDiv>
                                                    <AdminTooltip
                                                        description={<FormattedMessage id="event.url.tooltip.description" />}
                                                        header={<FormattedMessage id="event.url.tooltip.header" />}
                                                        placement="left"
                                                        type="info"
                                                    />
                                                </Flex>
                                            )
                                        }
                                        name="url"
                                        onClick={() => shouldDisable && message.warning(intl.formatMessage({ id: "event.url.disable.warning" }))}
                                    />

                                );
                            }}
                        </Form.Item>
                    </div>
                </Flex>
                <Flex>
                    <Form.Item
                        name="hideInList"
                        valuePropName="checked"
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="hideInList" />
                        </Checkbox>
                    </Form.Item>
                </Flex>
                <Flex>
                    <Form.Item
                        name="disableComments"
                        valuePropName="checked"
                    >
                        <Checkbox {...sharedProps}>
                            <FormattedMessage id="disableComments" />
                        </Checkbox>
                    </Form.Item>
                </Flex>
                <Flex>
                    {superAdmin && chronoRaceEvents && (
                        <React.Fragment>
                            <SelectWrapper>
                                <SelectField
                                    {...sharedProps}
                                    allowClear
                                    initialValue={chronoRaceId ?? null}
                                    label={<FormattedMessage id="event.chronoraceEvents" />}
                                    name="chronoraceEventId"
                                    placeholder={<FormattedMessage id="event.chronoraceEvent" />}
                                    onChange={value => this.props.setChronoRaceIdForm(value ? Number(value) : undefined)}
                                >
                                    {chronoRaceEvents.map(r => {
                                        if (!r.id) {
                                            return null;
                                        }

                                        return (
                                            <Select.Option
                                                key={r.id}
                                                value={r.id}
                                            >
                                                {moment(r.start).format("MMMM, DD YYYY")}
                                                {" - "}
                                                {r.title}
                                            </Select.Option>
                                        );
                                    })}
                                </SelectField>
                            </SelectWrapper>
                            <AdminTooltip
                                customStyles={{ padding: "1px 5px" }}
                                description={<FormattedMessage id="warning.chronraceClearDisabled" />}
                                hide={!chronoRaceId}
                                type="warning"
                            />
                        </React.Fragment>
                    )}
                </Flex>
            </Flex>
        );
    }
}
