import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm } from "antd";
import Column from "antd/lib/table/Column";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableComponentProps, TableHandlers } from "@components/table/tableContainer";
import { Flex } from "@components/flex/flex";
import { TableIcon } from "@components/table/tableStyle";
import { WhiteListTableData } from "pages/whiteListPage/whiteListPage";
import { PlainButton } from "@components/style/globalStyles";



export interface WhiteListTableProps extends TableComponentProps {
    dataSource: WhiteListTableData[];
    handlers: TableHandlers & {
        selectWhiteList(whiteList: WhiteListTableData): void;
        removeUserWhiteList(group?: string | null): void;
    };
}

export const WhiteListTable: React.FC<WhiteListTableProps> = ({ handlers, ...tableProps }) => {
    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="group"
                title={<FormattedMessage id="group" />}
                dataIndex="group"
                render={(text, row: WhiteListTableData) => {
                    return {
                        children: (
                            <PlainButton onClick={() => handlers.selectWhiteList(row)}>{text}</PlainButton>
                        )
                    };
                }}
            />
            <Column
                key="whitelist"
                title={<FormattedMessage id="whitelist" />}
                dataIndex="whitelist"
            />
            <Column
                key="roles"
                title={<FormattedMessage id="roleIds" />}
                dataIndex="roles"
                render={(text) => text.join(", ")}
            />
            <Column
                key="companyId"
                title={<FormattedMessage id="companyId" />}
                dataIndex="companyId"
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(text, row: WhiteListTableData) => ({
                    children: (
                        <Flex justifyContent="flex-end" alignItems="center">
                            <TableIcon as={EditOutlined} onClick={() => handlers.selectWhiteList(row)} />
                            <Popconfirm
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={() => handlers.removeUserWhiteList(row.group)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <TableIcon as={DeleteOutlined} />
                            </Popconfirm>
                        </Flex>
                    ),
                    props: {
                        colSpan: 2
                    }

                })}
            />
        </Table>
    );
};
