import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ArticleLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface TitleAndDescriptionFormValues {
    title: LanguageObject;
    description: LanguageObject;
}

export interface TitleAndDescriptionCardProps {
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onChange(): void;
}

const titleField: React.FC<ArticleLanguageFieldProps> = (props) => (
    <Field
        name={["title", props.language]}
        key={`title.${props.language}`}
        label={<FormattedMessage id="title" />}
        hidden={props.hidden}
        info={maxCharacters()}
        id={`title.${props.language}`}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

const descriptionField = (props: ArticleLanguageFieldProps) => (
    <Field
        name={["description", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        key={`description.${props.language}`}
        label={<FormattedMessage id="description" />}
        hidden={props.hidden}
        info={maxCharacters()}
        id={`description.${props.language}`}
        {...props.sharedProps}
    />
);

export const TitleAndDescriptionCard: React.FC<TitleAndDescriptionCardProps> = (props) => {
    const { changeSelectedLanguage, languageErrors, disabled, onChange, activeLanguage } = props;
    const sharedProps = {
        disabled,
        onBlur: onChange
    };

    const langFieldProps = languages.map(language => ({ sharedProps, defaultValues: {}, language: language.code, hidden: language.code !== activeLanguage }));

    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="titleAndDescription" />}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
                errors={languageErrors}
            />
            {langFieldProps.map(titleField)}
            {langFieldProps.map(descriptionField)}
        </Card>
    );
};
