import * as React from "react";
import { compose } from "redux";
import { withGetChallengesDetail, GetChallengesDetailProps } from "@graphql2/types";
import { exportFactory, ExportProps } from "./export";
import { ExportContainerProps, getByIdQuery, withLocalState } from "./exportContainer";

export const withChallenge = withGetChallengesDetail(getByIdQuery);

export const ExportChallengeContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withChallenge
)(exportFactory({ targetProp: ["data", "challenges"] }));

export type ExportChallengeContainerprops = ExportContainerProps & MaybeElements<GetChallengesDetailProps>;
