import styled from "styled-components";
import { theme } from "@style/theme";
import { DislikeOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";

export const AddGalleriesPageStyle = styled.div`
    h3 {
        color: #A0C63D;
        margin: 15px 0 0 0;
        font-weight: 600;
    }

    .ant-form > .ant-alert {
        margin: 26px 39px;
    }

    td {
        vertical-align: middle;
    }

    .imageSelect {
        width: 100%;

        .ant-select-selector {
            height: 50px !important;
        }
    }
`;

export const GalleryImageOverviewImageStyle = styled.img`
    width: 272px;
    margin-right: 40px;
`;

export const DislikeIcon = styled(DislikeOutlined)`
    color: ${() => theme.colors.error};
    font-size: 15px;
    padding: 5px;
    border: 2px solid ${() => theme.colors.error};
    border-radius: 50%;
`;

export const SwitchWrapper = styled(Flex)`
    margin-top: 15px;
`;
