import { HeaderContent } from "@components/headerContent/headerContent";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { getText } from "@utils/getText";
import { LanguageObject } from "@utils/toLanguageObject";
import { Breadcrumb, Button, Popconfirm } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

type EventGroupPageHeaderProps = {
    isSaving: boolean;
    enabledSave: boolean;
    groupTitle: LanguageObject;
    activeLanguage: string;
    removeEventCb?: () => Promise<void>;
}

export const EventGroupPageHeader: React.FC<EventGroupPageHeaderProps> = (props) => {
    const { isSaving, enabledSave, groupTitle, activeLanguage, removeEventCb } = props;
    return (
        <HeaderContent>
            <HeaderActions>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/event/groups">
                            <FormattedMessage id="overview" />
                        </Link>
                    </Breadcrumb.Item>
                    {Boolean(groupTitle) && (
                        <Breadcrumb.Item>
                            {getText(groupTitle, activeLanguage)}
                        </Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <div>
                    {enabledSave && <UnsavedChanges key="warning" />}
                    <Button loading={isSaving} className="headerButton" type="primary" htmlType="submit">
                        <FormattedMessage id="saveChanges" />
                    </Button>
                    {removeEventCb && (
                        <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={removeEventCb} okText="Yes" cancelText="No">
                            <Button className="headerButton" type="ghost">
                                <FormattedMessage id="eventsGroup.delete" />
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </HeaderActions>
        </HeaderContent>
    );
};
