import { OrdersPage } from "@pages/ordersPage/ordersPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
// import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";

import {
    Article,
    ArticleOrderFilter,
    GetArticlesProps,
    GetOrdersCountProps,
    GetOrdersProps,
    GetUsersProps,
    User,
    withGetArticles,
    withGetOrders,
    withGetOrdersCount,
    withGetUsers
} from "../../graphql2/types";

export interface OrderPageContainerProps {

}

interface DynamicFilterBarFields {
    userSearch?: string;
    articleSearch?: string;
}

interface OrderPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: DynamicFilterBarFields;
}

export const withOrders = withGetOrders({
    name: "orders",
    options({ paging, sort, filter }: OrdersPageProps) {
        const { articleIds, userIds } = filter as ArticleOrderFilter;
        return {
            variables: {
                filter: {
                    articleIds,
                    userIds
                },
                paging,
                sort
            }
        };
    }

});

export const withOrdersCount = withGetOrdersCount({
    name: "count",
    options({ filter }: OrdersPageProps) {
        const { articleIds, userIds } = filter as ArticleOrderFilter;

        return {
            variables: {
                filter: {
                    articleIds,
                    userIds
                }
            }
        };
    }
});

const withArticles = withGetArticles({
    name: "articles",
    skip({ filter }: OrdersPageProps) {
        return !filter?.articleSearch;
    },
    options({ filter }: OrdersPageProps) {
        const articleSearch = filter?.articleSearch;

        //  article search looks in title/description
        return {
            variables: {
                filter: {
                    search: articleSearch
                }
            }
        };
    }
});

const withUsers = withGetUsers({
    name: "users",
    skip({ filter }: OrdersPageProps) {
        return !filter?.userSearch;
    },
    options({ filter }: OrdersPageProps) {
        const userSearch = filter?.userSearch;

        //  userSearch looks in name/emails
        return {
            variables: {
                filter: {
                    userSearch
                }
            }
        };
    }
});

const withFilterDataAdjusted = () => {
    return (Component) => {
        return (props) => {
            const newFilter = props.filter;

            if (props.users?.users?.length) {
                const usersFitlered = props.users.users as User[];
                const usersFilteredIds = usersFitlered.map((user) => user.id);
                newFilter.userIds = usersFilteredIds;
            }

            if (props.articles?.articles?.length) {
                const articlesFiltered = props.articles.articles as Article[];
                const articlesFilteredIds = articlesFiltered.map((article) => article.id);
                newFilter.articleIds = articlesFilteredIds;
            }

            const newProps = { ...props, filter: newFilter as ArticleOrderFilter };

            return <Component {...newProps} />;
        };
    };
};

// TODO: there is no mutation for remove order - needs to be added in GQL

export const OrdersPageContainer = compose<React.ComponentClass<OrderPageContainerProps>>(
    withRouter,
    // TODO sorting won't work - we need a field to sort by on order's type
    withParseDynamicFilterBar(undefined, { defaultSortSettings: { sortBy: "id", sort: "DESC" } }),
    withArticles,
    withUsers,
    withFilterDataAdjusted(),
    withOrders,
    withOrdersCount
)(OrdersPage);

export type OrdersPageProps =
    RouteComponentProps &
    OrderPageFilterBarProps &
    GetArticlesProps<{}, "articles"> &
    GetUsersProps<{}, "users"> &
    GetOrdersProps<{}, "orders"> &
    GetOrdersCountProps<{}, "count"> &
    OrderPageContainerProps;

