import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { ExportVoucherProps, GetVoucherCountProps, GetVouchersProps, RemoveVoucherProps, VoucherFilter, withExportVoucher, withGetVoucherCount, withGetVouchers, withRemoveVoucher } from "@graphql2/types";
import { VouchersPage } from "@pages/voucherPage/voucherPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface VoucherPageContainerProps {
    id: string;
}

interface VouchersPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: VoucherFilter;
}

const withVoucherCount = withGetVoucherCount({
    name: "count",
    options({ filter }: VoucherPageProps) {
        return {
            variables: {
                filter
            }
        };
    }
});

const withExportVoucherMutation = withExportVoucher({
    name: "exportVoucher"
});

const withVouchers = withGetVouchers({
    name: "vouchers",
    options({ paging, sort, filter }: VoucherPageProps) {
        return {
            variables: {
                filter,
                paging,
                sort
            }
        };
    }
});

const withRemoveVoucherMutation = withRemoveVoucher({
    name: "removeVoucher",
    options: {
        refetchQueries: () => ["getVouchers", "getVoucherCount"],
        awaitRefetchQueries: true
    }
});

export const VouchersPageContainer = compose<React.ComponentClass<VoucherPageContainerProps>>(
    withRouter,
    withParseDynamicFilterBar(undefined, { defaultSortSettings: { sortBy: "voucherStart", sort: "DESC" } }),
    withVouchers,
    withVoucherCount,
    withRemoveVoucherMutation,
    withExportVoucherMutation
)(VouchersPage);

export type VoucherPageProps =
    VoucherPageContainerProps &
    VouchersPageFilterBarProps &
    RouteComponentProps &
    RemoveVoucherProps<{}, "removeVoucher"> &
    GetVoucherCountProps<{}, "count"> &
    ExportVoucherProps<{}, "exportVoucher"> &
    GetVouchersProps<{}, "vouchers">;
