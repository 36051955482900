import { InstagramFeedTableData } from "@components/social/instagramFeedTable";
import { TwitterFeedTableData } from "@components/social/twitterFeedTable";
import { TwitterFeedFragment, InstagramFeedFragment } from "@graphql2/types";

export const instagramFeedTableMapper = (originals: InstagramFeedFragment[]): InstagramFeedTableData[] => {
    return originals.map(feed => {
        const { id, user, text, visible, media, date, sourceUrl } = feed;

        return {
            id,
            date,
            caption: text || "",
            visible: Boolean(visible),
            user: (user && user.name) || "/",
            image: (media && media[0] && media[0].url) || "",
            link: sourceUrl
        };
    });
};

export const twitterFeedTableMapper = (originals: TwitterFeedFragment[]): TwitterFeedTableData[] => {
    return originals.map(feed => {
        const { id, user, text, visible, sourceUrl, date } = feed;

        return {
            id,
            date,
            tweet: text || "",
            visible: Boolean(visible),
            user: (user && user.name) || "/",
            link: sourceUrl
        };
    });
};
