/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { FormInstance } from "antd/lib/form";
import { Card, Button, Form, Table, Select } from "antd";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { FormattedMessage } from "react-intl";
import { Flex } from "@components/flex/flex";
import { TableIcon } from "@components/table/tableStyle";
import { DeleteOutlined } from "@ant-design/icons";
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { SelectField } from "@components/field/selectField";
import { ApplicationFragment } from "@graphql2/types";
import { CardInfo } from "@components/style/globalStyles";

interface VoucherAssignmentsCardProps {
    form: FormInstance;
    roles: ApplicationFragment["roles"];
}

export const VoucherAssignmentsCard: React.FC<VoucherAssignmentsCardProps> = (props) => {
    const { form, roles } = props;

    const addNewAssignment = (add: (data) => void) => {
        add({
            type: "",
            credits: 0,
            roles: []
        });
    };

    const renderHandlers = (row, remove: (index) => void) => {
        return (
            <Flex justifyContent="flex-end" alignItems="center">
                <a onClick={() => remove(row.index)}>
                    <TableIcon as={DeleteOutlined} />
                </a>
            </Flex>
        );
    };

    const renderTypeField = (row) => {
        return (
            <Field
                name={[row.index, "type"]}
                rules={[requiredrule]}
            />
        );
    };

    const renderCreditField = (row) => {
        return (
            <Field
                name={[row.index, "credits"]}
                numberType
                rules={[requiredrule]}
            />
        );
    };

    const renderRolesField = (row) => {
        return (
            <SelectField
                name={[row.index, "roles"]}
                mode="multiple"
                id="voucher.assignmentsRoles"
                rules={[requiredrule]}
            >
                {
                    roles.map(({ id, name }) => (
                        <Select.Option
                            value={id}
                            key={id}
                        >
                            {name}
                        </Select.Option>
                    ))
                }
            </SelectField>
        );
    };

    return (
        <Card>
            <Form.List name={["assignments"]}>
                {(fields, { add, remove }) => {
                    const dataSource = (form.getFieldValue("assignments") || []).map((a, i) => ({
                        index: i,
                        type: a.type,
                        credits: a.credits,
                        roles: a.roles
                    }));
                    return (
                        <React.Fragment>
                            <ContentHeader
                                title={<FormattedMessage id="voucher.assignments" />}
                                subTitle={(
                                    <CardInfo>
                                        <FormattedMessage id="voucher.assignments.info" />
                                    </CardInfo>
                                )}
                                rightFields={[
                                    <Button onClick={() => addNewAssignment(add)} type="primary"><FormattedMessage id="addNewRow" /></Button>
                                ]}
                            />
                            <Table dataSource={dataSource}>
                                <Table.Column
                                    key="type"
                                    title={<FormattedMessage id="voucher.assignmentType" />}
                                    dataIndex="type"
                                    width={250}
                                    render={(_, row) => renderTypeField(row)}
                                />
                                <Table.Column
                                    key="credits"
                                    title={<FormattedMessage id="voucher.assignmentCredits" />}
                                    dataIndex="credits"
                                    width={50}
                                    render={(_, row) => renderCreditField(row)}
                                />
                                <Table.Column
                                    key="roles"
                                    title={<FormattedMessage id="voucher.assignmentsRoles" />}
                                    dataIndex="roles"
                                    render={(_, row) => renderRolesField(row)}
                                />
                                <Table.Column
                                    title=""
                                    key="handlers"
                                    dataIndex=""
                                    width={50}
                                    render={(_, row) => renderHandlers(row, remove)}
                                />
                            </Table>
                        </React.Fragment>
                    );
                }}
            </Form.List>

        </Card>
    );
};
