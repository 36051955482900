import { JSONData } from "@energylab/schematics";
import { InjectedIntl } from "@utils/override.types";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib/form";
import { omit } from "lodash";
import React from "react";
import { AddNewKey } from "./additionalComponents/addNewKey";
import { RemoveWrapper } from "./fieldWrappers/removeWrapper";
import { FieldName, FieldsMapper } from "./fieldsMapper";

interface KeyValueMapperProps {
    form: FormInstance;
    namePath: (string | number)[];
    fieldName: string | number;
    name: FieldName;
    jsonData: JSONData;
    intl: InjectedIntl;
    disable: boolean;
}

export const KeyValueMapper: React.FC<KeyValueMapperProps> = (props) => {
    const { form, namePath, name, fieldName, jsonData, intl, disable } = props;

    const [keys, setKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (disable) {
            setKeys([]);
        } else {
            setKeys(Object.entries(form.getFieldValue([...namePath, fieldName]) ?? []).map(([key]) => key));
        }
    }, [disable, name]);

    return (
        <Form.Item
            dependencies={[[...namePath, fieldName]]}
        >
            {
                () => (
                    <React.Fragment>
                        {keys.map((key) => (
                            <RemoveWrapper
                                onRemove={() => {
                                    setKeys(keys.filter(k => k !== key));
                                    const omitted = omit(form.getFieldValue([...namePath, fieldName]), [], key);
                                    form.setFields([{ name: [...namePath, fieldName], value: omitted }]);
                                }}
                            >
                                <FieldsMapper
                                    key={key}
                                    required
                                    disable={disable}
                                    form={form}
                                    intl={intl}
                                    jsonData={jsonData.additionalProperties as JSONData}
                                    name={[...name, key]}
                                    namePath={fieldName !== undefined ? [...namePath, fieldName] : namePath}
                                />
                            </RemoveWrapper>
                        ))}
                        <AddNewKey
                            addNew={(key) => {
                                if (keys.some(k => key === k)) {
                                    message.error("Key already exists");
                                    return;
                                }
                                setKeys([...keys, key]);
                            }}
                            disable={disable}
                            enumArray={jsonData.propertyNames?.enum}
                            intl={intl}
                            keys={keys}
                        />
                    </React.Fragment>
                )
            }
        </Form.Item>
    );
};
