import { getFormTemplatesOverview } from "@graphql2/graphql_op";
import { AddFormTemplatesPage } from "@pages/addFormTemplatesPage/addFormTemplatesPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { AddFormTemplateProps, FormTemplateFragment, GetFormTemplatesProps, RemoveFormTemplateProps, UpdateFormTemplateProps, withAddFormTemplate, withGetFormTemplates, withRemoveFormTemplate, withUpdateFormTemplate } from "../../graphql2/types";

export interface AddFormTemplatesPageContainerProps {
    editMode?: boolean;
}

type AddFormTemplateRouteProps = RouteComponentProps<{ id: string; }, {}, { import?: FormTemplateFragment; }>;

export const withFormTemplates = withGetFormTemplates({
    name: "formTemplatesQueryResults",
    options: (props: AddFormTemplateRouteProps) => {
        return {
            variables: {
                filter: {
                    ids: props.match.params.id ? [props.match.params.id] : [],
                    type: "event"
                }
            }
        };
    },
    skip: (props: AddFormTemplateRouteProps) => {
        return !props.match.params.id;
    }
});

const withAddFormTemplateMutation = withAddFormTemplate({
    name: "addFormTemplate",
    options: {
        update: cleanCache([getFormTemplatesOverview])
    }
});

const withUpdateFormTemplateMutation = withUpdateFormTemplate({
    name: "updateFormTemplate"
});

const withRemoveFormTemplateMutation = withRemoveFormTemplate({
    name: "removeFormTemplate",
    options: {
        update: cleanCache([getFormTemplatesOverview])
    }
});

export const AddFormTemplatesPageContainer = compose<React.ComponentClass<AddFormTemplatesPageContainerProps>>(
    withRouter,
    withFormTemplates,
    withAddFormTemplateMutation,
    withUpdateFormTemplateMutation,
    withRemoveFormTemplateMutation
)(AddFormTemplatesPage);

export type AddFormTemplatesPageProps =
    AddFormTemplatesPageContainerProps
    & AddFormTemplateRouteProps
    & MaybeElements<GetFormTemplatesProps<{}, "formTemplatesQueryResults">>
    & RemoveFormTemplateProps<{}, "removeFormTemplate">
    & AddFormTemplateProps<{}, "addFormTemplate">
    & UpdateFormTemplateProps<{}, "updateFormTemplate">
