export const emptyAmbassadorConfig = {
    id: "",
    place: "",
    image: {
        size: "",
        shape: ""
    },
    visibility: {
        icons: true,
        name: true,
        info: true
    },
    title: {},
    preview: {},
    description: {},
    intro: {},
    ambassadorIntro: {},
    caption: {},
    heroImage: ""
};
