import styled from "styled-components";

export const ListItemStyle = styled.div`
    box-shadow: rgba(9, 30, 66, 0.5) 0px 0px 2px;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
`;

export const SortableItemStyle = styled(ListItemStyle)`
    user-select: none;
    cursor: grab;
    :hover {
        border: 1px solid #A0C63D;
    }
`;
