import { GetSessionQuery } from "@graphql2/types";
import { useContext } from "react";
import { AuthContext } from "./context";

// eslint-disable-next-line no-shadow
export enum AuthActionTypes {
    LOGIN,
    LOGOUT,
    UPDATE_SESSION_INFO
}

export type AuthAction =
    { type: AuthActionTypes.LOGIN;} |
    { type: AuthActionTypes.LOGOUT;} |
    { type: AuthActionTypes.UPDATE_SESSION_INFO; payload: GetSessionQuery["session"];};

export const useAuthActions = () => {
    const { authDispatch } = useContext(AuthContext);

    const loginAction = (token: string) => {
        localStorage.setItem("token", token);
        authDispatch({ type: AuthActionTypes.LOGIN });
    };

    const logoutAction = () => {
        authDispatch({ type: AuthActionTypes.LOGOUT });
        localStorage.removeItem("token");
    };

    const updateSessionInfo = (payload: GetSessionQuery["session"]) => {
        authDispatch({ type: AuthActionTypes.UPDATE_SESSION_INFO, payload });
    };

    return { loginAction, logoutAction, updateSessionInfo };
};
