import { TableContainer } from "@components/table/tableContainer";
import { TranslationsListProps } from "@components/translations/translationsList/translationsListContainer";
import { useAuth } from "@context/authContext/context";
import { getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Modal, message } from "antd";
import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import * as React from "react";
import { useIntl } from "react-intl";
import { AddTranslationMutationVariables, RemoveTranslationMutationVariables, UpdateTranslationMutationVariables } from "../../../graphql2/types";
import { TranslationTable } from "../translationTable";

export const TranslationsList: React.FC<TranslationsListProps> = (props) => {
    const {
        translations,
        count,
        selectedLanguage,
        superAdmin,
        filterQuery,
        form,
        addTranslation,
        removeTranslation,
        updateTranslation
    } = props;

    const intl = useIntl();
    const { projectId } = useAuth();

    const updateTableSettings = async (pagination: TablePaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        const values = form.isFieldsTouched();

        if (values) {
            Modal.confirm({
                width: 500,
                title: intl.formatMessage({ id: "losingChanges.title" }),
                content: intl.formatMessage({ id: "losingChanges.description" }),
                okText: intl.formatMessage({ id: "losingChanges.continue" }),
                cancelText: intl.formatMessage({ id: "losingChanges.cancel" }),
                onOk: () => {
                    form.resetFields();
                    updateTableSettings(pagination, filters, sorter);
                }
            });
        } else {
            filterQuery.updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
        }
    };

    // add if custom doesn't exist yet
    const addTranslationFn = async (key: string, translation: string) => {
        const current = (translations || []).find(t => t.key === key);

        if (!current) {
            return;
        }

        const variables: AddTranslationMutationVariables = {
            projectId: superAdmin ? "" : projectId,
            key,
            translations: [
                ...current.translations.filter(t => t && t.language !== selectedLanguage).map(t => ({
                    language: t && t.language,
                    text: t && t.text
                }
                )),
                {
                    language: selectedLanguage,
                    text: translation
                }
            ]
        };

        const result = await addTranslation({
            variables
        });

        if (result.data && result.data.addTranslation) {
            message.success(intl.formatMessage({ id: "translation.create.success" }));
        } else {
            message.error(intl.formatMessage({ id: "translation.create.failed" }));
        }
    };

    // update if custom already exists but value changes
    const updateTranslationFn = async (id: string, key: string, translation: string) => {
        const current = (translations || []).find(t => t.key === key);
        if (!current) {
            return;
        }

        const variables: UpdateTranslationMutationVariables = {
            id,
            key,
            projectId: superAdmin ? "" : projectId,
            translations: [
                ...current.translations.filter(t => t && t.language !== selectedLanguage).map(t => ({
                    language: t && t.language,
                    text: t && t.text
                }
                )),
                {
                    language: selectedLanguage,
                    text: translation
                }
            ]
        };

        const result = await updateTranslation({
            variables
        });

        if (result.data && result.data.updateTranslation) {
            message.success(intl.formatMessage({ id: "translation.update.success" }));
        } else {
            message.error(intl.formatMessage({ id: "translation.update.failed" }));
        }
    };

    // remove if custom exists, but is now disabled
    const removeTranslationFn = async (id: string) => {
        const variables: RemoveTranslationMutationVariables = {
            projectId: superAdmin ? "" : projectId,
            id
        };

        const result = await removeTranslation({
            variables
        });

        if (result.data && result.data.removeTranslation.success) {
            message.success(intl.formatMessage({ id: "translation.delete.success" }));
        } else {
            message.error(intl.formatMessage({ id: "translation.delete.failed" }));
        }
    };

    return (
        <TableContainer
            dataSource={translations || []}
            handlers={{
                addTranslation: addTranslationFn,
                updateTranslation: updateTranslationFn,
                removeTranslation: removeTranslationFn
            }}
            loading={!translations}
            pagination={{
                current: filterQuery.skip ? filterQuery.skip / filterQuery.pageSize + 1 : 1,
                pageSize: filterQuery.pageSize,
                total: (count && count.translationCount) || 0
            }}
            TableComponent={TranslationTable}
            variables={{
                language: selectedLanguage,
                form,
                superAdmin
            }}
            onChange={updateTableSettings}
        />
    );
};
