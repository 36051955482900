import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { OrdersTable } from "@components/orders/ordersTable";
import { TableContainer } from "@components/table/tableContainer";
import { translateOrders } from "@utils/mappers/ordersMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { Card } from "antd";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as React from "react";
import { useIntl } from "react-intl";
import { OrdersPageProps } from "./ordersPageContainer";

export const OrdersPage: React.FC<OrdersPageProps> = (props) => {
    const { orders, count, paging } = props;
    const intl = useIntl();

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const translatedOrders = React.useMemo(
        () => translateOrders(orders?.articleOrders ?? []),
        [orders]
    );

    // const [uploadError, setUploadError] = useState<string>();

    // const handleUpload = async (info: { file: File }) => {
    //     try {
    //         const importData = await readFile<ContentFragment>(info.file);
    //         props.history.push("/content/contents/add/general", { import: importData });
    //     } catch (error) {
    //         setUploadError("upload.tryAgain");
    //     }
    // };

    const updateTableSettings = async (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    return (
        <div>
            {/*
                // TODO importing and adding not supported for now - there is no mutation
                <HeaderContent>
                    <Flex justifyContent="space-between">
                        <h1><FormattedMessage id="order.overview" /></h1>
                        <div>
                            <Upload accept="application/json" customRequest={handleUpload}>
                                <Button className="headerButton" type="primary">
                                    <FormattedMessage id="order.import" />
                                </Button>
                            </Upload>
                            <Button
                                onClick={() => {
                                    props.history.push("/content/contents/add/general");
                                }}
                                className="headerButton"
                                type="primary"
                            >
                                <FormattedMessage id="order.addContent" />
                            </Button>
                        </div>
                    </Flex>
                </HeaderContent>
            */}
            <Card>
                {/* {uploadError && <Alert type="error" message={<FormattedMessage id={wuploadError} />} />} */}
                <DynamicFilterBar fields={[
                    {
                        name: "userSearch",
                        type: "input",
                        search: true,
                        placeholder: intl.formatMessage({ id: "orders.filter.userSearch" })
                    },
                    {
                        name: "articleSearch",
                        type: "input",
                        search: true,
                        placeholder: intl.formatMessage({ id: "orders.filter.articleSearch" })
                    }
                ]}
                />
                <TableContainer
                    dataSource={translatedOrders}
                    loading={(orders && orders.loading) || (count && count.loading)}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count && count.articleOrderCount
                    }}
                    TableComponent={OrdersTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
