import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { getUserCount } from "@graphql2/graphql_op";
import { GetApplicationsProps, GetUserCountProps, GetUsersOverviewProps, RemoveUserProps, UserFilter, withGetApplications, withGetUserCount, withGetUsersOverview, withRemoveUser } from "@graphql2/types";
import { UsersPage } from "@pages/usersPage/usersPage";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { createApplicationsFilter } from "./createApplicationsFilter";

export interface UsersPageContainerProps { }

interface UsersPageFilterBarProps extends GraphQLPagingAndSort {
    filter?: UserFilter;
}

const createUsersPageFilter = (objectFilter: any): UserFilter => {
    const createdFilter: UserFilter = {
        userSearch: objectFilter.userSearch,
        applications: []
    };

    if (objectFilter.userRoles) {
        if (!Array.isArray(objectFilter.userRoles)) {
            createdFilter.applications = createApplicationsFilter([objectFilter.userRoles]);
        } else {
            createdFilter.applications = createApplicationsFilter(objectFilter.userRoles);
        }
    }

    return createdFilter;
};

const withUsersOverview = withGetUsersOverview({
    name: "users",
    options: ({ sort, paging, filter }: UsersPageProps) => ({
        variables: {
            filter,
            sort,
            paging
        }
    })
});

const withUserCount = withGetUserCount({
    name: "userCount",
    options: ({ filter }: UsersPageProps) => ({
        variables: {
            filter
        }
    })
});

const withRemoveUserMutation = withRemoveUser({
    name: "removeUser",
    options: ({ filter }: UsersPageProps) => ({
        refetchQueries: () => [
            "getUsersOverview",
            {
                query: getUserCount,
                variables: {
                    filter
                }
            }
        ],
        awaitRefetchQueries: true
    })
});

const withApplications = withGetApplications({
    name: "applications"
});

export const UsersPageContainer = compose<React.ComponentClass<UsersPageContainerProps>>(
    withRouter,
    withParseDynamicFilterBar(createUsersPageFilter, { defaultSortSettings: { sortBy: "givenName", sort: "ASC" } }),
    withApplications,
    withUserCount,
    withUsersOverview,
    withRemoveUserMutation
)(UsersPage);

export type UsersPageProps =
    UsersPageContainerProps
    & UsersPageFilterBarProps
    & GetUsersOverviewProps<{}, "users">
    & GetUserCountProps<{}, "userCount">
    & RemoveUserProps<{}, "removeUser">
    & GetApplicationsProps<{}, "applications">
    & RouteComponentProps;
