/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { ContentHeaderStyle, RightFields, StyledIcon, StyledTitle } from "@components/contentHeader/contentHeaderStyle";
import { Flex } from "@components/flex/flex";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import { ButtonType } from "antd/lib/button/button";
import * as React from "react";

export interface ContentHeaderProps {
    title: string | JSX.Element;
    subTitle?: string | JSX.Element;
    directionTitles?: "row" | "column";
    rightFields?: (Field | JSX.Element | null)[] | false;
    back?: boolean;
}

export interface Field {
    type: string;
    text: string;
    icon?: string;
    buttonType?: ButtonType;
    onClick?(): void;
}

const isFieldType = (field): field is Field => (Boolean((field as Field).type));

export const ContentHeader: React.FC<ContentHeaderProps> = (props) => {
    const { title, subTitle, rightFields, back, directionTitles } = props;

    return (
        <ContentHeaderStyle>
            <Flex direction={directionTitles || "row"} alignItems={directionTitles !== "column" ? "center" : "flex-start"}>
                {back && <StyledIcon> <Icon type="arrow-left" /> </StyledIcon>}
                <div><StyledTitle>{title}</StyledTitle>
                    {subTitle}
                </div>
            </Flex>
            {rightFields
                && (
                    <RightFields>
                        {
                            rightFields.map((field, key) => {
                                if (!field) {
                                    return null;
                                }
                                if (React.isValidElement(field)) {
                                    return field;
                                }
                                if (isFieldType(field)) {
                                    if (field.type === "text") {
                                        return (
                                            <span key={`field_${field.type}_${key}`}>
                                                <Icon
                                                    type={field.icon}
                                                // TODO: Fix this
                                                // theme="outlined"
                                                />
                                                &nbsp;
                                                {field.text}
                                            </span>
                                        );
                                    }
                                    if (field.type === "button") {
                                        return (
                                            <Button
                                                key={`field_${field.type}_${key}`}
                                                className="headerButton"
                                                onClick={field.onClick}
                                                ghost
                                                type={field.buttonType}
                                            >
                                                {field.text}
                                            </Button>
                                        );
                                    }
                                }
                            })
                        }
                    </RightFields>
                )}
        </ContentHeaderStyle>
    );
};
