import { useAuthActions } from "@context/authContext/actions";
import { client } from "@graphql2/client";
import { getSession } from "@graphql2/graphql_op";
import { GetSessionQuery } from "@graphql2/types";
import { LoginProps } from "@pages/login/loginContainer";
import { LoginBoxStyle, LoginStyle } from "@pages/login/loginStyle";
import { parseError } from "@utils/parseError";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

const fetchSession = async () => {
    const result = await client.query<GetSessionQuery>({
        query: getSession
    });

    if (result.data) {
        return result.data.session;
    }
};
export interface FormValues {
    email: string;
    password: string;
}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
    const [loginProgress, setLoginProgress] = useState(false);
    const [form] = useForm();
    const intl = useIntl();
    const location = useLocation();
    const { loginAction, updateSessionInfo } = useAuthActions();
    const { login, history } = props;

    const submitForm = async (values) => {
        try {
            setLoginProgress(true);

            const { email, password } = values;
            const response = await login({ variables: { email, password } });

            if (response?.data?.login) {
                loginAction(response.data.login.jwtToken);
                const sessionData = await fetchSession();
                if (sessionData) {
                    updateSessionInfo(sessionData);
                }
                const queryParams = new URLSearchParams(location.search);
                const redirectTarget = queryParams.get("redirect");
                history.push(redirectTarget || "/");

                return;
            }

            setLoginProgress(false);
        } catch (errors) {
            const msg = parseError(errors, intl);

            if (msg === "error.notAllowed") {
                message.error(intl.formatMessage({ id: "login.failed" }));
            }

            setLoginProgress(false);
        }
    };

    return (
        <LoginStyle>
            <LoginBoxStyle>
                <img
                    alt="Logo Energylab"
                    src="https://res.cloudinary.com/energylab/image/upload/v1593509749/development/logo_login.png"
                />
                <div className="login__cover">
                    <Form
                        hideRequiredMark
                        className="login__form"
                        form={form}
                        layout="vertical"
                        onFinish={submitForm}
                    >
                        <Form.Item
                            colon={false}
                            label={`${intl.formatMessage({ id: "form.email" })}*`}
                            name="email"
                            rules={
                                [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) },
                                    { type: "email", message: intl.formatMessage({ id: "form.invalidemail" }) }
                                ]
                            }
                            validateTrigger="onSubmit"
                        >
                            <Input placeholder={intl.formatMessage({ id: "form.email" })} />
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            label={`${intl.formatMessage({ id: "form.password" })}*`}
                            name="password"
                            rules={[
                                { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({ id: "form.password" })}
                                type="password"
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            loading={loginProgress}
                        >
                            <FormattedMessage id="form.login" />
                        </Button>
                    </Form>
                </div>
            </LoginBoxStyle>
        </LoginStyle>
    );
};
