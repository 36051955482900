import styled from "styled-components";

export const ExampleLeaderboardStyle = styled.div`
    margin-top: 1rem;
    margin-right: 2rem;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .ant-empty {
        width: 60%;
    }

    .ant-tabs {
        align-self: flex-start;
        margin-top: 0;
        width: 100%;
    }

    .ant-tabs-nav {
        margin: 0 !important;
    }

    .ant-list-item:nth-child(even) {
        background-color: rgba(0,0,0,0.05);
    }

    .ant-list-item:last-child {
        color: #A0C63D;
    }

    .listContent {
        display: flex;
        width: 100%;
        justify-content: space-between;

        span:last-child {
            font-weight: bold;
        }
    }
`;
