import { VisualisationsDefaultCard } from "@pages/addChallengesPage/tabs/visualisations/default/visualisationsDefaultCard";
import { ChallengeGridVisualisationFormValues } from "@pages/addChallengesPage/tabs/visualisations/editChallengeGridVisualisation/editChallengeGridVisualisation";
import { ChallengeLeaderboardVisualisationFormValues } from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisation";
import { NoChallengeConditions } from "@pages/addChallengesPage/tabs/visualisations/noChallengeConditions/noChallengeConditions";
import { ChallengeVisualisationBaseFormValues } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { ChallengeTimelineVisualisationFormValues } from "../editChallengeTimelineVisualisation/editChallengeTimelineVisualisation";

export interface ChallengeVisualisationsCardProps {
    form: FormInstance;
    hidden?: boolean;
    errors?: ErrorDictionary;
    editChallengeVisualisation(index: number): void;
    onBlur(): void;
}

export type ChallengeVisualisationsFormValuesUnion = ChallengeVisualisationBaseFormValues | ChallengeTimelineVisualisationFormValues | ChallengeLeaderboardVisualisationFormValues | ChallengeGridVisualisationFormValues;

export interface ChallengeVisualisationsForm {
    visualisations?: ChallengeVisualisationsFormValuesUnion[];
}

export const ChallengeVisualisationsCard: React.FC<ChallengeVisualisationsCardProps> = (props) => {
    const { hidden, onBlur, editChallengeVisualisation, form, errors } = props;

    const conditions = form.getFieldValue("conditions");

    if (!conditions || !conditions.length) {
        return (
            <Card hidden={hidden}>
                <NoChallengeConditions />
            </Card>
        );
    }

    return (
        <VisualisationsDefaultCard
            editVisualisation={editChallengeVisualisation}
            errors={errors}
            form={form}
            hidden={hidden}
            onBlur={onBlur}
        />
    );
};
