import { ProviderActivitiesTable, ProviderActivitiesTableData } from "@components/providerActivities/providerActivitiesTable";
import { TableContainer } from "@components/table/tableContainer";
import { useGetProviderActivitiesQuery, useRemoveProviderActivityMutation } from "@graphql2/types";
import { PageNotFoundPage } from "@pages/pageNotFoundPage/pageNotFoundPage";
import { Card, DatePicker } from "antd";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

export const ProviderActivitiesPage: React.FC = () => {
    const { id: userId } = useParams<{ id: string; }>();
    const history = useHistory();
    const [date, setDate] = React.useState<moment.Moment|undefined>(moment());

    const { data, loading, error, refetch } = useGetProviderActivitiesQuery({
        variables: {
            date: moment().utc().startOf("day").valueOf(),
            userId
        },
        // ProviderActivities data returns sometimes id field with duplicated values across returned records, this brakes Apollo's cache
        // causing duplicated records in the table, so we need to disable cache for this query
        fetchPolicy: "no-cache"
    });
    const [removeActivity] = useRemoveProviderActivityMutation({ refetchQueries: ["getProviderActivities"] });

    const handleRemoveActivity = async (id: string, segmentId: string) => {
        await removeActivity({ variables: { id, segmentId } });
    };

    React.useEffect(() => {
        if (!date) {
            return;
        }
        refetch({
            date: date.utc().startOf("day").valueOf(),
            userId
        });
    }, [date, userId]);

    if (error) {
        history.push("/404");
        return <PageNotFoundPage />;
    }

    return (
        <Card
            extra={(
                <DatePicker
                    clearIcon={false}
                    value={date}
                    onChange={d => setDate(d || undefined)}
                />
            )}
            title={<FormattedMessage id="providerActivities" />}
        >
            <TableContainer
                dataSource={data?.providerActivities as ProviderActivitiesTableData[] || []}
                handlers={{
                    removeActivity: handleRemoveActivity
                }}
                loading={loading}
                pagination={undefined}
                TableComponent={ProviderActivitiesTable}
            />
        </Card>
    );
};
