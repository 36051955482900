import Icon, { CloudUploadOutlined } from "@ant-design/icons";
import * as React from "react";
import { DropTargetMonitor, ConnectDropTarget, DropTarget, DropTargetConnector } from "react-dnd";
import { FormattedMessage } from "react-intl";

const boxTarget = {
    drop(props: FileDragAndDropTargetProps, monitor: DropTargetMonitor) {
        if (props.onDrop) {
            props.onDrop(props, monitor);
        }
    }
};

export interface FileDragAndDropTargetProps {
    accepts: string[];
    connectDropTarget?: ConnectDropTarget;
    isOver?: boolean;
    canDrop?: boolean;
    currentImage?: string;
    info?: JSX.Element;
    onDelete?(): void;
    onDrop(props: FileDragAndDropTargetProps, monitor: DropTargetMonitor): void;
}

class FileDragAndDropTarget extends React.Component<FileDragAndDropTargetProps> {
    public render() {
        const { canDrop, isOver, connectDropTarget, currentImage, info, onDelete } = this.props;
        const isActive = canDrop && isOver;

        return (
            connectDropTarget &&
            connectDropTarget(
                <div>
                    {!currentImage && <CloudUploadOutlined />}
                    {currentImage && <img style={{ maxWidth: "100%" }} src={currentImage} role="presentation" alt="" />}
                    <FormattedMessage id={isActive ? "draganddrop.releasetoupload" : "draganddrop.dragtoupload"} />
                    <p>{info}</p>
                    {
                        onDelete && currentImage &&
                        <a onClick={() => onDelete()}>
                            <Icon style={{ display: 'inline-block', fontSize: '120%', marginRight: "10px" }} type="delete" />
                            <FormattedMessage tagName="span" id="image.delete" />
                        </a>
                    }
                </div>
            )
        );
    }
}

export default DropTarget(
    (props: FileDragAndDropTargetProps) => props.accepts,
    boxTarget,
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    })
)(FileDragAndDropTarget);
