import { CheckupTableData } from "@components/checkupTable/checkupTable";
import { CheckupLocationAvailabilityFormValues } from "@pages/addCheckupPage/addCheckupPage";
import { emptyCheckupLocationAvailability } from "@utils/emptyItems/emptyCheckup";
import { getText } from "@utils/getText";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import * as moment from "moment";
import { AddCheckupLocationAvailabilityMutationVariables, CheckupLocationAvailabilityFragment } from "@graphql2/types";

export function checkupLocationAvailabilityToFormValues(checkup?: CheckupLocationAvailabilityFragment | null): CheckupLocationAvailabilityFormValues {
    if (!checkup) {
        return emptyCheckupLocationAvailability;
    }

    const {
        checkupLocationId,
        coachId,
        coachType,
        from,
        to,
        id
    } = checkup;

    const date = moment(from);
    const totalMinuteDuration = Math.abs(date.diff(to, "minute"));
    const hourDuration = Math.abs(date.diff(to, "hour"));

    const minuteDuration = totalMinuteDuration - (hourDuration * 60);

    return {
        coach: coachId,
        coachType,
        date,
        duration: {
            hours: hourDuration,
            minutes: minuteDuration
        },
        id,
        location: checkupLocationId,
        time: date
    };
}

export function translateCheckupLocationAvailabilities(originals: CheckupLocationAvailabilityFragment[]): CheckupTableData[] {
    return originals.map((checkup): CheckupTableData => {
        const { id, checkupLocation, coach, from, to, coachType } = checkup;

        const date = moment(from);

        return {
            id,
            coach: coach.displayName || `${coach.givenName} ${coach.familyName}`,
            date,
            duration: date.diff(to),
            location: getText(checkupLocation.title),
            type: coachType
        };
    });
}

export function formValuesToCheckupLocationAvailability(formValues: CheckupLocationAvailabilityFormValues): AddCheckupLocationAvailabilityMutationVariables {
    const { coach, time, location, duration, date, coachType } = formValues;

    date.set("hour", time.get("hour"));
    date.set("minutes", time.get("minutes"));
    const to = moment(date).add(duration.hours, "hour").add(duration.minutes, "minute");

    return {
        checkupLocationId: location,
        coachId: coach,
        coachType,
        cost: {
            amount: 0,
            description: toTextByLanguage({ nl: " ", fr: " " })
        },
        from: date.valueOf(),
        to: to.valueOf()
    };
}
