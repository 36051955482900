/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from "@ant-design/icons";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { requiredrule } from "@components/form/requiredRule";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { ChallengeConditionGridImagesVisualisation } from "@graphql2/types";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { Label, VisualisationdCardstyle, VisualisationdGridImagesStyle, VisualisationdGridStyle } from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisationStyle";
import { ChallengeVisualisationBaseFormValues, VisualisationTypeForm } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { LanguageErrors, errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { ChallengeLanguageFieldProps, GridVisualisationLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Button, Form, Select, Table } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import Column from "antd/lib/table/Column";
import * as environment from "environment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../context/enabledLangContext/context";
import { useErrors } from "../../../../../hooks/useErrors";

export interface ChallengeGridVisualisationColumn {
    conditionId: string;
    header: LanguageObject;
    columnType: string;
    profileProperty?: string;
}

export interface ChallengeGridVisualisationFormValues extends ChallengeVisualisationBaseFormValues {
    conditionId?: string;
    order: string;
    columns?: ChallengeGridVisualisationColumn[];
    images?: ChallengeConditionGridImagesVisualisation;
}

export interface EditChallengeGridVisualisationCardProps {
    form: FormInstance;
    visualisation: ChallengeGridVisualisationFormValues;
    conditions?: ChallengeConditionFormValuesUnion[];
    index: number;
    hidden: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    back(): void;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

function createLabelField(column: ChallengeGridVisualisationColumn, columnIndex: number) {
    return (props: GridVisualisationLanguageFieldProps) => (
        <Field
            key={`${columnIndex}${props.language}`}
            hidden={props.hidden}
            id={`visualisations.${props.index}.columns.${columnIndex}.header.${props.language}`}
            label={<FormattedMessage id="visualisation.grid.labelName" />}
            name={[columnIndex, "header", props.language]}
            rules={[requiredrule]}
            {...props.sharedProps}
        />
    );
}

const newLabelField = (props: GridVisualisationLanguageFieldProps) => (
    <Field
        key={`grid.newColumn.header.${props.language}`}
        hidden={props.hidden}
        label={<FormattedMessage id="visualisation.grid.labelName" />}
        name={["header", props.language]}
        rules={[requiredrule]}
        {...props.sharedProps}
    />
);

export const EditChallengeGridVisualisationCard: React.FC<EditChallengeGridVisualisationCardProps> = (props) => {
    const {
        hidden,
        form: existingForm,
        visualisation,
        back,
        index,
        onBlur,
        activeLanguage,
        changeSelectedLanguage,
        languageErrors
    } = props;

    const { enabledLanguages } = useEnabledLang();

    const [columnIndex, setColumnIndex] = React.useState<number>();
    const formColumns = existingForm.getFieldValue(["visualisations", index, "columns"]);
    const [columns, setColumns] = React.useState<ChallengeGridVisualisationColumn[]>(formColumns || []);
    const [isProfileField, setIsProfileField] = React.useState<boolean>();

    const notStartedImage = visualisation.images?.notStarted;
    const inProgressImage = visualisation.images?.inProgress;
    const successImage = visualisation.images?.success;

    const conditions = existingForm.getFieldValue("conditions");

    const [fieldErrors, setError] = useErrors();

    const [form] = useForm();

    const sharedProps = {
        onBlur
    };

    const langFieldProps: ChallengeLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                form,
                sharedProps,
                defaultValues: {},
                language: language.code,
                hidden: language.code !== activeLanguage
            }
        )), [enabledLanguages, activeLanguage]
    );

    const gridLangFieldProps: GridVisualisationLanguageFieldProps[] = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                existingForm,
                index,
                defaultValues: visualisation,
                sharedProps: { disabled: false, onChange: onBlur },
                language: language.code,
                hidden: language.code !== activeLanguage
            }
        )), [enabledLanguages, activeLanguage]
    );

    const addColumn = async (add: (data) => void) => {
        try {
            await form.validateFields();
            add(form.getFieldsValue());
            const existingColumns = existingForm.getFieldValue(["visualisations", index, "columns"]);
            setColumns(existingColumns);
            form.resetFields();
            onBlur();
            setError({});
        } catch (error) {
            setError(error);
        }
    };

    const renderSwitchIcons = (currentColumn: number, move: (from: number, to: number) => void) => {
        const moveColumns = (add: boolean) => {
            const nextColumn = add ? currentColumn + 1 : currentColumn - 1;
            move(currentColumn, nextColumn);
            const existingColumns = existingForm.getFieldValue(["visualisations", index, "columns"]);
            setColumns(existingColumns);
            onBlur();
        };

        return (
            <React.Fragment key={index}>
                <UpOutlined onClick={() => moveColumns(false)} />
                <br />
                <DownOutlined onClick={() => moveColumns(true)} />
            </React.Fragment>
        );
    };

    const renderHeaderColumn = (text: LanguageObject) => {
        return getText(text);
    };

    const renderConditionIdColumn = (text: string, record: ChallengeGridVisualisationColumn, ci: number) => {
        const fetchedConditions = existingForm.getFieldValue("conditions");
        const condition = fetchedConditions && fetchedConditions.find(c => c.id === text);
        const profileField = environment.profileFields?.some(field => field.key === text);

        return (
            !condition && profileField ? text
                : (
                    <ConditionTitle
                        key={ci}
                        condition={condition}
                    />
                )
        );
    };

    const handleConditionChange = (value, prefix) => {
        const profileField = environment.profileFields?.some(field => field.key === value);

        if (profileField) {
            if (prefix.length) {
                existingForm.setFields([{ name: ["visualisations", index, "columns", ...prefix, "columnType"], value: "value" }]);
            } else {
                form.setFields([{ name: ["columnType"], value: "value" }]);
            }
        }
        setIsProfileField(profileField);
    };

    // eslint-disable-next-line no-shadow
    const renderActionsColumn = (ci: number, remove: (index: number) => void) => {
        const removeColumn = () => {
            remove(ci);
            const existingColumns = existingForm.getFieldValue(["visualisations", index, "columns"]);
            setColumns(existingColumns);
            onBlur();
        };

        return (
            <React.Fragment key={ci}>
                <EditOutlined onClick={() => {
                    const existingColumns = existingForm.getFieldValue(["visualisations", index, "columns"]);
                    const conditionID = existingColumns[ci].conditionId;
                    handleConditionChange(conditionID, ci);
                    setColumnIndex(ci);
                }}
                />
                <DeleteOutlined onClick={removeColumn} />
            </React.Fragment>
        );
    };

    const renderEditColumn = (add: (data) => void, column?: ChallengeGridVisualisationColumn, currentColumnIndex?: number) => {
        const prefix = column ? [(currentColumnIndex || 0)] : [];

        const editColumn = (
            <VisualisationdGridStyle hidden={column && (columnIndex !== currentColumnIndex)}>
                <FormattedMessage
                    id={column ? "edit" : "add"}
                    tagName="h1"
                />
                <Flex>
                    <Form.Item
                        label={<FormattedMessage id="visualisations.grid.conditionAndProfileFields" />}
                        name={[...prefix, "conditionId"]}
                        rules={[requiredrule]}
                    >
                        <Select onChange={(event) => handleConditionChange(event, prefix)}>
                            <Select.Option
                                key="global"
                                value="global"
                            >
                                <ConditionTitle />
                            </Select.Option>
                            {conditions && conditions.map(condition => (
                                <Select.Option
                                    key={condition.id}
                                    value={condition.id}
                                >
                                    <ConditionTitle condition={condition} />
                                </Select.Option>
                            ))}
                            {(environment?.profileFields || []).map(field => (
                                <Select.Option
                                    key={field.key}
                                    value={field.key}
                                >
                                    {getText(field.label)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        dependencies={[[...prefix, "conditionId"], ["visualisations", index, "columns", columnIndex || 0, "conditionId"]]}
                    >
                        {() => {
                            const isGlobalConditionId = column ? existingForm.getFieldValue(["visualisations", index, "columns", columnIndex || 0, "conditionId"]) === "global" : form.getFieldValue([...prefix, "conditionId"]) === "global";
                            const isValueOnGlobalCondition = column ? existingForm.getFieldValue(["visualisations", index, "columns", columnIndex || 0, "columnType"]) === "value" : form.getFieldValue([...prefix, "columnType"]) === "value";

                            if (isGlobalConditionId && isValueOnGlobalCondition) {
                                if (column) {
                                    existingForm.setFields([{
                                        name: ["visualisations", index, "columns", columnIndex || 0, "columnType"],
                                        value: undefined
                                    }]);
                                } else {
                                    form.setFields([{
                                        name: [...prefix, "columnType"],
                                        value: undefined
                                    }]);
                                }
                            }

                            return (
                                <Form.Item
                                    label={<FormattedMessage id="visualisations.grid.valueType" />}
                                    name={[...prefix, "columnType"]}
                                    rules={[requiredrule]}
                                >
                                    <Select disabled={isProfileField}>
                                        <Select.Option value="check">
                                            <FormattedMessage id="visualisations.grid.check" />
                                        </Select.Option>
                                        <Select.Option value="progress">
                                            <FormattedMessage id="visualisations.grid.progress" />
                                        </Select.Option>
                                        <Select.Option value="percent">
                                            <FormattedMessage id="visualisations.grid.percent" />
                                        </Select.Option>
                                        {!isGlobalConditionId
                                            && (
                                                <Select.Option value="value">
                                                    <FormattedMessage id="visualisations.grid.value" />
                                                </Select.Option>
                                            )}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Flex>

                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors || errorsToLanguageErrors(fieldErrors)}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />

                {column && columnIndex !== undefined && gridLangFieldProps.map(createLabelField(column, columnIndex))}
                {!column && gridLangFieldProps.map(newLabelField)}
                {
                    !column
                        ? (
                            <Button onClick={() => addColumn(add)}>
                                <FormattedMessage id="add" />
                            </Button>
                        )
                        : (
                            <Button onClick={() => setColumnIndex(undefined)}>
                                <FormattedMessage id="close" />
                            </Button>
                        ) // This should not save (automatically done through form), but just close
                }
            </VisualisationdGridStyle>
        );

        if (!column) {
            return (
                <Form form={form}>
                    {editColumn}
                </Form>
            );
        }

        return (
            editColumn
        );
    };

    const titleField = (titleProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.title.${titleProps.language}`}
            hidden={titleProps.hidden}
            id={`visualisations.title.${titleProps.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="title" />}
            name={["visualisations", index, "title", titleProps.language]}
            rules={[
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                },
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }
            ]}
            {...titleProps.sharedProps}
        />
    );

    const descriptionField = (descriptionProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.description.${descriptionProps.language}`}
            hidden={descriptionProps.hidden}
            id={`visualisations.description.${descriptionProps.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="description" />}
            name={["visualisations", index, "description", descriptionProps.language]}
            rules={[
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                },
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }
            ]}
            {...descriptionProps.sharedProps}
        />
    );

    const isTeamChallenge = existingForm.getFieldValue(["team", "enabled"]);

    return (
        <VisualisationdCardstyle hidden={hidden}>
            <VisualisationdGridStyle>
                <VisualisationTypeForm
                    back={back}
                    conditions={conditions}
                    form={existingForm}
                    index={index}
                    isTeamChallenge={isTeamChallenge}
                    prefix={["visualisations", index]}
                    visualisation={visualisation}
                    onChange={onBlur}
                />
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <FormattedMessage
                    id="titleAndDescription"
                    tagName="h1"
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(descriptionField)}
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <VisualisationdGridImagesStyle>
                    <div>
                        <Label><FormattedMessage id="visualisations.grid.image.notStarted" /></Label>
                        <ImageUpload
                            cropSettings={{
                                aspect: ImageRatios.AVATAR,
                                zoom: true,
                                rotate: true
                            }}
                            form={existingForm}
                            image={notStartedImage || ""}
                            name={["visualisations", index, "images", "notStarted"]}
                            {...sharedProps}
                        />
                    </div>
                    <div>
                        <Label><FormattedMessage id="visualisations.grid.image.inProgress" /></Label>
                        <ImageUpload
                            cropSettings={{
                                aspect: ImageRatios.AVATAR,
                                zoom: true,
                                rotate: true
                            }}
                            form={existingForm}
                            image={inProgressImage || ""}
                            name={["visualisations", index, "images", "inProgress"]}
                            {...sharedProps}
                        />
                    </div>
                    <div>
                        <Label><FormattedMessage id="visualisations.grid.image.success" /></Label>
                        <ImageUpload
                            cropSettings={{
                                aspect: ImageRatios.AVATAR,
                                zoom: true,
                                rotate: true
                            }}
                            form={existingForm}
                            image={successImage || ""}
                            name={["visualisations", index, "images", "success"]}
                            {...sharedProps}
                        />
                    </div>
                </VisualisationdGridImagesStyle>
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <SelectField
                    label={<FormattedMessage id="visualisations.grid.order" />}
                    name={["visualisations", index, "order"]}
                >
                    <Select.Option value="">
                        <FormattedMessage id="visualisations.grid.alphabetical" />
                    </Select.Option>
                    <Select.Option value="ranking">
                        <FormattedMessage id="visualisations.grid.ranking" />
                    </Select.Option>
                    <Select.Option value="completedConditions">
                        <FormattedMessage id="visualisations.grid.completedConditions" />
                    </Select.Option>
                    <Select.Option value="advancedCompletedConditions">
                        <FormattedMessage id="visualisations.grid.advancedCompletedConditions" />
                    </Select.Option>
                </SelectField>
            </VisualisationdGridStyle>
            <Form.List name={["visualisations", index, "columns"]}>
                {(fields, { add, remove, move }) => {
                    const table = (
                        <Table
                            dataSource={formColumns}
                            pagination={false}
                        >
                            <Column
                                key="order"
                                render={(_, __, i: number) => renderSwitchIcons(i, move)}
                                title={<FormattedMessage id="order" />}
                            />
                            <Column
                                key="header"
                                dataIndex="header"
                                render={renderHeaderColumn}
                                title={<FormattedMessage id="label" />}
                            />
                            <Column
                                key="columnType"
                                dataIndex="columnType"
                                title={<FormattedMessage id="type" />}
                            />
                            <Column
                                key="conditionId"
                                dataIndex="conditionId"
                                render={renderConditionIdColumn}
                                title={<FormattedMessage id="visualisations.grid.conditionAndProfileFields" />}
                            />
                            <Column
                                key="actions"
                                render={(_, __, i) => renderActionsColumn(i, remove)}
                            />
                        </Table>
                    );

                    return (
                        <React.Fragment>
                            <VisualisationdGridStyle>
                                {table}
                            </VisualisationdGridStyle>
                            {(columns || []).map((column, i) => renderEditColumn(add, column, i))}
                            {renderEditColumn(add)}
                        </React.Fragment>
                    );
                }}
            </Form.List>
        </VisualisationdCardstyle>
    );
};
