
import { CompaniesPage } from "@pages/companiesPage/companiesPage";

import * as React from "react";

import { getCompanies } from "@graphql2/graphql_op";
import { AddCompanyProps, GetApplicationsProps, GetCompaniesProps, RemoveCompanyProps, UpdateCompanyProps, withAddCompany, withGetApplications, withGetCompanies, withRemoveCompany, withUpdateCompany } from "@graphql2/types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface CompaniesPageContainerProps { }

const options = {
    refetchQueries: ["getCompanies"],
    cleanCache: [getCompanies],
    awaitRefetchQueries: true
};

const withApplications = withGetApplications({ name: "applications" });
const withCompanies = withGetCompanies({ name: "companies" });
const withAddCompanyMutation = withAddCompany({
    name: "addCompany",
    options
});
const withRemoveCompanyMutation = withRemoveCompany({
    name: "removeCompany",
    options
});
const withUpdateCompanyMutation = withUpdateCompany({
    name: "updateCompany",
    options
});

export const CompaniesPageContainer = compose<React.ComponentClass<CompaniesPageContainerProps>>(
    withRouter,
    withApplications,
    withCompanies,
    withAddCompanyMutation,
    withRemoveCompanyMutation,
    withUpdateCompanyMutation
)(CompaniesPage);

export type CompaniesPageProps = CompaniesPageContainerProps
    & GetCompaniesProps<{}, "companies">
    & GetApplicationsProps<{}, "applications">
    & RouteComponentProps
    & AddCompanyProps<{}, "addCompany">
    & RemoveCompanyProps<{}, "removeCompany">
    & UpdateCompanyProps<{}, "updateCompany">;
