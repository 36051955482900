import { Form } from "antd";
import * as React from "react";
import { DynamicPreviewContainer, DynamicPreviewContainerProps } from "./dynamicPreviewContainer";

export const DynamicPreviewWrapper: React.FC<Omit<DynamicPreviewContainerProps, "sort" | "limit">> = (props) => {
    const { widgetId, form } = props;
    return (
        <Form.Item
            dependencies={[
                ["widgets", widgetId, "sort", "field"],
                ["widgets", widgetId, "sort", "order"],
                ["widgets", widgetId, "paging", "size"]
            ]}
        >
            {() => {
                const widget = form.getFieldValue(["widgets", widgetId]);
                return (
                    <DynamicPreviewContainer
                        {...props}
                        limit={widget?.paging?.size}
                        sort={widget?.sort}
                    />
                );
            }}
        </Form.Item>
    );
};
