
import { AddUserWhiteListProps, GetApplicationsProps, GetCompaniesProps, GetUserWhiteListsProps, RemoveUserWhiteListProps, UpdateUserWhiteListProps, withAddUserWhiteList, withGetApplications, withGetCompanies, withGetUserWhiteLists, withRemoveUserWhiteList, withUpdateUserWhiteList } from "@graphql2/types";
import { WhiteListPage } from "@pages/whiteListPage/whiteListPage";
import * as React from "react";
import { compose } from "redux";

export interface WhiteListPageContainerProps { }

const withUserWhiteLists = withGetUserWhiteLists({ name: "userWhiteLists" });
const withAddUserWhiteListMutation = withAddUserWhiteList({
    name: "addUserWhiteList",
    options: {
        awaitRefetchQueries: true,
        refetchQueries: () => ["getUserWhiteLists"]
    }
});
const withUpdateUserWhiteListMutation = withUpdateUserWhiteList({
    name: "updateUserWhiteList",
    options: {
        awaitRefetchQueries: true,
        refetchQueries: () => ["getUserWhiteLists"]
    }
});
const withRemoveUserWhiteListMutation = withRemoveUserWhiteList({
    name: "removeUserWhiteList",
    options: {
        awaitRefetchQueries: true,
        refetchQueries: () => ["getUserWhiteLists"]
    }
});
const withApplications = withGetApplications({ name: "applications" });
const withCompanies = withGetCompanies({ name: "companies" });

export const WhiteListPageContainer = compose<React.ComponentClass<WhiteListPageContainerProps>>(
    withApplications,
    withCompanies,
    withUserWhiteLists,
    withAddUserWhiteListMutation,
    withUpdateUserWhiteListMutation,
    withRemoveUserWhiteListMutation
)(WhiteListPage);

export type WhiteListPageProps =
    WhiteListPageContainerProps
    & GetUserWhiteListsProps<{}, "userWhiteLists">
    & AddUserWhiteListProps<{}, "addUserWhiteList">
    & UpdateUserWhiteListProps<{}, "updateUserWhiteList">
    & RemoveUserWhiteListProps<{}, "removeUserWhiteList">
    & GetApplicationsProps<{}, "applications">
    & GetCompaniesProps<{}, "companies">;
