import { Flex } from "@components/flex/flex";
import { ChronoRaceEvent } from "@graphql2/types";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { InjectedIntl } from "@utils/override.types";
import Form, { FormInstance } from "antd/lib/form";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { EventFormValues } from "../addEventsPage";
import { CardInCard, SubEventCardStyle } from "../addEventsPageStyle";
import { SubEventContentCard } from "./subEventContentCard";
import { SubEventProjectCard } from "./subEventProjectCard";
import { SubEventsSettingsCard } from "./subEventsSettingsCard";

export interface SubEventCardProps {
    intl: InjectedIntl;
    form: FormInstance;
    defaultValues: Partial<EventFormValues>;
    editSubEventId?: string | number;
    selectedChronoRaceEvent?: ChronoRaceEvent;
    disabled?: boolean;
    onBlur(): void;
    hidden?: boolean;
    isNotEditable?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    back(): void;
    activeProjectInfoMap: { [id: string]: string; };
    changeSelectedLanguage(languageCode: string): void;
    // onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
}

@autobind
export class SubEventCard extends React.Component<SubEventCardProps> {
    public render() {
        const {
            intl,
            hidden,
            form,
            back,
            disabled,
            onBlur,
            isNotEditable,
            defaultValues,
            activeLanguage,
            changeSelectedLanguage,
            editSubEventId,
            selectedChronoRaceEvent,
            languageErrors
        } = this.props;

        return (
            <Form.Item dependencies={[["subEvents"]]}>
                {() => {
                    const subEvents = form.getFieldValue("subEvents");
                    const projects = form.getFieldValue("projects");

                    const projectSubEvents = projects && projects[0] && projects[0].subEvents;

                    return (
                        <SubEventCardStyle hidden={hidden}>
                            {(subEvents || []).map((subEvent, key) => {
                                if (!projectSubEvents) {
                                    return null;
                                }

                                const selectedProjectSubEvent = projectSubEvents.find(s => s.id === subEvent.id);
                                const selectedProjectSubEventIndex = projectSubEvents.findIndex(s => s.id === subEvent.id);

                                const hiddenSubEventCard = subEvent.id === editSubEventId;

                                return (
                                    <Flex
                                        direction="column"
                                        hidden={!hiddenSubEventCard}
                                    >
                                        <SubEventContentCard
                                            activeLanguage={activeLanguage}
                                            back={back}
                                            changeSelectedLanguage={changeSelectedLanguage}
                                            defaultValues={defaultValues}
                                            disabled={disabled || isNotEditable}
                                            eventKey={key}
                                            form={form}
                                            languageErrors={languageErrors}
                                            subEvent={subEvent}
                                            onBlur={onBlur}
                                        />
                                        <SubEventsSettingsCard
                                            disabled={disabled || isNotEditable}
                                            form={form}
                                            intl={intl}
                                            selectedChronoRaceEvent={selectedChronoRaceEvent}
                                            selectedProjectSubEventIndex={selectedProjectSubEventIndex}
                                            subEventKey={key}
                                            onBlur={onBlur}
                                        />
                                        <CardInCard title={<FormattedMessage id="subEvent.projectCard" />}>
                                            {
                                                selectedProjectSubEventIndex >= 0 && projects && projects.map((project, projectIndex) => (
                                                    <SubEventProjectCard
                                                        key={project.projectId}
                                                        disabled={disabled}
                                                        form={form}
                                                        projectIndex={projectIndex}
                                                        projectTitle={this.props.activeProjectInfoMap[project.projectId]}
                                                        selectedProjectSubEvent={selectedProjectSubEvent}
                                                        selectedProjectSubEventIndex={selectedProjectSubEventIndex}
                                                        onBlur={onBlur}
                                                    />
                                                ))
                                            }
                                        </CardInCard>
                                    </Flex>
                                );
                            })}
                        </SubEventCardStyle>
                    );
                }}
            </Form.Item>
        );
    }
}
