import React from "react";
import { message } from "antd";
import { FormattedMessage } from "react-intl";
import { Exact, RemoveUserMutation } from "@graphql2/types";
import { MutationFunctionOptions, FetchResult } from "@apollo/client";
import type { History } from "history";

type RemoveUserFn = (
    id: string,
    removeUserMutation: (options?: MutationFunctionOptions<RemoveUserMutation, Exact<{
        id: string;
        anonymize: boolean;
    }>> | undefined) => Promise<FetchResult<RemoveUserMutation>>,
    history?: History,
    anonymize?: boolean
) => Promise<void>

export const removeUserFn: RemoveUserFn = async (id, removeUserMutation, history, anonymize = false) => {
    const actionType = anonymize ? "anonimyse" : "remove";

    try {
        await removeUserMutation({ variables: { id, anonymize } });
        message.success(<FormattedMessage id={`user.${actionType}d`} />);
        if (history) {
            history.push("/users/overview");
        }
    } catch (err) {
        message.error(<FormattedMessage id={`user.${actionType}Failed`} />);
        console.error(err);
    }
};
