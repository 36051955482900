import { FieldInfo } from "@components/field/fieldStyle";
import { Flex } from "@components/flex/flex";
import { FieldsMapper } from "@components/genericFields/fieldsMapper";
import { JSONData } from "@energylab/schematics/lib";
import { InjectedIntl } from "@utils/override.types";
import { Alert, Card, Col, Form, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface MapFieldsProps {
    form: FormInstance;
    schema: JSONData;
    hidden?: boolean;
    intl: InjectedIntl;
    initialValues?: JSON;
    tabName: string;
    disableElements: {
        [key: string]: boolean;
    };
    setWarningUnsavedChanges(boolean): void;
}

export const MapFields: React.FC<MapFieldsProps> = (props) => {
    const { schema, hidden, form, intl, initialValues, disableElements, tabName, setWarningUnsavedChanges } = props;

    const entries = React.useMemo(() => Object.entries(schema.properties ?? []), [schema.properties]);

    const [disable, setDisable] = React.useState(false);

    const dependencies = React.useMemo(() => entries.map(([key]) => [key]), [entries]);

    React.useEffect(() => {
        setDisable(!(initialValues && entries.some(([key]) => Boolean(initialValues[key]))));
    }, [initialValues]);

    React.useEffect(() => {
        disableElements[tabName] = disable;
        if (disable) {
            form.setFields(dependencies.map(([key]) => ({ name: key, value: undefined })));
        }
    }, [disable]);

    return (
        <Form.Item
            hidden={hidden}
        >
            <Card>
                <Flex
                    direction="column"
                    gap={15}
                >
                    <div>
                        {schema.title}
                        <FieldInfo>
                            {schema.description}
                        </FieldInfo>
                    </div>
                    <Flex gap={10}>
                        <Switch
                            checked={!disable}
                            onChange={(value) => {
                                setDisable(!value);
                                setWarningUnsavedChanges(true);
                                if (value) {
                                    form.resetFields(dependencies);
                                }
                            }}
                        />
                        <FormattedMessage id="config.enable" />
                    </Flex>
                    {!disable && (
                        <Alert
                            closable
                            showIcon
                            description={<FormattedMessage id="config.enable.warning.description" />}
                            message={<FormattedMessage id="config.enable.warning.message" />}
                            style={{ margin: 0 }}
                            type="warning"
                        />
                    )}
                </Flex>
            </Card>
            <Row>
                {entries.map(([key, value]) => (
                    <Col
                        order={(value as JSONData).order}
                        span={(value as JSONData).isFullWidth ? 24 : 12}
                    >
                        <Card key={key}>
                            <FieldsMapper
                                key={key}
                                disable={disable}
                                disableTitle={entries.length === 1}
                                form={form}
                                intl={intl}
                                jsonData={value as JSONData}
                                name={[key]}
                                namePath={[]}
                                required={schema?.required?.some(r => r === key)}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </Form.Item>
    );
};
