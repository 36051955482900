import * as React from "react";
import { compose } from "redux";
import * as moment from "moment";
import { localState, LocalStateProps } from "@utils/withLocalState";
import { Moment } from "moment";
import { GetUserActivitiesProps, withGetUserActivities } from "@graphql2/types";
import { UserActivitiesCard } from "./userActivitiesCard";

export interface UserActivitiesCardContainerProps {
    userId: string;
}

export interface LocalState {
    date: Moment;
}

const withLocalState = localState<LocalState>({
    date: moment()
});

const withUserActivities = withGetUserActivities({
    name: "userActivities",
    options(ownProps: LocalState & UserActivitiesCardContainerProps) {
        return {
            variables: {
                filter: {
                    from: ownProps.date.startOf("day").valueOf(),
                    to: ownProps.date.endOf("day").valueOf(),
                    userId: ownProps.userId
                }
            }
        };
    }
});

export const UserActivitiesCardContainer = compose<React.ComponentClass<UserActivitiesCardContainerProps>>(
    withLocalState,
    withUserActivities
)(UserActivitiesCard);

export type UserActivitiesCardProps =
    UserActivitiesCardContainerProps
    & LocalStateProps<LocalState>
    & GetUserActivitiesProps<{}, "userActivities">;
