import { Checkbox, DatePicker, Row } from "antd";
import styled from "styled-components";

export const CardSubtitle = styled.h2`

    span {
        font-family: "pingFang", sans-serif;
        font-size: .75rem;
        font-weight: 600;
        color: #A0C63D;
    }
`;

export const CheckboxWrapper = styled.div`
    margin-bottom:0;
    width: auto;
`;

export const CheckboxRepeat = styled(Checkbox)`
    margin: 0;
`;

// tslint:disable-next-line:no-any
export const RepeatsRow = styled(Row as any)`
    display: flex;
    align-items: center;
`;

export const DatePickerStretched = styled(DatePicker)`
    width: 100%;
`;

export const DatePickerLabel = styled.span`
    display: block;
    margin: 1em 0 0 0;
`;
