import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { ProfileFieldsCard } from "@components/profileFields/profileFields";
import { TranslationFields } from "@components/translations/translationFields/translationFields";
import { AddCompanyMutationVariables, ApplicationFragment } from "@graphql2/types";
import {
    Label
} from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { ColStyled } from "@pages/companiesPage/companiesPageStyle";
import { FlexButtonWrapper } from "@pages/rolesPage/rolesPageStyle";
import { Button, Card, Checkbox, Col, Form, Select } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { FormInstance } from "antd/es/form";
import environment from "environment";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ConditionCollapse } from "./companyCreateStyle";

export interface CompanyCreateFormProps {
    form: FormInstance;
    initialValues: Partial<AddCompanyMutationVariables>;
    roles: ApplicationFragment["roles"];
    submitForm(values: AddCompanyMutationVariables);
}

export const CompanyCreateForm: React.FC<CompanyCreateFormProps> = ({ submitForm, roles, form, initialValues }) => {
    const [companyLanguages, setCompanyLanguages] = useState<Array<{ code: string; label: string; }> | null>(null);

    return (
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={submitForm}
        >
            <Field
                id="name"
                label={<FormattedMessage id="name" />}
                name="name"
                rules={[requiredrule]}
                type="text"
            />
            <Field
                id="code"
                label={<FormattedMessage id="companyCode" />}
                name="code"
                rules={[requiredrule]}
                type="text"
            />
            <SelectField
                id="type"
                label={<FormattedMessage id="role" />}
                name="role"
                rules={[requiredrule]}
            >
                {roles.map(r => (
                    <Select.Option
                        key={r.id}
                        value={r.id}
                    >
                        {r.name}
                    </Select.Option>
                ))}
            </SelectField>

            <Field
                numberType
                id="maxRegistrations"
                label={<FormattedMessage id="maxRegistrations" />}
                min={0}
                name="maxRegistrations"
            />

            <Form.Item
                name="limitRegistrations"
                valuePropName="checked"
            >
                <Checkbox>
                    <FormattedMessage id="limitRegistrations" />
                </Checkbox>
            </Form.Item>
            <ConditionCollapse
                ghost
                hidden={!environment.useChallengeProfileFields}
            >
                <CollapsePanel
                    key="extra"
                    forceRender
                    header={<FormattedMessage id="profileFields" />}
                >
                    <ProfileFieldsCard
                        form={form}
                        rootFieldName="profileRequirements"
                    />
                </CollapsePanel>
            </ConditionCollapse>
            <ConditionCollapse
                ghost
                hidden={!environment.useExtraCompanySettings}
            >
                <CollapsePanel
                    key="extra"
                    forceRender
                    header={<FormattedMessage id="companyAdditionalFields" />}
                >
                    <Field
                        id="brandColor"
                        label={<FormattedMessage id="brandColor" />}
                        name="brandColor"
                        type="text"
                    />
                    <Form.Item
                        name="hasSupport"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            <FormattedMessage id="hasSupport" />
                        </Checkbox>
                    </Form.Item>
                    <SelectField
                        id="languages"
                        label={<FormattedMessage id="languages" />}
                        mode="multiple"
                        name="languages"
                        placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                        onChange={
                            (langs: string[]) => {
                                if (!langs?.length) {
                                    setCompanyLanguages(null);
                                    return;
                                }

                                setCompanyLanguages((langs as string[]).map(l => ({ code: l, label: l.toUpperCase() })));
                            }
                        }
                    >
                        {environment.languages.map(lang => (
                            <Select.Option
                                key={lang}
                                value={lang}
                            >
                                {lang}
                            </Select.Option>
                        ))}
                    </SelectField>
                    <Col span={24}>
                        <Form.Item name="logo">
                            <Label><FormattedMessage id="logo" /></Label>
                            <ImageUpload
                                form={form}
                                image={form.getFieldValue("logo")}
                                name="logo"
                            />
                        </Form.Item>
                    </Col>
                    <ColStyled span={24}>
                        <Form.Item name="coach">
                            <Label><FormattedMessage id="coachAvatar" /></Label>
                            <ImageUpload
                                form={form}
                                image={form.getFieldValue("coach")}
                                name="coach"
                            />
                        </Form.Item>
                    </ColStyled>
                    <ColStyled span={24}>
                        <TranslationFields
                            fields={[
                                { path: "introTitle", label: "introTitle" },
                                { path: "introMessage", label: "introMessage" }
                            ]}
                            langs={companyLanguages}
                        />
                    </ColStyled>
                    <ColStyled>
                        <Card title={<FormattedMessage id="teamPage" />}>
                            <ColStyled span={24}>
                                <TranslationFields
                                    fields={[
                                        { path: ["team", "selectTitle"], label: "teamSelectTitle" },
                                        { path: ["team", "selectSubtitle"], label: "teamSelectSubtitle" }
                                    ]}
                                    langs={companyLanguages}
                                />
                                <ImageUpload
                                    form={form}
                                    image={form.getFieldValue(["team", "selectPicture"])}
                                    name={["team", "selectPicture"]}
                                />
                            </ColStyled>
                            <ColStyled span={24}>
                                <TranslationFields
                                    fields={[
                                        { path: ["team", "title"], label: "teamTitle" },
                                        { path: ["team", "subtitle"], label: "teamSubtitle" }
                                    ]}
                                    langs={companyLanguages}
                                />

                                <ImageUpload
                                    form={form}
                                    image={form.getFieldValue(["team", "picture"])}
                                    name={["team", "picture"]}
                                />
                            </ColStyled>
                        </Card>
                    </ColStyled>
                    <ColStyled>
                        <Card title={<FormattedMessage id="connectPage" />}>
                            <TranslationFields
                                fields={[
                                    { path: ["connect", "title"], label: "title" },
                                    { path: ["connect", "subtitle"], label: "subtitle" }
                                ]}
                                langs={companyLanguages}
                            />
                            <ImageUpload
                                form={form}
                                image={form.getFieldValue(["connect", "picture"])}
                                name={["connect", "picture"]}
                            />
                        </Card>
                    </ColStyled>
                </CollapsePanel>
            </ConditionCollapse>
            <FlexButtonWrapper
                fullWidth
                justifyContent="center"
                margin="20px 0"
            >
                <Button
                    key="button1"
                    htmlType="submit"
                    type="primary"
                >
                    <FormattedMessage id="company.save" />
                </Button>
            </FlexButtonWrapper>
        </Form>
    );
};
