export interface CoachType {
    value: string;
    label: string;
}

export const coachTypes: CoachType[] = [
    {
        value: "sport",
        label: "Sport"
    },
    {
        value: "food",
        label: "Food"
    },
    {
        value: "popup",
        label: "Popup"
    },
    {
        value: "aida sels",
        label: "Aïda Sels"
    },
    {
        value: "veronique de clerq",
        label: "Véronique De Clerq"
    },
    {
        value: "none",
        label: "None"
    },
    {
        value: "mama",
        label: "Mama"
    },
    {
        value: "baloiseVM",
        label: "BaloiseVM"
    },
    {
        value: "baloiseNM",
        label: "BaloiseNM"
    },
    {
        value: "foodOnline",
        label: "Food Online"
    },
    {
        value: "manual",
        label: "Manual"
    }
];
