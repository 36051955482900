import { JourneyTableData } from "@components/journeyTable/journeyTable";
import { EventJourney, EventJourneyUpdateInput, GetJourneysQuery, Journey, JourneyContent, JourneyCost, JourneyFragment, PersonalJourney, PersonalJourneyUpdateInput } from "@graphql2/types";
import { JourneyFormValues } from "@pages/addJourneysPage/addJourneysPage";
import { Content } from "@pages/addJourneysPage/content/editJourneyContentCard";
import { EventJourneySettingsFormValues, PersonalJourneySettingsFormValues } from "@pages/addJourneysPage/general/journeySettingsCard";
import { languages, languages as platformLanguages } from "@pages/translationsPage/constants/languages";
import { formatDate } from "@utils/dateUtils";
import { emptyJourney } from "@utils/emptyItems/emptyJourney";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import * as environment from "environment";
import * as moment from "moment";
import uuidv4 from "uuid/v4";
import { getText } from "../getText";

const getNumber = (num?: number | null) => {
    return num || 0;
};

// tslint:disable-next-line:max-func-body-length
// eslint-disable-next-line complexity
export const journeyToFormValues = (journey?: Journey | null): JourneyFormValues => {
    if (!journey) {
        return emptyJourney;
    }

    const {
        coachType,
        discounts,
        type,
        content,
        cost,
        imageUrl,
        registration,
        sequence,
        subTitle,
        title,
        comingSoon,
        hideInList,
        publishOn,
        expiresOn,
        id,
        tagIds,
        enabledLocales
    } = journey;

    // Fields available in all journeys
    const baseJourney = {
        id,
        cost: cost ? getNumber(cost.amount) : 0,
        currency: cost ? cost.currency : "EUR",
        discounts: {
            disabled: !(discounts && discounts[0]),
            ...(discounts && discounts[0])
        },
        imageUrl,
        maximumParticipants: registration.maxParticipants || 0,
        maximumParticipantsEnabled: registration.enableMaxParticipants || false,
        sequence,
        subTitle: toLanguageObject(subTitle),
        title: toLanguageObject(title),
        type,
        ultimateTimeToRegister: moment(registration.ultimateTime || undefined),
        ultimateTimeToRegisterEnabled: registration.enableUltimateTime || false,
        voucherRequired: registration.voucherRequired || false,
        coachType,
        tags: tagIds,
        comingSoon: comingSoon || false,
        hideInList: hideInList || false,
        content: content.map(c => ({
            id: uuidv4(),
            type: c.type || "",
            imageUrl: c.imageUrl || "",
            url: c.url || "",
            imageCaption: c.imageCaption || "",
            title: toLanguageObject(c.title),
            description: toLanguageObject(c.description)
        })),
        publishOn: moment(publishOn || undefined),
        expiresOn: moment(expiresOn || undefined),
        newRoles: registration.newRoles || [],
        enabledLocales: !enabledLocales || !enabledLocales.length
            ? environment.languages
            : enabledLocales
    };

    // Fields available in event journeys
    const { event } = journey as EventJourney;
    if (event) {
        return {
            ...baseJourney,
            date: moment(event.date),
            detailedLocation: toLanguageObject(event.detailedLocation),
            distance: event.distance || 0,
            eventCost: event.cost ? getNumber(event.cost.amount) : 0,
            eventCurrency: event.cost ? event.cost.currency : "EUR",
            eventDescription: toLanguageObject(event.description),
            eventTitle: toLanguageObject(event.title),
            imageUrl,
            location: toLanguageObject(event.location)
        };
    }

    // Fields available in personal journeys
    const { duration } = journey as PersonalJourney;
    if (duration) {
        return {
            ...baseJourney,
            duration: duration.duration
        };
    }

    return baseJourney;
};

const emptyContent = () => ({
    id: uuidv4(),
    title: {},
    subTitle: {},
    description: {},
    type: "",
    imageCaption: "",
    imageUrl: "",
    url: ""
});

export const contentFormValuesToJourneyContent = (formValues: Content = emptyContent()): JourneyContent => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...values } = formValues;

    return {
        ...values,
        title: toTextByLanguage(formValues.title),
        // SubTitle is not used by frontend, but required by backend.
        subTitle: languages.map(lang => ({ language: lang.code, text: " " })),
        description: toTextByLanguage(formValues.description)
    };
};

const mapCost = (cost: number, currency: string): JourneyCost | null => {
    return {
        amount: getNumber(cost),
        currency,
        title: platformLanguages.map(lang => ({
            language: lang.code,
            text: "-"
        })),
        description: platformLanguages.map(lang => ({
            language: lang.code,
            text: "-"
        }))
    };
};

// tslint:disable-next-line:max-func-body-length
export const formValuesToJourney = (formValues: JourneyFormValues): EventJourneyUpdateInput | PersonalJourneyUpdateInput | JourneyFragment => {
    const {
        coachType,
        cost,
        currency,
        discounts,
        imageUrl,
        maximumParticipants,
        maximumParticipantsEnabled,
        sequence,
        tags,
        type,
        ultimateTimeToRegister,
        ultimateTimeToRegisterEnabled,
        voucherRequired,
        title,
        subTitle,
        content,
        publishOn,
        expiresOn,
        comingSoon,
        hideInList,
        newRoles,
        enabledLocales
    } = formValues;

    const { eventCost, eventCurrency, eventTitle, eventDescription, date, distance, location, detailedLocation } = formValues as EventJourneySettingsFormValues;
    const { duration } = formValues as PersonalJourneySettingsFormValues;

    const defaultLangObjects = platformLanguages.map(lang => ({
        language: lang.code,
        text: ""
    }));

    const baseJourney = {
        coachType,
        content: content ? content.map(contentFormValuesToJourneyContent) : [],
        cost: mapCost(cost, currency),
        id: "",
        imageUrl: imageUrl || "",
        participants: 0,
        registration: {
            enableMaxParticipants: maximumParticipantsEnabled,
            enableUltimateTime: ultimateTimeToRegisterEnabled,
            maxParticipants: maximumParticipants,
            ultimateTime: ultimateTimeToRegister && formatDate(ultimateTimeToRegister),
            voucherRequired,
            newRoles
        },
        sequence,
        subTitle: toTextByLanguage(subTitle),
        tagIds: tags,
        tags: [],
        title: toTextByLanguage(title),
        type,
        comingSoon,
        hideInList,
        publishOn: formatDate(publishOn),
        expiresOn: formatDate(expiresOn),
        discounts: discounts.disabled ? [] : [{
            roles: [environment.defaultDiscountRole],
            amount: discounts.amount,
            type: discounts.type
        }],
        enabledLocales: (enabledLocales || []).sort() === environment.languages.sort()
            ? []
            : enabledLocales
    };

    // Fix for apollo and TS
    delete (baseJourney as { participants?: number; }).participants;
    delete (baseJourney as { id?: string; }).id;
    delete (baseJourney as { tags?: never[]; }).tags;

    if (baseJourney.type === "event") {
        return {
            ...baseJourney,
            event: {
                cost: {
                    amount: getNumber(eventCost),
                    currency: eventCurrency,
                    title: defaultLangObjects,
                    description: defaultLangObjects
                },
                date: date ? formatDate(date) : Date.now(),
                description: toTextByLanguage(eventDescription),
                detailedLocation: toTextByLanguage(detailedLocation),
                distance,
                location: toTextByLanguage(location),
                title: toTextByLanguage(eventTitle),
                position: {
                    latitude: 0,
                    longitude: 0
                }
            }
        };
    }

    return {
        ...baseJourney,
        duration: {
            duration: duration || 0,
            unitType: "month"
        }
    };
};

export const translateJourneys = (originals: GetJourneysQuery["journeys"]): JourneyTableData[] => {
    return originals.map(journey => {
        const { id, title, type, coachType, participants } = journey;

        return {
            id,
            title: getText(title),
            type,
            coachType,
            participants
        };
    });
};
