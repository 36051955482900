import styled from "styled-components";
import { Layout } from "antd";

export const HeaderContent = styled(Layout.Header)`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    border-bottom: 1px solid lightgray;

    .headerButton {
      text-shadow: none;
      box-shadow: none;
      margin-left: 10px;
    }
`;
