import { Field } from "@components/field/field";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ImageUpload } from "@components/imageUpload/imageUpload";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { InternalNamePath } from "antd/lib/form/interface";
import React from "react";
import { FormattedMessage } from "react-intl";

export const UrlImageFields: React.FC<{prefix: InternalNamePath; form: FormInstance;}> = ({ prefix, form }) => {
    return (
        <React.Fragment>
            <Field
                id="url"
                label={<FormattedMessage id="url" />}
                name={[...prefix, "url"]}
            />
            <Form.Item label={<FormattedMessage id="image" />}>
                <ImageUpload
                    cropSettings={{
                        aspect: ImageRatios.DEFAULT,
                        rotate: true,
                        zoom: true
                    }}
                    form={form}
                    image={form.getFieldValue([...prefix, "image"])}
                    name={[...prefix, "image"]}
                />
                <Field
                    hidden
                    id="imageUrl"
                    name={[...prefix, "image"]}
                />
            </Form.Item>
        </React.Fragment>
    );
};
