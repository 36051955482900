import * as React from "react";
import { Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Column from "antd/lib/table/Column";

export const renderErrorColumn = () => {
    return (
        <Column
            width={10}
            dataIndex="hasError"
            render={(hasError) => hasError && (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    <FormattedMessage id="error" />
                </Tag>
            )}
        />
    );
};
