import { AddCheckupPageProps } from "@pages/addCheckupPage/addCheckupPageContainer";
import {
    CheckupLocationAvailabilityModal,
    CheckupLocationAvailabilityModalFormValues
} from "@pages/addCheckupPage/checkupLocationAvailabilityModal/checkupLocationAvailabilityModal";
import { CheckupPageContainer } from "@pages/checkupPage/checkupPageContainer";
import { formValuesToCheckupLocationAvailability, checkupLocationAvailabilityToFormValues } from "@utils/mappers/checkupMapper";
import * as React from "react";
import { handleError } from "@utils/form/handlers";
import { FormattedMessage } from "react-intl";

export type CheckupLocationAvailabilityFormValues = { id?: string } & CheckupLocationAvailabilityModalFormValues;

export const AddCheckupPage: React.FC<AddCheckupPageProps> = (props) => {
    const { data: checkupLocationAvailabilitiesData, users: { users }, checkupLocations, history, match: { params } } = props;

    const checkup = React.useMemo(
        () => checkupLocationAvailabilityToFormValues(checkupLocationAvailabilitiesData?.checkupLocationAvailabilities?.[0]),
        [checkupLocationAvailabilitiesData?.checkupLocationAvailabilities]
    );

    const submitForm = async (values: CheckupLocationAvailabilityFormValues) => {
        try {
            if (params.id) {
                await props.updateCheckupLocationAvailability({
                    variables: {
                        id: params.id,
                        ...formValuesToCheckupLocationAvailability(values)
                    }
                });
            } else {
                await props.addCheckupLocationAvailability({ variables: formValuesToCheckupLocationAvailability(values) });
            }
            props.history.push("/checkups");
        } catch (error) {
            const errMsg = params.id ? "checkups.updateFailed" : "checkups.addFailed";
            handleError(<FormattedMessage id={errMsg} />);
        }
    };

    return (
        <React.Fragment>
            <CheckupPageContainer />
            <CheckupLocationAvailabilityModal
                history={history}
                id={params.id}
                defaultValues={checkup}
                coaches={users}
                locations={checkupLocations.checkupLocations}
                onSubmit={submitForm}
            />
        </React.Fragment>
    );
};
