import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { languages } from "@pages/translationsPage/constants/languages";
import { createOnChange } from "@utils/createOnChange";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ArticleLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface TitleAndDescriptionFormValues {
    title: LanguageObject;
    description: LanguageObject;
}

export interface TitleAndDescriptionCardProps {
    hidden?: boolean;
    defaultValues: Partial<TitleAndDescriptionFormValues>;
    disabled?: boolean;
    unsaved?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onChange?(key: string): void;
}

const titleField = (props: ArticleLanguageFieldProps) => (
    <Field
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        hidden={props.hidden}
        key={`title.${props.language}`}
        label={<FormattedMessage id="title" />}
        info={maxCharacters()}
        id={`title.${props.language}`}
        {...props.sharedProps}
    />
);

const descriptionField = (props: ArticleLanguageFieldProps) => (
    <Field
        name={["description", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        hidden={props.hidden}
        key={`description.${props.language}`}
        label={<FormattedMessage id="description" />}
        info={maxCharacters()}
        id={`description.${props.language}`}
        {...props.sharedProps}
    />
);

export class TitleAndDescriptionCard extends React.Component<TitleAndDescriptionCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { changeSelectedLanguage, hidden, defaultValues, languageErrors, disabled, unsaved, onChange, activeLanguage } = this.props;
        const sharedProps = {
            disabled,
            onBlur: onChange
        };

        const langFieldProps = languages.map(language => ({ sharedProps, defaultValues, language: language.code, hidden: language.code !== activeLanguage }));

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="titleAndDescription" />}
                    rightFields={unsaved && [
                        <UnsavedChanges key="warning" />
                    ]}
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                    errors={languageErrors}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(descriptionField)}
            </Card>
        );
    }
}
