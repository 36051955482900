import { CheckCircleFilled, DeleteOutlined, MinusCircleFilled, StopOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableIcon } from "@components/table/tableStyle";
import { ArticleDeleteItem } from "@pages/articlesPage/articlesPage";
import { Popconfirm, Table, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";

export interface CommentsTableData extends TableData {
    displayName?: string | null;
    userId?: string | null;
    comment: string;
    parentId?: string | null;
    reported: boolean;
    date: number;
    status: boolean;
    statusType: string;
}

export interface CommentsTableProps extends TableComponentProps {
    dataSource: CommentsTableData[];
    handlers: TableHandlers & {
        removeArticle(id: string): void;
        handleSelectedArticlesOnChange(articles: ArticleDeleteItem[]): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<CommentsTableData>;
}

export const CommentsTable: React.FC<CommentsTableProps> = ({ handlers, ...tableProps }) => {
    const renderStatus = (status: boolean, row: CommentsTableData) => {
        if (status && row.statusType === "active") {
            return (
                <Tooltip title={row.statusType}>
                    <CheckCircleFilled className="green" />
                </Tooltip>
            );
        }

        return (
            <Tooltip title={row.statusType}>
                <MinusCircleFilled className={row.statusType} />
            </Tooltip>
        );
    };

    const renderUser = (user: string) => {
        if (!user) {
            return <i><FormattedMessage id="comment.userNotFound" /></i>;
        }

        return user;
    };

    const renderHandlers = (row: CommentsTableData): Handlers => {
        const moderated = row.statusType === "moderated";
        return {
            children: (
                <Flex justifyContent="flex-end" alignItems="center">
                    {moderated ? (
                        <Tooltip title="Already moderated">
                            <TableIcon as={StopOutlined} />
                        </Tooltip>
                    ) : (
                        <Popconfirm
                            title={<FormattedMessage id="moderateConfirm" />}
                            disabled={moderated}
                            onConfirm={() => handlers.moderateComment(row.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <TableIcon as={StopOutlined} />
                        </Popconfirm>
                    )}
                    <Popconfirm title={<FormattedMessage id="removeConfirm" />} onConfirm={() => handlers.removeComment(row.id, row.userId)} okText="Yes" cancelText="No">
                        <TableIcon as={DeleteOutlined} />
                    </Popconfirm>
                </Flex>
            ),
            props: {
                colSpan: 2
            }
        };
    };

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="status"
                title={<FormattedMessage id="status" />}
                dataIndex="status"
                width={70}
                render={renderStatus}
                sorter={(a, b) => Number(b.status) - Number(a.status)}
            />
            <Column
                key="displayName"
                title={<FormattedMessage id="user" />}
                dataIndex="displayName"
                width={120}
                render={renderUser}
                sorter={(a: CommentsTableData, b) => (a.displayName || "").localeCompare((b.displayName || ""))}
            />
            <Column
                key="comment"
                title={<FormattedMessage id="comment" />}
                dataIndex="comment"
                width={250}
                sorter={(a: CommentsTableData, b) => a.comment.localeCompare(b.comment)}
            />
            <Column
                key="parentId"
                title={<FormattedMessage id="replyOn" />}
                dataIndex="parentId"
                width={150}
                sorter={(a: CommentsTableData, b) => (a.parentId || "").localeCompare((b.parentId || ""))}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: CommentsTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
