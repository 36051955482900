import uuidv4 from "uuid/v4";
import { cloneDeep } from "lodash";
import { ChallengeFragment } from "@graphql2/types";

const getIdsToModify = (challenge: ChallengeFragment) => {
    return challenge!.conditions!.map(condition => condition!.id);
};

const updatePropertyValue = (obj: ChallengeFragment, oldValue: string, newValue: string) => {
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updatePropertyValue(obj[key], oldValue, newValue);
        } else if (obj[key] === oldValue) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = newValue;
        }
    });
};

export const modifyConditionsIds = (inputChallenge: ChallengeFragment) => {
    if (!inputChallenge.conditions || !inputChallenge.conditions.length) {
        return inputChallenge;
    }

    const modifiedChallenge = cloneDeep(inputChallenge);
    const oldIds = getIdsToModify(modifiedChallenge) as string[];
    oldIds.forEach(oldId => {
        const newId = uuidv4();
        updatePropertyValue(modifiedChallenge, oldId, newId);
    });

    return modifiedChallenge;
};
