import * as React from "react";
import { Card, Button } from "antd";
import { TableContainer } from "@components/table/tableContainer";
import { SocialTagsTable } from "@components/social/socialTagsTable";
import { Flex } from "@components/flex/flex";
import { FormattedMessage } from "react-intl";
import { SocialFilterWordsTable } from "@components/social/socialFilterWordsTable";
import { GetSocialFilterWordsProps, GetSocialTagsProps } from "@graphql2/types";

export type GeneralCardProps = GetSocialFilterWordsProps<{}, "socialFilterWords"> & GetSocialTagsProps<{}, "socialTags"> & {
    hidden: boolean;
    updateTag(tag: string): void;
    removeTag(tag: string): void;
    updateWord(word: string): void;
    removeWord(word: string): void;
    addItem(type: string): void;
};

export const GeneralCard: React.FC<GeneralCardProps> = (props) => {
    const {
        hidden,
        socialTags,
        socialFilterWords,
        removeTag,
        updateTag,
        removeWord,
        updateWord,
        addItem
    } = props;

    return (
        <Flex direction="column" fullWidth>
            <Card hidden={hidden}>
                <Flex direction="column">
                    <Flex fullWidth justifyContent="space-between">
                        <FormattedMessage id="tags" tagName="h1" />
                        <Button key="button1" type="primary" onClick={() => addItem("tag")}>
                            <FormattedMessage id="social.addTag" />
                        </Button>
                    </Flex>
                    <TableContainer
                        tableFilter={{}}
                        loading={socialTags && socialTags.loading}
                        TableComponent={SocialTagsTable}
                        dataSource={(socialTags && (socialTags.socialTags || []).map(s => ({ ...s, id: "" }))) || []}
                        handlers={{
                            removeTag,
                            updateTag
                        }}
                    />
                </Flex>
            </Card>
            <Card hidden={hidden}>
                <Flex direction="column">
                    <Flex fullWidth justifyContent="space-between">
                        <FormattedMessage id="words" tagName="h1" />
                        <Button key="button1" type="primary" onClick={() => addItem("word")}>
                            <FormattedMessage id="social.addWord" />
                        </Button>
                    </Flex>
                    <TableContainer
                        tableFilter={{}}
                        loading={socialFilterWords && socialFilterWords.loading}
                        TableComponent={SocialFilterWordsTable}
                        dataSource={(socialFilterWords && (socialFilterWords.socialFilterWords || []).map(s => ({ ...s, id: "" }))) || []}
                        handlers={{
                            removeWord,
                            updateWord
                        }}
                    />
                </Flex>
            </Card>
        </Flex>
    );
};
