import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Card, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Form from "antd/lib/form";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "@components/field/field";
import { DisableState } from "../creditsPage";

export type SpentCreditsLimitFormValues = {
    enableSpentCreditsLimit: boolean;
    spentCreditsLimitValue: number|undefined;
    countForLastXDays?: number;
    countInCollectionPeriod?: boolean;
}

export type SpentCreditsLimitCardProps = {
    checkBoxChangeHandler: (event: CheckboxChangeEvent) => void
    disableState: DisableState
}

export const SpentCreditsLimitCard: FC<SpentCreditsLimitCardProps> = ({ checkBoxChangeHandler, disableState }) => {
    const { creditLimitOptions, countInCollectionPeriod, countForLastXDays } = disableState;

    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="webshop.credits.spentCreditsLimit" />}
            />
            <Form.Item
                name="enableSpentCreditsLimit"
                valuePropName="checked"
            >
                <Checkbox onChange={checkBoxChangeHandler}>
                    <FormattedMessage id="webshop.credits.spentCreditsLimit.enable" />
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Field
                    name="spentCreditsLimitValue"
                    label={<FormattedMessage id="webshop.credits.spentCreditsLimitValue" />}
                    numberType
                    min={0}
                    disabled={creditLimitOptions}
                    rules={[
                        {
                            required: !creditLimitOptions,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Field
                    name="countForLastXDays"
                    label={<FormattedMessage id="webshop.credits.countForLastXDays" />}
                    numberType
                    min={0}
                    disabled={creditLimitOptions || countForLastXDays}
                    rules={[
                        {
                            required: !creditLimitOptions && !countForLastXDays,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                />
            </Form.Item>
            <Form.Item
                name="countInCollectionPeriod"
                valuePropName="checked"
            >
                <Checkbox
                    disabled={creditLimitOptions || countInCollectionPeriod}
                    onChange={checkBoxChangeHandler}
                >
                    <FormattedMessage id="webshop.credits.countInCollectionPeriod" />
                </Checkbox>
            </Form.Item>
        </Card>
    );
};
