import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

import { withChallenges, withChallengeTeams, withFilterQueryState } from "@pages/challengeTeamPage/challengeTeamPageContainer";
import { ChallengeMemberPage } from "@pages/challengeTeamPage/teams/challengeMembersPage";
import { GetChallengesBasicProps, GetChallengeTeamsProps, RemoveChallengeTeamProps, RemoveFromChallengeTeamProps, UpdateChallengeTeamCaptainProps, withRemoveChallengeTeam, withRemoveFromChallengeTeam, withUpdateChallengeTeamCaptain } from "../../../graphql2/types";

const withUpdateChallengeTeamCaptainMutation = withUpdateChallengeTeamCaptain({
    name: "updateChallengeTeamCaptain",
    options: {
        refetchQueries: () => ["getChallengeTeams"],
        awaitRefetchQueries: true
    }
});

const withRemoveFromChallengeTeamMutation = withRemoveFromChallengeTeam({
    name: "removeMemberFromTeam",
    options: {
        refetchQueries: () => ["getChallengeTeams"],
        awaitRefetchQueries: true
    }
});

const withRemoveChallengeTeamMutation = withRemoveChallengeTeam({
    name: "removeTeam",
    options: {
        refetchQueries: ["getChallengeTeams", "getChallengeTeamCount"],
        awaitRefetchQueries: true
    }
});

export const ChallengeMemberPageContainer = compose<React.FC<ChallengeMemberPageProps>>(
    withFilterQueryState,
    withUpdateChallengeTeamCaptainMutation,
    withRemoveFromChallengeTeamMutation,
    withRemoveChallengeTeamMutation,
    withChallengeTeams,
    withChallenges
)(ChallengeMemberPage);

export type ChallengeMemberPageProps =
    GetChallengeTeamsProps<{}, "challengeTeams"> &
    GetChallengesBasicProps<{}, "challenges"> &
    UpdateChallengeTeamCaptainProps<{}, "updateChallengeTeamCaptain"> &
    RemoveFromChallengeTeamProps<{}, "removeMemberFromTeam"> &
    RemoveChallengeTeamProps<{}, "removeTeam"> &
    RouteComponentProps<{ id: string; teamId: string; }>;
