import { CheckupPage } from "@pages/checkupPage/checkupPage";
import { localState, LocalStateProps } from "@utils/withLocalState";
import { SortAndPaging, defaultSortAndPagingSettings } from "@utils/withPagingAndSort";
import * as React from "react";
import { compose } from "redux";
import { withGetCheckupLocationAvailabilities, withGetCheckupLocationAvailabilityCount, withRemoveCheckupLocationAvailability, withAddCheckupLocationAvailability, withGetCheckupLocations, GetCheckupLocationAvailabilitiesProps, RemoveCheckupLocationAvailabilityProps, GetCheckupLocationsProps, AddCheckupLocationAvailabilityProps, GetCheckupLocationAvailabilityCountProps, CheckupLocationAvailabilityFilter } from "../../graphql2/types";

interface CheckupPageContainerProps {

}

interface LocalState extends SortAndPaging {
    filter?: CheckupLocationAvailabilityFilter;
}

const withLocalState = localState<LocalState>({
    ...defaultSortAndPagingSettings
});

// Define today only once, or else apollo will loop the call
const today = Date.now();

const withCheckups = withGetCheckupLocationAvailabilities({
    options: (ownProps: LocalState) => ({
        variables: {
            filter: ownProps.filter || {
                from: today
            },
            paging: ownProps.paging,
            sort: ownProps.sort || {
                field: "from",
                order: "ASC"
            }
        }
    })
});

const withCheckupCount = withGetCheckupLocationAvailabilityCount({
    name: "count",
    options: (ownProps: LocalState) => ({
        variables: {
            filter: ownProps.filter || {
                from: today
            }
        }
    })
});

const withRemoveCheckupMutation = withRemoveCheckupLocationAvailability({
    name: "removeCheckupLocationAvailability",
    options: {
        refetchQueries: ["getCheckupLocationAvailabilities", "getCheckupLocationAvailabilityCount"]
    }
});

const withAddCheckupMutation = withAddCheckupLocationAvailability({
    name: "addCheckupLocationAvailability",
    options: {
        refetchQueries: ["getCheckupLocationAvailabilities", "getCheckupLocationAvailabilityCount"]
    }
});

const withCheckupLocations = withGetCheckupLocations({
    name: "checkupLocations"
});

export const CheckupPageContainer = compose<React.ComponentClass<CheckupPageContainerProps>>(
    withLocalState,
    withCheckups,
    withRemoveCheckupMutation,
    withCheckupLocations,
    withCheckupCount,
    withAddCheckupMutation
)(CheckupPage);

export type CheckupPageProps =
    LocalStateProps<LocalState> &
    CheckupPageContainerProps &
    GetCheckupLocationAvailabilitiesProps &
    GetCheckupLocationAvailabilityCountProps<{}, "count"> &
    RemoveCheckupLocationAvailabilityProps<{}, "removeCheckupLocationAvailability"> &
    GetCheckupLocationsProps<{}, "checkupLocations"> &
    AddCheckupLocationAvailabilityProps<{}, "addCheckupLocationAvailability">;
