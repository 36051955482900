import { DashboardConfigWidgetCommunity, DashboardConfigWidgetCommunityInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetCommunityFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetCommunity, {
    title: LanguageObject;
}>>;

export const mapCommunityStateWidgetToCommunityWidget = (widget: DashboardConfigWidgetCommunityFormValues): DashboardConfigWidgetCommunityInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title),
    };
};

export const mapCommunityWidgetToCommunityStateWidget = (genericWidget: DashboardConfigWidgetCommunity): DashboardConfigWidgetCommunityFormValues => {
    return {
        ...genericWidget,
        type: genericWidget.type as ViewType,
        title: toLanguageObject(genericWidget.title),
    };
};
