import { ContentHeader } from "@components/contentHeader/contentHeader";
import { StepFormValues } from "@utils/emptyItems/emptyFlow";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { Button, Card } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SingleStepCard } from "./singleStepCard";

interface StepsCardProps {
    fieldErrors: any;
    stepsData: StepFormValues[] | undefined;
    form: FormInstance;
}

export const StepsCard = ({ fieldErrors, stepsData, form } : StepsCardProps) => {
    return (
        <Card>
            <ContentHeader title={<FormattedMessage id="flows.addSteps" />} />
            <Form.List name="steps">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map((field) => (
                            <SingleStepCard
                                key={stepsData?.[field.key]?.id ?? field.key}
                                field={field}
                                form={form}
                                languageErrors={errorsToLanguageErrors({
                                    title: fieldErrors?.steps?.[field.key]?.title,
                                    description: fieldErrors?.steps?.[field.key]?.description
                                })}
                                remove={remove}
                                stepData={stepsData?.[field.key]}
                            />
                        ))}
                        <Button
                            block
                            type="dashed"
                            onClick={() => add()}
                        >
                            + Add Step
                        </Button>
                    </div>
                )}
            </Form.List>
        </Card>
    );
};
