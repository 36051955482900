/* eslint-disable camelcase */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { AppstoreOutlined, CalendarOutlined, CrownOutlined, DashboardOutlined, EditOutlined, PictureOutlined, ScheduleOutlined, SettingOutlined, ShareAltOutlined, ShopOutlined, StarOutlined, UserOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { NavGroupItem } from "@components/nav/navGroupItem";
import { NavItem } from "@components/nav/navItem";
import { FixedSideBar, Logo, VersionNumber } from "@components/nav/navStyles";
import { Avatar, Menu } from "antd";
import * as React from "react";
import { version } from "../../version";
import { useGetAllRoles } from "./useGetAllRoles";

export interface NavProps {
    collapsed: boolean;
}

export const Nav: React.FC<NavProps> = ({ collapsed }) => {
    const roles = useGetAllRoles();
    const {
        Challenge_admin, Content_admin, Translation_admin,
        Journey_admin, Voucher_admin, Article_admin,
        Checkup_location_admin, Ambassador_admin, Dashboard_admin,
        Gallery_admin, Translation_super_admin, Event_admin,
        Event_Super_admin, Social_admin, User_admin, Credits_admin, Project_admin, Flow_admin
    } = roles;

    const { pathname } = window.location;
    const path = pathname.split("/");
    let mainPath = path[1];
    let itemPath = path[2];
    const additionalItemPath = path[3];
    if (mainPath === "articles") {
        mainPath = "webshop";
        itemPath = "articles";
    } else if (mainPath === "orders") {
        mainPath = "webshop";
        itemPath = "orders";
    } else if (mainPath === "checkups") {
        mainPath = "journeys";
        itemPath = "checkups";
    } else if (itemPath === "translations") {
        itemPath = `${itemPath}_${additionalItemPath}`;
    } else if (mainPath === "dashboards" && itemPath === "edit") {
        itemPath = "overview";
    } else if (itemPath === "edit") {
        itemPath = mainPath;
    } else if (mainPath === "social") {
        itemPath = "general";
    } else if (mainPath === "users" && itemPath === "detail") {
        itemPath = "overview";
    } else if (mainPath === "ambassador" && itemPath === "ambassadors") {
        itemPath = "ambassadorConfigs";
    }

    const itemPathMenu = `${mainPath}_${itemPath || mainPath}`;

    return (
        <FixedSideBar
            collapsible
            collapsed={collapsed}
            trigger={null}
        >
            <Flex
                alignItems="center"
                wrap="wrap"
            >
                <Logo>
                    <Avatar
                        size="small"
                        src="https://res.cloudinary.com/energylab/image/upload/v1593509749/development/logo_energylab.png"
                    />
                </Logo>
                {!collapsed && <VersionNumber>{version}</VersionNumber>}
            </Flex>
            <Menu
                defaultOpenKeys={[mainPath]}
                defaultSelectedKeys={[itemPathMenu]}
                inlineCollapsed={collapsed}
                mode="inline"
                theme="dark"
            >
                {/* AMBASSADOR */}
                <NavGroupItem
                    key="ambassador"
                    icon={<CrownOutlined />}
                    roles={[Ambassador_admin]}
                    translation="ambassadors.overview"
                >
                    <NavItem
                        key="ambassador_ambassadorConfigs"
                        role={Ambassador_admin}
                        to="/ambassador/ambassadorConfigs"
                        translation="ambassadors.overview"
                    />
                    <NavItem
                        key="ambassador_tags"
                        role={Ambassador_admin}
                        to="/ambassador/tags"
                        translation="tags"
                    />
                </NavGroupItem>

                {/* CHALLENGES */}
                <NavGroupItem
                    key="challenges"
                    icon={<StarOutlined />}
                    roles={[Challenge_admin]}
                    translation="challenges"
                >
                    <NavItem
                        key="challenges_challenges"
                        role={Challenge_admin}
                        to="/challenges"
                        translation="challenges"
                    />
                    <NavItem
                        key="challenges_tags"
                        role={Challenge_admin}
                        to="/challenges/tags"
                        translation="tags"
                    />
                </NavGroupItem>

                {/* CONTENT */}
                <NavGroupItem
                    key="content"
                    icon={<EditOutlined />}
                    roles={[Content_admin, Translation_admin, Translation_super_admin]}
                    translation="content"
                >
                    <NavItem
                        key="content_contents"
                        role={Content_admin}
                        to="/content/contents"
                        translation="content"
                    />
                    <NavItem
                        key="content_tags"
                        role={Content_admin}
                        to="/content/tags"
                        translation="tags"
                    />
                    <NavItem
                        key="content_translations_project"
                        role={Translation_admin}
                        to="/content/translations/project"
                        translation="translations.projectSpecific"
                    />
                    <NavItem
                        key="content_translations_generic"
                        role={Translation_super_admin}
                        to="/content/translations/generic"
                        translation="translations.generic"
                    />
                </NavGroupItem>

                {/* EVENTS */}
                <NavGroupItem
                    key="event"
                    icon={<ScheduleOutlined />}
                    roles={[Event_admin, Event_Super_admin]}
                    translation="events"
                >
                    <NavItem
                        key="event_events"
                        role={Event_Super_admin || Event_admin}
                        to="/event/events"
                        translation="events"
                    />
                    <NavItem
                        key="event_tags"
                        role={!Event_Super_admin && Event_admin}
                        to="/event/tags"
                        translation="tags"
                    />
                    <NavItem
                        key="event_forms"
                        role={!Event_Super_admin && Event_admin}
                        to="/event/forms"
                        translation="forms"
                    />
                    <NavItem
                        key="event_groups"
                        role={!Event_Super_admin && Event_admin}
                        to="/event/groups"
                        translation="groups"
                    />
                </NavGroupItem>

                {/* GALLERIES */}
                <NavGroupItem
                    key="gallery"
                    icon={<PictureOutlined />}
                    roles={[Gallery_admin]}
                    translation="galleries.overview"
                >
                    <NavItem
                        key="gallery_galleries"
                        role={Gallery_admin}
                        to="/gallery/galleries"
                        translation="galleries.overview"
                    />
                    <NavItem
                        key="gallery_tags"
                        role={Gallery_admin}
                        to="/gallery/tags"
                        translation="tags"
                    />
                </NavGroupItem>

                {/* JOURNEYS */}
                <NavGroupItem
                    key="journeys"
                    icon={<CalendarOutlined />}
                    roles={[Journey_admin, Checkup_location_admin]}
                    translation="journeys"
                >
                    <NavItem
                        key="journeys_journeys"
                        role={Journey_admin}
                        to="/journeys"
                        translation="journeys"
                    />
                    <NavItem
                        key="journeys_tags"
                        role={Journey_admin}
                        to="/journeys/tags"
                        translation="tags"
                    />
                    <NavItem
                        key="journeys_participations"
                        experimental
                        role={Journey_admin}
                        to="/journeys/participations"
                        translation="journeys.participations"
                    />
                    <NavItem
                        key="journeys_checkups"
                        role={Checkup_location_admin}
                        to="/checkups"
                        translation="checkups"
                    />
                </NavGroupItem>

                {/* FLOWS */}
                <NavGroupItem
                    key="flows"
                    icon={<ShareAltOutlined />}
                    roles={[Flow_admin]}
                    translation="flows.flows"
                >
                    <NavItem
                        key="flows"
                        role={Flow_admin}
                        to="/flows"
                        translation="flows.flows"
                    />
                </NavGroupItem>

                {/* Vouchers */}
                <NavGroupItem
                    key="vouchers"
                    icon={<CalendarOutlined />}
                    roles={[Voucher_admin]}
                    translation="journeys.vouchers"
                >
                    <NavItem
                        key="vouchers_vouchers"
                        role={Voucher_admin}
                        to="/vouchers"
                        translation="journeys.vouchers"
                    />
                </NavGroupItem>

                {/* WEBSHOP */}
                <NavGroupItem
                    key="webshop"
                    icon={<ShopOutlined />}
                    roles={[Article_admin]}
                    translation="webshop"
                >
                    <NavItem
                        key="webshop_articles"
                        role={Article_admin}
                        to="/articles"
                        translation="webshop.articles"
                    />
                    <NavItem
                        key="webshop_orders"
                        role={Article_admin}
                        to="/orders"
                        translation="webshop.orders"
                    />
                    <NavItem
                        key="webshop_credits"
                        role={Credits_admin}
                        to="/credits"
                        translation="webshop.credits"
                    />
                </NavGroupItem>

                {/* DASHBOARD */}
                <NavGroupItem
                    key="dashboards"
                    icon={<DashboardOutlined />}
                    roles={[Dashboard_admin]}
                    translation="dashboard"
                >
                    <NavItem
                        key="dashboards_overview"
                        role={Dashboard_admin}
                        to="/dashboards/overview"
                        translation="dashboard.dashboards"
                    />
                </NavGroupItem>

                {/* SOCIAL */}
                <NavGroupItem
                    key="social"
                    icon={<AppstoreOutlined />}
                    roles={[Social_admin]}
                    translation="social.social"
                >
                    <NavItem
                        key="social_general"
                        role={Social_admin}
                        to="/social/general"
                        translation="social.social"
                    />
                </NavGroupItem>

                {/* USERS */}
                <NavGroupItem
                    key="users"
                    icon={<UserOutlined />}
                    roles={[User_admin]}
                    translation="users"
                >
                    <NavItem
                        key="users_overview"
                        role={User_admin}
                        to="/users/overview"
                        translation="users"
                    />
                    <NavItem
                        key="users_roles"
                        role={User_admin}
                        to="/users/roles"
                        translation="roles"
                    />
                    <NavItem
                        key="users_whitelists"
                        role={User_admin}
                        to="/users/whitelists"
                        translation="whitelists"
                    />
                    <NavItem
                        key="users_companies"
                        role={User_admin}
                        to="/users/companies"
                        translation="companies"
                    />
                </NavGroupItem>
                <NavGroupItem
                    key="config"
                    icon={<SettingOutlined />}
                    roles={[Project_admin]}
                    translation="config"
                >
                    <NavItem
                        key="config_main"
                        role={Project_admin}
                        to="/config/main"
                        translation="main"
                    />
                    <NavItem
                        key="config_theme"
                        role={Project_admin}
                        to="/config/theme"
                        translation="theme"
                    />

                    <NavItem
                        key="config_routes"
                        role={Project_admin}
                        to="/config/routes"
                        translation="routes"
                    />
                </NavGroupItem>
            </Menu>
        </FixedSideBar>
    );
};
