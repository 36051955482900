import styled from "styled-components";

type FlexWrap = "nowrap" | "wrap" | "wrap-reverse" | "initial" | "inherit";
type JustifyContent = "center" | "flex-start" | "flex-end" | "space-around" | "space-between";
type AlignItems = "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse" | "initial" | "inherit";

export interface FlexProps {
    wrap?: FlexWrap;
    justifyContent?: JustifyContent;
    margin?: string;
    padding?: string;
    alignItems?: AlignItems;
    direction?: FlexDirection;
    rowMargin?: string;
    fullWidth?: boolean;
    gap?: number
}

export const Flex = styled.div<FlexProps>`
    display: flex;
    ${props => props.margin ? `margin: ${props.margin};` : ""}
    ${props => props.padding ? `padding: ${props.padding};` : ""}
    ${props => props.wrap ? `flex-wrap: ${props.wrap};` : ""}
    ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
    ${props => props.alignItems ? `align-items: ${props.alignItems};` : ""}
    ${props => props.direction ? `flex-direction: ${props.direction};` : ""}
    ${props => props.fullWidth ? `width: 100%;` : ""}
    ${props => props.gap ? `gap: ${props.gap}px;` : ""}

    .ant-row + .ant-row {
        margin-left: ${props => props.rowMargin || "1em"};
    }
`;
