import { DeleteOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import React from "react";

type RemoveWrapperProps = {
    onRemove(): void;
}

export const RemoveWrapper: React.FC<RemoveWrapperProps> = (props) => {
    const { onRemove, children } = props;
    return (
        <Flex
            alignItems="flex-start"
            rowMargin="0px"
        >
            <div style={{ flex: 1 }}>
                {children}
            </div>
            <DeleteOutlined
                style={{
                    padding: 10,
                    cursor: "pointer"
                }}
                onClick={onRemove}
            />
        </Flex>
    );
};
