/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { SelectField } from "@components/field/selectField";
import { activityConditionFactory, formConditionFactory } from "@pages/addChallengesPage/factories";
import { BorderedRow, ButtonWrapper, SelectWrapper, SwitchWrapper } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCardStyle";
import { ConditionsForm } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeTypes/conditionsForm";
import { ConditionsTable } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeTypes/conditionsTable";
import { ChallengeActivityConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityCondition";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { ErrorObj } from "@pages/addJourneysPage/addJourneysPage";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { Button, Card, Checkbox, Col, Form, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

const DIFFICULTY_LEVELS = [1, 2, 3, 4];

export type ChallengeConditionFormValuesUnion = ChallengeFormConditionFormValues | ChallengeActivityConditionFormValues;

export interface ChallengeEndlessConditionFormValues {
    enabled: boolean;
    activityTypes: string[];
    unitType: string;
    rankingAlgorithm: string;
}

export interface ChallengeConditionsFormValues {
    conditions?: ChallengeConditionFormValuesUnion[];
    endless?: ChallengeEndlessConditionFormValues;
    difficultyLevel: number;
    exceedConditionLimit: boolean;
    conditionsInOrder: boolean;
}

export interface ChallengeConditionsCardProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    hasError?: ErrorObj;
    languageErrors?: LanguageErrors;
    errors?: ErrorDictionary;
    editCondition(index: number): void;
    onDownload(id: string): void;
    onBlur(): void;
}

export interface ChallengeConditionsCardState {

}

export const ChallengeConditionsCard: React.FC<ChallengeConditionsCardProps> = (props) => {
    const { form, hidden, disabled, onBlur, onDownload, editCondition, errors } = props;

    const sharedProps = {
        disabled,
        onBlur
    };

    const removeCondition = (remove: (index) => void, index: number) => {
        onBlur();
        remove(index);
    };

    const addCondition = (add: (values) => void, values: any) => {
        onBlur();
        add(values);
    };

    const renderConditions = () => {
        const endlessEnabled = form.getFieldValue(["endless", "enabled"]);
        return (
            <React.Fragment>
                <ConditionsForm
                    form={form}
                    hidden={!endlessEnabled}
                    {...sharedProps}
                />
                <Form.List name="conditions">
                    {(fields, { add, remove, move }) => {
                        const fetchedConditions = form.getFieldValue("conditions");

                        return (
                            <React.Fragment>
                                <ConditionsTable
                                    conditions={fetchedConditions || []}
                                    editCondition={editCondition}
                                    errors={errors}
                                    hidden={endlessEnabled}
                                    move={move}
                                    remove={(index: number) => removeCondition(remove, index)}
                                    onDownload={onDownload}
                                />
                                {!endlessEnabled && (
                                    <ButtonWrapper>
                                        <Button
                                            type="primary"
                                            onClick={() => addCondition(add, activityConditionFactory())}
                                        >
                                            <FormattedMessage id="addActivityCondition" />
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => addCondition(add, formConditionFactory(fetchedConditions.length))}
                                        >
                                            <FormattedMessage id="addFormCondition" />
                                        </Button>
                                    </ButtonWrapper>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Form.List>
                {!endlessEnabled && (
                    <Form.Item
                        name="conditionsInOrder"
                        valuePropName="checked"
                    >
                        <Checkbox
                            {...sharedProps}
                        >
                            <FormattedMessage id="conditionsInCompletedOrder" />
                        </Checkbox>
                    </Form.Item>
                )}
            </React.Fragment>
        );
    };

    const renderDifficulty = () => {
        return (
            <SelectWrapper>
                <SelectField
                    label={<FormattedMessage id="difficulty" />}
                    name="difficultyLevel"
                    rules={[{
                        required: true,
                        message: <FormattedMessage id="form.isrequired" />
                    }]}
                    {...sharedProps}
                >
                    {DIFFICULTY_LEVELS.map(
                        level => (
                            <Select.Option
                                key={level}
                                value={level}
                            >
                                {level}
                            </Select.Option>
                        )
                    )}
                </SelectField>
            </SelectWrapper>
        );
    };

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="challenge.tabs.conditions" />}
            />
            <BorderedRow
                align="middle"
                gutter={16}
                type="flex"
            >
                <Col span={8}>
                    <SwitchWrapper>
                        <Form.Item
                            name={["endless", "enabled"]}
                            valuePropName="checked"
                        >
                            <Switch
                                {...sharedProps}
                            />
                        </Form.Item>
                        <FormattedMessage id="challenge.endlessChallenge" />
                    </SwitchWrapper>
                </Col>
                <Col span={8}>
                    {renderDifficulty()}
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="exceedConditionLimit"
                        valuePropName="checked"
                    >
                        <Checkbox
                            {...sharedProps}
                        >
                            <FormattedMessage id="usersProgressCanExceedLimit" />
                        </Checkbox>
                    </Form.Item>
                </Col>
            </BorderedRow>
            <Form.Item dependencies={[["endless", "enabled"]]}>
                {() => renderConditions()}
            </Form.Item>
        </Card>
    );
};
