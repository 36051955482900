import { AddUserWhiteListMutationVariables, UserWhiteListFragment } from "@graphql2/types";

export interface WhiteListForm extends Omit<UserWhiteListFragment, "whitelist"> {
    whitelist: string
}

export const mapValuesToForm = (values: UserWhiteListFragment): WhiteListForm => {
    const whitelist = values.whitelist?.join("; ") ?? "";
    return {
        ...values,
        whitelist
    };
};

export const mapFormToValues = (values: WhiteListForm): Omit<AddUserWhiteListMutationVariables, "projectId"> => {
    const whitelist = values.whitelist.split(" ").join("").split(/[,;]+/);
    return {
        ...values,
        whitelist,
        group: values.group ?? ""
    };
};
