export const toBase64 = async (file: File | Blob): Promise<string | undefined> => new Promise((resolve, reject) => {
    try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(
            reader.result ? reader.result.toString() : undefined
        );
        reader.onerror = reject;
    } catch (error) {
        reject(error);
    }
});
