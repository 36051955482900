import { ApolloError } from "@apollo/client";
import { InjectedIntl } from "./override.types";

export const parseError = (error: ApolloError, intl: InjectedIntl): string => {
    const match = error.message.match(/error\..*$/);

    if (match && match.length) {
        return intl.formatMessage({ id: match[0].toLowerCase() });
    }

    if (error.message.includes("No updated voucheritem")) {
        return "error.addInstructionsFirst";
    }

    if (error.message.includes("Too late for registration")) {
        return "error.tooLateForRegistration";
    }

    if (error.message.includes("Not allowed")) {
        return "error.notAllowed";
    }

    return intl.formatMessage({ id: "error.unknown" });
};
