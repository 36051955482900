import { Application } from "@graphql2/types";
import { getLastHexValue } from "./getLastHexValue";

export const generateRoleId = (applications: Application[], applicationId: string) => {
    const application = applications.find(a => a.id === applicationId);
    if (!application) {
        throw Error("Application not found");
    }
    const lastHexValue = getLastHexValue(application);

    return (lastHexValue + 1).toString(16).padStart(2, "0");
};
