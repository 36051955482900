import { Avatar, Breadcrumb, Button, Card, Popconfirm, Table, Tooltip, message } from "antd";
import Column from "antd/lib/table/Column";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { DeleteOutlined, LeftOutlined, LockOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableComponentProps, TableContainer, TableData } from "@components/table/tableContainer";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { getText } from "@utils/getText";
import { Maybe } from "graphql/jsutils/Maybe";
import { User } from "../../../graphql2/types";
import { ChallengeMemberPageProps } from "./challengeMemberPageContainer";
import { MemberHandlersStyle } from "./challengeMembersPageStyle";

interface MembersTableData extends TableData {
    avatar: string;
    displayName: string;
    email: string;
    lastLogin: number;
    captain: boolean;
    hideRemoveOption: boolean;
}

const renderHandlers = ({ id, captain, hideRemoveOption }: MembersTableData,
    handlers: TableComponentProps["handlers"],
    membersCount: number) => {
    if (hideRemoveOption) {
        return null;
    }

    const isLastUser = membersCount === 1;

    if (captain && !isLastUser) {
        return ({
            children: (
                <MemberHandlersStyle>
                    <Tooltip title={<FormattedMessage id="challengeTeamsTable.cantRemoveCaptain" />}>
                        <LockOutlined style={{ marginRight: "12px" }} />
                    </Tooltip>
                </MemberHandlersStyle>
            ),
            props: {
                colSpan: 2
            }
        });
    }

    return ({
        children: (
            <MemberHandlersStyle>
                <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    title={<FormattedMessage id={isLastUser ? "challengeTeamsTable.removeTeam" : "deleteConfirm"} />}
                    onConfirm={() => {
                        handlers?.removeFromTeam(id, isLastUser);
                    }}
                >
                    <DeleteOutlined style={{ marginRight: "12px" }} />
                </Popconfirm>
            </MemberHandlersStyle>
        ),
        props: {
            colSpan: 2
        }
    });
};

const renderTitle = (text: string, row: MembersTableData) => (
    <Link
        className="plainText"
        to={`/users/detail/${row.id}`}
    >
        {text}
    </Link>
);

const renderCaptain = (isCaptain: boolean, row: MembersTableData, handlers: TableComponentProps["handlers"]) => {
    if (row.email.length === 0 && row.lastLogin === 0) {
        return null;
    }

    if (isCaptain) {
        return <FormattedMessage id="challengeTeamsTable.isCaptain" />;
    }
    return (
        <Button
            onClick={() => handlers && handlers.makeTeamCaptain(row.id)}
        >
            <FormattedMessage id="challengeTeamsTable.makeCaptain" />
        </Button>
    );
};

const ChallengeMemberTable: React.FC<TableComponentProps> = ({ handlers, ...tableProps }) => {
    const [numberOfMembers, setNumberofUsers] = React.useState(0);

    React.useEffect(() => {
        const numberofRows = tableProps.dataSource.length;
        if (numberofRows && numberofRows !== numberOfMembers) {
            setNumberofUsers(numberofRows);
        }
    }, [tableProps.dataSource.length]);

    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="avatar"
                dataIndex="avatar"
                render={img => (
                    <Avatar
                        size="small"
                        src={img}
                    />
                )}
            />
            <Column
                key="displayName"
                dataIndex="displayName"
                render={renderTitle}
                title={<FormattedMessage id="name" />}
            />
            <Column
                key="email"
                dataIndex="email"
                title={<FormattedMessage id="email" />}
            />
            <Column
                key="lastLogin"
                dataIndex="lastLogin"
                render={date => moment(date).format("Do MMM")}
                title={<FormattedMessage id="lastLogin" />}
            />
            <Column
                key="captain"
                align="center"
                dataIndex="captain"
                render={(value, row: MembersTableData) => renderCaptain(value, row, handlers)}
            />
            <Column
                key="handlers"
                align="right"
                render={(row: MembersTableData) => renderHandlers(row, handlers, numberOfMembers)}
            />
        </Table>
    );
};

export const mapMembers = (members: Maybe<{
    __typename?: "User" | undefined;
} & Pick<User, "avatar" | "lastLogin" | "id" | "email" | "displayName" | "registeredOn">>[] | null | undefined,
userCaptainId: string | null | undefined,
autoParticipationEnabled: boolean): MembersTableData[] => {
    if (!members) {
        return [];
    }
    return members.map(member => {
        return {
            id: (member && member.id) || "",
            avatar: (member && member.avatar) || "",
            displayName: (member && member.displayName) || "",
            email: (member && member.email) || "",
            lastLogin: (member && member.lastLogin) || 0,
            captain: Boolean(member && member.id === userCaptainId),
            hideRemoveOption: autoParticipationEnabled
        };
    });
};

export function ChallengeMemberPage(props: ChallengeMemberPageProps) {
    const { challenges, challengeTeams, updateChallengeTeamCaptain, removeMemberFromTeam, removeTeam, match } = props;
    const team = challengeTeams
        && challengeTeams.challengeTeams
        && challengeTeams.challengeTeams.find(t => t && t.id === match.params.teamId);
    const challenge = challenges?.challenges?.[0];

    const challengeTitle = challenge?.title && getText(challenge?.title);

    const members = team && team.members;
    const captainUserId = team && team.captainUserId;
    const { autoParticipationEnabled } = challenge || { };

    const makeTeamCaptain = async (userId: string) => {
        try {
            await updateChallengeTeamCaptain({
                variables: {
                    id: match.params.teamId,
                    captainUserId: userId
                }
            });
        } catch (err) {
            message.error("Something went wrong");
        }
    };

    const removeFromTeam = async (userId: string, shouldRemoveTeam = false) => {
        try {
            const successMessage = shouldRemoveTeam ? "Team removed" : "User removed from team";
            await removeMemberFromTeam({
                variables: {
                    teamId: match.params.teamId,
                    userId
                }
            });

            if (shouldRemoveTeam) {
                await removeTeam({
                    variables: {
                        id: match.params.teamId
                    }
                });
            }
            message.success(successMessage);
        } catch (err) {
            message.error(err.message ?? "Something went wrong");
        }
    };

    return (
        <React.Fragment>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/challenges">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/challenges/edit/${match.params.id}/general`}>
                                    {challengeTitle}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/challenges/${match.params.id}/teams`}>
                                    <FormattedMessage id="teams" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {team && team.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </HeaderActions>
                    <HeaderActions>
                        <Link to={`/challenges/${match.params.id}/teams`}>
                            <Button key="button1">
                                <LeftOutlined />
                                {" "}
                                <FormattedMessage id="challengeMembersPage.backToTeams" />
                            </Button>
                        </Link>
                    </HeaderActions>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    dataSource={mapMembers(members, captainUserId, Boolean(autoParticipationEnabled)) as TableData[]}
                    handlers={{
                        makeTeamCaptain,
                        removeFromTeam
                    }}
                    loading={challengeTeams && challengeTeams.loading}
                    TableComponent={ChallengeMemberTable}
                />
            </Card>
        </React.Fragment>
    );
}
