import * as React from "react";
import { compose } from "redux";
import { localState, LocalStateProps } from "@utils/withLocalState";
import { FixedContentField } from "@components/dashboard/widgets/contentWidget/fixedContentField/fixedContentField";
import * as moment from "moment";
import { WidgetSortProps } from "@components/dashboard/widgets/widgetSort";
import { SortProps } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { withGetContentTitles, GetContentTitlesProps } from "@graphql2/types";

export interface FixedContentFieldContainerProps {
    noSort?: boolean;
    contentOptions: WidgetSortProps;
    singleMode?: boolean;
    itemKey?: number;
    addSort?: boolean;
    forceSingle?: boolean;
}

interface LocalState {
    search?: string;
    sort: SortProps;
}

const withLocalState = localState<LocalState>({
    search: undefined,
    sort: {
        field: "publishOn",
        order: "",
        fields: ["publishOn"]
    }
});

const withContents = withGetContentTitles({
    name: "contentTitles",
    options: ({ noSort }: FixedContentFieldContainerProps & LocalState) => {
        const sortOptions = noSort ? null : {
            field: "title",
            order: "DESC"
        };

        return {
            variables: {
                sort: sortOptions,
                filter: {
                    expireAfter: moment().startOf("day").valueOf()
                }
            }
        };
    }
});

export const FixedContentFieldContainer = compose<React.ComponentClass<FixedContentFieldContainerProps>>(
    withLocalState,
    withContents
)(FixedContentField);

export type FixedContentFieldProps =
    FixedContentFieldContainerProps
    & LocalStateProps<LocalState>
    & GetContentTitlesProps<{}, "contentTitles">;

