import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { useAuth } from "@context/authContext/context";
import { Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface FlowsTableData extends TableData {
    id: string;
    title: string;
    start: number;
    end: number;
}

export interface FlowsTableProps extends TableComponentProps {
    dataSource: FlowsTableData[];
    handlers: TableHandlers & {
        removeFlow(id: string, projectId: string): void;
    };
}

const renderTitle = (text: string, row: FlowsTableData) => {
    return (
        <Link
            className="plainText"
            to={`/flows/edit/${row.id}`}
        >
            {text}
        </Link>
    );
};

const renderHandlers = (row: FlowsTableData, handlers: FlowsTableProps["handlers"]) => {
    const { projectId } = useAuth();

    return {
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <Link to={`/flows/edit/${row.id}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    title={<FormattedMessage id="deleteConfirm" />}
                    onConfirm={() => handlers.removeFlow(row.id, projectId)}
                >
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    };
};

export const FlowsTable: React.FC<FlowsTableProps> = (props) => {
    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...props}
        >
            <Column
                key="title"
                dataIndex="title"
                render={renderTitle}
                sorter={(a: FlowsTableData, b: FlowsTableData) => a.title.localeCompare(b.title)}
                title={<FormattedMessage id="title" />}
            />
            <Column
                key="start"
                dataIndex="start"
                render={(_, row: FlowsTableData) => moment(row.start).format("DD/MM/YYYY")}
                title={<FormattedMessage id="start" />}
            />
            <Column
                key="end"
                dataIndex="end"
                render={(_, row: FlowsTableData) => moment(row.end).format("DD/MM/YYYY")}
                title={<FormattedMessage id="end" />}
            />
            <Column
                key="handlers"
                dataIndex=""
                render={(_, row: FlowsTableData) => renderHandlers(row, props.handlers)}
                title=""
                width={120}
            />
        </Table>
    );
};
