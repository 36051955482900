import { Row } from "antd";
import styled from "styled-components";

export const SwitchWrapper = styled.div`
    .ant-switch {
        margin-right: 20px;
    }

    [value="true"] {
        span {
            color: #A0C63D
        }
    }
`;

// tslint:disable-next-line:no-any
export const BorderedRow = styled(Row as any)`
    border-top: 1px solid #D5D5D5;
    padding: 20px 0;
    
    .ant-table-wrapper {
        width: 100%;
    }
`;

export const SelectWrapper = styled.div`
    width: 100%;
`;

export const ButtonWrapper = styled.div`
    .ant-btn-primary:not(:first-child) {
        margin-left: 15px;
    }
`;
