
import { Pagination } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { PaginationProps } from "antd/lib/pagination/Pagination";
import { Flex } from "@components/flex/flex";

@autobind
export class TablePagination extends React.Component<PaginationProps> {

    public render() {

        const { } = this.props;

        return (
            <Flex justifyContent="flex-end">
                <Pagination
                    hideOnSinglePage
                    showSizeChanger
                    showQuickJumper
                    {...this.props}
                />
            </Flex>
        );
    }
}
