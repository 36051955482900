import styled from "styled-components";

export const TranslationUpdateFields = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;

    .ant-form-item:first-child {
        width: 100%;
    }

    .ant-checkbox-wrapper {
        margin: 0 !important;
        margin-left: 10px !important;
        width: 200px;
    }
`;

export const TranslationFormStyle = styled.div`
  display: flex;
`;
export const IsCustomIconStyle = styled.div`
    margin-top: 10px;
`;
