import styled from "styled-components";

export const UserActivitiesCardStyle = styled.div``;

export const Statistics = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 30px;
    margin-bottom: 0px;
`;

export const Statistic = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StatisticTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
`;

export const StatisticValue = styled.span`
    font-size: 22px;
    font-weight: 300;
`;

export const ProviderActivitiesLink = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a span {
        margin-left: 5px;
    }
    `;
