import React, { FC } from "react";
import { Card } from "antd";
import { FormattedMessage } from "react-intl";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { CreditsTable } from "@components/credits/creditsTable";
import { TableContainer } from "@components/table/tableContainer";
import { CreditTypeItem } from "@utils/mappers/creditsMapper";

type ConfigTypesListProps = {
    loading: boolean;
    creditTypes: CreditTypeItem[];
    removeHandler: (id: string) => Promise<void>
}

export const ConfigTypesList: FC<ConfigTypesListProps> = ({ loading, creditTypes, removeHandler }) => {
    return (
        <Card>
            <ContentHeader
                title={<FormattedMessage id="webshop.credits.configTypes" />}
            />
            <TableContainer
                loading={loading}
                TableComponent={CreditsTable}
                dataSource={creditTypes}
                handlers={{
                    removeCreditConfigType: removeHandler
                }}
            />
        </Card>
    );
};
