import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { withAddEventTag, withGetEventTags, withUpdateEventTag } from "@graphql2/types";
import { withRemoveEventTagMutation } from "@pages/tagsPage/eventTagsPageContainer";
import { getEventTags } from "@graphql2/graphql_op";

export const withEventTags = withGetEventTags({
    skip: (props: AddTagsRouteProps) => {
        return !props.match.params.id;
    }
});

export const AddEventTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddEventTag({
        name: "addTag",
        options: {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: getEventTags }]
        }
    }),
    withUpdateTag: withUpdateEventTag({
        name: "updateTag"
    }),
    withTags: withEventTags,
    withRemoveTag: withRemoveEventTagMutation,
    callbackUrl: "/event/tags",
    path: ["data", "eventTags"]
});
