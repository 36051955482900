import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { autobind } from "core-decorators";
import * as moment from "moment";
import { EyeOutlined, RollbackOutlined, TwitterOutlined } from "@ant-design/icons";

export interface TwitterFeedTableData extends TableData {
    tweet: string;
    user: string;
    visible: boolean;
}

export interface TwitterFeedTableProps extends TableComponentProps {
    dataSource: TwitterFeedTableData[];
    totalCount?: number;
    handlers: TableHandlers & {
        changeVisibleTwitterFeed(variables: { variables: { id: string; visible: boolean } }): void;
    };
}

@autobind
export class TwitterFeedTable extends React.Component<TwitterFeedTableProps> {
    public render() {
        const { totalCount } = this.props;

        return (
            <Table
                pagination={{
                    total: (totalCount || 0),
                    defaultPageSize: 50,
                    showSizeChanger: true,
                    showQuickJumper: true
                }}
                rowKey="id"
                {...this.props}
            >
                <Table.Column
                    key="date"
                    title={<FormattedMessage id="date" />}
                    dataIndex="date"
                    render={(date) => moment(date).format("DD/MM/YYYY HH:mm")}
                />
                <Table.Column
                    key="tweet"
                    title={<FormattedMessage id="tweet" />}
                    dataIndex="tweet"
                    render={this.renderTweet}
                />
                <Table.Column
                    key="user"
                    title={<FormattedMessage id="user" />}
                    dataIndex="user"
                    width={75}
                />
                <Table.Column
                    key="visit"
                    dataIndex="link"
                    render={(link) => (
                        <a href={link} target="_black"><TwitterOutlined /> <FormattedMessage id="open" /></a>
                    )}
                    width={100}
                />
                <Table.Column
                    key="handlers"
                    width={120}
                    render={(text, row: TwitterFeedTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <Flex justifyContent="flex-end" alignItems="center">
                                    <a onClick={() => handlers.changeVisibleTwitterFeed({ variables: { id: row.id, visible: !row.visible } })}>
                                        {row.visible ? <EyeOutlined /> : <RollbackOutlined />}
                                    </a>
                                </Flex>
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                />
            </Table>
        );
    }

    private renderTweet(tweet: string) {
        return <p>{tweet.length > 100 ? `${tweet.slice(0, 100)}...` : tweet}</p>;
    }
}
