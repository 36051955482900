/* eslint-disable max-lines-per-function */
import { CommentCardContainer } from "@components/comments/commentCard/commentCardContainer";
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useGlobalActions } from "@context/enabledLangContext/actions";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { ADD_CONTENT_TABS, AddContentsPageProps } from "@pages/addContentsPage/addContentsPageContainer";
import { AddContentsPageStyle } from "@pages/addContentsPage/addContentsPageStyle";
import { ContentsSettingsCardContainer } from "@pages/addContentsPage/general/contentSettingsCardContainer";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyContent } from "@utils/emptyItems/emptyContent";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { contentToFormValues, formValuesToContent } from "@utils/mappers/contentMapper";
import { translateTags } from "@utils/mappers/tagMapper";
import { Badge, Breadcrumb, Button, Form, Popconfirm, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { useErrors } from "../../hooks/useErrors";
import { ContentCard, ContentFormValues } from "./general/contentCard";
import { ContentSettingsFormValues } from "./general/contentSettingsCard";

export type ErrorObj = object;

export type ContentErrors = {
    title: ErrorObj;
    preview: ErrorObj;
    content: ErrorObj;
    url: ErrorObj;
    type: ErrorObj;
    expiresOn: ErrorObj;
    publishOn: ErrorObj;
    imageUrl: ErrorObj;
    tags: ErrorObj;
    isDraft: ErrorObj;
};

export type ContentsFormValues = {
    id?: string;
    imageUrl: string;
} & ContentFormValues & ContentSettingsFormValues;

const contentErrorMap = (tab: string, fieldErrors: any) => {
    switch (tab) {
        case ADD_CONTENT_TABS.general:
            return Boolean(
                fieldErrors.title
                || fieldErrors.preview
                || fieldErrors.content
                || fieldErrors.imageUrl
                || fieldErrors.url
                || fieldErrors.type
                || fieldErrors.expiresOn
                || fieldErrors.publishOn
                || fieldErrors.tags
                || fieldErrors.isDraft
            );
        default:
            return Boolean(fieldErrors[tab]);
    }
};

// tslint:disable max-func-body-length
export const AddContentsPage: React.FC<AddContentsPageProps> = (props: AddContentsPageProps) => {
    const {
        match,
        contents,
        applications,
        location,
        contentTags: { contentTags, loading: loadingTags }
    } = props;

    const intl = useIntl();

    const [updateEnabledLanguages, resetEnabledLanguages] = useGlobalActions();

    const translatedTags = React.useMemo(
        () => translateTags(contentTags || []),
        [contentTags]
    );

    React.useEffect(() => {
        return () => {
            // This is the cleanup function, and will execute when the component unmounts (eg. navigate back to /challenges)
            resetEnabledLanguages();
        };
    }, []);

    const content = React.useMemo(
        () => createDefaultValues(contentToFormValues, emptyContent, location.state?.import, contents?.loading, contents?.contents?.[0]),
        [contents]
    );

    const [form] = useForm();
    const [unsaved, setEnabledSave] = React.useState(false);
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setErrors] = useErrors();
    const [isSaving, setIsSaving] = React.useState(false);

    const contentForm = form.getFieldsValue();

    React.useEffect(() => {
        const enabledLocales = content?.enabledLocales;
        if (enabledLocales && enabledLocales.length) {
            updateEnabledLanguages(enabledLocales);
        }
    }, [content]);

    const removeContent = async (id?: string) => {
        if (id) {
            await props.removeContent({ variables: { id } });
            props.history.push("/content/contents");
        }
    };

    const onSubmit = async (values) => {
        const { history } = props;
        setIsSaving(true);
        try {
            const fields = formValuesToContent(values);

            if (props.match.params.id) {
                await props.updateContent({
                    variables: {
                        ...fields,
                        id: props.match.params.id
                    }
                });
                message.success(intl.formatMessage({ id: "content.updated" }));
            } else {
                await props.addContent({ variables: { ...fields } });
                message.success(intl.formatMessage({ id: "content.added" }));
            }
            history.push("/content/contents");
            setErrors({});
        } catch (errors) {
            const errMsg = props.match.params.id ? "content.contentUpdateFailed" : "content.contentAddFailed";
            message.error(intl.formatMessage({ id: errMsg }));
            setErrors(errors);
            message.error(intl.formatMessage({ id: "error.checkHighlightedFields" }));
        }

        setIsSaving(false);
    };

    const tabs: string[] = [
        ADD_CONTENT_TABS.general,
        ADD_CONTENT_TABS.comments
    ];

    const currentTab = tabs.indexOf(match.params.tab);

    if (applications?.loading || loadingTags || contents?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if (!contents?.contents?.length && match.params.id) {
        props.history.push("/404");
    }

    const defaultValues = content;

    //  changeSelectedLanguage, OnChange logic should incorporated into the hook
    const defaultProps = {
        form,
        changeSelectedLanguage,
        onBlur: () => {
            if (unsaved) {
                return;
            }
            setEnabledSave(true);
        }
    };

    const contentTagTabs = tabs.map(t => <Badge dot={contentErrorMap(t, fieldErrors)}><FormattedMessage id={t} /></Badge>);

    const changeSelectedTab = (index: number | string) => {
        props.history.push(
            `/content/contents/${match.params.id
                ? `edit/${match.params.id}`
                : "add"
            }/${tabs[index]}`
        );
    };

    const onFailed = (err) => {
        setErrors(err);
    };

    // Can this section be combined into Wrapper Component with hook for roles?
    const roles = getFrontendRoles(applications?.applications || []);
    return (
        <AddContentsPageStyle>
            <Form
                autoComplete="off"
                form={form}
                initialValues={defaultValues}
                onFinish={onSubmit}
                onFinishFailed={onFailed}
            >
                <HeaderContent>
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/content/contents">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {getText(contentForm?.title || content?.title, selectedLanguage)}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                            {unsaved && <UnsavedChanges key="warning" />}
                            <Button
                                key="button1"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            <Popconfirm
                                cancelText="No"
                                okText="Yes"
                                placement="bottomRight"
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={async () => removeContent(content.id)}
                            >
                                <Button
                                    className="headerButton"
                                    type="ghost"
                                >
                                    <FormattedMessage id="content.delete" />
                                </Button>
                            </Popconfirm>
                        </div>
                    </HeaderActions>
                    <Flex justifyContent="space-between">
                        <ContentTabs
                            currentTab={currentTab}
                            handleTabSelectedLanguage={changeSelectedTab}
                            tabs={contentTagTabs}
                        />
                    </Flex>
                </HeaderContent>
                <ContentCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    customErrors={{
                        content: fieldErrors.content
                    }}
                    defaultValues={{
                        content: defaultValues.content
                    }}
                    form={form}
                    hidden={match.params.tab !== ADD_CONTENT_TABS.general}
                    languageErrors={errorsToLanguageErrors({
                        title: fieldErrors.title,
                        preview: fieldErrors.preview,
                        content: fieldErrors.content
                    })}
                />
                <CoverImageCard
                    {...defaultProps}
                    required
                    cropSettings={{
                        aspect: ImageRatios.DEFAULT,
                        zoom: true,
                        rotate: true
                    }}
                    defaultImage={defaultValues.imageUrl}
                    form={form}
                    formField="imageUrl"
                    hasError={fieldErrors.imageUrl}
                    hidden={match.params.tab !== ADD_CONTENT_TABS.general}
                    titleId="image"
                />
                <ContentsSettingsCardContainer
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    contentTags={translatedTags}
                    form={form}
                    hidden={match.params.tab !== ADD_CONTENT_TABS.general}
                    languageErrors={errorsToLanguageErrors({
                        url: fieldErrors.url,
                        type: fieldErrors.type,
                        expiresOn: fieldErrors.expiresOn,
                        publishOn: fieldErrors.publishOn,
                        tags: fieldErrors.tags,
                        isDraft: fieldErrors.isDraft
                    })}
                    resetEnabledLocales={resetEnabledLanguages}
                    roles={roles}
                    setEnabledLocales={updateEnabledLanguages}
                    userId={content.userId}
                />
                {match.params.tab === ADD_CONTENT_TABS.comments && content.id && (
                    <CommentCardContainer
                        type="content"
                        typeId={content.id}
                    />
                )}
            </Form>
        </AddContentsPageStyle>
    );
};
