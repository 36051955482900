import { Alert } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { WidgetProps } from "@components/dashboard/widgets/models";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { GenericWidgetStyle } from "../genericWidget/genericWidgetStyle";
import { WidgetConfigStyle } from "../widgetStyle";
import { VideoCard } from "./fields/videoFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";

export interface VideoWidgetProps extends WidgetProps { }

export const VideoWidget: React.FC<VideoWidgetProps> = (props) => {
    const { applications, widgetId, form, errors } = props;

    return (
        <GenericWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    languageErrors={errors && errors.title}
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <Alert
                    message={(
                        <React.Fragment>
                            Vimeo - <FormattedMessage id="embedUrl.example" /> <strong>https://player.vimeo.com/video/YOUR_VIDEO_ID</strong>
                            <br />
                            YouTube - <FormattedMessage id="embedUrl.example" /> <strong>https://www.youtube.com/embed/YOUR_VIDEO_ID</strong>
                            <br />
                        </React.Fragment>
                    )}
                    type="info"
                />
                <VideoCard
                    id={widgetId}
                    form={form}
                />
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
            </WidgetConfigStyle>
        </GenericWidgetStyle>
    );
};
