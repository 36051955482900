import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useAuth } from "@context/authContext/context";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyCreditConfig } from "@utils/emptyItems/emptyCreditConfig";
import { creditConfigOnCreditConfigTypeDelete, creditConfigToFormValues, formValuesToCreditConfig, translateCreditsConfig } from "@utils/mappers/creditsMapper";
import { Button, Form, Spin, message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useEffect, useMemo, useReducer, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CollectionPeriodCard, CollectionPeriodFormValues } from "./collectionPeriod/collectionPeriodCard";
import { ConfigTypesList } from "./configTypesList/configTypesList";
import { CreditsPageProps } from "./creditsPageContainer";
import { CreditsPageStyle, HeaderActions } from "./creditsPageStyle";
import { SpentCreditsLimitCard, SpentCreditsLimitFormValues } from "./spentCreditsLimit/spentCreditsLimitCard";

export type CreditConfigFormValues = CollectionPeriodFormValues & SpentCreditsLimitFormValues

export type DisableState = {
    collectionPeriodDates: boolean;
    creditLimitOptions: boolean;
    countInCollectionPeriod: boolean;
    countForLastXDays: boolean;
}

export type DisableAction = {
    source: string|undefined;
    nextState: boolean;
}

const initialState = {
    collectionPeriodDates: false,
    creditLimitOptions: false,
    countInCollectionPeriod: false,
    countForLastXDays: false
};

export const CreditsPage: FC<CreditsPageProps> = (props) => {
    const { creditConfigCount: creditConfigCountQuery,
        creditConfigs: creditConfigsQuery,
        addCreditConfig, updateCreditConfig } = props;
    const intl = useIntl();
    const [form] = useForm();
    const { projectId } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [enabledSave, setEnabledSave] = useState<boolean>();
    const { creditConfigs, loading } = creditConfigsQuery;
    const { creditConfigCount, loading: countLoading } = creditConfigCountQuery;
    const creditConfig = creditConfigs?.[0];
    const types = creditConfig?.types;
    const capping = creditConfig?.capping;

    const areDatesDefined = () => {
        return (Boolean(form.getFieldValue("enableCollectionPeriod")) && Boolean(form.getFieldValue("from")) && Boolean(form.getFieldValue("to")));
    };

    const reducer = (state: DisableState, action: DisableAction) => {
        switch (action.source) {
            case "enableCollectionPeriod": {
                const countInCollectionPeriodEnabled = Boolean(form.getFieldValue("countInCollectionPeriod"));
                return {
                    ...state,
                    collectionPeriodDates: action.nextState,
                    countInCollectionPeriod: !areDatesDefined(),
                    countForLastXDays: countInCollectionPeriodEnabled ? !action.nextState : state.countForLastXDays
                };
            }
            case "enableSpentCreditsLimit": {
                return {
                    ...state,
                    creditLimitOptions: action.nextState
                };
            }
            case "collectionPeriodDates": {
                return {
                    ...state,
                    countInCollectionPeriod: action.nextState
                };
            }
            case "countInCollectionPeriod": {
                return {
                    ...state,
                    countForLastXDays: !action.nextState
                };
            }

            default: {
                throw new Error(`Unknown action: ${action.source}`);
            }
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const translatedCreditTypes = useMemo(
        () => translateCreditsConfig(types, capping),
        [types, capping]
    );

    const initialCreditFormValues: CreditConfigFormValues = useMemo(
        () => createDefaultValues(creditConfigToFormValues, emptyCreditConfig, undefined, loading, creditConfig),
        [creditConfig, loading]
    );

    useEffect(() => {
        if (initialCreditFormValues) {
            // Since initialState is computed before we get values from query, we need to populate state once we get them,
            // otherwise we would initially see incorrect state of disabled fields
            const { enableCollectionPeriod, enableSpentCreditsLimit, from, to, countInCollectionPeriod } = initialCreditFormValues;
            dispatch({
                source: "enableCollectionPeriod",
                nextState: !enableCollectionPeriod
            });

            dispatch({
                source: "enableSpentCreditsLimit",
                nextState: !enableSpentCreditsLimit
            });

            dispatch({
                source: "collectionPeriodDates",
                nextState: !enableCollectionPeriod || !from || !to
            });

            dispatch({
                source: "countInCollectionPeriod",
                nextState: !countInCollectionPeriod || !enableCollectionPeriod
            });
        }
    }, [initialCreditFormValues]);

    const handleSaveState = () => {
        if (enabledSave) {
            return;
        }
        setEnabledSave(true);
    };

    const checkBoxChangeHandler = (event: CheckboxChangeEvent) => {
        handleSaveState();
        dispatch({
            source: event.target.id,
            nextState: !event.target.checked
        });
    };

    const dateChangeHandler = () => {
        handleSaveState();
        dispatch({
            source: "collectionPeriodDates",
            nextState: !areDatesDefined()
        });
    };

    const removeCreditConfigTypeHandler = async (id: string) => {
        try {
            const updatedCreditConfig = creditConfigOnCreditConfigTypeDelete(id, creditConfig);
            if (!updatedCreditConfig) {
                throw new Error();
            }

            await updateCreditConfig({ variables: {
                projectId, creditConfig: updatedCreditConfig
            } });
            message.success(intl.formatMessage({ id: "webshop.credits.configUpdated" }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "webshop.credits.configUpdateFailed" }));
        }
    };

    const submitForm = async (values: CreditConfigFormValues) => {
        setIsSaving(true);
        const mutationVariables = { projectId, creditConfig: formValuesToCreditConfig(values, types, capping) };
        const transformedValues = () => (creditConfigCount
            ? updateCreditConfig({ variables: mutationVariables })
            : addCreditConfig({ variables: mutationVariables }));

        try {
            await form.validateFields();
            await transformedValues();
            message.success(intl.formatMessage({ id: "webshop.credits.configUpdated" }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "webshop.credits.configUpdateFailed" }));
        } finally {
            setIsSaving(false);
            setEnabledSave(false);
        }
    };

    if (loading || countLoading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    return (
        <CreditsPageStyle>
            <Form
                form={form}
                initialValues={initialCreditFormValues}
                onFinish={submitForm}
            >
                <HeaderContent>
                    <HeaderActions>
                        <div>
                            {enabledSave && <UnsavedChanges key="warning" />}
                            <Button
                                className="headerButton"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            <Link to="/credits/add">
                                <Button
                                    className="headerButton"
                                    type="primary"
                                >
                                    <FormattedMessage id="webshop.credits.addConfigType" />
                                </Button>
                            </Link>
                        </div>
                    </HeaderActions>
                </HeaderContent>
                <CollectionPeriodCard
                    checkBoxChangeHandler={checkBoxChangeHandler}
                    dateChangeHandler={dateChangeHandler}
                    disableState={state}
                    form={form}
                />
                <SpentCreditsLimitCard
                    checkBoxChangeHandler={checkBoxChangeHandler}
                    disableState={state}
                />
                <ConfigTypesList
                    creditTypes={translatedCreditTypes}
                    loading={loading}
                    removeHandler={removeCreditConfigTypeHandler}
                />
            </Form>
        </CreditsPageStyle>
    );
};
