import React from "react";

import { Spin } from "antd";
import { Flex } from "@components/flex/flex";

// Add error boundary to this component
export const LoaderComponent: React.FC<{ loading: boolean }> = ({ children, loading }) => {
    if (loading) {
        return (
            <Flex fullWidth justifyContent="center">
                <Spin />
            </Flex>
        );
    }
    // TODO: Vertically Align Component
    return <React.Fragment>{children}</React.Fragment>;
}