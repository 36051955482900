import styled from "styled-components";
import { Flex } from "@components/flex/flex";

export const UserConnectedProvidersCardStyle = styled.div`
    .ant-avatar {
        margin-right: 15px !important;
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        max-height: 30px;
        max-width: 30px;

        & img {
            height: 30px;
            width: 30px;
            min-height: 30px;
            min-width: 30px;
            max-height: 30px;
            max-width: 30px;
        }
    }

    span {
        font-size: 14px;
        padding-right: 12px;
        text-align: right;
    }

    & i {
        margin-left: 10px;
        cursor: pointer;
    }

    ${Flex} {
        width: 100%;
    }
`;

export const ProviderWrapper = styled(Flex)`
    margin: 10px 0;
`;
