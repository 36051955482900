import { Field } from "@components/field/field";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

/*
    Without this, the condition would lose it's type, id and sequence. Changing those values to undefined
    Because of this, the condition would no longer be rendered and dissapear from the state.
*/

export const hiddenConditionInputs = (form: FormInstance, condition: ChallengeConditionFormValuesUnion, index: number, type: string) => {
    return (
        <React.Fragment>
            <Field
                hidden
                initialValue={type}
                name={["conditions", index, "type"]}
                rules={[{ required: true }]}
            />
            <Field
                hidden
                initialValue={condition.id}
                name={["conditions", index, "id"]}
                rules={[{ required: true }]}
            />
            <Field
                hidden
                initialValue={index}
                name={["conditions", index, "sequence"]}
                rules={[{ required: true }]}
            />
        </React.Fragment>
    );
};
