import { WidgetProps } from "@components/dashboard/widgets/models";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RolesDropdown } from "@components/rolesDropdown/rolesDropdown";
import { Checkbox, Form } from "antd";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { CommunityWidgetStyle } from "./communityWidgetStyle";

export interface CommunityWidgetProps extends WidgetProps {

}

export const CommunityWidget: React.FC<CommunityWidgetProps> = (props) => {
    const { applications, widgetId } = props;

    return (
        <CommunityWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <Form.Item
                    name={["widgets", widgetId, "disableNewUsersView"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage id="communityWidget.disableNewUsersView" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name={["widgets", widgetId, "disableUsersCount"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage id="communityWidget.disableUsersCount" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name={["widgets", widgetId, "disableInviteAbility"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage id="communityWidget.disableInviteAbility" />
                    </Checkbox>
                </Form.Item>
                <RolesDropdown
                    widgetId={widgetId}
                    options={applications}
                />
            </WidgetConfigStyle>
        </CommunityWidgetStyle>
    );
};
