import styled from "styled-components";

export const Wrapper = styled.div`
  padding: ${(props) => props.styles.padding};
  .ant-tooltip-inner {
    color: black;
    background-color: ${(props) => props.styles.background};
    width: auto;
    border: 1px solid ${(props) => props.styles.border};
  }
  .ant-tooltip-arrow-content {
    background-color: ${(props) => props.styles.border};
  }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Title = styled.div`
    padding-left: 5px;
    font-weight: bold;
`;

export const Description = styled.div`
    text-align: justify;
`;

export const ErrorColors = {
    background: "#fff2f0",
    border: "#ffccc7",
    icon: "#ff4d4f"
};
export const WarningColors = {
    background: "#fffbe6",
    border: "#ffe58f",
    icon: "#faad14"
};
export const InfoColors = {
    background: "#e6f7ff",
    border: "#91d5ff",
    icon: "#1890ff"
};
