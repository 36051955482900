/*
    This function will return default values.
    1. Import data
    2. Fetched data (item), if not loading
    3. Empty data if not loading, null if loading.
*/
export const createDefaultValues = (
    mapper: (any) => any,
    emptyData: any,
    importData?: any,
    loading?: boolean,
    item?: any
) => {
    if (importData) {
        return mapper(importData);
    }

    if (!loading && item) {
        return mapper(item);
    }

    if (loading) {
        return null;
    }

    return emptyData;
};
