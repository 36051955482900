import { Field } from "@components/field/field";
import styled from "styled-components";

export const FormConditionTranslations = styled.div`
    padding: 0 30px;
    width: 100%;

    .ant-alert {
        margin-bottom: 1em;
    }
`;

export const TreeWrapper = styled.div`
    position: relative;

    &:before {
            content: "";
            width: 1px;
            border-left: 1px solid #d9d9d9;
            height: calc(100% - 85px);
            position: absolute;
            left: 0;
            top: 40px;
            margin: 22px 0;
        }
`;

export const TranslationField = styled(Field)`
    width: 100%;
    margin-bottom: 1rem;
`;

export const TranslationOptions = styled.div`
    padding-left: 20px;

    .ant-row.ant-form-item {
        &:before {
            content: "";
            display: table;
            width: 10px;
            background: #d9d9d9;
            height: 1px;
            position: absolute;
            left: -20px;
            top: calc(50% + 6px);
        }
    }
`;

export const TranslationOptionField = styled(Field)`
        margin-bottom: .5rem;
`;

export const ValueKey = styled.div`
    position: relative;
`;
