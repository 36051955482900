import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { ExportAmbassadorTagContainer } from "@components/export/exportAmbassadorTagsContainer";
import { withGetAmbassadorTags, withRemoveAmbassadorTag } from "@graphql2/types";

export const withAmbassadorTags = withGetAmbassadorTags();

export const withRemoveAmbassadorTagMutation = withRemoveAmbassadorTag({
    name: "removeTag",
    options: {
        refetchQueries: ["getAmbassadorTags"],
        awaitRefetchQueries: true
    }
});

export const AmbassadorTagsPageContainer = createTagsPageContainer(
    withAmbassadorTags,
    withRemoveAmbassadorTagMutation,
    "/ambassador/tags/add/general",
    "ambassador",
    ExportAmbassadorTagContainer,
    ["data", "ambassadorTags"]
);
