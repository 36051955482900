import { FixedChallengesFieldProps } from "@components/dashboard/widgets/challengeWidget/fixedChallengesField/fixedChallengesFieldContainer";
import { Form, Spin, Alert } from "antd";
import { NamePath } from "antd/lib/form/interface";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AdminTooltip } from "@components/tooltip/index";
import { WidgetSort } from "@components/dashboard/widgets/widgetSort";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { requiredrule } from "@components/form/requiredRule";
import { SpinStyle } from "@components/style/globalStyles";
import { WidgetSearchTitlesComponent } from "@components/dashboard/widgets/widgetSearchTitlesComponent";
import { get, concat, reduce, some } from "lodash";
import { GetChallengeTitlesQuery } from "@graphql2/types";

const SUPPORTED_CHALLENGE_VISUALISATIONS = ["timeline"];
const filterByVisualisations = (
    data: GetChallengeTitlesQuery["challenges"],
    onlyVisualisations = false
): GetChallengeTitlesQuery["challenges"] => {
    const filtered = reduce(data, (results, challenge) => {
        const visualisations = get(challenge, "visualisations");
        const filter = some(visualisations, (visualisation) => {
            if (!visualisation?.type) {
                return false;
            }
            return SUPPORTED_CHALLENGE_VISUALISATIONS.includes(visualisation.type);
        });
        return concat(results, filter ? challenge : []);
    }, []);
    const challenges = onlyVisualisations ? filtered : data;
    return challenges;
};

// eslint-disable-next-line complexity
export const FixedChallengesField: React.FC<FixedChallengesFieldProps> = (props) => {
    const {
        challengeOptions,
        singleMode,
        itemKey,
        forceSingle,
        sort,
        currentTitles,
        addSort,
        setLocalState,
        onlyChallengesWithVisualisations,
        onChange
    } = props;

    const isLoading = currentTitles.loading;
    const error = get(currentTitles, "error");
    const { id, form } = challengeOptions;
    let name: NamePath = ["widgets", id, "challengeIds"];
    let label = "challengeWidget.addChallenges";

    if (singleMode && (itemKey || itemKey === 0)) {
        name = [itemKey, "typeId"];
    }

    if (singleMode) {
        label = "challengeWidget.addChallenge";
    }

    if (forceSingle && singleMode) {
        name = ["widgets", id, "challengeId"];
    }

    React.useEffect(() => {
        setLocalState({
            ids: form.getFieldValue(name)
        });
    }, [form]);

    const AddChallengeLabel = (
        <div style={{ display: "flex" }}>
            <FormattedMessage id={label} />
            {onlyChallengesWithVisualisations && (
                <AdminTooltip
                    type="info"
                    description={<FormattedMessage id="challengeWidget.addChallenge.tooltip" />}
                    customStyles={{ padding: "0 0 0 5px" }}
                />
            )}
        </div>
    );
    const rules = [requiredrule];

    const titles = filterByVisualisations(get(currentTitles, "challenges", []), onlyChallengesWithVisualisations);

    return (
        <React.Fragment>
            <Form.Item>
                <div style={error ? { display: "none" } : undefined}>
                    <WidgetSearchTitlesComponent
                        isLoading={isLoading}
                        data={titles || []}
                        name={name}
                        rules={rules}
                        showArrow
                        showSearch
                        loading={isLoading}
                        mode={!singleMode ? "multiple" : undefined}
                        id={name.join(".")}
                        filterOption={false}
                        optionFilterProp="children"
                        onChange={onChange}
                        label={AddChallengeLabel}
                        dropdownRender={menu => {
                            if (isLoading) {
                                return <SpinStyle><Spin /></SpinStyle>;
                            }
                            return menu;
                        }}
                    />
                </div>
                {error && <Alert type="error" message={<FormattedMessage id="dashboard.norole" />} />}
            </Form.Item>
            {addSort && (
                <WidgetSort
                    type={PreviewTypes.challenge}
                    updateParentState={setLocalState}
                    defaults={sort}
                    {...challengeOptions}
                />
            )}
        </React.Fragment>
    );
};
