/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable max-lines-per-function */
import { ApolloProvider } from "@apollo/client";
import { Main } from "@components/main/main";
import { PrivateRoute } from "@components/privateRoute/privateRoute";
import { AuthProvider } from "@context/authContext/context";
import { MainConfigSchemas, RoutesConfigSchemas, ThemeConfigSchemas } from "@energylab/schematics/lib";
import { client } from "@graphql2/client";
import { AddAmbassadorConfigPageContainer } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPageContainer";
import { AddAmbassadorsPageContainer } from "@pages/addAmbassadorsPage/addAmbassadorsPageContainer";
import { AddArticlesPageContainer } from "@pages/addArticlesPage/addArticlesPageContainer";
import { AddChallengesPageContainer } from "@pages/addChallengesPage/addChallengesPageContainer";
import { AddChallengesTeamPageContainer } from "@pages/addChallengesTeamPage/addChallengesTeamPageContainer";
import { AddCheckupPageContainer } from "@pages/addCheckupPage/addCheckupPageContainer";
import { AddContentsPageContainer } from "@pages/addContentsPage/addContentsPageContainer";
import { AddCreditTypePageContainer } from "@pages/addCreditTypePage/addCreditTypePageContainer";
import { AddDashboardPageContainer } from "@pages/addDashboardPage/addDashboardPageContainer";
import { AddEventGroupPageContainer } from "@pages/addEventGroupPage/addEventGroupPageContainer";
import { AddEventsPageContainer } from "@pages/addEventsPage/addEventsPageContainer";
import { AddFlowsPageContainer } from "@pages/addFlowsPage/addFlowsPageContainer";
import { AddFormTemplatesPageContainer } from "@pages/addFormTemplatesPage/addFormTemplatesPageContainer";
import { AddGalleriesPageContainer } from "@pages/addGalleriesPage/addGalleriesPageContainer";
import { AddJourneysPageContainer } from "@pages/addJourneysPage/addJourneysPageContainer";
import { AddAmbassadorTagsPageContainer } from "@pages/addTagsPage/addAmbassadorTagsPageContainer";
import { AddChallengeTagsPageContainer } from "@pages/addTagsPage/addChallengeTagsPageContainer";
import { AddContentTagsPageContainer } from "@pages/addTagsPage/addContentTagsPageContainer";
import { AddEventTagsPageContainer } from "@pages/addTagsPage/addEventTagsPageContainer";
import { AddGalleryTagsPageContainer } from "@pages/addTagsPage/addGalleryTagsPageContainer";
import { AddJourneyTagsPageContainer } from "@pages/addTagsPage/addJourneyTagsPageContainer";
import { AddUserPageContainer } from "@pages/addUserPage/addUserPageContainer";
import { AddVouchersPageContainer } from "@pages/addVoucherPage/addVoucherPageContainer";
import { AmbassadorsPageContainer } from "@pages/ambassadorsPage/ambassadorsPageContainer";
import { ArticlesPageContainer } from "@pages/articlesPage/articlesPageContainer";
import { ChallengeParticipantsPageContainer } from "@pages/challengeParticipantsPage/challengeParticipantsPageContainer";
import { ChallengeTeamPageContainer } from "@pages/challengeTeamPage/challengeTeamPageContainer";
import { ChallengeMemberPageContainer } from "@pages/challengeTeamPage/teams/challengeMemberPageContainer";
import { ChallengePageContainer } from "@pages/challengesPage/challengesPageContainer";
import { CheckupPageContainer } from "@pages/checkupPage/checkupPageContainer";
import { CompaniesPageContainer } from "@pages/companiesPage/companiesPageContainer";
import { ConfigPageContainer } from "@pages/configPage/configPageContainer";
import { ContentsPageContainer } from "@pages/contentsPage/contentsPageContainer";
import { CreditsPageContainer } from "@pages/creditsPage/creditsPageContainer";
import { DashboardsPageContainer } from "@pages/dashboardsPage/dashboardsPageContainer";
import { EventGroupPageContainer } from "@pages/eventGroupPage/eventGroupPageContainer";
import { EventsPageContainer } from "@pages/eventsPage/eventsPageContainer";
import { FlowsPageContainer } from "@pages/flowsPage/flowsPageContainer";
import { FormsPageContainer } from "@pages/formsPage/formsPageContainer";
import { GalleriesPageContainer } from "@pages/galleriesPage/galleriesPageContainer";
import { JourneysPageContainer } from "@pages/journeysPage/journeysPageContainer";
import { LoginContainer } from "@pages/login/loginContainer";
import { MainPageContainer } from "@pages/mainPage/mainPageContainer";
import { OrdersPageContainer } from "@pages/ordersPage/ordersPageContainer";
import { PageNotFoundPage } from "@pages/pageNotFoundPage/pageNotFoundPage";
import { ProviderActivitiesPage } from "@pages/providerActivitiesPage/providerActivitiesPage";
import { RolesPageContainer } from "@pages/rolesPage/rolesPageContainer";
import { SocialPageContainer } from "@pages/socialPage/socialPageContainer";
import { AmbassadorTagsPageContainer } from "@pages/tagsPage/ambassadorTagsPageContainer";
import { ChallengeTagsPageContainer } from "@pages/tagsPage/challengeTagsPageContainer";
import { ContentTagsPageContainer } from "@pages/tagsPage/contentTagsPageContainer";
import { EventTagsPageContainer } from "@pages/tagsPage/eventTagsPageContainer";
import { GalleryTagsPageContainer } from "@pages/tagsPage/galleryTagsPageContainer";
import { JourneyTagsPageContainer } from "@pages/tagsPage/journeyTagsPageContainer";
import { TranslationsPage } from "@pages/translationsPage/translationsPage";
import { UserDetailPageContainer } from "@pages/userDetailPage/userDetailPageContainer";
import { UsersPageContainer } from "@pages/usersPage/usersPageContainer";
import { VouchersPageContainer } from "@pages/voucherPage/voucherPageContainer";
import { WhiteListPageContainer } from "@pages/whiteListPage/whiteListPageContainer";
import { Ambassador_admin, Article_admin, Challenge_admin, Checkup_location_admin, Content_admin, Credits_admin, Dashboard_admin, Event_Super_admin, Event_admin, Flow_admin, Gallery_admin, Journey_admin, Project_admin, Social_admin, Translation_admin, Translation_super_admin, User_admin, Voucher_admin } from "@utils/roles";
import moment from "moment-timezone";
import * as React from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { EnabledLangProvider } from "./context/enabledLangContext/context";

const MAIN_CONFIG_TABS = Object.values(MainConfigSchemas);
const ROUTES_CONFIG_TABS = Object.values(RoutesConfigSchemas);
const THEME_CONFIG_TABS = Object.values(ThemeConfigSchemas);

const messagesEN = require("./translations/en.json");

// tslint:disable-next-line:max-func-body-length
export const App = () => {
    useEffect(() => {
        // DatePicker will work in utc timezone
        moment.tz.setDefault("UTC");
    }, []);

    return (
        <IntlProvider
            locale="en"
            messages={messagesEN}
        >
            <ApolloProvider client={client}>
                <AuthProvider>
                    <EnabledLangProvider>
                        <BrowserRouter>
                            <Switch>
                                <Route
                                    exact
                                    component={LoginContainer}
                                    path="/login"
                                />
                                <Route
                                    component={() => {
                                        return (
                                            <Main>
                                                <Switch>

                                                    {/* AMBASSADORS */}

                                                    <PrivateRoute
                                                        component={AddAmbassadorConfigPageContainer}
                                                        path="/ambassador/ambassadorConfigs/add"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddAmbassadorConfigPageContainer}
                                                        path="/ambassador/ambassadorConfigs/edit/:id"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddAmbassadorsPageContainer}
                                                        path="/ambassador/ambassadors/add/:cId"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddAmbassadorsPageContainer}
                                                        path="/ambassador/ambassadors/edit/:cId/:aId"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AmbassadorsPageContainer}
                                                        path="/ambassador/ambassadorConfigs"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddAmbassadorTagsPageContainer}
                                                        path="/ambassador/tags/add/:tab"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddAmbassadorTagsPageContainer}
                                                        path="/ambassador/tags/edit/:id/:tab"
                                                        roles={[Ambassador_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AmbassadorTagsPageContainer}
                                                        path="/ambassador/tags"
                                                        roles={[Ambassador_admin]}
                                                    />

                                                    {/* CHALLENGES */}

                                                    <PrivateRoute
                                                        exact
                                                        component={ChallengePageContainer}
                                                        path="/challenges"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ChallengeTeamPageContainer}
                                                        path="/challenges/:id/teams"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddChallengesTeamPageContainer}
                                                        path="/challenges/teams/:id/add"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddChallengesTeamPageContainer}
                                                        path="/challenges/teams/:teamId/edit/:id"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ChallengeMemberPageContainer}
                                                        path="/challenges/challenge/:id/teams/:teamId/members"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ChallengeParticipantsPageContainer}
                                                        path="/challenges/:id/participants"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddChallengesPageContainer}
                                                        path="/challenges/add/:tab/:index?"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddChallengesPageContainer}
                                                        path="/challenges/edit/:id/:tab/:itemIndex?"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddChallengeTagsPageContainer}
                                                        path="/challenges/tags/add/:tab"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddChallengeTagsPageContainer}
                                                        path="/challenges/tags/edit/:id/:tab"
                                                        roles={[Challenge_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ChallengeTagsPageContainer}
                                                        path="/challenges/tags"
                                                        roles={[Challenge_admin]}
                                                    />

                                                    {/* CONTENT */}

                                                    <PrivateRoute
                                                        component={AddContentsPageContainer}
                                                        path="/content/contents/add/:tab"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddContentsPageContainer}
                                                        path="/content/contents/edit/:id/:tab"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ContentsPageContainer}
                                                        path="/content/contents"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddContentTagsPageContainer}
                                                        path="/content/tags/add/:tab"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddContentTagsPageContainer}
                                                        path="/content/tags/edit/:id/:tab"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ContentTagsPageContainer}
                                                        path="/content/tags"
                                                        roles={[Content_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={TranslationsPage}
                                                        path="/content/translations/project"
                                                        roles={[Translation_admin]}
                                                    />
                                                    <PrivateRoute
                                                        superAdmin
                                                        component={TranslationsPage}
                                                        path="/content/translations/generic"
                                                        roles={[Translation_super_admin]}
                                                    />

                                                    {/* EVENTS */}

                                                    <PrivateRoute
                                                        component={AddEventsPageContainer}
                                                        path="/event/events/add/:tab/:mode?/:subEventId?"
                                                        roles={[Event_admin, Event_Super_admin]}
                                                        superAdmin={Event_Super_admin}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddEventsPageContainer}
                                                        path="/event/events/edit/:id/:tab/:mode?/:subEventId?"
                                                        roles={[Event_admin, Event_Super_admin]}
                                                        superAdmin={Event_Super_admin}
                                                    />
                                                    <PrivateRoute
                                                        component={EventsPageContainer}
                                                        path="/event/events"
                                                        roles={[Event_admin, Event_Super_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddEventTagsPageContainer}
                                                        path="/event/tags/add/:tab"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddEventTagsPageContainer}
                                                        path="/event/tags/edit/:id/:tab"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={EventTagsPageContainer}
                                                        path="/event/tags"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddFormTemplatesPageContainer}
                                                        path="/event/forms/add"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddFormTemplatesPageContainer}
                                                        path="/event/forms/edit/:id"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={FormsPageContainer}
                                                        path="/event/forms"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddEventGroupPageContainer}
                                                        path="/event/groups/edit/:id"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddEventGroupPageContainer}
                                                        path="/event/groups/add"
                                                        roles={[Event_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={EventGroupPageContainer}
                                                        path="/event/groups"
                                                        roles={[Event_admin]}
                                                    />

                                                    {/* GALLERIES */}

                                                    <PrivateRoute
                                                        component={AddGalleriesPageContainer}
                                                        path="/gallery/galleries/add/:tab"
                                                        roles={[Gallery_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddGalleriesPageContainer}
                                                        path="/gallery/galleries/edit/:id/:tab"
                                                        roles={[Gallery_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={GalleriesPageContainer}
                                                        path="/gallery/galleries"
                                                        roles={[Gallery_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddGalleryTagsPageContainer}
                                                        path="/gallery/tags/add/:tab"
                                                        roles={[Gallery_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddGalleryTagsPageContainer}
                                                        path="/gallery/tags/edit/:id/:tab"
                                                        roles={[Gallery_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={GalleryTagsPageContainer}
                                                        path="/gallery/tags"
                                                        roles={[Gallery_admin]}
                                                    />

                                                    {/* JOURNEYS */}

                                                    <PrivateRoute
                                                        component={AddJourneysPageContainer}
                                                        path="/journeys/add/:tab"
                                                        roles={[Journey_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddJourneysPageContainer}
                                                        path="/journeys/edit/:id/:tab"
                                                        roles={[Journey_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddJourneyTagsPageContainer}
                                                        path="/journeys/tags/add/:tab"
                                                        roles={[Journey_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddJourneyTagsPageContainer}
                                                        path="/journeys/tags/edit/:id/:tab"
                                                        roles={[Journey_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={JourneyTagsPageContainer}
                                                        path="/journeys/tags"
                                                        roles={[Journey_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={JourneysPageContainer}
                                                        path="/journeys"
                                                        roles={[Journey_admin]}
                                                    />

                                                    { /* CHECKUPS */}

                                                    <PrivateRoute
                                                        component={AddCheckupPageContainer}
                                                        path="/checkups/add"
                                                        roles={[Checkup_location_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddCheckupPageContainer}
                                                        path="/checkups/edit/:id"
                                                        roles={[Checkup_location_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={CheckupPageContainer}
                                                        path="/checkups"
                                                        roles={[Checkup_location_admin]}
                                                    />

                                                    { /* WEBSHOP */}

                                                    <PrivateRoute
                                                        component={AddArticlesPageContainer}
                                                        path="/articles/add/:tab"
                                                        roles={[Article_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddArticlesPageContainer}
                                                        path="/articles/edit/:id/:tab"
                                                        roles={[Article_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ArticlesPageContainer}
                                                        path="/articles"
                                                        roles={[Article_admin]}
                                                    />

                                                    { /* ORDERS */}
                                                    <PrivateRoute
                                                        component={OrdersPageContainer}
                                                        path="/orders"
                                                        roles={[Article_admin]}
                                                    />

                                                    { /* CREDITS */}
                                                    <PrivateRoute
                                                        component={AddCreditTypePageContainer}
                                                        path="/credits/add"
                                                        roles={[Credits_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddCreditTypePageContainer}
                                                        path="/credits/edit/:id"
                                                        roles={[Credits_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={CreditsPageContainer}
                                                        path="/credits"
                                                        roles={[Credits_admin]}
                                                    />

                                                    { /* DASHBOARD */}

                                                    <PrivateRoute
                                                        component={DashboardsPageContainer}
                                                        path="/dashboards/overview"
                                                        roles={[Dashboard_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddDashboardPageContainer}
                                                        path="/dashboards/add/:device"
                                                        roles={[Dashboard_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddDashboardPageContainer}
                                                        path="/dashboards/edit/:device/:id"
                                                        roles={[Dashboard_admin]}
                                                    />

                                                    {/* {FLOWS} */}
                                                    <PrivateRoute
                                                        component={AddFlowsPageContainer}
                                                        path="/flows/add"
                                                        roles={[Flow_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddFlowsPageContainer}
                                                        path="/flows/edit/:id"
                                                        roles={[Flow_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={FlowsPageContainer}
                                                        path="/flows"
                                                        roles={[Flow_admin]}
                                                    />

                                                    {/* {VOUCHERS} */}

                                                    <PrivateRoute
                                                        component={AddVouchersPageContainer}
                                                        path="/vouchers/add"
                                                        roles={[Voucher_admin]}
                                                    />
                                                    <PrivateRoute
                                                        editMode
                                                        component={AddVouchersPageContainer}
                                                        path="/vouchers/edit/:id"
                                                        roles={[Voucher_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={VouchersPageContainer}
                                                        path="/vouchers"
                                                        roles={[Voucher_admin]}
                                                    />

                                                    {/* USERS */}
                                                    <PrivateRoute
                                                        component={UsersPageContainer}
                                                        path="/users/overview"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={AddUserPageContainer}
                                                        path="/users/add"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={ProviderActivitiesPage}
                                                        path="/users/detail/:id/providerActivities"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={UserDetailPageContainer}
                                                        path="/users/detail/:id"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={RolesPageContainer}
                                                        path="/users/roles"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={WhiteListPageContainer}
                                                        path="/users/whitelists"
                                                        roles={[User_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={CompaniesPageContainer}
                                                        path="/users/companies"
                                                        roles={[User_admin]}
                                                    />

                                                    { /* MAIN */}
                                                    <PrivateRoute
                                                        component={SocialPageContainer}
                                                        path="/social/:tab"
                                                        roles={[Social_admin]}
                                                    />
                                                    <PrivateRoute
                                                        exact
                                                        component={MainPageContainer}
                                                        path="/"
                                                    />
                                                    <PrivateRoute
                                                        component={(props) => (
                                                            <ConfigPageContainer
                                                                {...props}
                                                                place="main"
                                                                schemasNames={MAIN_CONFIG_TABS}
                                                            />
                                                        )}
                                                        path="/config/main"
                                                        roles={[Project_admin]}
                                                    />
                                                    <PrivateRoute
                                                        experimental
                                                        component={(props) => (
                                                            <ConfigPageContainer
                                                                {...props}
                                                                place="theme"
                                                                schemasNames={THEME_CONFIG_TABS}
                                                            />
                                                        )}
                                                        path="/config/theme"
                                                        roles={[Project_admin]}
                                                    />
                                                    <PrivateRoute
                                                        component={(props) => (
                                                            <ConfigPageContainer
                                                                {...props}
                                                                place="routes"
                                                                schemasNames={ROUTES_CONFIG_TABS}
                                                            />
                                                        )}
                                                        path="/config/routes"
                                                        roles={[Project_admin]}
                                                    />
                                                    <PrivateRoute
                                                        exact
                                                        component={PageNotFoundPage}
                                                        path="/404"
                                                    />
                                                    <Redirect
                                                        from="*"
                                                        to="/404"
                                                    />
                                                </Switch>
                                            </Main>
                                        );
                                    }}
                                    path="*"
                                />
                            </Switch>
                        </BrowserRouter>
                    </EnabledLangProvider>
                </AuthProvider>
            </ApolloProvider>
        </IntlProvider>
    );
};
