import { Avatar, Button, Card, Divider, Spin } from "antd";
import * as moment from "moment";
import * as React from "react";

import { Flex } from "@components/flex/flex";
import { UserInfoCardLabel, UserInfoCardRow, UserInfoCardStyle, UserInfoCardValue } from "@components/users/userInfoCard/userInfoCardStyle";
import { GetUsersQuery } from "@graphql2/types";
import { FormattedMessage } from "react-intl";

export interface UserInfoCardProps {
    validateEmail: () => void;
    requestValidateEmail: () => void;
    toggleEditUserModal(showModal: boolean): void;
    users?: GetUsersQuery["users"] | null;
    usersLoading: boolean;
}

export const UserInfoCard: React.FC<UserInfoCardProps> = (props: UserInfoCardProps) => {
    const { usersLoading, users, toggleEditUserModal, validateEmail, requestValidateEmail } = props;

    if (usersLoading) {
        return <Card><Spin /></Card>;
    }

    const user = users && users[0];

    if (!user) {
        return null;
    }

    const { avatar, givenName, familyName, email, locale, createdAt, lastLogin, profile: { sex, birthDate }, passwordUser } = user;
    const name = givenName && familyName ? `${givenName} ${familyName}` : "";
    const emailValidated = passwordUser?.emailValidated;

    return (
        <Card
            extra={(
                <Button
                    type="default"
                    onClick={() => toggleEditUserModal(true)}
                >
                    <FormattedMessage id="profile.edit" />
                </Button>
            )}
            title={<FormattedMessage id="profile" />}
        >
            <UserInfoCardStyle>
                <Flex direction="column">
                    <UserInfoCardRow alignItems="center">
                        <Avatar
                            shape="square"
                            size={60}
                            src={avatar || undefined}
                        >
                            {!avatar && name && name.split(" ").map(r => r.substr(0, 1))}
                        </Avatar>
                    </UserInfoCardRow>
                    <UserInfoCardRow>
                        <UserInfoCardLabel>Email:</UserInfoCardLabel>
                        <UserInfoCardValue>{email}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="gender" />
:
                        </UserInfoCardLabel>
                        <UserInfoCardValue>{sex}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="age" />
:
                        </UserInfoCardLabel>
                        <UserInfoCardValue>{moment().diff(birthDate || 0, "years")}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="locale" />
:
                        </UserInfoCardLabel>
                        <UserInfoCardValue>{locale && locale.toUpperCase()}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <Divider />
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="createdAt" />
:
                        </UserInfoCardLabel>
                        <UserInfoCardValue>{moment(createdAt).format("DD/MM/YYYY")}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="lastLogin" />
:
                        </UserInfoCardLabel>
                        <UserInfoCardValue>{lastLogin ? moment(lastLogin).format("DD/MM/YYYY") : "--"}</UserInfoCardValue>
                    </UserInfoCardRow>
                    <Divider />
                    <UserInfoCardRow alignItems="center">
                        <UserInfoCardLabel>
                            <FormattedMessage id="email.validationStatus" />
                            :
                        </UserInfoCardLabel>
                        {emailValidated ? <FormattedMessage id="profile.emailValidated" /> : <FormattedMessage id="profile.emailNotValidated" />}
                    </UserInfoCardRow>
                    <UserInfoCardRow
                        alignItems="center"
                        gap="10"
                        wrap="wrap"
                    >
                        <Button
                            disabled={emailValidated}
                            type="default"
                            onClick={requestValidateEmail}
                        >
                            <FormattedMessage id="profile.resendValidationEmail" />
                        </Button>
                        <Button
                            disabled={emailValidated}
                            type="default"
                            onClick={validateEmail}
                        >
                            <FormattedMessage id="profile.validateEmail" />
                        </Button>
                    </UserInfoCardRow>
                </Flex>
            </UserInfoCardStyle>
        </Card>
    );
};
