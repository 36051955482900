import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { maxCharacters } from "@utils/maxCharacters";
import { validateMaxChar } from "@utils/validators/validateMaxChar";
import { InternalNamePath } from "antd/lib/form/interface";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TitleSubTitleDescriptionFieldsStyle } from "../../widgetStyle";

export interface TitleDescriptionFieldsProps {
    languageErrors?: LanguageErrors;
    itemKey?: number;
    prefix: InternalNamePath;
}

export const TitleDescriptionFields: React.FC<TitleDescriptionFieldsProps> = (props) => {
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const { languageErrors, itemKey, prefix } = props;
    const langFieldProps = languages.map(language => ({
        itemKey, language: language.code, hidden: language.code !== selectedLanguage
    }));

    return (
        <TitleSubTitleDescriptionFieldsStyle>
            <TranslationTabs
                activeLanguage={selectedLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={languages}
            />
            {langFieldProps.map(langProps => (
                <React.Fragment key={langProps.language}>
                    <Field
                        required
                        hidden={langProps.hidden}
                        id={`title.${langProps.language}`}
                        label={<FormattedMessage id="title" />}
                        name={[...prefix, "title", langProps.language]}
                        rules={[requiredrule]}
                    />
                    <Field
                        hidden={langProps.hidden}
                        id={`description.${langProps.language}`}
                        info={maxCharacters(500)}
                        label={<FormattedMessage id="description" />}
                        name={[...prefix, "description", langProps.language]}
                        rules={[{ validator: validateMaxChar(500) }]}
                    />
                </React.Fragment>
            ))}
        </TitleSubTitleDescriptionFieldsStyle>
    );
};
