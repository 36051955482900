import { JSONData } from "@energylab/schematics";
import { InjectedIntl } from "@utils/override.types";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { KeyValueMapper } from "../KeyValueMapper";
import { FieldName } from "../fieldsMapper";
import { CardWrapper } from "./cardWrapper";

type ExtendedGenericFieldWrapperProps = {
    disable: boolean;
    disableTitle: boolean;
    displayName: string;
    form: FormInstance;
    intl: InjectedIntl;
    jsonData: JSONData;
    name: FieldName;
    namePath: (string | number)[];
    fieldName: string | number;
    fieldNamePath?: FieldName;
};

export const ExtendedGenericFieldWrapper: React.FC<ExtendedGenericFieldWrapperProps> = (props) => {
    const { disable, disableTitle, displayName, form, intl, jsonData, name, namePath, fieldName, fieldNamePath } = props;
    return (
        <CardWrapper
            description={jsonData.description}
            disableTitle={disableTitle}
            name={displayName}
        >
            <Form.Item
                noStyle
                initialValue={{}}
                name={fieldNamePath}
            >
                <KeyValueMapper
                    disable={disable}
                    fieldName={fieldName}
                    form={form}
                    intl={intl}
                    jsonData={jsonData}
                    name={name}
                    namePath={namePath}
                />
            </Form.Item>
        </CardWrapper>
    );
};
