import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Table, Popconfirm } from "antd";
import { TableIcon } from "@components/table/tableStyle";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { CheckCircleFilled, DeleteOutlined, EditOutlined, MinusCircleFilled } from "@ant-design/icons";
import { PlainButton } from "@components/style/globalStyles";

export interface CompaniesTableData extends TableData {
    name: string;
    code: string;
    role: string;
    limitRegistrations: boolean;
    maxRegistrations: number;
}

export interface CompaniesTableProps extends TableComponentProps {
    dataSource: CompaniesTableData[];
    handlers: TableHandlers & {
        selectCompany(companyId: string): void;
        removeCompany(id: string): void;
    };
}

export class CompaniesTable extends React.Component<CompaniesTableProps> {
    public render() {
        return (
            <Table
                pagination={{ showSizeChanger: true, showQuickJumper: true }}
                rowKey="id"
                {...this.props}
            >
                <Table.Column
                    key="code"
                    title={<FormattedMessage id="companyCode" />}
                    dataIndex="code"
                    sorter={(first: CompaniesTableData, second: CompaniesTableData) => first.code.localeCompare(second.code)}
                    render={(text, row: CompaniesTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <PlainButton onClick={() => handlers.selectCompany(row.id)}>{text}</PlainButton>
                            )
                        };
                    }}
                />
                <Table.Column
                    key="name"
                    title={<FormattedMessage id="name" />}
                    dataIndex="name"
                    sorter={((first: CompaniesTableData, second: CompaniesTableData) => first.name.localeCompare(second.name))}
                />
                <Table.Column
                    key="role"
                    title={<FormattedMessage id="role" />}
                    dataIndex="role"
                />
                <Table.Column
                    key="limitRegistrations"
                    title={<FormattedMessage id="limitRegistrations" />}
                    dataIndex="limitRegistrations"
                    width={180}
                    render={this.renderLimitRegistrations}
                />
                <Table.Column
                    key="maxRegistrations"
                    title={<FormattedMessage id="maxRegistrations" />}
                    dataIndex="maxRegistrations"
                    width={180}
                    render={this.renderMaxRegistrations}
                />
                <Table.Column
                    key="handlers"
                    width={120}
                    render={(text, row: CompaniesTableData) => {
                        const { handlers } = this.props;

                        return {
                            children: (
                                <Flex justifyContent="flex-end" alignItems="center">
                                    <TableIcon as={EditOutlined} onClick={() => handlers.selectCompany(row.id)} />
                                    <Popconfirm
                                        title={<FormattedMessage id="deleteConfirm" />}
                                        onConfirm={() => handlers.removeCompany(row.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <TableIcon as={DeleteOutlined} />
                                    </Popconfirm>
                                </Flex>
                            ),
                            props: {
                                colSpan: 2
                            }
                        };
                    }}
                />
            </Table>
        );
    }

    private renderLimitRegistrations(limitRegistrations) {
        if (limitRegistrations) {
            return <CheckCircleFilled className="green" />;
        }
        return <MinusCircleFilled className="draft" />;
    }

    private renderMaxRegistrations(maxRegistrations, row) {
        if (!row.limitRegistrations) {
            return "--";
        }

        return maxRegistrations;
    }
}
