import React, { useState } from "react";
import { ErrorDictionary, mapValidateErrorEntity } from "@utils/mapValidateErrorEntity";

/**
 * Usage:
 * Create the error state as with a normal useState
 * `const [errors, setErrors] = useErrors()`
 *
 * When updating the error State, pass the ant design form errors to setErrors (wrappedSetErrors in this file).
 * The errors will be mapped to an object before updating the state.
 *
 * input:
 * { errorFields: [{ name: ["type"], errors: [...] }] }
 *
 * output:
 * { type: { errors: [...] } }
 */

export const useErrors = (a?: any): [ErrorDictionary, React.Dispatch<React.SetStateAction<ErrorDictionary>>, () => void] => {
    const deprecated = () => {
        console.warn("remove the names on the useErrors hook and remove the 3th return item", a);
    };

    if (a) {
        deprecated();
    }

    const [errors, setErrors] = useState({});
    const wrappedSetErrors = (e) => setErrors(mapValidateErrorEntity(e));

    return [errors, wrappedSetErrors, deprecated];
};
