import * as React from "react";
import { compose } from "redux";
import { GetProjectConfigsProps, withGetProjectConfigs } from "@graphql2/types";
import { exportFactory, ExportProps } from "./export";
import { ExportContainerProps, getByPlaceQuery, withLocalState } from "./exportContainer";

export const withProjectConfig = withGetProjectConfigs(getByPlaceQuery);

export const ExportConfigContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withProjectConfig
)(exportFactory({ targetProp: ["data", "projectConfigs"] }));

export type ExportConfigContainerProps = ExportContainerProps & MaybeElements<GetProjectConfigsProps>;
