import { DatePickerField } from "@components/field/DatePickerfield";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { MarginRightDiv } from "@pages/addJourneysPage/addJourneysPageStyle";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { JourneyLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../context/enabledLangContext/context";

export interface EventJourneyFormProps {
    form: FormInstance;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const eventTitleField = (props: JourneyLanguageFieldProps, index) => (
    <Field
        key={index}
        hidden={props.hidden}
        id={`eventTitle.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="eventTitle" />}
        name={["eventTitle", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

const eventDescriptionField = (props: JourneyLanguageFieldProps, index) => (
    <Field
        key={index}
        hidden={props.hidden}
        id={`eventDescription.${props.language}`}
        label={<FormattedMessage id="eventDescription" />}
        name={["eventDescription", props.language]}
        {...props.sharedProps}
    />
);

const locationField = (props: JourneyLanguageFieldProps, index) => (
    <Field
        key={index}
        hidden={props.hidden}
        id={`location.${props.language}`}
        label={<FormattedMessage id="location" />}
        name={["location", props.language]}
        {...props.sharedProps}
    />
);

const detailedLocationField = (props: JourneyLanguageFieldProps, index) => (
    <Field
        key={index}
        hidden={props.hidden}
        id={`detailedLocation.${props.language}`}
        label={<FormattedMessage id="detailedLocation" />}
        name={["detailedLocation", props.language]}
        {...props.sharedProps}
    />
);

// tslint:disable-next-line:max-func-body-length
export const EventJourneyForm: React.FC<EventJourneyFormProps> = (props) => {
    const { enabledLanguages } = useEnabledLang();
    const { changeSelectedLanguage, languageErrors, form, activeLanguage, ...sharedProps } = props;

    const langFieldProps = React.useMemo(
        () => enabledLanguages.map(
            language => ({ form, sharedProps, defaultValues: {}, language: language.code, hidden: language.code !== activeLanguage })
        ),
        [enabledLanguages, activeLanguage]
    );

    return (
        <React.Fragment>
            <FormattedMessage
                id="eventJourneySettings"
                tagName="h2"
            />
            <Flex margin="0 0 1em 0">
                <MarginRightDiv>
                    <DatePickerField
                        id="date"
                        label={<FormattedMessage id="date" />}
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        {...sharedProps}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <Field
                        numberType
                        id="distance"
                        label={<FormattedMessage id="distance" />}
                        min={0}
                        name="distance"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        {...sharedProps}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <Field
                        numberType
                        id="cost"
                        label={<FormattedMessage id="cost" />}
                        min={0}
                        name="eventCost"
                        {...sharedProps}
                    />
                </MarginRightDiv>
                <MarginRightDiv>
                    <SelectField
                        id="currency"
                        label={<FormattedMessage id="currency" />}
                        name="eventCurrency"
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="form.isrequired" />
                            }
                        ]}
                        {...sharedProps}
                    >
                        <Select.Option
                            key="EUR"
                            value="EUR"
                        >
EUR
                        </Select.Option>
                        <Select.Option
                            key="USD"
                            value="USD"
                        >
USD
                        </Select.Option>
                        <Select.Option
                            key="GBP"
                            value="GBP"
                        >
GBP
                        </Select.Option>
                    </SelectField>
                </MarginRightDiv>
            </Flex>
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />
            {langFieldProps.map(eventTitleField)}
            {langFieldProps.map(eventDescriptionField)}
            <Flex>
                {langFieldProps.map(locationField)}
                {langFieldProps.map(detailedLocationField)}
            </Flex>
        </React.Fragment>
    );
};
