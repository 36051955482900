import { WidgetProps } from "@components/dashboard/widgets/models";
import { Flex } from "@components/flex/flex";
import { Button, Dropdown, Form, Menu, Tabs, Badge } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StatisticsWidgetPane } from "@components/dashboard/widgets/statisticsWidget/statisticsWidgetPane";
import { widgetItemFactory } from "../../../../pages/addDashboardPage/factories/widgetItemFactory";
import { DefaultWidgetFields } from "../defaultWidgetFields";
import { WidgetConfigStyle } from "../widgetStyle";
import { StatisticsWidgetStyle } from "./statisticsWidgetStyle";
import { TitleSubtitleDescriptionCard } from "../titleSubtitleDescriptionFields";

export enum SubPropertyTypes {
    CALORIES = "calories",
    DISTANCE = "distance",
    STEPS = "steps",
    DURATION = "duration",
    ELEVATION = "elevation",
    WORKOUTS = "workouts"
}

export interface StatisticsWidgetProps extends WidgetProps {

}

const menuItems: string[] = ["challenges", "users", "cycling", "running", "walking", "swimming", "workout"];

export const StatisticsWidget: React.FC<StatisticsWidgetProps> = (props) => {
    const { errors, widgetId, form } = props;
    const [activeKey, setActiveKey] = React.useState("0");

    return (
        <StatisticsWidgetStyle>
            <WidgetConfigStyle>
                <DefaultWidgetFields
                    id={widgetId}
                />
                <FormattedMessage id="widget.title" tagName="h3" />
                <TitleSubtitleDescriptionCard
                    disableDescription
                    disableSubtitle
                    languageErrors={errors && errors.title}
                    widgetId={widgetId}
                />
                <FormattedMessage id="widget.settings" tagName="h3" />
                <Form.List name={["widgets", widgetId, "statistics"]}>
                    {(fields, { add, remove }) => {
                        const menu = (
                            <Menu
                                onClick={e => add(widgetItemFactory({
                                    widgetType: "statistics",
                                    type: String(e.key)
                                }))}
                            >
                                {menuItems.map(item => (
                                    <Menu.Item key={item}>
                                        <FormattedMessage id={`statisticsWidget.${item}`} />
                                    </Menu.Item>
                                ))}
                            </Menu>
                        );

                        return (
                            <Flex direction="column" className="tabscover" justifyContent="flex-start" alignItems="flex-start">
                                <Dropdown className="dropDownCarousselPicker" overlay={menu}>
                                    <Button>
                                        <PlusOutlined />
                                        <FormattedMessage id="carousselWidget.add" />&nbsp;
                                        <DownloadOutlined />
                                    </Button>
                                </Dropdown>
                                <Tabs
                                    onChange={setActiveKey}
                                    activeKey={activeKey}
                                    type="editable-card"
                                    hideAdd
                                    onEdit={(targetKey, action) => {
                                        if (action === "remove") {
                                            remove(Number(targetKey));
                                        }
                                    }}
                                >
                                    {fields.map((field, index) => {
                                        const pane = form.getFieldValue(["widgets", widgetId, "statistics", field.name]);
                                        const hasError = Boolean(widgetId && errors && errors.widgets && errors.widgets[widgetId]
                                            && errors.widgets[widgetId].statistics[field.name]);
                                        return (
                                            <Tabs.TabPane
                                                forceRender
                                                tab={<Badge dot={hasError}>Item {index + 1}</Badge>}
                                                key={`${field.name}`}
                                            >
                                                <StatisticsWidgetPane
                                                    widgetId={widgetId}
                                                    form={form}
                                                    pane={pane}
                                                    field={field}
                                                />
                                            </Tabs.TabPane>
                                        );
                                    })}
                                </Tabs>
                            </Flex>
                        );
                    }}
                </Form.List>
            </WidgetConfigStyle>
        </StatisticsWidgetStyle>
    );
};
