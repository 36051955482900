import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TableIcon } from "@components/table/tableStyle";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { useEnabledLang } from "@context/enabledLangContext/context";
import { LanguageErrors, errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { getText } from "@utils/getText";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { maxCharacters } from "@utils/maxCharacters";
import { Button, Col, Form, Row, Select, Table, message } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChallengeLeaderboardVisualisationFormValues } from "./editChallengeLeaderboardVisualisation";
import { VisualisationdGridStyle } from "./editChallengeLeaderboardVisualisationStyle";
import { ExampleLeaderboard } from "./exampleLeaderboard/exampleLeaderboard";
import { ProfileFieldsNameMapper, SelectedProfileFieldsTranslationsMapper, titleField } from "./profileFIeldsForms";

interface ConditionsLeaderboardVisualisationFieldsProps {
    index: number;
    form: FormInstance;
    activeLanguage: string;
    errors?: ErrorDictionary;
    languageErrors?: LanguageErrors;
    onChange(): void;
    changeSelectedLanguage(languageCode: string): void;
}

interface AddConditionCardProps {
    type: "add" | "edit";
    form: FormInstance;
    index: number;
    hidden?: boolean;
    conditionIndex?: number;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    onChange(): void;
    back?(): void;
    changeSelectedLanguage(languageCode: string): void;
    add?(data): void;
}

export const AddConditionCard: React.FC<AddConditionCardProps> = (props) => {
    const {
        type,
        form: existingForm,
        index,
        add,
        changeSelectedLanguage,
        activeLanguage,
        hidden,
        conditionIndex,
        back,
        onChange
    } = props;

    const { enabledLanguages } = useEnabledLang();

    const sharedProps = {
        onBlur: onChange
    };

    let formToUse = existingForm;

    if (type === "add") {
        const [form] = useForm();
        formToUse = form;
    }

    const closeEditCard = async () => {
        if (back) {
            back();
        }
    };

    const addCondition = async () => {
        formToUse.submit();
    };

    const langFieldProps: ChallengeLanguageFieldProps[] = enabledLanguages.map(language => ({
        form: formToUse,
        sharedProps,
        defaultValues: {},
        language: language.code,
        hidden: language.code !== activeLanguage
    }));

    const conditions = existingForm.getFieldValue(["conditions"]);
    const prefix = conditionIndex !== undefined ? ["visualisations", index, "conditions", conditionIndex] : [];
    const columnNamePath = [...prefix, "columnNames"];

    const body = (
        <Row style={{ width: "100%", display: hidden ? "none" : "block" }}>
            <Col style={{ width: "100%" }}>
                <ContentHeader
                    directionTitles="column"
                    rightFields={[
                        <Button
                            type="primary"
                            onClick={type === "edit" ? closeEditCard : addCondition}
                        >
                            <FormattedMessage id={type === "edit" ? "back" : "add"} />
                        </Button>
                    ]}
                    subTitle={(
                        <FormattedMessage
                            id={`leaderboard.condition.${type}.description`}
                            tagName="span"
                        />
                    )}
                    title={(
                        <FormattedMessage
                            id={`leaderboard.condition.${type}`}
                            tagName="h1"
                        />
                    )}
                />
                <SelectField
                    label="Condition"
                    name={conditionIndex !== undefined ? [conditionIndex, "conditionId"] : ["conditionId"]}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="form.isrequired" />
                        }
                    ]}
                    {...sharedProps}
                >
                    {conditions.filter(condition => !condition.formTemplate).map(c => (
                        <Select.Option
                            key={c.id}
                            value={c.id}
                        >
                            <ConditionTitle condition={c} />
                        </Select.Option>
                    ))}
                </SelectField>
                <ProfileFieldsNameMapper
                    fieldPrefix={conditionIndex !== undefined ? [conditionIndex] : []}
                    form={formToUse}
                    prefix={prefix}
                    sharedProps={sharedProps}
                />
                <Form.Item
                    dependencies={[columnNamePath]}
                >
                    {() => {
                        const columnNames: string[] | undefined = formToUse.getFieldValue(columnNamePath);
                        const headersNames = langFieldProps.map(({ language }) => columnNames?.map((_, id) => [...prefix, "columns", id, "header", language]) || []);
                        const titleNames = langFieldProps.map(({ language }) => [...prefix, "title", language]);
                        return (
                            <React.Fragment>
                                <Form.Item
                                    shouldUpdate
                                >
                                    {() => {
                                        const langErrors = langFieldProps.map(({ language }, id) => {
                                            const hasErrors = formToUse.getFieldsError([titleNames[id], ...headersNames[id]]).some(({ errors }) => errors.length);
                                            return {
                                                [language]: hasErrors
                                            };
                                        }).reduce((a, b) => ({ ...a, ...b }), {});
                                        return (
                                            <TranslationTabs
                                                activeLanguage={activeLanguage}
                                                errors={langErrors}
                                                handleChangeSelectedLanguage={changeSelectedLanguage}
                                                languages={enabledLanguages}
                                            />
                                        );
                                    }}
                                </Form.Item>
                                {langFieldProps.map(
                                    titleField(conditionIndex !== undefined ? [conditionIndex, "title"] : ["title"], <FormattedMessage id="title" />)
                                )}
                                <SelectedProfileFieldsTranslationsMapper
                                    columnsPathName={conditionIndex !== undefined ? [conditionIndex, "columns"] : ["columns"]}
                                    form={formToUse}
                                    langFieldProps={langFieldProps}
                                    prefix={prefix}
                                    sharedProps={sharedProps}
                                />
                            </React.Fragment>
                        );
                    }}
                </Form.Item>
            </Col>
        </Row>
    );

    if (type === "add") {
        return (
            <Form
                form={formToUse}
                onFinish={(values) => {
                    if (!add) {
                        return;
                    }
                    try {
                        add(values);
                        onChange();

                        if (back) {
                            back();
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }}
                onFinishFailed={() => {
                    message.error("config.fields.error");
                }}
                onSubmitCapture={e => {
                    e.stopPropagation();
                }}
            >
                {body}
            </Form>
        );
    }

    return body;
};

export const ConditionsLeaderboardVisualisationFields: React.FC<ConditionsLeaderboardVisualisationFieldsProps> = (props) => {
    const { index, form, changeSelectedLanguage, activeLanguage, onChange, languageErrors, errors } = props;
    const [conditionEditIndex, setConditionEditIndex] = React.useState<number>();

    const { enabledLanguages } = useEnabledLang();

    const sharedProps = {
        onBlur: onChange
    };

    const goBack = () => {
        setConditionEditIndex(undefined);
    };

    const descriptionField = (descriptionProps: ChallengeLanguageFieldProps) => (
        <Field
            key={`visualisations.description.${descriptionProps.language}`}
            hidden={descriptionProps.hidden}
            id={`visualisations.description.${descriptionProps.language}`}
            info={maxCharacters()}
            label={<FormattedMessage id="description" />}
            name={["visualisations", index, "description", descriptionProps.language]}
            rules={[
                {
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                },
                {
                    max: 140,
                    message: <FormattedMessage id="form.toolong" />
                }
            ]}
            {...descriptionProps.sharedProps}
        />
    );

    const renderExampleLeaderboard = () => {
        const conditions = form.getFieldValue(["visualisations", index, "conditions"]);
        const tabs = conditions.map(c => c.title);

        return (
            <ExampleLeaderboard tabs={tabs} />
        );
    };

    const langFieldProps: ChallengeLanguageFieldProps[] = enabledLanguages.map(language => ({
        form,
        sharedProps,
        defaultValues: {},
        language: language.code,
        hidden: language.code !== activeLanguage
    }));

    const conditionsToTableDataSource = (conditions: ChallengeLeaderboardVisualisationFormValues["conditions"]) => {
        return conditions.map((c, i) => {
            return {
                ...c,
                hasError: hasError(errors, [i])
            };
        });
    };

    return (
        <React.Fragment>
            <VisualisationdGridStyle>
                <Row gutter={16}>
                    <Col style={{ width: "35%" }}>
                        <FormattedMessage
                            id="leaderboard.livedata"
                            tagName="h1"
                        />
                        {renderExampleLeaderboard()}
                    </Col>
                    <Col style={{ width: "65%" }}>

                        <Form.List name={["visualisations", index, "conditions"]}>
                            {(fields, { add }) => {
                                const conditions = form.getFieldValue(["visualisations", index, "conditions"]);
                                return (
                                    <React.Fragment>
                                        {conditions.map((c, i) => (
                                            <AddConditionCard
                                                activeLanguage={activeLanguage}
                                                back={goBack}
                                                changeSelectedLanguage={changeSelectedLanguage}
                                                conditionIndex={i}
                                                form={form}
                                                hidden={i !== conditionEditIndex}
                                                index={index}
                                                languageErrors={errors && errors[i] && errorsToLanguageErrors(errors[i])}
                                                type="edit"
                                                onChange={onChange}
                                            />
                                        ))}

                                        {conditionEditIndex === undefined && (
                                            <AddConditionCard
                                                activeLanguage={activeLanguage}
                                                add={add}
                                                back={() => {
                                                    setConditionEditIndex(undefined);
                                                }}
                                                changeSelectedLanguage={changeSelectedLanguage}
                                                form={form}
                                                index={index}
                                                languageErrors={languageErrors}
                                                type="add"
                                                onChange={onChange}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            }}
                        </Form.List>

                    </Col>
                </Row>
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <FormattedMessage
                    id="leaderboard.conditions"
                    tagName="h1"
                />
                <Form.List name={["visualisations", index, "conditions"]}>
                    {(fields, { remove }) => {
                        const conditions = form.getFieldValue(["conditions"]);
                        return (
                            <Table
                                dataSource={conditionsToTableDataSource(form.getFieldValue(["visualisations", index, "conditions"]))}
                            >
                                <Table.Column
                                    key="condition"
                                    dataIndex="conditionId"
                                    render={record => <ConditionTitle condition={conditions.find(c => c.id === record)} />}
                                    title="Condition"
                                    width={200}
                                />
                                <Table.Column
                                    key="title"
                                    dataIndex="title"
                                    render={record => getText(record)}
                                    title="Title"
                                    width={200}
                                />
                                <Table.Column
                                    key="columns"
                                    dataIndex="columns"
                                    render={(values) => values?.map(value => getText(value?.header)).join(", ")}
                                    title="Columns"
                                    width={200}
                                />
                                {renderErrorColumn()}
                                <Table.Column
                                    key="handlers"
                                    align="right"
                                    render={(text, record, i) => {
                                        return (
                                            <React.Fragment>
                                                <TableIcon
                                                    as={EditOutlined}
                                                    onClick={() => setConditionEditIndex(i)}
                                                />
                                                <TableIcon
                                                    as={DeleteOutlined}
                                                    onClick={() => {
                                                        remove(i);

                                                        if (conditionEditIndex === i) {
                                                            setConditionEditIndex(undefined);
                                                        }
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                    }}
                                    width={100}
                                />
                            </Table>
                        );
                    }}
                </Form.List>
            </VisualisationdGridStyle>
            <VisualisationdGridStyle>
                <FormattedMessage
                    id="leaderboard.info"
                    tagName="h1"
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={enabledLanguages}
                />
                {langFieldProps.map(descriptionField)}
            </VisualisationdGridStyle>
            {/* <VisualisationdGridStyle>
                <FormattedMessage id="rows" tagName="h1" />
                <Form.Item
                    {...sharedProps}
                    name={["visualisations", index, "item", "top", "enabled"]}
                    valuePropName="checked"
                >
                    <Checkbox><FormattedMessage id="visualisation.leaderboard.highlistUsers" /></Checkbox>
                </Form.Item>
                <Field
                    {...sharedProps}
                    name={["visualisations", index, "item", "top", "count"]}
                    label={<FormattedMessage id="visualisation.leaderboard.amountUsers" />}
                    numberType
                    min={0}
                />
            </VisualisationdGridStyle> */}
        </React.Fragment>
    );
};
