import * as React from "react";
import { compose } from "redux";
import { withGetGalleries, GetGalleriesProps } from "@graphql2/types";
import { ExportProps, exportFactory } from "./export";
import { getByIdQuery, withLocalState, ExportContainerProps } from "./exportContainer";

const withGalleries = withGetGalleries(getByIdQuery);

export const ExportGalleryContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withGalleries
)(exportFactory({ targetProp: ["data", "galleries"] }));

export type ExportGalleryContainerProps = ExportContainerProps & MaybeElements<GetGalleriesProps>;
