import { TextByLanguage } from "../graphql2/types";

export interface LanguageObject {
    [lang: string]: string;
}

export function toLanguageObject(textByLanguages?: TextByLanguage[] | any | null): LanguageObject {
    if (!textByLanguages) {
        return {};
    }

    const languageObject: LanguageObject = {};
    textByLanguages.forEach(textByLanguage => {
        if (textByLanguage.language) {
            languageObject[textByLanguage.language] = textByLanguage.text || "";
        }
    });

    return languageObject;
}
