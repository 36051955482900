import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { JourneyLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../context/enabledLangContext/context";

export interface JourneyTitleAndDescriptionFormValues {
    title: LanguageObject;
    subTitle: LanguageObject;
}

export interface TitleAndDescriptionCardProps {
    form: FormInstance;
    hidden?: boolean;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

// TODO: fix onblur

const titleField = (props: JourneyLanguageFieldProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

const subTitleField = (props: JourneyLanguageFieldProps) => (
    <Field
        key={`subTitle.${props.language}`}
        hidden={props.hidden}
        id={`subTitle.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="subTitle" />}
        name={["subTitle", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            }
        ]}
        {...props.sharedProps}
    />
);

export const TitleAndDescriptionCard: React.FC<TitleAndDescriptionCardProps> = (props: TitleAndDescriptionCardProps) => {
    const { enabledLanguages } = useEnabledLang();

    const { changeSelectedLanguage, hidden, form, languageErrors, disabled, onBlur, activeLanguage } = props;
    const sharedProps = {
        disabled,
        onBlur
    };

    const langFieldProps = React.useMemo(
        () => enabledLanguages.map(
            language => ({ form, sharedProps, defaultValues: {}, language: language.code, hidden: language.code !== activeLanguage })
        ),
        [enabledLanguages, activeLanguage]
    );

    return (
        <Card hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="titleAndDescription" />}
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />

            {langFieldProps.map(titleField)}
            {langFieldProps.map(subTitleField)}
        </Card>
    );
};

