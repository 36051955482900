import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { ExportGalleryTagContainer } from "@components/export/exportGalleryTagsContainer";
import { withGetGalleryTags, withRemoveGalleryTag } from "@graphql2/types";

export const withGalleryTags = withGetGalleryTags();
export const withRemoveGalleryTagMutation = withRemoveGalleryTag({
    name: "removeTag",
    options: {
        refetchQueries: ["getGalleryTags"],
        awaitRefetchQueries: true
    }
});

export const GalleryTagsPageContainer = createTagsPageContainer(
    withGalleryTags,
    withRemoveGalleryTagMutation,
    "/gallery/tags/add/general",
    "gallery",
    ExportGalleryTagContainer,
    ["data", "galleryTags"]
);
