/* eslint-disable react/prop-types */
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer, TableData } from "@components/table/tableContainer";
import { WhiteListTable } from "@components/whiteList/whiteListTable";
import { useAuth } from "@context/authContext/context";
import { UserWhiteListFragment } from "@graphql2/types";
import { WhiteListPageProps } from "@pages/whiteListPage/whiteListPageContainer";
import { FlexButtonWrapper, WhiteListPageStyle } from "@pages/whiteListPage/whiteListPageStyle";
import { getFrontendRoles } from "@utils/applicationUtils";
import { Button, Card, Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { WhiteListForm, mapFormToValues, mapValuesToForm } from "./witheListPageMappers";

export interface WhiteListPageState {
    whitelistIndex?: number;
}

export type WhiteListTableData = TableData & UserWhiteListFragment;

export const WhiteListPage: React.FC<WhiteListPageProps> = (props) => {
    const {
        userWhiteLists: { userWhiteLists, loading: loadingUserWhiteList },
        applications: { applications },
        companies: { companies }
    } = props;
    const [showModal, setShowModal] = React.useState(false);
    const [whiteList, setWhiteList] = React.useState<WhiteListTableData | undefined>();
    const intl = useIntl();
    const [form] = useForm();
    const { projectId } = useAuth();
    const roles = getFrontendRoles(applications);

    const removeUserWhiteList = async (group?: string | null) => {
        if (!group) {
            message.error(intl.formatMessage({ id: "whitelist.removeFailed" }));

            return;
        }
        try {
            await props.removeUserWhiteList({ variables: { group, projectId } });
            message.success(intl.formatMessage({ id: "whitelist.removed" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "whitelist.removeFailed" }));
            console.error(err);
        }
    };

    const selectWhiteList = (item: WhiteListTableData) => {
        form.setFieldsValue(item);
        setShowModal(true);
        setWhiteList(item);
    };

    const clearWhiteList = () => {
        form.resetFields();
        setWhiteList(undefined);
        setShowModal(false);
    };

    const submitForm = async (formsValues: WhiteListForm) => {
        const values = mapFormToValues(formsValues);
        if (whiteList) {
            try {
                await props.updateUserWhiteList({ variables: { ...values, projectId } });
                message.success(intl.formatMessage({ id: "whitelist.updated" }));
                clearWhiteList();
            } catch (err) {
                message.error(intl.formatMessage({ id: "whitelist.updateFailed" }));
            }
        } else {
            try {
                await props.addUserWhiteList({ variables: { ...values, projectId } });
                message.success(intl.formatMessage({ id: "whitelist.added" }));
                clearWhiteList();
            } catch (err) {
                message.error(intl.formatMessage({ id: "whitelist.addFailed" }));
            }
        }
    };

    return (
        <WhiteListPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage id="whitelists" />
                    <div>
                        <Button
                            key="button1"
                            type="primary"
                            onClick={() => setShowModal(true)}
                        >
                            <FormattedMessage id="whitelist.addWhitelistRule" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    dataSource={(userWhiteLists || []).map((w, i) => ({ ...mapValuesToForm(w), id: String(i) }))}
                    handlers={{
                        removeUserWhiteList,
                        selectWhiteList
                    }}
                    loading={loadingUserWhiteList}
                    TableComponent={WhiteListTable}
                    tableFilter={{}}
                />
            </Card>
            {
                userWhiteLists && (
                    <Modal
                        className="noModalFooter"
                        visible={showModal}
                        onCancel={clearWhiteList}
                    >
                        <Form
                            form={form}
                            onFinish={submitForm}
                        >
                            <Field
                                disabled={whiteList !== undefined}
                                id="group"
                                label={<FormattedMessage id="group" />}
                                name="group"
                                type="text"
                            />
                            <SelectField
                                filterOption={(input, option) => Object.values(option ?? []).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                                id="type"
                                label={<FormattedMessage id="roles" />}
                                mode="multiple"
                                name="roles"
                            >
                                {roles.map(r => (
                                    <Select.Option
                                        key={r.id}
                                        value={r.id}
                                    >
                                        {r.name}
                                    </Select.Option>
                                ))}
                            </SelectField>
                            <Field
                                id="whitelist"
                                label={<FormattedMessage id="whitelistRule" />}
                                name="whitelist"
                                type="text"
                            />
                            {companies && companies.length > 0 && (
                                <SelectField
                                    id="type"
                                    label={<FormattedMessage id="companyId" />}
                                    name="companyId"
                                >
                                    {companies.map(c => c && (
                                        <Select.Option
                                            key={c.id}
                                            value={c.id}
                                        >
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </SelectField>
                            )}
                            <FlexButtonWrapper
                                fullWidth
                                justifyContent="center"
                                margin="20px 0"
                            >
                                <Button
                                    key="button1"
                                    htmlType="submit"
                                    type="primary"
                                >
                                    <FormattedMessage id="whitelist.save" />
                                </Button>
                            </FlexButtonWrapper>
                        </Form>
                    </Modal>
                )
            }
        </WhiteListPageStyle>
    );
};
