import { Table, Popconfirm, Tooltip } from "antd";
import { CheckCircleFilled, MinusCircleFilled, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { LowStock, NormalStock } from "@pages/articlesPage/articlesPageStyle";
import { TableIcon } from "@components/table/tableStyle";
import { Link } from "react-router-dom";
import { Flex } from "@components/flex/flex";
import { ArticleDeleteItem } from "@pages/articlesPage/articlesPage";
import { ExportArticleContainer } from "@components/export/exportArticleContainer";
import Column from "antd/lib/table/Column";
import { ColumnProps } from "antd/lib/table";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";

export interface ArticleTableData extends TableData {
    title: string;
    price: number;
    thirdParty: string;
    currentStock: number;
    status: boolean;
    statusType: string;
    priority: number;
}

export interface ArticleTableProps extends TableComponentProps {
    dataSource: ArticleTableData[];
    handlers: TableHandlers & {
        removeArticle(id: string): void;
        handleSelectedArticlesOnChange(articles: ArticleDeleteItem[]): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<ArticleTableData>;
}

// tslint:disable-next-line: max-func-body-length
export const ArticleTable: React.FC<ArticleTableProps> = ({ handlers, ...tableProps }) => {
    const rowSelection = {
        onChange: (_, selectedRows) => {
            handlers.handleSelectedArticlesOnChange(selectedRows.map(r => {
                return { id: r.id, title: r.title };
            }));
        }
    };

    const renderTitle = (text: string, id: string) => (
        <Link className="plainText" to={`/articles/edit/${id}/general`}>
            {text}
        </Link>
    );

    const renderStatus = (status, statusType) => {
        if (status && statusType === "active") {
            return (
                <Tooltip title={statusType}>
                    <CheckCircleFilled className="green" />
                </Tooltip>
            );
        }
        return (
            <Tooltip title={statusType}>
                <MinusCircleFilled className={statusType} />
            </Tooltip>
        );
    };

    const renderStock = (stock: number) => {
        if (stock <= 5) {
            return <LowStock>{stock}</LowStock>;
        }

        return <NormalStock>{stock}</NormalStock>;
    };

    const renderHandlers = (row: ArticleTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <ExportArticleContainer id={row.id} />
                <Link to={`/articles/edit/${row.id}/general`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeArticle(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowSelection={rowSelection}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="expiresOn"
                title={<FormattedMessage id="status" />}
                dataIndex="status"
                width={95}
                render={(text, row: ArticleTableData) => renderStatus(text, row.statusType)}
                sorter
            />
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                sorter
                render={(text, row: ArticleTableData) => renderTitle(text, row.id)}
            />
            <Column
                key="thirdParty"
                title={<FormattedMessage id="thirdParty" />}
                dataIndex="thirdParty"
                sorter
            />
            <Column
                key="price"
                title={<FormattedMessage id="price" />}
                dataIndex="price"
                width={120}
                sorter
            />
            <Column
                key="currentStock"
                title={<FormattedMessage id="stock" />}
                dataIndex="currentStock"
                width={100}
                render={renderStock}
                sorter
            />
            <Column
                key="priority"
                title={<FormattedMessage id="priority" />}
                dataIndex="priority"
                width={100}
                sorter
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: ArticleTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
