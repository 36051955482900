import { debounce } from "@utils/debounce";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import { defaultSortAndPagingSettings } from "@utils/withPagingAndSort";
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as moment from "moment";
import { Moment } from "moment";
import queryString from "query-string";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface FilterField {
    name: string;
    type: "select" | "input" | "date" | "checkbox" | "seperator";
    disabled?: boolean;
    span?: number;
}

interface SelectOption {
    value: string;
    name: string;
}

interface GroupedSelectOption {
    name: string;
    options: SelectOption[];
}

interface SelectFilterField extends FilterField {
    options: SelectOption[] | GroupedSelectOption[];
    placeholder?: string;
    multiple?: boolean;
    type: "select";
}

interface InputFilterField extends FilterField {
    search?: boolean;
    placeholder?: string;
    type: "input";
    number?: {
        min?: number;
        max?: number;
    };
}

interface CheckboxFilterField extends FilterField {
    label: string;
    type: "checkbox";
}

interface DateFilterField extends FilterField {
    type: "date";
    placeholder?: string;
    label?: string;
    defaultValue?: Moment;
}

interface SeperatorField extends FilterField {
    type: "seperator";
    symbol?: string;
}

interface DynamicFilterBarProps {
    fields: (CheckboxFilterField | InputFilterField | SelectFilterField | DateFilterField | SeperatorField)[];
}

export const parseDynamicFilterValues = (search: string): any => {
    return queryString.parse(search, {
        arrayFormat: "comma",
        parseBooleans: true,
        parseNumbers: true
    });
};

export const DynamicFilterBar: React.FC<DynamicFilterBarProps> = (props) => {
    const { fields } = props;
    const [form] = useForm();
    const history = useHistory();

    const initialValues = React.useMemo(() => {
        const values = parseDynamicFilterValues(history.location.search);
        return fields.map(field => {
            if (values[field.name] === undefined) {
                return undefined;
            }
            if (field.type === "date") {
                return {
                    [field.name]: moment(values[field.name])
                };
            }
            return {
                [field.name]: values[field.name]
            };
        }).reduce((a, b) => (b ? { ...b, ...a } : a), {});
    }, [history]);

    const updateFilter = () => {
        // This filter will take over sorting values, but skip paging (on change of filter we always want to start at page 1 again!)
        const { sortBy, sort } = parseDynamicFilterValues(history.location.search);
        const parsedFilter = queryString.stringify(
            {
                ...form.getFieldsValue(), sortBy, sort
            },
            {
                arrayFormat: "comma",
                skipNull: true,
                skipEmptyString: true
            }
        );
        history.push({ search: parsedFilter });
    };

    const debouncedUpdateFilter = debounce(updateFilter, 500);

    const renderInputField = (field: InputFilterField) => {
        if (field.search) {
            return (
                <Input.Search
                    allowClear
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    type={field.number ? "number" : undefined}
                    onChange={debouncedUpdateFilter}
                />
            );
        }

        if (field.number) {
            return (
                <InputNumber
                    disabled={field.disabled}
                    max={field.number.max || undefined}
                    min={field.number.min || 0}
                    placeholder={field.placeholder}
                    onChange={debouncedUpdateFilter}
                />
            );
        }

        return (
            <Input
                allowClear
                disabled={field.disabled}
                placeholder={field.placeholder}
                onChange={debouncedUpdateFilter}
            />
        );
    };

    const renderSelectField = (field: SelectFilterField) => {
        let options;

        // eslint-disable-next-line dot-notation
        if (field.options[0] && field.options[0]["options"]) {
            options = (field.options as GroupedSelectOption[] || []).map((group: GroupedSelectOption) => {
                return (
                    <Select.OptGroup
                        key={group.name}
                        label={group.name}
                    >
                        {group.options.map(option => (
                            <Select.Option value={option.value}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                );
            });
        } else {
            options = (field.options as SelectOption[]).map(option => (
                <Select.Option value={option.value}>
                    {option.name}
                </Select.Option>
            ));
        }

        return (
            <Select
                allowClear
                disabled={field.disabled}
                mode={field.multiple ? "multiple" : undefined}
                placeholder={field.placeholder}
                onChange={updateFilter}
            >
                {options}
            </Select>
        );
    };

    const renderCheckboxField = (field: CheckboxFilterField) => {
        return (
            <Checkbox
                disabled={field.disabled}
                onChange={updateFilter}
            >
                {field.label}
            </Checkbox>
        );
    };

    const renderDateField = (field: DateFilterField) => {
        return (
            <Form.Item
                label={field.label}
                name={field.name}
                style={{ margin: "0 10px" }}
            >
                <DatePicker
                    inputReadOnly
                    allowClear={false}
                    defaultValue={field.defaultValue}
                    format="YYYY-MM-DD"
                    placeholder={field.placeholder}
                    onChange={updateFilter}
                />
            </Form.Item>
        );
    };

    const renderSeperatorField = (field: SeperatorField) => {
        if (field.symbol) {
            return <span>{field.symbol}</span>;
        }

        return <span>-</span>;
    };

    const renderFormItem = (field: DynamicFilterBarProps["fields"][0]) => {
        let input;
        const extraValues: any = {};
        if (objectTypeCheck<InputFilterField>(field, "input")) {
            input = renderInputField(field);
        }
        if (objectTypeCheck<DateFilterField>(field, "date")) {
            input = renderDateField(field);
        }

        if (objectTypeCheck<CheckboxFilterField>(field, "checkbox")) {
            input = renderCheckboxField(field);
            extraValues.valuePropName = "checked";
        }

        if (objectTypeCheck<SelectFilterField>(field, "select")) {
            input = renderSelectField(field);
        }

        if (objectTypeCheck<SeperatorField>(field, "seperator")) {
            input = renderSeperatorField(field);
            return (
                <Col>
                    <Form.Item>
                        {input}
                    </Form.Item>
                </Col>
            );
        }

        return (
            <Col span={field.span}>
                <Form.Item
                    name={field.name}
                    {...extraValues}
                >
                    {input}
                </Form.Item>
            </Col>
        );
    };

    return (
        <Form
            form={form}
            initialValues={initialValues}
        >
            <Row gutter={10}>
                {fields.map(renderFormItem)}
            </Row>
        </Form>
    );
};

export const withParseDynamicFilterBar = (
    createFilterFn?: (objectFilter: any) => any,
    settings?: {
        defaultSortSettings?: { sortBy: string; sort: string; };
        defaultPagingSettings?: { skip: number; pageSize: number; };
    }
) => {
    return (Component) => {
        return (props) => {
            const values = parseDynamicFilterValues(props.history.location.search);

            const { skip, pageSize, sortBy, sort, ...filter } = values;

            const newProps = {
                ...props,
                filter: createFilterFn ? createFilterFn(filter) : filter
            };

            newProps.paging = {
                skip: skip || settings?.defaultPagingSettings?.skip || defaultSortAndPagingSettings.paging.skip,
                limit: pageSize || settings?.defaultPagingSettings?.pageSize || defaultSortAndPagingSettings.paging.limit
            };

            if ((sort && sortBy) || settings?.defaultSortSettings) {
                newProps.sort = {
                    field: sortBy || settings?.defaultSortSettings?.sortBy,
                    order: sort || settings?.defaultSortSettings?.sort
                };
            }
            return <Component {...newProps} />;
        };
    };
};
