import styled from "styled-components";

export const EmptyPage = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 276px);

    h1 span {
        font-size: 2.25rem;
        font-weight: 600;
        font-family: "PingFang SC"
    }

    p {
        font-size: 1rem;
    }

    p, span {
        color: rgba(40, 40, 40, .5);
    }
`;
