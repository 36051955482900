// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from "apollo-utilities";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { DashboardConfigColumnItem, DashboardConfigColumnPickerStyle, DashboardConfigColumns, DashboardConfigColumnSizeItem, DashboardConfigColumnSizeItemSpan, DashboardConfigColumnSizes } from "@components/dashboard/dashboardConfigColumnPicker/dashboardConfigColumnPickerStyle";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type SetWidthSettingsColumns = (props: { widths: number[] }) => void;

export interface DashboardConfigColumnPickerProps {
    widths: number[];
    setWidths: (value: number[]) => void;
    view: ViewType;
}

const COLUMN_AMOUNT_OPTIONS = {
    mobile: [1],
    tablet: [1, 2],
    desktop: [1, 2, 3]
};

type ColumnOptions = {
    [key in ViewType]: {
        [key: string]: {
            amount: number;
            options: number[][];
        };
    }
}

const COLUMN_OPTIONS_BY_AMOUNT: ColumnOptions = {
    mobile: {
        1: {
            amount: 1,
            options: [
                [1]
            ]
        }
    },
    tablet: {
        1: {
            amount: 1,
            options: [
                [1]
            ]
        },
        2: {
            amount: 2,
            options: [
                [1, 1]
            ]
        }
    },
    desktop: {
        1: {
            amount: 1,
            options: [
                [1]
            ]
        },
        2: {
            amount: 2,
            options: [
                [1, 2],
                [1, 1],
                [2, 1]
            ]
        },
        3: {
            amount: 3,
            options: [
                [1, 1, 1]
            ]
        }
    }
};

// tslint:disable-next-line: max-func-body-length
export const DashboardConfigColumnPicker: React.FC<DashboardConfigColumnPickerProps> = ({ widths, setWidths, view }) => {
    const columnCount = widths.length;
    const currentColumnOptions = COLUMN_OPTIONS_BY_AMOUNT[view][columnCount];

    return (
        <DashboardConfigColumnPickerStyle>
            <FormattedMessage id="dashboardConfigColumnPicker.title" tagName="h2" />
            <FormattedMessage id="dashboardConfigColumnPicker.numberOfColumns" />

            {/* Number of columns */}
            <DashboardConfigColumns>
                {COLUMN_AMOUNT_OPTIONS[view].map((option) => {
                    return (
                        <DashboardConfigColumnItem
                            key={option}
                            active={columnCount === option}
                            onClick={() => {
                                setWidths(COLUMN_OPTIONS_BY_AMOUNT[view][option].options[0]);
                            }}
                        >
                            {option}
                        </DashboardConfigColumnItem>
                    );
                })}
            </DashboardConfigColumns>
            <FormattedMessage id="dashboardConfigColumnPicker.columnSizes" />
            {/* Column sizes */}
            <DashboardConfigColumnSizes>
                {currentColumnOptions.options.map((option) => {
                    const total = Object.keys(option).reduce((prev, size) => prev + option[size], 0);

                    return (
                        <DashboardConfigColumnSizeItem
                            key={option.join("-")}
                            active={isEqual(widths, option)}
                            onClick={() => setWidths(option)}
                        >
                            {option.map((width, index) => (
                                <DashboardConfigColumnSizeItemSpan
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    width={(100 / total) * width}
                                />
                            ))}
                        </DashboardConfigColumnSizeItem>
                    );
                })}
            </DashboardConfigColumnSizes>
        </DashboardConfigColumnPickerStyle>
    );
};
