import { ContentHeader } from "@components/contentHeader/contentHeader";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { TagsTableData } from "@components/tags/tagsTable";
import { UserDetailFragment } from "@graphql2/types";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AdminTooltip } from "@components/tooltip";
import { ACTIVITY_TYPES_CHALLNGE } from "../../../constants";

export interface SettingsFormValues {
    activityTypes: string[];
    tags: string[];
    userId?: string;
    place: string;
}

export interface SettingsCardProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    tags: TagsTableData[];
    users?: UserDetailFragment[];
    singleUser?: UserDetailFragment[];
    onBlur(): void;
    onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    changeSelectedLanguage(languageCode: string): void;
    searchUsers(userSearch: string): void;
}

@autobind
export class SettingsCard extends React.Component<SettingsCardProps> {
    public render() {
        const { hidden, disabled, onBlur } = this.props;
        const sharedProps = {
            disabled,
            onBlur
        };

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="ambassador.settings" />}
                />
                {this.renderAuthorPicker(sharedProps)}
                {this.renderTags(sharedProps)}
                {this.renderActivityTypes(sharedProps)}
            </Card>
        );
    }

    private renderActivityTypes(sharedProps) {
        return (
            <Flex>
                <SelectField
                    name="activityTypes"
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    mode="multiple"
                    label={<FormattedMessage id="activityTypes" />}
                    id="activityTypes"
                    {...sharedProps}
                >
                    {ACTIVITY_TYPES_CHALLNGE ? ACTIVITY_TYPES_CHALLNGE.map(a => <Select.Option value={a.key} key={a.key}>{a.value}</Select.Option>) : null}
                </SelectField>
            </Flex>
        );
    }

    private renderTags(sharedProps) {
        const { tags } = this.props;

        return (
            <Flex>
                <SelectField
                    name="tags"
                    placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                    mode="multiple"
                    label={<FormattedMessage id="tags" />}
                    id="tags"
                    {...sharedProps}
                >
                    {tags ? tags.map(contentTag => <Select.Option value={contentTag.id} key={contentTag.id}>{contentTag.title}</Select.Option>) : null}
                </SelectField>
            </Flex>
        );
    }

    private selectedUserStillExists = () => {
        const { users, singleUser, form } = this.props;
        const selectedUserId = form.getFieldValue("userId");

        if (selectedUserId) {
            const user = [...(users || []), ...(singleUser || [])].find(u => u.id === selectedUserId);

            if (user) {
                return true;
            }

            return false;
        }

        return true;
    }

    private renderAuthorPicker(sharedProps) {
        const { users, singleUser, form } = this.props;

        const userExists = this.selectedUserStillExists();

        return (
            <Flex>
                <Form.Item>
                    <SelectField
                        name="userId"
                        onSearch={this.props.searchUsers}
                        showArrow
                        showSearch
                        allowClear
                        placeholder="Select users"
                        id="userId"
                        label={(
                            <Flex>
                                <FormattedMessage id="user" />
                                {!userExists && (
                                    <AdminTooltip
                                        description={<FormattedMessage id="selectedUser.unavailable" />}
                                        type="warning"
                                        customStyles={{ padding: "0 0 0 5px" }}
                                    />
                                )}
                            </Flex>
                        )}
                        notFoundContent={null}
                        filterOption={false}
                        {...sharedProps}
                        onChange={(e: string) => {
                            if (e && e.length) {
                                form.setFieldsValue({
                                    userId: e
                                });
                            } else {
                                form.setFieldsValue({
                                    userId: undefined
                                });
                            }
                        }}
                    >
                        {users && Array.isArray(users)
                            ? users.map(u => <Select.Option value={u.id} key={u.id}>{u.displayName}</Select.Option>)
                            : singleUser && singleUser[0] && singleUser[0].id && (
                                <Select.Option value={singleUser[0].id} key={singleUser[0].id}>{singleUser[0].displayName}</Select.Option>
                            )}
                    </SelectField>
                </Form.Item>
            </Flex>
        );
    }
}
