import { FileDragAndDrop } from "@components/fileDragAndDrop/fileDragAndDrop";
import * as React from "react";
import { DragDropContext } from "react-dnd";
import createHTML5Backend from "react-dnd-html5-backend";
import { compose } from "redux";

export interface FileDragAndDropContainerProps {
    defaultImage?: string;
    onChange?(image?: string): void;
}

export const FileDragAndDropContainer = compose<React.ComponentClass<FileDragAndDropContainerProps>>(
    DragDropContext(createHTML5Backend)
)(FileDragAndDrop);

export type FileDragAndDropProps = FileDragAndDropContainerProps;
