import { Checkbox, Select, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { SortProps, PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { requiredrule } from "@components/form/requiredRule";

export interface Paging {
    enabled?: boolean | null;
    type?: string | null;
    size?: number | null;
}

export interface Sort {
    field: string;
    order: string;
}

export interface TitleSubtitleDescriptionFormValues {
    sort: Sort;
    paging: Paging;
}

export interface WidgetSortProps {
    defaults?: Partial<SortProps>;
    form: FormInstance;
    loadMore?: boolean;
    paging?: number;
    unsaved?: boolean;
    paginationLimit?: number;
    id: string;
}

export interface DirectProps {
    type: PreviewTypes;
    updateParentState?: (sortOptions: Partial<{
        limit: number;
        offset: number;
        sort: Partial<SortProps>;
    }>) => void;
}

export const WidgetSort = ({
    defaults, type, form, loadMore, paginationLimit, id, updateParentState = () => {}
}: WidgetSortProps & DirectProps) => {
    const hasPaging = Boolean(paginationLimit);

    if (!defaults || !defaults.fields || !defaults.fields.length) {
        return null;
    }

    return (
        <React.Fragment>
            <FormattedMessage id="sortAndPagination" tagName="h3" />
            <SelectField
                name={["widgets", id, "sort", "field"]}
                rules={[requiredrule]}
                onChange={fieldname => {
                    updateParentState({
                        sort: {
                            order: form.getFieldValue(["widget", id, "sort", "order"]),
                            field: fieldname.toString(),
                            fields: defaults.fields
                        },
                        limit: form.getFieldValue(["widgets", id, "paging", "size"])
                    });
                }}
                label={<FormattedMessage id="sort.field" />}
                id="sort.field"
            >
                {defaults.fields.map(sortOption => (
                    <Select.Option key={sortOption.toString()} value={sortOption.toString()}>
                        <FormattedMessage id={`dynamicWidget.${sortOption.toString()}`} values={{ item: type.toString() }} />
                    </Select.Option>
                ))}
            </SelectField>
            <SelectField
                label={<FormattedMessage id="sort.order" />}
                id="sort.order"
                rules={[requiredrule]}
                name={["widgets", id, "sort", "order"]}
                onChange={sort => {
                    const limit = hasPaging ? { limit: form.getFieldValue(["widgets", id, "paging", "size"]) } : {};
                    updateParentState({
                        sort: {
                            order: sort.toString(),
                            field: form.getFieldValue(["widgets", id, "sort", "field"]),
                            fields: defaults.fields
                        },
                        ...limit
                    });
                }}
            >
                <Select.Option key="DESC" value="DESC"><FormattedMessage id="sort.desc" /></Select.Option>
                <Select.Option key="ASC" value="ASC"><FormattedMessage id="sort.asc" /></Select.Option>
            </SelectField>
            <Field
                name={["widgets", id, "paging", "size"]}
                rules={[{
                    required: true,
                    message: <FormattedMessage id="form.isrequired" />
                }]}
                onChange={target => {
                    const limit = Number(target);

                    updateParentState({
                        sort: {
                            order: form.getFieldValue(["widgets", id, "sort", "order"]),
                            field: form.getFieldValue(["widgets", id, "sort", "field"]),
                            fields: defaults.fields
                        },
                        limit
                    });
                }}
                label={<FormattedMessage id="paging.size" />}
                id="paging.size"
                numberType
                min={1}
            />
            {loadMore && (
                <SelectField
                    name={["widgets", id, "paging", "type"]}
                    label={<FormattedMessage id="paging.type" />}
                    id="paging.type"
                >
                    <Select.Option key="loadMore" value="loadMore">
                        <FormattedMessage id="loadMore" />
                    </Select.Option>
                </SelectField>

            )}
            <Form.Item
                name={["widgets", id, "paging", "enabled"]}
                initialValue
            >
                <Checkbox style={{ display: "none" }} defaultChecked />
            </Form.Item>
        </React.Fragment>
    );
};
