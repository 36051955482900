import { getEventCount, getEventsOverview } from "@graphql2/graphql_op";
import { AddEventsPage } from "@pages/addEventsPage/addEventsPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import moment from "moment";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { AddEventProps, EventFragment, GetApplicationsProps, GetChronoRaceEventsProps, GetEventTagsProps, GetEventsProps, GetFormTemplatesOverviewProps, GetProjectsProps, RemoveEventProps, UpdateEventProps, withAddEvent, withGetApplications, withGetChronoRaceEvents, withGetEventTags, withGetEvents, withGetFormTemplatesOverview, withGetProjects, withRemoveEvent, withUpdateEvent } from "../../graphql2/types";

export interface AddEventsPageContainerProps {
    editMode?: boolean;
    superAdmin: boolean;
}

export const ADD_EVENT_TABS = {
    general: "general",
    subevents: "subevents",
    participants: "participants",
    comments: "comments"
};

export const ADD_EVENT_SUBEVENT_VIEWS = {
    overview: "overview",
    add: "add",
    edit: "edit"
};

export type AddEventsRouteProps = RouteComponentProps<{ tab: string; id: string; mode?: string; subEventId?: string; }, {}, { import?: EventFragment; }>;

const withEvents = withGetEvents({
    name: "events",
    options: (props: AddEventsRouteProps) => ({
        errorPolicy: "all",
        variables: {
            filter: {
                ids: props.match.params.id ? [props.match.params.id] : []
            },
            paging: {}
        }
    }),
    skip: (props: AddEventsRouteProps) => {
        return !props.match.params.id;
    }
});

const withEventTags = withGetEventTags({
    name: "tags"
});
const withApplications = withGetApplications({
    name: "applications"
});
const withFormTemplates = withGetFormTemplatesOverview({
    name: "formTemplatesQueryResults",
    options: () => ({
        variables: {
            filter: {
                type: "event"
            }
        }
    })
});

const withChronoRaceEvents = withGetChronoRaceEvents({
    name: "chronoRaceEvents",
    options: () => ({
        variables: {
            filter: {
                from: moment.utc().startOf("day").valueOf()
            }
        }
    }),
    skip(props: AddEventsPageProps) {
        return !props.superAdmin;
    }
});

const withProjects = withGetProjects({
    name: "projectsQueryResults",
    skip(props: AddEventsPageProps) {
        return !props.superAdmin;
    }
});

const withAddEventsMutation = withAddEvent({
    name: "addEvent",
    options: {
        refetchQueries: () => [
            "getEventsOverview",
            {
                query: getEventCount,
                variables: {
                    filter: {
                        endsAfter: moment.utc().startOf("day").valueOf()
                    },
                    paging: {}
                }
            }
        ],
        awaitRefetchQueries: true,
        update: cleanCache([getEventsOverview])
    }
});

const withUpdateEventMutation = withUpdateEvent({
    name: "updateEvent",
    options: {
        refetchQueries: () => [
            "getEventsOverview",
            {
                query: getEventCount,
                variables: {
                    filter: {
                        endsAfter: moment.utc().startOf("day").valueOf()
                    },
                    paging: {}
                }
            }
        ],
        awaitRefetchQueries: true,
        update: cleanCache([getEventsOverview])
    }
});

const withRemoveEventMutation = withRemoveEvent({
    name: "removeEvent",
    options: {
        refetchQueries: () => [
            "getEventsOverview",
            {
                query: getEventCount,
                variables: {
                    filter: {
                        endsAfter: moment.utc().startOf("day").valueOf()
                    },
                    paging: {}
                }
            }
        ],
        awaitRefetchQueries: true,
        update: cleanCache([getEventsOverview])
    }
});

export const AddEventsPageContainer = compose<React.ComponentClass<AddEventsPageContainerProps>>(
    withRouter,
    withEvents,
    withEventTags,
    withApplications,
    withFormTemplates,
    withAddEventsMutation,
    withUpdateEventMutation,
    withRemoveEventMutation,
    withProjects,
    withChronoRaceEvents
)(AddEventsPage);

export type AddEventsPageProps =
    AddEventsRouteProps
    & AddEventsPageContainerProps
    & GetEventTagsProps<{}, "tags">
    & MaybeElements<GetEventsProps<{}, "events">>
    & AddEventProps<{}, "addEvent">
    & UpdateEventProps<{}, "updateEvent">
    & RemoveEventProps<{}, "removeEvent">
    & GetApplicationsProps<{}, "applications">
    & GetFormTemplatesOverviewProps<{}, "formTemplatesQueryResults">
    & MaybeElements<GetProjectsProps<{}, "projectsQueryResults">>
    & MaybeElements<GetChronoRaceEventsProps<{}, "chronoRaceEvents">>
