import { GalleriesPage } from "@pages/galleriesPage/galleriesPage";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { GraphQLPagingAndSort } from "@utils/withPagingAndSort";
import { withGetGalleriesOverview, withGetGalleriesOverviewCount, withRemoveGallery, GetGalleriesOverviewProps, GetGalleriesOverviewCountProps, RemoveGalleryProps } from "@graphql2/types";
import { withParseDynamicFilterBar } from "@components/filter/dynamicFilterBar";

const withGalleriesOverview = withGetGalleriesOverview({
    name: "galleries",
    options({ paging, sort }: GalleriesPageProps) {
        return {
            variables: {
                paging,
                sort
            }
        };
    }
});

const withGalleriesOverviewCount = withGetGalleriesOverviewCount({
    name: "count"
});

const withRemoveGalleryMutation = withRemoveGallery({
    name: "removeGallery",
    options: {
        refetchQueries: ["getGalleriesOverview", "getGalleriesOverviewCount"],
        awaitRefetchQueries: true
    }
});

export const GalleriesPageContainer = compose<React.ComponentClass>(
    withRouter,
    withParseDynamicFilterBar(),
    withGalleriesOverview,
    withGalleriesOverviewCount,
    withRemoveGalleryMutation
)(GalleriesPage);

export type GalleriesPageProps =
    & GraphQLPagingAndSort
    & RouteComponentProps
    & GetGalleriesOverviewProps<{}, "galleries">
    & GetGalleriesOverviewCountProps<{}, "count">
    & RemoveGalleryProps<{}, "removeGallery">;
