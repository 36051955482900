import styled from "styled-components";


export const EditorWrapper = styled.div`
    position: relative;
`;

export const DisableOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: not-allowed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.05);
`;
