import Icon from "@ant-design/icons";
import styled from "styled-components";

export const FormItemFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        flex-shrink: 0;
        margin-right: 1em;
    }

    .ant-input, .ant-form-item {
        flex: 1;
        margin-right: 1rem;
    }
`;

export interface DeleteFormFieldProps {
    hidden?: boolean;
}

// TODO: replace icon
export const DeleteFormField = styled(Icon)`
    float: right;
    font-size: 1rem;
    color: rgba(40,40,40, .5);
    cursor: pointer;
    transition: color .2s ease;
    visibility: ${(props: DeleteFormFieldProps) => props.hidden ? "hidden" : "visible"};

    &:hover {
        color: rgba(40,40,40, 1)
    }
`;
