import { Button, Card } from "antd";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { AmbassadorTable } from "@components/ambassador/ambassadorTable";
import { TableContainer } from "@components/table/tableContainer";

import { DynamicFilterBar } from "@components/filter/dynamicFilterBar";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { AmbassadorsPageProps } from "@pages/ambassadorsPage/ambassadorsPageContainer";
import { translateAmbassadorConfigs } from "@utils/mappers/ambassadorMapper";
import { useUpdateQuery } from "@utils/useUrlQuery";
import { getCurrentTablePage, getPageSize, getUpdateUrlQueryParams, onTableChange } from "@utils/withPagingAndSort";
import { TablePaginationConfig as PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";

export const AmbassadorsPage: React.FC<AmbassadorsPageProps> = (props) => {
    const {
        ambassadorConfigs,
        removeAmbassadorConfig,
        history,
        count,
        paging
    } = props;

    const intl = useIntl();

    const updateUrlQuery = useUpdateQuery({ replace: true });

    const updateTableSettings = (pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) => {
        updateUrlQuery(getUpdateUrlQueryParams(onTableChange(pagination, filters, sorter)));
    };

    const translatedAmbassadorConfigs = React.useMemo(
        () => translateAmbassadorConfigs(ambassadorConfigs?.ambassadorConfigs || []),
        [ambassadorConfigs]
    );

    return (
        <div>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="ambassadorsConfig.overview"
                        tagName="h1"
                    />
                    <div>
                        <Button
                            className="headerButton"
                            type="primary"
                            onClick={() => history.push("/ambassador/ambassadorConfigs/add")}
                        >
                            <FormattedMessage id="ambassador.addAmbassadorConfig" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <DynamicFilterBar fields={[
                    {
                        name: "isPrimary",
                        type: "checkbox",
                        label: intl.formatMessage({ id: "ambassadors.filter.isMainAmbassador" })
                    }
                ]}
                />
                <TableContainer
                    dataSource={translatedAmbassadorConfigs}
                    handlers={{
                        removeAmbassadorConfig: async (id) => removeAmbassadorConfig({ variables: { id } })
                    }}
                    loading={ambassadorConfigs && ambassadorConfigs.loading}
                    pagination={{
                        current: getCurrentTablePage(paging),
                        pageSize: getPageSize(paging),
                        total: count?.ambassadorConfigCount || 0
                    }}
                    TableComponent={AmbassadorTable}
                    onChange={updateTableSettings}
                />
            </Card>
        </div>
    );
};
