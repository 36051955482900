import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { ChallengePageDefaultProps } from "@pages/addChallengesPage/addChallengesPage";
import { ChallengeBasicSettingsCard } from "@pages/addChallengesPage/tabs/general/sections/challengeBasicSettingsCard";
import { TitleAndDescriptionCard } from "@pages/addChallengesPage/tabs/general/sections/titleAndDescriptionCard";
import { BaseTag } from "@pages/tagsPage/baseTagsPageContainer";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";

export interface ChallengeGeneralTabProps {
    defaultProps: ChallengePageDefaultProps;
    hidden: boolean;
    errors?: LanguageErrors;
    customErrors?: any;
    tags?: BaseTag[];
    form: FormInstance;
    challengeParticipantCount?: number;
    editMode: boolean;
}

export const ChallengGeneralTab: React.FC<ChallengeGeneralTabProps> = (props) => {
    const {
        defaultProps,
        hidden,
        errors,
        customErrors,
        tags,
        form,
        challengeParticipantCount,
        editMode
    } = props;

    return (
        <React.Fragment>
            <TitleAndDescriptionCard
                {...defaultProps}
                customErrors={customErrors}
                form={form}
                hidden={hidden}
                languageErrors={errors}
            />
            <CoverImageCard
                {...defaultProps}
                required
                showSaveButton
                cropSettings={{
                    aspect: ImageRatios.DEFAULT,
                    zoom: true,
                    rotate: true
                }}
                defaultImage={defaultProps.defaultValues.imageUrl || ""}
                form={form}
                formField="imageUrl"
                hidden={hidden}
                titleId="coverImage"
            />
            <ChallengeBasicSettingsCard
                {...defaultProps}
                challengeParticipantCount={challengeParticipantCount}
                editMode={editMode}
                form={form}
                hidden={hidden}
                languageErrors={errors}
                tags={tags}
            />
        </React.Fragment>
    );
};
