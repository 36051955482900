import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";
import { DashboardConfigWidgetSocial, DashboardConfigWidget, DashboardConfigWidgetSocialInput } from "@graphql2/types";

export type DashboardConfigWidgetSocialFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetSocial, {
}>>;

export const mapSocialStateWidgetToSocialWidget = (widget: DashboardConfigWidgetSocialFormValues): DashboardConfigWidgetSocialInput => {
    return {
        ...widget
    };
};

export const mapSocialWidgetToSocialStateWidget = (socialWidget: DashboardConfigWidget): DashboardConfigWidgetSocialFormValues => {
    const widget = socialWidget as DashboardConfigWidgetSocial;

    return {
        ...widget,
        type: socialWidget.type as ViewType
    };
};
