import styled from "styled-components";
import { Flex } from "@components/flex/flex";
import { Col } from "antd";

export const UserDetailPageStyle = styled.div`
    .ant-col-16 .ant-card {
        margin-left: 0;
    }
`;

export const ModalFlexWrapper = styled(Flex)`
    justify-content: center;
    padding: 0;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;

    .ant-modal-body {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ant-btn-lg {
        width: calc(100% - 60px);
        margin-bottom: 10px;
    }
`;

export const CenteredCol = styled(Col)`
    text-align: center;
`;