import { CardSubtitle } from "@pages/addChallengesPage/tabs/general/challengeGeneralTabStyle";
import { Checkbox, Col, Form, Row } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SharedProps } from "../../general/sections/challengeBasicSettingsCard";
import { SettingsRowWrapper } from "../challengeAdvancedTabStyle";

const FIELDS = [
    "stepsVisible",
    "elevationVisible",
    "durationVisible",
    "distanceVisible",
    "creditsEarnedVisible",
    "caloriesVisible"
];

export const ExtraInfoVisibilitySettings = (props: SharedProps) => {
    return (
        <React.Fragment>
            <CardSubtitle><FormattedMessage id="challenge.totals" /></CardSubtitle>
            <SettingsRowWrapper>
                <Row gutter={48}>
                    <Col span={12}>
                        {FIELDS.map(f => (
                            <Form.Item
                                key={`totals-${f}`}
                                name={["totals", f]}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    {...props}
                                >
                                    <FormattedMessage id={`challenge.${f}`} />
                                </Checkbox>
                            </Form.Item>
                        ))}
                    </Col>
                </Row>
            </SettingsRowWrapper>
        </React.Fragment>
    );
};
