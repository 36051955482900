import * as React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { FormItemProps } from "antd/lib/form";
import { FieldInfo } from "@components/field/fieldStyle";
import ReactMarkdown from "@opuscapita/react-markdown";
import { NestedArrayData, setNestArrayFieldValue } from "@utils/form/setArrayFieldValue";
import { createSetFieldsValue } from "@utils/form/createSetFieldsValue";
import TextArea from "antd/lib/input/TextArea";
import { FormattedMessage } from "react-intl";

export interface MdeFieldProps extends FormItemProps {
    form?: FormInstance | null;
    label: JSX.Element;
    info?: JSX.Element;
    disabled?: boolean;
    id?: string;
    value?: string;
    hidden?: boolean;
    hasError?: any;
    formSetData?: NestedArrayData[];
    compatibilityMode?: boolean;
    onBlur?(): void;
}

export const MdeField: React.FC<MdeFieldProps> = (props) => {
    if (props.compatibilityMode) {
        return (
            <div style={props.hidden ? { display: "none" } : undefined}>
                {!props.hidden && <label htmlFor={props.id}>{props.label}</label>}
                <Form.Item
                    name={props.name}
                    rules={props.rules}
                    hidden={props.hidden}
                    id={props.id}
                >
                    <TextArea disabled={props.disabled} rows={4} />
                </Form.Item>
            </div>

        );
    }
    const mdeRef = React.useRef();

    /*
        * TODO: Warning: Received `true` for a non-boolean attribute `autoCorrect`.
        * This error is generated by Slate, maybe an update can fix this?
    */

    const { form, label, info, name, initialValue, hidden, disabled, formSetData, ...inputProps } = props;

    if (!form) {
        return null;
    }

    const formItemProps = {
        name,
        initialValue
    };

    const handleValueChange = (): void => {
        const { onBlur } = props;

        if (onBlur) {
            onBlur();
        }

        const text = (mdeRef.current as any).plainInput.getCurrentText();
        if (formSetData) {
            setNestArrayFieldValue(form, formSetData, text);
        } else {
            form.setFieldsValue(createSetFieldsValue(name, text));
        }
    };

    return (
        <div style={hidden ? { display: "none" } : undefined}>
            {!hidden && <label className={props.required ? "isRequired" : ""} htmlFor={inputProps.id}>{label}</label>}
            {!hidden && info && <FieldInfo>{info}</FieldInfo>}
            <div className={props.hasError ? "manual-error-styling" : ""}>
                <ReactMarkdown
                    value={formItemProps.initialValue}
                    readOnly={disabled}
                    onBlur={handleValueChange}
                    ref={mdeRef}
                />
            </div>
            {props.hasError && (
                <div className="manual-error-text">
                    <FormattedMessage id={props.hasError} />
                </div>
            )}
            <Form.Item hidden name={formItemProps.name} className={hidden ? "hidden" : ""} rules={props.rules}>
                <Input />
            </Form.Item>
        </div>
    );
};
