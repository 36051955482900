import { LoginProps as LoginMutationProps, withLogin } from "@graphql2/types";
import { Login } from "@pages/login/login";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";

export interface LoginContainerProps { }

export const withLoginMutation = withLogin({
    name: "login"
});

export const LoginContainer = compose<React.ComponentClass<LoginContainerProps>>(
    withLoginMutation,
    withRouter
)(Login);

export type LoginProps =
    LoginContainerProps &
    RouteComponentProps &
    LoginMutationProps<{}, "login">;
