import { SelectField } from "@components/field/selectField";
import { JSONData } from "@energylab/schematics";
import { InjectedIntl } from "@utils/override.types";
import { Select } from "antd";
import { Rule } from "antd/lib/form";
import React from "react";
import { FieldName } from "../fieldsMapper";

type SelectFieldWrapperProps = {
    disable: boolean;
    displayName: string;
    fieldNamePath?: FieldName;
    intl: InjectedIntl;
    jsonData: JSONData;
    required: boolean;
    isArray?: boolean;
};

export const SelectFieldWrapper: React.FC<SelectFieldWrapperProps> = (props) => {
    const { disable, displayName, fieldNamePath, intl, jsonData, required, isArray } = props;

    const defaultValue = isArray ? jsonData.items?.default : jsonData.default;
    const enumValues = isArray ? jsonData.items?.enum : jsonData.enum;
    const mode = isArray ? "tags" : undefined;

    const getValidatonRules = () => {
        const rules: Rule[] = [
            {
                required,
                message: "Required"
            }
        ];

        if (isArray) {
            rules.push({
                validator: (rule, value, callback) => {
                    const length = value?.length ?? 0;
                    if (!jsonData.minItems || jsonData.minItems <= length) {
                        return callback();
                    }
                    return callback(intl.formatMessage({ id: "array.minLength" }, { number: jsonData.minItems }));
                }
            });
        }
        return rules;
    };

    return (
        <SelectField
            fullWidth
            description={jsonData.description}
            disabled={disable}
            initialValue={defaultValue}
            label={displayName}
            mode={mode}
            name={fieldNamePath}
            rules={getValidatonRules()}
            style={{ width: 200 }}
        >
            {enumValues?.map(value => {
                const valueToDisplay = value?.toString() ?? "";
                return (
                    <Select.Option
                        key={valueToDisplay}
                        value={valueToDisplay}
                    >
                        {valueToDisplay}
                    </Select.Option>
                );
            })}
        </SelectField>
    );
};
