import { ContentHeader } from "@components/contentHeader/contentHeader";
import { SubEventsTable } from "@components/events/subEventsTable";
import { translateSubEvents } from "@utils/mappers/eventMapper";
import { InjectedIntl } from "@utils/override.types";
import { LanguageObject } from "@utils/toLanguageObject";
import { Button, Card, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { Moment } from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SubEventErrors } from "../addEventsPage";
import { projectSubEventFactory } from "../factories/projectSubEventFactory";
import { subEventFactory } from "../factories/subEventFactory";

export interface EventSubEventsOverviewCardProps {
    intl: InjectedIntl;
    form: FormInstance;
    isNotEditable?: boolean;
    hidden?: boolean;
    eventId: string;
    errors: SubEventErrors[];
    editContent(index: number): void;
}

export const EventSubEventsOverviewCard: React.FC<EventSubEventsOverviewCardProps> = (props: EventSubEventsOverviewCardProps) => {
    const { intl, form, hidden, errors, editContent, eventId, isNotEditable } = props;

    const getProjects = () => {
        const projects = form.getFieldValue("projects");

        if (!projects || !projects.length) {
            return [];
        }

        return projects;
    };

    const addSubEventToProjects = (subEventId: string) => {
        const projects = getProjects();

        projects.forEach((project, index) => {
            const projectSubEvent = projectSubEventFactory({ id: subEventId });

            form.setFields([{
                name: ["projects", index, "subEvents"],
                value: [
                    ...(project.subEvents || []),
                    projectSubEvent
                ]
            }]);
        });
    };

    const removeSubEventFromProjects = (subEventId: string) => {
        const projects = getProjects();

        projects.forEach((project, index) => {
            const leftoverSubEvents = project.subEvents
                ? project.subEvents.filter((subEvent) => subEvent.id !== subEventId)
                : [];

            form.setFields([{
                name: ["projects", index, "subEvents"],
                value: leftoverSubEvents
            }]);
        });
    };

    const onAddClick = (add: (defaultValues?: any) => void) => {
        const subEvent = subEventFactory(form);
        addSubEventToProjects(subEvent.id);
        add(subEvent);
    };

    const onRemoveClick = (remove: (index: number | number[]) => void, index: number | number[]) => {
        const subEvent = form.getFieldValue(["subEvents", index as number]);
        removeSubEventFromProjects(subEvent.id);
        remove(index);
    };

    return (
        <Card hidden={hidden}>
            <Form.List name="subEvents">
                {(fields, { add, remove, move }) => {
                    const subEvents = form.getFieldValue("subEvents");
                    const translatedSubEvents = translateSubEvents(subEvents, eventId, errors);

                    return (
                        <React.Fragment>
                            <ContentHeader
                                rightFields={[
                                    <Button
                                        key="button1"
                                        disabled={isNotEditable}
                                        type="primary"
                                        onClick={() => onAddClick(add)}
                                    >
                                        <FormattedMessage id="event.addSubEvent" />
                                    </Button>
                                ]}
                                title={(
                                    <FormattedMessage
                                        id="event.subEventsAmount"
                                        tagName="h1"
                                        values={{ subEventsAmount: translatedSubEvents.length }}
                                    />
                                )}
                            />
                            <SubEventsTable
                                dataSource={translatedSubEvents}
                                deleteContent={(index) => onRemoveClick(remove, index)}
                                editContent={editContent}
                                isNotEditable={isNotEditable}
                                moveSubEvent={move}
                            />
                        </React.Fragment>
                    );
                }}
            </Form.List>
            <Form.Item
                dependencies={["subEvents"]}
                name="subEvents"
                rules={[{
                    validator: (rule, value, callback) => {
                        if (value?.length) {
                            return callback();
                        }
                        return callback(intl.formatMessage({ id: "form.isrequired" }));
                    }
                }]}
            />
        </Card>
    );
};

export interface EventSubEventFormValue {
    id: string;
    sequence: number;
    title?: LanguageObject;
    date: Moment;
    endDate?: Moment | null;
    info: string;
    chronoraceSubEventId?: number | null;
}

export interface EventSubEventFormValues {
    subEvents: EventSubEventFormValue[];
}

export interface ProjectSubEvent {
    id: string;
    subscriptionEnabled: boolean;
    maxParticipants: number;
    participationFull: boolean;
    participantOffset: number;
    maxParticipantsDisabled: boolean;
}

export interface EventProjectsFormValue {
    projectId: string;
    description?: LanguageObject;
    subscriptionEnabled: boolean;
    formSubscriptionEnabled: boolean;
    formTemplates: string[];
    roles: string[];
    tags: string[];
    participants: number;
    participantsOnWaitingList: number;
    viewParticipants: boolean;
    viewParticipantCount: boolean;
    waitingListEnabled: boolean;
    reminders: string[];
    subEvents: ProjectSubEvent[];
    featured: boolean;
}

export interface EventProjectsFormValues {
    projects: EventProjectsFormValue[];
}
