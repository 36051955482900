import { FlowStepTypes } from "@graphql2/types";
import { LanguageObject } from "@utils/toLanguageObject";
import moment, { Moment } from "moment";

export interface StepFormValues {
    id: number;
    title: LanguageObject;
    description: LanguageObject;
    icon: string;
    type: FlowStepTypes;
    typeIds: string[];
}

export interface FlowFormValues {
    title: LanguageObject;
    start: Moment;
    end: Moment;
    id: string;
    reminderDaysDelay?: number;
    steps: StepFormValues[];
}

export const getEmptyFlow = (): FlowFormValues => ({
    id: "",
    title: {},
    start: moment(),
    end: moment().add(1, "M"),
    steps: []
});
