import { isArray, isObject, omitBy, isNil } from "lodash";

export const deepCleanEmptyValues = (obj: unknown) => {
    if (isArray(obj)) {
        return obj.filter((v) => !isNil(v)).map(deepCleanEmptyValues);
    }
    if (isObject(obj)) {
        const newObj = omitBy(obj, isNil);
        Object.keys(newObj).forEach((key) => {
            newObj[key] = deepCleanEmptyValues(newObj[key]);
        });
        return newObj;
    }
    return obj;
};
