import { TimelinePreviewStyle } from "@components/timelinePreview/timelinePreviewStyle";
import { Card } from "antd";
import styled from "styled-components";

export const TimelineIntroPreviewStyles = styled.div`
    ${TimelinePreviewStyle} {
        width: 150px;
        height: 90px;
        margin-left: 2em;
        padding-bottom: 0;
    }
    .ant-badge-status-dot.ant-badge-status-default{
        background-color: #f6f5f6;
        margin-left: 2em;
    }
    .ant-badge:first-of-type .ant-badge-status-dot.ant-badge-status-default {
        background-color: #dae3f6;
    }
`;

export const EmptyTimelineHeaderSettingsCard = styled(Card)`
    &.ant-card {
        cursor: pointer;
        height: 143px;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4em;
        margin-left: 0;
        margin-right: 1.5em;
    }
`;

export interface TimelineHeaderSettingsCardProps {
    align: string;
}

export const TimelineHeaderSettingsCard = styled(Card)<TimelineHeaderSettingsCardProps>`
    &.ant-card {
        height: 143px;
        text-align: ${props => props.align || "center"};
        margin-left: 0;
        margin-right: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ant-card-body {
            padding-bottom: 1em;
            padding-right: ${props => (props.align === "left" ? "1em" : 0)};
        }
        .ant-card-actions > li {
            width: auto !important;
            margin: 12px 1em;
        }
    }
    .ant-card-actions > li:not(:last-child) {
        border: none;
    }
    .anticon svg, img {
        width: 2em;
        height: 2em;
        margin: 1em;
    }
`;

export const TimelineBodySettingStyles = styled.div`
    width: 30%;
    padding-right: 1em;
    .ant-card {
        margin-left: 0;
        margin-right: 0;
        padding: 1.5em;
    }
`;

export const TimelineBodyPreviewStyles = styled.div`
    width: 70%;
    padding-left: 1em;
`;
