import * as React from "react";
import { compose } from "redux";
import { AddTranslationProps, UpdateTranslationProps, withAddTranslation, withUpdateTranslation } from "../../../graphql2/types";
import { ImportTranslations } from "./importTranslations";

export interface ImportTranslationsContainerProps {
    onSubmit(keys: string[]): void;
}

export const withAddTranslationMutation = withAddTranslation({
    name: "addTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const withUpdateTranslationMutation = withUpdateTranslation({
    name: "updateTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const ImportTranslationsContainer = compose<React.ComponentClass<ImportTranslationsContainerProps>>(
    withAddTranslationMutation,
    withUpdateTranslationMutation
)(ImportTranslations);

export type ImportTranslationsProps =
    ImportTranslationsContainerProps
    & AddTranslationProps<{}, "addTranslation">
    & UpdateTranslationProps<{}, "updateTranslation">;
