import {
    ChallengeParticipant,
    useGetChallengeParticipantsLazyQuery
} from "@graphql2/types";
import { toLanguageObject } from "@utils/toLanguageObject";
import moment from "moment";
import * as React from "react";
import { ExportToCsvButton } from "./exportToCsvButton";

interface ExportChallengeParticipantsToCsvButtonProps {
  challengeId: string;
}

const ExportChallengeParticipantsToCsvButton: React.FC<
  ExportChallengeParticipantsToCsvButtonProps
> = (props) => {
    const { challengeId } = props;

    const [getParticipants, { loading, data }] = useGetChallengeParticipantsLazyQuery({
        variables: {
            filter: {
                challengeIds: challengeId ? [challengeId] : []
            },
            sort: {
                field: "id",
                order: "ASC"
            }
        }
    });

    // eslint-disable-next-line complexity
    const mapEntryToCsvRow = (participant: ChallengeParticipant) => {
        let userId = participant?.user?.id;
        if (!userId) {
            userId = "";
        } else {
            userId = userId.length !== 24 ? "deleted_user" : userId;
        }

        const {
            givenName,
            familyName,
            email,
            locale,
            locked,
            avatar,
            lastLogin,
            registeredOn
        } = participant?.user;

        const { birthDate, sex, nationality, location } = participant?.user?.profile;

        const companyName = participant?.user?.company?.name ?? "";
        const { createdOn, progress: userProgress } = participant;
        const userProgressRounded = userProgress === undefined || userProgress === null
            ? ""
            : Math.round(userProgress);

        const teamId = participant?.team?.id;
        const teamName = participant?.team?.title;
        const teamProgress = participant?.team?.progress;
        const teamProgressRounded = teamProgress === undefined || teamProgress === null
            ? ""
            : Math.round(teamProgress);

        const challengeTitles = toLanguageObject(participant?.challenge?.title);
        const challengeTitle = challengeTitles.en ?? challengeTitles.nl;

        const { answer1, answer2 } = participant?.tieBreakerAnswers ?? {};

        return [
            userId,
            email ?? "",
            givenName ?? "",
            familyName ?? "",
            birthDate ? moment(birthDate).toISOString() : "",
            sex ?? "",
            nationality ?? "",
            location ?? "",
            locale ?? "",
            locked ?? "",
            avatar ? `"${avatar}"` : "",
            lastLogin ? moment(lastLogin).toISOString() : "",
            registeredOn ? moment(registeredOn).toISOString() : "",
            createdOn ? moment(createdOn).toISOString() : "",
            companyName ?? "",
            teamId ?? "",
            teamName ?? "",
            challengeTitle ?? "",
            userProgressRounded,
            teamProgressRounded,
            answer1 ?? "",
            answer2 ?? ""
        ].join(";");
    };

    return (
        <ExportToCsvButton
            columnNames={[
                "id",
                "email",
                "name",
                "last name",
                "birthDate",
                "sex",
                "nationality",
                "location",
                "locale",
                "locked",
                "avatar",
                "last login",
                "registered on",
                "participated on",
                "company name",
                "team id",
                "team name",
                "challenge title",
                "user progress",
                "team progress",
                "answer1",
                "answer2"
            ]}
            dataToDownload={data?.challengeParticipants}
            downloadedElementId={challengeId}
            exportFileName="challenge_participants"
            lazyDataQuery={getParticipants}
            loading={loading}
            mapEntryToCsvRow={mapEntryToCsvRow}
        />
    );
};

export default ExportChallengeParticipantsToCsvButton;
