import * as React from "react";
import { getDataFromProperties } from "./getDataFromProperties";

//  Updates form values based on formValuesMapper (function) and property of data to use
export const createWithUpdateFormValues = (formValuesMapper: (item) => any, property: string[]) => {
    return (Component) => {
        return (props) => {
            updateFormValues(props, formValuesMapper, property);
            return <Component {...props} />
        }
    }
}

export const updateFormValues = (props, formValuesMapper: (item) => any, property: string[]) => {
    let data = getDataFromProperties(props, property);

    if (props.updateFormValues && data && props.match.params.id && Array.isArray(data)) {
        const item = data.find(item => item.id === props.match.params.id);

        if (item) {
            props.updateFormValues(formValuesMapper(item), item.id);
        }
    } else if (props.updateFormValues && data && data.id) {
        props.updateFormValues(formValuesMapper(data), data.id);
    }
}