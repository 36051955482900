import { EventFragment } from "@graphql2/types";
import uuidv4 from "uuid/v4";

export const modifyEventsIds = (inputEvent: EventFragment, projectId: string) => {
    if (!inputEvent.subEvents || !inputEvent.subEvents.length) {
        return inputEvent;
    }

    const { subEvents, projects, ...remainingProps } = inputEvent;
    const newIdsMap = new Map();

    const updatedSubEvents = subEvents?.map((subEvent, index) => {
        const newId = uuidv4();
        newIdsMap.set(index, newId);

        return {
            ...subEvent,
            id: newId
        };
    });

    const updatedProjects = projects?.map(project => {
        const subEventsWithNewIds = project?.subEvents?.map(((subEvent, index) => ({
            ...subEvent,
            id: newIdsMap.get(index)
        })));

        return {
            ...project,
            subEvents: subEventsWithNewIds,
            projectId
        };
    });

    return { ...remainingProps, projects: updatedProjects, subEvents: updatedSubEvents };
};
