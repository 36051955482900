import { ChallengeTeamPage } from "@pages/challengeTeamPage/challengeTeamPage";
import { filterQueryState, FilterQueryStateProps } from "@utils/withFilterQueryState";
import { defaultSortAndPagingSettings, FilterSortPagingQuery } from "@utils/withPagingAndSort";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";
import {
    GetChallengesBasicProps,
    GetChallengeTeamCountProps,
    GetChallengeTeamsProps,
    withGetChallengesBasic,
    withGetChallengeTeamCount,
    withGetChallengeTeams
} from "../../graphql2/types";

export const withFilterQueryState = filterQueryState<FilterSortPagingQuery>(
    { pageSize: defaultSortAndPagingSettings.paging.limit }
);

export const withChallenges = withGetChallengesBasic({
    name: "challenges",
    options(props: ChallengeTeamPageProps) {
        return {
            variables: {
                filter: {
                    ids: props.match.params.id ? [props.match.params.id] : []
                }
            }
        };
    }
});

export const withChallengeTeams = withGetChallengeTeams({
    name: "challengeTeams",
    options: ({ match, filterQuery }) => {
        return {
            variables: {
                filter: {
                    challengeId: match.params.id,
                    ids: match.params.teamId ? [match.params.teamId] : undefined
                },
                paging: {
                    limit: filterQuery.pageSize,
                    skip: filterQuery.skip
                }
            },
            fetchPolicy: "network-only"
        };
    }
});

export const withChallengeTeamCount = withGetChallengeTeamCount({
    name: "challengeTeamCount",
    options(props: ChallengeTeamPageProps) {
        return {
            variables: {
                filter: {
                    challengeId: props.match.params.id
                }
            },
            fetchPolicy: "network-only"
        };
    }
});

export const ChallengeTeamPageContainer = compose<React.FC<ChallengeTeamPageProps>>(
    withFilterQueryState,
    withChallengeTeams,
    withChallengeTeamCount,
    withChallenges
)(ChallengeTeamPage);

export type ChallengeTeamPageProps =
    GetChallengeTeamsProps<{}, "challengeTeams"> &
    GetChallengeTeamCountProps<{}, "challengeTeamCount"> &
    GetChallengesBasicProps<{}, "challenges"> &
    RouteComponentProps<{ id: string; }> &
    FilterQueryStateProps<FilterSortPagingQuery>;
