import { LanguageObject } from "@utils/toLanguageObject";
import * as environment from "environment";
import { TextByLanguage as TextByLanguage2 } from "../graphql2/types";

export function getText(translations?: LanguageObject | (Partial<TextByLanguage2> | null)[] | null, chosenLanguage?: string): string {
    if (!translations) {
        return "";
    }

    let value = "";

    if (typeof translations === "string") {
        return translations;
    }

    if (!Array.isArray(translations)) {
        if (chosenLanguage) {
            value = translations[chosenLanguage];
        }

        if (!value) {
            value = translations.en || translations.nl || translations.fr || translations[Object.keys(translations)[0]];
        }

        return value;
    }

    let translation: TextByLanguage2 | Partial<TextByLanguage2> | null | undefined;

    if (chosenLanguage) {
        translation = translations.find(t => t && t.language === chosenLanguage);

        return translation ? translation.text || "" : "";
    }

    translation = translations.find(t => t && t.language === environment.defaultLanguage);

    if (translation && translation.text) {
        value = translation.text;
    }

    if (!translation && translations[0]) {
        value = translations[0].text || "";
    }

    return value;
}
