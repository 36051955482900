import styled from "styled-components";

export const AddAmbassadorConfigPageStyle = styled.div`
    .ant-row + .ant-row {
        margin-left: 0 !important;
    }
`;

export const SequenceWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
