import { SelectField } from "@components/field/selectField";
import { Role } from "@graphql2/types";
import { Checkbox, Form, Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SharedProps } from "../../general/sections/challengeBasicSettingsCard";
import { CardSubtitle } from "../challengeAdvancedTabStyle";

interface AccessibilitySettingsProps extends SharedProps {
    roles: ({
        __typename?: "Role" | undefined;
    } & Pick<Role, "id" | "description" | "name">)[] | undefined;
}

export const AccessibilitySettings = ({ roles, ...sharedProps }: AccessibilitySettingsProps) => {
    return (
        <React.Fragment>
            <CardSubtitle><FormattedMessage id="accessibility" /></CardSubtitle>
            <SelectField
                filterOption={(input, option) => Object.values(option ?? []).some(el => el.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                id="roles"
                label={<FormattedMessage id="roles" />}
                mode="multiple"
                name="roles"
                placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                {...sharedProps}
            >
                {roles && roles.map(role => (
                    <Select.Option
                        key={role.id}
                        value={role.id}
                    >
                        {role.name}
                    </Select.Option>
                ))}
            </SelectField>
            <Form.Item
                name="featured"
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="challenge.featuredChallenge" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="viewParticipants"
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="challenge.viewParticipants" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="viewParticipantCount"
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="challenge.viewParticipantCount" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="viewCompletedParticipants"
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="challenge.viewCompletedParticipants" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="viewTeamCount"
                valuePropName="checked"
            >
                <Checkbox
                    {...sharedProps}
                >
                    <FormattedMessage id="challenge.viewTeamCount" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="disableComments"
                valuePropName="checked"
            >
                <Checkbox {...sharedProps}>
                    <FormattedMessage id="disableComments" />
                </Checkbox>
            </Form.Item>

        </React.Fragment>
    );
};
