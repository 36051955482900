import * as moment from "moment";

export const emptyGallery = () => ({
    title: {},
    preview: {},
    description: {},
    imageUrl: "",
    isDraft: false,
    publishOn: moment(),
    expiresOn: moment(),
    roles: undefined,
    tags: undefined,
    type: "generic",
    featured: false,
    images: []
});
