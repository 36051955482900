import { DashboardConfigWidgetEvent, DashboardConfigWidgetEventInput } from "@graphql2/types";
import { BaseWidgetsFormValues } from "@pages/addDashboardPage/addDashboardPage";
import { Modify } from "@utils/modify";
import { OmitBreakpoint } from "@utils/omitBreakpoint";
import { LanguageObject, toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { ViewType } from "@components/dashboard/dashboardWidgetConfigurator/dashboardWidgetConfigurator";

export type DashboardConfigWidgetEventFormValues = OmitBreakpoint<BaseWidgetsFormValues & Modify<DashboardConfigWidgetEvent, {
    title: LanguageObject;
    mode: string;
}>>;

export const mapEventStateWidgetToEventWidget = ({ mode, ...widget }: DashboardConfigWidgetEventFormValues): DashboardConfigWidgetEventInput => {
    return {
        ...widget,
        title: toTextByLanguage(widget.title)
    };
};

export const mapEventWidgetToEventStateWidget = (eventWidget: DashboardConfigWidgetEvent): DashboardConfigWidgetEventFormValues => {
    return {
        ...eventWidget,
        mode: (eventWidget.eventIds && eventWidget.eventIds.length > 0)
            ? "fixed"
            : "dynamic",
        type: eventWidget.type as ViewType,
        title: toLanguageObject(eventWidget.title)
    };
};
