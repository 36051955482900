import { useAuth } from "@context/authContext/context";
import React from "react";
import { useHasRole } from "./useHasRole";

export interface WithAuthContextProps {
    getProjectId: () => string;
    hasRole: (role: string | undefined) => boolean;
}

export function withAuthContext<T>(Component: React.FC<T & WithAuthContextProps>): React.FC<T> {
    function ComponentWithAuthContextProp(props) {
        const { projectId } = useAuth();
        const hasRole = useHasRole();

        return (
            <Component
                getProjectId={() => projectId}
                hasRole={hasRole}
                {...props}
            />
        );
    }

    return ComponentWithAuthContextProp;
}
