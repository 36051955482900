/* eslint-disable max-lines-per-function */
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { VisualisationdCardstyle, VisualisationdGridStyle } from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisationStyle";
import { VisualisationTypeForm } from "@pages/addChallengesPage/tabs/visualisations/visualisationTypeForm/visualisationTypeForm";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { LanguageObject } from "@utils/toLanguageObject";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { ConditionsLeaderboardVisualisationFields } from "./conditionsLeaderboardVisualisationFields";
import { GlobalLeaderboardVisualisationFields } from "./globalLeaderboardVisualisationFields";

export interface ChallengeLeaderboardVisualisationItemTop {
    enabled?: boolean;
    count: number;
}

export interface ChallengeLeaderboardVisualisationItem {
    top: ChallengeLeaderboardVisualisationItemTop;
    unit: string;
}

export interface ChallengeLeaderboardVisualisationColumn {
    header: LanguageObject;
    profileProperty: string;
    filterEnabled: boolean;
}

export interface ChallengeLeaderboardVisualisationFormValues {
    id?: string;
    type?: string;
    conditionId?: string;
    place?: string | null;
    dataType?: string | null;
    clickable?: boolean | null;
    isGlobal?: boolean;
    item?: ChallengeLeaderboardVisualisationItem;
    availableUnitTypeRankings?: string[];
    title?: LanguageObject;
    description?: LanguageObject;
    numberOfItems: number;
    numberOfItemsEnabled?: boolean;
    globalSettings?: {
        title: LanguageObject;
        description: LanguageObject;
    };
    conditions: {
        id?: string | null;
        conditionId: string;
        title: LanguageObject;
        columnNames?: string[];
        enableFilters?: boolean;
        columns?: {
            header: LanguageObject;
            profileProperty: string;
            filterEnabled: boolean;
        }[];
    }[];
}

export interface EditChallengeLeaderboardVisualisationCardProps {
    form: FormInstance;
    conditions?: ChallengeConditionFormValuesUnion[];
    index: number;
    hidden: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    errors?: ErrorDictionary;
    back(): void;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

export const EditChallengeLeaderboardVisualisationCard: React.FC<EditChallengeLeaderboardVisualisationCardProps> = (props) => {
    const { hidden, form, onBlur, conditions, back, index, activeLanguage, changeSelectedLanguage, languageErrors, errors } = props;
    const prefix = ["visualisations", index];
    const visualisation = form.getFieldValue(prefix);

    const [isGlobal, setIsGlobal] = React.useState<boolean>(visualisation.isGlobal);

    const isTeamChallenge = form.getFieldValue(["team", "enabled"]);
    const visualisations = form.getFieldValue("visualisations");

    const onChangeData = () => { // triggers a re-render on change conditionId
        const fetchedVisualisation = form.getFieldValue(prefix);
        setIsGlobal(fetchedVisualisation.isGlobal);
        onBlur();
    };

    return (
        <VisualisationdCardstyle hidden={hidden}>
            <VisualisationdGridStyle>
                <VisualisationTypeForm
                    noHiddenId
                    back={back}
                    conditions={conditions}
                    existingVisualisations={visualisations}
                    form={form}
                    index={index}
                    isTeamChallenge={isTeamChallenge}
                    prefix={prefix}
                    visualisation={visualisation}
                    onChange={onChangeData}
                />
            </VisualisationdGridStyle>

            {isGlobal
                ? (
                    <GlobalLeaderboardVisualisationFields
                        activeLanguage={activeLanguage}
                        changeSelectedLanguage={changeSelectedLanguage}
                        form={form}
                        index={index}
                        languageErrors={errors && errors[index]?.conditions[0]}
                        onChange={onBlur}
                    />
                )
                : (
                    <ConditionsLeaderboardVisualisationFields
                        activeLanguage={activeLanguage}
                        changeSelectedLanguage={changeSelectedLanguage}
                        errors={errors && errors[index]?.conditions}
                        form={form}
                        index={index}
                        languageErrors={languageErrors}
                        onChange={onBlur}
                    />
                )}
        </VisualisationdCardstyle>
    );
};
