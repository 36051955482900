import { Menu } from "antd";
import { debug } from "environment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export type NavGroupItemProps = React.PropsWithChildren<{
    roles: boolean[];
    translation: string;
    icon: JSX.Element;
    experimental?: boolean;
}>;

export const NavGroupItem: React.FC<NavGroupItemProps> = (props) => {
    // subMenuProps are required by Ant Design
    const { roles, translation, icon, experimental, ...subMenuProps } = props;

    if (experimental && !debug) {
        return null;
    }

    if (roles.indexOf(true) === -1) {
        return null;
    }

    return (
        <Menu.SubMenu
            {...subMenuProps}
            icon={icon}
            title={<FormattedMessage id={translation} />}
        />
    );
};
