/* eslint-disable react/jsx-closing-tag-location */
import { ChallengeMilestonesTable, MilestoneEmailTestProps } from "@components/challengeMilestonesTable/challengeMilestonesTable";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { milestoneFactory } from "@pages/addChallengesPage/factories";
import { ChallengeConditionsFormValues } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { LanguageObject } from "@utils/toLanguageObject";
import { Button, Card, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface MilestoneEmailFormValues {
    subject: LanguageObject;
    body: LanguageObject;
}

export interface MilestoneFormValues {
    id: string;
    percentage: number;
    conditionId?: string;
    title: LanguageObject;
    description: LanguageObject;
    imageUrl: string;
    mailEnabled: boolean;
    notificationsEnabled: boolean;
    email?: MilestoneEmailFormValues;
}

export interface ChallengeMilestonesFormValues {
    milestones?: MilestoneFormValues[];
}

export interface ChallengeMilestonesCardProps {
    hidden?: boolean;
    defaultValues: Partial<ChallengeMilestonesFormValues & ChallengeConditionsFormValues>;
    form: FormInstance;
    errors?: ErrorDictionary;
    editMilestone(index: number): void;
    onBlur(): void;
    testMilestoneEmailProps: MilestoneEmailTestProps;
}

export const ChallengeMilestonesCard: React.FC<ChallengeMilestonesCardProps> = (props) => {
    const { form, hidden, defaultValues, editMilestone, onBlur, errors, testMilestoneEmailProps } = props;

    const sharedProps = {
        onBlur
    };

    return (
        <Card hidden={hidden}>
            <Form.List name="milestones">
                {(fields, { add, remove }) => {
                    const fetchedMilestones = form.getFieldValue("milestones");
                    const translatedMilestones = (fetchedMilestones || []).map((m, i) => ({
                        index: i, // index generated by table is paginated so will not give correct results past first page
                        percentageForCondition: m.percentage ? `${m.percentage}%` : "0%",
                        id: m.id,
                        condition: (defaultValues.conditions || []).find(c => c.id === m.conditionId),
                        hasError: hasError(errors, [i])
                    }));

                    return (
                        <React.Fragment>
                            <ContentHeader
                                rightFields={[<Button
                                    key="addbutton"
                                    onClick={() => {
                                        add(milestoneFactory()); onBlur();
                                    }}
                                >
                                    <FormattedMessage id="addMilestone" />
                                </Button>]}
                                title={<FormattedMessage id="conditionMilestones" />}
                            />
                            <ChallengeMilestonesTable
                                dataSource={translatedMilestones}
                                deleteMilestone={remove}
                                editMilestone={editMilestone}
                                testMilestoneEmailProps={testMilestoneEmailProps}
                                {...sharedProps}
                            />
                        </React.Fragment>
                    );
                }}
            </Form.List>
        </Card>
    );
};
