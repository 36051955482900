import styled from "styled-components";
import { Col } from "antd";

export const CompaniesPageStyle = styled.div`

`;

export const ColStyled = styled(Col)`
    margin-bottom: 1rem;
`;
