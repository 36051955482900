/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { List, Tabs, Empty } from "antd";
import { LanguageObject } from "@utils/toLanguageObject";
import { getText } from "@utils/getText";
import { FormattedMessage } from "react-intl";
import { ExampleLeaderboardStyle } from "./exampleLeaderboardStyle";

const dummyData = [
    {
        name: "Jaylon Rhiel Madsen",
        progress: 113
    },
    {
        name: "Abram Lubin",
        progress: 100
    },
    {
        name: "Ashlynn Culhane",
        progress: 95
    },
    {
        name: "Lincoln Workman",
        progress: 70
    },
    {
        position: 17,
        name: "You",
        progress: 45
    }
];

interface ExampleLeaderboardProps {
    tabs?: LanguageObject[];
}

export const ExampleLeaderboard: React.FC<ExampleLeaderboardProps> = (props) => {
    const { tabs } = props;

    const renderList = () => {
        return (
            <List
                bordered
                dataSource={dummyData}
                renderItem={(item, index) => (
                    <List.Item>
                        <div className="listContent">
                            <span>
                                {item.position || index + 1}
                                {" "}
                                {item.name}
                            </span>
                            <span>
                                {item.progress}
                                {" "}
km
                            </span>
                        </div>
                    </List.Item>
                )}
            />
        );
    };

    return (
        <ExampleLeaderboardStyle>
            {tabs && tabs.length
                ? (
                    <Tabs
                        defaultActiveKey={String(0)}
                        size="small"
                        type="card"
                    >
                        {tabs.map((c, i) => (
                            <Tabs.TabPane
                                key={i}
                                tab={getText(c)}
                            >
                                {renderList()}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                )
                : (
                    <Empty
                        description={<FormattedMessage id="leaderboard.livedata.empty" />}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
        </ExampleLeaderboardStyle>
    );
};
