import { PlusCircleOutlined } from "@ant-design/icons";
import { JSONData } from "@energylab/schematics";
import { InjectedIntl } from "@utils/override.types";
import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import React from "react";
import { FieldName } from "../../fieldsMapper";
import { EmptyField } from "../../styles";
import { InlineWrapper } from "../inlineWrapper";
import { ListField } from "./listField";
import { SortableListField } from "./sortableListField";

export type ListFieldWrapperProps = {
    disable: boolean;
    disableTitle: boolean;
    displayName: string;
    form: FormInstance;
    intl: InjectedIntl;
    jsonData: JSONData;
    name: FieldName;
    namePath: (string | number)[];
    fieldName: string | number;
    fieldNamePath?: FieldName;
}

export const ListFieldWrapper: React.FC<ListFieldWrapperProps> = (props) => {
    const { disable, disableTitle, displayName, form, intl, jsonData, name, namePath, fieldName, fieldNamePath } = props;

    const renderListItems = (fields: FormListFieldData[], operation: FormListOperation): React.ReactNode => {
        if (jsonData.isSortable) {
            return (
                <SortableListField
                    fieldOptions={props}
                    fields={fields}
                    operation={operation}
                />
            );
        }

        return (
            <ListField
                fieldOptions={props}
                fields={fields}
                operation={operation}
            />
        );
    };

    return (
        <InlineWrapper
            description={jsonData.description}
            disableTitle={disableTitle}
            name={displayName}
        >
            {disable
                ? (
                    <PlusCircleOutlined
                        disabled={disable}
                        style={{
                            opacity: 0.5,
                            padding: 10,
                            fontSize: 25
                        }}
                    />
                ) : (
                    <Col style={{ margin: 0 }}>
                        <Row
                            align="middle"
                            style={{ margin: 0 }}
                        >
                            <Form.List
                                name={name}
                            >
                                {renderListItems}
                            </Form.List>
                        </Row>
                        <EmptyField
                            dependencies={[[...namePath, fieldName]]}
                            initialValue={[]}
                            name={fieldNamePath}
                            rules={[
                                {
                                    validator: (rule, value, callback) => {
                                        const length = form.getFieldValue([...namePath, fieldName])?.length ?? 0;
                                        if (!jsonData.minItems || jsonData.minItems <= length) {
                                            return callback();
                                        }
                                        return callback(intl.formatMessage({ id: "array.minLength" }, { number: jsonData.minItems }));
                                    }
                                }
                            ]}
                        />
                    </Col>
                )}
        </InlineWrapper>
    );
};
