import { SpinStyle } from "@components/style/globalStyles";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyEventGroup } from "@utils/emptyItems/emptyEventGroup";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { EventGroupFormValues, eventGroupToFormValues, formValuesToEventGroup } from "@utils/mappers/eventGroupMapper";
import { Form, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AddEventGroupPageProps } from "./addEventGroupPageContainer";
import { GeneralSettingsCard } from "./pageContent/generalSettingsCard";
import { EventGroupPageHeader } from "./pageContent/header";
import { TitleCard } from "./pageContent/titleCard";

export const AddEventGroupPage: React.FC<AddEventGroupPageProps> = (props) => {
    const { eventTitles, eventGroups, addEventGroup, updateEventGroup, removeEventGroup, history } = props;
    const intl = useIntl();
    const [form] = useForm();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [isSaving, setIsSaving] = React.useState(false);
    const [enabledSave, setEnabledSave] = React.useState<boolean>();
    const [errors, setErrors] = useErrors();

    const eventId = props.match.params.id;
    const eventGroup: EventGroupFormValues = createDefaultValues(eventGroupToFormValues, emptyEventGroup, undefined, eventGroups?.loading, eventGroups?.eventGroups?.[0]);

    const removeEventGroupCb = async () => {
        await removeEventGroup({ variables: { id: eventId || "" } });
    };

    const handleFailedForm = (err: unknown) => {
        setErrors(err);
        message.error(intl.formatMessage({ id: "error.checkHighlightedFields" }));
    };

    const submitForm = async (values: EventGroupFormValues) => {
        setIsSaving(true);
        const transformedValues = () => (eventId
            ? updateEventGroup({ variables: { ...formValuesToEventGroup(values), id: eventId } })
            : addEventGroup({ variables: { ...formValuesToEventGroup(values) } }));

        const errMsg = eventId ? "eventsGroup.groupUpdateFailed" : "eventsGroup.groupAddFailed";
        const updateMsg = eventId ? "eventsGroup.groupUpdated" : "eventsGroup.groupAdded";
        const url = (response) => (eventId ? "/event/groups" : `/event/groups/${response.id}`);

        try {
            const response = await transformedValues();
            handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                history.push(url(response));
            });
        } catch (err) {
            setErrors(err);
            handleError(intl.formatMessage({ id: errMsg }));
        } finally {
            setEnabledSave(false);
            setIsSaving(false);
        }
    };

    const defaultProps = {
        changeSelectedLanguage,
        onChange: () => {
            if (enabledSave) {
                return;
            }
            setEnabledSave(true);
            if (errors) {
                setErrors(undefined);
            }
        }
    };

    if (eventTitles.loading || eventGroups?.loading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if (eventTitles.error || eventGroups?.error) {
        props.history.push("/404");
    }

    return (
        <Form
            form={form}
            initialValues={eventGroup}
            onFinish={submitForm}
            onFinishFailed={handleFailedForm}
        >
            <EventGroupPageHeader
                activeLanguage={selectedLanguage}
                enabledSave={Boolean(enabledSave)}
                groupTitle={eventGroup.title}
                isSaving={isSaving}
                removeEventCb={eventId ? removeEventGroupCb : undefined}
            />
            <TitleCard
                {...defaultProps}
                activeLanguage={selectedLanguage}
                languageErrors={errorsToLanguageErrors({
                    title: errors.title
                })}
            />
            <GeneralSettingsCard
                {...defaultProps}
                eventTitles={eventTitles.events}
            />
        </Form>
    );
};
