import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { Flex } from "@components/flex/flex";
import { ChallengeVisualisationFont, FontForm } from "@components/fontForm/fontForm";
import { FormModal } from "@components/modal/modal";
import { storeImage } from "@utils/storeImage";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTIVITY_TYPES, UNIT_TYPES } from "../../../../../../constants";
import { Label } from "../../editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisationStyle";

export interface ChallengeTimelineVisualisationCounter {
    image: string;
    font?: ChallengeVisualisationFont;
    unitType: string;
    activityTypes: string[];
}

export interface EditChallengeTimelineCounterProps {
    hidden: boolean;
    counter: ChallengeTimelineVisualisationCounter;
    form: FormInstance;
    visualisationIndex: number;
    counterIndex: number;
    close(): void;
    onBlur(): void;
}

export const EditChallengeTimelineCounter: React.FC<EditChallengeTimelineCounterProps> = (props) => {
    const { hidden, counter, form, onBlur, counterIndex, visualisationIndex, close } = props;
    const onChangeCurry = onBlur;

    const prefix = ["visualisations", visualisationIndex, "banner", "counters", counterIndex];

    return (
        <FormModal
            cancelButtonProps={{ onClick: close }}
            hidden={hidden}
            okButtonProps={{ onClick: close }}
        >
            <div>
                <Label><FormattedMessage id="image" /></Label>
                <FileDragAndDropContainer
                    defaultImage={counter.image}
                    onChange={async (image): Promise<void> => storeImage(onChangeCurry, form, `${prefix.join(".")}.image`, image, undefined, [
                        { fields: [["visualisations"], ["banner"]], index: visualisationIndex },
                        { fields: [["counters"], ["image"]], index: counterIndex }
                    ])}
                />
                <Field
                    hidden
                    id="imageUrl"
                    name={[...prefix, "image"]}
                />
            </div>
            <FontForm
                font={counter.font}
                form={form}
                prefix={[...prefix, "font"]}
            />
            <Flex justifyContent="space-around">
                <SelectField
                    label={<FormattedMessage id="activityTypes" />}
                    mode="multiple"
                    name={[...prefix, "activityTypes"]}
                >
                    {ACTIVITY_TYPES.map((activityType, key) => (
                        <Select.Option
                            // eslint-disable-next-line react/no-array-index-key
                            key={key.toString()}
                            value={activityType.key}
                        >
                            {activityType.value}
                        </Select.Option>
                    ))}
                </SelectField>
                <SelectField
                    label={<FormattedMessage id="unitType" />}
                    name={[...prefix, "unitType"]}
                >
                    {UNIT_TYPES.map((activityType) => activityType.values.map(value => (
                        <Select.Option
                            key={value}
                            value={value}
                        >
                            {value}
                        </Select.Option>
                    )))}
                </SelectField>
            </Flex>
        </FormModal>
    );
};
