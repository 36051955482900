import { Application } from "@graphql2/types";

export const getLastHexValue = (application: Application, offset: number = 1): number => {
    if (!application.roles.length) {
        return -1;
    }

    const selectedRole = application.roles[application.roles.length - offset];

    if (!selectedRole) {
        return -1;
    }

    const lastId = application.roles[application.roles.length - offset].id;
    const lastHexValue = parseInt(lastId, 16);

    if (isNaN(lastHexValue) || lastHexValue > 255) {
        return getLastHexValue(application, offset + 1);
    }

    return lastHexValue;
};
