import { Flex } from "@components/flex/flex";
import styled from "styled-components";

export const SwitchWrapper = styled(Flex)`

    .ant-switch:not(.ant-switch-checked) {
        background-color: #CF4948;
    }
    
 `;
