/* eslint-disable react/prop-types */
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { LanguageObject } from "@utils/toLanguageObject";

import { urlValidation } from "@utils/validators/validateUrl";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TitleSubTitleDescriptionFieldsStyle as VideoFieldsStyle } from "../../widgetStyle";

export interface VideoFormValues {
    video: LanguageObject;
}

export interface VideoCardProps {
    form: FormInstance;
    languageErrors?: LanguageErrors;
    id: string;
}

const videoField = (props) => (
    <Field
        name={["widgets", props.id, "video", props.language]}
        rules={[
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            },
            { validator: urlValidation }
        ]}
        hidden={props.hidden}
        key={`video.${props.language}`}
        label={<FormattedMessage id="videoWidget.video" />}
        id={`video.${props.language}`}
        {...props.sharedProps}
    />
);

export interface VideoCardState {
    selectedLanguage: string;
    toggleMulti: boolean;
}

export const VideoCard: React.FC<VideoCardProps> = (props) => {
    const { languageErrors, id } = props;

    const [selectedLanguage, setSelectedLanguage] = React.useState(languages[0].code);

    const langFieldProps = languages.map(language => ({
        defaultValues: {},
        language: language.code,
        hidden: language.code !== selectedLanguage,
        id
    }));

    return (
        <VideoFieldsStyle>
            <TranslationTabs
                activeLanguage={selectedLanguage}
                handleChangeSelectedLanguage={setSelectedLanguage}
                languages={languages}
                errors={languageErrors}
            />
            {langFieldProps.map(videoField)}
        </VideoFieldsStyle>
    );
};
