import { DeleteOutlined, DownOutlined, DownloadOutlined, EditOutlined, UpOutlined } from "@ant-design/icons";
import { OrderIconWrapper } from "@components/challengeConditionsTable/challengeConditionsTableStyle";
import { ConditionTitle } from "@components/conditionTitle/conditionTitle";
import { Flex } from "@components/flex/flex";
import { renderErrorColumn } from "@components/table/errorColumn";
import { TableComponentProps } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ChallengeConditionsTableData {
    id: string;
    sequence?: number | undefined | null;
    condition: ChallengeConditionFormValuesUnion;
    hasError: boolean;
}

export interface ChallengeConditionsTableProps extends TableComponentProps {
    dataSource: ChallengeConditionsTableData[];
    moveCondition(from: number, to: number): void;
    editCondition(index: number): void;
    deleteCondition(index: number): void;
    onDownload(id: string): void;
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<ChallengeConditionsTableData>;
}

export const ChallengeConditionsTable: React.FC<ChallengeConditionsTableProps> = ({ onDownload, moveCondition, editCondition, deleteCondition, ...tableProps }) => {
    const renderSequence = (index: number): { children: JSX.Element; } => ({
        children: (
            <OrderIconWrapper>
                <UpOutlined onClick={() => moveCondition(index, index - 1)} />
                <DownOutlined onClick={() => moveCondition(index, index + 1)} />
            </OrderIconWrapper>
        )
    });

    const renderHandlers = (row: ChallengeConditionsTableData, index: number): Handlers => ({
        children: (
            <Flex
                justifyContent="flex-end"
                style={{ width: 50 }}
            >
                {objectTypeCheck<ChallengeFormConditionFormValues>(row.condition, "form") && (
                    <TableIcon
                        as={DownloadOutlined}
                        onClick={() => onDownload(row.id)}
                    />
                )}
                <TableIcon
                    as={EditOutlined}
                    onClick={() => editCondition(index)}
                />
                <TableIcon
                    as={DeleteOutlined}
                    onClick={() => deleteCondition(index)}
                />
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            rowKey="id"
            {...tableProps}
            pagination={false}
        >
            <Column
                key="sequence"
                dataIndex="sequence"
                render={(_text, _row, index) => renderSequence(index)}
                title={<FormattedMessage id="order" />}
                width={50}
            />
            <Column
                key="condition"
                dataIndex="condition"
                render={(condition) => (
                    <ConditionTitle condition={condition} />
                )}
                title={<FormattedMessage id="conditionTitle" />}
            />
            {renderErrorColumn()}
            <Column
                dataIndex=""
                render={(_text, row: ChallengeConditionsTableData, index) => renderHandlers(row, index)}
                title=" "
                width={90}
            />
        </Table>
    );
};
