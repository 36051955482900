import * as React from "react";
import { compose } from "redux";
import { withGetEvents, GetEventsProps } from "@graphql2/types";
import { exportFactory, ExportProps } from "./export";
import { ExportContainerProps, getByIdQuery, withLocalState } from "./exportContainer";

export const withEvent = withGetEvents(getByIdQuery);

export const ExportEventContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withEvent
)(exportFactory({ targetProp: ["data", "events"] }));

export type ExportEventContainerprops = ExportContainerProps & MaybeElements<GetEventsProps>;
