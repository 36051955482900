import { WarningOutlined } from "@ant-design/icons";
import { Button, List, Popover, Select, message } from "antd";
import * as environment from "environment";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AddTranslationMutationVariables } from "../../../graphql2/types";
import { AddTranslationsProps } from "./addTranslationsContainer";
import { AddTranslationsStyle } from "./addTranslationsStyle";

export const AddTranslations: React.FC<AddTranslationsProps> = (props) => {
    const intl = useIntl();
    const [uploadErrors, setUploadErrors] = React.useState<string[]>([]);

    const { data, keys, filterEnabled, addTranslation, onSubmit, onChangeKeys } = props;
    const onUpdateKeys = (uKeys: string[]) => {
        onChangeKeys(uKeys);
    };

    const addKeys = (translations: AddTranslationMutationVariables[]) => {
        translations.forEach(async t => {
            try {
                const result = await addTranslation({
                    variables: t
                });

                if (!result) {
                    setUploadErrors([...uploadErrors, t.key]);
                }
            } catch (e) {
                console.error(e);
                setUploadErrors([...uploadErrors, t.key]);
            }
        });
    };

    const onSubmitKeys = async () => {
        if (data && data.translations && keys) {
            const existingKeys = data.translations.map(t => t.key);
            const newKeys = keys.filter(k => !existingKeys.includes(k));

            const addKeyVariables: AddTranslationMutationVariables[] = newKeys.map(key => ({
                key: key.trim(),
                translations: environment.languages.map(l => ({
                    language: l,
                    text: `${key.trim()}(${l})`
                }))
            }));

            await addKeys(addKeyVariables);

            if (!uploadErrors.length) {
                onSubmit();
            } else {
                message.error(intl.formatMessage({ id: "somethingWentWrong" }));
            }
        }
    };

    const content = (
        <List
            bordered
            dataSource={uploadErrors}
            renderItem={item => <List.Item>{item}</List.Item>}
            size="small"
        />
    );

    return (
        <AddTranslationsStyle>
            <div className="flex">
                <FormattedMessage
                    id="addTranslationKeys.title"
                    tagName="h1"
                />
                <FormattedMessage
                    id="addTranslationKeys.disclaimer"
                    tagName="span"
                />
            </div>
            <div className="flex">
                <Select
                    disabled={filterEnabled}
                    mode="tags"
                    placeholder={<FormattedMessage id="addTranslationKeys.placeholder" />}
                    value={keys}
                    onChange={onUpdateKeys}
                />
                <Button
                    disabled={filterEnabled || !keys || !keys.length}
                    loading={data?.loading}
                    onClick={onSubmitKeys}
                >
                    <FormattedMessage id="addTranslationKeys.save" />
                </Button>
                {Boolean(uploadErrors.length) && (
                    <Popover
                        content={content}
                        title={<FormattedMessage id="addTranslation.keysFailed" />}
                    >
                        <WarningOutlined />
                    </Popover>
                )}
            </div>
        </AddTranslationsStyle>
    );
};
