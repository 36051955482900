/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { Field } from "@components/field/field";
import { PrizesCard } from "@pages/addChallengesPage/tabs/prizes/challengePrizesCard/challengePrizesCardStyle";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { Col, Row } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChallengePrizesList } from "./sections/challengePrizesList";
import { ChallengeTieBreakerSettings } from "./sections/challengeTieBreakerSettings";
import { ChallengeVoucherSettings } from "./sections/challengeVoucherSettings";

export interface ChallengePrizesCardProps {
    form: FormInstance;
    disabled?: boolean;
    hidden?: boolean;
    errors?: ErrorDictionary;
    editPrize(index: number): void;
    onBlur(): void;
}

export const ChallengePrizesCard: React.FC<ChallengePrizesCardProps> = (props) => {
    const {
        disabled,
        form,
        hidden,
        onBlur,
        errors
    } = props;

    return (
        <PrizesCard hidden={hidden}>
            <ContentHeader
                title={<FormattedMessage id="prizes" />}
            />
            <Field
                numberType
                id="creditReward"
                label={<FormattedMessage id="creditReward" />}
                min={0}
                name={["rewards", "credits"]}
                onBlur={onBlur}
            />
            <ChallengePrizesList
                editPrize={props.editPrize}
                errors={errors}
                form={form}
                onBlur={onBlur}
            />
            <Row gutter={40}>
                <Col span={12}>
                    <ChallengeTieBreakerSettings
                        errors={errors}
                        form={form}
                        onBlur={onBlur}
                    />
                </Col>
                <Col span={12}>
                    <ChallengeVoucherSettings
                        disabled={disabled}
                        form={form}
                        languageErrors={errors}
                        onBlur={onBlur}
                    />
                </Col>
            </Row>
        </PrizesCard>
    );
};
