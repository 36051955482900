import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import Column, { ColumnProps } from "antd/lib/table/Column";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

type EventTitleItem = {
    id: string;
    title: string;
};

export interface EventsGroupTableData extends TableData {
    id: string;
    title: string;
    events: EventTitleItem[]
    subscriptionsCount: number;
}

export interface EventsGroupTableProps extends TableComponentProps {
    dataSource: EventsGroupTableData[];
    handlers: TableHandlers & {
        removeEventGroup(id: string): void;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<EventsGroupTableData>;
}

export const EventsGroupTable: React.FC<EventsGroupTableProps> = ({ handlers, ...tableProps }) => {
    const renderTitle = (text: string, id: string) => (
        <Link className="plainText" to={`/event/groups/edit/${id}`}>
            {text}
        </Link>
    );

    const renderEvents = (events: EventTitleItem[]) => {
        return (
            <div>
                {events.map((event) => (
                    <Link className="plainText" key={event.id} to={`/event/events/edit/${event.id}/general`}>
                        {event.title},&nbsp;
                    </Link>
                ))}
            </div>
        );
    };

    const renderHandlers = (row: EventsGroupTableData): Handlers => ({
        children: (
            <Flex justifyContent="flex-end" alignItems="center">
                <Link to={`/event/groups/edit/${row.id}`}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm title={<FormattedMessage id="deleteConfirm" />} onConfirm={() => handlers.removeEventGroup(row.id)} okText="Yes" cancelText="No">
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    return (
        <Table
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="title"
                title={<FormattedMessage id="title" />}
                dataIndex="title"
                width={120}
                render={(text, row: EventsGroupTableData) => renderTitle(text, row.id)}
            />
            <Column
                key="events"
                title={<FormattedMessage id="events" />}
                dataIndex="events"
                render={renderEvents}
            />
            <Column
                key="subscriptionsCount"
                title={<FormattedMessage id="eventsGroup.subscriptionLimit" />}
                dataIndex="subscriptionsCount"
                width={120}
            />
            <Column
                title=" "
                key="handlers"
                dataIndex=""
                width={120}
                render={(_, row: EventsGroupTableData) => renderHandlers(row)}
            />
        </Table>
    );
};
