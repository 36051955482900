import { getVoucherCount, getVouchers } from "@graphql2/graphql_op";
import { AddVoucherProps, GetApplicationsProps, GetChallengeTitlesProps, GetVouchersProps, RemoveVoucherProps, UpdateVoucherProps, withAddVoucher, withGetApplications, withGetChallengeTitles, withGetVouchers, withRemoveVoucher, withUpdateVoucher } from "@graphql2/types";
import { AddVouchersPage } from "@pages/addVoucherPage/addVoucherPage";
import { VoucherTypes } from "@pages/addVoucherPage/cards/voucherEnabledOnCard";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { LocalStateProps, localState } from "@utils/withLocalState";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

export interface AddVoucherPageContainerProps {
}

type AddVoucherRouteProps = RouteComponentProps<{
    id: string;
}>;

export interface LocalState {
    filter: { textSearch: string; };
}

const withLocalState = localState<LocalState>({
    filter: {
        textSearch: ""
    }
});

export const withVoucher = withGetVouchers({
    name: "vouchers",
    options: ({ match: { params: { id } } }: AddVoucherRouteProps) => {
        return {
            variables: {
                filter: {
                    ids: id ? [id] : []
                }
            }
        };
    },
    skip: (props: AddVoucherRouteProps) => {
        return !props.match.params.id;
    }
});

export const withChallengesBySearch = withGetChallengeTitles({
    name: "challengeTitlesBySearch",
    options: ({ filter: { textSearch } }: AddVouchersPageProps) => {
        return {
            variables: {
                filter: {
                    textSearch
                }
            }
        };
    },
    skip: (props: AddVouchersPageProps) => {
        return !props.filter.textSearch || !props.filter.textSearch.length;
    }
});

export const withChallengesById = withGetChallengeTitles({
    name: "challengeTitlesById",
    options: ({ vouchers }: AddVouchersPageProps) => {
        const challengeEnabledOns = vouchers?.vouchers?.[0].enabledOn?.filter(enOn => enOn.type === VoucherTypes.challengeParticipation);
        const challengeIds = challengeEnabledOns?.reduce((acc, currentValue) => {
            return [...acc, ...(currentValue.ids?.filter(getOutFalseValues) || [])];
        }, []);

        return {
            variables: {
                filter: {
                    ids: challengeIds || []
                }
            }
        };
    },
    skip: (props: AddVouchersPageProps) => {
        const voucher = props.vouchers?.vouchers?.[0];
        return !voucher || !voucher.enabledOn || !voucher.enabledOn.some(enOn => enOn.type === VoucherTypes.challengeParticipation);
    }
});

const withUpdateVoucherMutation = withUpdateVoucher({
    name: "updateVoucher"
});
const withAddVoucherMutation = withAddVoucher({
    name: "addVoucher",
    options: {
        refetchQueries: [{
            query: getVoucherCount,
            variables: {
                filter: {}
            }
        }],
        awaitRefetchQueries: true,
        update: cleanCache([getVouchers])
    }
});

export const withApplications = withGetApplications({
    name: "applications"
});

const withRemoveVoucherMutation = withRemoveVoucher({
    name: "removeVoucher",
    options: {
        refetchQueries: [{
            query: getVoucherCount,
            variables: {
                filter: {}
            }
        }],
        awaitRefetchQueries: true,
        update: cleanCache([getVouchers])
    }
});

export const AddVouchersPageContainer = compose<React.ComponentClass<AddVoucherPageContainerProps>>(
    withLocalState,
    withVoucher,
    withApplications,
    withChallengesBySearch,
    withChallengesById,
    withUpdateVoucherMutation,
    withRemoveVoucherMutation,
    withAddVoucherMutation
)(AddVouchersPage);

export type AddVouchersPageProps = AddVoucherPageContainerProps
    & GetApplicationsProps<{}, "applications">
    & MaybeElements<GetVouchersProps<{}, "vouchers">>
    & MaybeElements<GetChallengeTitlesProps<{}, "challengeTitlesById">>
    & MaybeElements<GetChallengeTitlesProps<{}, "challengeTitlesBySearch">>
    & AddVoucherRouteProps
    & RemoveVoucherProps<{}, "removeVoucher">
    & UpdateVoucherProps<{}, "updateVoucher">
    & LocalStateProps<LocalState>
    & AddVoucherProps<{}, "addVoucher">;
