import { Table } from "antd";
import { MinusOutlined, CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";

import * as React from "react";
import { FormattedMessage } from "react-intl";
import Column from "antd/lib/table/Column";
import { TableComponentProps, TableData, TableHandlers } from "../table/tableContainer";

export interface ArticleVoucherTableData extends TableData {
    code: string;
    used: boolean;
    userId?: string;
    orderId?: string;
    index: number;
}

export interface ArticleVoucherTableProps extends TableComponentProps {
    dataSource: ArticleVoucherTableData[];
    variables: {
        selectedRowKeys: string[];
    }
    handlers: TableHandlers & {
        handleSelectedVouchersOnChange(vouchers): void;
    }
}

export const ArticleVoucherTable: React.FC<ArticleVoucherTableProps> = (tableProps) => {
    const renderStatus = (status) => {
        if (status) {
            return <CheckCircleFilled className="green" />;
        }

        return <CheckCircleOutlined />;
    };

    const renderUserId = (userId) => {
        if (userId) {
            return userId;
        }
        return <MinusOutlined />;
    };

    const renderOrderId = (orderId) => {
        if (orderId) {
            return orderId;
        }

        return <MinusOutlined />;
    };

    const { handlers, variables } = tableProps;

    return (
        <Table
            pagination={false}
            rowKey="id"
            rowSelection={{
                onChange: (_, selectedRows) => {
                    handlers.handleSelectedVouchersOnChange(selectedRows);
                },
                selectedRowKeys: variables.selectedRowKeys
            }}
            {...tableProps}
        >
            <Column
                key="code"
                title={<FormattedMessage id="code" />}
                dataIndex="code"
            />
            <Column
                key="claimed"
                title={<FormattedMessage id="claimed" />}
                dataIndex="used"
                render={renderStatus}
            />
            <Column
                key="userId"
                title={<FormattedMessage id="userId" />}
                dataIndex="userId"
                render={renderUserId}
            />
            <Column
                key="orderId"
                title={<FormattedMessage id="orderId" />}
                dataIndex="orderId"
                render={renderOrderId}
            />
        </Table>
    );
};
