import { WidgetPickerStyle } from "@components/dashboard/widgetPicker/widgetPickerStyle";
import { DeviceSettingWidget } from "@pages/addDashboardPage/addDashboardPage";
import { Select } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { widgetFactory } from "@pages/addDashboardPage/factories/widgetFactory";
import { WidgetsFormValuesUnion } from "@pages/addGalleriesPage/constants/widgetContext";
import { WidgetType, widgetsList } from "./widgetsList/widgets";
import { SelectField } from "../../field/selectField";

export interface WidgetPickerProps {
    addWidget(widget: DeviceSettingWidget): void;
    addWidgetToRoot(widget: WidgetsFormValuesUnion): void;
    setEditWidgetId(widgetId: string | null): void;
    view: string;
}
export class WidgetPicker extends React.Component<WidgetPickerProps> {
    private select: Select | null;

    public render() {
        const { addWidget, addWidgetToRoot, setEditWidgetId, view: index } = this.props;
        return (
            <WidgetPickerStyle id={`widgetPickerArea-${index}`}>
                <SelectField
                    getPopupContainer={() => document.getElementById(`widgetPickerArea-${index}`) as HTMLElement}
                    onChange={(type: WidgetType) => {
                        const newWidget = widgetFactory(type);
                        addWidget({
                            id: newWidget.id,
                            visible: true
                        });
                        addWidgetToRoot(newWidget);
                        setEditWidgetId(newWidget.id);
                    }}
                    label={<FormattedMessage id="dashboard.chooseNewWidgetLabel" />}
                >
                    {widgetsList.map(widget => (
                        <Select.Option value={widget} key={widget}>
                            <FormattedMessage id={`widgetPickerItem.${widget}`} />
                        </Select.Option>
                    ))}
                </SelectField>
            </WidgetPickerStyle>
        );
    }
}
