/**
 * checks if object is of given type T, only works if T extends {type: string}
 * example:
 * interface A {type: "a", a: string}
 * interface B {type: "b", b: string}
 * let s: A | B;
 * if(objectTypeCheck<A>(s, "a")) { s.a }
 * if(objectTypeCheck<B>(s, "b")) { s.b }
 */
export const objectTypeCheck = <T>(object: unknown & { type?: string | null }, type: string): object is T & { type: string } => object.type === type;
