import { Col } from "antd";
import React from "react";
import { NameAndDescription } from "../additionalComponents/nameAndDescription";
import { CardWrapperProps } from "./cardWrapper";

export const InlineWrapper: React.FC<CardWrapperProps> = (props) => {
    const { children, name, description, disableTitle } = props;

    const title = name === "number" || disableTitle
        ? undefined
        : (
            <NameAndDescription
                description={description}
                label={name}
            />
        );

    return (
        <Col style={{ margin: 0 }}>
            {title}
            {children}
        </Col>
    );
};
