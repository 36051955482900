import { FormInstance } from "antd/lib/form/Form";

export interface PrizeFactoryProps {

}

export const prizeFactory = () => ({
    title: {},
    imageUrl: "",
    description: {}
});
