import { TranslationsList } from "@components/translations/translationsList/translationsList";
import { createTranslationsMerge } from "@utils/createTranslationsMerge";
import { withAuthContext, WithAuthContextProps } from "@utils/withAuthContext";
import { filterQueryState, FilterQueryStateProps } from "@utils/withFilterQueryState";
import { FilterSortPagingQuery } from "@utils/withPagingAndSort";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { compose } from "redux";
import { AddTranslationProps, GetTranslationCountProps, GetTranslationsProps, GetTranslationsQueryVariables, Maybe, RemoveTranslationProps, TextByLanguage, Translation, UpdateTranslationProps, withAddTranslation, withGetTranslationCount, withGetTranslations, withRemoveTranslation, withUpdateTranslation } from "../../../graphql2/types";

export interface TranslationsListContainerProps {
    selectedLanguage: string;
    search: string;
    superAdmin?: boolean;
    keys?: string[] | null;
    form: FormInstance;
}

const TRANSLATION_LIST_PAGINATION = 25;

const withFilterQueryState = filterQueryState<FilterSortPagingQuery>(
    { pageSize: TRANSLATION_LIST_PAGINATION }
);

export const withGenericTranslations = withGetTranslations({
    name: "genericTranslations",
    options(ownProps: TranslationsListProps) {
        let search: GetTranslationsQueryVariables["filter"] = {};

        const { sort: sortOrder, sortBy: sortField } = ownProps.filterQuery;
        const sort = (sortOrder && sortField) ? { field: sortField, order: sortOrder } : null;

        if (ownProps.search) {
            search = {
                keyPart: ownProps.search
            };
        }
        if (ownProps.keys) {
            search = {
                keys: ownProps.keys
            };
        }
        return {
            variables: {
                filter: {
                    includeDefault: true,
                    projectId: ownProps.superAdmin ? "" : ownProps.getProjectId(),
                    default: true,
                    ...search
                },
                paging: { limit: ownProps.filterQuery.pageSize, skip: ownProps.filterQuery.skip },
                sort
            }
        };
    }
});

export const withPlatformTranslations = withGetTranslations({
    name: "platformTranslations",
    options(ownProps: TranslationsListProps) {
        let translationKeys: string[] = [];

        if (ownProps.genericTranslations.translations) {
            translationKeys = ownProps.genericTranslations.translations.map(t => t.key);
        }

        const { sort: sortOrder, sortBy: sortField } = ownProps.filterQuery;
        const sort = (sortOrder && sortField) ? { field: sortField, order: sortOrder } : null;

        return {
            variables: {
                filter: {
                    includeDefault: true,
                    projectId: ownProps.superAdmin ? "" : ownProps.getProjectId(),
                    default: false,
                    keys: translationKeys
                },
                paging: { limit: ownProps.filterQuery.pageSize, skip: ownProps.filterQuery.skip },
                sort
            }
        };
    },
    skip(ownProps: TranslationsListProps) {
        return !ownProps.genericTranslations.translations || Boolean(ownProps.superAdmin);
    }
});

export const withTranslationCount = withGetTranslationCount({
    name: "count",
    options(ownProps: TranslationsListProps) {
        let search: GetTranslationsQueryVariables["filter"] = {};

        if (ownProps.search) {
            search = {
                keyPart: ownProps.search
            };
        }

        if (ownProps.keys) {
            search = {
                keys: ownProps.keys
            };
        }

        return {
            variables: {
                filter: {
                    includeDefault: true,
                    projectId: ownProps.superAdmin ? "" : ownProps.getProjectId(),
                    ...search
                }
            }
        };
    }
});

export const withAddTranslationMutation = withAddTranslation({
    name: "addTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const withUpdateTranslationMutation = withUpdateTranslation({
    name: "updateTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const withRemoveTranslationMutation = withRemoveTranslation({
    name: "removeTranslation",
    options: {
        refetchQueries: () => ["getTranslations", "getTranslationCount"],
        awaitRefetchQueries: true
    }
});

export const TranslationsListContainer = compose<React.ComponentClass<TranslationsListContainerProps>>(
    withAuthContext,
    withFilterQueryState,
    withGenericTranslations,
    withPlatformTranslations,
    createTranslationsMerge(["genericTranslations", "translations"], ["platformTranslations", "translations"], "translations"),
    withTranslationCount,
    withAddTranslationMutation,
    withUpdateTranslationMutation,
    withRemoveTranslationMutation
)(TranslationsList);

export interface MergedTranslation extends Translation {
    genericTranslations?: Array<Maybe<TextByLanguage>>;
}

export type TranslationsListProps =
    WithAuthContextProps &
    TranslationsListContainerProps &
    FilterQueryStateProps<FilterSortPagingQuery> &
    GetTranslationsProps<{}, "genericTranslations"> &
    MaybeElements<GetTranslationsProps<{}, "platformTranslations">> &
    AddTranslationProps<{}, "addTranslation"> &
    UpdateTranslationProps<{}, "updateTranslation"> &
    RemoveTranslationProps<{}, "removeTranslation"> &
    GetTranslationCountProps<{}, "count"> &
    { translations: MergedTranslation[]; };
