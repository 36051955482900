import { CloseCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AddCard } from "@components/addCard/addCard";
import { prizeFactory } from "@pages/addChallengesPage/factories";
import { getText } from "@utils/getText";
import { ErrorDictionary, hasError } from "@utils/mapValidateErrorEntity";
import { Card, Col, Form, Popconfirm, Row, Tag } from "antd";
import Meta from "antd/lib/card/Meta";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CardImage, CardSubtitle, CardWrapper } from "../challengePrizesCardStyle";

type ChallengePrizesListProps = {
    form: FormInstance;
    errors?: ErrorDictionary;
    editPrize(index: number): void;
    onBlur(): void;
};

export const ChallengePrizesList: React.FC<ChallengePrizesListProps> = (props) => {
    const { form, errors, editPrize, onBlur } = props;

    return (
        <React.Fragment>
            <CardSubtitle>
                <FormattedMessage id="otherPrizes" />
            </CardSubtitle>
            <Form.List name={["rewards", "prizes"]}>
                {(fields, { add, remove }) => {
                    const prizes = form.getFieldValue(["rewards", "prizes"]);
                    return (
                        <Row
                            gutter={16}
                            style={{ minHeight: 350 }}
                        >
                            {(prizes || []).map((prize, index) => (
                                <Col
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    span={8}
                                    style={{ marginBottom: 20, minHeight: 350 }}
                                >
                                    <CardWrapper>
                                        <Card
                                            actions={[
                                                <EditOutlined onClick={() => editPrize(index)} />,
                                                <Popconfirm
                                                    cancelText="No"
                                                    okText="Yes"
                                                    title={<FormattedMessage id="deleteConfirm" />}
                                                    onConfirm={() => remove(index)}
                                                >
                                                    <DeleteOutlined />
                                                </Popconfirm>
                                            ]}
                                            cover={<CardImage backgroundImage={prize.imageUrl ? prize.imageUrl : ""} />}
                                            extra={hasError(errors, ["rewards", "prizes", index]) && (
                                                <Tag
                                                    color="error"
                                                    icon={<CloseCircleOutlined />}
                                                >
                                                    <FormattedMessage id="error" />
                                                </Tag>
                                            )}
                                        >
                                            <Meta
                                                description={getText(prize.description ? prize.description : <FormattedMessage id="prize.empty.description" />)}
                                                title={getText(prize.title ? prize.title : <FormattedMessage id="prize.empty.title" />)}
                                            />
                                        </Card>
                                    </CardWrapper>
                                </Col>
                            ))}

                            <Col
                                span={8}
                                style={{ marginBottom: 20, minHeight: 350 }}
                            >
                                <CardWrapper>
                                    <AddCard
                                        label={<FormattedMessage id="addAPrize" />}
                                        onClick={() => {
                                            add(prizeFactory()); onBlur();
                                        }}
                                    />
                                </CardWrapper>
                            </Col>
                        </Row>
                    );
                }}
            </Form.List>
        </React.Fragment>
    );
};
