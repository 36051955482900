/* eslint-disable react/prop-types */
import { CompaniesTable } from "@components/companies/companiesTable";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { TableContainer } from "@components/table/tableContainer";
import { useAuth } from "@context/authContext/context";
import { AddCompanyMutationVariables, Company } from "@graphql2/types";
import { CompaniesPageProps } from "@pages/companiesPage/companiesPageContainer";
import { CompaniesPageStyle } from "@pages/companiesPage/companiesPageStyle";
import { CompanyCreateForm } from "@pages/companiesPage/companyCreate";
import { getFrontendRoles } from "@utils/applicationUtils";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage } from "@utils/toTextByLanguage";
import { Button, Card, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface CompaniesPageState {
    showModal: boolean;
    companyId?: string;
}

const emptyCompany: Partial<AddCompanyMutationVariables> = {
    limitRegistrations: false,
    maxRegistrations: 0,
    hasSupport: true
};

export const CompaniesPage: React.FC<CompaniesPageProps> = (props) => {
    const {
        applications: { applications, loading: applicationsLoading },
        companies: { companies, loading: loadingCompanies }
    } = props;
    const [showModal, setShowModal] = React.useState(false);
    const [companyId, setCompanyId] = React.useState<string | undefined>();
    const [form] = useForm();
    const intl = useIntl();
    const { projectId } = useAuth();

    const roles = getFrontendRoles(applications);

    const removeCompany = async (id: string) => {
        try {
            await props.removeCompany({ variables: { id } });
            message.success(intl.formatMessage({ id: "companies.removed" }));
        } catch (err) {
            message.error(intl.formatMessage({ id: "companies.removeFailed" }));
            console.error(err);
        }
    };

    const clearCompany = () => {
        setShowModal(false);
        setCompanyId(undefined);
        form.resetFields();
    };

    const submitForm = async (values) => {
        const { addCompany, updateCompany } = props;
        const requestValues: AddCompanyMutationVariables = {
            ...values,
            code: values.code?.trim(),
            introTitle: toTextByLanguage(values.introTitle),
            introMessage: toTextByLanguage(values.introMessage),
            connect: values.connect && {
                ...values.connect,
                subtitle: toTextByLanguage(values.connect.subtitle),
                title: toTextByLanguage(values.connect.title)
            },
            team: values.team && {
                ...values.team,
                title: toTextByLanguage(values.team.title),
                subtitle: toTextByLanguage(values.team.subtitle),
                selectTitle: toTextByLanguage(values.team.selectTitle),
                selectSubtitle: toTextByLanguage(values.team.selectSubtitle)
            }
        };
        if (companyId) {
            try {
                await updateCompany({ variables: { ...requestValues, projectId, id: companyId } });
                message.success(intl.formatMessage({ id: "company.updated" }));
                clearCompany();
            } catch (err) {
                message.error(intl.formatMessage({ id: "company.updateFailed" }));
            }
        } else {
            try {
                await addCompany({ variables: { ...requestValues, projectId } });
                message.success(intl.formatMessage({ id: "company.added" }));
                clearCompany();
            } catch (err) {
                message.error(intl.formatMessage({ id: "company.addFailed" }));
            }
        }
    };

    const selectCompany = async (id: string) => {
        // was causing issues without the next line (1 render behind at all times)
        const foundCompany = companies && companies.find(c => c && c.id === id);
        if (foundCompany) {
            const introMessage = toLanguageObject(foundCompany.introMessage);
            const introTitle = toLanguageObject(foundCompany.introTitle);

            let connect;
            if (foundCompany.connect) {
                connect = {
                    ...foundCompany.connect,
                    title: toLanguageObject(foundCompany.connect.title),
                    subtitle: toLanguageObject(foundCompany.connect.subtitle)
                };
            }
            let team;
            if (foundCompany.team) {
                team = {
                    ...foundCompany.team,
                    title: toLanguageObject(foundCompany.team.title),
                    subtitle: toLanguageObject(foundCompany.team.subtitle),
                    selectTitle: toLanguageObject(foundCompany.team.selectTitle),
                    selectSubtitle: toLanguageObject(foundCompany.team.selectSubtitle)
                };
            }

            await form.setFieldsValue({
                ...foundCompany,
                introMessage,
                introTitle,
                connect,
                team
            });
            setCompanyId(id);
            setShowModal(true);
        }
        // todo show alert
    };

    const createNewCompany = async () => {
        setShowModal(false);
        form.resetFields();
        await form.setFieldsValue(emptyCompany);
        setShowModal(true);
    };

    const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 });

    return (
        <CompaniesPageStyle>
            <HeaderContent>
                <Flex justifyContent="space-between">
                    <FormattedMessage
                        id="companies"
                        tagName="h1"
                    />
                    <div>
                        <Button
                            key="button1"
                            type="primary"
                            onClick={createNewCompany}
                        >
                            <FormattedMessage id="companies.addCompany" />
                        </Button>
                    </div>
                </Flex>
            </HeaderContent>
            <Card>
                <TableContainer
                    dataSource={companies as Company[] || []}
                    handlers={{
                        removeCompany,
                        selectCompany
                    }}
                    loading={loadingCompanies || applicationsLoading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: companies?.length
                    }}
                    TableComponent={CompaniesTable}
                    tableFilter={{}}
                    onChange={(newPagination) => setPagination({
                        current: newPagination.current !== pagination.current ? newPagination.current : 1,
                        pageSize: newPagination.pageSize
                    })}
                />
            </Card>
            <Modal
                className="noModalFooter"
                visible={showModal}
                width={1000}
                onCancel={clearCompany}
            >
                <CompanyCreateForm
                    form={form}
                    initialValues={emptyCompany}
                    roles={roles}
                    submitForm={submitForm}
                />
            </Modal>
        </CompaniesPageStyle>
    );
};
