/* eslint-disable react/no-array-index-key */
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { FormConditionTranslations, TranslationField, TranslationOptionField, TranslationOptions, TreeWrapper, ValueKey } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/translations/challengeFormConditionTranslationsStyle";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { ChallengeLanguageFieldProps } from "@utils/languageFieldProps";
import { ErrorDictionary } from "@utils/mapValidateErrorEntity";
import { maxCharacters } from "@utils/maxCharacters";
import { Alert } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useEnabledLang } from "../../../../../../context/enabledLangContext/context";

export interface ChallengeFormConditionTranslationsProps {
    activeLanguage: string;
    errors?: ErrorDictionary;
    condition: ChallengeFormConditionFormValues;
    index: number;
    form: FormInstance;
    hidden: boolean;
    prefix: (string | number)[];
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

export interface TranslationProps extends ChallengeLanguageFieldProps {
    condition: ChallengeFormConditionFormValues;
    index: number;
    prefix: (string | number)[];
    onBlur?(): void;
}

const titleField = (props: TranslationProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={[...props.prefix, "title", props.language]}
        {...props.sharedProps}
    />
);

const errorField = (props: TranslationProps) => (
    <Field
        key={`errorMessages.${props.language}`}
        hidden={props.hidden}
        id={`errorMessages.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="errorMessage" />}
        name={[...props.prefix, "errorMessages", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

export const ChallengeFormConditionTranslations: React.FC<ChallengeFormConditionTranslationsProps> = (props) => {
    const { changeSelectedLanguage, condition, index, form, activeLanguage, onBlur, hidden, prefix, errors } = props;
    const sharedProps = {
        onBlur,
        rules: [requiredrule]
    };

    const { enabledLanguages } = useEnabledLang();

    const elements = condition && condition.formTemplate ? condition.formTemplate.elements : [];

    const langFieldProps = React.useMemo(
        () => enabledLanguages.map(language => (
            {
                form,
                prefix,
                sharedProps,
                defaultValues: {},
                language: language.code,
                hidden: language.code !== activeLanguage,
                condition,
                index
            }
        )),
        [enabledLanguages, activeLanguage]
    );

    return (
        <FormConditionTranslations hidden={hidden}>
            <Alert
                message={<FormattedMessage id="form.warning.translations" />}
                type="warning"
            />
            <TranslationTabs
                activeLanguage={activeLanguage}
                errors={errorsToLanguageErrors(errors)}
                handleChangeSelectedLanguage={changeSelectedLanguage}
                languages={enabledLanguages}
            />
            {langFieldProps.map(titleField)}
            {langFieldProps.map(errorField)}
            {
                elements && elements.map((el, elementIndex) => {
                    return langFieldProps.map((vprops) => (
                        <TreeWrapper key={vprops.language}>
                            <ValueKey>
                                <TranslationField
                                    key={`${elementIndex}`}
                                    hidden={vprops.hidden}
                                    label={el.property ? el.property : ""}
                                    name={[...prefix, "elements", elementIndex, "title", vprops.language]}
                                    type="text"
                                    {...sharedProps}
                                />
                            </ValueKey>
                            <TranslationOptions>
                                {el.options && el.options.map((option, optionIndex) => (
                                    <TranslationOptionField
                                        key={`${elementIndex}.${optionIndex}`}
                                        hidden={vprops.hidden}
                                        initialValue={option.title && option.title[vprops.language]}
                                        label={option.value ? option.value : ""}
                                        name={[...prefix, "elements", elementIndex, "options", optionIndex, "title", vprops.language]}
                                        type="text"
                                        {...sharedProps}
                                    />
                                ))}
                            </TranslationOptions>
                        </TreeWrapper>
                    ));
                })
            }
        </FormConditionTranslations>
    );
};
