import * as React from "react";
import { EnabledLangContext } from "./context";
import { Types } from "./reducers";

type RESET_ENABLED_LANGUAGES = () => void;
type UPDATE_ENABLED_LANGUAGES = (payload: string[]) => void;

type GlobalActionsType = [
    UPDATE_ENABLED_LANGUAGES,
    RESET_ENABLED_LANGUAGES
];

export const useGlobalActions = (): GlobalActionsType => {
    const { globalDispatch } = React.useContext(EnabledLangContext);

    const updateEnabledLanguages = (payload: string[]) => {
        globalDispatch({ type: Types.UPDATE_LANGUAGES, payload });
    };

    const resetEnabledLanguages = () => {
        globalDispatch({ type: Types.RESET_LANGUAGES });
    };

    return [updateEnabledLanguages, resetEnabledLanguages];
};
