import { ContentTabs } from "@components/contentTabs/contentTabs";
import { CoverImageCard } from "@components/coverImageCard/coverImageCard";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { ImageRatios } from "@components/imageUpload/imageCrop/imageCrop";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { useErrors } from "@hooks/useErrors";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { AddTagsPageStyle } from "@pages/addTagsPage/addTagsPageStyle";
import { ADD_TAGS_TABS, AddTagsPageProps } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { createDefaultValues } from "@utils/createDefaultValues";
import { emptyTag } from "@utils/emptyItems/emptyTag";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getDataFromProperties } from "@utils/getDataFromProperties";
import { getText } from "@utils/getText";
import { formValuesToTag, tagToFormValues } from "@utils/mappers/tagMapper";
import { LanguageObject } from "@utils/toLanguageObject";
import { Badge, Breadcrumb, Button, Form, Popconfirm, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TagSettingsCard } from "./general/tagSettingsCard";
import { TitleAndDescriptionCard } from "./general/titleAndDescriptionCard";

export type ErrorObj = object;

export type TagErrors = {
    title: ErrorObj;
    description: ErrorObj;
    color: ErrorObj;
    image: ErrorObj;
};

export interface AddTagsPageState {
    selectedLanguage: string;
    currentTab: number;
    type?: string;
    editContentIndex?: number;
    unsaved: {
        titleAndDescription: boolean;
        coverImage: boolean;
        contentTagSettings: boolean;
    };
    errors?: TagErrors;
}

export type BaseTagFormValues = {
    id?: string;
    title: LanguageObject;
    description: LanguageObject;
    color: string;
    image?: string | null;
};

export const AddTagsPage: React.FC<AddTagsPageProps> = (props: AddTagsPageProps) => {
    const [form] = useForm();
    const intl = useIntl();
    const [selectedLanguage, changeSelectedLanguage] = useCurrentLanguage();
    const [fieldErrors, setErrors] = useErrors();
    const [enabledSave, setEnabledSave] = React.useState<boolean>();

    const { callbackUrl, match, path, location, history, addTag, updateTag, removeTag } = props;

    const data = getDataFromProperties(props, path.slice(0, -1));

    const defaultValues = React.useMemo(
        () => createDefaultValues(tagToFormValues, emptyTag, location.state?.import, data?.loading, getDataFromProperties(props, path)?.find(item => item.id === match.params.id)),
        [data]
    );

    const tag = form.getFieldsValue();

    const isLoading = data?.loading;

    const tabs: string[] = [
        ADD_TAGS_TABS.general
    ];

    // const defaultProps = {
    //     form,
    //     changeSelectedLanguage,
    //     disabled: isSaving,
    //     onBlur
    // };

    const defaultProps = {
        changeSelectedLanguage,
        disabled: false,
        defaultValues,
        onChange: () => {
            if (enabledSave) {
                return;
            }
            setEnabledSave(true);
        }
    };

    const changeSelectedTab = (index: number | string) => {
        props.history.push(
            `${callbackUrl}/${match.params.id
                ? `edit/${match.params.id}`
                : "add"
            }/${tabs[index]}`
        );
    };

    if (isLoading) {
        return <SpinStyle><Spin /></SpinStyle>;
    }

    if (match.params.id && !defaultValues.id) {
        props.history.push("/404");
    }

    const tagTabs = tabs.map(t => <Badge dot={Boolean(fieldErrors[t])}><FormattedMessage id={t} /></Badge>);
    const submitForm = async (values) => {
        const errMsg = match.params.id ? "webshop.articleUpdateFailed" : "webshop.articleAddFailed";
        const updateMsg = match.params.id ? "contentTag.contentTagUpdated" : "contentTag.contentTagAdded";
        const mappedValues = formValuesToTag(values);

        try {
            if (match.params.id) {
                await updateTag({ variables: { ...mappedValues, id: match.params.id } });
            } else {
                await addTag({ variables: mappedValues });
            }

            handleSuccess(<FormattedMessage id={updateMsg} />, () => {
                history.push(callbackUrl);
            });
        } catch (errors) {
            handleError(<FormattedMessage id={errMsg} />, () => {
                setErrors(errors);
            });
            message.error(<FormattedMessage id="error.checkHighlightedFields" />);
        }
    };

    const removeItem = async () => {
        try {
            if (match.params.id) {
                await removeTag({ variables: { id: match.params.id } });
            }
            message.success(intl.formatMessage({ id: "contentTags.deleteSuccess" }));
            history.push(callbackUrl);
        } catch (errors) {
            message.error(intl.formatMessage({ id: "error.couldNotDeleteTag" }));
        }
    };

    const onFailed = (errors) => {
        setErrors(errors);
    };

    return (
        <AddTagsPageStyle>
            <Form
                hideRequiredMark
                form={form}
                initialValues={defaultValues}
                onFinish={submitForm}
                onFinishFailed={onFailed}
            >
                <HeaderContent>
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={callbackUrl}>
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {getText(tag?.title || defaultValues?.title, selectedLanguage)}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                            {enabledSave && <UnsavedChanges key="warning" />}
                            <Button
                                htmlType="submit"
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            <Popconfirm
                                cancelText="No"
                                okText="Yes"
                                placement="bottomRight"
                                title={<FormattedMessage id="deleteConfirm" />}
                                onConfirm={removeItem}
                            >
                                <Button
                                    danger
                                    className="headerButton"
                                >
                                    <FormattedMessage id="contentTag.delete" />
                                </Button>
                            </Popconfirm>

                        </div>
                    </HeaderActions>
                    <Flex justifyContent="space-between">
                        <ContentTabs
                            handleTabSelectedLanguage={changeSelectedTab}
                            tabs={tagTabs}
                        />
                    </Flex>
                </HeaderContent>
                <TitleAndDescriptionCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    languageErrors={errorsToLanguageErrors({
                        title: fieldErrors && fieldErrors.title,
                        description: fieldErrors && fieldErrors.description
                    })}
                />
                <CoverImageCard
                    {...defaultProps}
                    cropSettings={{
                        aspect: ImageRatios.DEFAULT,
                        zoom: true,
                        rotate: true
                    }}
                    defaultImage={defaultValues.image || ""}
                    form={form}
                    formField="image"
                    titleId="contentTag.image"
                    onBlur={() => {
                        setEnabledSave(true);
                    }}
                />
                <TagSettingsCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    form={form}
                    languageErrors={errorsToLanguageErrors({
                        color: fieldErrors.color
                    })}
                />
            </Form>
        </AddTagsPageStyle>
    );
};
