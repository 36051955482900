import { TimelinePreviewStyle } from "@components/timelinePreview/timelinePreviewStyle";
import { TimelineAddElement } from "@components/timelinePreview/timelineAddElement/timelineAddElement";
import * as React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { FormattedMessage } from "react-intl";

export interface TimelinePreviewProps {
    backgroundImage?: string;
    flagImage?: string;
    foregroundImage?: string;
    splitterImage?: string;
    finishedImage?: string;

    children?: React.ReactNode;

    onForegroundImageClicked(): void;
    onBackgroundImageClicked(): void;
    onSplitterImageClicked(): void;
    onFlagImageClicked(): void;
    onFinishedImageClicked(): void;
}

export const TimelinePreview: React.FC<TimelinePreviewProps> = (props) => {
    const [progress, setProgress] = React.useState<number>(25);
    const [buttonsHidden, setButtonsHidden] = React.useState<boolean>(false);

    const {
        backgroundImage,
        foregroundImage,
        finishedImage,
        splitterImage,
        flagImage,
        children,
        onForegroundImageClicked,
        onBackgroundImageClicked,
        onSplitterImageClicked,
        onFlagImageClicked,
        onFinishedImageClicked
    } = props;

    return (
        <React.Fragment>
            <Checkbox
                onChange={() => { setButtonsHidden(!buttonsHidden); }}
            >
                <FormattedMessage id="challenge.timeline.hide.buttons" />
            </Checkbox>

            <TimelinePreviewStyle
                progress={progress}
                backgroundImage={backgroundImage}
                foregroundImage={progress >= 100 && finishedImage ? finishedImage : foregroundImage}
                splitterImage={splitterImage}
            >

                <div id="comparison">
                    <figure>
                        <img src={backgroundImage} alt="Background" />
                        <img src={foregroundImage} alt="Foreground" id="foreground" />
                        <div id="divisor" />
                        {Boolean(flagImage) && (
                            <div id="flagContainer">
                                <img id="flag" src={flagImage} alt="Flag" />
                                <div id="progress">{progress}%</div>
                            </div>
                        )}
                        {Boolean(splitterImage) && <img id="splitter" src={splitterImage} alt="Splitter" />}
                    </figure>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={progress}
                        id="slider"
                        onChange={(event) => { setProgress(Number(event.target.value)); }}
                    />
                </div>
                {
                    !buttonsHidden && (
                        <React.Fragment>
                            <TimelineAddElement
                                className="selector background-selector"
                                title={<FormattedMessage id="challenge.visualisations.timeline.backgroundImage" />}
                                icon={<PlusCircleOutlined />}
                                handleClick={onBackgroundImageClicked}
                            />
                            <TimelineAddElement
                                className="selector flag-selector"
                                title={<FormattedMessage id="challenge.visualisations.timeline.flag" />}
                                icon={<PlusCircleOutlined />}
                                handleClick={onFlagImageClicked}
                            />
                            <TimelineAddElement
                                className="selector splitter-selector"
                                title={<FormattedMessage id="challenge.visualisations.timeline.splitter" />}
                                icon={<PlusCircleOutlined />}
                                handleClick={onSplitterImageClicked}
                            />
                            <TimelineAddElement
                                className="selector foreground-selector"
                                title={<FormattedMessage id="challenge.visualisations.timeline.foregroundImage" />}
                                icon={<PlusCircleOutlined />}
                                handleClick={onForegroundImageClicked}
                            />
                            <TimelineAddElement
                                className="selector finished-selector"
                                title={<FormattedMessage id="challenge.visualisations.timeline.successImage" />}
                                icon={<PlusCircleOutlined />}
                                handleClick={onFinishedImageClicked}
                            />
                        </React.Fragment>
                    )
                }
                {children}
            </TimelinePreviewStyle>
        </React.Fragment>
    );
};
