import { MainPageProps } from "@pages/mainPage/mainPageContainer";
import { Card, Layout, Breadcrumb } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export interface MainPageState {

}

@autobind
export class MainPage extends React.Component<MainPageProps, MainPageState> {
    public render() {
        return (
            <div>
                <Layout.Header>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/"><FormattedMessage id="home" /></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="mainpage" /></Breadcrumb.Item>
                    </Breadcrumb>
                    <h2><FormattedMessage id="mainpage" /></h2>
                </Layout.Header>
                <Card
                    title={<FormattedMessage id="mainpage" />}
                >
                    <p><FormattedMessage id="form.forgotpasswordtitle" /></p>
                </Card>
            </div>

        );
    }
}
