import { AddTagsRouteProps, createAddTagsPageContainer } from "@pages/addTagsPage/baseAddTagsPageContainer";
import { cleanCache } from "@utils/cleanCacheHoc";
import { getAmbassadorTags } from "@graphql2/graphql_op";
import { withRemoveAmbassadorTagMutation } from "@pages/tagsPage/ambassadorTagsPageContainer";
import { withAddAmbassadorTag, withGetAmbassadorTags, withUpdateAmbassadorTag } from "../../graphql2/types";

export const withAmbassadorTags = withGetAmbassadorTags({
    skip: (props: AddTagsRouteProps) => {
        return !props.match.params.id;
    }
});

export const AddAmbassadorTagsPageContainer = createAddTagsPageContainer({
    withAddTag: withAddAmbassadorTag({
        name: "addTag",
        options: {
            update: cleanCache([getAmbassadorTags])
        }
    }),
    withUpdateTag: withUpdateAmbassadorTag({
        name: "updateTag"
    }),
    withTags: withAmbassadorTags,
    withRemoveTag: withRemoveAmbassadorTagMutation,
    callbackUrl: "/ambassador/tags",
    path: ["data", "ambassadorTags"]
});
