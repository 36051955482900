import { Field } from "@components/field/field";
import { FileDragAndDropContainer } from "@components/fileDragAndDrop/fileDragAndDropContainer";
import { ChallengeVisualisationFont, FontForm } from "@components/fontForm/fontForm";
import { FormModal } from "@components/modal/modal";
import { Label } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { storeImage } from "@utils/storeImage";
import { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ChallengeTimelineVisualisationRepeater {
    image: string;
    repeat: number;
    currentFont?: ChallengeVisualisationFont;
    totalFont?: ChallengeVisualisationFont;
}

export interface EditChallengeTimelineRepeaterProps {
    form: FormInstance;
    hidden: boolean;
    repeater?: ChallengeTimelineVisualisationRepeater;
    visualisationIndex: number;
    close(): void;
    onBlur(): void;

}

export const EditChallengeTimelineRepeater: React.FC<EditChallengeTimelineRepeaterProps> = (props) => {
    const { hidden, repeater, onBlur, visualisationIndex, close, form } = props;
    const onChangeCurry = onBlur;

    const prefix = ["visualisations", visualisationIndex, "banner", "repeater"];

    return (
        <FormModal
            cancelButtonProps={{ onClick: close }}
            hidden={hidden}
            okButtonProps={{ onClick: close }}
        >
            <div>
                <Label><FormattedMessage id="image" /></Label>
                <FileDragAndDropContainer
                    defaultImage={repeater && repeater.image}
                    onChange={async (image): Promise<void> => storeImage(onChangeCurry, form, `${prefix}image`, image, undefined, {
                        fields: [["visualisations"], ["banner", "repeater", "image"]],
                        index: visualisationIndex
                    })}
                />
                <Field
                    hidden
                    id="imageUrl"
                    name={[...prefix, "image"]}
                />
            </div>
            <Field
                numberType
                label={<FormattedMessage id="repeater" />}
                min={0}
                name={[...prefix, "repeat"]}
            />
            <Label><FormattedMessage id="challenge.visualisations.timeline.repeater.current.font" /></Label>
            <FontForm
                font={repeater && repeater.currentFont}
                form={form}
                prefix={[...prefix, "currentFont"]}
            />
            <Label><FormattedMessage id="challenge.visualisations.timeline.repeater.total.font" /></Label>
            <FontForm
                font={repeater && repeater.totalFont}
                form={form}
                prefix={[...prefix, "totalFont"]}
            />
        </FormModal>
    );
};
