// Returns data from an array of properties (in correct order)
export const getDataFromProperties = (data: any, properties: string[]) => {
    let exactData;

    properties.forEach(prop => {
        exactData = !exactData ? data[prop] : (exactData[prop] && exactData[prop]) || undefined;
    });

    return exactData;
};
