import { Application, Maybe, User, useGetUsersDataToExportLazyQuery } from "@graphql2/types";
import moment from "moment";
import * as React from "react";
import { ExportToCsvButton } from "./exportToCsvButton";

const COMMON_FIELDS = ["id", "email", "givenName", "familyName", "locale", "lastLogin", "registeredOn", "roles", "providers"] as const;
const PROFILE_FIELDS = ["sex", "nationality", "country"] as const;

const ExportUsersToCsvButton = () => {
    const [getUsers, { loading, data }] = useGetUsersDataToExportLazyQuery({
        variables: {
            sort: {
                field: "givenName",
                order: "ASC"
            }
        }
    });

    const mapRoles = (applications: Maybe<Application>[]) => {
        const frontEndApp = applications?.find((app) => app?.name === "frontend");

        if (frontEndApp?.roles) {
            const roleNames = frontEndApp.roles.map(role => role.name);

            return roleNames.join(",");
        }

        return "";
    };

    const mapDateField = (field: User["lastLogin"] | User["registeredOn"]): string => {
        return field ? moment(field).format("HH:mm DD-MM-YYYY") : "";
    };

    const mapProviders = (providers: User["providers"]): string => {
        let providersInfo: string = "";

        if (providers) {
            const providersMapped = providers.map((provider) => {
                const providerName = provider?.provider;
                const providerEnebled = provider?.enabled ? "enabled" : "disabled";

                return `${providerName}::${providerEnebled}`;
            });

            providersInfo = providersMapped.join(",");
        }
        return providersInfo;
    };

    const sanitizeString = (stringToSanitaze: string) => {
        return stringToSanitaze.replace(/;/g, " ");
    };

    const mapEntryToCsvRow = (user: User) => {
        const commonFieldsData: string[] = [];
        const profileFieldData: string[] = [];

        COMMON_FIELDS.forEach(field => {
            switch (field) {
                case "lastLogin":
                case "registeredOn":
                    commonFieldsData.push(
                        mapDateField(user[field])
                    );
                    break;
                case "providers":
                    commonFieldsData.push(
                        mapProviders(user[field])
                    );
                    break;
                case "roles":
                    commonFieldsData.push(
                        mapRoles(user.applications ?? [])
                    );
                    break;
                default:
                    commonFieldsData.push(
                        sanitizeString(user[field] ?? "")
                    );
            }
        });

        PROFILE_FIELDS.forEach(field => {
            const profileField = user.profile[field];

            if (typeof profileField === "string") {
                profileFieldData.push(
                    sanitizeString(profileField) ?? ""
                );
            } else {
                profileFieldData.push(
                    sanitizeString(profileField?.toString() ?? "")
                );
            }
        });

        return [...commonFieldsData, ...profileFieldData].join(";");
    };

    return (
        <ExportToCsvButton
            columnNames={[...COMMON_FIELDS, ...PROFILE_FIELDS]}
            dataToDownload={data?.users}
            exportFileName="users"
            lazyDataQuery={getUsers}
            loading={loading}
            mapEntryToCsvRow={mapEntryToCsvRow}
        />
    );
};

export default ExportUsersToCsvButton;

