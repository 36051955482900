import styled from "styled-components";
import { LinkWrap } from "@components/linkWrap";

export const AddChallengesPageStyle = styled.div``;

export const HeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .anticon-arrow-left {
      margin-right: 0.625rem;
    }
`;

export const WrapperIconLink = styled(LinkWrap)``;
