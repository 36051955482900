import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import React from "react";
import { AddListItem } from "./addListItem";
import { ListFieldWrapperProps } from "./listFieldWrapper";
import { ListItem } from "./listItem";
import { ListItemStyle } from "./styles";

export type ListFieldProps = {
    fields: FormListFieldData[];
    operation: FormListOperation;
    fieldOptions: ListFieldWrapperProps;
}

export const ListField: React.FC<ListFieldProps> = (props) => {
    const { fields, operation, fieldOptions } = props;
    const { add, remove } = operation;
    const { disable } = fieldOptions;

    return (
        <React.Fragment>
            {fields.map(field => {
                return (
                    <ListItemStyle>
                        <ListItem
                            key={field.name}
                            field={field}
                            fieldOptions={fieldOptions}
                            remove={remove}
                        />
                    </ListItemStyle>
                );
            })}
            <AddListItem
                add={add}
                disable={disable}
            />
        </React.Fragment>
    );
};
