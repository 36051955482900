/* eslint-disable complexity */
import { NamePath } from "antd/lib/form/interface";
import { FixedEventsFieldProps } from "@components/dashboard/widgets/eventWidget/fixedEventsField/fixedEventsFieldContainer";
import { WidgetSort } from "@components/dashboard/widgets/widgetSort";
import { PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { WidgetSearchTitlesComponent } from "@components/dashboard/widgets/widgetSearchTitlesComponent";
import { Form, Spin, Alert } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { requiredrule } from "@components/form/requiredRule";

export const FixedEventsField: React.FC<FixedEventsFieldProps> = (props) => {
    const {
        singleMode,
        itemKey,
        eventOptions,
        sort,
        setLocalState,
        addSort,
        forceSingle,
        eventTitles
    } = props;

    const { id } = eventOptions;
    const rules = [requiredrule];

    const isLoading = Boolean(eventTitles?.loading);
    const hasError = eventTitles?.error;

    let name: NamePath = ["widgets", id, "eventIds"];
    let label = "eventWidget.addEvents";

    if (singleMode && (itemKey || itemKey === 0)) {
        name = [itemKey, "typeId"];
    }

    if (singleMode) {
        label = "eventWidget.addEvent";
    }

    if (forceSingle && singleMode) {
        name = ["widgets", id, "eventId"];
    }

    React.useEffect(() => {
        const ids = eventOptions.form.getFieldValue(name);

        if (ids) {
            setLocalState({ events: ids });
        }
    }, []);

    return (
        <React.Fragment>
            <Form.Item>
                <div style={hasError ? { display: "none" } : undefined}>
                    <WidgetSearchTitlesComponent
                        data={eventTitles?.events || []}
                        name={name}
                        rules={rules}
                        showArrow
                        showSearch
                        isLoading={isLoading}
                        notFoundContent={isLoading ? <Spin size="small" /> : null}
                        mode={!singleMode ? "multiple" : undefined}
                        id={name.join(".")}
                        filterOption={false}
                        placeholder={<FormattedMessage id="eventWidget.fixedEvents.placeholder" />}
                        optionFilterProp="children"
                        label={<FormattedMessage id={label} />}
                    />
                    {addSort && (
                        <WidgetSort
                            type={PreviewTypes.event}
                            updateParentState={setLocalState}
                            defaults={sort}
                            {...eventOptions}
                        />
                    )}
                </div>
                {hasError && <Alert type="error" message={<FormattedMessage id="dashboard.norole" />} />}
            </Form.Item>
        </React.Fragment>
    );
};
