import { languageCodeToObject } from "@pages/translationsPage/constants/languages";
import { EnabledLangState, initialLangState } from "./context";

// eslint-disable-next-line no-shadow
export enum Types {
    UPDATE_LANGUAGES,
    RESET_LANGUAGES
}

export type Action =
    { type: Types.UPDATE_LANGUAGES; payload: string[];} |
    { type: Types.RESET_LANGUAGES;}

export const enabledLanguagesReducer = (state: EnabledLangState, action: Action) => {
    switch (action.type) {
        case Types.UPDATE_LANGUAGES:
            return {
                enabledLanguages: languageCodeToObject(action.payload)
            };
        case Types.RESET_LANGUAGES:
            return initialLangState;
        default:
            return state;
    }
};
