import { FormElementField } from "@components/formContentFields/formElement";
import { formElementFactory } from "@pages/addChallengesPage/factories";
import { FormTemplateFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { FormConditionFields, RightMenu, SideBar } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormConditionStyle";
import { InjectedIntl } from "@utils/override.types";
import { Menu } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface EditFormState {
    unsaved: boolean;
    sequence: number;
}

export interface DeleteFormConditionFieldProps {
    formTemplate: FormTemplateFormValues;
    elementIndex?: number;
    optionIndex?: number;
    elementType: string;
}

export interface FormStructureProps {
    form: FormInstance;
    intl: InjectedIntl;
    hidden: boolean;
    onChange?: () => void;
}

@autobind
export class FormStructure extends React.Component<FormStructureProps> {
    public render() {
        const {
            form,
            onChange,
            intl,
            hidden
        } = this.props;

        const sharedProps = {
            onBlur: onChange,
            intl
        };

        return (
            <Form.List name={["elements"]}>
                {(fields, { add, remove, move }) => {
                    const formElements = form.getFieldValue(["elements"]);
                    const content = (
                        <FormConditionFields hidden={hidden}>
                            {(formElements || []).map((element, i) => {
                                if (!element.type) {
                                    return;
                                }
                                return (
                                    <FormElementField
                                    // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        deleteFormElement={() => remove(i)}
                                        form={form}
                                        index={i}
                                        moveFormElement={move}
                                        prefix={["elements", i]}
                                        // condition={condition}
                                        {...element}
                                        {...sharedProps}
                                    />
                                );
                            })}
                        </FormConditionFields>
                    );

                    const sidebar = (
                        <SideBar hidden={hidden}>
                            <RightMenu
                                className="sider-right"
                                mode="inline"
                            >
                                <FormattedMessage
                                    id="inputFields"
                                    tagName="h3"
                                />

                                <Menu.Item
                                    key="checkbox"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="checkbox" />
                                </Menu.Item>

                                <Menu.Item
                                    key="radio"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="radio" />
                                </Menu.Item>

                                <Menu.Item
                                    key="text"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="text" />
                                </Menu.Item>

                                <Menu.Item
                                    key="textArea"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="textArea" />
                                </Menu.Item>

                                <Menu.Item
                                    key="date"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="date" />
                                </Menu.Item>

                                <Menu.Item
                                    key="select"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="select" />
                                </Menu.Item>

                                <Menu.Item
                                    key="number"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="number" />
                                </Menu.Item>

                                <Menu.Item
                                    key="fileUpload"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="fileUpload" />
                                </Menu.Item>

                                <FormattedMessage
                                    id="contentBlocks"
                                    tagName="h3"
                                />

                                <Menu.Item
                                    key="p"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="content" />
                                </Menu.Item>

                                <Menu.Item
                                    key="link"
                                    onClick={(e) => add(this.addFormElement(e, formElements.length))}
                                >
                                    <FormattedMessage id="link" />
                                </Menu.Item>

                            </RightMenu>
                        </SideBar>
                    );

                    return (
                        <React.Fragment>
                            {content}
                            {sidebar}
                        </React.Fragment>
                    );
                }}

            </Form.List>
        );
    }

    private addFormElement(data, i: number) {
        return formElementFactory({
            type: data.key,
            index: i
        });
    }
}
