import { getAmbassadors } from "@graphql2/graphql_op";
import { AddAmbassadorProps, GetAmbassadorConfigsProps, GetAmbassadorsProps, GetAmbassadorTagsProps, GetUsersProps, RemoveAmbassadorProps, UpdateAmbassadorProps, UserFilter, withAddAmbassador, withGetAmbassadorConfigs, withGetAmbassadors, withGetAmbassadorTags, withGetUsers, withRemoveAmbassador, withUpdateAmbassador } from "@graphql2/types";
import { AddAmbassadorsPage } from "@pages/addAmbassadorsPage/addAmbassadorsPage";
import { cleanCache } from "@utils/cleanCacheHoc";
import { localState, LocalStateProps } from "@utils/withLocalState";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "redux";

export interface AddAmbassadorsPageContainerProps { }

export type AddAmbassadorRouteProps = RouteComponentProps<{ cId: string; aId: string; }, {}, { import: boolean; }>;

interface LocalState {
    filter?: UserFilter;
}

const withLocalState = localState<LocalState>({
    filter: {
        userSearch: undefined
    }
});

const withAmbassadorConfig = withGetAmbassadorConfigs({
    name: "ambassadorConfigs",
    options: (props: AddAmbassadorRouteProps) => ({
        variables: {
            filter: {
                ids: [props.match.params.cId]
            }
        }
    }),
    skip: (props: AddAmbassadorRouteProps) => {
        return !props.match.params.cId;
    }
});

const withAmbassadors = withGetAmbassadors({
    name: "ambassadors",
    options: (props: AddAmbassadorRouteProps) => ({
        variables: {
            filter: {
                ids: props.match.params.aId ? [props.match.params.aId] : undefined
            }
        }
    }),
    skip: (props: AddAmbassadorRouteProps) => {
        return !props.match.params.aId;
    }
});

const withRemoveAmbassadorMutation = withRemoveAmbassador({
    name: "removeAmbassador",
    options: {
        update: cleanCache([getAmbassadors])
    }
});
const withUpdateAmbassadorMutation = withUpdateAmbassador({
    name: "updateAmbassador"
});

const withAddAmbassadorMutation = withAddAmbassador({
    name: "addAmbassador",
    options: (props: AddAmbassadorsPageProps) => ({
        refetchQueries: ["getAmbassadorConfigs", {
            query: getAmbassadors,
            variables: {
                filter: {
                    place: props.ambassadorConfigs?.ambassadorConfigs
                        && props.ambassadorConfigs.ambassadorConfigs[0]
                        && props.ambassadorConfigs.ambassadorConfigs[0].place
                }
            }
        }],
        awaitRefetchQueries: true
    })
});

const withUsers = withGetUsers({
    name: "users",
    options: (props: LocalState) => ({
        variables: {
            filter: props.filter
        }
    }),
    skip: (props: LocalState) => {
        return !props.filter || !props.filter.userSearch || props.filter.userSearch.length < 2;
    }
});

const withUsersSingleUser = withGetUsers({
    name: "singleUser",
    options: (props: AddAmbassadorsPageProps) => ({
        variables: {
            filter: {
                ids: props.ambassadors?.ambassadors?.[0].userId ? [props.ambassadors?.ambassadors?.[0].userId] : []
            }
        }
    }),
    skip: (props: AddAmbassadorsPageProps) => {
        return !props.ambassadors?.ambassadors?.[0]?.userId;
    }
});

const withAmbassadorTags = withGetAmbassadorTags({
    name: "ambassadorTags"
});

export const AddAmbassadorsPageContainer = compose<React.ComponentClass<AddAmbassadorsPageContainerProps>>(
    withAmbassadorConfig,
    withAmbassadors,
    withLocalState,
    withUsers,
    withUsersSingleUser,
    withRemoveAmbassadorMutation,
    withUpdateAmbassadorMutation,
    withAddAmbassadorMutation,
    withAmbassadorTags
)(AddAmbassadorsPage);

export type AddAmbassadorsPageProps =
    AddAmbassadorsPageContainerProps
    & AddAmbassadorRouteProps
    & RemoveAmbassadorProps<{}, "removeAmbassador">
    & UpdateAmbassadorProps<{}, "updateAmbassador">
    & AddAmbassadorProps<{}, "addAmbassador">
    & MaybeElements<GetAmbassadorConfigsProps<{}, "ambassadorConfigs">>
    & MaybeElements<GetAmbassadorsProps<{}, "ambassadors">>
    & GetAmbassadorTagsProps<{}, "ambassadorTags">
    & LocalStateProps<LocalState>
    & MaybeElements<GetUsersProps<{}, "users">>
    & MaybeElements<GetUsersProps<{}, "singleUser">>;
