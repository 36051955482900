import { createTagsPageContainer } from "@pages/tagsPage/baseTagsPageContainer";
import { ExportContentTagContainer } from "@components/export/exportContentTagsContainer";
import { withRemoveContentTag, withGetContentTags } from "../../graphql2/types";

export const withContentTags = withGetContentTags();
export const withRemoveContentTagMutation = withRemoveContentTag({
    name: "removeTag",
    options: {
        refetchQueries: ["getContentTags"],
        awaitRefetchQueries: true
    }
});

export const ContentTagsPageContainer = createTagsPageContainer(
    withContentTags,
    withRemoveContentTagMutation,
    "/content/tags/add/general",
    "content",
    ExportContentTagContainer,
    ["data", "contentTags"]
);
