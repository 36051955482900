import styled from "styled-components";

export const ContentHeaderStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0 30px 0;
`;

export const StyledTitle = styled.h1`
    font-size: 1.25em;
    font-weight: bold;
`;

export const StyledIcon = styled.div`
    margin-right: 1rem;
`;

export const RightFields = styled.div`
    & > * + * {
        margin-left: 1em;
    }
`;
