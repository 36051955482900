import { CreateUserCreditTransactionProps, GetUserCreditStatisticsProps, withCreateUserCreditTransaction, withGetUserCreditStatistics } from "@graphql2/types";
import * as React from "react";
import { compose } from "redux";
import { UserWalletCard } from "./userWalletCard";

export interface UserWalletCardContainerProps {
    userId: string;
}

const withUserCreditStatistics = withGetUserCreditStatistics({
    name: "creditStatistics",
    options: (ownProps: UserWalletCardContainerProps) => ({
        variables: {
            filter: {
                ids: [ownProps.userId]
            }
        },
        fetchPolicy: "no-cache"
    })
});

const withCreateUserCreditTransactionMutation = withCreateUserCreditTransaction({
    name: "createCreditTransaction",
    options: {
        awaitRefetchQueries: true,
        refetchQueries: ["getUserCreditStatistics"]
    }
});

export const UserWalletCardContainer = compose<React.ComponentClass<UserWalletCardContainerProps>>(
    withUserCreditStatistics,
    withCreateUserCreditTransactionMutation
)(UserWalletCard);

export type UserWalletCardProps =
    UserWalletCardContainerProps
    & GetUserCreditStatisticsProps<{}, "creditStatistics">
    & CreateUserCreditTransactionProps<{}, "createCreditTransaction">;
