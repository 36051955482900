import * as React from "react";
import { compose } from "redux";

import { withGetArticles, GetArticlesProps } from "@graphql2/types";
import { ExportProps, exportFactory } from "./export";
import { getByIdQuery, withLocalState, ExportContainerProps } from "./exportContainer";

export const withArticle = withGetArticles(getByIdQuery);

export const ExportArticleContainer = compose<React.ComponentClass<ExportProps>>(
    withLocalState,
    withArticle
)(exportFactory({ targetProp: ["data", "articles"] }));

export type ExportArticleContainerprops = ExportContainerProps & MaybeElements<GetArticlesProps>;
