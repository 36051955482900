import { CloseOutlined } from "@ant-design/icons";
import { Field } from "@components/field/field";
import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { FlowStepTypes } from "@graphql2/types";
import { useCurrentLanguage } from "@hooks/useCurrentLanguage";
import { languages } from "@pages/translationsPage/constants/languages";
import { StepFormValues } from "@utils/emptyItems/emptyFlow";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { Card, Divider, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TypeIdsField } from "./typeIdsField";

type SingleStepCardProps = {
    field: FormListFieldData;
    remove: FormListOperation["remove"];
    languageErrors: LanguageErrors;
    stepData: StepFormValues | undefined;
    form: FormInstance;
}

export type StepType = `${FlowStepTypes}`;

const STEP_TYPES = ["content", "typeform", "event", "challenge"] as StepType[];

export const SingleStepCard = ({ field, remove, languageErrors, stepData, form }: SingleStepCardProps) => {
    const [stepType, setStepType] = React.useState<StepType | undefined>(stepData?.type);
    const [stepModifiedManually, setStepTypeModifiedManually] = React.useState(false);
    const [selectedLanguage, setLanguage] = useCurrentLanguage();

    React.useEffect(() => {
        form.setFields([{
            name: ["steps", field.name, "id"],
            value: stepData?.id ?? Math.floor(Math.random() * 10000000)
        }]);
    }, [stepData?.id]);

    return (
        <Card
            extra={(
                <CloseOutlined
                    onClick={() => {
                        remove(field.name);
                    }}
                />
            )}
            size="small"
            title={`Step ${field.name + 1}`}
        >
            <Field
                hidden
                numberType
                name={[field.name, "id"]}
            />
            <TranslationTabs
                activeLanguage={selectedLanguage}
                errors={languageErrors}
                handleChangeSelectedLanguage={setLanguage}
                languages={languages}
            />
            {
                languages.map(lang => (
                    <Field
                        hidden={selectedLanguage !== lang.code}
                        label={<FormattedMessage id="title" />}
                        name={[field.name, "title", lang.code]}
                        rules={[requiredrule]}
                    />
                ))
            }
            {
                languages.map(lang => (
                    <Field
                        hidden={selectedLanguage !== lang.code}
                        label={<FormattedMessage id="description" />}
                        name={[field.name, "description", lang.code]}
                    />
                ))
            }
            <Divider />
            <Field
                label={<FormattedMessage id="flows.icon" />}
                name={[field.name, "icon"]}
            />
            <SelectField
                label={<FormattedMessage id="flows.type" />}
                name={[field.name, "type"]}
                rules={[requiredrule]}
                onChange={(value: StepType) => {
                    setStepType(value);
                    setStepTypeModifiedManually(true);
                }}
            >
                {STEP_TYPES.map((type) => (
                    <Select.Option
                        key={type}
                        value={type}
                    >
                        <FormattedMessage id={`flows.${type}`} />
                    </Select.Option>
                ))}
            </SelectField>
            <TypeIdsField
                fieldName={field.name}
                form={form}
                stepType={stepType}
                stepTypeModifiedManually={stepModifiedManually}
            />
        </Card>
    );
};
