import React from "react";
import { message } from "antd";

export const handleSuccess = (translation: React.ReactNode, callback: () => void) => {
    message.success(translation);
    callback();
};

export const handleError = (translation: React.ReactNode, callback?: () => void) => {
    message.error(translation);
    callback && callback();
};
