import { Field } from "@components/field/field";
import { DeleteFormField, FormItemFlex } from "@components/field/formConditionFieldStyle";
import { AddContentField, FormValueKey, FormValueOptions } from "@components/formContentFields/formElementStyle";
import { FormFieldBase } from "@components/formContentFields/formFieldBase";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { formOptionFactory } from "@pages/addChallengesPage/factories";

export class FormFieldRadio extends FormFieldBase {
    public renderBody() {
        const { prefix, index, form, property, deleteFormElement } = this.props;

        return (
            <React.Fragment>
                <FormItemFlex>
                    <Field
                        name={[index, "property"]}
                        initialValue={property}
                        {...this.sharedProps}
                        label={<FormattedMessage id="valueKey" />}
                    />
                    <DeleteFormField
                        as={DeleteOutlined}
                        onClick={deleteFormElement}
                    />
                </FormItemFlex>
                <Form.List name={[index, "options"]}>
                    {(fields, { add, remove, move }) => {
                        return (
                            <React.Fragment>
                                <FormValueKey>
                                    <FormValueOptions>
                                        {
                                            fields.map((option, i) => {
                                                return (
                                                    <FormItemFlex>
                                                        <Field
                                                            name={[i, "value"]}
                                                            key={i}
                                                            {...this.sharedProps}
                                                            label={<FormattedMessage id="valueKey" />}
                                                        />
                                                        <DeleteFormField
                                                            as={DeleteOutlined}
                                                            onClick={() => remove(i)}
                                                        />
                                                    </FormItemFlex>
                                                );
                                            })
                                        }
                                    </FormValueOptions>

                                </FormValueKey>
                                <AddContentField
                                    onClick={() => add(formOptionFactory(fields.length))}
                                >
                                    <FormattedMessage id="addRadio" />
                                </AddContentField>
                            </React.Fragment>
                        );
                    }}
                </Form.List>

            </React.Fragment>
        );
    }
}
